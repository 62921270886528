import { faArrowRight, faCircleXmark, faLock, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { Accordion, Button, Col, Container, Form, Modal, Nav, Row, Stack, Tab, useAccordionButton } from "react-bootstrap";
import { useParams } from "react-router-dom";
import '../../scss/global.scss';
import { InitialUtilsStateProps, actions } from "../../store/ducks/utils.duck";
import { connect } from "react-redux";
import { useFormik } from "formik";
import FormWithFormik from "../../components/reusableFormWithFormik";

interface Callback {
    id: number;
    title: string;
    list: Array<SubCallback>
}
interface SubCallback {
    description: string;
    name: string;
}

interface Ip { ip: string }

interface input {
    paid: string;
    created: string;
}

let dataCallback: Array<Callback> = [
    {
        id: 1,
        title: 'FIXED VIRTUAL ACCOUNTS',
        list: [
            { description: 'FVA Paid', name: 'paid' },
            { description: 'FVA Created', name: 'created' }
        ]
    },
    {
        id: 2,
        title: 'DIRECT DEBIT',
        list: [
            { description: 'FVA Paid', name: 'linked' },
            { description: 'FVA Created', name: 'completed' },
            { description: 'FVA Paid', name: 'method' },
            { description: 'FVA Created', name: 'refund' }
        ]
    },
    {
        id: 3,
        title: 'GENERAL REPORTS',
        list: [
            { description: 'FVA Paid', name: 'payment-notif' },
            { description: 'FVA Created', name: 'notif' }
        ]
    }
]

const ClientDetail: FC<InitialUtilsStateProps & typeof actions> = (props) => {
    const [showModalDetail, setShowModalDetail] = useState<boolean>(false)
    const [showModalAddIp, setShowModalAddIp] = useState<boolean>(false)
    const { id }: any = useParams();
    const [showApiKey, setShowApiKey] = useState<boolean>(false)
    const [showCallbackToken, setShowCallbackToken] = useState<boolean>(false)
    const [inputLength, setInputLength] = useState<number>(0)
    const [values, setValues] = useState<string>('')
    const [inputs, setInputs] = useState<input>({
        paid: '',
        created: '',
    });

    const changeInputs = (e: ChangeEvent<HTMLInputElement>) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
        setInputLength(e.target.value.length)
        setValues(e.target.value)
    }

    const valueInput = (data: string) => {
        for (let key in inputs) {
            let value: any = inputs[key as keyof input]
            if (key === data) return value
        }
    }
    const removeInputs = (val: string) => {
        setInputs({
            paid: '',
            created: ''
        })
        console.log('blm selesai', val)
    }
    const submit = (e: any) => {
        e.preventDefault();
        let sendData = {
            id: 1,
            name: values
        }
        console.log(sendData)
    }

    const configAddIp = useFormik<Ip>({
        initialValues: {
            ip: ''
        },
        onSubmit: values => {
            console.log(values)
        },
    })

    let bankData = [
        {
            id: 1,
            account: 'BCA',
            status: true
        },
        {
            id: 2,
            account: 'DANA',
            status: true
        },
        {
            id: 3,
            account: 'BRI',
            status: false
        },
        {
            id: 4,
            account: 'Mandiri',
            status: false
        }
    ]

    let ipData = [
        { id: 1, ip: '192.168.0.1' },
        { id: 2, ip: '192.168.0.1' },
        { id: 3, ip: '192.168.0.1' },
        { id: 4, ip: '192.168.0.1' },
        { id: 5, ip: '192.168.0.1' },
        { id: 6, ip: '192.168.0.1' },
        { id: 7, ip: '192.168.0.1' },
        { id: 8, ip: '192.168.0.1' },
        { id: 9, ip: '192.168.0.1' },
        { id: 10, ip: '192.168.0.1' },
    ]

    let jsonData = {
        id: 1,
        name: 'Ninja',
        trx: 9097554444,
        address: 'malino',
        created: '2017-02-15T11:01:52.896Z',
        updated: '2017-02-15T11:01:52.896Z'
    }

    const statusClient = (data: boolean) => {
        if (data) return <ul className="list-status mb-0" style={{ color: '#2DA602' }}>
            <li>
                <p style={{ fontSize: '15px', color: 'black' }}>ACTIVE</p>
            </li>
        </ul>
        else return <ul className="list-status mb-0" style={{ color: 'red' }}>
            <li>
                <p style={{ fontSize: '15px', color: 'black' }}>NONACTIVE</p>
            </li>
        </ul>
    }

    const ToggleAccordion = ({ children, eventKey }: any) => {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('show accordion'),
        );
        return (
            <button
                type="button"
                style={{ background: '#fff', color: '#278DD7', border: '1px solid #278DD7' }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }


    const logoPayment = (data: string) => {
        if (data === 'Mandiri') return require('../../../_metronic/_assets/image/mandirilogo.png')
        else if (data === 'BCA') return require('../../../_metronic/_assets/image/bca.png')
        else if (data === 'BRI') return require('../../../_metronic/_assets/image/brilogo.png')
        else if (data === 'BNI') return require('../../../_metronic/_assets/image/bnilogo.png')
        else if (data === 'OVO') return require('../../../_metronic/_assets/image/ovo.png')
        else if (data === 'DANA') return require('../../../_metronic/_assets/image/dana.png')
        else if (data === 'SPAY') return require('../../../_metronic/_assets/image/spay.png')
        else return require('../../../_metronic/_assets/image/mandirilogo.png')
    }

    const changeStatus = () =>
        props.addModal({
            type: "INFO",
            okLabel: "Confirm",
            onOk: () => { console.log('saat ok'); },
            cancelLabel: "Cancel",
            onCancel: () => { console.log('saat cancel'); },
            closable: true,
            title: "Deactivate Confirmation",
            content: 'Are you sure you want to deactivate this data?'
        })

    const openModalDetail = () => setShowModalDetail(true);
    const closeModalDetail = () => setShowModalDetail(false);

    const deleteIp = (id: number) =>
        props.addModal({
            type: "DANGER",
            okLabel: "Confirm",
            onOk: () => { console.log('delete', id); },
            cancelLabel: "Cancel",
            onCancel: () => { console.log('saat cancel'); },
            closable: true,
            title: "Delete Confirmation",
            content: 'Are you sure you want to delete this data?'
        })

    const openModalAddIp = () => setShowModalAddIp(true);
    const closeModalAddIp = () => setShowModalAddIp(false);

    const hideApiKey = (target: string) => {
        let api: string = "";
        for (let i: number = 0; i < target.length; i++) {
            if (i > 5 && i < 20) {
                api += "-";
            } else {
                api += target[i];
            }
        }
        return api;
    }

    const hideCallbackToken = (target: string) => {
        let callback: string = "";
        for (let i: number = 0; i < target.length; i++) {
            if (i >= 0) {
                callback += "X";
            } else {
                callback += target[i];
            }
        }
        return callback;
    }

    return (
        <>
            <Container fluid className="p-3 mx-2">
                <Tab.Container defaultActiveKey={1}>
                    <Row>
                        <div className="box-card bg-white p-3 flex-coumn" style={{ width: '300px', height: '460px' }}>
                            {statusClient(true)}
                            <div className="icon-project">
                                <img src={'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} alt="icon-project" />
                            </div>
                            <div className="text-center">
                                <h4>Tokocrypto</h4>
                                <p className="px-2">Tokocrypto is one of our internal company that will use middleware to allow their user to deposit</p>
                            </div>
                            <Nav variant="pills" className="flex-column tabs-client-detail">
                                <Nav.Item style={{ borderBottom: '1px solid #E5E4E4' }}>
                                    <Nav.Link eventKey={1}>
                                        <FontAwesomeIcon icon={faArrowRight} className="mr-4" />
                                        Credeentials
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item style={{ borderBottom: '1px solid #E5E4E4' }}>
                                    <Nav.Link eventKey={2}>
                                        <FontAwesomeIcon icon={faArrowRight} className="mr-4" />
                                        Payment Services
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item style={{ borderBottom: '1px solid #E5E4E4' }}>
                                    <Nav.Link eventKey={3}>
                                        <FontAwesomeIcon icon={faArrowRight} className="mr-4" />
                                        IP Whitelisted
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Col>
                            <Tab.Content className="tabs-content mb-3">
                                <Tab.Pane eventKey={1} className="px-3">
                                    <div className="box-card bg-white p-4 mb-4">
                                        <h5>API Credentials</h5>
                                        <p>Secret keys are used to authenticate API requests coming from your servers.</p>
                                        <div className="d-flex pb-3 mb-3" style={{ borderBottom: '2px solid #E5E4E4' }}>
                                            <b className="col">Description</b>
                                            <b className="col">Value</b>
                                        </div>
                                        <ul className="list-api-credentials">
                                            <li className="d-flex justify-content-between mb-2" style={{ borderBottom: '1px solid #E5E4E4' }}>
                                                <p className="col">Key Name</p>
                                                <p className="col">Tokopayment</p>
                                            </li>
                                            <li className="d-flex justify-content-between mb-2" style={{ borderBottom: '1px solid #E5E4E4' }}>
                                                <p className="col">API Key</p>
                                                <p className="col">{showApiKey ? 'Ahjx7ysbJaksjnxbbxhsgZXg667ahsn' : hideApiKey('Ahjx7ysbJaksjnxbbxhsgZXg667ahsn')}</p>
                                                <Button onClick={() => setShowApiKey(!showApiKey)} style={{ border: 'none', background: 'none' }}>
                                                    <FontAwesomeIcon icon={faLock} color='#F99705' />
                                                </Button>
                                            </li>
                                            <li className="d-flex justify-content-between mb-2" style={{ borderBottom: '1px solid #E5E4E4' }}>
                                                <p className="col">Secret</p>
                                                <p className="col">{showApiKey ? 'Ahjx7ysbJaksjnxbbxhsgZXg667ahsn' : hideApiKey('Ahjx7ysbJaksjnxbbxhsgZXg667ahsn')}</p>
                                                <Button onClick={() => setShowApiKey(!showApiKey)} style={{ border: 'none', background: 'none' }}>
                                                    <FontAwesomeIcon icon={faLock} color='#F99705' />
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="box-card bg-white p-4 mb-4">
                                        <h5>Callbacks</h5>
                                        <p>Add your callback URLs here. To learn how to add and manage your callbacks.</p>
                                        <br />
                                        <b>Callback verification token</b>
                                        <div className="d-flex mt-2 mb-5" style={{ backgroundColor: '#368AC799' }}>
                                            <p className="col p-3 m-0">{showCallbackToken ? '1225625162726ywhdgte6625Gv66Ay8888haCAE43r' : hideCallbackToken('1225625162726ywhdgte6625Gv66Ay8888haCAE43r')}</p>
                                            <Button onClick={() => setShowCallbackToken(!showCallbackToken)} style={{ border: 'none', background: 'none' }}>
                                                <FontAwesomeIcon icon={faLock} color='#fff' />
                                            </Button>
                                        </div>
                                        <div className="d-flex pb-3 mb-3" style={{ borderBottom: '2px solid #E5E4E4' }}>
                                            <b className="col">Description</b>
                                            <b className="col">Callback URL</b>
                                        </div>
                                        {dataCallback.map(data => (
                                            <div key={data.id}>
                                                <h5>{data.title}</h5>
                                                {data.list.map((item, idx) => (
                                                    <div key={idx} className="mb-2 p-3" style={{ background: '#F9F9F9' }}>
                                                        <Accordion>
                                                            <div className="d-flex">
                                                                <p className="mb-0" style={{ width: '130px', lineHeight: '35px' }}>{item.description}</p>
                                                                <Form onSubmit={submit} className="d-flex justify-content-between col">
                                                                    <div className="d-flex" style={{ width: '60%' }}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={item.name}
                                                                            onChange={changeInputs}
                                                                            value={valueInput(item.name)}
                                                                            placeholder="https://yourwebhook.url/receive"
                                                                        />
                                                                        <Button
                                                                            disabled={inputLength !== 0 ? false : true}
                                                                            className="p-0" style={{ position: 'relative', right: '28px', border: 'none', background: 'none' }}
                                                                            onClick={() => removeInputs(item.name)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faCircleXmark} color='#DEDEDE' />
                                                                        </Button>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <Button variant="success" type="submit" className="mr-3">Test and Save</Button>
                                                                        <ToggleAccordion eventKey="0">Show Payload</ToggleAccordion>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                            <Accordion.Collapse eventKey="0" className="mt-2">
                                                                <>
                                                                    <h5 className="px-4 py-2 m-0" style={{ color: '#fff', background: '#484848' }}>Example callback payload</h5>
                                                                    <div className="p-4" style={{ background: '#313131', color: '#fff' }}>
                                                                        <pre className="mb-0">{JSON.stringify(jsonData, undefined, 2)}</pre>
                                                                    </div>
                                                                </>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={2} className="px-3">
                                    <div className="box-card bg-white p-4 mb-4">
                                        <h5>Virtual Accounts</h5>
                                        <p>Instant Activation: Virtual Accounts would be instantly enabled by clicking the "Activate" button</p>
                                        <Stack direction="horizontal" gap={4} className='flex-wrap'>
                                            {bankData.map(list => (
                                                <div key={list.id} className="box-card col">
                                                    <h1 className="bullet-card-transaction" style={{ color: list.status ? '#2DA602' : '#D9D9D9' }}>&bull;</h1>
                                                    <div className="text-center mt-3 mb-5">
                                                        <img src={logoPayment(list.account)} alt='icon-bank' />
                                                    </div>
                                                    <div className="d-grid p-3" style={{ borderTop: '1px solid #E5E4E4' }}>
                                                        <Button variant={list.status ? 'danger' : 'success'} onClick={changeStatus} className="mb-1">
                                                            {list.status ? 'Deactivate' : 'Activate'}
                                                        </Button>
                                                        <Button onClick={openModalDetail} style={{ background: 'none', border: 'none', color: 'black' }}>
                                                            View Detail
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Stack>
                                    </div>
                                    <div className="box-card bg-white p-4 mb-4">
                                        <h5>Direct Debit</h5>
                                        <p>Instant Activation: Direct Debit would be instantly enabled by clicking the "Activate" button</p>
                                        <Stack direction="horizontal" gap={4} className='flex-wrap'>
                                            {bankData.map(list => (
                                                <div key={list.id} className="box-card col">
                                                    <h1 className="bullet-card-transaction" style={{ color: list.status ? '#2DA602' : '#D9D9D9' }}>&bull;</h1>
                                                    <div className="text-center mt-3 mb-5">
                                                        <img src={logoPayment(list.account)} alt='icon-bank' />
                                                    </div>
                                                    <div className="d-grid p-3" style={{ borderTop: '1px solid #E5E4E4' }}>
                                                        <Button variant={list.status ? 'danger' : 'success'} onClick={changeStatus} className="mb-1">
                                                            {list.status ? 'Deactivate' : 'Activate'}
                                                        </Button>
                                                        <Button onClick={openModalDetail} style={{ background: 'none', border: 'none', color: 'black' }}>
                                                            View Detail
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Stack>
                                    </div>
                                    <div className="box-card bg-white p-4 mb-4">
                                        <h5>eWallets</h5>
                                        <p>Instant Activation: eWallet would be instantly enabled by clicking the "Activate" button</p>
                                        <Stack direction="horizontal" gap={4} className='flex-wrap'>
                                            {bankData.map(list => (
                                                <div key={list.id} className="box-card col">
                                                    <h1 className="bullet-card-transaction" style={{ color: list.status ? '#2DA602' : '#D9D9D9' }}>&bull;</h1>
                                                    <div className="text-center mt-3 mb-5">
                                                        <img src={logoPayment(list.account)} alt='icon-bank' />
                                                    </div>
                                                    <div className="d-grid p-3" style={{ borderTop: '1px solid #E5E4E4' }}>
                                                        <Button variant={list.status ? 'danger' : 'success'} onClick={changeStatus} className="mb-1">
                                                            {list.status ? 'Deactivate' : 'Activate'}
                                                        </Button>
                                                        <Button onClick={openModalDetail} style={{ background: 'none', border: 'none', color: 'black' }}>
                                                            View Detail
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Stack>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={3} className="px-3">
                                    <div className="box-card bg-white p-4 mb-4">
                                        <div className="d-flex justify-content-between mb-3">
                                            <h5 style={{ lineHeight: '35px' }}>IP WHITELISTED</h5>
                                            <Button variant="outline-info" onClick={openModalAddIp}>Add Ip</Button>
                                        </div>
                                        <p>
                                            Secure API traffic and access against foreign or malicious IPs by allowing only specific IP addresses of your choice to access APIs.
                                            The IP Whitelist only works for API users through direct integration.
                                        </p>
                                        <div className="d-flex justify-content-between pb-3 mb-3" style={{ borderBottom: '2px solid #E5E4E4' }}>
                                            <b>Name</b>
                                            <b>Option</b>
                                        </div>
                                        {ipData.map(item => (
                                            <div key={item.id} className="d-flex justify-content-between mb-3" style={{ borderBottom: '1px solid #E5E4E4' }}>
                                                <p>{item.ip}</p>
                                                <Button onClick={() => deleteIp(item.id)} style={{ border: 'none', background: 'none' }}>
                                                    <FontAwesomeIcon icon={faTrash} color='#BC0505' />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

            <Modal size="lg" centered show={showModalDetail} onHide={closeModalDetail}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Bank Virtual Accounts</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex" style={{ color: 'black' }}>
                        <div className="col" >
                            <h5 className="mb-3">Invoice VA</h5>
                            <b className="mb-0">Settlement Time</b>
                            <p>10 Working Days</p>
                            <b className="mb-0">Settlement Time</b>
                            <p>10 Working Days</p>
                            <b className="mb-0">Settlement Time</b>
                            <p>10 Working Days</p>
                        </div>
                        <div className="col">
                            <h5 className="mb-3">Fixed VA</h5>
                            <b className="mb-0">Closed virtual account range</b>
                            <p>13282 171040000000 - 13282 171059999999</p>
                            <b className="mb-0">Closed virtual account range</b>
                            <p>13282 171040000000 - 13282 171059999999</p>
                            <b className="mb-0">Closed virtual account range</b>
                            <p>13282 171040000000 - 13282 171059999999</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={closeModalDetail}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" centered show={showModalAddIp} onHide={closeModalAddIp}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Form IP Whitelisted</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="d-flex justify-content-between">
                            <FormWithFormik
                                type='TEXT_INPUT'
                                name='ip'
                                label='IP Address'
                                config={configAddIp}
                                style={{ width: '51rem' }}
                                placeholder='Insert your IP Address'
                            />
                            <div style={{ position: 'absolute', bottom: '17px', right: '25px' }}>
                                <Button variant="outline-success">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
    return {
        ...props.utils
    }
}

export default connect(mapStateToProps, actions)(ClientDetail)
