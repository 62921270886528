import clsx from 'clsx'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from "react-bootstrap";
import ReusableFormGenerator, { FIELD_TYPE } from '../../components/reusableFormGenerator'
import { createBank, getBankDetail, updateBank } from '../../otc_services/resources/bank/bank_service';
import Swal from 'sweetalert2';
import { bankStatus } from './bankTransfer';

export const allBanks = [
  { label: "BCA", value: "bca" },
]

const BankTransferAdd = (props) => {
  const isEditMode = props.history.location.pathname.includes("edit")
  
  const [inputs, setInputs] = useState({
    id: null,
    bank_type: null,
    bank_account_name: null,
    bank_account_number: null,
    payment_guidelines_id: "",
    payment_guidelines_en: "",
    status: 0,
  })
  const [payment_guidelines_id, setPayment_guidelines_id] = useState('')
  const [payment_guidelines_en, setPayment_guidelines_en] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  
  const fields = [
    [
      {
        type: FIELD_TYPE.DROPDOWN,
        label: "Account Type",
        value: inputs.bank_type,
        onChange: (evt) => setInputs({...inputs, bank_type: evt.target.value}),
        options:[
          { label: "- Select Account Type -", value: null },
          ...allBanks
        ],
        placeholder: "Account Type",
        required: true
      },
      // {
      //   type: FIELD_TYPE.TEXT_INPUT,
      //   label: "Account Type",
      //   value: inputs.bank_type,
      //   onChange: (evt) => setInputs({...inputs, bank_type: evt.target.value}),
      //   placeholder: "Account Type",
      //   required: true
      // },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Account Name",
        value: inputs.bank_account_name,
        onChange: (evt) => setInputs({...inputs, bank_account_name: evt.target.value}),
        placeholder: "Account Name",
        required: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Account Number",
        value: inputs.bank_account_number,
        onChange: (evt) => setInputs({...inputs, bank_account_number: evt.target.value}),
        placeholder: "Account Number",
        required: true
      },
      {
        type: FIELD_TYPE.DROPDOWN,
        label: "Status",
        value: inputs.status,
        onChange: (evt) => setInputs({...inputs, status: Number(evt.target.value)}),
        options: bankStatus.map((status) => ({
          label: status.label,
          value: status.id
        })),
        placeholder: "Status",
        required: true
      },
    ],
    [
      {
        type: FIELD_TYPE.WYSIWYG,
        label: "Transfer Guidelines (ID)",
        data: payment_guidelines_id,
        onChange: ( event ) => {
          const data = event.editor.getData();
          setPayment_guidelines_id(data);
        },
        placeholder: "Transfer Guidelines (ID)",
        required: true
      },
    ],
    [
      {
        type: FIELD_TYPE.WYSIWYG,
        label: "Transfer Guidelines (EN)",
        data: payment_guidelines_en,
        onChange: ( event ) => {
          const data = event.editor.getData();
          setPayment_guidelines_en(data);
        },
        placeholder: "Transfer Guidelines (EN)",
        required: true
      },
    ]
  ]
  const submitHandler = () => {
    if(isEditMode) {
      setIsLoading(true)
      let payload = {...inputs}
      payload.bank_payment_guidelines_en = payment_guidelines_en
      payload.bank_payment_guidelines_id = payment_guidelines_id
      payload.bank_status = payload.status
      updateBank(inputs.id, payload)
        .then(()=>{
          Swal.fire(
            'Success!',
            `You has been successfully edit this data!`,
            'success'
          )
          props.history.push("/otc-finance/bank-transfer")
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          setIsLoading(false)
        })
    }
    else {
      setIsLoading(true)
      let payload = {...inputs}
      payload.bank_payment_guidelines_en = payment_guidelines_en
      payload.bank_payment_guidelines_id = payment_guidelines_id
      payload.bank_status = payload.status
      createBank(payload)
        .then(()=>{
          Swal.fire(
            'Success!',
            `You has been successfully create this data!`,
            'success'
          )
          props.history.push("/otc-finance/bank-transfer")
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          setIsLoading(false)
        })
    }
  }
  const firstInit = () => {
    if(props.match.params?.id) {
      setIsLoading(true)
      getBankDetail(props.match.params?.id)
        .then((res)=>{
          // console.log(res)
          setInputs(res.data.data);
          setPayment_guidelines_id(res.data.data.payment_guidelines_id);
          setPayment_guidelines_en(res.data.data.payment_guidelines_en);
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          setIsLoading(false)
        })
    }
  }
  useEffect(firstInit, [])
  return (
    <ReusableFormGenerator 
      title={isEditMode ? "Edit Bank Transfer" : "Create Bank Transfer"}
      fields={fields}
      footer={
        <Fragment>
          <Link to="/otc-finance/bank-transfer">
            <Button variant="outline-danger" className="mr-3">Back</Button>
          </Link>
          <button
            type="button"
            disabled={isLoading}
            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
              }
            )}`}
            onClick={()=>submitHandler()}
          >
            {isEditMode ? 'Submit' : 'Save'}
          </button>
        </Fragment>
      }
      isSubmit={(d)=>submitHandler()}
    />
  )
}

export default BankTransferAdd