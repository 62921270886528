import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";
import PerfectScrollbar from "react-perfect-scrollbar";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const AsideLeft = (props) => {
  const history = useHistory()
  const [isSmallMenu, setIsSmallMenu] = useState(true)
  const [isAnimate, setIsAnimate] = useState(false)
  const [isCollapse, setIsCollapse] = useState(false)
  const [marginBottom, setMarginBottom] = useState(0);
  const asideOffCanvasRef = useRef();
  const bottomRef = useRef();

  useEffect(()=>{
    new KTOffcanvas(
      asideOffCanvasRef.current,
      props.menuCanvasOptions
    );
  }, [])

  useEffect(()=> {
    if(isAnimate) setTimeout(()=>setIsAnimate(false), 200)
    else setIsSmallMenu(!isSmallMenu)
  }, [isAnimate])
  
  useEffect(()=>{
    if(isCollapse && isSmallMenu) setIsAnimate(true)
  },[isCollapse])

  useEffect(()=>{
    if(bottomRef.current) setMarginBottom(bottomRef.current.offsetHeight)
  }, [bottomRef.current])

  const avatar = () => {
    return localStorage.getItem('user_mail').split(' ').map(i => i[0].toUpperCase()).join('').slice(0, 2)
  }

  const customStyle = {
    transform: isAnimate ? 'translateY(-150%)' : undefined,
    opacity: isAnimate ? 0 : 1,
    visibility: isAnimate ? 'hidden' : 'visible'
  }

  const onCollapse = (value) => {
    setIsCollapse(value)
  }

  const onLogout = async () => {
    if(isSmallMenu) {
      const confirmation = await Swal.fire({
        title: 'Hi there!',
        icon: 'question',
        html: 'Are you sure to logout?',
        confirmButtonText: 'Yes!!!!!!',
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      })
      if(confirmation.isConfirmed) history.push('/logout')
    }
  }

  return (
    <>
      <button className="kt-aside-close" id="kt_aside_close_btn">
        <i className="la la-close" />
      </button>
      <div
        id="kt_aside"
        ref={asideOffCanvasRef}
        className={`kt-aside ${props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
      >
        <Brand onCollapse={onCollapse} />
        <div
          id="kt_aside_menu_wrapper"
          className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        >
          {props.disableScroll && (
            <Menu htmlClassService={props.htmlClassService} marginBottom={marginBottom} />
          )}
          {!props.disableScroll && (
            <PerfectScrollbar
              options={{ wheelSpeed: 2, wheelPropagation: false }}
            >
              <Menu htmlClassService={props.htmlClassService} marginBottom={marginBottom} />
            </PerfectScrollbar>
          )}
        </div>
        <div className="sidebar-bottom" ref={bottomRef}>
          <div 
            className="sidebar-bottom__wrapper" 
            style={{
              background: isSmallMenu ? "rgba(217, 217, 217, 0.1)" : undefined,
              padding: isCollapse ? 0 : undefined
            }}
          >
            <div 
              className="sidebar-bottom__content"
              style={{
                ...customStyle,
                cursor: isSmallMenu ? 'pointer' : undefined
              }}
              onClick={onLogout}
            >
              <div className={`sidebar-bottom__content__avatar ${isSmallMenu ? 'sidebar-bottom__content__sub-active' : 'sidebar-bottom__content__sub-inactive'}`}>
                {isSmallMenu ? 
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.5 12.75C3.04813 12.75 0.25 9.95188 0.25 6.5C0.25 3.04813 3.04813 0.250002 6.5 0.250002C7.47041 0.249273 8.42762 0.47486 9.29558 0.90884C10.1635 1.34282 10.9183 1.97324 11.5 2.75H9.80625C9.08452 2.1136 8.19448 1.69898 7.24295 1.55588C6.29141 1.41278 5.31879 1.54729 4.4418 1.94326C3.56482 2.33923 2.82072 2.97985 2.29879 3.78824C1.77687 4.59663 1.49929 5.53846 1.49937 6.50069C1.49945 7.46293 1.77719 8.40471 2.29925 9.21301C2.8213 10.0213 3.56551 10.6618 4.44256 11.0576C5.31962 11.4535 6.29226 11.5878 7.24377 11.4446C8.19528 11.3013 9.08525 10.8865 9.80688 10.25H11.5006C10.9189 11.0269 10.164 11.6573 9.29593 12.0913C8.42785 12.5253 7.47052 12.7508 6.5 12.75ZM10.875 9V7.125H5.875V5.875H10.875V4L14 6.5L10.875 9Z" fill="white"/>
                  </svg>
                  
                : avatar()}
              </div>
              {!isCollapse &&
                <div className="sidebar-bottom__content__infos">
                  {isSmallMenu ? 'Logout' :
                    <Fragment>
                      <div className="sidebar-bottom__content__infos__name">{localStorage.getItem('user_mail')}</div>
                      <div className="sidebar-bottom__content__infos__title">{localStorage.getItem('rule')}</div>
                    </Fragment>
                  }
                </div>
              }
            </div>
            {!isCollapse &&
              <button 
                className="sidebar-bottom__ellipsis" 
                onClick={()=>!isAnimate && setIsAnimate(true)}
                style={customStyle}
              >
                {isSmallMenu ?
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_78_3)">
                      <path d="M7.5 15C11.6422 15 15 11.6422 15 7.5C15 3.35775 11.6422 0 7.5 0C3.35775 0 0 3.35775 0 7.5C0 11.6422 3.35775 15 7.5 15ZM7.5 6.4395L9.621 4.31775L10.6823 5.379L8.5605 7.5L10.6823 9.621L9.621 10.6823L7.5 8.5605L5.379 10.6823L4.31775 9.621L6.4395 7.5L4.31775 5.379L5.379 4.31775L7.5 6.4395Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_78_3">
                        <rect width="15" height="15" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>                
                  :                
                  <svg width="3" height="13" viewBox="0 0 3 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1.5" cy="1.5" r="1.5" fill="#D9D9D9"/>
                    <circle cx="1.5" cy="6.5" r="1.5" fill="#D9D9D9"/>
                    <circle cx="1.5" cy="11.5" r="1.5" fill="#D9D9D9"/>
                  </svg>
                }
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active"
    }
  }
});

export default connect(mapStateToProps)(AsideLeft);
