import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import courseService from '../../../crud/course';
import { toast } from 'react-toastify';
import CKEditor from 'ckeditor4-react';

// Yup Schema
const Schema = yup.object({
  course_name_en: yup.string().required('Course Name (EN) is a required field'),
  course_name_id: yup.string().required('Course Name (ID) is a required field'),
  course_image: yup
    .mixed()
    .test('image-check', 'Course Image is a required field', (val, context) => {
      const is_edit = context.parent.is_edit;

      return !is_edit ? val && val !== '' : true;
    })
    .test('image-size', 'file image must less than 2 MB', (val) => {
      if (val?.size > 2097152) {
        return false;
      } else {
        return true;
      }
    }),
  description_en: yup
    .string()
    .required('Description (EN) is a required field')
    .max(350, 'Description (EN) must be at most 350 characters'),
  description_id: yup
    .string()
    .required('Description (ID) is a required field')
    .max(350, 'Description (ID) must be at most 350 characters'),
  goals: yup
    .array()
    .of(
      yup.object().shape({
        description_en: yup
          .string()
          .required('Goals Description (EN) is a required field'),
        description_id: yup
          .string()
          .required('Goals Description (ID) is a required field'),
      })
    )
    .min(1),
  target_participant_en: yup
    .string()
    .required('Target Participant (EN) is a required field'),
  target_participant_id: yup
    .string()
    .required('Target Participant (ID) is a required field'),
  upload_file: yup
    .mixed()
    // .test('upload-file-test', 'File is a required field', (val, context) => {
    //   const is_edit = context.parent.is_edit;

    //   return !is_edit ? val && val !== '' : true;
    // })
    .test('file-type', 'file type must be pdf', (val, context) => {
      const is_edit = context.parent.is_edit;
      if (val && val !== '' && !is_edit && val?.type !== 'application/pdf') {
        return false;
      } else {
        return true;
      }
    }),
  certificate_en: yup.string(),
  certificate_id: yup.string(),
  rewards_en: yup.string(),
  rewards_id: yup.string(),
  diffculities: yup.string().required('Diffculities is a required field'),
  time_allocation: yup.string().required('Time Allocation is a required field'),
  sorting_no: yup.string().required('Sorting No is a required field'),
  status: yup.string().required('Status is a required field'),
  showOnHomepage: yup.string().required('Show On Homepage is a required field'),
});

const CustomForm = (props) => {
  const uploadFileRef = useRef(null)
  const [loading, setloading] = useState(false);
  const [syllabus, setSyllabus] = useState({
    status: 'init',
    url: ''
  })
  const [newImage, setnewImage] = useState(null);
  const [oldImage, setoldImage] = useState(null);

  useEffect(() => {
    props.submitRef.current = handleSubmit;
  }, []);

  useEffect(() => {
    if (props.data) {
      setoldImage(props?.data?.course_image);
      if(props?.data?.syllabus) sylabusUrlSetter(props.data.syllabus)
      CourseForm.setValues({
        course_id: props?.data?.id,
        is_edit: props?.data?.id ? true : false,
        course_name_en: props?.data?.course_name_en,
        course_name_id: props?.data?.course_name_id,
        course_image: null,
        description_en: props?.data?.description_en,
        description_id: props?.data?.description_id,
        goals: props?.data?.goals,
        target_participant_en: props?.data?.target_participant_en,
        target_participant_id: props?.data?.target_participant_id,
        upload_file: props?.data?.syllabus ?? null,
        certificate_en: props?.data?.certificate_en,
        certificate_id: props?.data?.certificate_id,
        rewards_en: props?.data?.rewards_en,
        rewards_id: props?.data?.rewards_id,
        diffculities: props?.data?.difficulty,
        time_allocation: props?.data?.time_allocation,
        sorting_no: props?.data?.sorting_number,
        status: props?.data?.status,
        showOnHomepage: props?.data?.homepage_status,
      });
    }
  }, [props.data]);

  //   get token
  const getToken = () => localStorage.getItem('token');

  //   Use Formik Hook Use
  const CourseForm = useFormik({
    initialValues: {
      showOnHomepage: '1',
      course_id: '',
      is_edit: false,
      course_name_en: '',
      course_name_id: '',
      course_image: null,
      description_en: '',
      description_id: '',
      goals: [
        {
          description_en: '',
          description_id: '',
        },
      ],
      target_participant_en: '',
      target_participant_id: '',
      upload_file: null,
      certificate_en: '',
      certificate_id: '',
      rewards_en: '',
      rewards_id: '',
      diffculities: '1',
      time_allocation: '',
      sorting_no: '',
      status: '1',
    },
    validationSchema: Schema,
    onSubmit: async (value) => {
      try {
        const body = new FormData();

        body.append('course_name_en', value.course_name_en);
        body.append('course_name_id', value.course_name_id);
        body.append('course_image', value.course_image);
        body.append('description_en', value.description_en);
        body.append('description_id', value.description_id);
        body.append('target_participant_en', value.target_participant_en);
        body.append('target_participant_id', value.target_participant_id);
        if(syllabus.status === 'edited') body.append('syllabus', value.upload_file);
        else if(syllabus.status === 'not edited') body.append('syllabus', null);
        body.append('certificate_en', value.certificate_en);
        body.append('certificate_id', value.certificate_id);
        body.append('rewards_en', value.rewards_en);
        body.append('rewards_id', value.rewards_id);
        body.append('difficulty', value.diffculities);
        body.append('status', value.status);
        body.append('time_allocation', value.time_allocation);
        body.append('sorting_number', value.sorting_no);
        body.append('goals', JSON.stringify(value.goals));
        body.append('homepage_status', value.showOnHomepage);

        if (!value.is_edit) {
          const res = await courseService.fetchAddCourse(getToken(), body);
          toast.success(res.data.message);
        } else {
          const res = await courseService.fetcheditCourse(
            getToken(),
            value.course_id,
            body
          );
          toast.success(res.data.message);
        }
        props.history.push('/tokoscholar/courses');
      } catch (error) {
        console.log(error, 'error');
        toast.error(error.response.data.message);
      }
    },
  });

  const sylabusUrlSetter = (value) => {
    if(typeof value === 'string') setSyllabus({
      status: 'not edited',
      url: value
    })
    else {
      var reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = function() {
        if (value) {
          setSyllabus({
            status: 'edited',
            url: reader.result
          })
        }
      };
      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
    }
  }

  //   Goals Handler -------------------------
  const handleAddGoals = () => {
    const _fields = [...CourseForm.values.goals];
    _fields.push({ description_en: '', description_id: '' });
    CourseForm.setFieldValue('goals', _fields);
  };

  const handleRemove = (i) => {
    const _fields = [...CourseForm.values.goals];
    _fields.splice(i, 1);
    CourseForm.setFieldValue('goals', _fields);
  };

  const onChangeDescriptionGoals = (i, e) => {
    const goals = CourseForm.values.goals;
    const _goal = [...goals];
    _goal[i][e.target.name] = e.target.value;
    CourseForm.setFieldValue('goals', _goal);
  };
  //   -----------------------------------

  // course image file handler
  const onChangeFileCourse = (e) => {
    let file = e.target.files[0];
    var reader = new FileReader();
    CourseForm.setFieldValue('course_image', file);
    reader.readAsDataURL(file);
    reader.onload = function() {
      if (file) {
        var strImage = reader.result;
        setnewImage(strImage);
      }
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };

  // handle submit
  const handleSubmit = () => {
    CourseForm.handleSubmit();
  };

  return (
    <form className='m-login__form m-form'>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Course Name (EN) <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_en'
              isInvalid={
                CourseForm.errors.course_name_en &&
                CourseForm.touched.course_name_en
              }
              placeholder='Course Name (EN)'
              value={CourseForm.values.course_name_en}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.course_name_en &&
              CourseForm.touched.course_name_en && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.course_name_en}
                </Form.Text>
              )}
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Course Name (ID) <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_id'
              isInvalid={
                CourseForm.errors.course_name_id &&
                CourseForm.touched.course_name_id
              }
              placeholder='Course Name (ID)'
              value={CourseForm.values.course_name_id}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.course_name_id &&
              CourseForm.touched.course_name_id && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.course_name_id}
                </Form.Text>
              )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Course Image <span className='text-danger'>*</span>
            </Form.Label>
            <div className='d-flex justify-content-center align-items-center'>
              <Form.Control
                disabled={props.isEdit ? true : false}
                className='form-control'
                type='file'
                onChange={(e) => onChangeFileCourse(e)}
                accept='image/*'
              />
            </div>
            <Form.Text className='text-muted'>
              <span>*File type: Image, Max file size is 2MB</span>
            </Form.Text>
            {CourseForm.errors.course_image &&
              CourseForm.touched.course_image && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.course_image}
                </Form.Text>
              )}
          </Form.Group>
        </div>
        <div className='col-md-4 col-sm-12'>
          <img style={{ width: '100%' }} src={newImage || oldImage} alt='' />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Description (EN) <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              as='textarea'
              rows={4}
              placeholder={'Description (EN)'}
              name='description_en'
              isInvalid={
                CourseForm.errors.description_en &&
                CourseForm.touched.description_en
              }
              value={CourseForm.values.description_en}
              onChange={CourseForm.handleChange}
            />
            {CourseForm.errors.description_en &&
              CourseForm.touched.description_en && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.description_en}
                </Form.Text>
              )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Description (ID) <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              as='textarea'
              rows={4}
              placeholder={'Description (ID)'}
              name='description_id'
              isInvalid={
                CourseForm.errors.description_id &&
                CourseForm.touched.description_id
              }
              value={CourseForm.values.description_id}
              onChange={CourseForm.handleChange}
            />
            {CourseForm.errors.description_id &&
              CourseForm.touched.description_id && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.description_id}
                </Form.Text>
              )}
          </Form.Group>
        </div>
      </div>
      <Form.Group>
        <div className='row align-items-center'>
          <Form.Label className='font-weight-bold mr-3'>
            Goals <span className='text-danger'>*</span>{' '}
          </Form.Label>
          <div className=''>
            <button
              disabled={props.isEdit ? true : false}
              type='button'
              onClick={() => handleAddGoals()}
              className='btn btn-sm btn-outline-success'
            >
              Add
            </button>
          </div>
          <div className='col-md-12'>
            {CourseForm.values.goals.map((element, i) => {
              return (
                <div key={i} className='row mt-2 align-items-center'>
                  <div className='mr-5 font-weight-bold'>{i + 1}</div>
                  <div className='col-md-1'>
                    {props.isEdit ? (
                      <button
                        type='button'
                        onClick={() => null}
                        className='btn btn-sm btn-outline-danger mt-1'
                        disabled
                      >
                        Remove
                      </button>
                    ) : CourseForm.values.goals.length <= 1 ? (
                      <button
                        type='button'
                        onClick={() => null}
                        className='btn btn-sm btn-outline-danger mt-1'
                        disabled
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        type='button'
                        onClick={() => handleRemove(i)}
                        className='btn btn-sm btn-outline-danger mt-1'
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  <div className='col-md-5'>
                    <Form.Control
                      disabled={props.isEdit ? true : false}
                      as='textarea'
                      rows={4}
                      name='description_en'
                      placeholder='Goals Description (EN)'
                      isInvalid={
                        CourseForm.errors.goals &&
                        CourseForm.errors.goals.length > 0 &&
                        CourseForm.errors.goals[i] &&
                        CourseForm.touched.goals &&
                        CourseForm.touched.goals.length > 0 &&
                        CourseForm.touched.goals[i] &&
                        CourseForm.touched.goals[i].description_en
                      }
                      value={element.description_en}
                      onChange={(e) => onChangeDescriptionGoals(i, e)}
                    />
                    <div className='row'>
                      <div className='col-12'>
                        {CourseForm.errors.goals &&
                          CourseForm.errors.goals.length > 0 &&
                          CourseForm.errors.goals[i] &&
                          CourseForm.touched.goals &&
                          CourseForm.touched.goals.length > 0 &&
                          CourseForm.touched.goals[i] &&
                          CourseForm.touched.goals[i].description_en && (
                            <Form.Text className='text-danger'>
                              {CourseForm.errors.goals[i].description_en}
                            </Form.Text>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-5'>
                    <Form.Control
                      disabled={props.isEdit ? true : false}
                      as='textarea'
                      rows={4}
                      placeholder='Goals Description (ID)'
                      name='description_id'
                      isInvalid={
                        CourseForm.errors.goals &&
                        CourseForm.errors.goals.length > 0 &&
                        CourseForm.errors.goals[i] &&
                        CourseForm.touched.goals &&
                        CourseForm.touched.goals[i] &&
                        CourseForm.touched.goals.length > 0 &&
                        CourseForm.touched.goals[i].description_id
                      }
                      value={element.description_id}
                      onChange={(e) => onChangeDescriptionGoals(i, e)}
                    />
                    <div className='row'>
                      <div className='col-12'>
                        {CourseForm.errors.goals &&
                          CourseForm.errors.goals.length > 0 &&
                          CourseForm.errors.goals[i] &&
                          CourseForm.touched.goals &&
                          CourseForm.touched.goals[i] &&
                          CourseForm.touched.goals.length > 0 &&
                          CourseForm.touched.goals[i].description_id && (
                            <Form.Text className='text-danger'>
                              {CourseForm.errors.goals[i].description_id}
                            </Form.Text>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Form.Group>
      <div className='row'>
        <div className='col-md-12 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Target Participant (EN) <span className='text-danger'>*</span>
            </Form.Label>
            <CKEditor
              onChange={(e) => {
                const data = e.editor.getData();
                CourseForm.setFieldValue('target_participant_en', data);
              }}
              onError={(e) => {
                console.log(e, 'error');
              }}
              data={CourseForm?.values?.target_participant_en || ''}
              config={{
                toolbar: [['NumberedList', 'BulletedList']],
                removeButtons: 'Table',
              }}
            />
            {CourseForm.errors.target_participant_en &&
              CourseForm.touched.target_participant_en && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.target_participant_en}
                </Form.Text>
              )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Target Participant (ID) <span className='text-danger'>*</span>
            </Form.Label>
            <CKEditor
              onChange={(e) => {
                const data = e.editor.getData();
                CourseForm.setFieldValue('target_participant_id', data);
              }}
              onError={(e) => {
                console.log(e, 'error');
              }}
              data={CourseForm?.values?.target_participant_id || ''}
              config={{
                toolbar: [['NumberedList', 'BulletedList']],
                removeButtons: 'Table',
              }}
            />

            {CourseForm.errors.target_participant_id &&
              CourseForm.touched.target_participant_id && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.target_participant_id}
                </Form.Text>
              )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Upload File</Form.Label>
            <div className='d-flex justify-content-center align-items-center'>
              <Form.Control
                ref={uploadFileRef}
                disabled={props.isEdit ? true : false}
                className='form-control'
                type='file'
                accept='.doc,.docx,application/pdf'
                onChange={(e) =>{
                  CourseForm.setFieldValue('upload_file', e.target.files[0])
                  sylabusUrlSetter(e.target.files[0])
                }}
                value={CourseForm.values.upload_file ? uploadFileRef.current?.value : ''}
              />
              {CourseForm.values.upload_file &&
                <button
                  class='btn btn-outline-danger ml-2'
                  type='button'
                  onClick={() => {
                    setSyllabus({
                      status: 'removed',
                      url: ''
                    })
                    CourseForm.setFieldValue('upload_file', null)
                  }}
                >
                  Remove
                </button>
              }
            </div>
            <Form.Text className='text-muted'>
              <span>File type: Document Type, PDF</span>
            </Form.Text>
            {CourseForm.errors.upload_file &&
              CourseForm.touched.upload_file && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.upload_file}
                </Form.Text>
              )}
            {syllabus.url && !syllabus.edited &&
              <div className='mt-3'>
                <a href={syllabus.url} target="_blank">
                  Current Syllabus
                </a>
              </div>
            }
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='d-block'>Show on Homepage :</Form.Label>
            <Form.Check
              inline
              label='Yes'
              type='radio'
              value='1'
              checked={CourseForm.values.showOnHomepage === '1'}
              onChange={(e) =>
                CourseForm.setFieldValue('showOnHomepage', e.target.value)
              }
            />
            <Form.Check
              inline
              label='No'
              type='radio'
              value='0'
              checked={CourseForm.values.showOnHomepage === '0'}
              onChange={(e) =>
                CourseForm.setFieldValue('showOnHomepage', e.target.value)
              }
            />
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Certificate (EN)
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='certificate_en'
              isInvalid={
                CourseForm.errors.certificate_en &&
                CourseForm.touched.certificate_en
              }
              placeholder='Certificate Completion'
              value={CourseForm.values.certificate_en}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.certificate_en &&
              CourseForm.touched.certificate_en && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.certificate_en}
                </Form.Text>
              )}
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Certificate (ID)
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='certificate_id'
              isInvalid={
                CourseForm.errors.certificate_id &&
                CourseForm.touched.certificate_id
              }
              placeholder='Sertifikat Kelulusan'
              value={CourseForm.values.certificate_id}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.certificate_id &&
              CourseForm.touched.certificate_id && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.certificate_id}
                </Form.Text>
              )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Rewards (EN)</Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='rewards_en'
              isInvalid={
                CourseForm.errors.rewards_en && CourseForm.touched.rewards_en
              }
              placeholder='TKO'
              value={CourseForm.values.rewards_en}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.rewards_en && CourseForm.touched.rewards_en && (
              <Form.Text className='text-danger'>
                {CourseForm.errors.rewards_en}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Rewards (ID)</Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='rewards_id'
              isInvalid={
                CourseForm.errors.rewards_id && CourseForm.touched.rewards_id
              }
              placeholder='TKO'
              value={CourseForm.values.rewards_id}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.rewards_id && CourseForm.touched.rewards_id && (
              <Form.Text className='text-danger'>
                {CourseForm.errors.rewards_id}
              </Form.Text>
            )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Difficulties <span className='text-danger'>*</span>{' '}
            </Form.Label>
            <select
              className='form-control'
              onChange={(e) =>
                CourseForm.setFieldValue('diffculities', e.target.value)
              }
              // disabled={i === 0 ? true : false}
            >
              <option
                selected={CourseForm.values.diffculities === '1'}
                value='1'
              >
                Beginner
              </option>
              <option
                selected={CourseForm.values.diffculities === '2'}
                value='2'
              >
                Intermediate
              </option>
              <option
                selected={CourseForm.values.diffculities === '3'}
                value='3'
              >
                Advance
              </option>
            </select>

            {CourseForm.errors.diffculities &&
              CourseForm.touched.diffculities && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.diffculities}
                </Form.Text>
              )}
          </Form.Group>
        </div>

        <div className='col-md-3 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Time Allocation <span className='text-danger'>*</span>{' '}
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='time_allocation'
              placeholder='3 months'
              isInvalid={
                CourseForm.errors.time_allocation &&
                CourseForm.touched.time_allocation
              }
              value={CourseForm.values.time_allocation}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.time_allocation &&
              CourseForm.touched.time_allocation && (
                <Form.Text className='text-danger'>
                  {CourseForm.errors.time_allocation}
                </Form.Text>
              )}
          </Form.Group>
        </div>
        <div className='col-md-3 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Sorting No. <span className='text-danger'>*</span>{' '}
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='number'
              name='sorting_no'
              min='1'
              isInvalid={
                CourseForm.errors.sorting_no && CourseForm.touched.sorting_no
              }
              value={CourseForm.values.sorting_no}
              onChange={CourseForm.handleChange}
              // required
            />
            {CourseForm.errors.sorting_no && CourseForm.touched.sorting_no && (
              <Form.Text className='text-danger'>
                {CourseForm.errors.sorting_no}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className='col-md-3 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Status <span className='text-danger'>*</span>{' '}
            </Form.Label>
            <select
              className='form-control'
              onChange={(e) =>
                CourseForm.setFieldValue('status', e.target.value)
              }
              // disabled={i === 0 ? true : false}
            >
              <option selected={CourseForm.values.status === '1'} value='1'>
                Enabled
              </option>
              <option selected={CourseForm.values.status === '0'} value='0'>
                Disabled
              </option>
            </select>
            {CourseForm.errors.status && CourseForm.touched.status && (
              <Form.Text className='text-danger'>
                {CourseForm.errors.status}
              </Form.Text>
            )}
          </Form.Group>
        </div>
      </div>
    </form>
  );
};

export default CustomForm;
