import axios from "axios";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { IGetCsRequest, IGetCsResponse, IUpdateStatusRequest } from "./cs-request-form-types";
import { PaginationResponse, ResponseAPI } from "./global-types";
import { cancelTokenConfiguration } from "./instanceWithCancelToken";

const history =  createBrowserHistory();

export const baseURL = process.env.REACT_APP_ENV === 'demo' ? "https://service-form-demo.tokocrypto.com/admin/request-form/" : "https://service-form.tokocrypto.com/admin/request-form/"

const instance = (isCancelToken?: boolean, isUnauthorized?: boolean) => {
  const i = axios.create({
    baseURL,
  });
  
  if(!isUnauthorized) {
    i.interceptors.request.use((config) => {
      if(config.headers) {
        config.headers.Authorization = localStorage.getItem("token");
        config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
      }
      if(isCancelToken) cancelTokenConfiguration(config)
      return config
    })
    
    i.interceptors.response.use(config => config, (err) => {
      if(err.response){
        if(err.response.status === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
        }
      }
      
      return Promise.reject(err)
    })
  }
  
  return i
}

const i = instance()

export const getRequests = (params: IGetCsRequest) => i.get<ResponseAPI<PaginationResponse<IGetCsResponse>>>("/list", {params})
export const getDetailRequest = (id: string) => i.get<ResponseAPI<IGetCsResponse>>(`${id}/details`)
export const updateDetailRequest = (id: string, payload: IUpdateStatusRequest) => i.patch<ResponseAPI<boolean>>(`${id}/status`, payload)
export const getRawImage = (path: string) => i.get<File>('/get-image', {params: { path }, responseType: 'arraybuffer'})