import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import thubActions from '../../../crud/thub';
import {
  Button,
  Table,
  Modal,
  Badge,
  Tab,
  Tabs,
  Image,
} from 'react-bootstrap';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
import { useDebounce } from './eventTags.constant';
require('bootstrap/scss/bootstrap.scss');
require('./eventTags.scss');

const history = createBrowserHistory();
const ThubEventTagsList = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const [stringToken, setStringToken] = useState();
  const [filterData, setFilterData] = useState({
    tag_name: '',
    display_for: '',
    status: '',
    limitData: 10,
  });
  const [tagsDetail, setTagsDetail] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [tagName, setTagName] = useState('');

  const [tagsData, setTagsData] = useState({
    data: [],
    currentPage: 1,
    totalData: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });

  const getTagsListData = async (page) => {
    if (stringToken) {
      const { tag_name, display_for, status, limitData } = filterData;
      const params = `?page=${page ||
        1}&limit=${limitData}${tag_name &&
        `&tag_name=${tag_name}`}${display_for &&
        `&display_for=${display_for}`}${status &&
        `&status=${status}`}`;
      console.log(params);
      try {
        setLoading(true);
        const response = await thubActions.fetchThubEventTagsList(
          stringToken,
          params
        );

        const { data } = response.data;

        setTagsData((prev) => ({
          ...prev,
          data: data.event_tags,
          currentPage: data.currentPage,
          totalData: data.totalData,
          totalDataOnPage: data.totalDataOnPage,
          totalPages: data.totalPages,
        }));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setTagsData([]);
        if (e.response) {
          if (
            e.response.data.code === 401 ||
            e.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else if (e.response.data.code === 404) {
            return;
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      }
    }
  };

  const getTagsDetail = async (tagId) => {
    setLoadingDetailData(true);
    await thubActions
      .fetchThubTagsDetail(stringToken, tagId)
      .then((res) => {
        setTagsDetail(res.data.data);
        setLoadingDetailData(false);
      })
      .catch((e) => {
        setLoadingDetailData(false);
        setTagsDetail([]);
        if (e.response) {
          if (
            e.response.data.code === 401 ||
            e.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getDateTime = (date) => {
    const fmtDate = moment(date).format('DD MMM YYYY HH:mm');

    return fmtDate;
  };

  const getStatus = (id) => {
    switch (id) {
      case 0:
        return 'Inactive';
      case 1:
        return 'Active';

      default:
        return 'Inactive';
    }
  };

  const getBadgeColor = (id) => {
    switch (id) {
      case 0:
        return 'danger';
      case 1:
        return 'success';

      default:
        return 'primary';
    }
  };

  const handleChangeFilter = (name, value) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePageChange = (page) => {
    setTagsData((prev) => ({
      ...prev,
      currentPage: page,
    }));
    getTagsListData(page);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getTagsListData();
    }
  }, [stringToken]);

  // this for debounce filtering data so it wont repeatedly request to api for every changes in filter field
  const debouncedFilterData = useDebounce(filterData, 1000);
  useEffect(() => {
    if (debouncedFilterData) {
      getTagsListData();
    }
  }, [debouncedFilterData]);

  console.log(filterData);
  return (
    <div style={{ fontSize: '11px' }} className="head">
      <div className="kt-portlet">
        <div
          className="kt-portlet__body pddmdForce"
          style={{ padding: '0 20px 20px 20px' }}
        >
          <div className="kt-portlet__preview">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <label
                  className="col-md-12"
                  style={{ paddingLeft: '0' }}
                >
                  Filter By Tag Name
                </label>
                <input
                  type="text"
                  className="form-control mr-3"
                  name="tag_name"
                  placeholder="eg: kriptoversity, without #"
                  value={filterData.tag_name}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>

              <div className="col-md-3 col-sm-12">
                <label
                  className="col-md-12"
                  style={{ paddingLeft: '0' }}
                >
                  Filter By Status
                </label>
                <select
                  className="form-control mr-3"
                  name="status"
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                >
                  <option selected disabled>
                    Select Status
                  </option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="kt-portlet kt-portlet-top">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <div className="paddupgrade">
              <div className="collaboration-menu__top">
                <Link
                  to="/thub/event/tags/create"
                  className="kt-link"
                >
                  <Button
                    variant="success"
                    style={{ fontSize: '11px' }}
                  >
                    Create New Tag
                  </Button>
                </Link>
                <select
                  className="form-control collaboration-limit"
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                  name="limitData"
                >
                  <option value={5}>5</option>
                  <option selected value={10}>
                    10
                  </option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="kt-portlet kt-portlet-top">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <div className="kt-section" style={{ marginBottom: 0 }}>
              {/* <div className="paddupgrade">
                <Link
                  to="/thub/booking-order/create"
                  className="kt-link"
                >
                  <Button
                    variant="success"
                    style={{ fontSize: '11px' }}
                  >
                    Create Booking Order
                  </Button>
                </Link>
              </div> */}
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th
                          className="tableheh"
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          No
                        </th>
                        <th
                          className="tableheh"
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Tag Name
                          <br />
                        </th>
                        {/* <th
                          className="tableheh"
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Tagline 
                        </th> */}

                        <th
                          className="tableheh"
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Display for
                        </th>
                        <th
                          className="tableheh"
                          style={{ verticalAlign: 'middle' }}
                        >
                          Status
                          {/* <button
                            onClick={(e) =>
                              this.onSortChange('email')
                            }
                            className="sort-button"
                          >
                            <i
                              className={`fas fa-${
                                sortTypes[this.state.orderSort].class
                              }`}
                            />
                          </button> */}
                        </th>
                        <th
                          className="tableheh"
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Last Updated
                        </th>
                        <th
                          className="tableheh"
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Last Updated by
                        </th>

                        <th className="tableheh">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tagsData && tagsData.length === 0 && (
                        <tr style={{ textAlign: 'center' }}>
                          <td colSpan={9}>No records data found</td>
                        </tr>
                      )}
                      {tagsData &&
                        tagsData.data &&
                        tagsData.data.length > 0 &&
                        tagsData.data.map((row, i) => (
                          <tr key={row._id}>
                            <td>{i + 1}</td>
                            <td>{row.tag_name || '-'}</td>
                            <td>{row.display_for.join(', ')}</td>
                            <td>
                              <Badge
                                bg={getBadgeColor(row.status)}
                              >
                                {getStatus(row.status)}
                              </Badge>
                            </td>
                            <td>{getDateTime(row.updated_at)}</td>
                            <td>{row.updated_by || '-'}</td>

                            <td className="center">
                              <div>
                                {' '}
                                <span
                                  onClick={() => {
                                    getTagsDetail(row.event_tag_id);
                                    setTagName(row.tag_name);
                                    setShowDetailModal(
                                      !showDetailModal
                                    );
                                  }}
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className="btn btn-outline-primary"
                                >
                                  View Detail
                                </span>
                                <br />
                                <Link
                                  to={`/thub/event/tags/${row.event_tag_id}/edit`}
                                >
                                  <span
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className="btn btn-outline-success"
                                  >
                                    Edit Tag
                                  </span>
                                </Link>
                                <br />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {tagsData.data && tagsData.data.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center pagination-data">
                      <Pagination
                        activePage={tagsData.currentPage}
                        itemsCountPerPage={tagsData.totalDataOnPage}
                        itemClass="page-item"
                        linkClass="page-link"
                        totalItemsCount={tagsData.totalData}
                        pageRangeDisplayed={
                          tagsData.totalDataOnPage >=
                          filterData.limitData
                            ? tagsData.totalPages
                            : 1
                        }
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          {tagsDetail &&
            typeof tagsDetail.event_tag_id !== 'undefined' && (
              <Modal.Title>{tagName}</Modal.Title>
            )}
        </Modal.Header>
        <Modal.Body>
          {loadingDetailData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type={'bars'}
                color={'#0000FF'}
                height={'2%'}
                width={'5%'}
              />
            </div>
          ) : (
            <>
              {tagsDetail &&
                typeof tagsDetail.event_tag_id !== 'undefined' && (
                  <Tabs
                    id="controlled-tab-example"
                    defaultActiveKey="detail"
                    // onSelect={key => this.setState({ key })}
                  >
                    <Tab eventKey="detail" title="Detail Tag">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Event Tag ID</strong>
                            </td>
                            <td colSpan={5}>
                              {tagsDetail.event_tag_id}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Tag Name</strong>
                            </td>
                            <td colSpan={5}>{tagsDetail.tag_name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Displayed For</strong>
                            </td>
                            <td colSpan={5}>
                              {tagsDetail.display_for.join(', ')}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Tag Status</strong>
                            </td>
                            <td colSpan={5}>
                              {getStatus(tagsDetail.status)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Created At</strong>
                            </td>
                            <td colSpan={5}>
                              {getDateTime(tagsDetail.created_at)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Updated At</strong>
                            </td>
                            <td colSpan={5}>
                              {getDateTime(tagsDetail.updated_at)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThubEventTagsList;
