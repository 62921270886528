import { useEffect, useState } from 'react';
import moment from 'moment';

export const getDateNow = () => {
  const dateData = moment()
    .toDate()
    .toISOString()
    .split('T');
  const timeData = moment()
    .toDate()
    .toLocaleTimeString('id-ID')
    .split('.');
  const dateNow = dateData[0] + 'T' + timeData[0] + ':' + timeData[1];

  return dateNow;
};

// Hook
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const daysOption = [
  {
    label: 'Sunday',
    value: 'sunday',
  },
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
];
