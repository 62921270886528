import { subMonths, format } from "date-fns"
import { useFormik } from "formik"
import { FC, Fragment, ReactNode } from "react"
import { Modal } from "react-bootstrap"
import FormWithFormik from "../../components/reusableFormWithFormik"
import ReusableTable from "../../components/reusableTable"
import { Pagination, PaginationRequest } from "../../crud/global-types"
import { getGeneralReports } from "../../crud/helpdesk"
import { GeneralReportResponse, PriorityRequest, StatusRequest } from "../../crud/helpdesk-types"
import { capitalizeEveryWord, globalDateTime, useDebounce, useMultipleState } from "../../helper"
import { PopoverAction, RowWrapper } from "../user-new/user"

interface InitialState extends Pagination<GeneralReportResponse>, PaginationRequest {
  loading: boolean;
  selectedDetail?: GeneralReportResponse;
}

interface IForm {
  keyword: string;
  start_date?: Date | null;
  end_date?: Date | null;
}

interface DetailRenderer {
  key: keyof GeneralReportResponse;
  renderer?: (data: GeneralReportResponse) => ReactNode;
}

const initialState: InitialState = {
  loading: false,
  total_data: 0,
  total_page: 1,
  limit: 5,
  page: 1,
  data: [],
}

const GeneralReport: FC = () => {
  const config = useFormik<IForm>({
    initialValues: {
      start_date: subMonths(new Date(), 1),
      end_date: new Date(),
      keyword: "",
    },
    onSubmit: () => {}
  })
  const [state, dispatch] = useMultipleState<InitialState>(initialState)
  
  const generalReportService = (q: string, limit: number, page: number, start_date?: Date | null, end_date?: Date | null) => {
    getGeneralReports({
      limit,
      page,
      q,
      start_date: start_date ? format(start_date, "yyyy-MM-dd") : undefined,
      end_date: end_date ? format(end_date, "yyyy-MM-dd") : undefined,
    })
      .then(res => dispatch({
        data: res.data.data?.data,
        total_data: res.data.data?.total_data,
        total_page: res.data.data?.total_page
      }))
      .catch(err => console.log(err))
      .finally(()=>dispatch({loading: false}))
  }

  useDebounce<[string, number, number, Date | null | undefined, Date | null | undefined]>(generalReportService, [config.values.keyword, state.limit, state.page, config.values.start_date, config.values.end_date], 400, () => dispatch({loading: true}))
  
  const detailRenderer = () => {
    if(state.selectedDetail) {
      const renderer: Array<DetailRenderer> = [
        { key: "title" },
        { key: "priority", renderer: (data) => Object.entries(PriorityRequest).find(([_, value]) => value === data.priority)?.[0] },
        { key: "doc_url", renderer: (data) => data.doc_url ? <a href={data.doc_url} target="_blank" /> : null },
        { key: "created_at", renderer: (data) => data.created_at ? globalDateTime(data.created_at) : null },
        { key: "updated_at", renderer: (data) => data.updated_at ? globalDateTime(data.updated_at) : null },
        { key: "additional_information", renderer: (data) => data.additional_information ? <div dangerouslySetInnerHTML={{__html: data.additional_information}} /> : null },
        { key: "start_date", renderer: (data) => data.start_date ? globalDateTime(data.start_date) : null },
        { key: "end_date", renderer: (data) => data.end_date ? globalDateTime(data.end_date) : null },
        { key: "unique_code" },
        { key: "note" },
        { key: "order" },
        { 
          key: "status", 
          renderer: (data) => {
            const selectedStatus = Object.entries(StatusRequest).find(([_, value]) => value === data.status)?.[0]

            if(selectedStatus) return selectedStatus.replace(/_/g, " ")
            else return null
          }
        },
      ]

      return renderer.map((r, index) => (
        <div className="custom-flex-row-space-between" key={index}>
          <b>{capitalizeEveryWord(r.key.replace(/_/g," "))}</b>
          <span>{r.renderer ? r.renderer(state.selectedDetail!!) : state.selectedDetail!![r.key]}</span>
        </div>
      ))
    }
    else return null
  }
  
  return (
    <Fragment>
      <div className="custom-flex-col-center gap-2">
        <FormWithFormik 
          type="TEXT_INPUT"
          config={config}
          name="keyword"
          placeholder="Search by keyword"
          label="Search"
        />
        <div className="custom-flex-row-center gap-2 w-100">
          <FormWithFormik 
            type="DATE"
            config={config}
            name="start_date"
            placeholderText="Start Date"
            label="Start Date"
            isClearable
          />
          <FormWithFormik 
            type="DATE"
            config={config}
            name="end_date"
            placeholderText="End Date"
            label="End Date"
            isClearable
          />
        </div>
      </div>
      <ReusableTable<GeneralReportResponse>
        className="mt-3"
        loading={state.loading}
        dataSources={state.data}
        columns={[
          {
            dataIndex: "title",
            title: "Title",
            render: (_, data) => {
              return (
                <RowWrapper position="LEFT">
                  <div className="mt-3 ml-3">
                    <div className="new-version-kyc-table-label">Title</div>
                    <div>{data.title}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "priority",
            title: "Priority",
            render: (_, data) => {
              return (
                <RowWrapper>
                  <div className="mt-3">
                    <div className="new-version-kyc-table-label">Priority</div>
                    <div>{Object.entries(PriorityRequest).find(([_, value]) => value === data.priority)?.[0]}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "start_date",
            title: "Start Date",
            render: (_, data) => {
              return (
                <RowWrapper>
                  <div className="mt-3">
                    <div className="new-version-kyc-table-label">Start Date</div>
                    <div>{data.start_date ? globalDateTime(data.start_date) : "-"}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "end_date",
            title: "End Date",
            render: (_, data) => {
              return (
                <RowWrapper>
                  <div className="mt-3">
                    <div className="new-version-kyc-table-label">End Date</div>
                    <div>{data.end_date ? globalDateTime(data.end_date) : "-"}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "unique_code",
            title: "Unique Code",
            render: (_, data) => {
              return (
                <RowWrapper>
                  <div className="mt-3">
                    <div className="new-version-kyc-table-label">Unique Code</div>
                    <div>{data.unique_code}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "id",
            title: "Action",
            render: (_, data) => {
              return (
                <RowWrapper position="RIGHT" className="custom-flex-row-center">
                  <PopoverAction label="Open Detail Report">
                    <button className={`new-version-user-action-btn new-version-user-action-btn-none`} onClick={()=>dispatch({selectedDetail: data})}><span className="far fa-eye" /></button>
                  </PopoverAction>
                </RowWrapper>
              )
            }
          }
        ]}
        pagination={{
          itemsCountPerPage: state.limit,
          totalItemsCount: state.total_data,
          onChange: (page) => dispatch({page}),
          activePage: state.page
        }}
        withoutDefaultStyles
        withoutHeader
      />
      <Modal
        show={!!state.selectedDetail}
        onHide={() => dispatch({selectedDetail: undefined})}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>General Report Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-flex-col gap-3">
          {detailRenderer()}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default GeneralReport