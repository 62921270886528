import React, { useState, useEffect, Fragment, useRef } from 'react';
import CKEditor from 'ckeditor4-react';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
// import Select from 'react-select'
import './events.scss';
import 'react-datepicker/dist/react-datepicker.css';
import {
  categoryOption,
  getDateNow,
  locationTypeValidationSchema,
  offlineValidationSchema,
  onlineValidationSchema,
} from './events.constant';
import { ArrowBack } from '@material-ui/icons';
import { debounce } from 'lodash';
import moment from 'moment';
require('bootstrap/scss/bootstrap.scss');

const ThubEventsCreate = (props) => {
  const [loading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [stringToken, setStringToken] = useState();
  const [seatList, setSeatList] = useState();
  const [bookingTypeList, setBookingTypeList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCheckEvents, setIsCheckEvents] = useState(false);
  const [paymentProductList, setPaymentProductList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [isOffline, setIsOffline] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

  const [tagsData, setTagsData] = useState([]);

  const submitEvents = async (data) => {
    let type = '';
    if (isOnline && isOffline) {
      type = 'online, offline';
    } else if (isOnline && !isOffline) {
      type = 'online';
    } else if (!isOnline && isOffline) {
      type = 'offline';
    }
    const formData = { ...data, location_type: type };
    setLoading(true);
    try {
      const response = await thubActions.fetchThubEventsCreate(
        stringToken,
        formData,
        isOnline,
        isOffline
      );

      if (response.data.code === 200 || response.data.code === 201) {
        toast.success('Add new event success');
        if (data.proposal && data.proposal.length > 0) {
          if (isOffline) {
            await addEventsProposal(response.data.data.event.event_id, data);
          } else {
            await addEventsProposal(response.data.data.event_id, data);
          }
        }
        toast.success('Successfully create events');

        setLoading(false);
        setTimeout(() => {
          props.history.push('/thub/events');
          // window.location.reload();
        }, 1500);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          props.history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const addEvents = async (data) => {
    if (isOnline && isOffline) {
      if (isCheckEvents) {
        submitEvents(data);
      } else {
        checkSeatAvailibilty();
      }
    } else if (isOnline && !isOffline) {
      submitEvents(data);
    } else if (!isOnline && isOffline) {
      if (isCheckEvents) {
        submitEvents(data);
      } else {
        checkSeatAvailibilty();
      }
    }
  };

  const addEventsProposal = async (eventId, formData) => {
    setLoading(true);
    try {
      const response = await thubActions.fetchThubEventsUploadProposal(
        stringToken,
        eventId,
        formData
      );
      if (response.data.code === 200 || response.data.code === 201) {
        toast.success('Successfully submitted events proposal');

        // setLoading(false);
        // setTimeout(() => {
        //   props.history.push('/thub/events');
        //   window.location.reload();
        // }, 1500);
      }
    } catch (e) {
      setLoading(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();

          setTimeout(() => {
            props.history.push('/auth');
            window.location.reload();
          }, 1500);
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const getUserList = async () => {
    setLoading(true);
    await thubActions
      .fetchThubUserAll(stringToken, { limit: 15 })
      .then((res) => {
        const { data } = res.data;

        let userArray = [];
        if (data && data.users && data.users.length > 0) {
          data.users.forEach((element) => {
            userArray.push({
              label: `${element.first_name} ${element.last_name} - ${element.email}`,
              value: element.user_id,
            });
          });
        }

        setUserList(userArray);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setUserList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();

            setTimeout(() => {
              props.history.push('/auth');
              window.location.reload();
            }, 1500);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getLocationList = async () => {
    setLoading(true);
    try {
      const params = '?type_name=multi function';
      const response = await thubActions.fetchThubBookingTypeList(
        stringToken,
        params
      );
      const { bookingTypes } = response.data.data;
      setLocationList(bookingTypes);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setLocationList([]);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          props.history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const getBookingTypeList = async (locationId) => {
    setLoading(true);
    const params = `?page=1&limit=9999&location_id=${locationId}`;
    thubActions
      .fetchThubBookingTypeList(stringToken, params)
      .then((res) => {
        setBookingTypeList(res.data.data.bookingTypes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setBookingTypeList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();

            setTimeout(() => {
              props.history.push('/auth');
              window.location.reload();
            }, 1500);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getSeatList = async (bookingTypeId) => {
    setLoading(true);

    thubActions
      .fetchThubSeatListByBookingType(stringToken, bookingTypeId)
      .then((res) => {
        setSeatList(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setSeatList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();

            setTimeout(() => {
              props.history.push('/auth');
              window.location.reload();
            }, 1500);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getPaymentProductList = async () => {
    setLoading(true);
    thubActions
      .fetchThubPaymentProductList(stringToken)
      .then((res) => {
        setPaymentProductList(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setPaymentProductList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();

            setTimeout(() => {
              props.history.push('/auth');
              window.location.reload();
            }, 1500);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getTagsListData = async () => {
    if (stringToken) {
      // const params = `?page=1&limit=15`;
      try {
        setLoading(true);
        const _params = `?limit=15&display_for=admin&status=1`;
        const response = await thubActions.fetchThubEventTagsList(
          stringToken,
          _params
        );

        const { data } = response.data;

        let tagsArray = [];
        if (data && data.event_tags && data.event_tags.length > 0) {
          data.event_tags.forEach((element) => {
            tagsArray.push({
              label: element.tag_name,
              value: element.tag_name,
            });
          });
        }

        setTagsData(tagsArray);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setTagsData([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else if (e.response.data.code === 404) {
            return;
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      }
    }
  };

  const checkSeatAvailibilty = async () => {
    if (isOnline && isOffline) {
      const { values } = eventsFormik;
      const combinedStartDate = values.start_date.split('T').join(' ');
      const combinedEndDate = values.end_date.split('T').join(' ');

      const newCombinedStartDate = moment(combinedStartDate)
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');
      const newCombinedEndDate = moment(combinedEndDate)
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');

      const params = `?start_date=${newCombinedStartDate}&end_date=${newCombinedEndDate}&booking_type_id=${values.booking_type_id}&seat_id=${values.seat_id}`;
      setLoading(true);

      await thubActions
        .fetchThubCheckSeatAvailibility(stringToken, params)
        .then((res) => {
          if (res.data && res.data.data !== true) {
            setIsDisabled(false);
            setLoading(false);
            setIsCheckEvents(true);
            toast.success('Seat/Room is Available');
          } else {
            setLoading(false);
            setIsDisabled(true);
            setIsCheckEvents(false);
            toast.error('Seat/Room is not Available');
          }
        })
        .catch((e) => {
          setLoading(false);
          setIsDisabled(true);
          setIsCheckEvents(false);
          if (e.response) {
            if (e.response.data.code === 401 || e.response.data.code === 500) {
              toast.error('token has expired');
              localStorage.clear();

              setTimeout(() => {
                props.history.push('/auth');
                window.location.reload();
              }, 1500);
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    } else if (isOffline) {
      const { values } = eventsFormik;
      const combinedStartDate = values.start_date.split('T').join(' ');
      const combinedEndDate = values.end_date.split('T').join(' ');
      const newCombinedStartDate = moment(combinedStartDate)
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');
      const newCombinedEndDate = moment(combinedEndDate)
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');

      const params = `?start_date=${newCombinedStartDate}&end_date=${newCombinedEndDate}&booking_type_id=${values.booking_type_id}&seat_id=${values.seat_id}`;
      setLoading(true);

      await thubActions
        .fetchThubCheckSeatAvailibility(stringToken, params)
        .then((res) => {
          if (res.data && res.data.data !== true) {
            setIsDisabled(false);
            setLoading(false);
            setIsCheckEvents(true);
            toast.success('Seat/Room is Available');
          } else {
            setLoading(false);
            setIsDisabled(true);
            setIsCheckEvents(false);
            toast.error('Seat/Room is not Available');
          }
        })
        .catch((e) => {
          setLoading(false);
          setIsDisabled(true);
          setIsCheckEvents(false);
          if (e.response) {
            if (e.response.data.code === 401 || e.response.data.code === 500) {
              toast.error('token has expired');
              localStorage.clear();

              setTimeout(() => {
                props.history.push('/auth');
                window.location.reload();
              }, 1500);
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    } else {
      setIsCheckEvents(true);
      toast.success('Event time is Available');
    }
  };

  const validationSchemaType = () => {
    if (isOffline && isOnline) {
      return locationTypeValidationSchema;
    } else if (!isOffline && isOnline) {
      return onlineValidationSchema;
    } else if (isOffline && !isOnline) {
      return offlineValidationSchema;
    } else {
      return locationTypeValidationSchema;
    }
  };

  const eventsFormik = useFormik({
    initialValues: {
      user_id: '',
      event_code: '',
      event_title: '',
      event_tag: '',
      category: '',
      event_type: '',
      speakers: [],
      event_description: '',
      location_id: '',
      booking_type_id: '',
      seat_id: '',
      proposal: '',
      image: '',
      participant_quota: '',
      start_date: '',
      end_date: '',
      additional_note: '',
      allow_collaboration: true,
      submitted_by: 'admin',
      what_people_will_learn: '',
      skill_level: '',
      special_request: '',
      product_code: '',
      meeting_link: [],
      // event_recurring: '',
    },
    validationSchema: validationSchemaType,
    onSubmit: (values) => {
      addEvents(values);
    },
  });

  const handleChangeMultiSelect = (e, fieldName) => {
    console.log(e);
    const listData =
      (Array.isArray(e) &&
        e.reduce(
          (prev, cur) =>
            prev === '' ? (prev = cur.value) : (prev += ', ' + cur.value),
          ''
        )) ||
      '';

    eventsFormik.setFieldValue(fieldName, listData);
  };

  const handleChangeFiles = (event) => {
    let imageData = [];
    for (let i = 0; i < event.target.files.length; i++) {
      imageData = [...imageData, event.target.files[i]];
    }

    setFieldValue(event.target.name, imageData);
  };

  const handleAddSpeakers = () => {
    const _fields = [...eventsFormik.values.speakers];
    _fields.push({
      fullname: '',
      tittle: '',
      about: '',
      link_to_personal_data: '',
      instagram: '',
      twitter: '',
      portrait_image: '',
    });
    eventsFormik.setFieldValue('speakers', _fields);
  };

  const handleRemoveSpeakers = (i) => {
    const _fields = [...eventsFormik.values.speakers];
    _fields.splice(i, 1);
    eventsFormik.setFieldValue('speakers', _fields);
  };

  const handleAddMeetingLinks = () => {
    const _fields = [...eventsFormik.values.meeting_link];
    _fields.push({
      link: '',
      pass_code: '',
      platform: '',
    });
    eventsFormik.setFieldValue('meeting_link', _fields);
  };

  const handleRemoveMeetingLinks = (i) => {
    const _fields = [...eventsFormik.values.meeting_link];
    _fields.splice(i, 1);
    eventsFormik.setFieldValue('meeting_link', _fields);
  };

  const onChangePortraitImage = (i, e) => {
    const speakersValue = eventsFormik.values.speakers;
    const _speakersValue = [...speakersValue];

    // let imageData = [];
    // for (let i = 0; i < e.target.files.length; i++) {
    //   imageData = [...imageData, e.target.files[i]];
    // }

    _speakersValue[i]['portrait_image'] = e.target.files;

    setFieldValue('speakers', _speakersValue);
  };

  const handleChangeFields = (e) => {
    console.log(e.target.value, 'E');
    setFieldValue(e.target.name, e.target.value);
    setIsCheckEvents(false);
    setIsDisabled(true);
  };
  // thub users handle
  // const debounceProcess = useRef(
  //   debounce((stringToken, params) => {
  //     handleGetUserList(stringToken, params);
  //   }, 1000)
  // );

  const handleGetUserList = async (stringToken, params) => {
    try {
      const res = await thubActions.fetchThubUserAll(stringToken, {
        limit: 15,
        name_or_email: params.user_name,
      });
      const { data } = res.data;

      let userArray = [];
      if (data && data.users && data.users.length > 0) {
        data.users.forEach((element) => {
          userArray.push({
            label: `${element.first_name} ${element.last_name} - ${element.email}`,
            value: element.user_id,
          });
        });
      }

      return userArray;
    } catch (e) {
      console.log(e, 'Error');
      // if (e.response) {
      //   if (e.response.data.code === 401 || e.response.data.code === 500) {
      //     toast.error('token has expired');
      //     localStorage.clear();
      //     props.history.push('/auth');
      //     window.location.reload();
      //   } else {
      //     toast.error(
      //       `error code: ${e.response.data.code} message: ${e.response.data.message}`
      //     );
      //   }
      // }
      return [];
    }
  };

  const handleGetEventTagList = async (stringToken, params) => {
    try {
      const _params = `?limit=15&tag_name=${params.tag_name}&display_for=admin&status=1`;

      const res = await thubActions.fetchThubEventTagsList(
        stringToken,
        _params
      );
      const { data } = res.data;

      let userArray = [];
      if (data && data.event_tags && data.event_tags.length > 0) {
        data.event_tags.forEach((element) => {
          userArray.push({
            label: element.tag_name,
            value: element.tag_name,
          });
        });
      }

      return userArray;
    } catch (e) {
      console.log(e, 'Error');
      // if (e.response) {
      //   if (e.response.data.code === 401 || e.response.data.code === 500) {
      //     toast.error('token has expired');
      //     localStorage.clear();
      //     props.history.push('/auth');
      //     window.location.reload();
      //   } else {
      //     toast.error(
      //       `error code: ${e.response.data.code} message: ${e.response.data.message}`
      //     );
      //   }
      // }
      return [];
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise(async (resolve) => {
      const res = await handleGetUserList(stringToken, {
        user_name: inputValue,
      });
      // setTimeout(() => {
      if (res) {
        resolve(res);
      }
      // }, 1000);
    });

  const promiseOptionsEventTag = (inputValue) =>
    new Promise(async (resolve) => {
      const res = await handleGetEventTagList(stringToken, {
        tag_name: inputValue,
      });
      // setTimeout(() => {
      if (res) {
        resolve(res);
      }
      // }, 1000);
    });

  const handleChangeEditor = (e) => {
    const data = e.editor.getData();
    const name = e.editor.name;

    eventsFormik.setFieldValue(name, data);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getLocationList();
      getUserList();
      getPaymentProductList();
      getTagsListData();
    }
  }, [stringToken]);

  const { handleBlur, handleChange, errors, touched, values, setFieldValue } =
    eventsFormik;

  // created this because there is booking type data with same location id
  const locationListUniqueData = locationList &&
    locationList.length > 0 && [
      ...new Map(
        locationList.map((item) => [item['location_id'], item])
      ).values(),
    ];

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form
                className='m-login__form m-form'
                onSubmit={(e) => {
                  e.preventDefault();
                  eventsFormik.handleSubmit(e);
                }}
              >
                <div className='row'>
                  <div className='col-md-4'>
                    {userList && userList.length > 0 ? (
                      <Form.Group>
                        <Form.Label>User</Form.Label>
                        <AsyncSelect
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              width: '100%',
                            }),
                          }}
                          name='user_id'
                          // options={userList}
                          classNamePrefix='select'
                          onChange={(e) => {
                            setFieldValue('user_id', e.value);
                          }}
                          defaultOptions={userList}
                          loadOptions={(val) => promiseOptions(val)}
                          onBlur={handleBlur}
                          className={`${
                            touched.user_id && errors.user_id && 'error'
                          } basic-multi-select`}
                        />
                        {touched.user_id && errors.user_id && (
                          <div className='error-message'>{errors.user_id}</div>
                        )}
                      </Form.Group>
                    ) : (
                      <p>
                        You must create a user before creating a new booking
                        order
                      </p>
                    )}
                  </div>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Event Code</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter event code. Eg: NFTNC22'
                        maxLength={8}
                        value={values.event_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='event_code'
                        className={
                          touched.event_code && errors.event_code && 'error'
                        }
                      />
                      {touched.event_code && errors.event_code && (
                        <div className='error-message'>{errors.event_code}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-5'>
                    <Form.Group>
                      <Form.Label>Event Title</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter event title. Eg: Thub Events'
                        value={values.event_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='event_title'
                        className={
                          touched.event_title && errors.event_title && 'error'
                        }
                      />
                      {touched.event_title && errors.event_title && (
                        <div className='error-message'>
                          {errors.event_title}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <div className='input-group col-md-12 p-0'>
                        <div className='input-group'>
                          <Select
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '100%',
                              }),
                            }}
                            name='category'
                            isMulti
                            options={categoryOption}
                            classNamePrefix='select'
                            onChange={(e) =>
                              handleChangeMultiSelect(e, 'category')
                            }
                            onBlur={handleBlur}
                            className={`${
                              touched.category && errors.category && 'error'
                            } basic-multi-select`}
                          />
                        </div>
                      </div>
                      {touched.category && errors.category && (
                        <div className='error-message'>{errors.category}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Skill Level</Form.Label>
                      <Form.Control
                        as='select'
                        value={values.skill_level}
                        onChange={(e) => {
                          setFieldValue('skill_level', e.target.value);
                        }}
                        onBlur={handleBlur}
                        name='skill_level'
                        className={
                          touched.skill_level && errors.skill_level && 'error'
                        }
                      >
                        <option selected>Select Skill Level</option>
                        {['All level', 'Basic', 'Intermediate', 'Advanced'].map(
                          (data, i) => (
                            <option
                              selected={values.skill_level === data}
                              value={data}
                              key={i}
                            >
                              {data}
                            </option>
                          )
                        )}
                      </Form.Control>
                      {touched.skill_level && errors.skill_level && (
                        <div className='error-message'>
                          {errors.skill_level}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group style={{ position: 'relative', zIndex: 0 }}>
                      <Form.Label>Location Type</Form.Label>
                      <br />
                      <Form.Check
                        inline
                        type='switch'
                        id='online'
                        label='Online'
                        checked={isOnline}
                        onChange={() => {
                          // if (isOffline) {
                          //   setIsDisabled(true);
                          // } else {
                          //   setIsDisabled(false);
                          // }
                          setIsOnline(!isOnline);
                        }}
                      />
                      <Form.Check
                        inline
                        type='switch'
                        id='offline'
                        label='Offline'
                        checked={isOffline}
                        onChange={() => {
                          // setIsDisabled(true);
                          setIsOffline(!isOffline);
                        }}
                      />
                      {!isOffline && !isOnline && (
                        <div className='mt-2'>
                          <span style={{ fontSize: '13px', color: 'red' }}>
                            Location type option list must be chosen
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>

                {isOnline && (
                  <Form.Group>
                    <Form.Label>Meeting Links</Form.Label>
                    <div className='row mb-4'>
                      <div className='col-md-12 mb-2'>
                        <button
                          type='button'
                          onClick={() => handleAddMeetingLinks()}
                          className='btn btn-sm btn-outline-success'
                        >
                          Add Meeting Links
                        </button>
                      </div>
                      <div className='col-md-12'>
                        {values.meeting_link.map((element, i) => {
                          return (
                            <Fragment key={i}>
                              {/* <div className='row mt-2'></div> */}
                              <div className='row mt-3 align-items-end'>
                                <div className='col-md-1'>
                                  <button
                                    type='button'
                                    onClick={() => handleRemoveMeetingLinks(i)}
                                    className='btn btn-sm btn-outline-danger mt-1'
                                  >
                                    Remove
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <Form.Group>
                                    <Form.Label>Link</Form.Label>
                                    <Form.Control
                                      placeholder='Event Links. Eg: https://goo.gl/e8f923'
                                      name={`meeting_link[${i}].link`}
                                      type='text'
                                      className={`form-control ${
                                        touched[`meeting_link`] &&
                                        touched[`meeting_link`][i] &&
                                        touched[`meeting_link`][i].link &&
                                        errors[`meeting_link`] &&
                                        errors[`meeting_link`][i] &&
                                        errors[`meeting_link`][i].link &&
                                        'error'
                                      }`}
                                      required
                                      value={element.link}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {touched[`meeting_link`] &&
                                      touched[`meeting_link`][i] &&
                                      touched[`meeting_link`][i].link &&
                                      errors[`meeting_link`] &&
                                      errors[`meeting_link`][i] &&
                                      errors[`meeting_link`][i].link && (
                                        <div className='error-message'>
                                          {errors[`meeting_link`][i].link}
                                        </div>
                                      )}
                                  </Form.Group>
                                </div>
                                <div className='col-md-3'>
                                  <Form.Group>
                                    <Form.Label>Pass Code</Form.Label>
                                    <Form.Control
                                      placeholder='Event passcode. Eg: a9d6e147511747b294253b6a9716546e'
                                      name={`meeting_link[${i}].pass_code`}
                                      type='text'
                                      className={`form-control ${
                                        touched[`meeting_link`] &&
                                        touched[`meeting_link`][i] &&
                                        touched[`meeting_link`][i].pass_code &&
                                        errors[`meeting_link`] &&
                                        errors[`meeting_link`][i] &&
                                        errors[`meeting_link`][i].pass_code &&
                                        'error'
                                      }`}
                                      value={element.pass_code}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {touched[`meeting_link`] &&
                                      touched[`meeting_link`][i] &&
                                      touched[`meeting_link`][i].pass_code &&
                                      errors[`meeting_link`] &&
                                      errors[`meeting_link`][i] &&
                                      errors[`meeting_link`][i].pass_code && (
                                        <div className='error-message'>
                                          {errors[`meeting_link`][i].pass_code}
                                        </div>
                                      )}
                                  </Form.Group>
                                </div>
                                <div className='col-md-4'>
                                  <Form.Group>
                                    <Form.Label>Platform</Form.Label>
                                    <Form.Control
                                      as='select'
                                      value={element.platform}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`meeting_link[${i}].platform`}
                                      className={`form-control ${
                                        touched[`meeting_link`] &&
                                        touched[`meeting_link`][i] &&
                                        touched[`meeting_link`][i].platform &&
                                        errors[`meeting_link`] &&
                                        errors[`meeting_link`][i] &&
                                        errors[`meeting_link`][i].platform &&
                                        'error'
                                      }`}
                                    >
                                      <option value='' selected>
                                        Select Platform
                                      </option>
                                      <option value='Zoom'>Zoom</option>
                                      <option value='Google Meet'>
                                        Google Meet
                                      </option>
                                      <option value='Youtube Live'>
                                        Youtube Live
                                      </option>
                                      <option value='IG Live'>IG Live</option>
                                      <option value='Discord'>Discord</option>
                                      <option value='AMA'>AMA</option>
                                    </Form.Control>
                                    {touched[`meeting_link`] &&
                                      touched[`meeting_link`][i] &&
                                      touched[`meeting_link`][i].platform &&
                                      errors[`meeting_link`] &&
                                      errors[`meeting_link`][i] &&
                                      errors[`meeting_link`][i].platform && (
                                        <div className='error-message'>
                                          {errors[`meeting_link`][i].platform}
                                        </div>
                                      )}
                                  </Form.Group>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                    {typeof errors.meeting_link === 'string' &&
                      errors.meeting_link && (
                        <div className='error-message'>
                          {errors.meeting_link}
                        </div>
                      )}
                  </Form.Group>
                )}

                <div className='row'>
                  <div className='col-md-4'>
                    {' '}
                    <Form.Group>
                      <Form.Label className='d-block'>Event Type</Form.Label>
                      <Form.Check
                        inline
                        label='Private'
                        type='radio'
                        name='event_type'
                        onClick={() => {
                          setFieldValue('event_type', 'private');
                        }}
                      />
                      <Form.Check
                        inline
                        label='Public'
                        type='radio'
                        name='event_type'
                        onClick={() => {
                          setFieldValue('event_type', 'public');
                        }}
                      />

                      {touched.event_type && errors.event_type && (
                        <div className='error-message'>{errors.event_type}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-8'>
                    {' '}
                    <Form.Group>
                      <Form.Label>What People Will Learn</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        name='what_people_will_learn'
                        placeholder='Enter what people will learn description'
                        value={values.what_people_will_learn}
                        onChange={handleChange}
                        className={
                          touched.what_people_will_learn &&
                          errors.what_people_will_learn &&
                          'error'
                        }
                      />
                      {touched.what_people_will_learn &&
                        errors.what_people_will_learn && (
                          <div className='error-message'>
                            {errors.what_people_will_learn}
                          </div>
                        )}
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <Form.Group>
                      <Form.Label>Event Description</Form.Label>
                      <br />
                      <CKEditor
                        onChange={(e) => handleChangeEditor(e)}
                        data={values.event_description}
                        name='event_description'
                        config={{
                          toolbar: [
                            {
                              name: 'paragraph',
                              groups: [
                                'list',
                                'indent',
                                'blocks',
                                'align',
                                'bidi',
                              ],
                              items: [
                                'NumberedList',
                                'BulletedList',
                                '-',
                                'Outdent',
                                'Indent',
                                '-',
                                'Blockquote',
                                'CreateDiv',
                                '-',
                                'JustifyLeft',
                                'JustifyCenter',
                                'JustifyRight',
                                'JustifyBlock',
                                '-',
                                'BidiLtr',
                                'BidiRtl',
                                'Language',
                              ],
                            },
                          ],
                        }}
                        fontFamily={{
                          options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                          ],
                        }}
                      />
                      {touched.event_description &&
                        errors.event_description && (
                          <div className='error-message'>
                            {errors.event_description}
                          </div>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <Form.Group>
                  <Form.Label>Event Tag</Form.Label>
                  <div className='input-group col-md-12 p-0'>
                    <div className='input-group'>
                      <AsyncSelect
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                            width: '100%',
                          }),
                        }}
                        name='event_tag'
                        isMulti
                        defaultOptions={tagsData}
                        classNamePrefix='select'
                        loadOptions={(val) => promiseOptionsEventTag(val)}
                        onChange={(e) =>
                          handleChangeMultiSelect(e, 'event_tag')
                        }
                        onBlur={handleBlur}
                        className={`${
                          touched.event_tag && errors.event_tag && 'error'
                        } basic-multi-select`}
                      />
                    </div>
                  </div>
                  {touched.event_tag && errors.event_tag && (
                    <div className='error-message'>{errors.event_tag}</div>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Speakers</Form.Label>
                  <div className='row'>
                    <div className='col-md-12 mb-2'>
                      <button
                        type='button'
                        onClick={() => handleAddSpeakers()}
                        className='btn btn-sm btn-outline-success'
                      >
                        Add Speakers
                      </button>
                    </div>
                    <div className='col-md-12'>
                      {values.speakers.map((element, i) => {
                        return (
                          <Fragment key={i}>
                            <div className='row mt-2'>
                              <div className='col-md-1'>
                                <button
                                  type='button'
                                  onClick={() => handleRemoveSpeakers(i)}
                                  className='btn btn-sm btn-outline-danger mt-1'
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                            <div className='row mt-3'>
                              <div className='col-md-4'>
                                <Form.Group>
                                  <Form.Label>Speaker Fullname</Form.Label>
                                  <Form.Control
                                    placeholder='Full Name. Eg: Evelyn'
                                    name={`speakers[${i}].fullname`}
                                    type='text'
                                    className={`form-control ${
                                      touched[`speakers`] &&
                                      touched[`speakers`][i] &&
                                      touched[`speakers`][i].fullname &&
                                      errors[`speakers`] &&
                                      errors[`speakers`][i] &&
                                      errors[`speakers`][i].fullname &&
                                      'error'
                                    }`}
                                    required
                                    value={element.fullname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touched[`speakers`] &&
                                    touched[`speakers`][i] &&
                                    touched[`speakers`][i].fullname &&
                                    errors[`speakers`] &&
                                    errors[`speakers`][i] &&
                                    errors[`speakers`][i].fullname && (
                                      <div className='error-message'>
                                        {errors[`speakers`][i].fullname}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                              <div className='col-md-4'>
                                <Form.Group>
                                  <Form.Label>Speaker Title</Form.Label>
                                  <Form.Control
                                    placeholder='Title. Eg: Head of Blockchain'
                                    name={`speakers[${i}].tittle`}
                                    type='text'
                                    className={`form-control ${
                                      touched[`speakers`] &&
                                      touched[`speakers`][i] &&
                                      touched[`speakers`][i].tittle &&
                                      errors[`speakers`] &&
                                      errors[`speakers`][i] &&
                                      errors[`speakers`][i].tittle &&
                                      'error'
                                    }`}
                                    required
                                    value={element.tittle}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touched[`speakers`] &&
                                    touched[`speakers`][i] &&
                                    touched[`speakers`][i].tittle &&
                                    errors[`speakers`] &&
                                    errors[`speakers`][i] &&
                                    errors[`speakers`][i].tittle && (
                                      <div className='error-message'>
                                        {errors[`speakers`][i].tittle}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                              <div className='col-md-4'>
                                <Form.Group>
                                  <Form.Label>Speaker's About</Form.Label>
                                  <Form.Control
                                    placeholder='About. Eg: A crypto and blockchain enthusiast'
                                    name={`speakers[${i}].about`}
                                    type='text'
                                    className={`form-control ${
                                      touched[`speakers`] &&
                                      touched[`speakers`][i] &&
                                      touched[`speakers`][i].about &&
                                      errors[`speakers`] &&
                                      errors[`speakers`][i] &&
                                      errors[`speakers`][i].about &&
                                      'error'
                                    }`}
                                    required
                                    value={element.about}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touched[`speakers`] &&
                                    touched[`speakers`][i] &&
                                    touched[`speakers`][i].about &&
                                    errors[`speakers`] &&
                                    errors[`speakers`][i] &&
                                    errors[`speakers`][i].about && (
                                      <div className='error-message'>
                                        {errors[`speakers`][i].about}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4'>
                                <Form.Group>
                                  <Form.Label>
                                    Speaker's Personal Data Link
                                  </Form.Label>
                                  <Form.Control
                                    placeholder='Link to personal data'
                                    name={`speakers[${i}].link_to_personal_data`}
                                    type='text'
                                    className={`form-control ${
                                      touched[`speakers`] &&
                                      touched[`speakers`][i] &&
                                      touched[`speakers`][i]
                                        .link_to_personal_data &&
                                      errors[`speakers`] &&
                                      errors[`speakers`][i] &&
                                      errors[`speakers`][i]
                                        .link_to_personal_data &&
                                      'error'
                                    }`}
                                    // required
                                    value={element.link_to_personal_data}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  {touched[`speakers`] &&
                                    touched[`speakers`][i] &&
                                    touched[`speakers`][i]
                                      .link_to_personal_data &&
                                    errors[`speakers`] &&
                                    errors[`speakers`][i] &&
                                    errors[`speakers`][i]
                                      .link_to_personal_data && (
                                      <div className='error-message'>
                                        {
                                          errors[`speakers`][i]
                                            .link_to_personal_data
                                        }
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                              <div className='col-md-4'>
                                <Form.Group>
                                  <Form.Label>
                                    Speaker's Twitter Link
                                  </Form.Label>
                                  <Form.Control
                                    placeholder='Twitter Link'
                                    name={`speakers[${i}].twitter`}
                                    type='text'
                                    className={`form-control ${
                                      touched[`speakers`] &&
                                      touched[`speakers`][i] &&
                                      touched[`speakers`][i].twitter &&
                                      errors[`speakers`] &&
                                      errors[`speakers`][i] &&
                                      errors[`speakers`][i].twitter &&
                                      'error'
                                    }`}
                                    onBlur={handleBlur}
                                    value={element.twitter}
                                    onChange={handleChange}
                                  />
                                  {touched[`speakers`] &&
                                    touched[`speakers`][i] &&
                                    touched[`speakers`][i].twitter &&
                                    errors[`speakers`] &&
                                    errors[`speakers`][i] &&
                                    errors[`speakers`][i].twitter && (
                                      <div className='error-message'>
                                        {errors[`speakers`][i].twitter}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                              <div className='col-md-4'>
                                <Form.Group>
                                  <Form.Label>
                                    Speaker's Instagram Link
                                  </Form.Label>
                                  <Form.Control
                                    placeholder='Instagram Link'
                                    name={`speakers[${i}].instagram`}
                                    type='text'
                                    className={`form-control ${
                                      touched[`speakers`] &&
                                      touched[`speakers`][i] &&
                                      touched[`speakers`][i].instagram &&
                                      errors[`speakers`] &&
                                      errors[`speakers`][i] &&
                                      errors[`speakers`][i].instagram &&
                                      'error'
                                    }`}
                                    value={element.instagram}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touched[`speakers`] &&
                                    touched[`speakers`][i] &&
                                    touched[`speakers`][i].instagram &&
                                    errors[`speakers`] &&
                                    errors[`speakers`][i] &&
                                    errors[`speakers`][i].instagram && (
                                      <div className='error-message'>
                                        {errors[`speakers`][i].instagram}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6'>
                                <Form.Group>
                                  <Form.Label>
                                    Speaker Portrait Image
                                  </Form.Label>
                                  <Form.Control
                                    type='file'
                                    name={`speakers[${i}].portrait_image`}
                                    accept='image/*'
                                    className={`form-control ${
                                      touched[`speakers`] &&
                                      touched[`speakers`][i] &&
                                      touched[`speakers`][i].portrait_image &&
                                      errors[`speakers`] &&
                                      errors[`speakers`][i] &&
                                      errors[`speakers`][i].portrait_image &&
                                      'error'
                                    }`}
                                    onChange={(e) => {
                                      onChangePortraitImage(i, e);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {touched[`speakers`] &&
                                    touched[`speakers`][i] &&
                                    touched[`speakers`][i].portrait_image &&
                                    errors[`speakers`] &&
                                    errors[`speakers`][i] &&
                                    errors[`speakers`][i].portrait_image && (
                                      <div className='error-message'>
                                        {errors[`speakers`][i].portrait_image}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                  {typeof errors.speakers === 'string' && errors.speakers && (
                    <div className='error-message'>{errors.speakers}</div>
                  )}
                </Form.Group>

                {isOffline && (
                  <div className='row'>
                    <div className='col-md-6'>
                      {locationList && locationList.length > 0 ? (
                        <Form.Group>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            as='select'
                            value={values.location_id}
                            onChange={(e) => {
                              handleChangeFields(e);
                              getBookingTypeList(e.target.value);
                            }}
                            onBlur={handleBlur}
                            name='location_id'
                            className={`${
                              touched.location_id &&
                              errors.location_id &&
                              'error'
                            }`}
                          >
                            <option value='' selected>
                              Select Location
                            </option>
                            {locationListUniqueData.map((data, i) => (
                              <option value={data.location_id} key={i}>
                                {`${data.location_name} - ${data.type_name}`}
                              </option>
                            ))}
                          </Form.Control>
                          {touched.location_id && errors.location_id && (
                            <div className='error-message'>
                              {errors.location_id}
                            </div>
                          )}
                        </Form.Group>
                      ) : (
                        <p>
                          You must create a location before creating a new
                          booking order
                        </p>
                      )}
                    </div>
                    <div className='col-md-3'>
                      {' '}
                      <Form.Group>
                        <Form.Label>Booking Type</Form.Label>
                        <Form.Control
                          as='select'
                          value={values.booking_type_id}
                          onChange={(e) => {
                            handleChangeFields(e);
                            getSeatList(e.target.value);
                          }}
                          onBlur={handleBlur}
                          name='booking_type_id'
                          className={
                            touched.booking_type_id &&
                            errors.booking_type_id &&
                            'error'
                          }
                        >
                          <option selected>Select Booking Type</option>
                          {bookingTypeList &&
                            bookingTypeList.length > 0 &&
                            bookingTypeList
                              .filter(
                                (booking) =>
                                  booking.type_name === 'Multi Function' ||
                                  booking.type_name === 'Multifunction'
                              )
                              .map((data, i) => (
                                <option value={data.booking_type_id} key={i}>
                                  {`${data.type_name} - ${data.rate_type_name} - ${data.capacities} person`}
                                </option>
                              ))}
                        </Form.Control>
                        {touched.booking_type_id && errors.booking_type_id && (
                          <div className='error-message'>
                            {errors.booking_type_id}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <div className='col-md-3'>
                      {' '}
                      <Form.Group>
                        <Form.Label>Seat / Room</Form.Label>
                        <Form.Control
                          as='select'
                          value={values.seat_id}
                          onChange={(e) => {
                            handleChangeFields(e);
                          }}
                          onBlur={handleBlur}
                          name='seat_id'
                          className={
                            touched.seat_id && errors.seat_id && 'error'
                          }
                        >
                          <option selected>Select Seat / Room</option>
                          {seatList &&
                            seatList.length > 0 &&
                            seatList.map((data, i) => (
                              <option value={data.seat_id} key={i}>
                                {data.seat_name}
                              </option>
                            ))}
                        </Form.Control>
                        {touched.seat_id && errors.seat_id && (
                          <div className='error-message'>{errors.seat_id}</div>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                )}

                <div className='row'>
                  {isOffline && (
                    <div className='col-md-3'>
                      {' '}
                      <Form.Group>
                        <Form.Label>Product Code</Form.Label>
                        <Form.Control
                          as='select'
                          value={values.product_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='product_code'
                          className={
                            touched.product_code &&
                            errors.product_code &&
                            'error'
                          }
                        >
                          <option selected>Select Product Code</option>
                          {paymentProductList &&
                            paymentProductList.length > 0 &&
                            paymentProductList
                              .filter(
                                (price) =>
                                  price.product_name !== 'AZN NFT Holder'
                              )
                              .map((payment, i) => (
                                <option value={payment.product_code}>
                                  {payment.product_name}
                                </option>
                              ))}
                        </Form.Control>
                        {touched.product_code && errors.product_code && (
                          <div className='error-message'>
                            {errors.product_code}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  )}
                  <div className='col-md-3'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Participant Quota</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter Participant Quota. Eg: 20'
                        value={values.participant_quota}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='participant_quota'
                        className={
                          touched.participant_quota &&
                          errors.participant_quota &&
                          'error'
                        }
                      />
                      {touched.capacities && errors.participant_quota && (
                        <div className='error-message'>
                          {errors.participant_quota}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type='datetime-local'
                        placeholder='Enter start date for this events'
                        min={getDateNow()}
                        value={values.start_date}
                        onChange={(e) => {
                          handleChangeFields(e);
                        }}
                        onBlur={handleBlur}
                        name='start_date'
                        className={
                          touched.start_date && errors.start_date && 'error'
                        }
                      />
                      {touched.start_date && errors.start_date && (
                        <div className='error-message'>{errors.start_date}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    {' '}
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type='datetime-local'
                        placeholder='Enter end date for this events'
                        min={getDateNow()}
                        value={values.end_date}
                        onChange={(e) => {
                          handleChangeFields(e);
                        }}
                        onBlur={handleBlur}
                        name='end_date'
                        className={
                          touched.end_date && errors.end_date && 'error'
                        }
                      />
                      {touched.end_date && errors.end_date && (
                        <div className='error-message'>{errors.end_date}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Proposal</Form.Label>
                      <div className='d-flex justify-content-center align-items-center '>
                        <Form.Control
                          type='file'
                          className={`form-control ${
                            touched.proposal && errors.proposal && 'error'
                          }`}
                          name='proposal'
                          accept='application/pdf'
                          onChange={(e) => handleChangeFiles(e)}
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Please use only pdf and doc files</span>
                      </Form.Text>

                      {touched.proposal && errors.proposal && (
                        <div className='error-message'>{errors.proposal}</div>
                      )}
                    </Form.Group>
                  </div>
                  {/* <div className="col-md-3">
                    {' '}
                    <Form.Group>
                      <Form.Label>Event Recurring</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.event_recurring}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="event_recurring"
                      >
                        <option disabled selected>
                          Select Recurring
                        </option>
                        <option value="no recurring">
                          No Recurring
                        </option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                      </Form.Control>
                    </Form.Group>
                  </div> */}
                  {/* <div className="col-md-3">
                    <Form.Group>
                      <Form.Label className="d-block">
                        Allow Collaboration ?
                      </Form.Label>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        name="allow_collaboration"
                        onClick={() => {
                          setFieldValue('allow_collaboration', true);
                        }}
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        name="allow_collaboration"
                        onClick={() => {
                          setFieldValue('allow_collaboration', false);
                        }}
                      />

                      {touched.allow_collaboration &&
                        errors.allow_collaboration && (
                          <div className="error-message">
                            {errors.allow_collaboration}
                          </div>
                        )}
                    </Form.Group>
                  </div> */}
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Images</Form.Label>
                      <div className='d-flex justify-content-center align-items-center '>
                        <Form.Control
                          className={`form-control ${
                            touched.image && errors.image && 'error'
                          }`}
                          type='file'
                          name='image'
                          multiple
                          accept='image/*'
                          onChange={(e) => handleChangeFiles(e)}
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Please using 1440 x 400 px for this image</span>
                      </Form.Text>
                      {touched.image && errors.image && (
                        <div className='error-message'>{errors.image}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Special Request</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        name='special_request'
                        placeholder='Enter your special request'
                        value={values.special_request}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Additional Notes</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        name='additional_note'
                        placeholder='Enter your notes'
                        value={values.additional_note}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>

                {/* <Form.Group>
                  <Form.Label>
                    Special Request (Press enter to input your special
                    request)
                  </Form.Label>
                  <div
                    className={`input-group col-md-12 tags-input-container ${touched.special_request &&
                      errors.special_request &&
                      'error'}`}
                  >
                    {specialRequests.map((request, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text">{request}</span>
                        <span
                          className="close"
                          onClick={() =>
                            removeItem(index, 'special_request')
                          }
                        >
                          &times;
                        </span>
                      </div>
                    ))}
                    <input
                      onKeyDown={handleKeyDown}
                      type="text"
                      className="form-control"
                      placeholder="Type your special request. Eg: wifi, private room, gaming chair"
                      name="special_request"
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.special_request &&
                    errors.special_request && (
                      <div className="error-message">
                        {errors.special_request}
                      </div>
                    )}
                </Form.Group> */}

                <div
                  className='kt-login__actions mt-4'
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <button
                    type='button'
                    disabled={loading}
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/events');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <button
                    type='button'
                    disabled={!isDisabled}
                    className={`btn btn-secondary btn-elevate kt-login__btn-secondary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      eventsFormik.handleSubmit();
                    }}
                  >
                    Check Availability
                  </button>

                  <button
                    type='submit'
                    disabled={
                      !isOffline && isOnline
                        ? false
                        : isDisabled && !isCheckEvents
                    }
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                  >
                    Add Event
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThubEventsCreate;
