import React, { FC, useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { toAbsoluteUrl } from "../../_metronic/utils/utils";

export interface DatetimeProps extends Omit<ReactDatePickerProps, "onChange"> {
  changeDate: (date: Date | null) => void;
  selected?: Date | null;
}

const Datetime: FC<DatetimeProps> = ({ changeDate, selected, ...anotherProps }) => {
  const [startDate, setStartDate] = useState(selected);
  const setDate = (val: Date | null) => {
    changeDate(val);
    setStartDate(val);
  };

  useEffect(() => {
    setStartDate(selected)
  }, [selected])

  const ExampleCustomInput = ({ value = '', onClick }: { value?: string, onClick?: () => void }): JSX.Element => (
    <div className="input-date mri-input" role="button" tabIndex={0} onClick={onClick} onKeyPress={onClick}>
      <span>{value === '' ? 'Date' : value}</span>
      <button type="button" className="btn remove-frame-button" title="Reset Date" onClick={() => setDate(null)}>
        <img src={toAbsoluteUrl("/media/icons/calender.svg")} alt="" style={{ width: '65%'}}/>
      </button>
    </div>
  );

  return (
    <DatePicker
      popperPlacement="bottom-start"
      selected={startDate}    
      customInput={<ExampleCustomInput />}
      {...anotherProps}
      onChange={(date) => setDate(date)}
    />
  );
};
export default Datetime;