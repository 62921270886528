import React, { useState, useEffect, Fragment } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import thubActions from '../../../crud/thub';
import { Button, Table, Modal, Badge, Tab, Tabs } from 'react-bootstrap';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
import { uniquePriceCode, useDebounce } from './bookingOrder.constant';
import clsx from 'clsx';
import { getImageOssURL, redirectToNewPage } from '../../../helper';
require('bootstrap/scss/bootstrap.scss');
require('./bookingOrder.scss');

const history = createBrowserHistory();
const ThubBookingList = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const [stringToken, setStringToken] = useState();
  const [bookingOrderData, setBookingOrderData] = useState({
    data: [],
    currentPage: 0,
    totalData: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [filterData, setFilterData] = useState({
    booking_id: '',
    user_id: '',
    email: '',
    location_name: '',
    type_name: '',
    start_date: '',
    end_date: '',
    status: '',
    approval: '',
    limitData: 50,
  });
  const [bookingOrderDetail, setBookingOrderDetail] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [approvalValue, setApprovalValue] = useState();
  const [paymentValue, setPaymentValue] = useState();
  const [bookingId, setBookingId] = useState();
  const [rejectReason, setRejectReason] = useState('');

  const imageUrl =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://thub-prod.oss-ap-southeast-5.aliyuncs.com'
      : 'http://thub-demo.oss-ap-southeast-5.aliyuncs.com';

  const [bankTransferSelected, setBankTransferSelected] = useState(false);
  const [paymentProofList, setPaymentProofList] = useState([]);

  const getBookingOrderList = async (page) => {
    const {
      booking_id,
      user_id,
      email,
      location_name,
      type_name,
      start_date,
      end_date,
      status,
      approval,
      limitData,
    } = filterData;
    const startDate = start_date ? start_date.split('T').join(' ') : '';
    const endDate = end_date ? end_date.split('T').join(' ') : '';
    const params = `?page=${
      page || 1
    }&limit=${limitData}&booking_id=${booking_id}&user_id=${user_id}&email=${email}&location_name=${location_name}&type_name=${type_name}&start_date=${startDate}&end_date=${endDate}&status=${status}&approval=${approval}`;
    if (stringToken) {
      setLoading(true);
      await thubActions
        .fetchThubBookingOrderList(stringToken, params)
        .then((res) => {
          setBookingOrderData((prev) => ({
            ...prev,
            data: res.data.data.bookings,
            currentPage: res.data.data.currentPage,
            totalData: res.data.data.totalData,
            totalDataOnPage: res.data.data.totalDataOnPage,
            totalPages: res.data.data.totalPages,
          }));
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setBookingOrderData([]);
          if (e.response) {
            if (e.response.data.code === 401 || e.response.data.code === 500) {
              toast.error('token has expired');
              localStorage.clear();
              history.push('/auth');
              window.location.reload();
            } else if (e.response.data.code === 404) {
              return;
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const getBookingOrderDetail = async (bookingId) => {
    setLoadingDetailData(true);
    thubActions
      .fetchThubBookingOrderDetail(stringToken, bookingId)
      .then((res) => {
        setBookingOrderDetail(res.data.data);
        setLoadingDetailData(false);
      })
      .catch((e) => {
        setLoadingDetailData(false);
        setBookingOrderDetail([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else if (e.response.data.code === 404) {
            return;
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const handleChangeApproval = async () => {
    setLoading(true);

    try {
      const statusData = {
        approval: Number(approvalValue),
        reject_reason: approvalValue !== '2' ? '-' : rejectReason,
      };

      const response = await thubActions.fetchThubChangeApprovalStatus(
        stringToken,
        bookingId,
        statusData
      );

      if (response.data.code === 200 || response.data.code === 201) {
        setShowApprovalModal(false);
        setLoading(false);
        toast.success('Successfully changed the approval status');

        setTimeout(() => {
          setApprovalValue('');
          getBookingOrderList();
        }, 500);
      }
    } catch (e) {
      setLoading(false);
      setShowApprovalModal(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const handleChangePayment = async () => {
    setLoading(true);

    try {
      const statusData = {
        status: Number(paymentValue),
        reject_reason: paymentValue !== '4' ? '-' : rejectReason,
      };

      const response = await thubActions.fetchThubChangePaymentStatus(
        stringToken,
        bookingId,
        statusData
      );

      if (response.data.code === 200 || response.data.code === 201) {
        setShowPaymentModal(false);
        setLoading(false);
        toast.success('Successfully changed the payment status');

        setTimeout(() => {
          setPaymentValue('');
          getBookingOrderList();
        }, 500);
      }
    } catch (e) {
      setLoading(false);
      setShowPaymentModal(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const getDateTime = (date) => {
    const dateOffset = moment(date).utcOffset();
    // const _time = moment(date);
    const fmtDate = moment(date)
      // .subtract(dateOffset, 'minute')
      .format('DD MMM YYYY HH:mm');

    return fmtDate;
  };
  const getDate = (date) => {
    const dateOffset = moment(date).utcOffset();
    const fmtDate = moment(date)
      // .subtract(dateOffset, 'minute')
      .format('DD MMM YYYY');

    return fmtDate;
  };

  const getPaymentStatus = (id) => {
    switch (id) {
      case 0:
        return 'Waiting for Payment';
      case 1:
        return 'Paid';
      case 2:
        return 'Expired';
      case 3:
        return 'Waiting for Confirmation';
      case 4:
        return 'Rejected';

      default:
        return 'Waiting for Payment';
    }
  };

  const getApprovalStatus = (id) => {
    switch (id) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';

      default:
        return 'Pending';
    }
  };

  const getBadgeColor = (id) => {
    switch (id) {
      case 0:
        return 'info';
      case 1:
        return 'success';
      case 2:
        return 'danger';
      case 3:
        return 'warning';
      case 4:
        return 'danger';

      default:
        return 'primary';
    }
  };

  const getPriceTypeColor = (type) => {
    switch (type) {
      case 'cash':
        return 'info';
      case 'special-privilege':
        return 'success';
      case 'collab-with-tokocrypto':
        return 'success';
      case 'tko-redeem':
        return 'warning';
      case 'tko-holder':
        return 'danger';

      default:
        return 'info';
    }
  };

  const getBookingTypeColor = (name) => {
    switch (name) {
      case 'Co-Working':
        return 'primary';
      case 'Private':
        return 'warning';
      case 'Multi Function':
        return 'success';

      default:
        return 'secondary';
    }
  };

  const handleChangeFilter = (name, value) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePageChange = (page) => {
    setBookingOrderData((prev) => ({
      ...prev,
      currentPage: page,
    }));
    getBookingOrderList(page);
  };

  const isPDF = (src) => {
    if (src.includes('.pdf')) return true;
    return false;
  };

  const showPaymentProof = (proofList) => {
    setBankTransferSelected(proofList[0]);
    setPaymentProofList(proofList);
  };

  const paymentProofListRender = (proofList, isWithoutMargin) => {
    if (Array.isArray(proofList) && proofList.length > 0)
      return proofList.map((file, index) => (
        <img
          key={index}
          className={`img-otc-payment-proof ${
            bankTransferSelected === file ? 'img-otc-payment-proof-active' : ''
          } ${!isWithoutMargin && index > 0 ? 'ml-3' : ''}`}
          src={
            isPDF(file) ? '/media/logos/pdf-icon.png' : imageUrl + '/' + file
          }
          alt='proof-img'
          onClick={() =>
            isPDF(file)
              ? redirectToNewPage(imageUrl + '/' + file, true)
              : setBankTransferSelected(file)
          }
        />
      ));
    else return <div>- No Data -</div>;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getBookingOrderList();
    }
  }, [stringToken]);

  // this for debounce filtering data so it wont repeatedly request to api for every changes in filter field
  const debouncedFilterData = useDebounce(filterData, 1000);
  useEffect(() => {
    if (debouncedFilterData) {
      getBookingOrderList();
    }
  }, [debouncedFilterData]);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Booking Number
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='booking_id'
                  placeholder='eg: 202208160010'
                  value={filterData.booking_id}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Email
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='email'
                  placeholder='eg: email@email.com'
                  value={filterData.email}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Location
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='location_name'
                  placeholder='eg: Bali'
                  value={filterData.location_name}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Booking Type
                </label>
                <select
                  className='form-control mr-3'
                  name='type_name'
                  value={filterData.type_name}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                >
                  <option disabled selected>
                    Select Booking Type
                  </option>
                  <option value='Co-Working'>Co-Working</option>
                  <option value='Private'>Private</option>
                  <option value='Multi Function'>Multi Function</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          className='kt-portlet__body'
          style={{ padding: '0 20px 20px 20px' }}
        >
          <div className='kt-portlet__preview'>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Payment Status
                </label>
                <select
                  className='form-control mr-3'
                  name='status'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                >
                  <option selected>Select Payment Status</option>
                  <option value={0}>Waiting Payment</option>
                  <option value={1}>Paid</option>
                  <option value={2}>Expired</option>
                  <option value={3}>Waiting for Confirmation</option>
                  <option value={4}>Rejected</option>
                </select>
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event Start Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='start_date'
                  placeholder='eg: 24/03/2022'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event End Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='end_date'
                  placeholder='eg: 24/03/2022'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>

              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Approval Status
                </label>
                <select
                  className='form-control mr-3'
                  name='approval'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                >
                  <option selected disabled>
                    Select Approval Status
                  </option>
                  <option value={0}>Pending</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='paddupgrade'>
              <div className='bookingorder-menu__top'>
                <Link to='/thub/booking-order/create' className='kt-link'>
                  <Button variant='success' style={{ fontSize: '11px' }}>
                    Create Booking Order
                  </Button>
                </Link>
                <select
                  className='form-control bookingorder-limit'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                  name='limitData'
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option selected value={50}>
                    50
                  </option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section' style={{ marginBottom: 0 }}>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          No
                        </th>
                        <th
                          className='tableheh'
                          style={{ verticalAlign: 'middle' }}
                        >
                          Booking Number
                          {/* <button
                            onClick={(e) =>
                              this.onSortChange('email')
                            }
                            className="sort-button"
                          >
                            <i
                              className={`fas fa-${
                                sortTypes[this.state.orderSort].class
                              }`}
                            />
                          </button> */}
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Email
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Location
                          <br />
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Type
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                            width: '10%',
                          }}
                        >
                          Start Datetime
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                            width: '10%',
                          }}
                        >
                          End Datetime
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Unique Code
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Payment Method
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Total Price
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Payment Status
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Approval Status
                        </th>

                        <th className='center tableheh'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingOrderData && bookingOrderData.length === 0 && (
                        <tr className='text-center'>
                          <td colSpan={14}>No booking order data found</td>
                        </tr>
                      )}
                      {bookingOrderData.data &&
                        bookingOrderData.data.length > 0 &&
                        bookingOrderData.data.map((row, i) => (
                          <tr key={row._id}>
                            <td>{i + 1}</td>
                            <td>{row.booking_id}</td>
                            <td>{row.user.email}</td>
                            <td>{row.location.location_name}</td>
                            <td>
                              <Badge
                                bg={getBookingTypeColor(
                                  row.booking_type.type_name
                                )}
                              >
                                {row.booking_type.type_name}
                              </Badge>
                            </td>
                            <td>{getDateTime(row.start_date)}</td>
                            <td>{getDateTime(row.end_date)}</td>
                            <td>
                              {row.payment_method &&
                              row.payment_method.product_code === 'tko-holder'
                                ? '0'
                                : row.payment_method.unique_payment_code}
                            </td>
                            <td style={{ textTransform: 'capitalize' }}>
                              {row.payment_method && (
                                <Badge
                                  bg={getPriceTypeColor(
                                    row.payment_method.product_code
                                  )}
                                >
                                  {row.payment_method.product_name
                                    ? row.payment_method.product_name
                                    : '-'}
                                </Badge>
                              )}
                            </td>
                            <td>
                              {row.payment_method &&
                              row.payment_method.product_code &&
                              row.payment_method.product_code !==
                                'special-privilege'
                                ? row.payment_method.currency_symbol
                                : ''}{' '}
                              {row.payment_method && (
                                <Fragment>
                                  {row.payment_method.price_type ===
                                  'tko_redeem'
                                    ? uniquePriceCode(
                                        row.total_price_order,
                                        row.payment_method.unique_payment_code
                                      )
                                    : row.total_price_order}
                                </Fragment>
                              )}
                            </td>
                            <td>
                              <Badge
                                bg={getBadgeColor(row.status)}
                                onClick={() => {
                                  setShowPaymentModal(true);
                                  setBookingId(row.booking_id);
                                }}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                {getPaymentStatus(row.status)}
                              </Badge>
                            </td>
                            <td>
                              <Badge
                                bg={getBadgeColor(row.approval)}
                                onClick={() => {
                                  if (row.approval == '0') {
                                    setShowApprovalModal(true);
                                    setBookingId(row.booking_id);
                                  }
                                }}
                                style={{
                                  cursor:
                                    row.approval == '0'
                                      ? 'pointer'
                                      : 'not-allowed',
                                }}
                              >
                                {getApprovalStatus(row.approval)}
                              </Badge>
                            </td>
                            <td className='center'>
                              <div>
                                {' '}
                                <span
                                  onClick={() => {
                                    getBookingOrderDetail(row.booking_id);
                                    setShowDetailModal(!showDetailModal);
                                  }}
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className='btn btn-outline-primary'
                                >
                                  View
                                </span>
                                <br />
                                {row.booking_type.type_name !==
                                  'Multi Function' && (
                                  <Fragment>
                                    <Link
                                      to={`/thub/booking-order/${row.booking_id}/edit`}
                                    >
                                      <span
                                        style={{
                                          padding: '2px',
                                          fontSize: '0.8rem',
                                          width: '100%',
                                          marginBottom: '5px',
                                        }}
                                        className='btn btn-outline-success'
                                      >
                                        Edit
                                      </span>
                                    </Link>
                                    <br />
                                  </Fragment>
                                )}
                                {row.payment_method.product_code &&
                                  row.payment_method.product_code !==
                                    'tko-holder' && (
                                    <Link
                                      to={`/thub/booking-order/${row.booking_id}/${row.user.first_name} - ${row.user.last_name}/booking-history`}
                                    >
                                      <span
                                        style={{
                                          padding: '2px',
                                          fontSize: '0.8rem',
                                          width: '100%',
                                          marginBottom: '5px',
                                        }}
                                        className='btn btn-outline-secondary'
                                      >
                                        Log History
                                      </span>
                                    </Link>
                                  )}
                                <br />
                                {row.payment_method &&
                                  row.payment_method.proof.length > 0 && (
                                    <span
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                      }}
                                      className='btn btn-outline-danger'
                                      onClick={() =>
                                        showPaymentProof(
                                          row.payment_method.proof
                                        )
                                      }
                                    >
                                      View Payment Proof
                                    </span>
                                  )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <div className='d-flex justify-content-center align-items-center pagination-data'>
                    <Pagination
                      activePage={bookingOrderData.currentPage}
                      itemsCountPerPage={bookingOrderData.totalDataOnPage}
                      itemClass='page-item'
                      linkClass='page-link'
                      totalItemsCount={bookingOrderData.totalData}
                      pageRangeDisplayed={
                        bookingOrderData.totalDataOnPage >= filterData.limitData
                          ? bookingOrderData.totalPages
                          : 1
                      }
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>{bookingOrderDetail.booking_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingDetailData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type={'bars'}
                color={'#0000FF'}
                height={'2%'}
                width={'5%'}
              />
            </div>
          ) : (
            <>
              {bookingOrderDetail &&
                typeof bookingOrderDetail.booking_id !== 'undefined' && (
                  <Tabs
                    id='controlled-tab-example'
                    defaultActiveKey='home'
                    // onSelect={key => this.setState({ key })}
                  >
                    <Tab eventKey='home' title='Detail Data'>
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Booking Number</strong>
                            </td>
                            <td colSpan={5}>{bookingOrderDetail.booking_id}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>First & Last Name</strong>
                            </td>
                            <td colSpan={5}>
                              {`${bookingOrderDetail.user.first_name} ${bookingOrderDetail.user.last_name}`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email Address</strong>
                            </td>
                            <td colSpan='5'>{bookingOrderDetail.user.email}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Whatsapp Number</strong>
                            </td>
                            <td colSpan='5'>
                              {bookingOrderDetail.user.whatsapp_number || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Location</strong>
                            </td>
                            <td colSpan='5'>
                              {bookingOrderDetail.location.location_name}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Booking Type</strong>
                            </td>
                            <td colSpan={5}>
                              {bookingOrderDetail.booking_type.type_name}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Booking Datetime</strong>
                            </td>
                            <td colSpan='5'>
                              {`${getDateTime(
                                bookingOrderDetail.start_date
                              )} - ${getDateTime(bookingOrderDetail.end_date)}`}
                            </td>
                          </tr>
                          {bookingOrderDetail.event && (
                            <tr>
                              <td>
                                <strong>Event Datetime</strong>
                              </td>
                              <td colSpan='5'>
                                {`${getDateTime(bookingOrderDetail.start_date)} 
                                 - ${getDateTime(bookingOrderDetail.end_date)}`}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <strong>Total Day</strong>
                            </td>
                            <td colSpan={5}>
                              {bookingOrderDetail.total_day || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Hour</strong>
                            </td>
                            <td colSpan={5}>
                              {bookingOrderDetail.total_hour || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Price</strong>
                            </td>
                            <td colSpan={5}>
                              {bookingOrderDetail.payment_method &&
                              bookingOrderDetail.payment_method.product_code &&
                              bookingOrderDetail.payment_method.product_code !==
                                'special-privilege'
                                ? bookingOrderDetail.payment_method
                                    .currency_symbol
                                : ''}{' '}
                              {bookingOrderDetail.payment_method.price_type ===
                              'tko_redeem'
                                ? uniquePriceCode(
                                    bookingOrderDetail.total_price_order,
                                    bookingOrderDetail.payment_method
                                      .unique_payment_code
                                  )
                                : bookingOrderDetail.total_price_order}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Payment Method</strong>
                            </td>
                            <td colSpan={5}>
                              {(bookingOrderDetail.payment_method &&
                                bookingOrderDetail.payment_method
                                  .product_name) ||
                                '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Customer TKO BSC Address</strong>
                            </td>
                            <td colSpan='5'>
                              {bookingOrderDetail.user.tko_address}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Created By</strong>
                            </td>
                            <td colSpan='5'>{bookingOrderDetail.created_by}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Additional Notes</strong>
                            </td>
                            <td colSpan='5'>
                              {bookingOrderDetail.additional_note || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Reject Reason</strong>
                            </td>
                            <td colSpan={5}>
                              {bookingOrderDetail.rejected_reason || '-'}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              <strong>Check In Datetime</strong>
                            </td>
                            <td colSpan={5}>
                              {bookingOrderDetail.check_in || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Check Out Datetime</strong>
                            </td>
                            <td colSpan={5}>
                              {bookingOrderDetail.check_out || '-'}
                            </td>
                          </tr> */}
                          <tr>
                            <td>
                              <strong>Status</strong>
                            </td>
                            <td colSpan={5}>
                              {getPaymentStatus(bookingOrderDetail.status)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Approval</strong>
                            </td>
                            <td colSpan={5}>
                              {getApprovalStatus(bookingOrderDetail.approval)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showApprovalModal}
        onHide={() => {
          setShowApprovalModal(!showApprovalModal);
          setApprovalValue('');
          setRejectReason();
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='m-login__form m-form'
            onSubmit={(e) => {
              e.preventDefault();
              handleChangeApproval();
            }}
          >
            <div>
              <div className='form-group' style={{ marginBottom: '15px' }}>
                <select
                  className='form-control'
                  name='approval'
                  onChange={(e) => {
                    setApprovalValue(e.target.value);
                    setRejectReason();
                  }}
                  required
                >
                  <option selected>Select Approval Status</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                </select>
              </div>

              {approvalValue === '2' && (
                <div className='form-group' style={{ marginBottom: '15px' }}>
                  <input
                    type='text'
                    value={rejectReason}
                    className='form-control'
                    placeholder='Type your reject reason'
                    onChange={(e) => setRejectReason(e.target.value)}
                    required
                  />
                </div>
              )}
            </div>

            <div
              className='kt-login__actions'
              style={{
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <Button
                variant='danger'
                onClick={() => setShowApprovalModal(!showApprovalModal)}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={loading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      loading,
                  }
                )}`}
              >
                Change Approval Status
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPaymentModal}
        onHide={() => {
          setShowPaymentModal(!showPaymentModal);
          setRejectReason();
          setPaymentValue('');
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='m-login__form m-form'
            onSubmit={(e) => {
              e.preventDefault();
              handleChangePayment();
            }}
          >
            <div>
              <div className='form-group' style={{ marginBottom: '15px' }}>
                <select
                  className='form-control'
                  name='payment'
                  onChange={(e) => {
                    setPaymentValue(e.target.value);
                  }}
                  required
                >
                  <option selected>Select Payment Status</option>
                  <option value={0}>Waiting for Payment</option>
                  <option value={1}>Paid</option>
                  <option value={2}>Expired</option>
                  <option value={3}>Waiting for Confirmation</option>
                  <option value={4}>Rejected</option>
                </select>
              </div>

              {paymentValue === '4' && (
                <div className='form-group' style={{ marginBottom: '15px' }}>
                  <input
                    type='text'
                    value={rejectReason}
                    className='form-control'
                    placeholder="Payment's reject reason"
                    onChange={(e) => setRejectReason(e.target.value)}
                    required
                  />
                </div>
              )}
            </div>

            <div
              className='kt-login__actions'
              style={{
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <Button
                variant='danger'
                onClick={() => setShowPaymentModal(!showPaymentModal)}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={loading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      loading,
                  }
                )}`}
              >
                Change Payment Status
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={!!bankTransferSelected}
        onHide={() => setBankTransferSelected(null)}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          {bankTransferSelected && (
            <Modal.Title>{bankTransferSelected}</Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          <div className='custom-flex-row' style={{ gap: 10, width: '100%' }}>
            <div className='custom-flex-col' style={{ gap: 10 }}>
              {bankTransferSelected &&
                paymentProofListRender(paymentProofList, true)}
            </div>
            {bankTransferSelected && (
              <img
                className='img-otc-payment-proof-w-80'
                src={`${imageUrl}/${bankTransferSelected}`}
                alt='payment-proof'
              />
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setBankTransferSelected(null)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ThubBookingList;
