import React, { useState, useEffect, Fragment } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import thubActions from '../../../crud/thub';
import { Button, Table, Modal, Badge, Tab, Tabs, Image } from 'react-bootstrap';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
import { useDebounce } from './collaboration.constant';
import clsx from 'clsx';
require('bootstrap/scss/bootstrap.scss');
require('./collaboration.scss');

const history = createBrowserHistory();
const ThubCollaborationList = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const [stringToken, setStringToken] = useState();
  const [collaborationData, setCollaborationData] = useState({
    data: [],
    currentPage: 1,
    totalData: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [filterData, setFilterData] = useState({
    event_number: '',
    fullname: '',
    start_date: '',
    end_date: '',
    approval: '',
    event_title: '',
    limitData: 10,
  });
  const [collaborationDetail, setCollaborationDetail] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [approvalValue, setApprovalValue] = useState();
  const [collaborationId, setCollaborationId] = useState();
  const [rejectReason, setRejectReason] = useState('');
  const [eventTitle, setEventTitle] = useState('');

  const getCollaborationList = async (page) => {
    if (stringToken) {
      const {
        event_number,
        fullname,
        start_date,
        end_date,
        approval,
        event_title,
        limitData,
      } = filterData;
      const startDate = start_date ? start_date.split('T').join(' ') : '';
      const endDate = end_date ? end_date.split('T').join(' ') : '';
      const params = `?page=${page || 1}&limit=${limitData}${
        event_number && `&event_number=${event_number}`
      }${fullname && `&fullname=${fullname}`}${
        start_date && `&start_date=${startDate}`
      }${end_date && `&end_date=${endDate}`}${
        approval && `&collaboration_approval_status=${approval}`
      }${event_title && `&event_title=${event_title}`}`;
      try {
        setLoading(true);
        const response = await thubActions.fetchThubCollaborationList(
          stringToken,
          params
        );

        const { data } = response.data;

        setCollaborationData((prev) => ({
          ...prev,
          data: data.collaborationEvents,
          currentPage: data.currentPage,
          totalData: data.totalData,
          totalDataOnPage: data.totalDataOnPage,
          totalPages: data.totalPages,
        }));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setCollaborationData([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else if (e.response.data.code === 404) {
            return;
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      }
    }
  };

  const getCollaborationDetail = async (collaborationId) => {
    setLoadingDetailData(true);
    await thubActions
      .fetchThubCollaborationDetail(stringToken, collaborationId)
      .then((res) => {
        setCollaborationDetail(res.data.data);
        setLoadingDetailData(false);
      })
      .catch((e) => {
        setLoadingDetailData(false);
        setCollaborationDetail([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const handleChangeApproval = async () => {
    setLoading(true);

    const statusData = {
      approval: +approvalValue,
      reject_reason: approvalValue !== '2' ? '-' : rejectReason,
    };

    await thubActions
      .fetchThubChangeCollaborationStatus(
        stringToken,
        collaborationId,
        statusData
      )
      .then(() => {
        setShowApprovalModal(false);
        setLoading(false);
        toast.success('Successfully change approval status');
        setApprovalValue();

        setTimeout(() => {
          getCollaborationList();
        }, 500);
      })
      .catch((e) => {
        setLoading(false);
        setShowApprovalModal(false);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getDateTime = (date) => {
    const fmtDate = moment(date).format('DD MMM YYYY HH:mm');

    return fmtDate;
  };

  const getDateValue = (date) => {
    const fmtDate = moment(date).format('DD MMM YYYY');

    return fmtDate;
  };

  const getApprovalStatus = (id) => {
    switch (id) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';

      default:
        return 'Pending';
    }
  };

  const getBadgeColor = (id) => {
    switch (id) {
      case 0:
        return 'info';
      case 1:
        return 'success';
      case 2:
        return 'danger';

      default:
        return 'primary';
    }
  };

  const handleChangeFilter = (name, value) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePageChange = (page) => {
    setCollaborationData((prev) => ({
      ...prev,
      currentPage: page,
    }));
    getCollaborationList(page);
  };

  const getDate = (date) => {
    const fmtDate = moment(date).format('DD MMM YYYY HH:mm');

    return fmtDate;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getCollaborationList();
    }
  }, [stringToken]);

  // this for debounce filtering data so it wont repeatedly request to api for every changes in filter field
  const debouncedFilterData = useDebounce(filterData, 1000);
  useEffect(() => {
    if (debouncedFilterData) {
      getCollaborationList();
    }
  }, [debouncedFilterData]);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div
          className='kt-portlet__body pddmdForce'
          style={{ padding: '0 20px 20px 20px' }}
        >
          <div className='kt-portlet__preview'>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event Number
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='event_number'
                  placeholder='eg: 948294'
                  value={filterData.event_number}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event Title
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='event_title'
                  placeholder='eg: Blockhain Seminar'
                  value={filterData.event_title}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Speaker Name
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='fullname'
                  placeholder='eg: Evelyn'
                  value={filterData.fullname}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              {/* <div className="col-md-3 col-sm-12">
                <label
                  className="col-md-12"
                  style={{ paddingLeft: '0' }}
                >
                  Filter By Date
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="datetime-local"
                      className="form-control mr-3"
                      name="start_date"
                      placeholder="eg: 24/03/2022"
                      onChange={(e) =>
                        handleChangeFilter(
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="datetime-local"
                      className="form-control mr-3"
                      name="end_date"
                      placeholder="eg: 24/03/2022"
                      onChange={(e) =>
                        handleChangeFilter(
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div> */}

              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Approval
                </label>
                <select
                  className='form-control mr-3'
                  name='approval'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                >
                  <option selected disabled>
                    Select Approval Status
                  </option>
                  <option value={0}>Pending</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div
          className='kt-portlet__body'
          style={{ padding: '0 20px 20px 20px' }}
        >
          <div className='kt-portlet__preview'>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event Start Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='start_date'
                  placeholder='eg: 24/03/2022'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event End Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='end_date'
                  placeholder='eg: 24/03/2022'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='paddupgrade'>
              <div className='collaboration-menu__top'>
                <Link to='/thub/collaboration/create' className='kt-link'>
                  <Button variant='success' style={{ fontSize: '11px' }}>
                    Create New Speaker
                  </Button>
                </Link>
                <select
                  className='form-control collaboration-limit'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                  name='limitData'
                >
                  <option value={5}>5</option>
                  <option selected value={10}>
                    10
                  </option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section' style={{ marginBottom: 0 }}>
              {/* <div className="paddupgrade">
                <Link
                  to="/thub/booking-order/create"
                  className="kt-link"
                >
                  <Button
                    variant="success"
                    style={{ fontSize: '11px' }}
                  >
                    Create Booking Order
                  </Button>
                </Link>
              </div> */}
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          No
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Speaker Name
                          <br />
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Speaker Title
                          <br />
                        </th>
                        {/* <th
                          className="tableheh"
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Tagline 
                        </th> */}

                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          About the Speaker
                        </th>
                        <th
                          className='tableheh'
                          style={{ verticalAlign: 'middle' }}
                        >
                          Event Number
                          {/* <button
                            onClick={(e) =>
                              this.onSortChange('email')
                            }
                            className="sort-button"
                          >
                            <i
                              className={`fas fa-${
                                sortTypes[this.state.orderSort].class
                              }`}
                            />
                          </button> */}
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Title
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Start Date - End Date
                        </th>

                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }}
                        >
                          Status
                        </th>
                        <th className='tableheh'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {collaborationData && collaborationData.length === 0 && (
                        <tr style={{ textAlign: 'center' }}>
                          <td colSpan={9}>No records data found</td>
                        </tr>
                      )}
                      {collaborationData &&
                        collaborationData.data &&
                        collaborationData.data.length > 0 &&
                        collaborationData.data.map((row, i) => (
                          <tr key={row._id}>
                            <td>{i + 1}</td>
                            <td>{row.speaker.fullname}</td>
                            <td>{row.speaker.tittle}</td>
                            <td
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: '175px',
                              }}
                            >
                              {row.speaker.about || '-'}
                            </td>
                            <td>{row.events.event_number || '-'}</td>
                            <td>{row.events.event_title}</td>
                            <td>
                              {row.booking ? (
                                <Fragment>
                                  {getDate(row.booking.start_date)} -{' '}
                                  {getDate(row.booking.end_date)}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {getDate(row.events.start_date_online)} -{' '}
                                  {getDate(row.events.end_date_online)}
                                </Fragment>
                              )}
                            </td>

                            <td>
                              <Badge bg={getBadgeColor(row.approval_status)}>
                                {getApprovalStatus(row.approval_status)}
                              </Badge>
                            </td>
                            {/* <td
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: '175px',
                              }}
                            >
                              {row.events.special_request}
                            </td> */}
                            <td className='center'>
                              <div>
                                {' '}
                                <span
                                  onClick={() => {
                                    getCollaborationDetail(
                                      row.collaboration_id
                                    );
                                    setEventTitle(row.events.event_title);
                                    setShowDetailModal(!showDetailModal);
                                  }}
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className='btn btn-outline-primary'
                                >
                                  View Detail
                                </span>
                                <br />
                                <span
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                  }}
                                  className='btn btn-outline-danger'
                                  onClick={() => {
                                    setShowApprovalModal(true);
                                    setCollaborationId(row.collaboration_id);
                                  }}
                                >
                                  Update Status
                                </span>
                                <Link
                                  to={`/thub/collaboration/${row.collaboration_id}/edit`}
                                >
                                  <span
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className='btn btn-outline-success'
                                  >
                                    Edit Speaker
                                  </span>
                                </Link>
                                <br />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {collaborationData.data && collaborationData.data.length > 0 && (
                    <div className='d-flex justify-content-center align-items-center pagination-data'>
                      <Pagination
                        activePage={collaborationData.currentPage}
                        itemsCountPerPage={collaborationData.totalDataOnPage}
                        itemClass='page-item'
                        linkClass='page-link'
                        totalItemsCount={collaborationData.totalData}
                        pageRangeDisplayed={
                          collaborationData.totalDataOnPage >=
                          filterData.limitData
                            ? collaborationData.totalPages
                            : 1
                        }
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          {collaborationDetail &&
            typeof collaborationDetail.collaboration_id !== 'undefined' && (
              <Modal.Title>
                {`${eventTitle} - ${
                  collaborationDetail.speaker.fullname || ''
                }`}{' '}
              </Modal.Title>
            )}
        </Modal.Header>
        <Modal.Body>
          {loadingDetailData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type={'bars'}
                color={'#0000FF'}
                height={'2%'}
                width={'5%'}
              />
            </div>
          ) : (
            <>
              {collaborationDetail &&
                typeof collaborationDetail.collaboration_id !== 'undefined' && (
                  <Tabs
                    id='controlled-tab-example'
                    defaultActiveKey='detail'
                    // onSelect={key => this.setState({ key })}
                  >
                    <Tab eventKey='detail' title='detail'>
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Event Code</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.event.event_code}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Event Number</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.event.event_number}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Event Title</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.event.event_title}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Event Start - End Hour</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.booking ? (
                                <Fragment>
                                  {getDate(
                                    collaborationDetail.booking.start_date
                                  )}{' '}
                                  -{' '}
                                  {getDate(
                                    collaborationDetail.booking.end_date
                                  )}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {getDate(
                                    collaborationDetail.event.start_date_online
                                  )}{' '}
                                  -{' '}
                                  {getDate(
                                    collaborationDetail.event.end_date_online
                                  )}
                                </Fragment>
                              )}
                              {/* {`${getDateValue(
                                collaborationDetail.booking.start_date
                              )} ${
                                collaborationDetail.event.start_hour
                              }`}{' '}
                              -{' '}
                              {`${getDateValue(
                                collaborationDetail.booking.end_date
                              )} ${
                                collaborationDetail.event.end_hour
                              }`} */}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Created at</strong>
                            </td>
                            <td colSpan={5}>
                              {getDateTime(collaborationDetail.created_at)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Speaker Name</strong>
                            </td>
                            <td colSpan={5}>
                              {`${collaborationDetail.speaker.fullname || ''}`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Speaker Title</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.speaker.tittle || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>About Speaker</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.speaker.about || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Link to personal data</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.speaker
                                .link_to_personal_data || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Instagram</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.speaker.instagram || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Twitter</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.speaker.twitter || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Speaker Image</strong>
                            </td>
                            <td colSpan={5}>
                              {(collaborationDetail.speaker.portrait_image && (
                                <Image
                                  thumbnail
                                  src={
                                    collaborationDetail.speaker.portrait_image
                                  }
                                  alt='gallery-img'
                                  style={{
                                    maxWidth: '200px',
                                    height: 'auto',
                                  }}
                                />
                              )) ||
                                '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Approval Status</strong>
                            </td>
                            <td colSpan={5}>
                              {getApprovalStatus(
                                collaborationDetail.approval_status
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Reject Reason</strong>
                            </td>
                            <td colSpan={5}>
                              {collaborationDetail.reject_reason || '-'}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showApprovalModal}
        onHide={() => setShowApprovalModal(!showApprovalModal)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='m-login__form m-form'
            onSubmit={(e) => {
              e.preventDefault();
              handleChangeApproval();
            }}
          >
            <div>
              <div className='form-group' style={{ marginBottom: '15px' }}>
                <select
                  className='form-control'
                  name='approval'
                  onChange={(e) => {
                    setApprovalValue(e.target.value);
                    setRejectReason();
                  }}
                  required
                >
                  <option selected>Select Approval Status</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                </select>
              </div>

              {approvalValue === '2' && (
                <div className='form-group' style={{ marginBottom: '15px' }}>
                  <input
                    type='text'
                    value={rejectReason}
                    className='form-control'
                    placeholder='Type your reject reason'
                    onChange={(e) => setRejectReason(e.target.value)}
                    required
                  />
                </div>
              )}
            </div>

            <div
              className='kt-login__actions'
              style={{
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <Button
                variant='danger'
                onClick={() => setShowApprovalModal(!showApprovalModal)}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={loading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      loading,
                  }
                )}`}
              >
                Update Status
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThubCollaborationList;
