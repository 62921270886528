export interface MarketMakerParams {
  limit: number;
  page: number;
  q: string;
}
export interface MarketMakerPayload {
  name: string;
  uid: string;
}
export interface CheckDJPSummaryParams {
  month: number;
  year: number;
  bidr_rate: number;
}
export interface SaveRequestDJPParams {
  bidr_rate: number;
  month?: number;
  year?: number;
}
export interface SubmitDJPParams {
  month: number;
  year: number;
}
export interface Paginate<T> {
  total_data: number;
  total_page: number,
  per_page: number,
  current_page: number,
  data: T
}
export interface TaxMarketMaker {
  id: number;
  uid: number;
  name: string;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
export interface CheckDJPSummaryResponse {
  b_commission_asset: number;
  totalFee: number;
}

export interface CheckSaveProcessDJP {
  year: number;
  month: number;
}

export interface CheckSaveProcessDJPResponse {
  created_at:string;
  days_of_month:number;
  days_save:number;
  id:number;
  is_processed:boolean;
  is_saved:boolean;
  month:number;
  on_process:boolean;
  on_save:boolean;
  processed_data:number | null;
  total_data:number;
  updated_at:string;
  usdt_bidr_rate:string;
  year:number;
}

export interface RatesParams {
  month: number;
  year: number;
}

export interface UpdateRatesPayload {
  id: string;
  value: number;
}

export interface Rate{
  close_price: string;
  created_at: string;
  deleted_at: null
  high_price: string;
  id: string;
  low_price: string;
  month: number;
  open_price: string;
  quote: string;
  symbol: string;
  updated_at: string;
  year: number;
}

export interface TaxOtcSaveRequest {
  month: number;
  year: number;
  file: File;
}

export interface TaxOtcSummaryRequest {
  year: number;
}

export enum StatusOtcTax {
  DRAFT = 1,
  ON_PROCESS = 2,
  FINISH = 3,
  FAILED = 5,
}

export const statusOtcTaxs = [
  { label: "Draft", value: StatusOtcTax.DRAFT },
  { label: "On Process", value: StatusOtcTax.ON_PROCESS },
  { label: "Finish", value: StatusOtcTax.FINISH },
  { label: "Failed", value: StatusOtcTax.FAILED },
]

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export enum TypeFilter {
  OTC = "otc",
  VENDOR = "vendor",
  REWARD = "reward",
}
export interface TaxOtcSummary {
  id: number;
  month: number;
  year: number;
  status: StatusOtcTax;
  total_data: number;
  processed_data: number;
  saved_by: string;
  send_by: string | null;
  send_date: string | null;
  created_at: string;
  updated_at: string;
  type?: TypeFilter;
}

export interface TaxOtcCheckSaveProcess {
  year: number;
  month: number;
}

export enum SendStatus {
  NOT_SENT = 0,
  SENT = 1,
  FAILED = -1
}

export const sendStatusOption = [
  { label: "Not Sent", value: SendStatus.NOT_SENT, className: "text-muted" },
  { label: "Sent", value: SendStatus.SENT, className: "text-success" },
  { label: "Failed", value: SendStatus.FAILED, className: "text-danger" },
]

export interface TaxOtcCheckSaveProcessResponse {
  id: number;
  uid: number;
  trx_qty: string;
  trx_price: string;
  market: string;
  trx_amount: string;
  idr: string;
  ppn: string;
  pph: string;
  month_bucket: number;
  year_bucket: number;
  name: string | null;
  country: string | null;
  id_number: number | null;
  send_status: SendStatus;
  djp_response?: string;
}