import { ReactNode } from "react";
import { Action } from "redux";
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type CustomModalType = "DANGER" | "INFO"
export type CustomModalSize = "xl" | "sm" | "lg"

export interface CustomModal {
  id: number;
  type: CustomModalType;
  size?: CustomModalSize;
  show?: boolean;
  onOk?: () => void;
  cancelLabel?: ReactNode;
  onCancel?: () => void;
  closable?: boolean;
  onClosable?: () => void;
  okLabel?: ReactNode;
  title: ReactNode;
  content: ReactNode;
}

export interface InitialUtilsStateProps {
  activeModals: Array<CustomModal>;
}

export const actionTypes = {
  AddModal: "[Add] Modal",
  RemoveModal: "[Remove] Modal",
  HideModal: "[Hide] Modal",
};

const initialAuthState: InitialUtilsStateProps = {
  activeModals: [],
};

export interface ActionPayload extends Action<string> {
  payload: Partial<CustomModal | Pick<CustomModal, "id">>;
}

export const slice = createSlice({
  name: 'utils',
  initialState: initialAuthState,
  reducers: {
    addModal: (state, action: PayloadAction<Omit<CustomModal, "id">>) => {
      if (state.activeModals.length > 0) state.activeModals.push({
        ...action.payload,
        show: true,
        id: state.activeModals[state.activeModals.length - 1].id + 1
      })
      else state.activeModals.push({
        ...action.payload,
        show: true,
        id: 0
      })
      return state;
    },
    removeModal: (state, action: PayloadAction<number>) => {
      state.activeModals = state.activeModals.filter(modal => modal.id !== action.payload)
      return state;
    },
    hideModal: (state, action: PayloadAction<number>) => {
      state.activeModals = state.activeModals.map((modal) => {
        if (modal.id === action.payload) modal.show = false
        return modal
      })
      return state;
    }
  }
})

export const reducer = slice.reducer
export const actions = slice.actions