import React, { useEffect, useRef, useState } from 'react'
import ReusableListPage, { FILTER_TYPE, TRIGGER_BTN_TYPE } from '../../components/reusableListPage/index'
import { Badge, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import { getListOTCDeposit } from '../../otc_services/resources/deposit/deposit_service';
import eventSourceInstance from '../../otc_services/sse'
import moment from 'moment';
import { debounce } from 'lodash';
import { constantDateFormat, numFormatter } from '../../helper';

// "danger" | "warning" | "success" | "primary" | "secondary" | "info" | "light" | "dark"

export const depositStatus = [
  { id: -1, label: "Rejected", theme: "danger" },
  { id: 0, label: "Pending", theme: "warning" },
  { id: 1, label: "Waiting Payment", theme: "info" },
  { id: 2, label: "Paid/Waiting Confirmation", theme: "info" },
  { id: 3, label: "Confirmed/Approved", theme: "success" },
  { id: 4, label: "Failed", theme: "danger" },
  { id: 5, label: "Expired", theme: "warning" },
  { id: 6, label: "Canceled", theme: "primary" },
  { id: 7, label: "Manual Confirmation", theme: "primary" },
  { id: 8, label: "Queue on Process", theme: "warning" },
  { id: 9, label: "Withdraw Requested", theme: "info" },
]

export const withdrawStatus = [
  { id: null, label: "pending / not approved", theme: "warning" },
  { id: 0, label: "Email Sent", theme: "info" },
  { id: 1, label: "Cancelled", theme: "danger" },
  { id: 2, label: "Awaiting Approval", theme: "info" },
  { id: 3, label: "Rejected", theme: "danger" },
  { id: 4, label: "Processing", theme: "info" },
  { id: 5, label: "Failure", theme: "danger" },
  { id: 10, label: "Completed", theme: "success" },
]

const DepositRequest = (props) => {
  const [loading, setLoading] = useState(false)
  const [dataSources, setDataSources] = useState([])

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [keywords, setKeywords] = useState("")

  const [activePage, setActivePage] = useState(1)
  const [totalItemCount, setTotalItemCount] = useState(0)

  const itemsCountPerPage = 15

  const getSelectedDepositStatus = (id) => {
    return depositStatus.find(status => status.id === id)
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      className: "tableheh all-center",
      render: (dataIndex) => <span>{dataIndex + 1}</span>
    },
    {
      title: "UID",
      dataIndex: "customer",
      className: "tableheh",
      render: (_, data) => <span>{data.customer?.tokocrypto_uid || '-'}</span>
    },
    {
      title: "Email",
      dataIndex: "customer",
      className: "tableheh",
      render: (_, data) => <span>{data.customer?.email || '-'}</span>
    },
    {
      title: <div className="text-right">Amount</div>,
      dataIndex: "transfer_amount",
      className: "tableheh",
      render: (dataIndex) => <div className="text-right">{dataIndex && numFormatter(dataIndex)}</div>
    },
    {
      title: "Base Currency",
      dataIndex: "currency",
      className: "tableheh",
      render: (dataIndex) => dataIndex?.toUpperCase()
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      className: "tableheh",
      render: (_, data) => <span>{data.created_at ? constantDateFormat(data.created_at) : '-'}</span>
    },
    {
      title: "Status",
      dataIndex: "deposit_status",
      className: "tableheh center",
      render: (dataIndex) => (getSelectedDepositStatus(dataIndex) &&
        <Badge bg={getSelectedDepositStatus(dataIndex).theme}>
          {getSelectedDepositStatus(dataIndex).label}
        </Badge>
      )
    },
    {
      title: "Action",
      dataIndex: "id",
      className: "tableheh center",
      render: (dataIndex) => (
        <ButtonToolbar style={{ justifyContent: 'center'}}>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-top`}>
                Detail
              </Tooltip>
            }
          >
            <span className="btn-action" onClick={()=>props.history.push(`/otc-finance/deposit-request/${dataIndex}/detail`)}>
              <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye text-info" />
            </span>
          </OverlayTrigger>
        </ButtonToolbar>
      )
    },
  ]

  const filters = [
    {
      type: "DATE_PICKER",
      selected: startDate,
      changeDate: (val) => setStartDate(val),
      placeholderText: "Start Date"
    },
    {
      type: "DATE_PICKER",
      selected: endDate,
      changeDate: (val) => setEndDate(val),
      placeholderText: "End Date"
    },
    {
      type: "INPUT_TEXT",
      onChange: (evt) => setKeywords(evt.target.value),
      value: keywords,
      placeholder: "Search By Email, UIDs"
    },
  ]
  const triggerBtns = [
    {
      type: "BUTTON",
      label: "Search",
      onClick: () => getList(1, {q: keywords, start_date: startDate, end_date: endDate})
    }
  ]

  const getList = (page, params) => {
    setLoading(true)
    if(params.start_date) params.start_date = moment(params.start_date).format("YYYY-MM-DD")
    else params.start_date = undefined
    if(params.end_date) params.end_date = moment(params.end_date).format("YYYY-MM-DD")
    else params.end_date = undefined

    // params.pending_status = true
    getListOTCDeposit({limit: itemsCountPerPage, deposit_status: "0,1,7", page, ...params})
      .then((res) => {
        res.data.data.data.forEach((d, index) => {
          d.no = ((page - 1) * itemsCountPerPage) + index
        })
        setDataSources(res.data.data.data)
        setTotalItemCount(res.data.data.total_data)
      })
      .catch((err)=>{
        console.log(err)
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  const tesSSE = () => {
    const sse = eventSourceInstance("admin/deposit/test")
    sse.addEventListener("open",(evt) => {
      console.log(evt)
    })
    sse.addEventListener("error",(evt) => {
      console.log(evt)
    })
    sse.addEventListener("message",(evt) => {
      console.log(evt)
    })
  }

  const firstInit = () => {
    // tesSSE()
  }

  const debounceProcess = useRef(debounce((newKeywords, newStartDate, newEndDate) => {
    getList(1, {q: newKeywords, start_date: newStartDate, end_date: newEndDate})
  }, 1000))

  const filterListener = () => debounceProcess.current(keywords, startDate, endDate)

  useEffect(firstInit, [])
  useEffect(filterListener, [keywords, startDate, endDate])

  return (
    <ReusableListPage 
      filters={filters}
      triggerBtns={triggerBtns}
      loading={loading}
      dataSources={dataSources}
      columns={columns}
      pagination={{
        activePage,
        itemsCountPerPage,
        totalItemCount,
        onChange: (pageNumber) => {
          getList(pageNumber, {q: keywords, start_date: startDate, end_date: endDate})
          setActivePage(pageNumber)
        }
      }}
    />
  )
}

export default DepositRequest