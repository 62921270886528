import React, { Component, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from './Form';
import services from '../../../crud/tokoscholarUsers';
import moment from 'moment';

const ViewContactUs = (props) => {
  const [isEdit, setisEdit] = useState(true);
  const [detailData, setdetailData] = useState(null);
  const formRef = useRef(null);
  const { id } = useParams();

  const getToken = () => localStorage.getItem('token');

  const getUserDetail = async (id) => {
    try {
      const res = await services.fetchUsersById(getToken(), id);
      setdetailData(res.data.data);
      //   console.log(res, 'res');
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (id) getUserDetail(id);
  }, [id]);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='d-flex justify-content-between align-content-center'>
              <div className=''>
                <div className='d-flex flex-column'>
                  <h3>{detailData?.fullname || ''}</h3>
                  <p>
                    Last Updated on{' '}
                    <b>
                      {detailData?.updated_at &&
                        moment(detailData.updated_at).format(
                          'DD MMM YYYY HH:mm:ss'
                        )}{' '}
                    </b>
                    by <b>{detailData?.updated_by || ''}</b>
                  </p>
                  {/* <p className='d-inline-block font-weight-bold'>User Detail</p> */}
                </div>
              </div>
              <div className='d-flex '>
                {/* <div className='col-md-4'> */}
                <button
                  onClick={() => formRef.current()}
                  type='button'
                  className={`btn btn-outline-success mr-3`}
                >
                  Update
                </button>
                <button
                  onClick={() =>
                    props.history.push(
                      '/tokoscholar/user-courses?' + 'user=' + detailData.id
                    )
                  }
                  type='button'
                  className={`btn btn-outline-success mr-3`}
                >
                  See Course Progress
                </button>
                <button
                  onClick={() => props.history.push('/tokoscholar/users')}
                  type='button'
                  className={`btn btn-outline-danger`}
                >
                  Back to User List
                </button>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <Form submitRef={formRef} isEdit={false} data={detailData} />
            {/* <div className='flex-fill mt-5 d-flex justify-content-end align-items-center'>
              <button
                class='btn btn-outline-warning mr-2'
                type='submit'
                onClick={() => formRef.current()}
              >
                Submit
              </button>
              <button
                class='btn btn-outline-danger '
                type='submit'
                onClick={() => props.history.push('/tokoscholar/courses')}
              >
                Back To Course List
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewContactUs;
