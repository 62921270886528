import React from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import actions from "../../crud/nft";
import SelectDate from '../../components/datetime';
import { Table, Modal, Badge, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
import "react-datepicker/dist/react-datepicker.css";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth
  },
  default: {
    class: 'sort',
    fn: (a, b) => a
  }
};

export default class NFT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingModal: false,
      totalData: 0,
      data:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderByHistory: '',
      orderSort: 'asc',
      active: 1,
      date: null,
      show: false,
    }
    
    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }

    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
  }

  handleShowDetail(id){
    this.setState({ show: true});
    this.getDetail(id);
  }

  handleCloseDetail(){
    this.setState({ show: false });
    this.getData();
  }

  handlePageChangeHistory = (pageNumber) => {
    let activePage = pageNumber;
    this.setState({
      active: activePage
    }, () => {
      this.getData();
    })
  }

  handleSearch = () => {
    this.setState({ active: 1}, () => {
      this.getData()
    })
  }

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  dateTime = date => {
    var fmtDate = moment(date).utc().format('DD/MM/Y hh:mm A');

    return fmtDate;
  }

  getToken(){
    let token = localStorage.getItem("token");
    return token;
  }

  getData() {
    this.setState({
      loading: true
    })

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }

    if(this.state.seacrhParams !== '')
    {
      params += '&q='+ this.state.seacrhParams ;
    }

    if(this.state.date){
      const st = moment(this.state.date).format('Y-MM-DD');
      params += '&date='+st;
    }

    actions.fetchNFTSummary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data?.data?.total_data ?? 0,
        data: res.data?.data?.data ?? [],
        postPerPage: res.data?.data?.per_page ?? 15,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getDetail(id){
    this.setState({
      loadingModal: true
    })

    actions.fetchDetailNFTSummary(this.getToken(), id)
    .then(res => {
      this.setState({
        detailData: res.data.data || [],
        loadingModal: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        detailData:[],
        loadingModal: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  generateExcel(){
    this.setState({
      loading: true
    })

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }

    if(this.state.seacrhParams !== '')
    {
      params += '&q='+ this.state.seacrhParams ;
    }

    if(this.state.date){
      const st = moment(this.state.date).format('Y-MM-DD');
      params += '&date='+st;
    }

    actions.fetchDownloadNFTSummary(this.getToken(), params)
    .then(res => {
      this.setState({
        loading: false,
      })

      let datas = res.data.data
      var url = "data:application/vnd.ms-excel;base64," + datas;
      // you need to precise a front-end button to have a name
      var a = document.createElement("a");
      a.download = `NFT-waitinglist.xlsx`;
      a.href = url;
      a.click();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        dataReward:[],
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  
  componentDidMount(){
    this.getData();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getData()
  };

  render(){
    return (
      <div className="head" style={{fontSize: '11px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body pad-10-20">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-2">
                  <SelectDate changeDate={(val) => this.setState({ date: val })} />
                </div>
                <div className="col-md-8 d-flex">
                  <input 
                    type="text"
                    className="form-control mr-4"
                    name="search"
                    placeholder="Fullname or Email or Phone"
                    value={this.state.seacrhParams}
                    onChange={this.changeSearch} />
                    <ButtonToolbar>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Search Data
                          </Tooltip>
                        }
                      >
                      <button className="btn btn-outline-secondary font-8rem" type="button"  onClick={() => this.handleSearch()} style={{ width: '108px', height: '38px' }}>
                        Search
                      </button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-center align-content-center">
                    <ButtonToolbar>
                      <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Export to Excel
                            </Tooltip>
                          }
                        >
                      <button className="btn btn-outline-success font-8rem" type="button" onClick={() => this.generateExcel()} style={{ width: '108px', height: '38px' }}>
                        Export To Excel
                      </button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ verticalAlign: 'middle', width: '20%' }}>
                        Date Created &nbsp;
                        <button onClick={(e) => this.onSortChange('created_at')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" style={{ width: '25%' }}>
                        Fullname &nbsp;
                        <button onClick={(e) => this.onSortChange('full_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" style={{ width: '25%' }}>
                        Email
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        Phone
                      </th>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        Option &nbsp;
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="5"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.data.map( (row, index) => (
                        <tr key={index}>
                          <td>{this.dateTime(row.created_at)}</td>
                          <td>{row.full_name}</td>
                          <td>{row.email}</td>
                          <td>{row.phone_number}</td>
                          <td className="center">
                            <span 
                              onClick={() => this.handleShowDetail(row.waitinglist_id)}
                              style={{ padding: '2px', fontSize: '0.8rem', width: '100%', marginBottom: '5px' }}
                              className="btn btn-outline-primary">
                              view
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChangeHistory}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleCloseDetail} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.detailData ? this.state.detailData.full_name : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.detailData &&
              <div>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    {this.state.detailData.role === 'creator' ?
                      <Badge bg="success">
                        Creator
                      </Badge> 
                    :
                      <Badge bg="primary">
                        Collector
                      </Badge>
                    }
                  </div>
                  <div className="col-md-12 mb-4">
                    <p className="m-0"><strong>About Me: </strong> </p>
                    <span>{this.state.detailData.about}</span>
                  </div>
                  <div className="col-md-4">
                    <span>
                      <strong>Contact Address</strong>
                    </span>
                    <p><i className="fa fa-envelope-open-text"/>&nbsp;
                      <a href={`mailto:${this.state.detailData.email}`} target="_blank" rel="noopener noreferrer">
                        {this.state.detailData.email}
                      </a>
                    </p>
                    <p><i className="fas fa-phone-square-alt"/>&nbsp; 
                      {/* <a href={this.state.detailData.phone_number} target="_blank" rel="noopener noreferrer">
                      </a> */}
                      {this.state.detailData.phone_number}
                    </p>
                    {this.state.detailData.other !== "" &&
                      <p><i className="fas fa-link"/>&nbsp;
                        {this.state.detailData.other}
                      </p>
                    }
                  </div>
                  <div className="col-md-4">
                    <span>
                      <strong>Portofolios</strong>
                      {this.state.detailData.linkedin !== "" &&
                        <p><i className="fab fa-linkedin"/>&nbsp;
                          {this.state.detailData.linkedin}
                        </p>
                      }
                      {this.state.detailData.behance !== "" &&
                        <p><i className="fab fa-behance-square"/>&nbsp;
                          {this.state.detailData.behance}
                        </p>
                      }
                      {this.state.detailData.dribbble !== "" &&
                        <p><i className="fab fa-dribbble-square"/>&nbsp;
                          {this.state.detailData.dribbble}
                        </p>
                      }
                      {this.state.detailData.spotify !== "" &&
                        <p><i className="fab fa-spotify"/>&nbsp;
                          {this.state.detailData.spotify}
                        </p>
                      }
                      {this.state.detailData.pinterest !== "" &&
                        <p><i className="fab fa-pinterest-square"/>&nbsp;
                          {this.state.detailData.pinterest}
                        </p>
                      }
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span>
                      <strong>Social Media</strong>
                      {this.state.detailData.facebook !== "" &&
                        <p><i className="fab fa-facebook-square"/>&nbsp;
                          {this.state.detailData.facebook}
                        </p>
                      }
                      {this.state.detailData.twitter !== "" &&
                        <p><i className="fab fa-twitter-square"/>&nbsp;
                          {this.state.detailData.twitter}
                        </p>
                      }
                      {this.state.detailData.instagram !== "" &&
                        <p><i className="fab fa-instagram-square"/>&nbsp;
                          {this.state.detailData.instagram}
                        </p>
                      }
                    </span>
                  </div>
                </div>
              </div>
            }
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}