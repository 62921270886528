import React, { ChangeEventHandler, DetailedHTMLProps, FC, InputHTMLAttributes } from "react";

export interface CheckboxCustomProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> { 
  handleClick?: ChangeEventHandler<HTMLInputElement>;
  isChecked: boolean;
}

const Checkbox: FC<CheckboxCustomProps> = ({ id, type, name, handleClick, isChecked }) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
    />
  );
};

export default Checkbox;