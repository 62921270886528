import instance from './tokoscholarInstace';

const getContactData = (token, params) => {
  return instance.get('/admin/contacts', {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
    params,
    // : {
    //   mobile_no: params?.mobile_no || null,
    //   student: params?.student || null,
    //   // (student atau not-student)
    //   page: params?.page || null,
    //   limit: params?.limit || null,
    //   inquiry: params?.inquiry || null,
    //   //  (course-progress, event-tokoscholar, suggestion)
    //   q: params?.q || null,
    //   //  (isinya string bebas nanti buat query name, tokoscrypto_uid sama emai
    // },
  });
};

const getContactById = (token, id) => {
  return instance.get('/admin/contacts/' + id, {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
  });
};

const exportContactData = (token, params) => {
  return instance.get('/admin/contacts/export', {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
    params,
    // : {
    //   mobile_no: params?.mobile_no || null,
    //   student: params?.student || null,
    //   inquiry: params?.inquiry || null,
    //   //  (course-progress, event-tokoscholar, suggestion)
    //   q: params?.q || null,
    //   start_datepa:params,
    //   end_date:
    //   //  (isinya string bebas nanti buat query name, tokoscrypto_uid sama emai
    // },
  });
};

const fetchContactData = (token, query) => {
  return new Promise((resolve, reject) => {
    getContactData(token, query)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchContactById = (token, id) => {
  return new Promise((resolve, reject) => {
    getContactById(token, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchExportContactData = (token, params) => {
  return new Promise((resolve, reject) => {
    exportContactData(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  fetchContactData,
  fetchContactById,
  fetchExportContactData,
};
