// import React, { Suspense, lazy } from "react";
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Builder from '../pages/home/Builder';
import Dashboard from '../pages/home/Dashboard';
import Category from '../pages/category';

import User from '../pages/user/user';
import UserNew from '../pages/user-new/user';
import DetailUserNew from '../pages/user-new/detail';
import UIDConverter from '../pages/user/uidConverter/uidConverter';

import UserDetail from '../pages/user/userDetail';
import UserHistory from '../pages/user/userHistory';
import UserHistoryLevel from '../pages/user/userHistoryLevel';

import DataSubmissionCreate from '../pages/costumerDataUpdate/createDataSubmission';
import DataSubmission from '../pages/costumerDataUpdate/dataSubmissionList';

import Admin from '../pages/admin/admin';
import AddAdmin from '../pages/admin/addAdmin';

import Leaderboard from '../pages/leaderboard/leaderboard';
import addEvent from '../pages/leaderboard/addEvent';
import editEvent from '../pages/leaderboard/addEvent';

import Finance from '../pages/finance/bca';

import Community from '../pages/community/community';
import Config from '../pages/community/config';

import NFT from '../pages/nft/nft';

import Article from '../pages/btc/article';
import ArticleAdd from '../pages/btc/articleAdd';
import ArticleEdit from '../pages/btc/articleEdit';
import Tags from '../pages/btc/tags';

import StaticContent from '../pages/btc/staticContent';
import MorningInsight from '../pages/btc/morningInsight';
import AddMorningInsight from '../pages/btc/addMorningInsight';
import UpdateMorningInsight from '../pages/btc/morningInsightUpdate';

import Compare from '../pages/btc/compare';

import PriceAlert from '../pages/priceAlert/priceAlert';

import Launcher from '../pages/launcher/launcer';
import AddLauncher from '../pages/launcher/addLauncher';
import EditLauncher from '../pages/launcher/addLauncher';
import ManageLink from '../pages/launcher/link';
import AddLink from '../pages/launcher/addLink';
import EditLink from '../pages/launcher/addLink';
import TagLink from '../pages/launcher/tag';

import MiniBanner from '../pages/tkowebsite/miniBanner';
import AddMiniBanner from '../pages/tkowebsite/addMiniBanner';
import UpdateMiniBanner from '../pages/tkowebsite/addMiniBanner';
import ArticleTKO from '../pages/tkowebsite/article';
import AddArticleTKO from '../pages/tkowebsite/addArticle';
import UpdateArticleTKO from '../pages/tkowebsite/addArticle';
import Tag from '../pages/tkowebsite/tag';
// import Newsletter from "../pages/tkowebsite/newsletter";

import OTCBankTransfer from '../pages/otcFinance/bankTransfer';
import OTCBankTransferAdd from '../pages/otcFinance/bankTransferAdd';
import OTCConfiguration from '../pages/otcFinance/configuration';
import OTCCustomers from '../pages/otcFinance/customers';
import OTCCustomersEdit from '../pages/otcFinance/customersEdit';
import OTCDepositRequest from '../pages/otcFinance/depositRequest';
import OTCDepositRequestDetail from '../pages/otcFinance/depositRequestDetail';
import OTCTransactionHistory from '../pages/otcFinance/transactionHistory';
import OTCTransactionHistoryLog from '../pages/otcFinance/transactionHistoryLog';
import OTCSalesReferralCode from '../pages/otcFinance/salesReferralCode';
import OTCSalesReferralCodeEdit from '../pages/otcFinance/salesReferralCodeEdit';
import OTCSalesReferralCodeLog from '../pages/otcFinance/salesReferralCodeLog';

//Tokoscholar Import
import CoursesPage from '../pages/tokoscholar/courses';
import TopicsPage from '../pages/tokoscholar/topics';
import CoursesAdd from '../pages/tokoscholar/courses/AddCourse';
import TopicsAdd from '../pages/tokoscholar/topics/AddTopics';
import CoursesEdit from '../pages/tokoscholar/courses/EditCourse';
import TopicsEdit from '../pages/tokoscholar/topics/EditTopics';
import UsersPage from '../pages/tokoscholar/users/index';
import UsersCoursesPage from '../pages/tokoscholar/userscourse/index';
import DetailUsersPage from '../pages/tokoscholar/users/View';
import EditUsersPage from '../pages/tokoscholar/users/Edit';

import { LayoutSplashScreen } from '../../_metronic';
import ThubUserList from '../pages/thub/users/thubUserList';
import ThubUserDetail from '../pages/thub/users/thubUserDetail';
import ThubUserHistoryLog from '../pages/thub/users/thubUserHistoryLog';
import ThubLocation from '../pages/thub/location/thubLocation';
import ThubLocationEdit from '../pages/thub/location/thubLocationEdit';
import ThubLocationAdd from '../pages/thub/location/thubLocationAdd';
import ThubBookingList from '../pages/thub/bookingList/thubBookingList';
import ThubBookingListEdit from '../pages/thub/bookingList/thubBookingListEdit';
import ThubBookingTypeList from '../pages/thub/bookingType/thubBookingType';
import ThubBookingTypeEdit from '../pages/thub/bookingType/thubBookingTypeEdit';
import ThubBookingTypeCreate from '../pages/thub/bookingType/thubBookingTypeCreate';
import ThubBookingListHistory from '../pages/thub/bookingList/thubBookingListHistory';
import ThubBookingListCreate from '../pages/thub/bookingList/thubBookingListCreate';
import ThubEvents from '../pages/thub/events/thubEvents';
import ThubEventsEdit from '../pages/thub/events/thubEventsEdit';
import ThubEventsCreate from '../pages/thub/events/thubEventsCreate';
import ThubEventsHistory from '../pages/thub/events/thubEventsHistory';
import ThubConfigurationList from '../pages/thub/configurationType/thubConfigurationList';
import ThubConfigurationCreate from '../pages/thub/configurationType/thubConfigurationCreate';
import ThubBookingTypeSeatList from '../pages/thub/bookingType/thubBookingTypeSeatList';
import ThubConfigurationListEdit from '../pages/thub/configurationType/thubConfigurationListEdit';
import ThubCollaborationList from '../pages/thub/collaboration/thubCollaborationList';
import ThubCollaborationEdit from '../pages/thub/collaboration/thubCollaborationEdit';
import ThubEventsData from '../pages/thub/events/thubEventsData';
import ThubCollaborationCreate from '../pages/thub/collaboration/thubCollaborationCreate';
import ContactUsPage from '../pages/tokoscholar/contactus';
import ViewContactUs from '../pages/tokoscholar/contactus/View';
import ThubEventTagsList from '../pages/thub/eventTags/thubEventTagsList';
import ThubEventTagsCreate from '../pages/thub/eventTags/thubEventTagsCreate';
import ThubEventTagsEdit from '../pages/thub/eventTags/thubEventTagsEdit';
import HomeMiddleware from '../pages/middleware/Dashboard';
import SettingMiddleware from '../pages/middleware/Settings';
import MiddlewareKycUser from '../pages/middleware/Channels';
import ManageUser from '../pages/middleware/User';
import Clients from '../pages/middleware/Clients';
import ClientDetail from '../pages/middleware/ClientDetail';
import Channels from '../pages/middleware/Channels';
import CallbackType from '../pages/middleware/CallbackType';
import PaymentMethod from '../pages/middleware/PaymentMethod';
import CallbackItem from '../pages/middleware/CallbackItem';
import Merchants from '../pages/middleware/Merchants';

import TaxMarketMakerList from '../pages/tax/MarketMakerList';
import TaxMarketMakerAdd from '../pages/tax/MarketMakerAdd';
import SetupDJPDocs from '../pages/tax/SetupDJPDocs';
import SetupOTCDJPDocs from '../pages/tax/SetupOTCDJPDocs';
import CoinRates from '../pages/tax/CoinRates';
import { UserContextWrapper } from '../../context/userContext';
import HelpDesk from '../pages/helpDesk';
import HelpDeskEmployee from '../pages/helpDesk/employees';
import HelpDeskEmployeeCreate from '../pages/helpDesk/employeeCreate';
import HelpDeskFaqs from '../pages/helpDesk/faqs';
import HelpDeskFaqCreate from '../pages/helpDesk/faqCreate';
import HelpDeskCategories from '../pages/helpDesk/categories';
import HelpDeskCategoriesCreate from '../pages/helpDesk/categoriesCreate';
import HelpDeskReasons from '../pages/helpDesk/reasons';
import HelpDeskReasonsCreate from '../pages/helpDesk/reasonsCreate';
import HelpDeskAccessList from '../pages/helpDesk/accessList';
import HelpDeskAccessListCreate from '../pages/helpDesk/accessListCreate';
import HelpDeskSystemList from '../pages/helpDesk/systemList';
import HelpDeskSystemListCreate from '../pages/helpDesk/systemListCreate';
import GeneralReport from '../pages/helpDesk/generalReport';
import AccessListReport from '../pages/helpDesk/accesslistReport';

import Sipendar from '../pages/sipendar';
import SipendarReport from '../pages/sipendar/report';
import BursaFTP from '../pages/bursa';
import KYCDasboard from '../pages/user-new/dashboard';
import RefinitivPage from '../pages/refinitiv';
import CSResetRequest from '../pages/cs-reset-request';

export default function HomePage() {
  return (
    <UserContextWrapper>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from='/' to='/dashboard' />
          }
          <Route exact path='/builder' component={Builder} />
          <Route exact path='/dashboard' component={Dashboard} />

          <Route exact path='/category' component={Category} />
          <Route exact path='/user-new/:id/detail' component={DetailUserNew} />
          <Route exact path='/user' component={UserNew} />
          <Route exact path='/user/kyc-dashboard' component={KYCDasboard} />
          {/* <Route exact path='/user' component={User} /> */}
          <Route exact path='/user/uid-converter' component={UIDConverter} />

          <Route path='/user/:id/kyc' component={UserDetail} />
          <Route path='/user/:id/history-level-1' component={UserHistory} />
          <Route path='/user/:id/history-level-2' component={UserHistoryLevel} />

          <Route
            exact
            path='/data-submission/create'
            component={DataSubmissionCreate}
          />
          <Route exact path='/data-submission/list' component={DataSubmission} />
          <Route exact path='/admin' component={Admin} />
          <Route exact path='/admin/add-admin' component={AddAdmin} />
          <Route exact path='/leaderboard' component={Leaderboard} />
          <Route exact path='/leaderboard/addEvent' component={addEvent} />
          <Route exact path='/leaderboard/:id/editEvent' component={editEvent} />
          {/* Tokoscholar */}
          <Route exact path='/tokoscholar/courses' component={CoursesPage} />
          <Route exact path='/tokoscholar/contactus' component={ContactUsPage} />
          <Route
            exact
            path='/tokoscholar/contactus/:id'
            component={ViewContactUs}
          />
          <Route exact path='/tokoscholar/courses/add' component={CoursesAdd} />
          <Route exact path='/tokoscholar/courses/:id' component={CoursesEdit} />
          <Route exact path='/tokoscholar/topics' component={TopicsPage} />
          <Route exact path='/tokoscholar/topics/add' component={TopicsAdd} />
          <Route exact path='/tokoscholar/topics/:id' component={TopicsEdit} />
          <Route exact path='/tokoscholar/users' component={UsersPage} />
          <Route
            exact
            path='/tokoscholar/user-courses'
            component={UsersCoursesPage}
          />
          <Route
            exact
            path='/tokoscholar/users/view/:id'
            component={DetailUsersPage}
          />
          <Route
            exact
            path='/tokoscholar/users/edit/:id'
            component={EditUsersPage}
          />
          {/* End Tokoscholar */}
          {/* <Route exact path='/nft-waiting-list' component={NFT} /> */}
          <Route exact path='/bca-reconsiliation' component={Finance} />
          <Route exact path='/community-tipping' component={Community} />
          <Route exact path='/community-tipping/config' component={Config} />
          <Route exact path='/data-submission/list' component={DataSubmission} />

          <Route exact path='/admin' component={Admin} />
          <Route exact path='/admin/add-admin' component={AddAdmin} />

          <Route exact path='/leaderboard' component={Leaderboard} />
          <Route exact path='/leaderboard/addEvent' component={addEvent} />
          <Route exact path='/leaderboard/:id/editEvent' component={editEvent} />

          {/* <Route exact path='/nft-waiting-list' component={NFT} /> */}

          <Route exact path='/bca-reconsiliation' component={Finance} />

          <Route exact path='/community-tipping' component={Community} />
          <Route exact path='/community-tipping/config' component={Config} />

          <Route
            exact
            path='/btc-microsite/static-content'
            component={StaticContent}
          />
          <Route
            exact
            path='/btc-microsite/morning-insight'
            component={MorningInsight}
          />
          <Route
            exact
            path='/btc-microsite/morning-insight/add'
            component={AddMorningInsight}
          />
          <Route
            exact
            path='/btc-microsite/morning-insight/:id/update'
            component={UpdateMorningInsight}
          />
          <Route exact path='/btc-microsite/article' component={Article} />
          <Route exact path='/btc-microsite/article/add' component={ArticleAdd} />
          <Route
            exact
            path='/btc-microsite/article/:id/update'
            component={ArticleEdit}
          />

          <Route exact path='/btc-microsite/tags' component={Tags} />

          <Route exact path='/btc-microsite/comparation' component={Compare} />

          <Route exact path='/price-alert' component={PriceAlert} />

          <Route exact path='/launcher' component={Launcher} />
          <Route exact path='/launcher/add-launcher' component={AddLauncher} />
          <Route
            exact
            path='/launcher/:id/edit-launcher'
            component={EditLauncher}
          />
          <Route exact path='/launcher/:id/link' component={ManageLink} />
          <Route exact path='/launcher/:id/add-link' component={AddLink} />
          <Route exact path='/launcher/:id/edit-link' component={EditLink} />
          <Route exact path='/launcher/tag' component={TagLink} />

          <Route exact path='/tko-website/mini-banner' component={MiniBanner} />
          <Route
            exact
            path='/tko-website/mini-banner/add'
            component={AddMiniBanner}
          />
          <Route
            exact
            path='/tko-website/mini-banner/:id/edit'
            component={UpdateMiniBanner}
          />
          <Route exact path='/tko-website/article' component={ArticleTKO} />
          <Route
            exact
            path='/tko-website/article/add'
            component={AddArticleTKO}
          />
          <Route
            exact
            path='/tko-website/article/:id/edit'
            component={UpdateArticleTKO}
          />
          <Route exact path='/tko-website/article/tag-config' component={Tag} />
          {/* <Route exact path="/tko-website/newsletter" component={Newsletter} /> */}
          <Route exact path='/otc-finance/customers' component={OTCCustomers} />
          <Route
            exact
            path='/otc-finance/customers/:id/detail'
            component={OTCCustomersEdit}
          />
          <Route
            exact
            path='/otc-finance/customers/:id/edit'
            component={OTCCustomersEdit}
          />
          <Route
            exact
            path='/otc-finance/deposit-request'
            component={OTCDepositRequest}
          />
          <Route
            exact
            path='/otc-finance/deposit-request/:id/detail'
            component={OTCDepositRequestDetail}
          />
          <Route
            exact
            path='/otc-finance/transaction-history'
            component={OTCTransactionHistory}
          />
          <Route
            exact
            path='/otc-finance/transaction-history/:id/log'
            component={OTCTransactionHistoryLog}
          />
          <Route
            exact
            path='/otc-finance/bank-transfer'
            component={OTCBankTransfer}
          />
          <Route
            exact
            path='/otc-finance/bank-transfer/add'
            component={OTCBankTransferAdd}
          />
          <Route
            exact
            path='/otc-finance/bank-transfer/:id/edit'
            component={OTCBankTransferAdd}
          />
          <Route
            exact
            path='/otc-finance/configuration'
            component={OTCConfiguration}
          />
          <Route
            exact
            path='/otc-finance/sales-referral-code'
            component={OTCSalesReferralCode}
          />
          <Route
            exact
            path='/otc-finance/sales-referral-code/:id/edit'
            component={OTCSalesReferralCodeEdit}
          />
          <Route
            exact
            path='/otc-finance/sales-referral-code/:id/log'
            component={OTCSalesReferralCodeLog}
          />

          <Route exact path='/thub/user-list' component={ThubUserList} />
          <Route exact path='/thub/:id/detail' component={ThubUserDetail} />
          <Route
            exact
            path='/thub/:id/:name/log-history'
            component={ThubUserHistoryLog}
          />
          <Route exact path='/thub/location' component={ThubLocation} />
          <Route exact path='/thub/location/add' component={ThubLocationAdd} />
          <Route
            exact
            path='/thub/location/:id/edit'
            component={ThubLocationEdit}
          />
          <Route exact path='/thub/booking-order' component={ThubBookingList} />
          <Route
            exact
            path='/thub/booking-order/:id/edit'
            component={ThubBookingListEdit}
          />
          <Route
            exact
            path='/thub/booking-order/create'
            component={ThubBookingListCreate}
          />
          <Route
            exact
            path='/thub/booking-order/:id/:name/booking-history'
            component={ThubBookingListHistory}
          />

          <Route
            exact
            path='/thub/booking-type'
            component={ThubBookingTypeList}
          />
          <Route
            exact
            path='/thub/booking-type/:id/edit'
            component={ThubBookingTypeEdit}
          />
          <Route
            exact
            path='/thub/booking-type/create'
            component={ThubBookingTypeCreate}
          />
          <Route
            exact
            path='/thub/booking-type/:id/:name/seat-list'
            component={ThubBookingTypeSeatList}
          />

          <Route exact path='/thub/events' component={ThubEvents} />
          <Route
            exact
            path='/thub/events/:id/:code/edit'
            component={ThubEventsEdit}
          />
          <Route exact path='/thub/events/create' component={ThubEventsCreate} />
          <Route
            exact
            path='/thub/events/:id/:name/event-history'
            component={ThubEventsHistory}
          />
          <Route
            exact
            path='/thub/events/:id/:bookingid/:eventnumber/:name/event-data'
            component={ThubEventsData}
          />
          <Route
            exact
            path='/thub/configuration/list'
            component={ThubConfigurationList}
          />
          <Route
            exact
            path='/thub/configuration/create'
            component={ThubConfigurationCreate}
          />
          <Route
            exact
            path='/thub/configuration/:id/edit'
            component={ThubConfigurationListEdit}
          />
          <Route
            exact
            path='/thub/collaboration/list'
            component={ThubCollaborationList}
          />
          <Route
            exact
            path='/thub/collaboration/create'
            component={ThubCollaborationCreate}
          />
          <Route
            exact
            path='/thub/collaboration/:id/edit'
            component={ThubCollaborationEdit}
          />
          <Route
            exact
            path='/thub/event/tags/list'
            component={ThubEventTagsList}
          />
          <Route
            exact
            path='/thub/event/tags/create'
            component={ThubEventTagsCreate}
          />
          <Route
            exact
            path='/thub/event/tags/:id/edit'
            component={ThubEventTagsEdit}
          />
          <Route
            exact
            path='/middleware-kyc-user'
            component={MiddlewareKycUser}
          />
          <Route exact path='/middleware/dashboard' component={HomeMiddleware} />
          <Route
            exact
            path='/middleware/settings'
            component={SettingMiddleware}
          />
          <Route exact path='/middleware/settings/clients' component={Clients} />
          <Route
            exact
            path="/tax/market-maker/list"
            component={TaxMarketMakerList}
          />
          <Route
            exact
            path="/tax/market-maker/add"
            component={TaxMarketMakerAdd}
          />
          <Route
            exact
            path="/tax/market-maker/:id/edit"
            component={TaxMarketMakerAdd}
          />
          <Route
            exact
            path="/tax/djp-document"
            component={SetupDJPDocs}
          />
          <Route
            exact
            path="/tax/non-tradeable-djp-document"
            component={SetupOTCDJPDocs}
          />
          <Route
            exact
            path="/tax/rates"
            component={CoinRates}
          />

          <Route
            exact
            path='/middleware/settings/clients/detail/:id'
            component={ClientDetail}
          />
          <Route
            exact
            path='/middleware/settings/channels'
            component={Channels}
          />
          <Route
            exact
            path='/middleware/settings/callback-type'
            component={CallbackType}
          />
          <Route
            exact
            path='/middleware/settings/callback-item'
            component={CallbackItem}
          />
          <Route
            exact
            path='/middleware/settings/user-management'
            component={ManageUser}
          />
          <Route
            exact
            path='/middleware/settings/payment-method'
            component={PaymentMethod}
          />
          <Route
            exact
            path='/middleware/settings/merchants'
            component={Merchants}
          />
          <Route
            exact
            path='/helpdesk/home'
            component={HelpDesk}
          />
          <Route
            exact
            path='/helpdesk/employees'
            component={HelpDeskEmployee}
          />
          <Route
            exact
            path='/helpdesk/employees/create'
            component={HelpDeskEmployeeCreate}
          />
          <Route
            exact
            path='/helpdesk/employees/:id/update'
            component={HelpDeskEmployeeCreate}
          />
          <Route
            exact
            path='/helpdesk/faqs'
            component={HelpDeskFaqs}
          />
          <Route
            exact
            path='/helpdesk/faqs/create'
            component={HelpDeskFaqCreate}
          />
          <Route
            exact
            path='/helpdesk/faqs/:id/update'
            component={HelpDeskFaqCreate}
          />
          <Route
            exact
            path='/helpdesk/categories'
            component={HelpDeskCategories}
          />
          <Route
            exact
            path='/helpdesk/categories/create'
            component={HelpDeskCategoriesCreate}
          />
          <Route
            exact
            path='/helpdesk/categories/:id/update'
            component={HelpDeskCategoriesCreate}
          />
          <Route
            exact
            path='/helpdesk/reasons'
            component={HelpDeskReasons}
          />
          <Route
            exact
            path='/helpdesk/reasons/create'
            component={HelpDeskReasonsCreate}
          />
          <Route
            exact
            path='/helpdesk/reasons/:id/update'
            component={HelpDeskReasonsCreate}
          />
          <Route
            exact
            path='/helpdesk/access-list'
            component={HelpDeskAccessList}
          />
          <Route
            exact
            path='/helpdesk/access-list/create'
            component={HelpDeskAccessListCreate}
          />
          <Route
            exact
            path='/helpdesk/access-list/:id/update'
            component={HelpDeskAccessListCreate}
          />
          <Route
            exact
            path='/helpdesk/general-report'
            component={GeneralReport}
          />
          <Route
            exact
            path='/helpdesk/access-list-report'
            component={AccessListReport}
          />
          <Route
            exact
            path='/helpdesk/system-list'
            component={HelpDeskSystemList}
          />
          <Route
            exact
            path='/helpdesk/system-list/create'
            component={HelpDeskSystemListCreate}
          />
          <Route
            exact
            path='/helpdesk/system-list/:id/update'
            component={HelpDeskSystemListCreate}
          />
          <Route
            exact
            path='/compliance/terrorist'
            component={Sipendar}
          />
          <Route
            exact
            path='/compliance/sipendar'
            component={Sipendar}
          />
          <Route
            exact
            path='/compliance/report'
            component={SipendarReport}
          />
          <Route
            exact
            path='/compliance/bursa'
            component={BursaFTP}
          />
          <Route
            exact
            path='/user/refinitiv'
            component={RefinitivPage}
          />
          <Route
            exact
            path='/user/reset-request'
            component={CSResetRequest}
          />
          <Redirect to='/error/error-v1' />
        </Switch>
      </Suspense>
    </UserContextWrapper>
  );
}
