import { FC, useReducer } from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "react-bootstrap";
import FormWithFormik from "../../components/reusableFormWithFormik";
import { useFormik } from "formik";
import { Reason, ReasonRequest, StatusRequest } from "../../crud/helpdesk-types";
import { useDebounce } from "../../helper";
import { createReason, getReason, updateReason } from "../../crud/helpdesk";
import Swal from "sweetalert2";
import { SectionWrapper } from "../user-new/user";
import * as yup from "yup";

export const optionsType = [
  { label: "WAITING CONFIRMATION", value: StatusRequest.WAITING_CONFIRMATION },
  { label: "RECEIVED", value: StatusRequest.RECEIVED },
  { label: "WAITING APPROVAL", value: StatusRequest.WAITING_APPROVAL },
  { label: "OPEN", value: StatusRequest.OPEN },
  { label: "ON PROCESSED", value: StatusRequest.ON_PROCESSED },
  { label: "ON HOLD", value: StatusRequest.ON_HOLD },
  { label: "DONE", value: StatusRequest.DONE },
  { label: "REJECT", value: StatusRequest.REJECT },
]

export const reasonType = [
  { label: "CANCEL", value: Reason.CANCEL },
  { label: "APPROVE", value: Reason.APPROVE },
  { label: "REJECT", value: Reason.REJECT },
]

interface InitialState {
  loading: boolean;
}

interface IFormCreate extends Omit<ReasonRequest, "type"> {
  type?: Reason
}

const initialState: InitialState = {
  loading: false,
}

const ReasonCreateHelpdesk: FC<RouteComponentProps<{id?: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  const formikConfig = useFormik<IFormCreate>({
    initialValues: {
      name: "",
      details: "",
    },
    onSubmit: async (values) => {
      dispatch({loading: true})
      try {
        if(values.type) {
          const payload: ReasonRequest = {
            ...values,
            type: values.type
          }
          if(values.id) await updateReason(values.id, payload)
          else await createReason(payload)

          Swal.fire("Success", values.id ? "Successfully updated data!" : "Successfully created data!", "success")
          props.history.push("/helpdesk/reasons")
        }
      }
      catch(err: unknown) {
        console.log(err)
      }
      dispatch({loading: false})
    },
    validationSchema: yup.object({
      name: yup.string().required('Name must be fill!'),
      details: yup.string().required('Details must be fill!'),
    })
  })
  useDebounce<[string | undefined]>(async ()=>{
    try {
      if(props.match.params?.id) {
        const response = await getReason(props.match.params.id)
  
        if(response.data.data) formikConfig.setValues({
          id: props.match.params?.id,
          ...response.data.data,
        })
      }
    }
    catch(err: unknown) {
      console.log(err)
    }
    dispatch({loading: false})
  },[props.match.params?.id], undefined, () => dispatch({loading: true}))
  const isUpdateMode = !!formikConfig.values.id

  return (
    <SectionWrapper>
      <form className="custom-flex-col gap-3" onSubmit={formikConfig.handleSubmit}>
        <h2>{isUpdateMode ? "Update Reason" : "Create Reason"}</h2>
        <FormWithFormik 
          label="Name"
          type="TEXT_INPUT"
          name="name"
          placeholder="Name"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Details"
          type="TEXT_INPUT"
          name="details"
          placeholder="Details"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Type"
          type="SEARCHABLE_SELECT"
          name="type"
          placeholder="Type"
          options={reasonType}
          config={formikConfig}
        />
        <br />
        <div className="custom-flex-row-center">
          <Button 
            onClick={()=>props.history.goBack()} 
            variant="outline-info"
            disabled={state.loading}
          >
            Back
          </Button>
          <Button 
            variant="outline-success" 
            onClick={()=>formikConfig.handleSubmit()}
            disabled={state.loading}
          >
            {isUpdateMode ? "Update Reason" : "Create Reason"}
          </Button>
        </div>
      </form>
    </SectionWrapper>
  )
}

export default ReasonCreateHelpdesk