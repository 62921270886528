import React from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import data from '../../crud/tes.json';
import actions from '../../crud/kyc';
import listData from '../../crud/json';
import SelectDate from '../../components/datetime';
import {
  Button,
  Table,
  Modal,
  Form,
  Badge,
  Tab,
  Tabs,
  Tooltip,
} from 'react-bootstrap';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import PrettyJSON from 'json-stringify-pretty-compact';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import Select from 'react-select';
import PreviewImage from '../../components/previewImage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
require('bootstrap/scss/bootstrap.scss');

const history = createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth,
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth,
  },
  default: {
    class: 'sort',
    fn: (a, b) => a,
  },
};

const screeningClass = {
  0: {
    class: 'warning',
    stat: 'pending',
    name: 'pending',
  },
  1: {
    class: 'success',
    stat: 'approve',
    name: 'low',
  },
  2: {
    class: 'success',
    stat: 'approve',
    name: 'medium',
  },
  3: {
    class: 'danger',
    stat: 'reject',
    name: 'high',
  },
};

const KYCLevel = ['all', 'pending', 'approved', 'rejected'];
const FilterLevels = [
  { label: 'All', value: 'all' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];
const FilterStats = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Rejected', value: 'rejected' },
];
const FilterScreeningRisks = [
  { label: 'All', value: 'all' },
  { label: 'None', value: 'none' },
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
];
const FilterGoverments = [
  { label: 'All', value: 'all' },
  { label: 'Pending/Reject', value: 'Pending/Reject' },
  { label: '-', value: '-' },
  { label: 'Approved', value: 'approved' },
];
const FilterLivenesss = [
  { label: 'All', value: 'all' },
  { label: 'Reject', value: 'reject' },
  { label: 'Pending/Reject', value: 'Pending/Reject' },
  { label: 'Approved', value: 'approve' },
];

export default class User extends React.Component {
  detail(id) {
    window.open('/user/' + id + '/kyc');
    // history.push("/user/" + id + "/kyc");
    // window.location.reload();
  }
  history(id, level) {
    if (level === 1) {
      window.open('/user/' + id + '/history-level-1');
      // history.push("/user/" + id + "/history-level-1");
      // window.location.reload();
    }
    if (level === 2) {
      window.open('/user/' + id + '/history-level-2');
      // history.push("/user/" + id + "/history-level-2");
      // window.location.reload();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      loadingPreview: false,
      data: data,
      loading: false,
      loadingDataModal: false,
      loadingModalData: false,
      totalData: 0,
      userKYC: [],
      name: '',
      idNumber: '',
      pob: '',
      dob: '',
      userKYCDetail: [],
      userPremium: [],
      livenessData: [],
      postPerPage: 10,
      seacrhParams: '',
      orderBy: 'updated_at',
      orderSort: 'desc',
      active: 1,
      show: false,
      show2: false,
      show3: false,
      report: false,
      showDetail: false,
      liveGovtState: '',
      params: '',
      reason: '',
      country: 'all',
      countries: [],
      countriesData: [],
      occupation: 'all',
      occupations: [],
      occupationsData: [],
      SelectDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      KYC: [],
      asliriResult: null,
      gesture: '',
      editStatus: true,
      FilterLevelData: [],
      filterLevel: 'all',
      FilterStatusData: [],
      filterStatus: 'all',
      FilterLivenessData: [],
      filterLiveness: 'all',
      filterGovermentData: [],
      filterGovernment: 'all',
      filterScreeningData: [],
      filterScreening: 'all',
      filterCountry: 'all',
      loadingFilter: false,
      conversionNationality: '',
      conversionCountry: '',
      conversionOccupation: '',
      conversionProvinvce: '',
      visible: false,
      preview: '',
      contentPreview: 'image',
      liveness_video: '',
      showReset: false,
      paramReset: null,
      report_type: 'simple',
      exportKYC1: 'all',
      exportKYC2: 'all',
      reportLiveness: 'all',
      reportGovernment: 'all',
      reportScreening: 'all',
      reportDateType: 'create_date',
      reportAction: 'all',
    };

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({});
      obj[fieldName] = event.target.value;
      obj.resetCapctha = false;
      this.setState(obj);
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShows = this.handleShows.bind(this);
    this.handleCloses = this.handleCloses.bind(this);
    this.showDownload = this.showDownload.bind(this);
    this.closeDownload = this.closeDownload.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
    this.handleResetOpen = this.handleResetOpen.bind(this);
    this.handleResetClose = this.handleResetClose.bind(this);
  }

  handleResetOpen(id) {
    this.setState({ showReset: true, paramReset: id });
  }
  handleResetClose() {
    this.setState({ showReset: false, paramReset: null });
  }

  handleShowDetail(params) {
    this.setState({ showDetail: true, params: params });
    this.getDetailUser(params);
    this.getDataLiveness(params);
  }
  handleCloseDetail() {
    this.setState({ showDetail: false, liveness_video: '' });
    this.checkParams();
  }
  handleStatus(params) {
    if (this.state.editStatus) {
      this.setState({ editStatus: false });
    } else {
      this.setState({ editStatus: true });
      this.getDetailUser(params);
    }
  }

  closeDownload() {
    this.setState({
      report: false,
      report_type: 'simple',
      country: 'all',
      occupation: 'all',
      exportKYC1: 'all',
      exportKYC2: 'all',
      reportLiveness: 'all',
      reportGovernment: 'all',
      reportScreening: 'all',
      reportDateType: 'create_date',
      reportAction: 'all',
    });
  }

  showDownload() {
    this.setState({ report: true });
  }

  handleClose() {
    this.setState({
      show: false,
      editStatus: true,
      liveness_video: '',
    });
    this.checkParams();
  }

  handleShow(id, params) {
    this.setState({ show: true, liveGovtState: id, params: params });
    this.getDetailUser(params);
    this.getDataLiveness(params);
  }

  handleCloses() {
    this.setState({ show2: false, liveness_video: '', reason: '' });
  }

  handleShows(id, params) {
    this.setState({ show2: true, liveGovtState: id, params: params });
    this.getPremium(params);
  }

  getToken() {
    let token = localStorage.getItem('token');
    return token;
  }

  async checkParams() {
    let ord = '';
    if (this.state.orderBy !== '') {
      ord = `&order_by=${this.state.orderBy}`;
    } else {
      ord = '';
    }

    let filterStat = '';
    if (this.state.filterStatus !== 'all') {
      filterStat = `&status=${this.state.filterStatus}`;
    } else {
      filterStat = '';
    }

    let filterLvl = '';
    if (this.state.filterLevel !== 'all') {
      filterLvl = `&level=${this.state.filterLevel}`;
    } else {
      filterLvl = '';
    }
    // else if (
    //   this.state.filterGovernment &&
    //   this.state.filterGovernment.value === '-'
    // ) {
    //   filterGov = `&government_status=0`;
    // } else if (
    //   this.state.filterGovernment &&
    //   this.state.filterGovernment.value === 'approved'
    // ) {
    //   filterGov = `&government_status=1`;
    // } else {
    //   filterGov = '';
    // }

    let filterGov = '';
    if (this.state.filterGovernment === 'Pending/Reject') {
      filterGov = `&government_status=-1`;
    } else if (this.state.filterGovernment === '-') {
      filterGov = `&government_status=0`;
    } else if (this.state.filterGovernment === 'approved') {
      filterGov = `&government_status=1`;
    } else {
      filterGov = '';
    }

    let filterLive = '';
    if (this.state.filterLiveness === 'reject') {
      filterLive = `&liveness_check=-1`;
    } else if (this.state.filterLiveness === 'Pending/Reject') {
      filterLive = `&liveness_check=0`;
    } else if (this.state.filterLiveness === 'approve') {
      filterLive = `&liveness_check=1`;
    } else {
      filterLive = '';
    }

    let filterScreen = '';
    if (this.state.filterScreening === 'none') {
      filterScreen = `&screening_risk=0`;
    } else if (this.state.filterScreening === 'low') {
      filterScreen = `&screening_risk=1`;
    } else if (this.state.filterScreening === 'medium') {
      filterScreen = `&screening_risk=2`;
    } else if (this.state.filterScreening === 'high') {
      filterScreen = `&screening_risk=3`;
    } else if (this.state.filterScreening === 'all') {
      filterScreen = '';
    }

    let filterCountry = '';
    if (this.state.filterCountry !== 'all') {
      filterCountry = `&country=${this.state.filterCountry}`;
    } else {
      filterCountry = '';
    }

    let params = `?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}${ord}${filterStat}${filterLvl}${filterGov}${filterLive}${filterScreen}${filterCountry}`;
    // if(this.state.orderBy !== '' && this.state.filterStatus !== 'all' && this.state.filterLevel !== 'all'){
    //   params=`?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}&status=${this.state.filterStatus}&level=${this.state.filterLevel}`
    // }
    // if(this.state.orderBy !== '' && this.state.filterStatus === 'all' && this.state.filterLevel !== 'all'){
    //   params=`?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}&level=${this.state.filterLevel}`
    // }
    // if(this.state.orderBy !== '' && this.state.filterStatus !== 'all' && this.state.filterLevel === 'all'){
    //   params=`?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}&status=${this.state.filterStatus}`
    // }
    // if(this.state.orderBy !== '' && this.state.filterStatus === 'all' && this.state.filterLevel === 'all'){
    //   params=`?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    // }
    // if(this.state.orderBy === '' && this.state.filterStatus !== 'all' && this.state.filterLevel !== 'all'){
    //   params=`?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}&status=${this.state.filterStatus}&level=${this.state.filterLevel}`
    // }
    // if(this.state.orderBy === '' && this.state.filterStatus !== 'all' && this.state.filterLevel === 'all'){
    //   params=`?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}&status=${this.state.filterStatus}`
    // }
    // if(this.state.orderBy === '' && this.state.filterStatus === 'all' && this.state.filterLevel !== 'all'){
    //   params=`?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}&level=${this.state.filterLevel}`
    // }
    // if(this.state.orderBy === '' && this.state.filterStatus === 'all' && this.state.filterLevel === 'all'){
    //   params = `?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    // }

    this.setState({
      loading: true,
    });

    actions
      .fetchUserKYC(this.getToken(), params)
      .then((res) => {
        window.pushPage = (id) => {
          history.push('customers/' + id + '/kyc');
        };
        window.pushPage = (id) => {
          history.push('customers/' + id + '/history');
        };
        this.setState({
          totalData: res.data.data.total_data,
          userKYC: res.data.data.data,
          postPerPage: res.data.data.per_page,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          userKYC: [],
          loading: false,
        });
        console.log(e.response);
        if (e.response) {
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  async getDataLiveness(param) {
    this.setState({
      loading: true,
    });
    actions
      .fetchLiveness(this.getToken(), param)
      .then((res) => {
        this.setState({
          // gesture:res.data.data.gestures_set || '',
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  getLiveness(id, type) {
    this.setState({
      loadingPreview: true,
    });

    actions
      .fetchLivenessPhoto(this.getToken(), id)
      .then((res) => {
        this.setState({
          loadingPreview: false,
        });
        if (type === 'preview') {
          const image = 'data:image/jpg;base64,' + res.data.data[0];
          this.previewImage(image);
        } else {
          var a = document.createElement('a');
          // a.href = "data:text/plain;base64," + res.data.data;
          // a.download = `${id}.zip`;
          a.href = 'data:image/jpg;base64,' + res.data.data[0];
          a.download = `${id}`;
          a.click();
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loadingPreview: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  onSortChange = async (name) => {
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });

    this.checkParams();
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  };

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber;
    this.setState(
      {
        active: activePage,
      },
      () => {
        this.checkParams();
      }
    );
  };

  handleSearch = () => {
    this.setState({ active: 1 }, () => {
      this.checkParams();
    });
  };

  dateTime = (date) => {
    var date1 = date;
    var fmtDate = moment(date1)
      .utc()
      .format('DD-MM-Y hh:m A');

    return fmtDate;
  };

  async liveGovt(id) {
    this.setState({
      loading: true,
    });

    let data = {
      status: parseInt(id),
    };

    if (this.state.liveGovtState === '1') {
      actions
        .confirmKYCLiveness(this.getToken(), this.state.params, data)
        .then((res) => {
          this.setState({
            loading: false,
            show: false,
          });
          this.checkParams();
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        });
    }
    if (this.state.liveGovtState === '2') {
      actions
        .confirmKYCGovernment(
          this.getToken(),
          this.state.params,
          data
        )
        .then((res) => {
          this.setState({
            loading: false,
            show: false,
          });
          this.checkParams();
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        });
    }
  }

  async confirmKyc(id) {
    this.setState({
      loading: true,
    });

    let data = {
      status: parseInt(id),
      reject_reason: this.state.reason,
      level: parseInt(this.state.liveGovtState),
    };

    if (this.state.liveGovtState === '1') {
      actions
        .confirmKYC(this.getToken(), this.state.params, data)
        .then((res) => {
          this.setState({
            loading: false,
            show2: false,
            reason: '',
          });
          this.checkParams();
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
            show2: false,
          });
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        });
    }
    if (this.state.liveGovtState === '2') {
      actions
        .confirmKYC(this.getToken(), this.state.params, data)
        .then((res) => {
          this.setState({
            loading: false,
            show2: false,
          });
          this.checkParams();
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
            show2: false,
          });
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        });
    }
  }

  async getDetailUser(params) {
    this.setState({
      loadingDataModal: true,
    });
    actions
      .fetchDetailUserKYC(this.getToken(), params)
      .then((res) => {
        const getNationality =
          this.state.countriesData.find(
            (country) => country.Iso2 === res.data.data.nationality
          ) || {};
        const nationality = getNationality.Name || '';

        const getCountry =
          this.state.countriesData.find(
            (country) => country.Iso2 === res.data.data.country
          ) || {};
        const country = getCountry.Name || '';

        const province =
          this.state.provinces[res.data.data.province] || '';
        const occupation =
          this.state.occupationsData[res.data.data.occupation] || '';

        this.setState({
          userKYCDetail: res.data.data,
          name: res.data.data.name,
          idNumber: res.data.data.id_number,
          pob: res.data.data.place_of_birth,
          dob: res.data.data.date_of_birth,
          conversionNationality: nationality,
          conversionCountry: country,
          conversionProvinvce: province,
          conversionOccupation: occupation,
          loadingDataModal: false,
          liveness_video: res.data.data.liveness_video || '',
        });
      })
      .catch((e) => {
        this.setState({
          // userKYCDetail:[],
          loadingDataModal: false,
        });
        if (e.response) {
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        } else {
          console.log(e);
        }
      });
  }

  previewImage = (url) => {
    this.setState({
      visible: true,
      preview: url,
      contentPreview: 'image',
    });
  };

  disabledImagePreview = () => {
    this.setState({
      visible: false,
      preview: '',
    });
  };

  getPhoto(id, type) {
    this.setState({
      loadingPreview: true,
    });

    if (id === '' || id === null) {
      alert('Data Not Found');
      setTimeout(() => {
        this.setState({
          loadingPreview: false,
        });
      }, 500);
    } else {
      actions
        .fetchPhoto(this.getToken(), id)
        .then((res) => {
          setTimeout(() => {
            this.setState({
              loadingPreview: false,
            });
          }, 500);
          if (type === 'preview') {
            const image = 'data:image/jpg;base64,' + res.data;
            this.previewImage(image);
          } else {
            var a = document.createElement('a');
            a.href = 'data:image/jpg;base64,' + res.data;
            a.download = `${id}`;
            a.click();
          }
        })
        .catch((e) => {
          console.log(e);
          setTimeout(() => {
            this.setState({
              loadingPreview: false,
            });
          }, 500);
          if (e.response) {
            if (e.response.data.code === 401) {
              toast.error('token has expired');
              localStorage.clear();
              history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  }

  previewVideo = (url) => {
    this.setState({
      visible: true,
      preview: url,
      contentPreview: 'video',
    });
  };

  getVideoLiveness(id, type) {
    this.setState({
      loadingPreview: true,
    });

    if (id === '' || id === null) {
      alert('Data Not Found');
      setTimeout(() => {
        this.setState({
          loadingPreview: false,
        });
      }, 500);
    } else {
      actions
        .fetchPhoto(this.getToken(), id)
        .then((res) => {
          setTimeout(() => {
            this.setState({
              loadingPreview: false,
            });
          }, 500);
          if (type === 'preview') {
            const data = 'data:video/mp4;base64,' + res.data;
            this.previewVideo(data);
          } else {
            var a = document.createElement('a');
            a.href = 'data:image/jpg;base64,' + res.data;
            a.download = `${id}`;
            a.click();
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loadingPreview: false,
          });
          if (e.response) {
            if (e.response.data.code === 401) {
              toast.error('token has expired');
              localStorage.clear();
              history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  }

  previewPDF = (url) => {
    this.setState({
      visible: true,
      preview: url,
      contentPreview: 'pdf',
    });
  };

  getProofOfAddress(dataProof, type) {
    this.setState({
      loadingPreview: true,
    });

    if (dataProof === '' || dataProof === null) {
      alert('File Not Found');
      this.setState({
        loadingPreview: false,
      });
    } else {
      actions
        .fetchPhoto(this.getToken(), dataProof)
        .then((res) => {
          setTimeout(() => {
            this.setState({
              loadingPreview: false,
            });
          }, 500);

          if (type === 'preview') {
            const extension = dataProof.split('.')[
              dataProof.split('.').length - 1
            ];
            let data = '';
            if (extension === 'pdf') {
              data =
                'data:application/pdf;base64, ' + encodeURI(res.data);
              this.previewPDF(data);
            } else {
              data = 'data:image/jpg;base64,' + res.data;
              this.previewImage(data);
            }
          } else {
            var a = document.createElement('a');
            a.href =
              'data:application/octet-stream;base64,' + res.data;
            a.download = `${dataProof}`;
            a.click();
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loadingPreview: false,
          });
          if (e.response.data) {
            if (e.response.data.code === 401) {
              toast.error('token has expired');
              localStorage.clear();
              history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  }

  getCountry() {
    listData.fetchCountry(this.getToken()).then((res) => {
      let countryData = [{ label: ' All', value: 'all' }];
      res.data.data.map((c) => {
        return countryData.push({
          label: c.Name,
          value: c.Iso2,
        });
      });

      countryData = _.orderBy(countryData, ['label'], ['asc']); // Use Lodash to sort array by 'name'

      this.setState({
        countries: countryData,
        countriesData: res.data.data,
      });
    });
  }

  getProvinces() {
    listData.fetchProvince(this.getToken()).then((res) => {
      this.setState({
        provinces: res.data.data,
      });
    });
  }

  getOccupations() {
    listData.fetchOccupation(this.getToken()).then((res) => {
      let occupationData = [{ label: 'All', value: 'null' }];
      Object.entries(res.data.data).forEach(([key, value]) =>
        occupationData.push({
          label: value,
          value: key,
        })
      );
      this.setState({
        occupations: occupationData,
        occupationsData: res.data.data,
      });
    });
  }

  updateDate(prop, value) {
    let proj = this.state.SelectDate;
    proj[prop] = value;

    this.setState({
      SelectDate: proj,
    });
  }

  exportDataExcel() {
    // report_type: 'simple',
    //   exportKYC1: 'all',
    //   exportKYC2: 'all',
    //   reportLiveness: 'all',
    //   reportGovernment: 'all',
    //   reportScreening: 'all',
    //   reportDateType: 'create_date',
    //   reportAction: 'all'

    let data = {
      type: this.state.report_type,
      nationality: this.state.country,
      occupation: parseInt(this.state.occupation),
      kyc_lvl_1: this.state.exportKYC1,
      kyc_lvl_2: this.state.exportKYC2,
      liveness_check: this.state.reportLiveness,
      government_status: this.state.reportGovernment,
      screening_risk: this.state.reportScreening,
      date_source: this.state.reportDateType,
      action_by: this.state.reportAction,
      date_from: moment(this.state.SelectDate.startDate).format(
        'YYYY-MM-DD'
      ),
      date_to: moment(this.state.SelectDate.endDate).format(
        'YYYY-MM-DD'
      ),
    };

    // if(this.state.reportDateType === 'action_date'){
    //   data['action_by']= this.state.reportAction
    // }

    this.setState({
      loading: true,
    });
    this.closeDownload();

    actions.fetchExportData(this.getToken(), data).then((res) => {
      this.setState({
        loading: false,
      });

      let csvContent = atob(res.data.data);
      var url = window.URL.createObjectURL(
        new Blob([csvContent], {
          type: 'data:application/octet-stream;base64',
        })
      );
      // you need to precise a front-end button to have a name
      var a = document.createElement('a');
      a.download = `${moment(this.state.SelectDate.startDate).format(
        'YYYY-MM-DD'
      )}.csv`;
      a.href = url;
      a.click();
    });
  }

  async getPremium(param) {
    this.setState({
      loading: true,
    });
    actions
      .fetchPremium(this.getToken(), param)
      .then((res) => {
        this.setState({
          userPremium: res.data.data || [],
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          userPremium: [],
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  getPremiumFile(id, type) {
    this.setState({
      loadingPreview: true,
    });

    actions
      .fetchPhoto(this.getToken(), id)
      .then((res) => {
        this.setState({
          loadingPreview: false,
        });
        if (type === 'preview') {
          const extension = id.split('.')[id.split('.').length - 1];
          let data = '';
          if (extension === 'pdf') {
            data =
              'data:application/pdf;base64, ' + encodeURI(res.data);
            this.previewPDF(data);
          } else {
            data = 'data:image/jpg;base64,' + res.data;
            this.previewImage(data);
          }
        } else {
          var a = document.createElement('a');
          a.href = 'data:application/pdf;base64,' + res.data;
          a.download = `${id}`;
          a.click();
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  updateKYC(id) {
    this.setState({
      loading: true,
    });
    let data = {
      name: this.state.name,
      id_number: this.state.idNumber,
      place_of_birth: this.state.pob,
      date_of_birth: this.state.dob,
    };
    actions
      .updateDetailKYC(this.getToken(), id, data)
      .then((res) => {
        this.setState({
          loading: false,
          editStatus: true,
          show: false,
          // editStatus: false
        });
        this.getDetailUser(id);
      })
      .catch((e) => {
        this.setState({
          loading: false,
          editStatus: true,
          show: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
        this.getDetailUser(id);
      });
  }

  reset(stat) {
    this.setState({
      loading: true,
      showReset: false,
    });

    let data = {
      reset_status: stat,
    };

    actions
      .resetKYC(this.getToken(), this.state.paramReset, data)
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(`message: ${res.data.data.msg}`);
        }
        this.setState({
          loading: false,
        });
        this.checkParams();
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        this.checkParams();
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  componentDidMount() {
    this.checkParams();
    // this.getPremium();
    this.getCountry();
    this.getProvinces();
    this.getOccupations();
    // this.liveGovt();

    let countriesKYC = [];
    KYCLevel.map((c) => {
      return countriesKYC.push({
        label: _.capitalize(c),
        value: c,
      });
    });

    let FilterScreeningRisk = [];
    FilterScreeningRisks.map((c) => {
      return FilterScreeningRisk.push({
        label: c.label,
        value: c.value,
      });
    });

    let FilterFilterGoverment = [];
    FilterGoverments.map((c) => {
      return FilterFilterGoverment.push({
        label: c.label,
        value: c.value,
      });
    });

    let FilterLiveness = [];
    FilterLivenesss.map((c) => {
      return FilterLiveness.push({
        label: c.label,
        value: c.value,
      });
    });

    let FilterLvl = [];
    FilterLevels.map((c) => {
      return FilterLvl.push({
        label: c.label,
        value: c.value,
      });
    });

    let Filterstat = [];
    FilterStats.map((c) => {
      return Filterstat.push({
        label: c.label,
        value: c.value,
      });
    });

    this.setState({
      KYC: countriesKYC,
      FilterLevelData: FilterLvl,
      FilterStatusData: Filterstat,
      filterScreeningData: FilterScreeningRisk,
      filterGovermentData: FilterFilterGoverment,
      FilterLivenessData: FilterLiveness,
    });
  }

  filterStatus = async (e) => {
    await this.setState({
      filterStatus: e,
      active: 1,
    });
    this.checkParams();
  };
  filterLevel = async (e) => {
    await this.setState({
      filterLevel: e,
      active: 1,
    });
    this.checkParams();
  };
  filterLiveness = async (e) => {
    await this.setState({
      filterLiveness: e,
      active: 1,
    });
    this.checkParams();
  };
  filterGovernment = async (e) => {
    await this.setState({
      filterGovernment: e,
      active: 1,
    });
    this.checkParams();
  };
  filterScreening = async (e) => {
    await this.setState({
      filterScreening: e,
      active: 1,
    });
    this.checkParams();
  };

  filterCountry = async (e) => {
    await this.setState({
      filterCountry: e,
      active: 1,
    });
    this.checkParams();
  };

  render() {
    let rule = localStorage.getItem('rule');

    const { result } =
      this.state.userKYCDetail.goverment_result || '';
    let trx_id = '';
    let rate = 0;
    let obj;
    if (result) {
      obj = JSON.parse(result);
      trx_id = obj.trx_id || '';
      if (obj) {
        const subObj = obj.data || 0;
        if (subObj) rate = obj.data.selfie_photo || 0;
      }
    }

    const reportType = [
      {
        label: 'Simple Report',
        value: 'simple',
      },
      {
        label: 'Peruri Report',
        value: 'peruri_report',
      },
    ];
    const livenessCheck = [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
      {
        label: 'Rejected',
        value: 'rejected',
      },
    ];
    const screningRisk = [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
    ];
    const dateType = [
      {
        label: 'Created Date',
        value: 'create_date',
      },
      {
        label: 'Action Date',
        value: 'action_date',
      },
    ];
    const reportAction = [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Peruri',
        value: 'peruri',
      },
      {
        label: 'Auto',
        value: 'auto',
      },
    ];

    const CheckFilterTooltip = (props) => {
      return (
        <Tooltip id="filter-tooltip" {...props}>
          You haven't set any filter yet
        </Tooltip>
      );
    };

    return (
      <div style={{ fontSize: '11px' }} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body pddmdForce">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex">
                  <input
                    style={{ width: '80%' }}
                    type="text"
                    className="form-control mr-3"
                    name="search"
                    placeholder="Search"
                    value={this.state.seacrhParams}
                    onChange={this.changeSearch}
                  />
                  <button
                    className="btn btn-outline-secondary mr-5 font-8rem"
                    type="button"
                    style={{ width: '10%' }}
                    onClick={() => this.handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-outline-success font-8rem"
                    style={{ width: '15%' }}
                    onClick={() => this.showDownload()}
                  >
                    Export Excel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddmdForce">
            <div className="kt-portlet__preview">
              <div className="row">
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginBottom: '10px' }}
                >
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By KYC Level
                  </label>
                  <Select
                    defaultValue={{ label: 'All', value: 'all' }}
                    value={this.state.FilterLevelData.value}
                    options={this.state.FilterLevelData}
                    onChange={(e) => {
                      this.filterLevel(e.value);
                    }}
                  />
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginBottom: '10px' }}
                >
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By KYC Status
                  </label>
                  <Select
                    defaultValue={{ label: 'All', value: 'all' }}
                    value={this.state.FilterStatusData.value}
                    options={this.state.FilterStatusData}
                    onChange={(e) => {
                      this.filterStatus(e.value);
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Liveness
                  </label>
                  <Select
                    defaultValue={{ label: 'All', value: 'all' }}
                    value={this.state.FilterLivenessData.value}
                    options={this.state.FilterLivenessData}
                    onChange={(e) => {
                      this.filterLiveness(e.value);
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Goverment
                  </label>
                  <Select
                    defaultValue={{ label: 'All', value: 'all' }}
                    value={this.state.filterGovermentData.value}
                    options={this.state.filterGovermentData}
                    onChange={(e) => {
                      this.filterGovernment(e.value);
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Screening Risk
                  </label>
                  <Select
                    defaultValue={{ label: 'All', value: 'all' }}
                    value={this.state.filterScreeningData.value}
                    options={this.state.filterScreeningData}
                    onChange={(e) => {
                      this.filterScreening(e.value);
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Country
                  </label>
                  <Select
                    options={this.state.countries}
                    value={this.state.countries.value}
                    defaultValue={{ label: 'All', value: 'all' }}
                    onChange={(e) => {
                      this.filterCountry(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                {this.state.loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ReactLoading
                      type={'bars'}
                      color={'#0000FF'}
                      height={'2%'}
                      width={'5%'}
                    />
                  </div>
                ) : (
                  <>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className="tableheh"
                            style={{
                              verticalAlign: 'middle',
                              width: '7%',
                            }}
                          >
                            User ID &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{ verticalAlign: 'middle' }}
                          >
                            Email &nbsp;
                            <button
                              onClick={(e) =>
                                this.onSortChange('email')
                              }
                              className="sort-button"
                            >
                              <i
                                className={`fas fa-${
                                  sortTypes[this.state.orderSort]
                                    .class
                                }`}
                              />
                            </button>
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Govt Database &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Liveness &nbsp;
                            <br />
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Name Screning &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            KYC Level 1 &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            KYC Level 2 &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Country &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Last Updated &nbsp;
                            <button
                              onClick={(e) =>
                                this.onSortChange('updated_at')
                              }
                              className="sort-button"
                            >
                              <i
                                className={`fas fa-${
                                  sortTypes[this.state.orderSort]
                                    .class
                                }`}
                              />
                            </button>
                          </th>
                          {/* <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          CreatedBy &nbsp;
                        </th> */}
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Risk Level &nbsp;
                            {/* <button onClick={(e) => this.onSortChange('screening_risk')} className="sort-button">
                            <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                          </button> */}
                          </th>
                          <th className="center tableheh">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.userKYC.map((row) => (
                          <tr key={row.id}>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.ref_id}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.email}{' '}
                              {row.external_kycs[0].liveness_video !==
                              null ? (
                                <sup className="peruri">PERURI</sup>
                              ) : (
                                ''
                              )}
                            </td>
                            <td
                              className="center"
                              style={{ verticalAlign: 'middle' }}
                            >
                              {row.government_status === -1 &&
                                row.kyc_status === 0 &&
                                row.external_kycs[0]
                                  .liveness_video === null && (
                                  <Badge
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.handleShow('2', row.ref_id)
                                    }
                                    bg="warning"
                                  >
                                    pending
                                  </Badge>
                                )}
                              {row.government_status === -1 &&
                                row.kyc_status === 0 &&
                                row.external_kycs[0]
                                  .liveness_video !== null && (
                                  <Badge bg="danger">
                                    reject
                                  </Badge>
                                )}
                              {row.government_status === -1 &&
                                row.kyc_status !== 0 && (
                                  <Badge bg="danger">
                                    reject
                                  </Badge>
                                )}
                              {row.government_status === 0 &&
                                row.external_kycs[0]
                                  .liveness_video !== null && (
                                  <Badge
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.handleShow('2', row.ref_id)
                                    }
                                    bg="warning"
                                  >
                                    pending
                                  </Badge>
                                )}
                              {row.government_status === 0 &&
                                row.external_kycs[0]
                                  .liveness_video === null && (
                                  <span> - </span>
                                )}
                              {row.government_status === 1 && (
                                <Badge bg="success">
                                  approved
                                </Badge>
                              )}
                              {row.government_status === 2 && (
                                <span> - </span>
                              )}
                            </td>
                            <td
                              className="center"
                              style={{ verticalAlign: 'middle' }}
                            >
                              {row.liveness_check === -1 &&
                                row.kyc_status === 0 &&
                                row.external_kycs[0]
                                  .liveness_video === null && (
                                  <Badge
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.handleShow('1', row.ref_id)
                                    }
                                    bg="warning"
                                  >
                                    pending
                                  </Badge>
                                )}
                              {row.liveness_check === -1 &&
                                row.kyc_status === 0 &&
                                row.external_kycs[0]
                                  .liveness_video !== null && (
                                  <Badge bg="danger">
                                    reject
                                  </Badge>
                                )}
                              {row.liveness_check === -1 &&
                                row.kyc_status !== 0 && (
                                  <Badge bg="danger">
                                    reject
                                  </Badge>
                                )}
                              {row.liveness_check === 0 && (
                                <Badge
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    this.handleShow('1', row.ref_id)
                                  }
                                  bg="warning"
                                >
                                  pending
                                </Badge>
                              )}
                              {row.liveness_check === 1 && (
                                <Badge bg="success">
                                  approve
                                </Badge>
                              )}
                            </td>
                            <td
                              className="center"
                              style={{ verticalAlign: 'middle' }}
                            >
                              {row.screening_risk === 0 && (
                                <span> - </span>
                              )}
                              {row.screening_risk === 1 && (
                                <Badge bg="success">
                                  approve
                                </Badge>
                              )}
                              {row.screening_risk === 2 && (
                                <Badge bg="success">
                                  approve
                                </Badge>
                              )}
                              {row.screening_risk === 3 &&
                                row.kyc_status === -1 && (
                                  <Badge bg="danger">
                                    reject
                                  </Badge>
                                )}
                              {row.screening_risk === 3 &&
                                row.kyc_status === 0 && (
                                  <Badge bg="warning">
                                    pending
                                  </Badge>
                                )}
                              {row.screening_risk === 3 &&
                                row.kyc_status === 1 && (
                                  <Badge bg="success">
                                    approve
                                  </Badge>
                                )}
                              {/* {row.kyc_level === 1 && row.kyc_status === 0 && <Badge style={{cursor: 'pointer'}} onClick={() => this.handleShows('1', row.ref_id)} bg={`${statusClass[row.kyc_status ].class}`}>{`${statusClass[row.kyc_status ].stat}`}</Badge>}
                            {row.kyc_level === 1 && row.kyc_status === -1 && <Badge bg={`${statusClass[row.kyc_status ].class}`}>{`${statusClass[row.kyc_status ].stat}`}</Badge>}
                            {row.kyc_level === 1 && row.kyc_status === 1 && <Badge bg={`${statusClass[row.kyc_status ].class}`}>{`${statusClass[row.kyc_status ].stat}`}</Badge>} */}
                            </td>
                            <td
                              className="center"
                              style={{ verticalAlign: 'middle' }}
                            >
                              {row.kyc_level === 1 &&
                                row.kyc_status === -1 && (
                                  <Badge
                                    bg="danger"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.handleResetOpen(row.id)
                                    }
                                  >
                                    reject
                                  </Badge>
                                )}
                              {(rule === 'superuser' ||
                                rule === 'compliance') &&
                                row.kyc_level === 1 &&
                                row.kyc_status === 0 && (
                                  <Badge
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.handleShows(
                                        '1',
                                        row.ref_id
                                      )
                                    }
                                    bg="warning"
                                  >
                                    pending
                                  </Badge>
                                )}
                              {rule === 'cs' &&
                                row.kyc_level === 1 &&
                                row.kyc_status === 0 && (
                                  <Badge bg="warning">
                                    pending
                                  </Badge>
                                )}
                              {((row.kyc_level === 1 &&
                                row.kyc_status === 1) ||
                                row.kyc_level === 2) && (
                                <Badge bg="success">
                                  approve
                                </Badge>
                              )}
                              {/* {row.kyc_level === 1 && row.kyc_status === 1 && <Badge style={{cursor: 'pointer'}} onClick={() => this.handleShows('2', row.ref_id)} variant="warning">pending</Badge>} */}
                              {/* {row.kyc_level === 1 && <span> - </span>} */}
                            </td>
                            <td
                              className="center"
                              style={{ verticalAlign: 'middle' }}
                            >
                              {row.kyc_level === 2 &&
                                row.kyc_status === -1 && (
                                  <Badge
                                    bg="danger"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.handleResetOpen(row.id)
                                    }
                                  >
                                    reject
                                  </Badge>
                                )}
                              {(rule === 'superuser' ||
                                rule === 'compliance') &&
                                row.kyc_level === 2 &&
                                row.kyc_status === 0 && (
                                  <Badge
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.handleShows(
                                        '2',
                                        row.ref_id
                                      )
                                    }
                                    bg="warning"
                                  >
                                    pending
                                  </Badge>
                                )}
                              {rule === 'cs' &&
                                row.kyc_level === 2 &&
                                row.kyc_status === 0 && (
                                  <Badge bg="warning">
                                    pending
                                  </Badge>
                                )}
                              {row.kyc_level === 2 &&
                                row.kyc_status === 1 && (
                                  <Badge bg="success">
                                    approve
                                  </Badge>
                                )}
                              {/* {row.kyc_level === 1 && row.kyc_status === 1 && <Badge style={{cursor: 'pointer'}} onClick={() => this.handleShows('2', row.ref_id)} variant="warning">pending</Badge>} */}
                              {row.is_reset === 1 ? (
                                <Badge bg="info">reset</Badge>
                              ) : (
                                row.kyc_level === 1 && (
                                  <span> - </span>
                                )
                              )}
                            </td>
                            <td
                              className="center"
                              style={{ verticalAlign: 'middle' }}
                            >
                              {row.external_kycs && (
                                <>
                                  {row.external_kycs.length > 0 ? (
                                    <>
                                      {
                                        row.external_kycs[
                                          row.external_kycs.length - 1
                                        ].country
                                      }
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {this.dateTime(row.updated_at)}
                            </td>
                            {/* <td className="center" style={{ verticalAlign: 'middle'}}>
                            {row.created_by}
                          </td> */}
                            <td
                              className="center"
                              style={{ verticalAlign: 'middle' }}
                            >
                              {row.screening_risk !== 0 ? (
                                <Badge
                                  bg={`${
                                    screeningClass[row.screening_risk]
                                      .class
                                  }`}
                                >{`${
                                  screeningClass[row.screening_risk]
                                    .name
                                }`}</Badge>
                              ) : (
                                <span> - </span>
                              )}
                            </td>
                            <td className="center">
                              <div>
                                {/* <span onClick={() => this.detail(row.ref_id)} style={{ cursor:'pointer', color: 'blue' }}>view</span> */}
                                <span
                                  onClick={() =>
                                    this.handleShowDetail(row.ref_id)
                                  }
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className="btn btn-outline-primary"
                                >
                                  view
                                </span>
                                <br />
                                <span
                                  onClick={() =>
                                    this.history(row.ref_id, 1)
                                  }
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className="btn btn-outline-primary"
                                >
                                  History Level 1
                                </span>
                                <br />
                                <span
                                  onClick={() =>
                                    this.history(row.ref_id, 2)
                                  }
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                  }}
                                  className="btn btn-outline-primary"
                                >
                                  History Level 2
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center align-items-center pagination-data">
                      <Pagination
                        activePage={this.state.active}
                        itemsCountPerPage={this.state.postPerPage}
                        itemClass="page-item"
                        linkClass="page-link"
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <Tabs
              id="controlled-tab-example"
              defaultActiveKey="home"
              // onSelect={key => this.setState({ key })}
            >
              <Tab eventKey="home" title="Home">
                {this.state.liveGovtState === '2' ? (
                  <div className="row">
                    <div
                      className="col-sm-12 margin-botHalf"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {this.state.editStatus ? (
                        <Button
                          variant="primary"
                          onClick={this.handleStatus}
                        >
                          Edit
                        </Button>
                      ) : (
                        <div>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.updateKYC(this.state.params)
                            }
                          >
                            Submit
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() =>
                              this.handleStatus(this.state.params)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </div>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name</strong>
                          </td>
                          <td>
                            {this.state.editStatus ? (
                              this.state.name
                            ) : (
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                style={{ width: '50%' }}
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Place Of Birth</strong>
                          </td>
                          <td>
                            {this.state.editStatus ? (
                              this.state.pob
                            ) : (
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.pob}
                                onChange={this.handleChange('pob')}
                                style={{ width: '50%' }}
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Date of Birth</strong>
                          </td>
                          <td>
                            {this.state.editStatus ? (
                              this.state.dob
                            ) : (
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.dob}
                                onChange={this.handleChange('dob')}
                                style={{ width: '50%' }}
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>ID Number</strong>
                          </td>
                          <td>
                            {this.state.editStatus ? (
                              this.state.idNumber
                            ) : (
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.idNumber}
                                onChange={this.handleChange(
                                  'idNumber'
                                )}
                                style={{ width: '50%' }}
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>ID Type</strong>
                          </td>
                          <td>
                            {this.state.userKYCDetail.id_type ===
                            0 ? (
                              <span>Identity Card</span>
                            ) : (
                              <span>Passport</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Asliri Rating</strong>
                          </td>
                          <td>{rate}</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                        </tr>
                      </tbody>
                    </Table>
                    {this.state.loadingPreview ? (
                      <img
                        src="/media/loading.gif"
                        className="img-loading"
                        alt=""
                      />
                    ) : (
                      <div className="mb-4 container-button">
                        <div className="grouping-button-small">
                          <button
                            type="button"
                            className="btn btn-in-group btc-s"
                          >
                            ID Card
                          </button>
                          <button
                            type="button"
                            className="btn btn-in-group btn-outline btn-without-border-lr"
                            onClick={() =>
                              this.getPhoto(
                                this.state.userKYCDetail.id_photo,
                                'download'
                              )
                            }
                          >
                            Download
                          </button>
                          <button
                            type="button"
                            className="btn btn-in-group btn-outline"
                            onClick={() =>
                              this.getPhoto(
                                this.state.userKYCDetail.id_photo,
                                'preview'
                              )
                            }
                          >
                            Preview
                          </button>
                        </div>
                        <div className="grouping-button-small">
                          <button
                            type="button"
                            className="btn btn-in-group btc-s"
                          >
                            Selfie
                          </button>
                          <button
                            type="button"
                            className="btn btn-in-group btn-outline btn-without-border-lr"
                            onClick={() =>
                              this.getPhoto(
                                this.state.userKYCDetail.selfie_photo,
                                'download'
                              )
                            }
                          >
                            Download
                          </button>
                          <button
                            type="button"
                            className="btn btn-in-group btn-outline"
                            onClick={() =>
                              this.getPhoto(
                                this.state.userKYCDetail.selfie_photo,
                                'preview'
                              )
                            }
                          >
                            Preview
                          </button>
                        </div>
                      </div>
                    )}
                    {(rule === 'superuser' ||
                      rule === 'compliance') &&
                    this.state.userKYCDetail.liveness_video ===
                      null ? (
                      <>
                        <div
                          className="col-sm-6 margin-botHalf"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            style={{ width: '50%' }}
                            variant="danger"
                            onClick={() => this.handleClose()}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div
                          className="col-sm-6 margin-botHalf"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            disabled={this.state.loading}
                            style={{ width: '50%' }}
                            variant="primary"
                            onClick={() => this.liveGovt('1')}
                          >
                            Approve
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div
                        className="col-sm-12 margin-botHalf"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          variant="danger"
                          onClick={() => this.handleClose()}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="row">
                    {this.state.liveness_video !== '' ? (
                      <>
                        <div className="col-sm-12 margin-botHalf d-flex justify-content-center">
                          <div className="mb-4 container-button">
                            <div className="grouping-button-small">
                              <button
                                type="button"
                                className="btn btn-in-group btc-s"
                              >
                                Liveness
                              </button>
                              <button
                                type="button"
                                className="btn btn-in-group btn-outline btn-without-border-lr"
                                onClick={() =>
                                  this.getVideoLiveness(
                                    this.state.liveness_video,
                                    'download'
                                  )
                                }
                              >
                                Download
                              </button>
                              <button
                                type="button"
                                className="btn btn-in-group btn-outline"
                                onClick={() =>
                                  this.getVideoLiveness(
                                    this.state.liveness_video,
                                    'preview'
                                  )
                                }
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-sm-12 margin-botHalf"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            variant="danger"
                            onClick={() => this.handleClose()}
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-sm-4 margin-botHalf">
                          Gesture Set
                        </div>
                        <div className="col-sm-8 margin-botHalf">
                          {' '}
                          : &nbsp; {this.state.gesture}
                        </div>
                        <div
                          className="col-sm-12 margin-botHalf"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '30px',
                          }}
                        >
                          <div className="mb-4 container-button">
                            <div className="grouping-button-small">
                              <button
                                type="button"
                                className="btn btn-in-group btc-s"
                              >
                                Liveness
                              </button>
                              <button
                                type="button"
                                className="btn btn-in-group btn-outline btn-without-border-lr"
                                onClick={() =>
                                  this.getLiveness(
                                    this.state.params,
                                    'download'
                                  )
                                }
                              >
                                Download
                              </button>
                              <button
                                type="button"
                                className="btn btn-in-group btn-outline"
                                onClick={() =>
                                  this.getLiveness(
                                    this.state.params,
                                    'preview'
                                  )
                                }
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                        {rule === 'superuser' ||
                        rule === 'compliance' ? (
                          <>
                            <div
                              className="col-sm-6 margin-botHalf"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                disabled={this.state.loading}
                                variant="primary"
                                onClick={() => this.liveGovt('1')}
                              >
                                Approve
                              </Button>
                            </div>
                            <div
                              className="col-sm-6 margin-botHalf"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant="danger"
                                onClick={() => this.handleClose()}
                              >
                                Cancel
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div
                            className="col-sm-12 margin-botHalf"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              variant="danger"
                              onClick={() => this.handleClose()}
                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Tab>
              {this.state.liveGovtState === '2' ? (
                <Tab eventKey="asliri" title="Asliri">
                  <AceEditor
                    width="50"
                    mode="json"
                    theme="github"
                    readOnly={true}
                    value={PrettyJSON(obj)}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                  />
                </Tab>
              ) : null}
            </Tabs>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.show2}
          onHide={this.handleCloses}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>KYC Level {this.state.level}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.liveGovtState === '2' &&
              this.state.userPremium && (
                <div className="row">
                  <div className="col-sm-12">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              width: '30%',
                            }}
                          >
                            <strong>Action By</strong>
                          </td>
                          <td className="center">
                            {this.state.userPremium.action_by}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              width: '30%',
                            }}
                          >
                            <strong>Timestamp</strong>
                          </td>
                          <td className="center">
                            {this.dateTime(
                              this.state.userPremium.updated_at
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              width: '30%',
                            }}
                          >
                            <strong>Bank Statement</strong>
                          </td>
                          <td>
                            <div className="container-button">
                              {this.state.loadingPreview ? (
                                <img
                                  src="/media/loading.gif"
                                  className="img-loading"
                                  alt=""
                                />
                              ) : (
                                <div className="grouping-button-new">
                                  <button
                                    type="button"
                                    className="btn btn-in-group btn-outline btn-without-border-lr"
                                    onClick={() =>
                                      this.getPremiumFile(
                                        this.state.userPremium
                                          .bank_statement,
                                        'download'
                                      )
                                    }
                                  >
                                    Download
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-in-group btn-outline"
                                    onClick={() =>
                                      this.getPremiumFile(
                                        this.state.userPremium
                                          .bank_statement,
                                        'preview'
                                      )
                                    }
                                  >
                                    Preview
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              width: '30%',
                            }}
                          >
                            <strong>Source Of Found</strong>
                          </td>
                          <td>
                            <div className="container-button">
                              {this.state.loadingPreview ? (
                                <img
                                  src="/media/loading.gif"
                                  className="img-loading"
                                  alt=""
                                />
                              ) : (
                                <>
                                  <div className="grouping-button-new">
                                    <button
                                      type="button"
                                      className="btn btn-in-group btn-outline btn-without-border-lr"
                                      onClick={() =>
                                        this.getPremiumFile(
                                          this.state.userPremium
                                            .source_of_wealth,
                                          'download'
                                        )
                                      }
                                    >
                                      Download
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-in-group btn-outline"
                                      onClick={() =>
                                        this.getPremiumFile(
                                          this.state.userPremium
                                            .source_of_wealth,
                                          'preview'
                                        )
                                      }
                                    >
                                      Preview
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            <div
              style={{
                textAlign: 'center',
                display: 'grid',
                padding: '0 0.75rem',
              }}
            >
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="left-modal">
                  <strong style={{ color: '#000' }}>
                    Reject Reason
                  </strong>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={this.state.reason}
                  onChange={(e) =>
                    this.setState({
                      reason: e.target.value,
                      required: false,
                    })
                  }
                />
                {this.state.required && (
                  <span
                    style={{
                      textAlign: 'left',
                      color: 'red',
                      display: 'block',
                    }}
                  >
                    Please insert reason before rejected
                  </span>
                )}
              </Form.Group>
              <Button
                disabled={this.state.loading}
                variant="primary"
                onClick={() => this.confirmKyc('1')}
              >
                Approve
              </Button>
              <br />
              <Button
                disabled={this.state.loading}
                variant="danger"
                onClick={() => this.confirmKyc('-1')}
              >
                Reject
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.report}
          onHide={this.closeDownload}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header closeButton /> */}
          <Modal.Body style={{ textAlign: 'center' }}>
            <div className="row">
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">Report Type</label>
                <Select
                  defaultValue={{
                    label: 'Simple Report',
                    value: 'simple',
                  }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={reportType.value}
                  options={reportType}
                  onChange={(val) => {
                    this.setState({ report_type: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">Nationality</label>
                <Select
                  defaultValue={{ label: 'All', value: 'all' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={this.state.countries.value}
                  options={this.state.countries}
                  onChange={(val) => {
                    this.setState({ country: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">Occupation</label>
                <Select
                  defaultValue={{ label: 'All', value: 'null' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={this.state.occupations.value}
                  options={this.state.occupations}
                  onChange={(val) => {
                    this.setState({ occupation: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">KYC Level 1</label>
                <Select
                  defaultValue={{ label: 'All', value: 'all' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={this.state.KYC.value}
                  options={this.state.KYC}
                  onChange={(val) => {
                    this.setState({ exportKYC1: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">KYC Level 2</label>
                <Select
                  defaultValue={{ label: 'All', value: 'all' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={this.state.KYC.value}
                  options={this.state.KYC}
                  onChange={(val) => {
                    this.setState({ exportKYC2: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">Liveness Check Status</label>
                <Select
                  defaultValue={{ label: 'All', value: 'all' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={livenessCheck.value}
                  options={livenessCheck}
                  onChange={(val) => {
                    this.setState({ reportLiveness: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">
                  Goverment Check Status
                </label>
                <Select
                  defaultValue={{ label: 'All', value: 'all' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={livenessCheck.value}
                  options={livenessCheck}
                  onChange={(val) => {
                    this.setState({ reportGovernment: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">Screening Risk</label>
                <Select
                  defaultValue={{ label: 'All', value: 'all' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={screningRisk.value}
                  options={screningRisk}
                  onChange={(val) => {
                    this.setState({ reportScreening: val.value });
                  }}
                />
              </div>
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">Date Type</label>
                <Select
                  defaultValue={{
                    label: 'Create At',
                    value: 'create_date',
                  }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={dateType.value}
                  options={dateType}
                  onChange={(val) => {
                    this.setState({ reportDateType: val.value });
                  }}
                />
              </div>
              {/* {this.state.reportDateType === "action_date" && */}
              <div
                className="col-md-12"
                style={{ marginBottom: '5px', textAlign: 'start' }}
              >
                <label className="label">Action By</label>
                <Select
                  defaultValue={{ label: 'All', value: 'all' }}
                  name="form-field-name"
                  style={{ marginBottom: '5px' }}
                  value={reportAction.value}
                  options={reportAction}
                  onChange={(val) => {
                    this.setState({ reportAction: val.value });
                  }}
                />
              </div>
              {/* } */}
              <div
                className="col-sm-6"
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label className="label">Start Date</label>
                <SelectDate
                  changeDate={(val) =>
                    this.updateDate('startDate', val)
                  }
                  selected={this.state.SelectDate.startDate}
                />
              </div>
              <div
                className="col-sm-6"
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label className="label">End Date</label>
                <SelectDate
                  changeDate={(val) =>
                    this.updateDate('endDate', val)
                  }
                  selected={this.state.SelectDate.endDate}
                />
              </div>
              <div
                className="col-sm-6"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                <Button
                  style={{ width: '50%' }}
                  variant="primary"
                  onClick={() => this.closeDownload()}
                >
                  Cancel
                </Button>
              </div>
              <div
                className="col-sm-6"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                <Button
                  style={{ width: '50%' }}
                  variant="danger"
                  onClick={() => this.exportDataExcel()}
                >
                  download
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showDetail}
          onHide={this.handleCloseDetail}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.userKYCDetail.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loadingDataModal ? (
              ''
            ) : (
              <>
                <Tabs
                  id="controlled-tab-example"
                  defaultActiveKey="home"
                  // onSelect={key => this.setState({ key })}
                >
                  <Tab eventKey="home" title="Home">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Nationality</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.conversionNationality}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>ID Number</strong>
                          </td>
                          <td>
                            {this.state.userKYCDetail.id_number}
                          </td>
                          <td>
                            <strong>ID Type</strong>
                          </td>
                          <td colSpan="3">
                            {this.state.userKYCDetail.id_type ===
                            0 ? (
                              <span>Identity Card</span>
                            ) : (
                              <span>Passport</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email</strong>
                          </td>
                          <td>{this.state.userKYCDetail.email}</td>
                          <td>
                            <strong>Gender</strong>
                          </td>
                          <td colSpan="3">
                            {this.state.userKYCDetail.gender === 0 ? (
                              <span>Female</span>
                            ) : (
                              <span>Male</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Place & Date of Birth</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userKYCDetail.place_of_birth},{' '}
                            {this.state.userKYCDetail.date_of_birth}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Address</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userKYCDetail.address}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Province</strong>
                          </td>
                          <td>{this.state.conversionProvinvce}</td>
                          <td>
                            <strong>Zip Code</strong>
                          </td>
                          <td colSpan="3">
                            {this.state.userKYCDetail.zip_code}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Occupation</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.conversionOccupation}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Phone</strong>
                          </td>
                          <td>{this.state.userKYCDetail.phone_no}</td>
                          <td>
                            <strong>Mobile Phone</strong>
                          </td>
                          <td colSpan="3">
                            {this.state.userKYCDetail.mobile_no}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Screening Risk</strong>
                          </td>
                          <td>
                            {this.state.userKYCDetail
                              .screening_risk === 0 && (
                              <span> - </span>
                            )}
                            {this.state.userKYCDetail
                              .screening_risk === 1 && (
                              <span> low </span>
                            )}
                            {this.state.userKYCDetail
                              .screening_risk === 2 && (
                              <span> medium </span>
                            )}
                            {this.state.userKYCDetail
                              .screening_risk === 3 && (
                              <span> high </span>
                            )}
                          </td>
                          <td>
                            <strong>Asliri Rate</strong>
                          </td>
                          <td colSpan="3">{rate}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Trx id</strong>
                          </td>
                          <td colSpan={5}>
                            {trx_id === '' || trx_id === null ? 'none' : trx_id}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Action By</strong>
                          </td>
                          <td>
                          {this.state.userKYCDetail.action_by === '' ||
                          this.state.userKYCDetail.action_by === null
                            ? 'none'
                            : this.state.userKYCDetail.action_by}
                          </td>

                          <td>
                            <strong>Liveness At</strong>
                          </td>
                          <td colSpan='3'>
                            {this.state.userKYCDetail.user_liveness_at
                              ? moment(
                                  this.state.userKYCDetail.user_liveness_at
                                ).format('YYYY-MM-DD H:mm:ss')
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Rejected At</strong>
                          </td>
                          <td>
                            {this.state.userKYCDetail.rejected_at
                              ? this.dateTime(
                                  this.state.userKYCDetail.rejected_at
                                )
                              : '-'}
                          </td>
                          <td>
                            <strong>Auto Approved</strong>
                          </td>
                          <td colSpan='3'>
                            {this.state.userKYCDetail?.auto_approve === 1
                              ? 'Approved'
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Reject Reason</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userKYCDetail.reject_reason
                              ? this.state.userKYCDetail.reject_reason
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>NPWP</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userKYCDetail.npwp
                              ? this.state.userKYCDetail.npwp
                              : '-'}
                          </td>
                        </tr>
                        {this.state.userKYCDetail.reset_by && (
                          <tr>
                            <td>
                              <strong>Reset By</strong>
                            </td>
                            <td colSpan="5">
                              {this.state.userKYCDetail.reset_by}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>
                            <strong>ID CARD</strong>
                          </td>
                          <td>
                            <div className="container-button">
                              {this.state.loadingPreview ? (
                                <img
                                  src="/media/loading.gif"
                                  className="img-loading"
                                  alt=""
                                />
                              ) : (
                                <>
                                  <div className="grouping-button-new">
                                    <button
                                      type="button"
                                      className="btn btn-in-group btn-outline btn-without-border-lr"
                                      onClick={() =>
                                        this.getPhoto(
                                          this.state.userKYCDetail
                                            .id_photo,
                                          'download'
                                        )
                                      }
                                    >
                                      Download
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-in-group btn-outline"
                                      onClick={() =>
                                        this.getPhoto(
                                          this.state.userKYCDetail
                                            .id_photo,
                                          'preview'
                                        )
                                      }
                                    >
                                      Preview
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <strong>Liveness</strong>
                          </td>
                          <td>
                            <div className="container-button">
                              {this.state.loadingPreview ? (
                                <img
                                  src="/media/loading.gif"
                                  className="img-loading"
                                  alt=""
                                />
                              ) : (
                                <div className="grouping-button-new">
                                  {this.state.liveness_video ===
                                  '' ? (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-in-group btn-outline btn-without-border-lr"
                                        onClick={() =>
                                          this.getLiveness(
                                            this.state.params,
                                            'download'
                                          )
                                        }
                                      >
                                        Download
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-in-group btn-outline"
                                        onClick={() =>
                                          this.getLiveness(
                                            this.state.params,
                                            'preview'
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-in-group btn-outline btn-without-border-lr"
                                        onClick={() =>
                                          this.getVideoLiveness(
                                            this.state.userKYCDetail
                                              .liveness_video,
                                            'download'
                                          )
                                        }
                                      >
                                        Download
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-in-group btn-outline"
                                        onClick={() =>
                                          this.getVideoLiveness(
                                            this.state.userKYCDetail
                                              .liveness_video,
                                            'preview'
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          {this.state.userKYCDetail.nationality !==
                          'ID' ? (
                            <>
                              <td style={{ verticalAlign: 'middle' }}>
                                <strong>KITAS/KITAP</strong>
                              </td>
                              <td>
                                <div className="container-button">
                                  {this.state.loadingPreview ? (
                                    <img
                                      src="/media/loading.gif"
                                      className="img-loading"
                                      alt=""
                                    />
                                  ) : (
                                    <div className="grouping-button-new">
                                      <button
                                        type="button"
                                        className="btn btn-in-group btn-outline btn-without-border-lr"
                                        onClick={() =>
                                          this.getProofOfAddress(
                                            this.state.userKYCDetail
                                              .proof_of_address,
                                            'download'
                                          )
                                        }
                                      >
                                        Download
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-in-group btn-outline"
                                        onClick={() =>
                                          this.getProofOfAddress(
                                            this.state.userKYCDetail
                                              .proof_of_address,
                                            'preview'
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </td>
                              {this.state.liveness_video === '' ? (
                                <>
                                  <td
                                    style={{
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    <strong>SELFIE</strong>
                                  </td>
                                  <td>
                                    <div className="container-button">
                                      {this.state.loadingPreview ? (
                                        <img
                                          src="/media/loading.gif"
                                          className="img-loading"
                                          alt=""
                                        />
                                      ) : (
                                        <div className="grouping-button-new">
                                          <button
                                            type="button"
                                            className="btn btn-in-group btn-outline btn-without-border-lr"
                                            onClick={() =>
                                              this.getPhoto(
                                                this.state
                                                  .userKYCDetail
                                                  .selfie_photo,
                                                'download'
                                              )
                                            }
                                          >
                                            Download
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-in-group btn-outline"
                                            onClick={() =>
                                              this.getPhoto(
                                                this.state
                                                  .userKYCDetail
                                                  .selfie_photo,
                                                'preview'
                                              )
                                            }
                                          >
                                            Preview
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td colSpan="2"></td>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.liveness_video === '' ? (
                                <>
                                  <td
                                    style={{
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    <strong>SELFIE</strong>
                                  </td>
                                  <td>
                                    <div className="container-button">
                                      {this.state.loadingPreview ? (
                                        <img
                                          src="/media/loading.gif"
                                          className="img-loading"
                                          alt=""
                                        />
                                      ) : (
                                        <div className="grouping-button-new">
                                          <button
                                            type="button"
                                            className="btn btn-in-group btn-outline btn-without-border-lr"
                                            onClick={() =>
                                              this.getPhoto(
                                                this.state
                                                  .userKYCDetail
                                                  .selfie_photo,
                                                'download'
                                              )
                                            }
                                          >
                                            Download
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-in-group btn-outline"
                                            onClick={() =>
                                              this.getPhoto(
                                                this.state
                                                  .userKYCDetail
                                                  .selfie_photo,
                                                'preview'
                                              )
                                            }
                                          >
                                            Preview
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td colSpan="2"></td>
                                </>
                              ) : (
                                <>
                                  <td colSpan="4"></td>
                                </>
                              )}
                            </>
                          )}
                        </tr>
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="asliri" title="Asliri">
                    <AceEditor
                      width="50"
                      mode="json"
                      theme="github"
                      readOnly={true}
                      value={PrettyJSON(obj)}
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Tab>
                </Tabs>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showReset}
          onHide={this.handleResetClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="close"
              onClick={() => this.handleResetClose()}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <div className="d-flex">
              <div className="swal2-header">
                <ul
                  className="swal2-progress-steps"
                  style={{ display: 'none' }}
                ></ul>
                <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
                  <div className="swal2-icon-content">!</div>
                </div>
                <img
                  className="swal2-image"
                  alt=""
                  style={{ display: 'none' }}
                />
                <h3>Are you sure want to reset KYC user? </h3>
              </div>
            </div>
            <div className="swal2-content">
              <span>You won't be able to revert this!</span>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Button
                style={{ margin: '0.3em' }}
                variant="primary"
                onClick={() => this.reset(1)}
              >
                Set to Pending
              </Button>
              <Button
                style={{ margin: '0.3em' }}
                variant="success"
                onClick={() => this.reset(0)}
              >
                Reset KYC
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <PreviewImage
          active={this.state.visible}
          contentData={this.state.contentPreview}
          url={this.state.preview}
          disablePreview={() => this.disabledImagePreview()}
        />
      </div>
    );
  }
}
