import React from 'react';
import ReactLoading from 'react-loading';
import clsx from "clsx";
// import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import actions from "../../crud/microsite";
import { Button, Table, Modal, OverlayTrigger, ButtonToolbar, Tooltip, Badge } from "react-bootstrap";
import { TextField } from '@material-ui/core';
// import { VisibilityOff, Visibility } from '@material-ui/icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
// import _ from 'lodash';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth
  },
  default: {
    class: 'sort',
    fn: (a, b) => a
  }
};

export default class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      totalData: 0,
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      params: '',
      data: [],
      status: '0',
      name: '',
      type: 'create',
      idEdit : null
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(id, typeButton){
    this.setState({ show: true, type: typeButton, idEdit: id});
    if(typeButton === 'update'){
      this.getDetail(id);
    }
  }
  handleClose(){
    this.setState({ show: false, name:'', status:'0', idEdit: null});
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }
  
  async checkParams () {
    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = `?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }
    

    this.setState({
      loading: true
    })
    actions.fetchTags(this.getToken(), params)
    .then(res => {
      this.setState({
        data:res.data.data.data,
        loading: false,
        totalData: res.data.data.total_data
      });
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.checkParams()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.checkParams()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getDetail(id){
    this.setState({
      loading: true
    })

    actions.fetchDetailTag(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
        // show: false,
        status: res.data.data.status.toString(),
        name: res.data.data.name,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    }) 
  }

  handleDeleteOpen(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(id);
      }
    })
  }

  delete(id){
    this.setState({
      loading: true
    })

    actions.deleteDataTags(this.getToken(), id)
    .then(res => {      
      if(res.data.code === 200){
        toast.success("Delete success");
      }
      this.setState({
        loading: false,
      })
      this.checkParams();
    })
    .catch(e => {
      this.setState({
        loading: false,
        showDelete: false,
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    })
  }

  isSubmit = event => {
    const dataTag = {
      // status: parseInt(this.state.status),
      status: this.state.status.toString(),
      name: this.state.name,
    }
    event.preventDefault();
    // const validate = this.isValidate();
    // if(validate){
      // };
    if(this.state.type === 'create'){
      this.addTag(dataTag);
    }
    else{
      this.editTag(dataTag);
    }
  }

  addTag(dataTag){
    this.setState({
      loading: true
    })

    actions.postTags(this.getToken(), dataTag)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new tag success");
      }
      this.setState({
        loading: false,
        show: false,
        name:'',
        status:'0',
      })
      this.checkParams();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        show: false,
        name:'',
        status:'0',
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  editTag(dataTag){
    this.setState({
      loading: true
    })
    actions.putTags(this.getToken(), this.state.idEdit, dataTag)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Update is success");
      }
      this.setState({
        loading: false,
        show: false,
        name:'',
        status:'0',
        idEdit: null,
      })
      this.checkParams();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        show: false,
        name:'',
        status:'0',
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  
  componentDidMount(){
    this.checkParams();
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  render(){
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          {/* <div className="kt-portlet__body pddmdForce">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-2" 
                    name="search" 
                    placeholder="Search" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} /> 
                  <button className="btn btn-success btn-sm pr-5 pl-5" type="button" onClick={() => this.checkParams()}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top"> */}
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div className="paddupgrade">
                  <Button onClick={() => this.handleShow(null, 'create')} variant="success" style={{fontSize: '11px'}}>Create</Button>
                </div>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle', width: '5%' }}>
                        No &nbsp;
                      </th>
                      <th className="tableheh" style={{ width: '3 0%' }}>
                        Name &nbsp;
                        <button onClick={(e) => this.onSortChange('name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh">
                        Status
                        <button onClick={(e) => this.onSortChange('status')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" style={{ width: '15%' }}>
                        Created At
                      </th>
                      <th className="center tableheh" style={{ width: '15%' }}>
                        Options
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="7"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.data.map( (row, index) => (
                        <tr key={index}>
                          <td className="tableheh center">{index +1}</td>
                          <td>{row.name }</td>
                          <td>{row.status === 1 ? 
                            <Badge bg="success">
                              Active
                            </Badge>  : 
                            <Badge bg="warning">
                              NonActive
                            </Badge>}
                          </td>
                          <td>{this.dateTime(row.created_at)}</td>
                          <td className="center">
                            <>
                              <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Update Tag
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-action" onClick={() => this.handleShow(row.id, 'update')}>
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                  </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Delete Tag
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-action" onClick={() => this.handleDeleteOpen(row.id)}>
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                  </span>
                                </OverlayTrigger>
                              </ButtonToolbar>
                            </>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  } 
                </Table>
                <Pagination
                  activePage={this.state.active}
                  itemsCountPerPage={this.state.postPerPage}
                  itemClass="page-item"
                  linkClass="page-link"
                  totalItemsCount={this.state.totalData}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
                <Modal.Title>{this.state.type === 'create' ? 'Add Tags' : 'Update Tags'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="m-login__form m-form" onSubmit={this.isSubmit}>
              <div className="form-group" style={{marginBottom: '15px'}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Name Tag"
                  margin="normal"
                  className="kt-width-full"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange('name')}
                  required
                />
                <span className="required">{this.state.emailError}</span>
              </div>
              <div className="form-group">
                  <label className="col-md-6 p-0">Select Status</label>
                    <div className="input-group">
                      <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })}>
                        <option defaultValue>{this.state.status === '1' ? 'Active' : 'Non Active'}</option>
                        {this.state.status === '1' && <option value="0">Non Active</option>}
                        {this.state.status === '0' && <option value="1">Active</option>}
                        
                      </select>
                    </div>
                  <span className="required">{this.state.roleError}</span>
              </div>

              <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                <button
                  type="submit"
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                >
                  {this.state.type === 'create' ? 'Add' : 'Update'}
                </button>
              </div>

            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}