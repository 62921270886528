import React, { Fragment } from 'react';
import { Form } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CKEditor from 'ckeditor4-react';

require("bootstrap/scss/bootstrap.scss");

export const FIELD_TYPE = {
  TEXT_INPUT: "TEXT_INPUT",
  TEXT_AREA: "TEXT_AREA",
  NUMBER_INPUT: "NUMBER_INPUT",
  DROPDOWN: "DROPDOWN",
  IMAGE_FILE: "IMAGE_FILE",
  DATE: "DATE",
  WYSIWYG: "WYSIWYG"
}

const ReusableFormGenerator = (props) => {
  const fieldRenderedByFieldType = (field) => {
    const { label, formText, options, src, value, onChange, onError, bottomComponent, ...anotherProps } = field
    if (field.type === FIELD_TYPE.TEXT_INPUT) return (
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control type="text" value={value} onChange={onChange} {...anotherProps} />
        {formText &&
          <Form.Text className="text-muted desc-length-position">
            <span>{formText}</span>
          </Form.Text>
        }
      </Form.Group>
    )
    else if (field.type === FIELD_TYPE.TEXT_AREA) return (
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          as="textarea"
          rows={4}
          value={value}
          onChange={onChange}
          {...anotherProps}
        />
        {formText &&
          <Form.Text className="text-muted desc-length-position">
            <span>{formText}</span>
          </Form.Text>
        }
      </Form.Group>
    )
    else if (field.type === FIELD_TYPE.NUMBER_INPUT) return (
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control type="number" value={value} onChange={onChange} {...anotherProps} />
        {formText &&
          <Form.Text className="text-muted desc-length-position">
            <span>{formText}</span>
          </Form.Text>
        }
      </Form.Group>
    )
    else if (field.type === FIELD_TYPE.DROPDOWN) return (
      <div className="form-group">
        {label && <label className="form-label">{label}</label>}
        <div className="input-group">
          <select className="form-control" value={value} onChange={onChange} {...anotherProps}>
            {options.map((option, index) =>
              <option key={index} {...option}>{option.label}</option>
            )}
          </select>
        </div>
      </div>
    )
    else if (field.type === FIELD_TYPE.IMAGE_FILE) {
      const getURLImage = () => {
        if (src) {
          if (typeof src === "string") return src
          else return URL.createObjectURL(src)
        }
        else return ""
      }

      return (
        <Fragment>
          <Form.Group>
            {label && <Form.Label>{label}</Form.Label>}
            <div className="d-flex justify-content-center align-items-center">
              <Form.Control className="form-control" type="file" accept="image/*" onChange={onChange} onError={onError} value="" />
            </div>
            {formText &&
              <Form.Text className="text-muted">
                <span>{formText}</span>
              </Form.Text>
            }
            {bottomComponent}
          </Form.Group>
          {src &&
            <Form.Group>
              <div style={{ marginTop: '2rem' }}>
                <img src={getURLImage()} alt="img" className="img-bn" />
              </div>
            </Form.Group>
          }
        </Fragment>
      )
    }
    else if (field.type === FIELD_TYPE.DATE) return (
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <br />
        <DatePicker
          className="form-control"
          showTimeSelect
          dateFormat="Pp"
          value={value}
          onChange={onChange}
          {...field}
        />
        {formText &&
          <Form.Text className="text-muted desc-length-position">
            <span>{formText}</span>
          </Form.Text>
        }
      </Form.Group>
    )
    else if (field.type === FIELD_TYPE.WYSIWYG) {
      return (
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          <br />
          <CKEditor
            {...field}
          />
          {formText &&
            <Form.Text className="text-muted desc-length-position">
              <span>{formText}</span>
            </Form.Text>
          }
        </Form.Group>
      )
    }
    else return null
  }
  return (
    <div style={{ fontSize: '10px' }} className="head">
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-flex_row">
            {props.title ?
              <h2><b>{props.title}</b></h2>
              :
              <div />
            }
            {props.rightTitle}
          </div>
          <br />
          <div className="kt-portlet__preview">
            <div className="kt-section">
              {props.conditionalComponent}
              <form className="m-login__form m-form" onSubmit={props.isSubmit}>
                {props.fields.map((field, index) =>
                  <div className="row" key={index}>
                    {field.map((f, fIndex) =>
                      <div key={fIndex} className={`col-md-${12 / field.length}`}>
                        {fieldRenderedByFieldType(f)}
                      </div>
                    )}
                  </div>
                )}
                {props.anotherFooter}
                <div className="kt-login__actions" style={{ justifyContent: 'center', display: 'flex' }}>
                  {props.footer}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReusableFormGenerator