import { useFormik } from "formik";
import { FC, Fragment, useEffect, useMemo } from "react";
import FormWithFormik, { FileUpload, ReactSelectDropdown } from "../../components/reusableFormWithFormik";
import ReusableTable from "../../components/reusableTable";
import { convertToCSV, globalDateTime, isStringJson, useDebounce, useMultipleState } from "../../helper";
import { RowWrapper } from "../user-new/user";
import { CSSObjectWithLabel, GroupBase, StylesConfig } from "react-select"
import AsyncSelect from 'react-select/async';
import { Accordion, Modal, Table } from "react-bootstrap";
import CodeEditor from "react-simple-code-editor"
import { highlight, languages } from "prismjs"
import "./_refinitiv.scss"
import { generateReport, getSummary, getSummaryHistory, updateStatus } from "../../crud/refinitiv";
import { GetSummaryResponse, RefinitivAmlResponse, RefinitivStatus, ScreeningDetail } from "../../crud/refinitiv-types";
import clsx from "clsx";
import moment from "moment";
import Swal2 from "sweetalert2";
import { Checkbox } from "@material-ui/core";
import * as yup from "yup";
import ReusableCloseIcon from "../../components/reusableCloseIcon";

interface ISelectedForm {
  status: RefinitivStatus;
}

interface IFilterForm {
  q: string;
  totalCase: string;
  status: RefinitivStatus;
  startDate: Date;
  endDate: Date;
}

interface IInitialState {
  is_summary_loading: boolean;
  is_history_loading: boolean;
  is_detail_modal: boolean;
  is_selected_batch_loading: boolean;
  is_selected_batch_modal: boolean;
  selected_summary?: GetSummaryResponse;
  datas: Array<GetSummaryResponse>;
  selectedDatas: Array<GetSummaryResponse>;
  limit: number;
  total_data: number;
  page: number;
  histories: Array<ScreeningDetail>;
  total_data_histories: number;
  page_histories: number;
  selected_uid?: string;
}

const statusDropdownStyles = (withoutAddMaxWidth?: boolean): StylesConfig<ReactSelectDropdown, boolean, GroupBase<ReactSelectDropdown>> => {
  return {
    container: (base) => {
      if(withoutAddMaxWidth) return {
        ...base
      } as CSSObjectWithLabel
      else return {
        ...base,
        maxWidth: 200,
      } as CSSObjectWithLabel
    },
    option: (base, prop) => {
      const { data, isFocused, isSelected } = prop
      if(data.value === RefinitivStatus.BANNED) return {
        ...base,
        color: "#FF0000",
        background: isFocused || isSelected ? "rgba(255, 0, 0, 0.2)" : base.background,
        ':active': {
          ...base[':active'],
          background: "rgba(255, 0, 0, 0.2)",
        }
      } as CSSObjectWithLabel
      else if(data.value === RefinitivStatus.PASSED) return {
        ...base,
        color: "#008000",
        background: isFocused || isSelected ? "rgba(0, 128, 0, 0.2)" : base.background,
        ':active': {
          ...base[':active'],
          background: "rgba(0, 128, 0, 0.2)",
        }
      } as CSSObjectWithLabel
      else if(data.value === RefinitivStatus.NOT_CHECK) return {
        ...base,
        color: "#0000FF",
        background: isFocused || isSelected ? "rgba(0, 0, 255, 0.2)" : base.background,
        ':active': {
          ...base[':active'],
          background: "rgba(0, 0, 255, 0.2)",
        }
      } as CSSObjectWithLabel
      else return {
        ...base
      } as CSSObjectWithLabel
    },
    singleValue: (base, prop) => {
      const { data } = prop
      if(data?.value === RefinitivStatus.BANNED) return {
        ...base,
        color: "#FF0000"
      } as CSSObjectWithLabel
      else if(data?.value === RefinitivStatus.PASSED) return {
        ...base,
        color: "#008000"
      } as CSSObjectWithLabel
      else if(data?.value === RefinitivStatus.NOT_CHECK) return {
        ...base,
        color: "#0000FF"
      } as CSSObjectWithLabel
      else return {
        ...base,
      } as CSSObjectWithLabel
    },
    control: (base, prop) => {
      const data = prop.getValue()[0]
      if(data?.value === RefinitivStatus.BANNED) return {
        ...base,
        background: "rgba(255, 0, 0, 0.2)"
      } as CSSObjectWithLabel
      else if(data?.value === RefinitivStatus.PASSED) return {
        ...base,
        background: "rgba(0, 128, 0, 0.2)"
      } as CSSObjectWithLabel
      else if(data?.value === RefinitivStatus.NOT_CHECK) return {
        ...base,
        background: "rgba(0, 0, 255, 0.2)"
      } as CSSObjectWithLabel
      else return {
        ...base,
      } as CSSObjectWithLabel
    }
  }
}

const RefinitivPage: FC = () => {
  const [state, dispatch] = useMultipleState<IInitialState>({
    is_summary_loading: false,
    is_history_loading: false,
    is_detail_modal: false,
    is_selected_batch_loading: false,
    is_selected_batch_modal: false,
    datas: [],
    selectedDatas: [],
    limit: 15,
    total_data: 0,
    page: 1,
    histories: [],
    total_data_histories: 0,
    page_histories: 1,
  })

  const filterFormik = useFormik<Partial<IFilterForm>>({
    initialValues: {
      totalCase: "1"
    },
    onSubmit: () => {}
  })

  const selectedFormik = useFormik<Partial<ISelectedForm>>({
    initialValues: {},
    onSubmit: (val, formikHelper) => {
      if(val.status) {
        const statusLabel = Object.entries(RefinitivStatus).find(([_label,value]) => value === val.status)?.[0]?.replace(/_/g, " ")
        Swal2.fire({
          title: 'Update Status Confirmation',
          html: `Are you sure want to update all data status into ${statusLabel}?`,
          confirmButtonText: "OKE",
          showCancelButton: true,
          icon: "warning"
        })
          .then((result) => {
            if(result.isConfirmed) {
              dispatch({is_selected_batch_loading: true})
        
              Promise.all(state.selectedDatas.map(sd => val.status && updateStatus(sd.screening_detail.uid, {status: val.status})))
                .then(() => {
                  Swal2.fire('Success!','Successfully updated selected datas', 'success')
      
                  dispatch({
                    selectedDatas: [],
                    is_selected_batch_modal: false
                  })

                  formikHelper.resetForm()
                })
                .catch(err => console.log(err))
                .finally(() => dispatch({is_selected_batch_loading: false}))
            }
          })
          .catch(err => console.log(err))
      }
    },
    validationSchema: yup.object().shape({
      status: yup.string().required('Status must be fill!')
    })
  })

  const generateReportService = () => {
    if(filterFormik.values.startDate && filterFormik.values.endDate) {
      const start_date = moment(filterFormik.values.startDate).format("YYYY-MM-DD")
      const end_date = moment(filterFormik.values.endDate).format("YYYY-MM-DD")
      generateReport({
        limit: state.limit,
        page: state.page,
        q: filterFormik.values.q,
        status: filterFormik.values.status,
        case: filterFormik.values.totalCase ? Number(filterFormik.values.totalCase) : undefined,
        start_date,
        end_date,
      })
        .then(res => {
          const titles: Array<keyof ScreeningDetail> = ["case", "caseId", "caseSystemId", "created_at", "dob", "id", "name", "nationality", "pob", "status", "uid", "updated_at", "aml_response"]
  
          const contents: Array<Array<string>> = (res.data.data ?? []).map(d => titles.map(title => {
            if(title === "status") return Object.entries(RefinitivStatus).find(([_label, value]) => value === d.screening_detail.status)?.[0] ?? ""
            else return String(d.screening_detail[title])
          }))
  
          convertToCSV([titles as Array<string>].concat(contents), `refinitiv_${start_date}_${end_date}.csv`)
        })
        .catch(err => console.log(err))
    }
    else Swal2.fire('Warning', 'Please select start date & end date', 'warning')
  }

  const getSummaryService = (page: number, limit: number, q?: string, totalCase?: string, status?: RefinitivStatus, startDate?: Date, endDate?: Date) => {
    getSummary({
      page, 
      limit, 
      case: totalCase ? Number(totalCase) : undefined, 
      q: q ? q : undefined, 
      status: status ? status : undefined,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
    })
      .then(res => dispatch({
        datas: res.data.data?.data,
        total_data: res.data.data?.total_data
      }))
      .catch(err => console.log(err))
      .finally(()=> dispatch({is_summary_loading: false}))
  }

  const getSummaryHistoryService = (page: number, uid?: string) => {
    if(uid) getSummaryHistory({page, limit: state.limit, uid})
      .then(res => dispatch({
        histories: res.data.data?.data,
        total_data_histories: res.data.data?.total_data,
        selected_uid: uid
      }))
      .catch(err => console.log(err))
      .finally(()=> dispatch({is_history_loading: false}))
    else dispatch({is_history_loading: false})
  }

  useDebounce<[number, number, string | undefined, string | undefined, RefinitivStatus | undefined, Date | undefined, Date | undefined]>(getSummaryService, [state.page, state.limit, filterFormik.values.q, filterFormik.values.totalCase, filterFormik.values.status, filterFormik.values.startDate, filterFormik.values.endDate], 400, () => dispatch({is_summary_loading: true}))

  useDebounce<[number, string | undefined]>(getSummaryHistoryService, [state.page_histories, state.selected_uid], 400, () => dispatch({ is_history_loading: true }))

  useEffect(()=>{
    if(state.selectedDatas.length === 0) {
      dispatch({is_selected_batch_modal: false})
      selectedFormik.resetForm()
    }
  }, [state.selectedDatas])
  
  const selectedDetail = useMemo(()=>{
    return state.datas.find(data => data.uid === state.selected_uid)
  }, [state.datas, state.selected_uid])

  const amlResponseAccordion = () => {
    if(state.selected_summary?.screening_detail?.aml_response && isStringJson(state.selected_summary.screening_detail.aml_response)) {
      const amlResponse = JSON.parse(state.selected_summary.screening_detail?.aml_response) as RefinitivAmlResponse

      if(amlResponse?.results.length > 0) return (
        <ReusableTable 
          columns={[
            {title: "Name", dataIndex: "matchedTerm"},
            {title: "Match Score", dataIndex: "matchScore"},
            {title: "Match Strength", dataIndex: "matchStrength"},
            {title: "Categories", dataIndex: "categories", render: (_, data) => data.categories?.join(", ")},
            {title: "DOB", dataIndex: "secondaryFieldResults", render: (_, data) => {
              const finder = data.secondaryFieldResults?.find(sfr => sfr.typeId === "SFCT_2")

              return finder?.matchedDateTimeValue ?? "-"
            }},
            {title: "Nationality", dataIndex: "countryLinks", render: (_, data) => {
              const finder = data.countryLinks?.find(cl => cl.type === "NATIONALITY")

              return [finder?.country?.code ?? "-", finder?.country?.name ?? "-"].join(" - ")
            }},
            {title: <Fragment>Screening Date <br />(Modification Date)</Fragment>, dataIndex: "modificationDate", render: (_,data) => data.modificationDate ? globalDateTime(data.modificationDate) : "-"},
            {title: "Review Result", dataIndex: "resultReview", render: (_,data) => String(data.resultReview?.reviewRequired) },
          ]}
          dataSources={amlResponse.results}
        />
      )
      else return null
    }
    else return null
  }

  return (
    <div className="wrapper">
      <div className="custom-flex-row-space-between">
        <div className="custom-flex-col-space-between gap-4 w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="title">Name Screening Report</div>
              <div className="desc">All of the of the report details will be showing below.</div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-2">
              {state.selectedDatas.length > 0 &&
                <button 
                  className="sipendar-button custom-flex-row-center gap-2 pt-2 pb-2"
                  onClick={()=>dispatch({is_selected_batch_modal: true})}
                >
                  <RecycleIcon />
                  <span className="ml-2 mr-2">Update Status Batch</span>
                </button>
              }
              <button 
                className="sipendar-button custom-flex-row-center gap-2 pt-2 pb-2"
                onClick={()=>generateReportService()}
              >
                <DownloadIcon />
                <span className="ml-2 mr-2">Generate Report</span>
              </button>
            </div>
          </div>
          <div className="custom-flex-row-center gap-2">
            <FormWithFormik 
              name="q"
              config={filterFormik}
              type="TEXT_INPUT"
              placeholder="Search by keyword"
              style={{minWidth: 160}}
            />
            <FormWithFormik 
              name="totalCase"
              config={filterFormik}
              type="SEARCHABLE_SELECT"
              placeholder="Case Found"
              styles={{
                container: (base) => ({
                  ...base,
                  minWidth: 160
                } as CSSObjectWithLabel)
              }}
              options={[
                {label: "-ALL-", value: ""},
                {label: "NO", value: "0"},
                {label: "YES", value: "1"},
              ]}
            />
            <FormWithFormik 
              name="status"
              config={filterFormik}
              type="SEARCHABLE_SELECT"
              placeholder="Status"
              styles={statusDropdownStyles(true)}
              options={[
                {label: "-ALL-", value: ""},
                ...Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value}))
              ]}
            />
            <FormWithFormik 
              name="startDate"
              config={filterFormik}
              type="DATE"
              placeholderText="Start Date"
              isClearable
            />
            <FormWithFormik 
              name="endDate"
              config={filterFormik}
              type="DATE"
              placeholderText="End Date"
              isClearable
            />
          </div>
        </div>
      </div>
      <ReusableTable
        withoutDefaultStyles={true}
        className="sipendar-table"
        dataSources={state.datas}
        columns={[
          {
            title: "",
            dataIndex: "id",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} position="LEFT" className="custom-flex-row-center gap-4">
                <Checkbox 
                  disabled={data.status === RefinitivStatus.BANNED}
                  checked={!!state.selectedDatas.find(sd => sd.id === data.id)} 
                  onChange={() => {
                    const finder = state.selectedDatas.find(sd => sd.id === data.id)

                    if(finder) dispatch({ selectedDatas: state.selectedDatas.filter(sd => sd.id !== finder.id) })
                    else dispatch({ selectedDatas: [...state.selectedDatas, data] })
                  }} 
                />
              </RowWrapper>
            )
          },
          {
            title: "UID",
            dataIndex: "screening_detail",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                <span className="ml-2">{data.screening_detail?.uid}</span>
              </RowWrapper>
            )
          },
          {
            title: "Name",
            dataIndex: "screening_detail",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                {data.screening_detail?.name}
              </RowWrapper>
            )
          },
          {
            title: "Case Id",
            dataIndex: "screening_detail",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                {data.screening_detail?.caseId}
              </RowWrapper>
            )
          },
          {
            title: "Place of Birth",
            dataIndex: "screening_detail",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                {data.screening_detail?.pob}
              </RowWrapper>
            )
          },
          {
            title: "Nationality",
            dataIndex: "screening_detail",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                {data.screening_detail?.nationality}
              </RowWrapper>
            )
          },
          {
            title: "Date of Birth",
            dataIndex: "screening_detail",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                {data.screening_detail?.dob && globalDateTime(new Date(data.screening_detail.dob))}
              </RowWrapper>
            )
          },
          {
            title: "Total Case Found",
            dataIndex: "screening_detail",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-col-center-center gap-4">
                {data.screening_detail.case}
              </RowWrapper>
            )
          },
          {
            title: "Last Updated",
            dataIndex: "updated_at",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                {data.updated_at && globalDateTime(new Date(data.updated_at))}
              </RowWrapper>
            )
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (_, data) => (
              <RowWrapper className="custom-flex-row-center gap-4" style={{overflow: "inherit", height: 80}}>
                <StatusComponent 
                  {...data}
                  onChange={()=>getSummaryService(state.page, state.limit, filterFormik.values.q, filterFormik.values.totalCase, filterFormik.values.status)}
                />
              </RowWrapper>
            )
          },
          {
            title: "Action",
            dataIndex: "id",
            render: (_, data) => (
              <RowWrapper style={{height: 80}} position="RIGHT">
                <div className="main-row right-row custom-flex-row-center-end">
                  <button 
                    className={`btn btn-success custom-flex-center ${clsx({'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': state.is_history_loading})}`}
                    onClick={()=>dispatch({ page_histories: 1, selected_uid: data.uid })}
                    disabled={state.is_history_loading}
                  >
                    <ViewIcon />
                  </button>
                  <button 
                    className="btn btn-primary custom-flex-center"
                    onClick={()=>dispatch({selected_summary: data})}
                  >
                    <ReportIcon />
                  </button>
                </div>
              </RowWrapper>
            )
          }
        ]}
        pagination={{
          pageRangeDisplayed: 5,
          itemsCountPerPage: state.limit,
          totalItemsCount: state.total_data,
          activePage: state.page,
          onChange: (page) => dispatch({page})
        }}
        limitConfig={{
          limits: [...Array(10).keys()].map(key => (key + 1) * 5),
          onSelect: (limit) => dispatch({limit}),
          selectedLimit: state.limit
        }}
      />
      <Modal
        show={state.is_selected_batch_modal}
        onHide={()=>{
          dispatch({is_selected_batch_modal: false})

          selectedFormik.resetForm()
        }}
        centered
        size="xl"
      >
        <Modal.Body className="wrapper refinitiv-modal-wrapper">
          <div className="title">Selected Data</div>
          <ReusableTable
            dataSources={state.selectedDatas}
            columns={[
              {
                title: "UID",
                dataIndex: "screening_detail",
                render: (_, data) => data.screening_detail?.uid
              },
              {
                title: "Name",
                dataIndex: "screening_detail",
                render: (_, data) => data.screening_detail?.name
              },
              {
                title: "Case Id",
                dataIndex: "screening_detail",
                render: (_, data) => data.screening_detail?.caseId
              },
              {
                title: "Place of Birth",
                dataIndex: "screening_detail",
                render: (_, data) => data.screening_detail?.pob
              },
              {
                title: "Nationality",
                dataIndex: "screening_detail",
                render: (_, data) => data.screening_detail?.nationality
              },
              {
                title: "Date of Birth",
                dataIndex: "screening_detail",
                render: (_, data) => data.screening_detail?.dob && globalDateTime(new Date(data.screening_detail.dob))
              },
              {
                title: "Total Case Found",
                dataIndex: "screening_detail",
                render: (_, data) => data.screening_detail.case
              },
              {
                title: "Last Updated",
                dataIndex: "updated_at",
                render: (_, data) => data.updated_at && globalDateTime(new Date(data.updated_at))
              },
              {
                title: "Status",
                dataIndex: "status",
                render: (_, data) => Object.entries(RefinitivStatus).find(([label,value]) => value === data.status)?.[0]?.replace(/_/g, " ")
              },
              {
                title: "Action",
                dataIndex: "id",
                render: (_, data) => (
                  <button 
                    className="btn btn-danger custom-flex-center"
                    disabled={state.is_selected_batch_loading}
                    onClick={()=>dispatch({ selectedDatas: state.selectedDatas.filter(sd => sd.id !== data.id) })}
                  >
                    DESELECT
                  </button>
                )
              }
            ]}
          />
          {state.selectedDatas.length > 0 &&
            <div className="d-flex align-items-end justify-content-between">
              <FormWithFormik 
                config={selectedFormik}
                name="status"
                type="ASYNC_SEARCHABLE_SELECT"
                label="Update Status"
                placeholder="Update Status"
                styles={statusDropdownStyles()}
                options={Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value}))}
                defaultOptions={Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value}))}
                cacheOptions={Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value}))}
              />
              <button 
                disabled={state.is_selected_batch_loading} 
                style={{minWidth: 200}}
                className="btn btn-success" 
                onClick={selectedFormik.submitForm}
              >
                Update Status
              </button>
            </div>
          }
        </Modal.Body>
      </Modal>
      <Modal
        show={!!state.selected_uid && !state.selected_summary}
        onHide={()=>dispatch({
          selected_uid: undefined,
          page_histories: 1,
        })}
        centered
        size="lg"
      >
        <Modal.Body className="wrapper refinitiv-modal-wrapper">
          <div className="d-flex justify-content-between">
            <div className="title">Log History</div>
            <ReusableCloseIcon 
              onClick={()=>{
                dispatch({
                  selected_uid: undefined,
                  page_histories: 1,
                })
              }}
            />
          </div>
          <Table bordered={false} borderless={true} striped={false}>
            <tbody>
              <tr>
                <td><b>UID</b></td>
                <td>: {state.selected_uid}</td>
                <td><b>Name</b></td>
                <td>: {selectedDetail?.screening_detail?.name}</td>
                <td><b>Nationality</b></td>
                <td>: {selectedDetail?.screening_detail?.nationality}</td>
              </tr>
              <tr>
                <td><b>Place of Birth</b></td>
                <td>: {selectedDetail?.screening_detail?.pob}</td>
                <td><b>Date of Birth</b></td>
                <td>: {selectedDetail?.screening_detail?.dob && globalDateTime(selectedDetail?.screening_detail?.dob)}</td>
                <td><b>Last Status Update By</b></td>
                <td>: {selectedDetail?.updated_by?.email}</td>
              </tr>
              <tr>
                <td><b>Last Status Update At</b></td>
                <td>: {selectedDetail?.updated_admin_at && globalDateTime(selectedDetail?.updated_admin_at)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <ReusableTable
            dataSources={state.histories}
            loading={state.is_history_loading}
            columns={[
              {
                title: "Screening Time",
                dataIndex: "created_at",
                render: (_, data) => (
                  (data.updated_at || data.created_at) ? globalDateTime(new Date(data.updated_at || data.created_at)) : "-"
                )
              },
              {
                title: "Case Found",
                dataIndex: "case"
              },
              // {
              //   title: "Status",
              //   dataIndex: "status",
              //   render: (_, data) => Object.entries(RefinitivStatus).find(([_label, value]) => value === data.status)?.[0].replace(/_/g, " ")
              // },
              // {
              //   title: "Status Updated At",
              //   dataIndex: "updated_at",
              //   render: (_, data) => data.updated_at && globalDateTime(new Date(data.updated_at))
              // },
              // {
              //   title: "Status Updated By",
              //   dataIndex: "updated_by",
              //   render: (_, data) => data.updated_by ?? "-"
              // },
              {
                title: "Action",
                dataIndex: "id",
                render: (_, data) => (
                  <button 
                    className="btn btn-primary custom-flex-center"
                    onClick={()=>{
                      dispatch({ selected_summary: {
                        created_at: data.created_at,
                        id: data.id,
                        screening_detail: data,
                        status: data.status,
                        uid: data.uid,
                        updated_at: data.updated_at,
                        updated_admin_at: selectedDetail?.updated_admin_at,
                        updated_by: selectedDetail?.updated_by
                      } })
                    }}
                  >
                    <ReportIcon />
                  </button>
                )
              }
            ]}
            pagination={{
              pageRangeDisplayed: 5,
              itemsCountPerPage: state.limit,
              totalItemsCount: state.total_data_histories,
              activePage: state.page_histories,
              onChange: (page) => dispatch({page})
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={!!state.selected_summary}
        onHide={()=>dispatch({selected_summary: undefined})}
        centered
        size="xl"
      >
        <Modal.Body className="wrapper refinitiv-modal-wrapper">
          <div className="d-flex justify-content-between">
            <div className="title">Detail Screening</div>
            <ReusableCloseIcon onClick={()=>dispatch({selected_summary: undefined})} />
          </div>
          <Table bordered={false} borderless={true} striped={false}>
            <tbody>
              <tr>
                <td><b>UID</b></td>
                <td>: {state.selected_summary?.uid}</td>
                <td><b>Name</b></td>
                <td>: {state.selected_summary?.screening_detail?.name}</td>
                <td><b>Nationality</b></td>
                <td>: {state.selected_summary?.screening_detail?.nationality}</td>
              </tr>
              <tr>
                <td><b>Place of Birth</b></td>
                <td>: {state.selected_summary?.screening_detail?.pob}</td>
                <td><b>Date of Birth</b></td>
                <td>: {state.selected_summary?.screening_detail?.dob && globalDateTime(state.selected_summary.screening_detail.dob)}</td>
                <td><b>Last Status Update By</b></td>
                <td>: {state.selected_summary?.updated_by?.email}</td>
              </tr>
              <tr>
                <td><b>Last Status Update At</b></td>
                <td>: {state.selected_summary?.updated_admin_at && globalDateTime(state.selected_summary?.updated_admin_at)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          {amlResponseAccordion()}
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>AML Response [JSON]</Accordion.Header>
              <Accordion.Body>
                <CodeEditor
                  value={(state.selected_summary?.screening_detail?.aml_response && isStringJson(state.selected_summary?.screening_detail?.aml_response)) ? JSON.stringify(JSON.parse(state.selected_summary?.screening_detail?.aml_response), null, 4) : "{}"}
                  onValueChange={()=>{}}
                  highlight={code => highlight(code, languages.javascript, "javascript")}
                  padding={10}
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 16,
                  }}
                  disabled={true}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default RefinitivPage

export interface StatusComponentProps extends GetSummaryResponse {
  onChange: (response?: boolean) => void;
}

const StatusComponent: FC<StatusComponentProps> = (props) => {
  const [state, dispatch] = useMultipleState({
    isLoading: false
  })
  const onChangeHandler = (status: RefinitivStatus) => {
    const statusLabel = Object.entries(RefinitivStatus).find(([_label,value]) => value === status)?.[0]?.replace(/_/g, " ")
    Swal2.fire({
      title: 'Update Status Confirmation',
      html: `Are you sure want to update status into ${statusLabel}?`,
      confirmButtonText: "OKE",
      showCancelButton: true,
      icon: "warning"
    })
      .then((result) => {
        if(result.isConfirmed) {
          dispatch({isLoading: true})
          updateStatus(props.screening_detail.uid, {status})
            .then((res) => props.onChange(res.data.data))
            .catch(err => console.log(err))
            .finally(()=>dispatch({isLoading: false}))
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <AsyncSelect<ReactSelectDropdown, false>
      isLoading={state.isLoading}
      styles={statusDropdownStyles()}
      options={Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value}))}
      defaultOptions={Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value}))}
      cacheOptions={Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value}))}
      value={Object.entries(RefinitivStatus).map(([label,value]) => ({label: label.replace(/_/g, " "), value})).find(value => value.value === props.status)}
      isDisabled={props.status === RefinitivStatus.BANNED}
      isMulti={false}
      onChange={(nv) => nv?.value && onChangeHandler(nv.value as RefinitivStatus)}
    />
  )
}

export const DownloadIcon: FC = () => {
  return (
    <div className="icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 13.5H13.5V15H7.5V13.5ZM7.5 9.75H16.5V11.25H7.5V9.75ZM7.5 17.25H11.25V18.75H7.5V17.25Z" fill="currentColor"/>
        <path d="M18.75 3.75H16.5V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H9C8.60218 1.5 8.22064 1.65804 7.93934 1.93934C7.65804 2.22064 7.5 2.60218 7.5 3V3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25V21C3.75 21.3978 3.90804 21.7794 4.18934 22.0607C4.47064 22.342 4.85218 22.5 5.25 22.5H18.75C19.1478 22.5 19.5294 22.342 19.8107 22.0607C20.092 21.7794 20.25 21.3978 20.25 21V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75ZM9 3H15V6H9V3ZM18.75 21H5.25V5.25H7.5V7.5H16.5V5.25H18.75V21Z" fill="currentColor"/>
      </svg>
    </div>
  )
}

export const RecycleIcon: FC = () => {
  return (
    <div className="icon">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.7 9C6.1 7 7.9 5.5 10 5.5C11.5 5.5 12.7 6.2 13.5 7.3L15.2 5.3C14 3.9 12.1 3 10 3C6.5 3 3.6 5.6 3.1 9H1L4.5 13L8 9H5.7ZM15.5 7L12 11H14.3C13.8 13 12.1 14.5 10 14.5C8.5 14.5 7.3 13.8 6.5 12.7L4.8 14.6C6 16.1 7.9 17 10 17C13.5 17 16.4 14.4 16.9 11H19L15.5 7Z" fill="currentColor"/>
      </svg>
    </div>
  )
}

export const ViewIcon: FC = () => {
  return (
    <div className="icon">
      <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.95831 9.49984C3.95831 9.49984 5.9731 5.5415 9.49998 5.5415C13.0261 5.5415 15.0416 9.49984 15.0416 9.49984C15.0416 9.49984 13.0261 13.4582 9.49998 13.4582C5.9731 13.4582 3.95831 9.49984 3.95831 9.49984Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.625 13.4583V15.0417C16.625 15.4616 16.4582 15.8643 16.1613 16.1613C15.8643 16.4582 15.4616 16.625 15.0417 16.625H3.95833C3.53841 16.625 3.13568 16.4582 2.83875 16.1613C2.54181 15.8643 2.375 15.4616 2.375 15.0417V13.4583M16.625 5.54167V3.95833C16.625 3.53841 16.4582 3.13568 16.1613 2.83875C15.8643 2.54181 15.4616 2.375 15.0417 2.375H3.95833C3.53841 2.375 3.13568 2.54181 2.83875 2.83875C2.54181 3.13568 2.375 3.53841 2.375 3.95833V5.54167M9.5 10.2917C9.70996 10.2917 9.91133 10.2083 10.0598 10.0598C10.2083 9.91133 10.2917 9.70996 10.2917 9.5C10.2917 9.29004 10.2083 9.08867 10.0598 8.94021C9.91133 8.79174 9.70996 8.70833 9.5 8.70833C9.29004 8.70833 9.08867 8.79174 8.94021 8.94021C8.79174 9.08867 8.70833 9.29004 8.70833 9.5C8.70833 9.70996 8.79174 9.91133 8.94021 10.0598C9.08867 10.2083 9.29004 10.2917 9.5 10.2917Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  )
}

const ReportIcon: FC = () => {
  return (
    <div className="icon">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33333 16.7778H6.22222V9.38889H8.33333V16.7778ZM12.5556 16.7778H10.4444V6.22222H12.5556V16.7778ZM16.7778 16.7778H14.6667V12.5556H16.7778V16.7778ZM18.8889 18.8889H4.11111V4.11111H18.8889V18.9944M18.8889 2H4.11111C2.95 2 2 2.95 2 4.11111V18.8889C2 20.05 2.95 21 4.11111 21H18.8889C20.05 21 21 20.05 21 18.8889V4.11111C21 2.95 20.05 2 18.8889 2Z" fill="currentColor"/>
      </svg>
    </div>
  )
}