import instance from "./axiosKycInstance";

const getAdmin = (token, params) => {
  return instance.get("/admin/admin-user" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchAdmin = (token, params) => {
  return new Promise((resolve, reject) => {
    getAdmin(token, params).then(res => {
        resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const getDetailAdmin = (token, id) => {
  return instance.get("/admin/admin-user/" + id + "/detail", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailAdmin = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailAdmin(token, id).then(res => {
        resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const getAdminProfile = (token) => {
  return instance.get("/admin/profile", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const fetchAdminProfile = (token) => {
  return new Promise((resolve, reject) => {
    getAdminProfile(token).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}


const postCreateAdmin = (token, data) => {
  return instance.post("/admin/admin-user/create", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const createAdmin = (token, data) => {
  return new Promise((resolve, reject) => {
    postCreateAdmin(token, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const patchUpdtaeAdmin = (token, id, data) => {
  return instance.patch("/admin/admin-user/"+ id + "/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateAdmin = (token, id, data) => {
  return new Promise((resolve, reject) => {
    patchUpdtaeAdmin(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const deleteAdminProfile = (token, id) => {
  return instance.delete("/admin/admin-user/" + id + "/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteAdmin = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteAdminProfile(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const postResetOTP = (token, id) => {
  return instance.post("/admin/admin-user/renew-verification/"+ id, {}, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateResetOTP = (token, id) => {
  return new Promise((resolve, reject) => {
    postResetOTP(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

export default {
  fetchAdmin: fetchAdmin,
  fetchDetailAdmin: fetchDetailAdmin,
  fetchAdminProfile: fetchAdminProfile,
  createAdmin: createAdmin,
  updateAdmin: updateAdmin,
  deleteAdmin:deleteAdmin,
  updateResetOTP: updateResetOTP,
};