import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import courseService from '../../../crud/course';
import { toast } from 'react-toastify';
import CKEditor from 'ckeditor4-react';
import moment from 'moment';

// Yup Schema

const CustomContactForm = (props) => {
  const uploadFileRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [syllabus, setSyllabus] = useState({
    status: 'init',
    url: '',
  });
  const [newImage, setnewImage] = useState(null);
  const [oldImage, setoldImage] = useState(null);

  useEffect(() => {
    // props.submitRef.current = handleSubmit;
  }, []);

  //   get token
  const getToken = () => localStorage.getItem('token');

  //   Use Formik Hook Use

  const sylabusUrlSetter = (value) => {
    if (typeof value === 'string')
      setSyllabus({
        status: 'not edited',
        url: value,
      });
    else {
      var reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = function () {
        if (value) {
          setSyllabus({
            status: 'edited',
            url: reader.result,
          });
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  console.log(props.isEdit, 'props.isEdit');

  return (
    <form className='m-login__form m-form'>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Subject Inquiry
            </Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_en'
              //                placeholder='Course Name (EN)'
              value={props?.data?.inquiry || ''}
              // required
            />
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Name</Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_id'
              placeholder='Name'
              value={props?.data?.name || ''}
              // required
            />
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Email</Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_en'
              //                placeholder='Course Name (EN)'
              value={props?.data?.email || ''}
              // required
            />
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Mobile No.</Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_id'
              placeholder='Mobile No'
              value={props?.data?.mobile_no || ''}
              // required
            />
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Tokocrypto UID</Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_en'
              //                placeholder='Course Name (EN)'
              value={props?.data?.tokocrypto_uid || ''}
              // required
            />
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          {/* <div className='row'>
            <div className='col-md-12'> */}
          <Form.Group>
            <Form.Label className='font-weight-bold'>Student</Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_id'
              placeholder='Name'
              value={props?.data?.student || ''}
              // required
            />
          </Form.Group>
          {/* </div>
          </div> */}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-6'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Interest</Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              type='textarea'
              as='textarea'
              rows={4}
              name='course_name_en'
              value={props?.data?.message || ''}
            />
          </Form.Group>
        </div>
        {props?.data?.student === 'student' && (
          <div className='col-md-6 col-sm-6'>
            <Form.Group>
              <Form.Label className='font-weight-bold'>University</Form.Label>
              <Form.Control
                onChange={() => null}
                disabled={props.isEdit ? true : false}
                type='text'
                name='course_name_en'
                value={props?.data?.university || ''}
              />
            </Form.Group>
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-6'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Created At</Form.Label>
            <Form.Control
              onChange={() => null}
              disabled={props.isEdit ? true : false}
              // type='textarea'
              // as='textarea'
              rows={4}
              name='course_name_en'
              value={
                (props?.data?.created_at &&
                  props?.data?.created_at &&
                  moment(props?.data?.created_at)
                    .subtract(
                      moment(props?.data?.created_at).utcOffset(),
                      'minute'
                    )
                    .format('DD MMM YYYY HH:mm')) ||
                '' ||
                ''
              }
            />
          </Form.Group>
        </div>
      </div>
    </form>
  );
};

export default CustomContactForm;
