import { FC, ReactNode, useEffect, useState } from "react"
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

export interface ReusableCopyButtonProps {
  value: ReactNode;
}

const ReusableCopyButton: FC<ReusableCopyButtonProps> = (props) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const onClickHandler = () => {
    if (!isCopied) {
      navigator.clipboard.writeText(props.value?.toString() ?? "")
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    }
  }
  useEffect(()=>{
    if(isCopied) toast.success(`"${props.value}" is copied!`)
  }, [isCopied])
  return (
    <Button style={{padding: 5}} variant={isCopied ? "secondary" : "light"} disabled={isCopied} onClick={onClickHandler}>
      <i className="fa-solid fa-copy" />
    </Button>
  )
}

export default ReusableCopyButton