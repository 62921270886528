import clsx from "clsx";
import { useFormik } from "formik";
import { FC, Fragment } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { CSSObjectWithLabel } from "react-select";
import FormWithFormik from "../../components/reusableFormWithFormik";
import ReusableTable from "../../components/reusableTable";
import { IGetCsResponse, ServiceType, StatusForm, IUpdateStatusRequest, TypeForm, serviceTypes } from "../../crud/cs-request-form-types";
import { getRawImage, getRequests, updateDetailRequest } from "../../crud/cs-request-form";
import { useMultipleState, globalDateTime, useDebounce } from "../../helper";
import { ViewIcon } from "../refinitiv";
import { RowWrapper } from "../user-new/user";
import ReusableCloseIcon from "../../components/reusableCloseIcon";
import Swal2 from "sweetalert2"
import * as yup from "yup"
import PreviewImage from "../../components/previewImage";
import { FilePreviewUrl, ResponseAPI } from "../../crud/global-types";
import { AxiosError } from "axios";

interface IFilterFormik {
  q: string;
  type: TypeForm;
  status: StatusForm;
  lastUpdate: Date | null;
}

interface IInitialState {
  datas: Array<Partial<IGetCsResponse>>;
  selectedData?: Partial<IGetCsResponse>;
  limit: number;
  total_data: number;
  page: number;
  isLoading: boolean;
  isApprovalLoading: boolean;
  filePreviewUrl?: FilePreviewUrl;
}

interface IUpdateStatusForm extends IUpdateStatusRequest {
  isChecked: boolean;
}

const CSResetRequest: FC = () => {
  const filterFormik = useFormik<Partial<IFilterFormik>>({
    initialValues: {},
    onSubmit: (vals) => {

    }
  })

  const selectedFormik = useFormik<Partial<IFilterFormik>>({
    initialValues: {},
    onSubmit: (vals) => {

    }
  })

  const [state, dispatch] = useMultipleState<IInitialState>({
    datas: [],
    limit: 15,
    total_data: 0,
    page: 1,
    isLoading: false,
    isApprovalLoading: false,
  })

  const isDisabledReasonForm = (data: Partial<IGetCsResponse>) => {
    const disabledTypes = [StatusForm.APPROVED, StatusForm.REJECTED]
    if(disabledTypes.find(dt => dt === data.status)) return true
    else return false
  }

  const getRequestList = (page: number, limit: number, q?: string, status?: StatusForm, last_update?: Date | null, type?: TypeForm) => {
    getRequests({
      limit,
      q,
      page,
      status,
      last_update: last_update ? new Date(last_update).toISOString() : undefined,
      type
    })
      .then((res) => {
        const getFileUrl = (part: File) => new Promise<string | ArrayBuffer | null>((resolve,reject) => {
          var reader = new FileReader();
          reader.readAsDataURL(new Blob([part]));
          reader.onload = function () {
            resolve(reader.result);
          };
          reader.onerror = function (error) {
            reject(error);
          };
        })
        const newDatas = Promise.all((res.data.data?.data ?? []).map(async (d) => {
          const photo_bank_book = d.photo_bank_book ? await getFileUrl((await getRawImage(d.photo_bank_book)).data) : undefined
          const photo_failed_wd = d.photo_failed_wd ? await getFileUrl((await getRawImage(d.photo_failed_wd)).data) : undefined
          const photo_ktp = d.photo_ktp ? await getFileUrl((await getRawImage(d.photo_ktp)).data) : undefined
          const photo_linked_binance = d.photo_linked_binance ? await getFileUrl((await getRawImage(d.photo_linked_binance)).data) : undefined
          const photo_linked_tokocrypto = d.photo_linked_tokocrypto ? await getFileUrl((await getRawImage(d.photo_linked_tokocrypto)).data) : undefined
          const photo_selfie = d.photo_selfie ? await getFileUrl((await getRawImage(d.photo_selfie)).data) : undefined
          
          return {
            ...d,
            photo_bank_book: photo_bank_book?.toString(),
            photo_failed_wd: photo_failed_wd?.toString(),
            photo_ktp: photo_ktp?.toString(),
            photo_linked_binance: photo_linked_binance?.toString(),
            photo_linked_tokocrypto: photo_linked_tokocrypto?.toString(),
            photo_selfie: photo_selfie?.toString()
          }
        }))
        
        newDatas.then(nd => {
          dispatch({
            datas: nd ?? [],
            total_data: res.data.data?.total_data ?? 0
          })
        }).catch(err => console.log('err;', err))
      })
      .catch(err => console.log(err))
      .finally(()=>dispatch({isLoading: false}))
  }

  const updateStatusFormik = useFormik<Partial<IUpdateStatusForm>>({
    initialValues: {},
    onSubmit: (vals) => {
      if(state.selectedData?.id && vals.status) {
        dispatch({isApprovalLoading: true})
        updateDetailRequest(state.selectedData.id.toString(), {
          status: vals.status,
          reason: vals.reason ?? ""
        })
          .then(() => {
            Swal2.fire('Success', 'Successfully updated data!', 'success')

            dispatch({
              selectedData: undefined,
              isLoading: true
            })

            getRequestList(state.page, state.limit, filterFormik.values.q, filterFormik.values.status, filterFormik.values.lastUpdate, filterFormik.values.type)
          })
          .catch((err: AxiosError<ResponseAPI<null>>) => {
            if(err?.response?.data?.message) Swal2.fire('Error', err.response.data.message, 'error')
          })
          .finally(()=>dispatch({isApprovalLoading: false}))
      }
      else Swal2.fire('Warning', 'Data ID is not found!', 'warning')
    },
    validationSchema: yup.object().shape({
      isChecked: yup.boolean().required("You must accept the agreement!")
    })
  })

  useDebounce<[number, number, string | undefined, StatusForm | undefined, Date | null | undefined, TypeForm | undefined]>(getRequestList, [state.page, state.limit, filterFormik.values.q, filterFormik.values.status, filterFormik.values.lastUpdate, filterFormik.values.type], 400, () => dispatch({isLoading: true}))

  const getLabelOfTypeForm = (type?: TypeForm) => {
    const selectedServiceType = serviceTypes.find((st) => st.value === type)?.label

    return Object.entries(ServiceType).find(([_key,val]) => val === selectedServiceType)?.[0]?.replace(/_/g," ")
  }

  // const generateReportService = () => {

  // }

  const fileFields = [
    {label: "Photo Bank Book", value: state.selectedData?.photo_bank_book},
    {label: "Photo Failed WD", value: state.selectedData?.photo_failed_wd},
    {label: "Photo KTP", value: state.selectedData?.photo_ktp},
    {label: "Photo Screenshot Binance", value: state.selectedData?.photo_linked_binance},
    {label: "Photo Screenshot Tokocrypto", value: state.selectedData?.photo_linked_tokocrypto},
    {label: "Photo Selfie/Swadaya", value: state.selectedData?.photo_selfie},
  ]

  const onModalCloseHandler = () => {
    dispatch({selectedData: undefined})

    selectedFormik.resetForm()
    updateStatusFormik.resetForm()
  }

  return (
    <Fragment>
      <div className="wrapper">
        <div className="custom-flex-row-space-between">
          <div className="custom-flex-col-space-between gap-4 w-100">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="title">Customer Reset Request</div>
                <div className="desc">All of the of the request details will be showing below.</div>
              </div>
              {/* <button 
                className="sipendar-button custom-flex-row-center gap-2 pt-2 pb-2"
                onClick={()=>generateReportService()}
              >
                <DownloadIcon />
                <span className="ml-2 mr-2">Generate Report</span>
              </button> */}
            </div>
            <div className="custom-flex-row-center gap-2">
              <FormWithFormik 
                name="q"
                config={filterFormik}
                type="TEXT_INPUT"
                label="Filter by keyword"
                placeholder="Search by keyword"
                style={{minWidth: 160}}
              />
              <FormWithFormik 
                name="type"
                config={filterFormik}
                type="SEARCHABLE_SELECT"
                label="Request Type"
                placeholder="Request Type"
                styles={{
                  container: (base) => ({
                    ...base,
                    minWidth: 160
                  } as CSSObjectWithLabel)
                }}
                options={[
                  {label: "-ALL-", value: ""},
                  ...serviceTypes.map((st) => ({
                    label: getLabelOfTypeForm(st.value),
                    value: st.value
                  }))
                ]}
              />
              <FormWithFormik 
                name="status"
                config={filterFormik}
                label="Status"
                type="SEARCHABLE_SELECT"
                placeholder="Status"
                styles={{
                  container: (base) => ({
                    ...base,
                    minWidth: 160
                  } as CSSObjectWithLabel)
                }}
                options={[
                  {label: "-ALL-", value: ""},
                  ...Object.entries(StatusForm).map(([label,value]) => ({
                    label: label.replace(/_/g, " "), 
                    value
                  }))
                ]}
              />
              <FormWithFormik 
                name="lastUpdate"
                config={filterFormik}
                label="Last Update"
                type="DATE"
                placeholderText="Last Update"
                isClearable
              />
            </div>
          </div>
        </div>
        <ReusableTable
          loading={state.isLoading}
          withoutDefaultStyles={true}
          className="sipendar-table"
          dataSources={state.datas}
          columns={[
            {
              title: "UID Tokocrypto",
              dataIndex: "uid_tokocrypto",
              render: (_, data) => (
                <RowWrapper style={{height: 80}} position="LEFT" className="custom-flex-row-center gap-4">
                  <span className="ml-4">{data.uid_tokocrypto ?? "-"}</span>
                </RowWrapper>
              )
            },
            {
              title: "Email Address",
              dataIndex: "email_tokocrypto",
              render: (_, data) => (
                <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                  {data.email_tokocrypto ?? "-"}
                </RowWrapper>
              )
            },
            {
              title: "Request Type",
              dataIndex: "type",
              render: (_, data) => {
                return (
                  <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                    {getLabelOfTypeForm(data.type)}
                  </RowWrapper>
                )
              }
            },
            {
              title: "Created At",
              dataIndex: "created_at",
              render: (_, data) => (
                <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                  {data.created_at && globalDateTime(data.created_at)}
                </RowWrapper>
              )
            },
            {
              title: "Status",
              dataIndex: "status",
              render: (_, data) => (
                <RowWrapper style={{height: 80}} className="custom-flex-row-center gap-4">
                  {Object.entries(StatusForm).find(([_key,val]) => val === data.status)?.[0]?.replace(/_/g, " ")}
                </RowWrapper>
              )
            },
            {
              title: "Action",
              dataIndex: "id",
              render: (_, data) => (
                <RowWrapper style={{height: 80}} position="RIGHT">
                  <div className="main-row right-row custom-flex-row-center-end">
                    <button 
                      className={`btn btn-success custom-flex-center ${clsx({'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': state.isLoading})}`}
                      onClick={()=>{
                        dispatch({selectedData: data})
  
                        if(isDisabledReasonForm(data)) updateStatusFormik.setFieldValue("reason", data.reason_request ?? data.admin_reject_reason)
                      }}
                    >
                      <ViewIcon />
                    </button>
                  </div>
                </RowWrapper>
              )
            }
          ]}
          pagination={{
            pageRangeDisplayed: 5,
            itemsCountPerPage: state.limit,
            totalItemsCount: state.total_data,
            activePage: state.page,
            onChange: (page) => dispatch({page})
          }}
          limitConfig={{
            limits: [...Array(10).keys()].map(key => (key + 1) * 5),
            onSelect: (limit) => dispatch({limit, page: 1}),
            selectedLimit: state.limit
          }}
        />
        <Modal
          show={!!state.selectedData}
          onHide={onModalCloseHandler}
          centered
          size="xl"
        >
          <Modal.Body className="wrapper refinitiv-modal-wrapper">
            <div className="d-flex justify-content-between">
              <div className="title">Ticket Request</div>
              <ReusableCloseIcon 
                onClick={onModalCloseHandler}
              />
            </div>
            <Table bordered={false} borderless={true} striped={false}>
              <tbody>
                <tr>
                  <td><b>UID Tokocrypto</b></td>
                  <td>: {state.selectedData?.uid_tokocrypto}</td>
                  <td><b>UID Binance</b></td>
                  <td>: {state.selectedData?.uid_binance}</td>
                  <td><b>Email Tokocrypto</b></td>
                  <td>: {state.selectedData?.email_tokocrypto}</td>
                </tr>
                <tr>
                  <td><b>Email Binance</b></td>
                  <td>: {state.selectedData?.email_binance}</td>
                  <td><b>Status</b></td>
                  <td>: {state.selectedData?.status ? Object.entries(StatusForm).find(([_key,val]) => val === state.selectedData?.status)?.[0]?.replace(/_/g, " ") : "-"}</td>
                  <td><b>Request Type</b></td>
                  <td>: {getLabelOfTypeForm(state.selectedData?.type) ?? "-"}</td>
                </tr>
                <tr>
                  <td><b>Created At</b></td>
                  <td>: {state.selectedData?.created_at ? globalDateTime(state.selectedData.created_at) : "-"}</td>
                  <td><b>Last Status Update At</b></td>
                  <td>: {state.selectedData?.updated_at ? globalDateTime(state.selectedData.updated_at) : "-"}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
            <FormWithFormik 
              name="reason"
              config={updateStatusFormik}
              type="TEXT_AREA"
              label="Reason"
              placeholder="Reason"
              disabled={state.selectedData ? isDisabledReasonForm(state.selectedData) : false}
            />
            <div className="d-flex flex-row">
            {fileFields.map((fileField, index) => fileField.value &&
              <div 
                key={index} 
                className="d-flex flex-column gap-4" 
                style={{
                  width: "100%", 
                  overflow: "hidden",
                  cursor: "pointer"
                }}
                onClick={()=>{
                  if(fileField.value) dispatch({filePreviewUrl: {
                    contentPreview: "image",
                    preview: fileField.value
                  }})
                }}
              >
                <b>{fileField.label}</b>
                <img src={fileField.value} alt={`File ${fileField.label}`} />
              </div>
            )}
            </div>
            {state.selectedData && !isDisabledReasonForm(state.selectedData) &&
              <Fragment>
                <FormWithFormik 
                  name="isChecked"
                  config={updateStatusFormik}
                  type="CHECKBOX"
                  description="Ya, saya telah melakukan pemeriksaan lebih lanjut dan melakukan review pada tiket ini."
                />
                <div className="d-flex flex-row justify-content-between gap-3">
                  <Button 
                    style={{width: "100%"}} 
                    variant="success"
                    onClick={()=>{
                      updateStatusFormik.setFieldValue("status", StatusForm.APPROVED)
  
                      updateStatusFormik.submitForm()
                    }}
                    disabled={state.isApprovalLoading}
                  >
                    Approve
                  </Button>
                  <Button 
                    style={{width: "100%"}} 
                    variant="danger"
                    onClick={()=>{
                      updateStatusFormik.setFieldValue("status", StatusForm.REJECTED)
  
                      updateStatusFormik.submitForm()
                    }}
                    disabled={state.isApprovalLoading}
                  >
                    Rejected
                  </Button>
                </div>
              </Fragment>
            }
          </Modal.Body>
        </Modal>
      </div>
      <PreviewImage 
        active={!!state.filePreviewUrl}
        contentData={state.filePreviewUrl?.contentPreview}
        url={state.filePreviewUrl?.preview}
        disablePreview={()=>dispatch({filePreviewUrl: undefined})}
      />
    </Fragment>
  )
}

export default CSResetRequest