import React, { useState, useRef, useEffect } from 'react';
import ReusableListPage, {
  FILTER_TYPE,
  TRIGGER_BTN_TYPE,
} from '../../components/reusableListPage/index';
import { Badge, OverlayTrigger, ButtonToolbar, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  exportListOTCUser,
  getListOTCUser,
} from '../../otc_services/resources/user/user_service';
import { debounce } from 'lodash';
import moment from 'moment';
import { constantDateFormat } from '../../helper';
import Swal from 'sweetalert2';

export const userStatus = [
  { id: -1, label: 'Rejected', theme: 'danger' },
  { id: 0, label: 'Pending', theme: 'warning' },
  { id: 1, label: 'Approved', theme: 'success' },
];

const Customers = () => {
  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keywords, setKeywords] = useState('');
  const [statusSelected, setStatusSelected] = useState('');

  const [activePage, setActivePage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);

  const itemsCountPerPage = 15;

  const getStatusSelected = (dataIndex) => {
    return userStatus.find((user) => user.id === dataIndex);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      className: 'tableheh all-center',
      render: (dataIndex, data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'UID',
      dataIndex: 'tokocrypto_uid',
      className: 'tableheh',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      className: 'tableheh',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullname',
      className: 'tableheh',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      className: 'tableheh',
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      className: 'tableheh',
      render: (_, data) =>
        data.created_at ? constantDateFormat(data.created_at) : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: 'tableheh center',
      render: (dataIndex) =>
        getStatusSelected(dataIndex) && (
          <Badge bg={getStatusSelected(dataIndex).theme}>
            {getStatusSelected(dataIndex).label}
          </Badge>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'tableheh center',
      render: (dataIndex) => (
        <ButtonToolbar style={{ justifyContent: 'center' }}>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-top`}>Detail</Tooltip>}
          >
            <Link
              to={{
                pathname: `/otc-finance/customers/${dataIndex}/detail`,
              }}
              className='kt-link'
            >
              <span className='btn-action'>
                <i
                  style={{ fontSize: '1rem', paddingRight: '0' }}
                  className='far fa-eye text-info'
                />
              </span>
            </Link>
          </OverlayTrigger>
          &nbsp; | &nbsp;
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}
          >
            <Link
              to={{
                pathname: `/otc-finance/customers/${dataIndex}/edit`,
              }}
              className='kt-link'
            >
              <span className='btn-action'>
                <i
                  style={{ fontSize: '1rem', paddingRight: '0' }}
                  className='fas fa-pencil-alt text-success'
                />
              </span>
            </Link>
          </OverlayTrigger>
        </ButtonToolbar>
      ),
    },
  ];

  const filters = [
    {
      type: "DATE_PICKER",
      selected: startDate,
      changeDate: (val) => setStartDate(val),
      placeholderText: 'Start Date',
    },
    {
      type: "DATE_PICKER",
      selected: endDate,
      changeDate: (val) => setEndDate(val),
      placeholderText: 'End Date',
    },
    {
      type: "INPUT_TEXT",
      onChange: (evt) => setKeywords(evt.target.value),
      value: keywords,
      placeholder: 'Search By Email, UID',
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: '- Status -', value: '' },
        ...userStatus.map((status) => ({
          label: status.label,
          value: status.id || status.id === 0 ? status.id.toString() : 'null',
        })),
      ],
      value: statusSelected,
      onChange: (evt) => setStatusSelected(evt.target.value),
    },
  ];
  const triggerBtns = [
    {
      type: "BUTTON",
      label: 'Search',
      onClick: () =>
        getList(1, {
          q: keywords,
          start_date: startDate,
          end_date: endDate,
          status: statusSelected,
        }),
    },
    {
      type: "BUTTON",
      label: 'Export',
      className: 'btn btn-outline-info',
      onClick: () =>
        exportList({
          q: keywords,
          start_date: startDate,
          end_date: endDate,
          status: statusSelected,
        }),
    },
  ];

  const getList = (page, params) => {
    setLoading(true);
    if (params.status && !isNaN(params.status))
      params.status = Number(params.status);
    else params.status = undefined;

    if (params.start_date)
      params.start_date = moment(params.start_date).format('YYYY-MM-DD');
    else params.start_date = undefined;
    if (params.end_date)
      params.end_date = moment(params.end_date).format('YYYY-MM-DD');
    else params.end_date = undefined;
    getListOTCUser({ limit: itemsCountPerPage, page, ...params })
      .then((res) => {
        res.data.data.data.forEach((d, index) => {
          d.no = (page - 1) * itemsCountPerPage + index;
        });
        setDataSources(res.data.data.data);
        setTotalItemCount(res.data.data.total_data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportList = (params) => {
    setLoading(true);
    if (params.status && !isNaN(params.status))
      params.status = Number(params.status);
    else params.status = undefined;

    if (params.start_date)
      params.start_date = moment(params.start_date).format('YYYY-MM-DD');
    else params.start_date = undefined;
    if (params.end_date)
      params.end_date = moment(params.end_date).format('YYYY-MM-DD');
    else params.end_date = undefined;
    exportListOTCUser({ ...params })
      .then((res) => {
        // console.log(res, 'res');
        Swal.fire('Success', 'Please check your email!', 'success');
        // res.data.data.data.forEach((d, index) => {
        //   d.no = (page - 1) * itemsCountPerPage + index;
        // });
        // setDataSources(res.data.data.data);
        // setTotalItemCount(res.data.data.total_data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceProcess = useRef(
    debounce((newKeywords, newStartDate, newEndDate, newStatusSelected) => {
      getList(1, {
        q: newKeywords,
        start_date: newStartDate,
        end_date: newEndDate,
        status: newStatusSelected,
      });
    }, 1000)
  );

  const filterListener = () =>
    debounceProcess.current(keywords, startDate, endDate, statusSelected);

  useEffect(filterListener, [keywords, startDate, endDate, statusSelected]);

  return (
    <ReusableListPage
      filters={filters}
      triggerBtns={triggerBtns}
      loading={loading}
      dataSources={dataSources}
      columns={columns}
      pagination={{
        activePage,
        itemsCountPerPage,
        totalItemCount,
        onChange: (pageNumber) => {
          getList(pageNumber, {
            q: keywords,
            start_date: startDate,
            end_date: endDate,
            status: statusSelected,
          });
          setActivePage(pageNumber);
        },
      }}
    />
  );
};

export default Customers;
