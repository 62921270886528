import axios from 'axios';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://service-tokoscholar.tokocrypto.com/'
      : 'https://api-tokoscholar-demo.tcdx.id/',
  // baseURL: process.env.REACT_APP_ENV === 'production' ? 'https://service.tcdx.id/' : 'https://service-kyc-demo.tokocrypto.com/'
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error?.response?.status === 401) {
      toast.error('token has expired');
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;
