import clsx from 'clsx'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from "react-bootstrap";
import ReusableFormGenerator, { FIELD_TYPE } from '../../components/reusableFormGenerator'
import { depositStatus } from './depositRequest';
import { approveOTCDeposit, cancelOTCDeposit, getAccountBalance, getDetailOTCDeposit, rejectOTCDeposit } from '../../otc_services/resources/deposit/deposit_service';
import Swal from 'sweetalert2'
import { getImageOssURL, numFormatter, redirectToNewPage } from '../../helper/index'

const DepositRequestDetail = (props) => {
  const [inputs, setInputs] = useState({
    customer: {},
    transaction_id: 0,
    currency: "",
    amount: 0,
    unique_code: 0,
    created_at: new Date(),
    deposit_status: 0,
    transfer_amount: 0,
    bank_transfer_files: null
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [freeBalance, setFreeBalance] = useState(0)
  const [bankTransferSelected, setBankTransferSelected] = useState(null)
  const [isApproveModal, setIsApproveModal] = useState(false)
  const [approveAmount, setApproveAmount] = useState(0)

  const getSelectedDepositStatus = (id) => {
    return depositStatus.find(status => status.id === id)
  }

  const getBalanceBCA = () => {
    getAccountBalance()
      .then((res) => {
        // console.log(res)
        setFreeBalance(res.data.data.free)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const firstInit = () => {
    getDetail()
    getBalanceBCA()
  }

  const getDetail = () => {
    if(props.match.params?.id){
      setIsLoading(true)
      getDetailOTCDeposit(props.match.params?.id)
        .then((res) => {
          if(res.data.data) {
            res.data.data.amount = Number(res.data.data.amount)
            res.data.data.transfer_amount = Number(res.data.data.transfer_amount)
          }
          setInputs(res.data.data)
          setApproveAmount(res.data.data.transfer_amount || 0)
        })
        .catch((err) => {
          console.log(err)
          setIsDisabled(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(firstInit, [])

  const approveHandler = () => {
    if(!approveAmount) Swal.fire(
      'Warning!',
      `Amount must be fill, or must more than 0`,
      'warning'
    )
    // else if(Number(approveAmount) < 50000000) Swal.fire(
    //   'Warning!',
    //   `Amount must more than 50.000.000`,
    //   'warning'
    // )
    else {
      setIsLoading(true)
      approveOTCDeposit(props.match.params?.id, {amount: Number(approveAmount)})
        .then(() => {
          Swal.fire(
            'Success!',
            `You has been successfully Approve this request!`,
            'success'
          )
        })
        .catch((err) => {
          console.log(err)
          const getDesc = () => {
            if(Array.isArray(err.response?.data.message)) {
              let stringHTML = ''
              err.response.data.message.forEach((m) => {
                stringHTML += `<div>${m}</div>`
              })
              return stringHTML
            }
            else return err.response?.data.message
          }
          Swal.fire({
            title: 'Failed!',
            html: getDesc(),
            icon: 'error'
          })
        })
        .finally(() => {
          setIsLoading(false)
          setIsApproveModal(false)
          getDetail()
        })
    }
  }

  const approveRejectOrCancel = (status) => {
    if(status !== "Approve") {
      Swal.fire({
        title: `Are you sure to ${status} this request?`,
        html: status === "Approve" && `
          <div class="${getTextColour()}">Current Balance: IDR ${numFormatter(freeBalance)}</div>
          <div>Deposit Request: IDR ${numFormatter(inputs.transfer_amount || 0)}</div>
        `,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, ${status}!`
      }).then((result) => {
        if (result.isConfirmed) {
          if(status === "Reject") {
            setIsLoading(true)
            rejectOTCDeposit(props.match.params?.id)
              .then(() => {
                Swal.fire(
                  'Success!',
                  `You has been successfully ${status} this request!!`,
                  'success'
                )
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                setIsLoading(false)
                getDetail()
              })
          }
          else {
            setIsLoading(true)
            cancelOTCDeposit(props.match.params?.id)
              .then(() => {
                Swal.fire(
                  'Success!',
                  `You has been successfully ${status} this request!!`,
                  'success'
                )
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                setIsLoading(false)
                getDetail()
              })
          }
        }
      })
    }
    else setIsApproveModal(true)
  }
  
  const fields = [
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Email",
        value: inputs.customer?.email,
        onChange: (evt) => setInputs({...inputs, customer: {...inputs.customer, email: evt.target.value}}),
        placeholder: "Email",
        disabled: true
      },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "UID",
        value: inputs.customer?.tokocrypto_uid,
        onChange: (evt) => setInputs({...inputs, customer: {...inputs.customer, tokocrypto_uid: evt.target.value}}),
        placeholder: "UID",
        disabled: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Full Name",
        value: inputs.customer?.fullname,
        onChange: (evt) => setInputs({...inputs, customer: {...inputs.customer, fullname: evt.target.value}}),
        placeholder: "Full Name",
        disabled: true
      },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Transaction ID",
        value: inputs.transaction_id,
        onChange: (evt) => setInputs({...inputs, transaction_id: evt.target.value}),
        placeholder: "Transaction ID",
        disabled: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Base Currency",
        value: inputs.currency?.toUpperCase(),
        onChange: (evt) => setInputs({...inputs, currency: evt.target.value}),
        placeholder: "Base Currency",
        disabled: true
      },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Amount",
        value: numFormatter(inputs.amount),
        onChange: (evt) => setInputs({...inputs, amount: evt.target.value?.replace(/\./g,"")}),
        placeholder: "Amount",
        disabled: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Unique Code",
        value: inputs.unique_code,
        onChange: (evt) => setInputs({...inputs, unique_code: evt.target.value}),
        placeholder: "Unique Code",
        disabled: true
      },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Total Amount",
        value: numFormatter(inputs.transfer_amount),
        onChange: (evt) => setInputs({...inputs, transfer_amount: evt.target.value?.replace(/\./g,"")}),
        placeholder: "Total Amount",
        disabled: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Requested At",
        value: inputs.created_at,
        onChange: (evt) => setInputs({...inputs, created_at: evt.target.value}),
        placeholder: "Requested At",
        disabled: true
      },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Status",
        value: getSelectedDepositStatus(inputs.deposit_status).label,
        onChange: (evt) => setInputs({...inputs, deposit_status: evt.target.value}),
        placeholder: "Status",
        disabled: true
      },
    ],
  ]
  const getTextColour = () => {
    if(inputs.transfer_amount > freeBalance) return "text-danger"
    else return "text-success"
  }
  const isPDF = (src) => {
    if(src.includes(".pdf")) return true
    return false
  }
  const bankTransferFilesRenderer = (isWithoutMargin) => {
    if(Array.isArray(inputs?.bank_transfer_files) && inputs?.bank_transfer_files.length > 0) return inputs?.bank_transfer_files?.map((file,index) =>
      <img 
        key={index}
        className={`img-otc-payment-proof ${bankTransferSelected === file ? "img-otc-payment-proof-active" : ""} ${!isWithoutMargin && index > 0 ? "ml-3" : ""}`}
        src={isPDF(file) ? "/media/logos/pdf-icon.png" : getImageOssURL() + file} 
        onClick={()=>isPDF(file) ? redirectToNewPage(getImageOssURL() + file, true) : setBankTransferSelected(file)}
      />
    )
    else return (
      <div>- No Data -</div>
    )
  }
  return (
    <ReusableFormGenerator 
      title="View Deposit Request"
      rightTitle={
        <div>
          <button
            type="button"
            disabled={isLoading || isDisabled}
            className={`mr-3 btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
              }
            )}`}
            onClick={()=>approveRejectOrCancel("Cancel")}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoading || isDisabled}
            className={`mr-3 btn btn-danger btn-elevate kt-login__btn-danger ${clsx(
              {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
              }
            )}`}
            onClick={()=>approveRejectOrCancel("Reject")}
          >
            Reject
          </button>
          <Modal 
            show={isApproveModal} 
            onHide={()=>{
              setIsApproveModal(false)
              setApproveAmount(inputs.transfer_amount)
            }} 
            size="lg" 
            centered
          >
            <Modal.Body>
              <ReusableFormGenerator 
                conditionalComponent={
                  <div>
                    <div className={`${getTextColour()} text-center font-2rem`}>Current Balance: IDR {numFormatter(freeBalance)}</div>
                    <div className={`text-center font-2rem`}>Deposit Request: IDR {numFormatter(inputs.transfer_amount || 0)}</div>
                    <br /><br />
                    <div className="text-center font-2rem">Are you sure to Approve this request?</div>
                  </div>
                }
                fields={[
                  [
                    {
                      type: FIELD_TYPE.NUMBER_INPUT,
                      label: "Amount",
                      value: numFormatter(approveAmount),
                      onChange: (evt) => setApproveAmount(Number(evt.target.value?.replace(/\D+/g, ''))),
                      placeholder: "Amount",
                    }
                  ]
                ]}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button 
                variant="secondary" 
                onClick={()=>{
                  setIsApproveModal(false)
                  setApproveAmount(inputs.transfer_amount)
                }}
              >
                Close
              </Button>
              <Button 
                variant="primary" 
                onClick={()=>approveHandler()}
              >
                Approve
              </Button>
            </Modal.Footer>
          </Modal>
          <button
            type="button"
            disabled={isLoading || isDisabled}
            className={`btn btn-success btn-elevate kt-login__btn-success ${clsx(
              {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
              }
            )}`}
            onClick={()=>approveRejectOrCancel("Approve")}
          >
            Approve
          </button>
        </div>
      }
      fields={fields}
      anotherFooter={
        <div className="kt-flex_row">
          <div />
          <div style={{width:'50%'}}>
            <div className={`kt-flex_row p-1 ${getTextColour()}`} style={{gap: 15}}>
              <h4 className="kt-font-boldest">Current Balance</h4>
              <h4>IDR {numFormatter(freeBalance)}</h4>
            </div>
            <div className="kt-flex_row p-1" style={{gap: 15}}>
              <h4 className="kt-font-boldest">Deposit Request</h4>
              <h4>IDR {numFormatter(inputs.transfer_amount || 0)}</h4>
            </div>
          </div>
        </div>
      }
      anotherFooter={
        <div>
          <h3>Payment Proof</h3>
          {bankTransferFilesRenderer()}
          <Modal show={!!bankTransferSelected} onHide={()=>setBankTransferSelected(null)} size="lg" centered>
            <Modal.Header closeButton>
              {bankTransferSelected && <Modal.Title>{bankTransferSelected}</Modal.Title>}
            </Modal.Header>

            <Modal.Body>
              <div className="custom-flex-row" style={{gap: 10, width:'100%'}}>
                <div className="custom-flex-col" style={{gap: 10}}>{bankTransferFilesRenderer(true)}</div>
                {bankTransferSelected && <img className="img-otc-payment-proof-w-80" src={getImageOssURL() + bankTransferSelected} />}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setBankTransferSelected(null)}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      }
      footer={
        <Fragment>
          <Link to="/otc-finance/deposit-request">
            <Button variant="outline-danger" className="mr-3">Back</Button>
          </Link>
        </Fragment>
      }
      isSubmit={(d)=>{}}
    />
  )
}

export default DepositRequestDetail