import React, { useState, useEffect, Fragment } from 'react';
// import { Button, InputGroup, FormControl, Table } from "react-bootstrap";
import ReactLoading from 'react-loading';
import moment from 'moment';
import thubActions from '../../../crud/thub';
import { Table, Modal, Badge, Form } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowBack } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { values } from 'lodash';
require('bootstrap/scss/bootstrap.scss');

// const history = createBrowserHistory();

const ValidationSchema = yup.object({
  seat_name: yup.string(),
  status: yup.string(),
  image_gallery: yup
    .array()
    .max(3)
    .test(
      'image test',
      'Image size must less or equal 500kb ',
      (value, context) => {
        console.log(value, 'valudsfsdf');
        if (value && Array.isArray(value)) {
          const isValidSize = value.find((val) => val.size >= 512000);
          return isValidSize ? false : true;
        }
        return true;
      }
    ),
});

const ThubBookingTypeSeatList = (props) => {
  const [seatList, setSeatList] = useState({
    data: [],
    totalData: 0,
    currentPage: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [token, setToken] = useState();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [seatData, setSeatData] = useState({
    seat_name: '',
    status: '',
    image_gallery: [],
  });
  const [seatImageData, setseatImageData] = useState(null);

  const { id, name } = useParams();

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
  };

  const SeatFormik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: {
      seat_name: '',
      status: '',
      image_gallery: [],
      type: '',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (values.type === 'edit') {
          await editSeatData(values);
          resetForm();
          seatImageData(null);
        } else {
          await createSeatData(values);
          resetForm();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  console.log(SeatFormik.errors, 'Errors');

  const getSeatList = async (page) => {
    const params = `?page=${page || 1}&limit=10`;
    if (token) {
      setIsLoading(true);

      await thubActions
        .fetchThubBookingTypeSeatList(token, id, params)
        .then((res) => {
          setSeatList((prevState) => {
            return {
              ...prevState,
              data: res.data.data.seat,
              totalData: res.data.data.totalData,
              totalDataOnPage: res.data.data.totalDataOnPage,
              currentPage: res.data.data.currentPage,
              totalPages: res.data.data.totalPages,
            };
          });
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setSeatList((prevState) => {
            return {
              ...prevState,
              data: [],
              totalData: 0,
              currentPage: 0,
              totalDataOnPage: 0,
              totalPages: 0,
            };
          });
          if (e.response) {
            if (e.response.data.code === 401 || e.response.data.code === 500) {
              toast.error('token has expired');
              localStorage.clear();
              props.history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const editSeatData = async (seatData) => {
    // e.preventDefault();
    if (token && seatData) {
      const body = {
        seat_name: seatData.seat_name,
        status: `${seatData.status}`,
        images: seatData.image_gallery,
      };
      setIsSubmitting(true);

      await thubActions
        .fetchThubBookingTypeEditSeatList(token, body, id, seatData.seat_id)
        .then((res) => {
          toast.success('Success edit seat data');
          setIsSubmitting(false);
          setIsEdit(!isEdit);
          getSeatList();
          // setSeatData({
          //   seat_name: '',
          //   status: '',
          //   image_gallery: null,
          // });
          // setseatImageData(null);
        })
        .catch((e) => {
          setIsSubmitting(false);
          setIsEdit(!isEdit);
          getSeatList();
          if (e.response) {
            if (e.response.data.code === 401 || e.response.data.code === 500) {
              toast.error('token has expired');
              localStorage.clear();
              props.history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const createSeatData = async (seatData) => {
    // e.preventDefault();
    if (token && seatData) {
      const body = {
        seat_name: seatData.seat_name,
        status: `${seatData.status}`,
        images: seatData.image_gallery,
      };
      setIsSubmitting(true);

      await thubActions
        .fetchThubBookingTypeCreateSeatList(token, body, id)
        .then((res) => {
          toast.success('Success create new seat data');
          setIsSubmitting(false);
          setIsAdd(!isAdd);
          // setSeatData({
          //   seat_name: '',
          //   status: '',
          //   image_gallery: null,
          // });
          // setseatImageData(null);
          getSeatList();
        })
        .catch((e) => {
          setIsSubmitting(false);
          // setIsAdd(!isAdd);
          getSeatList();
          if (e.response) {
            if (e.response.data.code === 401 || e.response.data.code === 500) {
              toast.error('token has expired');
              localStorage.clear();
              props.history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const setSeatDataForm = (field, value) => {
    setSeatData({
      ...seatData,
      [field]: value,
    });
  };

  const getDateTime = (date) => {
    const fmtDate = moment(date).format('YYYY-MM-DD - H:m:s');

    return fmtDate;
  };

  const handlePageChange = (page) => {
    setSeatList((prev) => ({
      ...prev,
      currentPage: page,
    }));
    getSeatList(page);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return 'success';
      case 0:
        return 'danger';

      default:
        return 'danger';
    }
  };

  useEffect(() => {
    getToken();
    setTimeout(() => {
      getSeatList();
    }, 1000);
  }, [token]);

  return (
    <Fragment>
      <div style={{ fontSize: '10px' }} className='head'>
        <div className='kt-portlet'>
          <div className='kt-portlet__body'>
            <div className='kt-portlet__preview'>
              <div className='kt-section'>
                <div className='row row-search'>
                  <div className='col-md-6'>
                    <h4>{name}'s Seat List</h4>
                  </div>
                  <div className='col-md-6' style={{ textAlign: 'right' }}>
                    <span
                      style={{
                        padding: '5px 20px',
                        fontSize: '0.8rem',
                        marginBottom: '5px',
                      }}
                      className='btn btn-outline-success'
                      onClick={() => {
                        setIsAdd(!isAdd);
                        // setSeatData(row);
                      }}
                    >
                      Add New Seat
                    </span>
                  </div>
                </div>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        No &nbsp;
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Room / Seat Number &nbsp;
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Images &nbsp;
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Status &nbsp;
                      </th>
                      <th className='left'>Action</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td className='center' colSpan='6'>
                          <ReactLoading
                            style={{
                              position: 'relative',
                              left: '50%',
                              fill: 'rgb(88, 103, 221)',
                              height: '5%',
                              width: '5%',
                            }}
                            type={'bars'}
                            color={'#5867dd'}
                            height={'5%'}
                            width={'5%'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {seatList.data &&
                        seatList.data.length > 0 &&
                        seatList.data.map((row, i) => (
                          <tr key={row._id}>
                            <td>{i + 1}</td>
                            <td style={{ fontWeight: '600' }}>
                              {row.seat_name}
                            </td>
                            <td>
                              <Badge bg='info'>
                                {(row.image_gallery &&
                                  row.image_gallery.length) ||
                                  0}
                              </Badge>
                            </td>
                            <td>
                              <Badge bg={getStatusColor(row.status)}>
                                {row.status === 0 ? 'Disabled' : 'Enabled'}
                              </Badge>
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              <span
                                style={{
                                  padding: '2px',
                                  fontSize: '0.8rem',
                                  width: '50%',
                                  marginBottom: '5px',
                                }}
                                className='btn btn-outline-primary'
                                onClick={() => {
                                  setIsEdit(!isEdit);
                                  SeatFormik.setValues({
                                    seat_name: row.seat_name,
                                    status: row.status,
                                    seat_id: row.seat_id,
                                    type: 'edit',
                                  });
                                  setseatImageData(row.image_gallery);
                                  // setSeatData(row);
                                }}
                              >
                                Edit
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </Table>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <button
                    type='button'
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/booking-type');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <Pagination
                    activePage={seatList.currentPage}
                    itemsCountPerPage={seatList.totalDataOnPage}
                    itemClass='page-item'
                    linkClass='page-link'
                    totalItemsCount={seatList.totalData}
                    pageRangeDisplayed={
                      seatList.totalDataOnPage >= 10 ? seatList.totalPages : 1
                    }
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={isAdd}
        onHide={() => {
          SeatFormik.setValues({
            image_gallery: '',
            seat_name: '',
            status: '',
          });
          setIsAdd(false);
          // SeatFormik.handleSubmit();
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Seat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='m-login__form m-form'
            onSubmit={SeatFormik.handleSubmit}
          >
            <div className='form-group' style={{ marginBottom: '15px' }}>
              <TextField
                fullWidth
                required
                variant='outlined'
                type='text'
                label='Seat Name'
                margin='normal'
                className='kt-width-full'
                name='seat_name'
                value={SeatFormik.values.seat_name}
                onChange={SeatFormik.handleChange}
              />
              {SeatFormik.touched.seat_name && SeatFormik.errors.seat_name && (
                <div className='row'>
                  <p className='text-danger'>{SeatFormik.errors.seat_name}</p>
                </div>
              )}
              {/* <span className="required">
                {this.state.emailError}
              </span> */}
            </div>

            <div className='form-group'>
              <label className='control-label col-md-6 p-0'>Image's</label>
              <Form.Control
                className='form-control'
                type='file'
                multiple
                onChange={(e) => {
                  // console.log(e.target.files, 'files');
                  let data = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    data.push(e.target.files[i]);
                  }
                  // console.log(data, 'data');
                  SeatFormik.setFieldValue('image_gallery', data);
                  // setseatImageData(data);
                }}
                accept='image/*'
              />
              <div className='row'>
                {SeatFormik.values?.image_gallery &&
                  SeatFormik.values.image_gallery.length > 0 &&
                  SeatFormik.values.image_gallery.map((val, key) => (
                    <div
                      style={{
                        height: '60px',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className='col-md-3 mt-3'
                    >
                      <img
                        style={{ width: '100%' }}
                        alt='seat'
                        src={URL.createObjectURL(val)}
                      />
                    </div>
                  ))}
              </div>
              {SeatFormik.touched.image_gallery &&
                SeatFormik.errors.image_gallery && (
                  <div className='row'>
                    <p className='text-danger'>
                      {SeatFormik.errors.image_gallery}
                    </p>
                  </div>
                )}
            </div>
            <div className='form-group'>
              <label className='control-label col-md-6 p-0'>Seat Status</label>
              <select
                name='status'
                value={SeatFormik.values.status}
                className='form-control mr-3'
                onChange={SeatFormik.handleChange}
                // onChange={this.changeFilter}
              >
                <option value='' selected>
                  Select Status
                </option>
                <option value='1'>Enabled</option>
                <option value='0'>Disabled</option>
              </select>
              {SeatFormik.touched.status && SeatFormik.errors.status && (
                <div className='row'>
                  <p className='text-danger'>{SeatFormik.errors.status}</p>
                </div>
              )}
            </div>

            <div
              className='kt-login__actions'
              style={{ justifyContent: 'center', display: 'flex' }}
            >
              <button
                type='submit'
                disabled={isSubmitting}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      isSubmitting,
                  }
                )}`}
              >
                Add Seat
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {seatData && (
        <Modal
          show={isEdit}
          onHide={() => {
            SeatFormik.setValues({
              image_gallery: '',
              seat_name: '',
              status: '',
            });
            setIsEdit(false);
            // SeatFormik.handleSubmit();
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Seat {seatData.seat_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              className='m-login__form m-form'
              onSubmit={SeatFormik.handleSubmit}
            >
              <div className='form-group' style={{ marginBottom: '15px' }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  type='text'
                  label='Seat Name'
                  margin='normal'
                  className='kt-width-full'
                  name='seat_name'
                  value={SeatFormik.values.seat_name}
                  onChange={SeatFormik.handleChange}
                />
                {SeatFormik.touched.seat_name && SeatFormik.errors.seat_name && (
                  <div className='row'>
                    <p className='text-danger'>{SeatFormik.errors.seat_name}</p>
                  </div>
                )}
                {/* <span className="required">
                {this.state.emailError}
              </span> */}
              </div>
              <div className='form-group'>
                <label className='control-label col-md-6 p-0'>Image's</label>
                <Form.Control
                  className='form-control'
                  type='file'
                  multiple
                  onChange={(e) => {
                    console.log(e.target.files, 'files');
                    let data = [];
                    for (let i = 0; i < e.target.files.length; i++) {
                      data.push(e.target.files[i]);
                    }
                    SeatFormik.setFieldValue('image_gallery', data);
                  }}
                  accept='image/*'
                />
                <div className='row'>
                  {SeatFormik.values?.image_gallery &&
                  SeatFormik.values.image_gallery.length > 0
                    ? SeatFormik.values.image_gallery.map((val, key) => (
                        <div className='col-md-3 mt-3'>
                          <img
                            style={{ width: '100%' }}
                            alt='seat'
                            src={URL.createObjectURL(val)}
                          />
                        </div>
                      ))
                    : seatImageData &&
                      Array.isArray(seatImageData) &&
                      seatImageData.map((val) => (
                        <div className='col-md-3 mt-3'>
                          <img style={{ width: '100%' }} alt='seat' src={val} />
                        </div>
                      ))}
                </div>
                {SeatFormik.touched.image_gallery &&
                  SeatFormik.errors.image_gallery && (
                    <div className='row'>
                      <p className='text-danger'>
                        {SeatFormik.errors.image_gallery}
                      </p>
                    </div>
                  )}
              </div>

              <div className='form-group'>
                <label className='control-label col-md-6 p-0'>
                  Seat Status
                </label>
                <select
                  name='status'
                  required
                  className='form-control mr-3'
                  value={SeatFormik.values.status}
                  onChange={SeatFormik.handleChange}
                  // onChange={this.changeFilter}
                >
                  <option value='' selected>
                    Select Status
                  </option>
                  <option value='1' selected={seatData.status == 1}>
                    Enabled
                  </option>
                  <option value='0' selected={seatData.status == 0}>
                    Disabled
                  </option>
                </select>
                {SeatFormik.touched.status && SeatFormik.errors.status && (
                  <div className='row'>
                    <p className='text-danger'>{SeatFormik.errors.status}</p>
                  </div>
                )}
              </div>

              <div
                className='kt-login__actions'
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                        isSubmitting,
                    }
                  )}`}
                >
                  Edit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  );
};

export default ThubBookingTypeSeatList;
