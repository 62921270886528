import React, { useState } from 'react'
import ReusableListPage, { FILTER_TYPE, TRIGGER_BTN_TYPE } from '../../components/reusableListPage/index'
import { Badge, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const SalesRefferalCode = () => {
  const [loading, setLoading] = useState(false)
  const [dataSources, setDataSources] = useState([])

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [keywords, setKeywords] = useState("")

  const [activePage, setActivePage] = useState(1)
  const [itemsCountPerPage, setItemsCountPerPage] = useState(15)
  const [totalItemCount, setTotalItemCount] = useState(0)

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      className: "tableheh all-center",
      render: (dataIndex, data, index) => <span>{index + 1}</span>
    },
    {
      title: "UID",
      dataIndex: "uid",
      className: "tableheh",
    },
    {
      title: "Email",
      dataIndex: "email",
      className: "tableheh",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      className: "tableheh",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      className: "tableheh",
    },
    {
      title: "Referral Code",
      dataIndex: "referralCode",
      className: "tableheh",
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "tableheh center",
      render: () => (
        <Badge bg="success">
          Approved
        </Badge>
      )
    },
    {
      title: "Action",
      dataIndex: "id",
      className: "tableheh center",
      render: (dataIndex) => (
        <ButtonToolbar style={{ justifyContent: 'center'}}>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-top`}>
                Detail
              </Tooltip>
            }
          >
            <span className="btn-action" onClick={() => this.handleDelete(dataIndex)}>
              <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye text-info" />
            </span>
          </OverlayTrigger>
          &nbsp; | &nbsp;
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-top`}>
                Edit
              </Tooltip>
            }
          >
            <Link
              to={{
                pathname: `/otc-finance/customers/${dataIndex}/edit`
              }}
              className="kt-link">
              <span className="btn-action">
                <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-success" />
              </span>
            </Link>
          </OverlayTrigger>
        </ButtonToolbar>
      )
    },
  ]

  const filters = [
    {
      type: "DATE_PICKER",
      selected: startDate,
      changeDate: (val) => {},
      placeholderText: "Start Date"
    },
    {
      type: "DATE_PICKER",
      selected: endDate,
      changeDate: (val) => {},
      placeholderText: "End Date"
    },
    {
      type: "INPUT_TEXT",
      onChange: (evt) => setKeywords(evt.target.value),
      value: keywords,
      placeholder: "Search By Email, UID"
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: "- Status -", value: "" }
      ],
      value: "",
      onChange: (evt) => {}
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: "- Sales (Referal Code) -", value: "" }
      ],
      value: "",
      onChange: (evt) => {}
    },
  ]
  const triggerBtns = [
    {
      type: "BUTTON",
      label: "Search",
      onClick: () => {}
    }
  ]

  return (
    <ReusableListPage 
      filters={filters}
      triggerBtns={triggerBtns}
      loading={loading}
      dataSources={dataSources}
      columns={columns}
      pagination={{
        activePage,
        itemsCountPerPage,
        totalItemCount,
        onChange: (pageNumber) => {
          console.log(pageNumber)
        }
      }}
    />
  )
}

export default SalesRefferalCode