import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Container, Modal, Spinner, Stack } from "react-bootstrap";
import { connect } from "react-redux";
import FormWithFormik from "../../components/reusableFormWithFormik";
import { createCallbackType, deleteCallbackType, getCallbackType, getDetailCallbackType, updateCallbackType } from "../../crud/middleware";
import { DataCallback } from "../../crud/middleware-types";
import '../../scss/global.scss';
import { actions, InitialUtilsStateProps } from "../../store/ducks/utils.duck";

const initialValues: DataCallback = { type_name: '' };

const titleData: Array<any> = [
    { id: 1, title: 'Name' },
    { id: 2, title: 'Options' },
]

const CallbackType: FC<InitialUtilsStateProps & typeof actions> = (props) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<Array<DataCallback>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [loadingGetDetail, setLoadingGetDetail] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5)
    const [totalData, setTotalData] = useState<number>(0)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [saveId, setSaveId] = useState<string | number>('')
    const [searching, setSearching] = useState<string>('')

    const getListCallback = (page: number, type_name?: string, limit?: number) => {
        setLoading(true)
        getCallbackType({ page, type_name, limit })
            .then((res) => {
                setDataSource(res.data.data?.data ?? [])
                setTotalData(res.data.data?.total_data ?? 0)
                setRowsPerPage(res.data.data?.per_page ?? 0)
            })
            .catch(err => {
                console.log(err.message)
            })
            .finally(() => setLoading(false))
    }

    const getDetailCallback = (id: string) => {
        setLoadingGetDetail(true)
        getDetailCallbackType(id)
            .then((res) => {
                configForm.setFieldValue("type_name", res.data.data?.type_name)
            })
            .catch(err => {
                console.log(err.message)
            })
            .finally(() => setLoadingGetDetail(false))
    }

    useEffect(() => {
        getListCallback(page, searching || undefined, rowsPerPage)
    }, [page, searching, rowsPerPage])

    useEffect(() => {
        if (!showModal) {
            setSaveId('')
            configForm.resetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    useEffect(() => {
        if (saveId) {
            getDetailCallback(saveId as string)
            setIsEdit(true)
        }
        else setIsEdit(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveId])

    const configFormSearch = useFormik<DataCallback>({
        initialValues,
        onSubmit: values => {
            setSearching(values.type_name ?? '')
        },
    })

    const configForm = useFormik<DataCallback>({
        initialValues,
        onSubmit: (values, { resetForm }) => {
            setLoadingSubmit(true)
            if (!isEdit) {
                createCallbackType(values)
                    .then(() => {
                        getListCallback(page, undefined, rowsPerPage)
                    })
                    .catch((err) => { console.log(err.message) })
                    .finally(() => {
                        resetForm()
                        setLoadingSubmit(false)
                        setShowModal(false)
                    })
            } else {
                updateCallbackType(saveId, values)
                    .then(() => {
                        getListCallback(page, searching || undefined, rowsPerPage)
                    })
                    .catch((err) => { console.log(err.message) })
                    .finally(() => {
                        resetForm()
                        setLoadingSubmit(false)
                        setShowModal(false)
                    })
            }
        },
    })

    const openModalAdd = () => {
        setShowModal(true)
    }
    const openModalEdit = (id: string) => {
        setShowModal(true)
        setSaveId(id)
    }
    const closeModal = () => setShowModal(false);

    const openModalDel = (id: string) =>
        props.addModal({
            type: "DANGER",
            okLabel: "Confirm",
            onOk: () => {
                deleteCallbackType(id)
                    .then(() => {
                        getListCallback(page, searching || undefined, rowsPerPage)
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },
            cancelLabel: "Cancel",
            onCancel: () => { console.log('saat cancel'); },
            closable: true,
            title: "Delete Confirmation",
            content: 'Are you sure you want to delete this data?'
        })

    return (
        <>
            <Container fluid className="box-card bg-white p-3 mx-2">
                <form onSubmit={configFormSearch.handleSubmit} className="form-search">
                    <Stack direction="horizontal" gap={3}>
                        <FormWithFormik
                            type='TEXT_INPUT'
                            name='type_name'
                            config={configFormSearch}
                            placeholder="Name"
                        />
                        <Button variant="outline-success" type="submit">Search</Button>
                    </Stack>
                </form>
            </Container>
            <Container fluid className="box-card bg-white mx-2 my-4">
                <div className=" d-flex justify-content-between head-row-data p-3">
                    {titleData.map(data => (
                        <div key={data.id}>
                            <p className="data-row-title m-0">{data.title}</p>
                        </div>
                    ))}
                </div>
                <div className="box-card" style={{ boxShadow: 'none' }}>
                    {loading ?
                        <div className="py-3 mx-auto text-center">
                            <Spinner animation="border" />
                        </div>
                        :
                        <>
                            {dataSource.map((list) => (
                                <div key={list.callback_type_id} className="d-flex justify-content-between base-row-data-client my-3 p-3">
                                    <div style={{ width: '250px' }}>
                                        <p style={{ fontWeight: '400' }}>{list.type_name}</p>
                                    </div>
                                    <div>
                                        <Stack direction="horizontal" gap={2}>
                                            <Button style={{ background: '#278DD7', border: 'none' }} onClick={() => openModalEdit(list.callback_type_id as string)}>
                                                <FontAwesomeIcon icon={faPen} />
                                            </Button>
                                            <Button onClick={() => openModalDel(list.callback_type_id as string)} style={{ background: '#BC0505', border: 'none' }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </Stack>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                </div>
            </Container>
            {dataSource.length === 0 || dataSource.length === totalData ?
                null
                :
                <div className="text-center my-5">
                    <Button variant="outline-secondary" onClick={() => setRowsPerPage(rowsPerPage + 5)}>Load More...</Button>
                </div>
            }

            <div className="btn-add-client-wrapper p-4">
                <Button className="btn-add-client" onClick={openModalAdd}>
                    <FontAwesomeIcon icon={faPlus} color='black' />
                </Button>
            </div>

            <Modal size="lg" centered show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{!isEdit ? 'Form Callback Type' : 'Update Callback Type'}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={configForm.handleSubmit}>
                        {loadingGetDetail ?
                            <p className="text-center">Loading...</p>
                            :
                            null
                        }
                        <FormWithFormik
                            type='TEXT_INPUT'
                            name='type_name'
                            className="mb-4"
                            label='Type Name'
                            config={configForm}
                            placeholder='Insert your channel name'
                        />
                        <Button variant="outline-success" disabled={loadingSubmit} type="submit">
                            {loadingSubmit ? <Spinner animation="border" variant="secondary" /> : 'Submit'}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
    return {
        ...props.utils
    }
}

export default connect(mapStateToProps, actions)(CallbackType)