import axiosInstance from "../../axios_config";

export const getListOTCTransactionHistory = (params) => {
  return axiosInstance().get("admin/deposit",{params})
}
export const getDetailOTCTransactionHistory = (id) => {
  return axiosInstance().get("admin/deposit/"+id)
}
export const confirmPaymentOTC = (id) => {
  return axiosInstance().get(`admin/deposit/${id}/confirm-payment`)
}