import axiosInstance from '../../axios_config';

export const getListOTCUser = (params) => {
  return axiosInstance().get('admin/user', { params });
};
export const getDetailOTCUser = (id) => {
  return axiosInstance().get('admin/user/' + id);
};
export const getLoginActivityOTCUser = (id, params) => {
  return axiosInstance().get('admin/login-activity/' + id, { params });
};
export const approveOTCUser = (id) => {
  return axiosInstance().patch(`admin/user/${id}/status/approve`);
};
export const rejectOTCUser = (id) => {
  return axiosInstance().patch(`admin/user/${id}/status/reject`);
};
export const updateProfile = (id, payload) => {
  return axiosInstance().post(`admin/user/${id}/update-profile`, payload);
};
export const sendResetToEmail = (id) => {
  return axiosInstance().get(`admin/user/${id}/forget-password`);
};
export const exportListOTCUser = (params) => {
  return axiosInstance().get('admin/user/export', { params });
};
