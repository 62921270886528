

const KYCDasboard = () => {
  

  return (
    <div style={{ fontSize: '11px' }} className='head'>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            { <div className='flex-fill  justify-content-end align-items-center'>
            <iframe 
                src="https://metabase.tcdx.id/public/dashboard/19fec711-336a-49f0-919d-d55552627108#bordered=false"
                width="100%"
                height="2500"
                allowFullScreen
            ></iframe>
            </div> }

          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCDasboard;
