import axios from "axios"
import { toast } from "react-toastify";
import { createBrowserHistory } from 'history';
import { ResponseAPI } from "./global-types";
import {
  AdminRole,
  AdminUsers,
  AdminUsersKYC,
  AdminUsersParams,
  AdminUsersPayload,
  CallbackParams,
  DataCallback,
  DataChannels,
  DataChannelsParam,
  ListPaymentMethod,
  Pagination,
  PaymentMethodParams,
  RolePermission,
  RolePermissionParams
} from "./middleware-types";

const history = createBrowserHistory();
const middlewareInstance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://api-middleware-payment-prod.tcdx.id'
      : 'https://api-middleware-payment-demo.tcdx.id',
})

middlewareInstance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = localStorage.getItem("token");
    config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
  }
  return config
})

middlewareInstance.interceptors.response.use(config => config, (err) => {
  if (err.response) {
    if (err.response.status === 401) {
      toast.error("token has expired");
      localStorage.clear();
      history.push("/auth");
      window.location.reload();
    }
    else {
      toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
    }
  }

  return Promise.reject(err)
})

//Role
export const getAdminRoles = () => middlewareInstance.get<ResponseAPI<Array<AdminRole>>>('/admin/roles')
export const getRolesPermission = (params: RolePermissionParams) => middlewareInstance.get<ResponseAPI<Array<AdminRole>>>('/admin/roles/permission', { params })
export const getRolesPermissionAll = () => middlewareInstance.get<ResponseAPI<Array<AdminRole>>>('/admin/roles/permission/all')
export const updateRole = (payload: RolePermission) => middlewareInstance.patch<ResponseAPI<boolean>>('/admin/roles/update', { data: payload })

//Channel
export const getDataChannels = (params: DataChannelsParam) => middlewareInstance.get<ResponseAPI<Pagination<Array<DataChannels>>>>('/admin/channel', { params })
export const getDetailChannels = (params: number | string) => middlewareInstance.get<ResponseAPI<DataChannels>>(`/admin/channel/${params}`)
export const createChannel = (payload: DataChannels) => middlewareInstance.post<ResponseAPI<boolean>>('/admin/channel', payload)
export const updateChannel = (params: number | string, payload: DataChannels) => middlewareInstance.patch<ResponseAPI<boolean>>(`/admin/channel/${params}`, payload)
export const deleteChannel = (params: number | string) => middlewareInstance.delete<ResponseAPI<boolean>>(`/admin/channel/${params}`)
export const changeStatusChannel = (params: number | string, payload: string | boolean) => middlewareInstance.patch<ResponseAPI<boolean>>(`/admin/channel/status/${params}`, payload)
export const getTypeChannels = () => middlewareInstance.get<ResponseAPI<any>>('/admin/channel/type')

//Callback type
export const getCallbackType = (params: CallbackParams) => middlewareInstance.get<ResponseAPI<Pagination<Array<DataCallback>>>>('/admin/callback-types', { params })
export const getDetailCallbackType = (params: number | string) => middlewareInstance.get<ResponseAPI<DataCallback>>(`/admin/callback-types/${params}`)
export const createCallbackType = (payload: DataCallback) => middlewareInstance.post<ResponseAPI<boolean>>('/admin/callback-types', payload)
export const updateCallbackType = (params: number | string, payload: DataCallback) => middlewareInstance.patch<ResponseAPI<boolean>>(`/admin/callback-types/${params}`, payload)
export const deleteCallbackType = (params: number | string) => middlewareInstance.delete<ResponseAPI<boolean>>(`/admin/callback-types/${params}`)
export const changeStatusCallbackType = (params: number | string) => middlewareInstance.get<ResponseAPI<boolean>>(`/admin/callback-types/${params}`)

//Callback items
export const getCallbackItems = (params: CallbackParams) => middlewareInstance.get<ResponseAPI<Pagination<Array<DataCallback>>>>('/admin/callback-items', { params })
export const getDetailCallbackItems = (params: number | string) => middlewareInstance.get<ResponseAPI<DataCallback>>(`/admin/callback-items/${params}`)
export const createCallbackItems = (payload: DataCallback) => middlewareInstance.post<ResponseAPI<boolean>>('/admin/callback-items', payload)
export const updateCallbackItems = (params: number | string, payload: DataCallback) => middlewareInstance.patch<ResponseAPI<boolean>>(`/admin/callback-items/${params}`, payload)
export const deleteCallbackItems = (params: number | string) => middlewareInstance.delete<ResponseAPI<boolean>>(`/admin/callback-items/${params}`)

//AdminUsers
export const getAdminUsers = (params: Partial<AdminUsersParams>) => middlewareInstance.get<ResponseAPI<Pagination<Array<AdminUsers>>>>('/admin/admin-users', { params })
export const createAdminUser = (payload: AdminUsersPayload) => middlewareInstance.post<ResponseAPI<boolean>>('/admin/admin-users/create', payload)
export const updateAdminUser = (id: number | string, payload: AdminUsersPayload) => middlewareInstance.patch<ResponseAPI<boolean>>('/admin/admin-users/' + id, payload)
export const deleteAdminUser = (id: number | string) => middlewareInstance.delete<ResponseAPI<boolean>>('/admin/admin-users/' + id)
export const getUsersKYC = () => middlewareInstance.get<ResponseAPI<Array<AdminUsersKYC>>>('/admin/admin-users/kyc')

//Payment Method
export const getPaymentMethod = (params: PaymentMethodParams) => middlewareInstance.get<ResponseAPI<Pagination<Array<ListPaymentMethod>>>>('/admin/payment-methods', { params })
export const getDetailPaymentMethod = (params: number | string) => middlewareInstance.get<ResponseAPI<DataCallback>>(`/admin/payment-methods/${params}`)
export const createPaymentMethod = (payload: DataCallback) => middlewareInstance.post<ResponseAPI<boolean>>('/admin/payment-methods', payload)
export const updatePaymentMethod = (params: number | string, payload: DataCallback) => middlewareInstance.patch<ResponseAPI<boolean>>(`/admin/payment-methods/${params}`, payload)
export const deletePaymentMethod = (params: number | string) => middlewareInstance.delete<ResponseAPI<boolean>>(`/admin/payment-methods/${params}`)
