import React, { useState, useEffect, Fragment } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import thubActions from '../../../crud/thub';
import { Button, Table, Modal, Badge, Tab, Tabs, Image } from 'react-bootstrap';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
import { useDebounce } from './events.constant';
import clsx from 'clsx';
require('bootstrap/scss/bootstrap.scss');
require('./events.scss');

const history = createBrowserHistory();
const ThubEventsList = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const [stringToken, setStringToken] = useState();
  const [eventsData, setEventsData] = useState({
    data: [],
    currentPage: 0,
    totalData: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [filterData, setFilterData] = useState({
    event_number: '',
    event_title: '',
    location_name: '',
    start_at_range_1: '',
    start_at_range_2: '',
    approval: '',
    status: '',
    location_type: '',
    limitData: 50,
  });
  const [eventDetail, setEventDetail] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [approvalValue, setApprovalValue] = useState();
  const [paymentValue, setPaymentValue] = useState();
  const [bookingId, setBookingId] = useState();
  const [rejectReason, setRejectReason] = useState('');
  const [createdByData, setCreatedByData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [proposalValue, setProposalValue] = useState([]);
  const [eventId, setEventId] = useState();
  const [eventIdentifier, setEventIdentifier] = useState({
    id: '',
    name: '',
  });

  const getEventsList = async (page) => {
    if (stringToken) {
      const {
        event_number,
        event_title,
        location_name,
        start_at_range_1,
        start_at_range_2,
        approval,
        status,
        limitData,
        location_type,
      } = filterData;
      const startDate = start_at_range_1
        ? start_at_range_1.split('T').join(' ')
        : '';
      const endDate = start_at_range_2
        ? start_at_range_2.split('T').join(' ')
        : '';
      const params = `?page=${page || 1}&limit=${limitData}${
        event_number && `&event_number=${event_number}`
      }${event_title && `&event_title=${event_title}`}${
        location_name && `&location_name=${location_name}`
      }${start_at_range_1 && `&start_at_range_1=${startDate}`}${
        start_at_range_2 && `&start_at_range_2=${endDate}`
      }${approval && `&approval=${approval}`}${status && `&status=${status}`}${
        location_type && `&location_type=${location_type}`
      }`;
      try {
        setLoading(true);
        const response = await thubActions.fetchThubEventsList(
          stringToken,
          params
        );

        const { data } = response.data;

        setEventsData((prev) => ({
          ...prev,
          data: data.events,
          currentPage: data.currentPage,
          totalData: data.totalData,
          totalDataOnPage: data.totalDataOnPage,
          totalPages: data.totalPages,
        }));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setEventsData([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else if (e.response.data.code === 404) {
            return;
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      }
    }
  };

  const getEventsDetail = async (eventId) => {
    setLoadingDetailData(true);
    thubActions
      .fetchThubEventsDetail(stringToken, eventId)
      .then((res) => {
        setEventDetail(res.data.data);
        setLoadingDetailData(false);
      })
      .catch((e) => {
        setLoadingDetailData(false);
        setEventDetail([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const handleChangeApproval = async () => {
    setLoading(true);

    try {
      const statusData = {
        approval: Number(approvalValue),
        reject_reason: approvalValue !== '2' ? '-' : rejectReason,
      };

      const response = await thubActions.fetchThubChangeApprovalStatus(
        stringToken,
        bookingId,
        statusData
      );

      if (response.data.code === 200 || response.data.code === 201) {
        toast.success('Successfully change approval status');
        setShowApprovalModal(false);
        setLoading(false);

        setTimeout(() => {
          setApprovalValue();
          getEventsList();
        }, 500);
      }
    } catch (e) {
      setLoading(false);
      setShowApprovalModal(false);
      setApprovalValue();
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const handleChangePayment = async () => {
    setLoading(true);

    try {
      const statusData = {
        status: Number(paymentValue),
        reject_reason: paymentValue !== '4' ? '-' : rejectReason,
      };

      const response = await thubActions.fetchThubChangePaymentStatus(
        stringToken,
        bookingId,
        statusData
      );

      if (response.data.code === 200 || response.data.code === 201) {
        setShowPaymentModal(false);
        setLoading(false);
        toast.success('Successfully changed the payment status');

        setTimeout(() => {
          setPaymentValue('');
          getEventsList();
        }, 500);
      }
    } catch (e) {
      setLoading(false);
      setShowPaymentModal(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const handleUploadProposal = async () => {
    setLoading(true);

    try {
      const data = {
        proposal: proposalValue,
      };

      const response = await thubActions.fetchThubEventsUploadProposal(
        stringToken,
        eventId,
        data
      );

      if (response.data.code === 200 || response.data.code === 201) {
        setShowPaymentModal(false);
        setLoading(false);
        toast.success('Successfully upload proposal');

        setTimeout(() => {
          setProposalValue();
          getEventsList();
          setShowProposalModal(false);
        }, 500);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowProposalModal(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const handleDeleteEvents = async () => {
    try {
      setLoading(true);
      await thubActions.fetchThubEventsDelete(stringToken, eventIdentifier.id);

      setShowDeleteModal(!showDeleteModal);
      setEventIdentifier({ id: '', name: '' });
      toast.success('Successfully deleted this event');

      setTimeout(() => {
        getEventsList();
      }, 1000);
    } catch (e) {
      setLoading(false);
      setEventIdentifier({ id: '', name: '' });
      setShowDeleteModal(!showDeleteModal);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const getDateTime = (date) => {
    const dateOffset = moment(date).utcOffset();
    const _time = moment(date);
    // .subtract(dateOffset, 'minute');
    return _time.format('DD MMM YYYY HH:mm');
  };

  const getDate = (date) => {
    const dateOffset = moment(date).utcOffset();
    const _time = moment(date);
    // .subtract(dateOffset, 'minute');
    const fmtDate = _time.format('DD MMM YYYY HH:mm');

    return fmtDate;
  };

  const getPaymentStatus = (id) => {
    switch (id) {
      case 0:
        return 'Waiting for Payment';
      case 1:
        return 'Paid';
      case 2:
        return 'Expired';
      case 3:
        return 'Waiting for Confirmation';
      case 4:
        return 'Rejected';

      default:
        return 'Paid';
    }
  };

  const getApprovalStatus = (id) => {
    switch (id) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';

      default:
        return 'Approved';
    }
  };

  const getBadgeColor = (id) => {
    switch (id) {
      case 0:
        return 'info';
      case 1:
        return 'success';
      case 2:
        return 'danger';
      case 3:
        return 'warning';
      case 4:
        return 'danger';

      default:
        return 'success';
    }
  };

  const handleChangeFilter = (name, value) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePageChange = (page) => {
    setEventsData((prev) => ({
      ...prev,
      currentPage: page,
    }));

    getEventsList(page);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getEventsList();
    }
  }, [stringToken]);

  // this for debounce filtering data so it wont repeatedly request to api for every changes in filter field
  const debouncedFilterData = useDebounce(filterData, 1000);
  useEffect(() => {
    if (debouncedFilterData) {
      getEventsList();
    }
  }, [debouncedFilterData]);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Location Type
                </label>
                <select
                  className='form-control mr-3'
                  name='location_type'
                  onChange={(e) => {
                    setFilterData({
                      event_number: '',
                      event_title: '',
                      location_name: '',
                      start_at_range_1: '',
                      start_at_range_2: '',
                      approval: '',
                      status: '',
                      location_type: '',
                      limitData: 50,
                    });
                    handleChangeFilter(e.target.name, e.target.value);
                  }}
                >
                  <option selected value=''>
                    Select Location Type
                  </option>
                  <option value='online'>Online</option>
                  <option value='offline'>Offline</option>
                  <option value='online, offline'>Online & Offline</option>
                </select>
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event Number
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='event_number'
                  placeholder='eg: 202207280006'
                  value={filterData.event_number}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event Start Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='start_at_range_1'
                  placeholder='eg: 24/03/2022'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter By Event End Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='start_at_range_2'
                  placeholder='eg: 24/03/2022'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className='kt-portlet__body'
          style={{ padding: '0 20px 20px 20px' }}
        >
          <div className='kt-portlet__preview'>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Filter Event Title
                </label>
                <input
                  type='text'
                  className='form-control mr-3'
                  name='event_title'
                  placeholder='eg: Come and Join Trading	'
                  value={filterData.event_title}
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              {filterData.location_type !== 'online' && (
                <Fragment>
                  <div className='col-md-3 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Filter By Location
                    </label>
                    <input
                      type='text'
                      className='form-control mr-3'
                      name='location_name'
                      placeholder='eg: Bali'
                      value={filterData.location_name}
                      onChange={(e) =>
                        handleChangeFilter(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Filter By Payment Status
                    </label>
                    <select
                      className='form-control mr-3'
                      name='status'
                      onChange={(e) =>
                        handleChangeFilter(e.target.name, e.target.value)
                      }
                    >
                      <option value='' selected>
                        Select Payment Status
                      </option>
                      <option value={0}>Waiting for Payment</option>
                      <option value={1}>Paid</option>
                      <option value={2}>Expired</option>
                      <option value={3}>Waiting for Confirmation</option>
                      <option value={4}>Rejected</option>
                    </select>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Filter By Approval
                    </label>
                    <select
                      className='form-control mr-3'
                      name='approval'
                      onChange={(e) =>
                        handleChangeFilter(e.target.name, e.target.value)
                      }
                    >
                      <option selected disabled>
                        Select Approval Status
                      </option>
                      <option value={0}>Pending</option>
                      <option value={1}>Approved</option>
                      <option value={2}>Rejected</option>
                    </select>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='paddupgrade'>
              <div className='events-menu__top'>
                <Link to='/thub/events/create' className='kt-link'>
                  <Button variant='success' style={{ fontSize: '11px' }}>
                    Create Events
                  </Button>
                </Link>
                <select
                  className='form-control events-limit'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                  name='limitData'
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option selected value={50}>
                    50
                  </option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section' style={{ marginBottom: 0 }}>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          No
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Number
                          {/* <button
                            onClick={(e) =>
                              this.onSortChange('email')
                            }
                            className="sort-button"
                          >
                            <i
                              className={`fas fa-${
                                sortTypes[this.state.orderSort].class
                              }`}
                            />
                          </button> */}
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Booking ID
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Code
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Title
                          <br />
                        </th>
                        {/* <th
                          className="tableheh"
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Tagline &nbsp;
                        </th> */}
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Location & Booking Room
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Event Datetime
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Booking Datetime
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Location Type
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Payment Status
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Approval Status
                        </th>

                        <th
                          className='tableheh'
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventsData && eventsData.length === 0 && (
                        <tr style={{ textAlign: 'center' }}>
                          <td colSpan={12}>No records data found</td>
                        </tr>
                      )}
                      {eventsData.data &&
                        eventsData.data.length > 0 &&
                        eventsData.data.map((row, i) => {
                          const is_event_online_only =
                            (row.location_type &&
                              row.location_type.length === 1 &&
                              String(row.location_type[0]).toLowerCase() ===
                                'online') ||
                            false;

                          return (
                            <tr key={row._id}>
                              <td>{i + 1}</td>
                              <td>{row.event_number}</td>
                              <td>{row.booking_id || '-'}</td>
                              <td>{row.event_code || '-'}</td>
                              <td>{row.event_title}</td>
                              <td>
                                {row.location_type &&
                                row.location_type.length > 0 &&
                                row.location_type.findIndex(
                                  (e) => e === 'offline'
                                ) !== -1 ? (
                                  <Fragment>
                                    <p>
                                      {`${
                                        (row.location &&
                                          row.location.location_name) ||
                                        ''
                                      } - ${
                                        (row.seat && row.seat.seat_name) || ''
                                      }`}
                                    </p>
                                  </Fragment>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {row.location_type &&
                                row.location_type.length > 0 ? (
                                  <Fragment>
                                    {row.location_type.findIndex(
                                      (e) => e === 'online'
                                    ) !== -1 ? (
                                      <Fragment>
                                        <p style={{ marginBottom: 5 }}>
                                          {`${
                                            (row.start_date_online &&
                                              getDateTime(
                                                row.start_date_online
                                              )) ||
                                            (row.start_date &&
                                              getDateTime(row.start_date)) ||
                                            ''
                                          }`}
                                        </p>
                                        <p style={{ marginBottom: 5 }}>-</p>
                                        <p>{`${
                                          (row.end_date_online &&
                                            getDateTime(row.end_date_online)) ||
                                          (row.end_date &&
                                            getDateTime(row.end_date)) ||
                                          ''
                                        }`}</p>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <p style={{ marginBottom: 5 }}>
                                          {`${
                                            (row.start_date &&
                                              getDateTime(row.start_date)) ||
                                            (row.start_date_online &&
                                              getDateTime(
                                                row.start_date_online
                                              )) ||
                                            ''
                                          }`}
                                        </p>
                                        <p style={{ marginBottom: 5 }}>-</p>
                                        <p>{`${
                                          (row.end_date &&
                                            getDateTime(row.end_date)) ||
                                          (row.end_date_online &&
                                            getDateTime(row.end_date_online)) ||
                                          ''
                                        }`}</p>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {row.location_type &&
                                row.location_type.length > 0 ? (
                                  <Fragment>
                                    {row.location_type.findIndex(
                                      (e) => e === 'offline'
                                    ) !== -1 ? (
                                      <Fragment>
                                        <p style={{ marginBottom: 5 }}>
                                          {`${
                                            (row.start_date &&
                                              getDateTime(row.start_date)) ||
                                            ''
                                          }`}
                                        </p>
                                        <p style={{ marginBottom: 5 }}>-</p>
                                        <p>{`${
                                          (row.end_date &&
                                            getDateTime(row.end_date)) ||
                                          ''
                                        } `}</p>
                                      </Fragment>
                                    ) : (
                                      '-'
                                    )}
                                  </Fragment>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {row.location_type &&
                                  row.location_type.length > 0 &&
                                  row.location_type.map((type, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        padding: '2px 5px',
                                        backgroundColor:
                                          type === 'online'
                                            ? '#182747'
                                            : '#562B08',
                                        borderRadius: 3,
                                        textAlign: 'center',
                                      }}
                                      className='mb-1'
                                    >
                                      <span
                                        style={{
                                          textTransform: 'capitalize',
                                          color: 'white',
                                        }}
                                      >
                                        {type}
                                      </span>
                                    </div>
                                  ))}
                              </td>
                              <td>
                                {is_event_online_only ? (
                                  <Badge bg={getBadgeColor(row.status)}>
                                    {getPaymentStatus(row.status)}
                                  </Badge>
                                ) : (
                                  <Badge
                                    bg={getBadgeColor(row.status)}
                                    onClick={() => {
                                      setShowPaymentModal(true);
                                      setBookingId(row.booking_id);
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {getPaymentStatus(row.status)}
                                  </Badge>
                                )}
                              </td>
                              <td>
                                <Badge
                                  bg={getBadgeColor(row.approval)}
                                  onClick={() => {
                                    if (row.approval == '0') {
                                      setShowApprovalModal(true);
                                      setBookingId(row.booking_id);
                                    }
                                  }}
                                  style={{
                                    cursor:
                                      row.approval == '0'
                                        ? 'pointer'
                                        : 'not-allowed',
                                  }}
                                >
                                  {getApprovalStatus(row.approval)}
                                </Badge>
                              </td>
                              <td className='center'>
                                <div>
                                  {' '}
                                  <span
                                    onClick={() => {
                                      getEventsDetail(row.event_id);
                                      setShowDetailModal(!showDetailModal);
                                      setCreatedByData(row.created_by);
                                    }}
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className='btn btn-outline-primary'
                                  >
                                    View
                                  </span>
                                  <br />
                                  <Link
                                    to={`/thub/events/${row.event_id}/${row.event_code}/edit`}
                                  >
                                    <span
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                      }}
                                      className='btn btn-outline-success'
                                    >
                                      Edit
                                    </span>
                                  </Link>
                                  <br />
                                  {!is_event_online_only ? (
                                    <Link
                                      to={`/thub/events/${row.booking_id}/${row.event_title}/event-history`}
                                    >
                                      <span
                                        style={{
                                          padding: '2px',
                                          fontSize: '0.8rem',
                                          width: '100%',
                                          marginBottom: '5px',
                                        }}
                                        className='btn btn-outline-secondary'
                                      >
                                        Log History
                                      </span>
                                    </Link>
                                  ) : (
                                    <button
                                      disabled
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                        cursor: 'auto',
                                      }}
                                      className='btn'
                                    >
                                      Log History
                                    </button>
                                  )}
                                  <br />
                                  <Link
                                    to={`/thub/events/${row.event_id}/${row.booking_id}/${row.event_number}/${row.event_title}/event-data`}
                                  >
                                    <span
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                      }}
                                      className='btn btn-outline-primary'
                                    >
                                      Speakers and Participants
                                    </span>
                                  </Link>
                                  <br />
                                  <span
                                    onClick={() => {
                                      setEventIdentifier({
                                        name: row.event_title,
                                        id: row.event_id,
                                      });
                                      setShowDeleteModal(!showDeleteModal);
                                      setCreatedByData(row.created_by);
                                    }}
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className='btn btn-outline-danger'
                                  >
                                    Delete
                                  </span>
                                  <br />
                                  {/* <span
                                  onClick={() => {
                                    setShowProposalModal(!showProposalModal);
                                    setEventId(row.event_id);
                                  }}
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className='btn btn-outline-secondary'
                                >
                                  {row.proposal
                                    ? 'Change Proposal'
                                    : 'Upload Proposal'}
                                </span> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className='d-flex justify-content-center align-items-center pagination-data'>
                    <Pagination
                      activePage={eventsData.currentPage}
                      itemsCountPerPage={eventsData.totalDataOnPage}
                      itemClass='page-item'
                      linkClass='page-link'
                      totalItemsCount={eventsData.totalData}
                      pageRangeDisplayed={
                        eventsData.totalDataOnPage >= filterData.limitData
                          ? eventsData.totalPages
                          : 1
                      }
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>{'Booking ID : ' + eventDetail.booking_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingDetailData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type={'bars'}
                color={'#0000FF'}
                height={'2%'}
                width={'5%'}
              />
            </div>
          ) : (
            <>
              {eventDetail && typeof eventDetail.event_id !== 'undefined' && (
                <Tabs
                  id='controlled-tab-example'
                  defaultActiveKey='home'
                  // onSelect={key => this.setState({ key })}
                >
                  <Tab eventKey='home' title='Event Details'>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Event Number</strong>
                          </td>
                          <td colSpan={5}>{eventDetail.event_number}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Event Code</strong>
                          </td>
                          <td colSpan={5}>{eventDetail.event_code}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Event Type</strong>
                          </td>
                          <td colSpan={5}>{eventDetail.event_type || '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Title</strong>
                          </td>
                          <td colSpan='5'>{eventDetail.event_title}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Category</strong>
                          </td>
                          <td colSpan='5'>{eventDetail.category || '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Type</strong>
                          </td>
                          <td colSpan='5'>{eventDetail.event_type}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Tag</strong>
                          </td>
                          <td colSpan='5'>
                            {eventDetail.event_tag.join(', ')}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>What people will learn</strong>
                          </td>
                          <td colSpan='5'>
                            {eventDetail.what_people_will_learn}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Speakers</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.speakers &&
                            eventDetail.speakers.length > 0 ? (
                              <Fragment>
                                <Link
                                  to={`/thub/events/${eventDetail.event_id}/${eventDetail.booking_id}/${eventDetail.event_number}/${eventDetail.event_title}/event-data`}
                                >
                                  View Speakers List
                                </Link>
                              </Fragment>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Description</strong>
                          </td>
                          <td colSpan='5'>{eventDetail.event_description}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Events Proposal</strong>
                          </td>
                          <td colSpan='5'>
                            {eventDetail.proposal ? (
                              <>
                                <a
                                  href={eventDetail.proposal}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  View Proposal
                                </a>
                                <span> | </span>
                                <span
                                  style={{
                                    color: '#5867dd',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setShowProposalModal(!showProposalModal);
                                    setEventId(eventDetail.event_id);
                                    setShowDetailModal(!showDetailModal);
                                  }}
                                >
                                  Change Proposal
                                </span>
                              </>
                            ) : (
                              <span
                                style={{
                                  color: '#5867dd',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setShowProposalModal(!showProposalModal);
                                  setEventId(eventDetail.event_id);
                                  setShowDetailModal(!showDetailModal);
                                }}
                              >
                                Upload Proposal
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Location & Booking Room</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.booking ? (
                              <Fragment>
                                {`${eventDetail.booking.location.location_name} - ${eventDetail.booking.seats.seat_name}`}
                              </Fragment>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Event Datetime</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.booking ? (
                              <Fragment>
                                {`${getDate(
                                  eventDetail.booking.start_date
                                )} - ${getDate(eventDetail.booking.end_date)}`}
                              </Fragment>
                            ) : (
                              <Fragment>
                                {`${getDate(
                                  eventDetail.start_date_online
                                )} - ${getDate(eventDetail.end_date_online)}`}
                              </Fragment>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Booking Datetime</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.booking ? (
                              <Fragment>
                                {`${getDateTime(
                                  eventDetail.booking.start_date
                                )} - ${getDateTime(
                                  eventDetail.booking.end_date
                                )}`}
                              </Fragment>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Customer Name</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail?.booking?.user ? (
                              <p>
                                {eventDetail?.booking?.user.first_name +
                                  ' ' +
                                  eventDetail?.booking?.user.last_name}
                              </p>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Customer Email</strong>
                          </td>
                          <td colSpan={5}>
                            <p>{eventDetail.booking?.user?.email}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Customer Wa Number</strong>
                          </td>
                          <td colSpan={5}>
                            <p>{eventDetail.booking?.user?.whatsapp_number}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Image</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail &&
                              eventDetail.event_image &&
                              eventDetail.event_image.length > 0 &&
                              eventDetail.event_image.map((image, i) => (
                                <Image
                                  thumbnail
                                  src={image}
                                  key={i}
                                  alt='gallery-img'
                                  style={{
                                    maxWidth: '200px',
                                    height: 'auto',
                                  }}
                                />
                              ))}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Participant Filled / Quota</strong>
                          </td>
                          <td colSpan={5}>
                            {`${eventDetail.participant_list.length} / ${eventDetail.participant_quota}`}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Participant List</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.participant_list &&
                            eventDetail.participant_list.length > 0 ? (
                              <Fragment>
                                <Link
                                  to={`/thub/events/${eventDetail.event_id}/${eventDetail.booking_id}/${eventDetail.event_number}/${eventDetail.event_title}/event-data`}
                                >
                                  View Participant List
                                </Link>
                              </Fragment>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Created By</strong>
                          </td>
                          <td colSpan={5}>{createdByData || '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Payment Method</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.booking &&
                            eventDetail.booking.payment_method
                              ? eventDetail.booking.payment_method.product_name
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Special Requests</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.special_request || '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Additional Notes</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.booking
                              ? eventDetail.booking.additional_note
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Allow Collaborations</strong>
                          </td>
                          <td colSpan={5}>
                            {eventDetail.allow_collaboration ? 'Yes' : 'No'}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showApprovalModal}
        onHide={() => {
          setShowApprovalModal(!showApprovalModal);
          setApprovalValue('');
          setRejectReason();
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='m-login__form m-form'
            onSubmit={(e) => {
              e.preventDefault();
              handleChangeApproval();
            }}
          >
            <div>
              <div className='form-group' style={{ marginBottom: '15px' }}>
                <select
                  className='form-control'
                  name='approval'
                  onChange={(e) => {
                    setApprovalValue(e.target.value);
                    setRejectReason();
                  }}
                  required
                >
                  <option selected>Select Approval Status</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                </select>
              </div>

              {approvalValue === '2' && (
                <div className='form-group' style={{ marginBottom: '15px' }}>
                  <input
                    type='text'
                    value={rejectReason}
                    className='form-control'
                    placeholder='Type your reject reason'
                    onChange={(e) => setRejectReason(e.target.value)}
                    required
                  />
                </div>
              )}
            </div>

            <div
              className='kt-login__actions'
              style={{
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <Button
                variant='danger'
                onClick={() => setShowApprovalModal(!showApprovalModal)}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={loading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      loading,
                  }
                )}`}
              >
                Change Approval Status
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPaymentModal}
        onHide={() => {
          setShowPaymentModal(!showPaymentModal);
          setPaymentValue('');
          setRejectReason();
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='m-login__form m-form'
            onSubmit={(e) => {
              e.preventDefault();
              handleChangePayment();
            }}
          >
            <div>
              <div className='form-group' style={{ marginBottom: '15px' }}>
                <select
                  className='form-control'
                  name='payment'
                  onChange={(e) => {
                    setPaymentValue(e.target.value);
                  }}
                  required
                >
                  <option value='' selected>
                    Select Payment Status
                  </option>
                  <option value={0}>Waiting for Payment</option>
                  <option value={1}>Paid</option>
                  <option value={2}>Expired</option>
                  <option value={3}>Waiting for Confirmation</option>
                  <option value={4}>Rejected</option>
                </select>
              </div>

              {paymentValue === '4' && (
                <div className='form-group' style={{ marginBottom: '15px' }}>
                  <input
                    type='text'
                    value={rejectReason}
                    className='form-control'
                    placeholder='Type your reject reason'
                    onChange={(e) => setRejectReason(e.target.value)}
                    required
                  />
                </div>
              )}
            </div>

            <div
              className='kt-login__actions'
              style={{
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <Button
                variant='danger'
                onClick={() => setShowPaymentModal(!showPaymentModal)}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={loading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      loading,
                  }
                )}`}
              >
                Change Payment Status
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showProposalModal}
        onHide={() => {
          setShowProposalModal(!showProposalModal);
          setProposalValue();
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload New Proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='m-login__form m-form'
            onSubmit={(e) => {
              e.preventDefault();
              handleUploadProposal();
            }}
          >
            <div>
              <div className='form-group' style={{ marginBottom: '15px' }}>
                <input
                  type='file'
                  name='proposal'
                  accept='application/pdf'
                  onChange={(e) => {
                    let proposalData = [];
                    for (let i = 0; i < e.target.files.length; i++) {
                      proposalData = [...proposalData, e.target.files[i]];
                    }

                    setProposalValue(proposalData);
                  }}
                  className='form-control'
                  required
                />
              </div>
            </div>

            <div
              className='kt-login__actions'
              style={{
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <Button
                variant='danger'
                onClick={() => setShowProposalModal(!showProposalModal)}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={loading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      loading,
                  }
                )}`}
              >
                Upload
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(!showDeleteModal)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete {eventIdentifier.name}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <Button
            variant='primary'
            onClick={() => setShowDeleteModal(!showDeleteModal)}
            className='mr-3'
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            disabled={loading}
            onClick={() => handleDeleteEvents()}
            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                  loading,
              }
            )}`}
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThubEventsList;
