import React from 'react';
import ReactLoading from 'react-loading';
// import clsx from "clsx";
import { Link } from "react-router-dom";
import moment from 'moment';
import actions from "../../crud/microsite";
import { Button, Table, OverlayTrigger, ButtonToolbar, Tooltip, Badge, Form } from "react-bootstrap";
// import { TextField, InputAdornment, IconButton } from '@material-ui/core';
// import { VisibilityOff, Visibility } from '@material-ui/icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
// import _ from 'lodash';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth
  },
  default: {
    class: 'sort',
    fn: (a, b) => a
  }
};

export default class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalData: 0,
      postPerPage: 15,
      seacrhParams: '',
      orderBy: 'id',
      orderSort: 'desc',
      active: 1,
      params: '',
      data: [],
      type: '',
      startDate: null,
      endDate: null,
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }
  
  async checkParams () {
    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }
    if(this.state.startDate){
      const st = moment(this.state.startDate).format('Y-MM-DD');
      params += '&start_date='+st;
    }
    if(this.state.endDate){
      const ed = moment(this.state.endDate).format('Y-MM-DD');
      params += '&end_date='+ed;
    }
    if(this.state.seacrhParams){
      params += '&q='+this.state.seacrhParams;
    }
    if(this.state.type){
      params += '&status='+this.state.type;
    }

    this.setState({
      loading: true
    })
    actions.fetchPost(this.getToken(), 'article', params)
    .then(res => {
      this.setState({
        data:res.data.data.data,
        loading: false,
        totalData: res.data.data.total_data
      });
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.checkParams()
  };

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.checkParams()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getDetail(id){
    this.setState({
      loading: true
    })

    actions.fetchDetailAdmin(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
        show: false,
        emailEdit: res.data.data.email,
        roleEdit: res.data.data.role,
        switchValue: res.data.data.status.toString()
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    }) 
  }

  handleDeleteOpen(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(id);
      }
    })
  }

  async handleSearch (){
    if(!this.state.startDate && this.state.endDate){
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        title: 'Please fill Start Date',
        icon: 'warning',
      })
    }
    else if(this.state.startDate && !this.state.endDate){
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        title: 'Please fill End Date',
        icon: 'warning',
      })
    }
    else {
      await this.setState({
        active: 1
      })
      this.checkParams();
    }
  }

  delete(id){
    this.setState({
      loading: true
    })

    actions.deleteDataPost(this.getToken(), 'article', id)
    .then(res => {      
      if(res.data.code === 200){
        toast.success("Delete success");
      }
      this.setState({
        loading: false,
      })
      this.checkParams();
    })
    .catch(e => {
      this.setState({
        loading: false,
        showDelete: false,
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    })
  }

  componentDidMount(){
    this.checkParams();
  }

  render(){
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body pad-10-20">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-4" style={{alignSelf: 'flex-end'}}>
                  <input 
                    type="text" 
                    className="form-control mr-2" 
                    name="search" 
                    placeholder="Search" 
                    value={this.state.seacrhParams} 
                    onChange={(e) => this.setState({ seacrhParams: e.target.value })}
                    />
                </div>
                <div className="col-md-2">
                  <Form.Group className="mb-0">
                    <Form.Label className="font-8rem">Start Date</Form.Label>
                    <DatePicker
                      className="form-control font-8rem width-date"
                      // style={{ width: '165px!important'}}
                      selected={this.state.startDate}
                      onChange={(e) => this.setState({ startDate: e })}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group className="mb-0">
                    <Form.Label className="font-8rem">End Date</Form.Label>
                    <DatePicker
                      className="form-control font-8rem width-date"
                      selected={this.state.endDate}
                      onChange={(e) => this.setState({ endDate: e })}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group className="mb-0">
                    <Form.Label className="font-8rem">Status</Form.Label>
                    <select className="form-control font-8rem" onChange={(e) => this.setState({ type: e.target.value })} placeholder="Select Data">
                        <option value="" selected>All</option>
                        <option value="0">Unpublish</option>
                        <option value="1">Published</option>
                    </select>
                  </Form.Group>
                </div>
                <div className="col-md-2" style={{alignSelf: 'flex-end'}}>
                  <div className="d-flex justify-content-center align-content-center">
                    <ButtonToolbar>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Search Data
                          </Tooltip>
                        }
                      >
                      <button className="btn btn-success btn-sm pr-5 pl-5" type="button" onClick={() => this.handleSearch()}>
                      {/* onClick={() => this.checkParams()}> */}
                        Search
                      </button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div className="paddupgrade ml-0">
                    <Link to="/btc-microsite/tags" className="kt-link">
                      <Button variant="warning" className="font-8rem" style={{fontSize: '11px', color: '#fff'}}><i className="fas fa-users-cog" />Tags Config</Button>
                    </Link>
                    &nbsp; &nbsp;
                    <Link to="/btc-microsite/article/add" className="kt-link">
                      <Button variant="success" style={{fontSize: '11px'}}>Create Article</Button>
                    </Link>
                  {/* <div className="row">
                    <div className="col-md-6">
                      
                    </div>
                    <div className="col-md-6 text-right">
                      <Link to="/btc-microsite/tags" className="kt-link">
                        <Button variant="warning" className="font-8rem" style={{fontSize: '11px', color: '#fff'}}><i className="fas fa-users-cog" />Tags Config</Button>
                      </Link>
                    </div>
                  </div> */}
                </div>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle', width: '5%' }}>
                        No &nbsp;
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        Title
                        <button onClick={(e) => this.onSortChange('title_id')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" style={{ width: '40%' }}>
                        Desc
                      </th>
                      <th className="center tableheh">
                        Tags
                      </th>
                      <th className="tableheh">
                        Created At
                        <button onClick={(e) => this.onSortChange('created_at')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="center tableheh">
                        Status
                        <button onClick={(e) => this.onSortChange('status')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="center tableheh">
                        Options
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="7"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.data.map( (row, index) => (
                        <tr key={index}>
                          <td className="center align-middle">{(index+1)+(this.state.active === 1 ? 0 : 10*(this.state.active-1))}</td>
                          <td className="align-middle">{row.title_id }</td>
                          <td className="align-middle">{row.desc_id }</td>
                          <td className="align-middle center">
                            {row.tags?.map((row, index) => (
                              <div key={index}>
                                {row.name} &nbsp;
                              </div>
                            ))}
                          </td>
                          <td className="align-middle">{this.dateTime(row.created_at)}</td>
                          <td className="align-middle center">{row.status === 1 ? 
                            <Badge bg="success">
                              Published
                            </Badge>  : 
                            <Badge bg="warning">
                              Unpublish
                            </Badge>}
                          </td>
                          <td className="center align-middle">
                            <>
                              <ButtonToolbar style={{ justifyContent: 'center'}}>
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Update Article
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/btc-microsite/article/${row.id}/update`} className="kt-link">
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                  </Link>
                                  {/* <span className="btn-action" onClick={() => this.handleShows('2',row.id)}>
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-edit text-warning" />
                                  </span> */}
                                </OverlayTrigger>
                                &nbsp; | &nbsp;
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-action" onClick={() => this.handleDeleteOpen(row.id)}>
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                  </span>
                                </OverlayTrigger>
                              </ButtonToolbar>
                            </>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  } 
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}