import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import { DownloadType, GetJournalParams, GetListTransactionParams, GetReportParams, GetReportV2Params, GetUserParams, ListTransactionType, Total, TransactionReport, TransactionResponse, Volume } from "./dashboard-types";
import { Pagination, ResponseAPI } from "./global-types";
import kycInstance from './axiosKycInstance';
import { addDays, format, parse } from "date-fns";

interface CancelPerToken {
  url: string | undefined;
  cancelToken: CancelTokenSource;
}

const cancelTokenConfiguration = (config: AxiosRequestConfig) => {
  if(!cancelTokenPerUrls.map(cancelTokenPerUrl => cancelTokenPerUrl.url).includes(config.url)) {
    cancelTokenPerUrls.push({
      url: config.url,
      cancelToken: axios.CancelToken.source()
    })
  }
  const selectedUrl = cancelTokenPerUrls.find(cancelTokenPerUrl => cancelTokenPerUrl.url === config.url)
  const indexSelectedUrl = cancelTokenPerUrls.findIndex(cancelTokenPerUrl => cancelTokenPerUrl.url === config.url)
  if (selectedUrl) {
    selectedUrl.cancelToken.cancel("Operation canceled due to new request.")
  }

  if(indexSelectedUrl > -1) {
    cancelTokenPerUrls[indexSelectedUrl].cancelToken = axios.CancelToken.source()
    config.cancelToken = cancelTokenPerUrls[indexSelectedUrl].cancelToken.token
  }
}

let cancelTokenPerUrls: Array<CancelPerToken> = []

const history =  createBrowserHistory();

const instance = (disableCancelToken?: boolean) => {
  const newInstance = axios.create({
    baseURL:
      process.env.REACT_APP_ENV === 'production'
        ? 'https://trade.tokocrypto.com/'
        : 'https://trade-demo.tcdx.id/',
  });
  newInstance.interceptors.request.use((config) => {
    if(config.headers) {
      config.headers.Authorization = localStorage.getItem("token");
      config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
    }
    if(!disableCancelToken) cancelTokenConfiguration(config)
    return config
  })
  
  newInstance.interceptors.response.use(config => config, (err) => {
    if(err.response){
      if(err.response.status === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
      }
    }
  
    return Promise.reject(err)
  })

  return newInstance
}

export const getActiveUser = (params?: GetUserParams) => instance(true).get<ResponseAPI<Total>>("/v2/admin/dashboard/daily/active-user", {params});

export const getTotalUser = () => instance().get<ResponseAPI<Total>>("/v2/admin/dashboard/total-user");

export const getDailyVolume = (params?: GetUserParams) => instance().get<ResponseAPI<Total>>("/v2/admin/dashboard/daily/volume", {params});

export const getVolume = (params: GetUserParams) => {
  let partitionDate: Array<GetUserParams> = []

  const addPartitionDate = (start_date: string, increment: number) => {
    if(addDays(parse(start_date, "dd/MM/yyyy", new Date()), increment) < parse(params.end_date, "dd/MM/yyyy", new Date())) {
      partitionDate.push({
        start_date: start_date,
        end_date: format(addDays(parse(start_date, "dd/MM/yyyy", new Date()), increment), "dd/MM/yyyy")
      })
      addPartitionDate(format(addDays(parse(start_date, "dd/MM/yyyy", new Date()), increment + 1), "dd/MM/yyyy"), increment)
    }
    else partitionDate.push({
      start_date,
      end_date: params.end_date
    })
  }
  addPartitionDate(params.start_date, 4)
  
  return new Promise<Array<Volume>>((resolve, reject) => {
    Promise.all(partitionDate.map(date => instance(true).get<ResponseAPI<Array<Volume>>>("/v2/admin/dashboard/volume", {params: date})))
      .then(vals => {
        let values: Array<Volume> = []
        vals.forEach(val => {
          if(val.data.data) val.data.data.forEach(v => values = [...values, v])
        })
        values.sort((a,b) => {
          if(a.trx_date > b.trx_date) return 1
          else return -1
        })
        resolve(values)
      })
      .catch(err => {
        reject(err)
      })
  })

  // return instance(true).get<ResponseAPI<Array<Volume>>>("/v2/admin/dashboard/volume", {params});
}

export const postOldReport = (data: GetReportParams) => kycInstance.post<ResponseAPI<string>>("/admin/dashboard/report", data);

export const getReport = <T extends DownloadType>(params: GetReportV2Params<T>) => instance().get<T extends "raw" ? ResponseAPI<Array<TransactionReport>> : string>("/v2/admin/dashboard/download-report", {params});

export const getJournal = <T extends DownloadType, U extends ListTransactionType>(params: GetJournalParams<T, U>) => instance().get<T extends "raw" ? ResponseAPI<Array<TransactionResponse<U>>> : string>("/v2/admin/dashboard/download-journal", {params});

export const getListTransactions = <T extends ListTransactionType>(params: GetListTransactionParams<T>) => instance().get<ResponseAPI<Pagination<TransactionResponse<T>>>>("/v2/admin/dashboard/journal", {params});

export const getBidrById = (id: string | number) => instance().get<ResponseAPI<string>>(`/v2/admin/dashboard/${id}/get-bidr`);

export const getAssetById = (id: string | number) => instance().get<ResponseAPI<string>>(`/v2/admin/dashboard/${id}/get-asset`);