import instance from "./axiosKycInstance";

const getUserKYC = (token, params) => {
  return instance.get("/admin/user" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchUserKYC = (token, params) => {
  return new Promise((resolve, reject) => {
    getUserKYC(token, params).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getDetailUserKYC = (token, id) => {
  return instance.get("/admin/user/" + id + "/kyc", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailUserKYC = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailUserKYC(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getHistoryUserKYC = (token, id, params) => {
  return instance.get("/admin/user/" + id + "/kyc/history" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchHistoryUserKYC = (token, id, params) => {
  return new Promise((resolve, reject) => {
    getHistoryUserKYC(token, id, params).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getPremium = (token, id) => {
  return instance.get("/admin/user/" + id + "/premium", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchPremium = (token, id) => {
  return new Promise((resolve, reject) => {
    getPremium(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getHistoryPremiumKYC = (token, id, params) => {
  return instance.get("/admin/user/" + id + "/premium/history" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchHistoryPremiumKYC = (token, id, params) => {
  return new Promise((resolve, reject) => {
    getHistoryPremiumKYC(token, id, params).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getPhoto = (token, id) => {
  return instance.get("/admin/file/" + id + "?base64=1", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchPhoto = (token, id) => {
  return new Promise((resolve, reject) => {
    getPhoto(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getLivenessPhoto = (token, id) => {
  return instance.get("/admin/user/" + id + "/liveness/download?base64=1", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchLivenessPhoto = (token, id) => {
  return new Promise((resolve, reject) => {
    getLivenessPhoto(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const postKYC = (token, id, data) => {
  return instance.post("/admin/user/" + id + "/kyc/confirm", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const confirmKYC = (token, id, data) => {
  return new Promise((resolve, reject) => {
    postKYC(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getLiveness = (token, id) => {
  return instance.get("/admin/user/" + id + "/liveness", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchLiveness = (token, id) => {
  return new Promise((resolve, reject) => {
    getLiveness(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const postKYCLiveness = (token, id, data) => {
  return instance.post("/admin/user/" + id + "/kyc/liveness/confirm", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const confirmKYCLiveness = (token, id, data) => {
  return new Promise((resolve, reject) => {
    postKYCLiveness(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const postKYCGovernment = (token, id, data) => {
  return instance.post("/admin/user/" + id + "/kyc/government/confirm", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const confirmKYCGovernment = (token, id, data) => {
  return new Promise((resolve, reject) => {
    postKYCGovernment(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const postExportData = (token, data) => {
  return instance.post("/admin/user/download-report", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchExportData = (token, data) => {
  return new Promise((resolve, reject) => {
    postExportData(token, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const patchUpdateDataDetail = (token, id, data) => {
  return instance.post("/admin/kyc/"+ id + "/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateDetailKYC = (token, id, data) => {
  return new Promise((resolve, reject) => {
    patchUpdateDataDetail(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const resetDetailKYC = (token, id, data) => {
  return instance.post("/admin/user/" + id + "/kyc/reset", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const resetKYC = (token, id, data) => {
  return new Promise((resolve, reject) => {
    resetDetailKYC(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

export default {
  fetchUserKYC: fetchUserKYC,
  fetchDetailUserKYC: fetchDetailUserKYC,
  fetchHistoryUserKYC: fetchHistoryUserKYC,
  fetchPremium: fetchPremium,
  fetchHistoryPremiumKYC: fetchHistoryPremiumKYC,
  fetchPhoto: fetchPhoto,
  fetchLivenessPhoto: fetchLivenessPhoto,
  fetchExportData: fetchExportData,
  fetchLiveness: fetchLiveness,
  confirmKYC: confirmKYC,
  confirmKYCLiveness: confirmKYCLiveness,
  confirmKYCGovernment: confirmKYCGovernment,
  updateDetailKYC: updateDetailKYC,
  resetKYC: resetKYC,
};