// import { all } from "redux-saga/effects";
import { combineReducers, Reducer } from "redux";

import * as auth from "./ducks/auth.duck";
import * as utils from "./ducks/utils.duck";
import { metronic } from "../../_metronic";
import { PersistPartial } from "redux-persist/lib/persistReducer";

export interface CombineReducersTypes {
  auth: Reducer<Partial<auth.InitialAuthStateProps> & PersistPartial, auth.ActionPayload>;
  utils: Reducer<Partial<utils.InitialUtilsStateProps> & PersistPartial, utils.ActionPayload>;
  i18n: Reducer<any, any>;
  builder: Reducer<any, any>;
}

export const rootReducer = combineReducers({
  auth: auth.reducer,
  utils: utils.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer
});

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
