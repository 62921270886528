/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Table, Modal, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
// import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import service from "../../crud/submission";
import listData from "../../crud/json";
import { obj2ParamStr } from '../../helper';
import SelectDate from '../../components/datetime';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import ReusableTable from '../../components/reusableTable';

const CreateDataSubmission = () => {
  const history =  createBrowserHistory();
  const [loading, setLoading] = useState(false)
  const [dataSources, setDataSources] = useState([])
  const [show, setShow] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [seacrhParams, setSeacrhParams] = useState("")
  const [submissionLvl, setSubmissionLvl] = useState("")
  const [emailStatus, setEmailStatus] = useState("")
  const [hasMore, setHasMore] = useState(true);

  const [avgIncomes, setAvgIncomes] = useState({})
  const [sourceOfIncomes, setSourceOfIncomes] = useState({})
  const [purposeOfAccounts, setPurposeOfAccounts] = useState({})
  const [listOfBanks, setListOfBanks] = useState([])


  const [detailPage, setDetailPage] = useState({
    current_page: 1,
    per_page: 20,
  });
  const [dataProvince, setDataProvince] = useState([])
  const [dataOccupation, setDataOccupation] = useState([])

  const getAvgIncomesService = async () => {
    try {
      const avgIncomesRes = await listData.avgIncomePerYear()
      
      if(avgIncomesRes.data.data) setAvgIncomes(avgIncomesRes.data.data)
    }
    catch(err) {
      console.log(err)
    }
  }
  const getSourceOfIncomeService = async () => {
    try {
      const sourceOfIncome = await listData.sourceOfIncome("en")
      
      if(sourceOfIncome.data.data) setSourceOfIncomes(sourceOfIncome.data.data)
    }
    catch(err) {
      console.log(err)
    }
  }
  const getPurposeOfAccountService = async () => {
    try {
      const purposeOfAccount = await listData.purposeOfAccount()
      
      if(purposeOfAccount.data.data) setPurposeOfAccounts(purposeOfAccount.data.data)
    }
    catch(err) {
      console.log(err)
    }
  }
  const getListOfBanksService = async () => {
    try {
      const banks = await listData.listOfBanks()
      
      if(banks.data.data) setListOfBanks(banks.data.data)
    }
    catch(err) {
      console.log(err)
    }
  }

  // const sortTypes = {
  //   asc: {
  //     class: 'sort-up',
  //     fn: (a, b) => a.net_worth - b.net_worth
  //   },
  //   desc: {
  //     class: 'sort-down',
  //     fn: (a, b) => b.net_worth - a.net_worth
  //   },
  //   default: {
  //     class: 'sort',
  //     fn: (a, b) => a
  //   }
  // };

  const getToken = () =>{
    let token = localStorage.getItem("token");
    return token;
  }

  const summary = async (page = 1) => {
    const currentParams = {
      page,
      limit: detailPage.per_page,
      q: seacrhParams,
    };
    submissionLvl !== '' && (currentParams.submission_number = submissionLvl);
    emailStatus !== '' && (currentParams.status = emailStatus);
    startDate && (currentParams.start_at = moment(startDate).format('YYYY-MM-DD'));
    endDate && (currentParams.end_at = moment(endDate).format('YYYY-MM-DD'));
    try {
      setLoading(true);
      const res = await service.fetchListDataSubmission(getToken(), `?${obj2ParamStr(currentParams)}`);
      if (page === 1) {
        setDataSources(res.data.data.data);
        setDetailPage((prevState) => ({
          ...prevState,
          current_page: res.data.data.current_page,
          per_page: res.data.data.per_page,
        }));
        setHasMore(true);
      } else if (res.data.data.data.length) {
        setDataSources(Array.from(dataSources).concat(res.data.data.data));
        setDetailPage((prevState) => ({
          ...prevState,
          current_page: res.data.data.current_page,
          per_page: res.data.data.per_page,
        }));
      } else {
        setHasMore(false);
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }
  };

  const exportData = async() => {
    if(!startDate || !endDate) return toast.error('please input StartDate and EndDate');
    let datas = {};
    submissionLvl !== '' && (datas.submission_number = submissionLvl);
    emailStatus !== '' && (datas.status = emailStatus);
    startDate && (datas.date_from = moment(startDate).format('YYYY-MM-DD'));
    endDate && (datas.date_to = moment(endDate).format('YYYY-MM-DD'));
    try {
      setLoading(true);
      const res = await service.downloadDataSubmission(getToken(), datas);
      let csvContent = atob(res.data.data);
      var url = window.URL.createObjectURL( new Blob([csvContent], {type: "data:application/octet-stream;base64"}) );
      // you need to precise a front-end button to have a name
      var a = document.createElement("a");
      a.download = `export-data-submission-${moment(startDate).format("YYYY-MM-DD")}.csv`;
      a.href = url;
      a.click();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }
  }

  const resendEmail = async(val) => {
    let datas = {};
    try {
      await service.resendEmail(getToken(), val, datas);
      toast.success('success resend email')
      summary(1);
    } catch (e) {
      console.log(e);
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }
  }

  const getProvinces = async() => {
    const res = await listData.fetchProvince(getToken());
    setDataProvince(res.data.data);
  }
  const getOccupations = async() => {
    const res = await listData.fetchOccupation(getToken());
    setDataOccupation(res.data.data);
  }

  useEffect(() => {
    summary(1);
    getToken();
    getProvinces();
    getOccupations();
    getAvgIncomesService()
    getSourceOfIncomeService()
    getPurposeOfAccountService()
    getListOfBanksService()
  }, []);

  const [dataDetail, setDataDetail] = useState({});
  const summaryDetail = async (id) => {
    try {
      setLoading(true);
      const res = await service.fetchDetailListDataSubmission(getToken(), id);
      setDataDetail(res.data.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }
  };

  const handleShowDetail = (val) => {
    setShow(true);
    summaryDetail(val)
  }
  const handleCloseDetail = () => {
    setShow(false);
  }

  const handleSearch = () => {
    summary(1);
  }

  const handleNextSerries = () => {
    summary(parseInt(detailPage.current_page) + 1);
  }

  const dateTime = date => {
    var fmtDate = moment(date).utc().format('DD/MM/Y hh:mm A');
    return fmtDate;
  }
  const FilterEmail = [{label: "All", value: ""},{label: "Sent", value: 'sent'},{label: "Form Submitted", value: 'submitted'},{label: "Expired", value: 'expired'}];

  // const modalSources = [
  //   {
  //     label: "Occupation",
  //     old: dataOccupation[dataDetail.occupation_old],
  //     new: dataOccupation[dataDetail.occupation_new]
  //   },
  //   {
  //     label: "Address",
  //     old: dataDetail.address_old,
  //     new: dataDetail.address_new
  //   },
  //   {
  //     label: "Province",
  //     old: dataProvince[dataDetail.province_old],
  //     new: dataProvince[dataDetail.province_new]
  //   },
  //   {
  //     label: "City",
  //     old: dataDetail.city_old,
  //     new: dataDetail.city_new
  //   },
  //   {
  //     label: "Purpose of Account Creation",
  //     old: !isNaN(dataDetail.purpose_of_account_old) ? purposeOfAccounts[dataDetail.purpose_of_account_old] : '-',
  //     new: !isNaN(dataDetail.purpose_of_account_new) ? purposeOfAccounts[dataDetail.purpose_of_account_new] : '-'
  //   },
  //   {
  //     label: "Source of Funds",
  //     old: !isNaN(dataDetail.source_of_income_old) ? sourceOfIncomes[dataDetail.source_of_income_old] : '-',
  //     new: !isNaN(dataDetail.source_of_income_new) ? sourceOfIncomes[dataDetail.source_of_income_new] : '-'
  //   },
  //   {
  //     label: "Average Annual Income",
  //     old: !isNaN(dataDetail.average_income_per_year_old) ? avgIncomes[dataDetail.average_income_per_year_old] : '-',
  //     new: !isNaN(dataDetail.average_income_per_year_new) ? avgIncomes[dataDetail.average_income_per_year_new] : '-'
  //   },
  //   {
  //     label: "Bank Account",
  //     old: !isNaN(dataDetail.bank_code_old) ? listOfBanks[dataDetail.bank_code_old]?.name : '-',
  //     new: !isNaN(dataDetail.bank_code_new) ? listOfBanks[dataDetail.bank_code_new]?.name : '-'
  //   },
  //   {
  //     label: "Account Number",
  //     old: dataDetail.account_number_old,
  //     new: dataDetail.account_number_new
  //   },
  // ]
  
  // const columns = [
  //   {
  //     title: "",
  //     dataIndex: "label"
  //   },
  //   {
  //     title: "Old Data",
  //     dataIndex: "old"
  //   },
  //   {
  //     title: "New Data",
  //     dataIndex: "new"
  //   },
  // ]

  return (
    <div className="head" style={{fontSize: '11px'}}>
      <div className="kt-portlet">
        <div className="kt-portlet__body pad-10-20">
          <div className="kt-portlet__preview">
            <div className="row">
              <div className="col-md-4 d-flex">
                <input 
                  type="text"
                  className="form-control mr-3 font-8rem"
                  name="search"
                  placeholder="Search by UID, Email, Fullname"
                  value={seacrhParams}
                  onChange={(e) => setSeacrhParams(e.target.value)} />
                <input 
                  type="number"
                  className="form-control font-8rem"
                  name="search"
                  placeholder="Submission Level"
                  value={submissionLvl}
                  onChange={(e) => setSubmissionLvl(e.target.value)} />
              </div>
              <div className="col-md-2">
                <select className="form-control font-8rem mr-3" onChange={(e) => setEmailStatus(e.target.value)} placeholder="Select Data">
                  <option disabled selected={emailStatus === '' ? true : false}>Select Type</option>
                  {FilterEmail.map((e) => (
                    <option key={e.value} value={e.value} selected={e.value === emailStatus ? true : false} defaultValue={e.value === emailStatus ? true : false}>{e.label}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex col-md-4 p-0">
                <div style={{width: '100%', marginRight: '1rem'}}>
                  <SelectDate changeDate={(val) => setStartDate(val)} />
                </div>
                <div style={{width: '100%', marginRight: '.5rem'}}>
                  <SelectDate changeDate={(val) => setEndDate(val)} />
                </div>
                <button className="btn btn-outline-warning font-8rem" type="button"  onClick={() => handleSearch()} style={{ width: '100%', height: '38px' }}>
                  Search
                </button>
              </div>
              <div className="col-md-2">
                  <ButtonToolbar>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Please input startDate and EndDate
                        </Tooltip>
                      }
                    >
                      <span
                        className="btn btn-outline-success font-8rem"
                        onClick={() => exportData()}
                        style={{ width: '100%', height: '38px' }}>
                        Export Result
                      </span>
                    </OverlayTrigger>
                  </ButtonToolbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kt-portlet kt-portlet-top">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              <InfiniteScroll
                dataLength={dataSources.length}
                next={handleNextSerries}
                hasMore={hasMore}
                // loader={<ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} />}
              >
                <Table striped>
                  <thead>
                    <tr>
                      <th className="tableheh">
                        No
                      </th>
                      <th className="tableheh">
                        User ID
                      </th>
                      <th className="tableheh">
                        Email
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        Created Date
                      </th>
                      <th className="tableheh">
                        Email Status
                      </th>
                      <th className="tableheh">
                        Submission
                      </th>
                      <th className="tableheh">
                        No. of Resend
                      </th>
                      <th className="tableheh">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataSources.map( (row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.external_user?.ref_id}</td>
                        <td>{row.external_user?.email}</td>
                        <td>{dateTime(row.created_at)}</td>
                        <td>
                          {row.status === 0 && 'Sent'}
                          {row.status === 1 && 'Form Submited'}
                          {row.status === 2 && 'Expired'}
                        </td>
                        <td>{row.submission_number}</td>
                        <td>{row.total_resend}</td>
                        <td>
                          <div>
                            <span className="btn-action" onClick={() => handleShowDetail(row.id)}>
                              <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye" />
                            </span>
                            {row.status === 0 &&
                              <span className="btn-action" onClick={() => resendEmail(row.id)}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="flaticon-mail" />
                              </span>}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </InfiniteScroll> 
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleCloseDetail} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detail Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading && dataDetail &&
            <div>
              <span className="add-selection font-16">{dataDetail.kyc?.name}</span>
              <tr className="d-flex justify-content-between add-selection mt-1 mb-3">
                <td>email: {dataDetail.kyc?.email}</td>
                <td>UID: {dataDetail.user?.ref_id}</td>
                <td>ID Number: {dataDetail.id}</td>
                <td>Submission: {dataDetail.submission_number}</td>
              </tr>
              {/* <ReusableTable 
                columns={columns}
                dataSources={modalSources}
              /> */}
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th className="tableheh" style={{ width: '20%' }}>
                      {" "}
                    </th>
                    <th className="tableheh" style={{backgroundColor: '#C4C4C4B0'}}>
                      Old Data
                    </th>
                    <th className="tableheh" style={{backgroundColor: '#6CBB07'}}>
                      New Data
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Occupation
                    </td>
                    <td>
                      {dataOccupation[dataDetail.occupation_old]}
                    </td>
                    <td>
                      {dataOccupation[dataDetail.occupation_new]}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Address
                    </td>
                    <td>
                      {dataDetail.address_old}
                    </td>
                    <td>
                      {dataDetail.address_new}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Province
                    </td>
                    <td>
                      {dataProvince[dataDetail.province_old]}
                    </td>
                    <td>
                      {dataProvince[dataDetail.province_new]}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      City
                    </td>
                    <td>
                      {dataDetail.city_old}
                    </td>
                    <td>
                      {dataDetail.city_new}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Purpose of Account Creation
                    </td>
                    <td>
                      {!isNaN(dataDetail.purpose_of_account_old) ? purposeOfAccounts[dataDetail.purpose_of_account_old] : '-'}
                    </td>
                    <td>
                      {!isNaN(dataDetail.purpose_of_account_new) ? purposeOfAccounts[dataDetail.purpose_of_account_new] : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Source of Funds
                    </td>
                    <td>
                      {!isNaN(dataDetail.source_of_income_old) ? sourceOfIncomes[dataDetail.source_of_income_old] : '-'}
                    </td>
                    <td>
                      {!isNaN(dataDetail.source_of_income_new) ? sourceOfIncomes[dataDetail.source_of_income_new] : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Average Annual Income
                    </td>
                    <td>
                      {!isNaN(dataDetail.average_income_per_year_old) ? avgIncomes[dataDetail.average_income_per_year_old] : '-'}
                    </td>
                    <td>
                      {!isNaN(dataDetail.average_income_per_year_new) ? avgIncomes[dataDetail.average_income_per_year_new] : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Bank Account
                    </td>
                    <td>
                      {!isNaN(dataDetail.bank_code_old) ? listOfBanks.find(bank => bank.code === dataDetail.bank_code_old)?.name : '-'}
                    </td>
                    <td>
                      {!isNaN(dataDetail.bank_code_new) ? listOfBanks.find(bank => bank.code === dataDetail.bank_code_new)?.name : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Account Number
                    </td>
                    <td>
                      {dataDetail.account_number_old}
                    </td>
                    <td>
                      {dataDetail.account_number_new}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreateDataSubmission