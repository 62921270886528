import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from "react-bootstrap";
import ReusableFormGenerator, { FIELD_TYPE } from '../../components/reusableFormGenerator'

const SalesRefferalCodeEdit = (props) => {
  const isEditMode = props.history.location.pathname.includes("edit")
  
  const [inputs, setInputs] = useState({
    email: "",
    uid: "",
    fullName: "",
    phoneNumber: "",
    status: "PENDING",
    registeredAt: new Date(),
    sales: "",
    resetPassword: ""
  })
  const [isLoading, setIsLoading] = useState(false)
  
  const fields = [
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Email",
        value: inputs.email,
        onChange: (evt) => setInputs({...inputs, email: evt.target.value}),
        placeholder: "Email",
        required: true,
        disabled: true
      },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "UID",
        value: inputs.uid,
        onChange: (evt) => setInputs({...inputs, uid: evt.target.value}),
        placeholder: "UID",
        required: true,
        disabled: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Full Name",
        value: inputs.fullName,
        onChange: (evt) => setInputs({...inputs, fullName: evt.target.value}),
        placeholder: "Full Name",
        required: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Phone Number",
        value: inputs.phoneNumber,
        onChange: (evt) => setInputs({...inputs, phoneNumber: evt.target.value}),
        placeholder: "Phone Number",
        required: true
      },
      {
        type: FIELD_TYPE.DROPDOWN,
        label: "Status",
        value: inputs.status,
        onChange: (evt) => setInputs({...inputs, status: evt.target.value}),
        options:[
          { label: "Pending", value: "PENDING" }
        ],
        placeholder: "Status",
        required: true
      },
    ],
    [
      {
        type: FIELD_TYPE.DATE,
        label: "Registered At",
        selected: inputs.registeredAt,
        onChange: (val) => setInputs({...inputs, registeredAt: val}),
        disabled: true
      },
      {
        type: FIELD_TYPE.DROPDOWN,
        label: "Sales",
        value: inputs.sales,
        onChange: (evt) => setInputs({...inputs, sales: evt.target.value}),
        options:[
          { label: "Pilih Sales", value: "" }
        ],
        placeholder: "Sales",
        required: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Reset Password",
        value: inputs.resetPassword,
        onChange: (evt) => setInputs({...inputs, resetPassword: evt.target.value}),
        placeholder: "Send Reset Email",
        required: true
      }
    ]
  ]
  return (
    <ReusableFormGenerator 
      title="Edit Sales"
      fields={fields}
      footer={
        <Fragment>
          <Link to="/otc-finance/sales-referral-code">
            <Button variant="outline-danger" className="mr-3">Back</Button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
              }
            )}`}
          >
            Submit
          </button>
        </Fragment>
      }
      isSubmit={(d)=>console.log(d)}
    />
  )
}

export default SalesRefferalCodeEdit