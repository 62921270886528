import { FC, useReducer } from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "react-bootstrap";
import FormWithFormik from "../../components/reusableFormWithFormik";
import { useFormik } from "formik";
import { HelpdeskStatus, SystemListRequest } from "../../crud/helpdesk-types";
import { useDebounce } from "../../helper";
import { createSystemList, getSystemList, updateSystemList } from "../../crud/helpdesk";
import Swal from "sweetalert2";
import { SectionWrapper } from "../user-new/user";
import * as yup from "yup";

interface InitialState {
  loading: boolean;
}

interface IFormCreate extends SystemListRequest {

}

const initialState: InitialState = {
  loading: false,
}

export const statusOptions = [
  {label: "ENABLE", value: HelpdeskStatus.ENABLE},
  {label: "DISABLE", value: HelpdeskStatus.DISABLE},
]

const SystemListCreateHelpdesk: FC<RouteComponentProps<{id?: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  const formikConfig = useFormik<IFormCreate>({
    initialValues: {
      name: "",
      status: HelpdeskStatus.ENABLE
    },
    onSubmit: async (values) => {
      dispatch({loading: true})
      try {

        if(values.id) await updateSystemList(values.id, values)
        else await createSystemList(values)

        Swal.fire("Success", values.id ? "Successfully updated data!" : "Successfully created data!", "success")
        props.history.push("/helpdesk/system-list")
      }
      catch(err: unknown) {
        console.log(err)
      }
      dispatch({loading: false})
    },
    validationSchema: yup.object({
      name: yup.string().required('Name must be fill!'),
    })
  })
  useDebounce<[string | undefined]>(async ()=>{
    try {
      if(props.match.params?.id) {
        const response = await getSystemList(props.match.params.id)
  
        if(response.data.data) formikConfig.setValues({
          id: props.match.params?.id,
          ...response.data.data,
        })
      }
    }
    catch(err: unknown) {
      console.log(err)
    }
    dispatch({loading: false})
  },[props.match.params?.id], undefined, () => dispatch({loading: true}))
  const isUpdateMode = !!formikConfig.values.id

  return (
    <SectionWrapper>
      <form className="custom-flex-col gap-3" onSubmit={formikConfig.handleSubmit}>
        <h2>{isUpdateMode ? "Update System List" : "Create System List"}</h2>
        <FormWithFormik 
          label="Name"
          type="TEXT_INPUT"
          name="name"
          placeholder="Name"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Status"
          type="RADIO"
          name="status"
          placeholder="Status"
          options={statusOptions}
          config={formikConfig}
        />
        <br />
        <div className="custom-flex-row-center">
          <Button 
            onClick={()=>props.history.goBack()} 
            variant="outline-info"
            disabled={state.loading}
          >
            Back
          </Button>
          <Button 
            variant="outline-success" 
            onClick={()=>formikConfig.handleSubmit()}
            disabled={state.loading}
          >
            {isUpdateMode ? "Update System List" : "Create System List"}
          </Button>
        </div>
      </form>
    </SectionWrapper>
  )
}

export default SystemListCreateHelpdesk