import React, { useState, useEffect } from 'react';
import CKEditor from 'ckeditor4-react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Form, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowBack } from '@material-ui/icons';
require('bootstrap/scss/bootstrap.scss');

const ThubCollaborationEdit = (props) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const params = useParams();

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
  };

  const onChangeFileImage = (e) => {
    let imageData = [];
    imageData = [...imageData, e.target.files[0]];

    setField('portrait_image', imageData);
  };

  const findFormErrors = () => {
    const {
      fullname,
      tittle,
      about,
      link_to_personal_data,
      instagram,
      twitter,
      portrait_image,
    } = form;
    // console.log(portrait_image, 'portrait_image');
    const newErrors = {};
    // name errors
    if (!fullname || fullname === '')
      newErrors.fullname = 'Speakers full name cant be blank!';
    if (!tittle || tittle === '')
      newErrors.tittle = 'Speakers title cant be blank!';
    if (!about || about === '')
      newErrors.about = 'Speakers about cant be blank!';
    // if (!link_to_personal_data || link_to_personal_data === '')
    //   newErrors.link_to_personal_data =
    //     'Speakers personal data link cant be blank!';
    // if (!instagram || instagram === '')
    //   newErrors.instagram = 'Speakers instagram link must not empty!';
    if (
      portrait_image &&
      Array.isArray(portrait_image) &&
      portrait_image[0].size >= 2097152
    )
      newErrors.portrait_image = 'Speakers image must less than 2mb';
    // if (!twitter || twitter === '')
    //   newErrors.twitter =
    //     'Speakers twitter social link cant be blank!';

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      editCollaboration();
    }
  };

  const editCollaboration = async () => {
    setLoading(true);
    await thubActions
      .fetchThubCollaborationSpeakersEdit(token, form, params.id)
      .then((res) => {
        toast.success('Successfully edited collaboration speakers');
        props.history.push('/thub/collaboration/list');
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getCollaborationData = (collaborationId) => {
    thubActions
      .fetchThubCollaborationDetail(token, collaborationId)
      .then((res) => {
        const {
          fullname,
          tittle,
          about,
          link_to_personal_data,
          instagram,
          twitter,
          portrait_image,
        } = res.data.data.speaker;

        const detailSpeakerData = {
          fullname: fullname || undefined,
          tittle: tittle || undefined,
          about: about || undefined,
          link_to_personal_data: link_to_personal_data || undefined,
          instagram: instagram || undefined,
          twitter: twitter || undefined,
          portrait_image: portrait_image || undefined,
        };

        setForm(detailSpeakerData);
      })
      .catch((e) => {
        console.log(e);
        toast.error(
          `error code: ${e.response.data.code} message: ${e.response.data.message}`
        );
        if (e.response) {
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token && typeof token !== 'undefined') {
      getCollaborationData(params.id);
    }
  }, [token]);

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form className='m-login__form m-form' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-3'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Speaker Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter speaker fullname'
                        value={form.fullname}
                        onChange={(e) => setField('fullname', e.target.value)}
                        isInvalid={!!errors.fullname}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.fullname}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type='text'
                        value={form.tittle}
                        placeholder='Enter speaker title'
                        onChange={(e) => setField('tittle', e.target.value)}
                        isInvalid={!!errors.tittle}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.tittle}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Link to personal data</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter speaker link to personal address'
                        value={form.link_to_personal_data}
                        onChange={(e) =>
                          setField('link_to_personal_data', e.target.value)
                        }
                        isInvalid={!!errors.link_to_personal_data}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.link_to_personal_data}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Instagram</Form.Label>
                      <Form.Control
                        type='text'
                        value={form.instagram}
                        placeholder="Enter speaker's instagram link"
                        onChange={(e) => setField('instagram', e.target.value)}
                        isInvalid={!!errors.instagram}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.instagram}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Twitter</Form.Label>
                      <Form.Control
                        type='text'
                        value={form.twitter}
                        placeholder="Enter speaker's twitter link"
                        onChange={(e) => setField('twitter', e.target.value)}
                        isInvalid={!!errors.twitter}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.twitter}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {' '}
                    <Form.Group>
                      <Form.Label>About</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        name='about'
                        placeholder="Enter speaker's about"
                        maxlength={150}
                        value={form.about}
                        onChange={(e) => setField('about', e.target.value)}
                        isInvalid={!!errors.about}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>{errors.about}</span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Speaker Portrait Image</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={(e) => onChangeFileImage(e)}
                          accept='image/*'
                          isInvalid={!!errors.portrait_image}
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Please using 1440 x 400 px for this image</span>
                      </Form.Text>
                      {errors.portrait_image && (
                        <div className='row'>
                          <span style={{ fontSize: '13px', color: 'red' }}>
                            {errors.portrait_image}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div
                    className='col-md-6'
                    style={{
                      display: 'flex',
                      columnGap: '5px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {form && form.portrait_image && (
                      <Image
                        thumbnail
                        src={
                          form.portrait_image &&
                          typeof form.portrait_image === 'string'
                            ? form.portrait_image
                            : URL.createObjectURL(form.portrait_image[0])
                        }
                        alt='gallery-img'
                        style={{
                          maxWidth: '75px',
                          height: 'auto',
                        }}
                      />
                    )}
                  </div>
                </div>

                <div
                  className='kt-login__actions'
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <button
                    type='button'
                    disabled={loading}
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/collaboration/list');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                  >
                    Edit Speakers
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThubCollaborationEdit;
