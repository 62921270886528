import React from 'react';
import clsx from "clsx";
import moment from 'moment';
import Select from 'react-select';
import { Link } from "react-router-dom";
import actions from "../../crud/launcher";
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import "react-datepicker/dist/react-datepicker.css";

require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class User extends React.Component {
  history() {
    history.push(`/launcher/${this.getID()}/link`);
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tokolink-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tokolink-prod.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      categoryTitle: '',
      title: '',
      title_en: '',
      desc: '',
      desc_en: '',
      url: '',
      icon: '',
      iconEdit: '',
      status: '1',
      label: '',
      idData: 0,
      editPage: false,
      selectedOption: 'detail',
      listTags: [],
      tagID: [],
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value
    });
  }

  emptyState(){
    this.setState({
      title: '',
      title_en: '',
      desc: '',
      desc_en: '',
      url: '',
      icon: '',
      iconEdit: '',
      status: '',
      label: '',
      editPage: false,
      listTags: [],
      tagID: [],
    });
  }

  getID(){
    let checkPath = this.props.history.location.pathname.split('/');
    const idData = checkPath[checkPath.length - 2]

    return idData
  }

  getIDLink(){
    const idLink = this.props.location.state.idLink

    return idLink
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getListTag(){
    this.setState({
      loading: true,
    })

    actions.fetchSummaryListTag(this.getToken())
    .then(res => {
      const data = res.data.data;
      const arr = [];
      data.map((e) => 
        arr.push({
          value: e.id,
          label: e.title
        })
      );
      this.setState({
        loading: false,
        listTags: arr
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 

  }

  getDetailData(id){
    this.setState({
      loading: true,
    })

    actions.fetchDetailLinkCategory(this.getToken(), id)
    .then(res => {
      const data = res.data.data;
      let role = [];
      if(data.external_launcher_tag){
        role.push({
          value: data.external_launcher_tag.id,
          label: data.external_launcher_tag.title
        })
      }
      this.setState({
        loading: false,
        tagID: role,
        title: data.link_title_id,
        title_en: data.link_title_en,
        desc: data.desc_id,
        desc_en: data.desc_en,
        iconEdit: data.link_image,
        url: data.link_url,
        status: data.link_status,
        label: data.link_label,
        selectedOption: data.type,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 

  }

  isSubmit = event => {
    event.preventDefault();

    let tags = null;
    if(this.state.tagID !== []){
      this.state.tagID.map((e) => tags = e.value);
    }

    var bodyFormData = new FormData();
    bodyFormData.append('type', this.state.selectedOption);
    bodyFormData.append('tags_id', tags);
    bodyFormData.append('link_title_id', this.state.title);
    bodyFormData.append('link_title_en', this.state.title_en);
    if (this.state.selectedOption === 'detail'){
      bodyFormData.append('desc_id', this.state.desc);
      bodyFormData.append('desc_en', this.state.desc_en);
    }
    bodyFormData.append('link_label', this.state.label === '' ? 'Read More' : this.state.label);
    bodyFormData.append('link_status', this.state.status);
    bodyFormData.append('link_url', this.state.url);
    if(this.state.icon !== null){
      bodyFormData.append('image', this.state.icon);
    }
    
    if(this.state.editPage){
      this.editEvent(bodyFormData);
    }else{
      bodyFormData.append('category_id', this.getID());
      this.addEvent(bodyFormData);
    }
  }

  addEvent(bodyFormData){
    this.setState({
      loading: true
    })

    actions.postLinkCategory(this.getToken(), bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new event is success");
      }
      this.emptyState()
      setTimeout(() => {
        this.setState({
          loading: false,
        })
        this.history()
      }, 2000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  editEvent(bodyFormData){
    this.setState({
      loading: true
    })

    actions.putLinkCategory(this.getToken(), this.getIDLink(), bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Edit new event is success");
      }
      this.emptyState()
      setTimeout(() => {
        this.setState({
          loading: false,
        })
        this.history()
      }, 2000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  onChangeFile = async (e) => {
    let file = e.target.files[0];
    if(file){
      await this.setState({
        icon: file
      })
    }
  }

  componentDidMount(){
    this.getListTag();
    const name = this.props.location.state.detailName
    let checkPath = this.props.history.location.pathname.split('/');
    this.setState({
      categoryTitle: name,
      idData: checkPath[checkPath.length - 2],
    });
    if(checkPath.indexOf("edit-link") > 0){
      this.getDetailData(this.getIDLink());
      this.setState({
        editPage: true,
      });
    }
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()

    return ISOformat
  }

  handleSelect(e) {
    let arr = []
    arr.push(e)
    this.setState({
      tagID: arr || [],
    })
  }

  render(){
    const listStatus = [
      {
        id: "1",
        name: 'Enabled',
      },
      {
        id: "0",
        name: 'Disabled',
      },
    ];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <Form.Group>
                    <Form.Label className="d-block">Display Types :</Form.Label>
                    <Form.Check 
                      inline label="Detail"
                      type="radio"
                      value="detail"
                      checked={this.state.selectedOption === 'detail'} 
                      onChange={(e) => this.handleOptionChange(e)} />
                    <Form.Check 
                      inline label="Simple"
                      type="radio"
                      value="simple"
                      checked={this.state.selectedOption === 'simple'} 
                      onChange={(e) => this.handleOptionChange(e)} />
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-8">
                      <Form.Group>
                        <Form.Label>Category</Form.Label>
                        <Form.Control type="text" placeholder="Category" value={this.state.categoryTitle} onChange={(e) => this.setState({categoryTitle: e.target.value})} required disabled/>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                      <label className="form-label">Tags (EN)</label>
                        <Select
                          value={this.state.tagID}
                          onChange={this.handleSelect}
                          options={this.state.listTags}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Link Title (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Link Title" value={this.state.title_en} onChange={(e) => this.setState({title_en: e.target.value})} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Link Title (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Link Title" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} required/>
                      </Form.Group>
                    </div>
                  </div>
                  {this.state.selectedOption === 'detail' &&
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Description (EN)</Form.Label>
                          <Form.Control as="textarea" row={3} placeholder="Enter Description" value={this.state.desc_en} onChange={(e) => this.setState({desc_en: e.target.value})} required/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Description (ID)</Form.Label>
                          <Form.Control as="textarea" row={3} placeholder="Enter Description" value={this.state.desc} onChange={(e) => this.setState({desc: e.target.value})} required/>
                        </Form.Group>
                      </div>
                    </div>
                  }
                  <Form.Group>
                    <Form.Label>URL</Form.Label>
                    <Form.Control type="text" placeholder="https://youtu.be/I6gO35AuSTA" value={this.state.url} onChange={(e) => this.setState({url: e.target.value})} required/>
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-9 col-sm-12">
                      <Form.Group>
                        <Form.Label>Background Image</Form.Label>
                        <div className="d-flex justify-content-center align-items-center">
                          <Form.Control className="form-control" type="file" onChange={this.onChangeFile} accept="image/*"/>
                        </div>  
                        <Form.Text className="text-muted">
                        {this.state.selectedOption === 'detail' ?
                          <span>*Please using 210px x 120px for this image</span>
                          :
                          <span>*Please using 24px x 24px for this image</span>}
                        </Form.Text>
                      </Form.Group>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <Form.Group>
                        <div style={{marginTop: '2rem'}}>
                          {this.state.iconEdit !== "" && 
                            <>
                              <a href={this.state.urlAsset+this.state.iconEdit} target='_blank' rel="noopener noreferrer">
                                <img src={this.state.urlAsset+this.state.iconEdit} alt="img" className="img-bn" />
                              </a>
                            </>
                          }
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <div className="input-group">
                      <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })} placeholder="Select Data">
                        {/* <option disabled selected={this.state.status === '' ? true : false}>Pilih Salah Satu</option> */}
                        {listStatus.map((e) => (
                          <option key={e.id} value={e.id} selected={e.id === this.state.status ? true : false} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Form.Group>
                    <Form.Label>Link Label</Form.Label>
                    <Form.Control type="text" placeholder="Read More" value={this.state.label} onChange={(e) => this.setState({label: e.target.value})} required/>
                  </Form.Group>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <Link to={`/launcher/${this.getID()}/link`}>
                      <Button variant="outline-danger" className="mr-3">Back To Link</Button>
                    </Link>
                    <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      Save Event
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}