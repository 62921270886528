import { ChangeEvent, FC, useEffect, useReducer, useState } from "react";
import { faEllipsis, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover, Modal } from 'react-bootstrap'
import FormWithFormik from '../../components/reusableFormWithFormik';
import { useFormik } from 'formik'
import { connect } from 'react-redux';
import { actions, InitialUtilsStateProps } from '../../store/ducks/utils.duck';
import { AdminRole, AdminUsers, AdminUsersKYC } from "../../crud/middleware-types";
import { capitalizeEveryWord, debounce, useDebounce } from "../../helper";
import { createAdminUser, deleteAdminUser, getAdminRoles, getAdminUsers, getUsersKYC, updateAdminUser } from "../../crud/middleware";
import moment from "moment";

interface InitialState {
  role: string;
  email: string;
}

const initialState: InitialState = {
  role: '',
  email: ''
}

const ManageUser: FC<InitialUtilsStateProps & typeof actions> = (props) => {
  const [isAddModal, setIsAddModal] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isEndOfPage, setIsEndOfPage] = useState(false)
  const [roles, setRoles] = useState<Array<AdminRole>>([])
  const [datas, setDatas] = useState<Array<AdminUsers>>([])
  const [emails, setEmails] = useState<Array<AdminUsersKYC>>([])
  const [selectedData, setSelectedData] = useState<AdminUsers | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [state,dispatch] = useReducer((prev: InitialState,next: Partial<InitialState>)=>({...prev,...next}), initialState)
  const formik = useFormik({
    initialValues: {
      email: '',
      role: '0',
      status: '1'
    },
    onSubmit: (vals) => {
      if(selectedData?.id) {
        updateAdminUser(selectedData.id, {
          status: Number(vals.status) as -1 | 0 | 1,
          role_middleware: vals.role,
          email: vals.email,
          role: emails.find(email => email.email === vals.email)?.role,
          id: emails.find(email => email.email === vals.email)?.id
        })
          .then(()=>{
            const findIndexData = datas.findIndex(data => data.id === selectedData.id)
            if(findIndexData > -1) {
              setDatas(prev => {
                prev[findIndexData] = {
                  ...prev[findIndexData],
                  status: Number(vals.status) as -1 | 0 | 1,
                  role_middleware: vals.role,
                  email: vals.email
                }
                return prev
              })

              props.addModal({
                type: "INFO",
                okLabel: "Ok",
                onOk: () => {},
                closable: true,
                title: "Info",
                content: 'data is successfully edited!'
              })
            }
          })
          .catch((err)=>{
            console.log(err)
            props.addModal({
              type: "DANGER",
              okLabel: "Ok",
              onOk: () => {},
              closable: true,
              title: "Info",
              content: 'Something went wrong!'
            })
          })
          .finally(()=>{
            setIsAddModal(false)
            getAdminUsersService(state.email, state.role, 1)
          })
      }
      else {
        createAdminUser({
          status: Number(vals.status) as -1 | 0 | 1,
          role_middleware: vals.role,
          email: vals.email,
          role: emails.find(email => email.email === vals.email)?.role,
          id: emails.find(email => email.email === vals.email)?.id
        })
          .then(()=>{
            props.addModal({
              type: "INFO",
              okLabel: "Ok",
              onOk: () => {},
              closable: true,
              title: "Info",
              content: 'data is successfully created!'
            })
          })
          .catch((err)=>{
            console.log(err)
            props.addModal({
              type: "DANGER",
              okLabel: "Ok",
              onOk: () => {},
              closable: true,
              title: "Info",
              content: 'Something went wrong!'
            })
          })
          .finally(()=>{
            setIsAddModal(false)
            getAdminUsersService(state.email, state.role, 1)
          })
      }
    }
  })

  const getAdminUsersService = (email: string, role: string, page: number) => {
    setIsRefreshing(true)
    getAdminUsers({
      email: !!email ? email : undefined,
      role: !!role ? role : undefined,
      limit: 15, 
      page
    })
      .then((res) => {
        if(res.data.data?.data) {
          if(page > 1) setDatas(prev => [...prev, ...res.data.data?.data ?? []])
          else setDatas(res.data.data?.data ?? [])
        }
        if(res.data.data?.total_page) setTotalPage(res.data.data.total_page)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(()=>setIsRefreshing(false))
  }

  useDebounce<[string, string, number]>(getAdminUsersService, [state.email, state.role, currentPage], 200)

  useDebounce<[boolean, boolean]>((refreshing, endOfPage)=>{
    if(endOfPage && !refreshing) setCurrentPage(prev => prev+1)
  }, [isRefreshing, isEndOfPage], 200)

  useEffect(()=>{
    getAdminRoles()
      .then((res) => {
        setRoles(res.data.data ?? [])
      })
      .catch((err)=>{
        console.log(err)
      })
      
    getUsersKYC()
      .then((res) => {
        setEmails(res.data.data ?? [])
      })
      .catch((err)=>{
        console.log(err)
      })
    
    window.addEventListener('scroll',debounce(()=>{
      if(currentPage < totalPage && window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) setIsEndOfPage(true)
      else setIsEndOfPage(false)
    }, 200))
  }, [])

  const popoverHelper = (type: "SET_STATUS" | "MODIFY" | "DELETE", data: AdminUsers) => {
    if(type === "SET_STATUS") {
      props.addModal({
        type: "INFO",
        okLabel: "Confirm",
        onOk: () => {
          updateAdminUser(data.id, {
            status: data.status === 1 ? 0 : 1,
            role_middleware: data.role_middleware
          })
            .then(()=>{
              setDatas(prev => {
                return prev.map(p => {
                  if(p.id === data.id) p.status = data.status === 1 ? 0 : 1
                  return p
                })
              })
              setTimeout(()=>{
                props.addModal({
                  type: "INFO",
                  okLabel: "Ok",
                  onOk: () => {},
                  closable: true,
                  title: "Info",
                  content: 'Set data status is successfull'
                })
              }, 400)
            })
            .catch(err => {
              console.log(err)
            })
        },
        cancelLabel: "Cancel",
        onCancel: () => {},
        closable: true,
        title: `${data.status === 1 ? "Deactivate" : "Activate"} Confirmation`,
        content: `Are you sure you want to ${data.status === 1 ? "Deactivate" : "Activate"} this data?`
      })
    }
    else if(type === "MODIFY") {
      setSelectedData(data)
      setIsAddModal(true)
      formik.setFieldValue('email', data.email)
      formik.setFieldValue('role', data.role_middleware)
      formik.setFieldValue('status', data.status.toString())
    }
    else {
      props.addModal({
        type: "DANGER",
        okLabel: "Confirm",
        onOk: () => {
          deleteAdminUser(data.id)
            .then(()=>{
              getAdminUsersService(state.email, state.role, 1)
              setTimeout(()=>{
                props.addModal({
                  type: "INFO",
                  okLabel: "Ok",
                  onOk: () => {},
                  closable: true,
                  title: "Info",
                  content: 'Delete data is successfull'
                })
              }, 400)
            })
            .catch((err)=>{
              console.log(err)
            })
        },
        cancelLabel: "Cancel",
        onCancel: () => {},
        closable: true,
        title: "Delete Confirmation",
        content: 'Are you sure you want to delete this data?'
      })
    }
  }

  useEffect(()=>{
    if(!isAddModal) {
      setSelectedData(undefined)
      formik.resetForm()
    }
  }, [isAddModal])

  return (
    <div className='head' style={{ fontSize: '10px' }}>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pad-10-20'>
          <div className='kt-portlet__preview'>
            <div className='row'>
              <div className='col-md-2'>
                <select 
                  className='form-control font-8rem'
                  onChange={(evt)=>{
                    setCurrentPage(1)
                    dispatch({role: evt.target.value})
                  }}
                >
                  <option value=''> - Select Roles -</option>
                  {roles.map((role, index)=><option key={index} value={role.name}>{role.name}</option>)}
                </select>
              </div>
              <div className='col-md-2'>
                <input
                  style={{ fontSize: '0.8rem' }}
                  type='text'
                  className='form-control mr-2'
                  placeholder="Email address"
                  onChange={(evt)=>{
                    setCurrentPage(1)
                    dispatch({email: evt.target.value})
                  }}
                />
              </div>
              <div className={`col-md-2`}>
                <div className='d-flex'>
                  <button
                    className={`btn btn-outline-success mr-3 font-8rem`}
                    type='button'
                    onClick={()=>setCurrentPage(1)}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="users_grid">
        {datas.map((data,index)=>
          <div className="users_grid__child" key={index}>
            <div className="users_grid__child__top">
              <ul className="list-status mb-0" style={{ color: data.status === 1 ? '#2DA602' : '#bc0505' }}>
                <li>
                  <p style={{ fontSize: '15px', color: 'black' }}>
                    {data.status === 1 ? 'Active' : 'Inactive'}
                  </p>
                </li>
              </ul>
              <OverlayTrigger 
                trigger="focus" 
                placement="bottom-start" 
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body className="popover-body">
                      <div className="popover-body__child" onClick={()=>popoverHelper("SET_STATUS", data)}>{data.status === 0 ? 'Enable' : 'Disable'}</div>
                      <div className="popover-body__child" onClick={()=>popoverHelper("MODIFY", data)}>Modify</div>
                      <div className="popover-body__child" onClick={()=>popoverHelper("DELETE", data)}>Delete</div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button style={{ background: 'none', border: 'none', padding: 0 }}>
                  <FontAwesomeIcon icon={faEllipsis} color="#838383" />
                </Button>
              </OverlayTrigger>
            </div>
            <svg className="users_grid__child__image" width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M33.5 16.75C31.4295 16.75 29.4055 17.364 27.6839 18.5143C25.9623 19.6646 24.6205 21.2996 23.8281 23.2125C23.0358 25.1254 22.8285 27.2304 23.2324 29.2611C23.6363 31.2918 24.6334 33.1572 26.0975 34.6213C27.5616 36.0854 29.4269 37.0824 31.4577 37.4863C33.4884 37.8903 35.5933 37.683 37.5062 36.8906C39.4191 36.0983 41.0541 34.7565 42.2045 33.0349C43.3548 31.3133 43.9688 29.2893 43.9688 27.2188C43.9688 24.4423 42.8658 21.7795 40.9025 19.8162C38.9393 17.853 36.2765 16.75 33.5 16.75ZM33.5 33.5C32.2577 33.5 31.0433 33.1316 30.0103 32.4414C28.9774 31.7512 28.1723 30.7702 27.6969 29.6225C27.2215 28.4747 27.0971 27.2118 27.3394 25.9933C27.5818 24.7749 28.18 23.6557 29.0585 22.7772C29.9369 21.8988 31.0562 21.3006 32.2746 21.0582C33.493 20.8158 34.756 20.9402 35.9037 21.4156C37.0515 21.891 38.0325 22.6961 38.7227 23.7291C39.4129 24.762 39.7813 25.9764 39.7813 27.2188C39.7796 28.8841 39.1173 30.4808 37.9397 31.6584C36.7621 32.836 35.1654 33.4983 33.5 33.5Z" fill="#AEAEAE"/>
              <path d="M33.5 4.1875C27.7025 4.1875 22.0353 5.90665 17.2149 9.12755C12.3944 12.3484 8.63739 16.9264 6.41879 22.2826C4.2002 27.6388 3.61972 33.5325 4.75074 39.2186C5.88177 44.9047 8.67352 50.1276 12.7729 54.2271C16.8724 58.3265 22.0954 61.1182 27.7814 62.2493C33.4675 63.3803 39.3613 62.7998 44.7174 60.5812C50.0736 58.3626 54.6516 54.6056 57.8725 49.7852C61.0934 44.9647 62.8125 39.2975 62.8125 33.5C62.8036 25.7286 59.7125 18.278 54.2173 12.7827C48.722 7.28749 41.2715 4.19637 33.5 4.1875ZM20.9375 55.2268V52.3438C20.9392 50.6784 21.6015 49.0817 22.7791 47.9041C23.9567 46.7265 25.5534 46.0642 27.2188 46.0625H39.7813C41.4466 46.0642 43.0433 46.7265 44.2209 47.9041C45.3985 49.0817 46.0608 50.6784 46.0625 52.3438V55.2268C42.2501 57.4529 37.9147 58.626 33.5 58.626C29.0853 58.626 24.7499 57.4529 20.9375 55.2268ZM50.2333 52.1888C50.1915 49.4425 49.0726 46.8225 47.1176 44.8933C45.1625 42.9641 42.5279 41.8802 39.7813 41.875H27.2188C24.4721 41.8802 21.8375 42.9641 19.8824 44.8933C17.9274 46.8225 16.8085 49.4425 16.7668 52.1888C12.9699 48.7985 10.2923 44.3349 9.08863 39.389C7.88495 34.4431 8.21191 29.2483 10.0262 24.4924C11.8405 19.7365 15.0566 15.6438 19.2486 12.7563C23.4406 9.86882 28.4108 8.32271 33.5011 8.32271C38.5913 8.32271 43.5615 9.86882 47.7535 12.7563C51.9455 15.6438 55.1616 19.7365 56.9759 24.4924C58.7902 29.2483 59.1172 34.4431 57.9135 39.389C56.7098 44.3349 54.0322 48.7985 50.2354 52.1888H50.2333Z" fill="#AEAEAE"/>
            </svg>
            <div className="users_grid__child__title">
              {data.email}
            </div>
            <div className="users_grid__child__info_label">
              {capitalizeEveryWord(data.role_middleware.replace(/_/g," "))}
            </div>
            <div className="users_grid__child__infos">
              <div className="users_grid__child__infos__info">
                <div>Created Date</div>
                <div>{data.created_at ? moment(data.created_at).format('dd/MMM/YYYY hh:mm:ss') : '-'}</div>
              </div>
              <div className="users_grid__child__infos__info">
                <div>Created By</div>
                <div>-</div>
              </div>
              <div className="users_grid__child__infos__info">
                <div>Latest Update</div>
                <div>{data.updated_at ? moment(data.updated_at).format('dd/MMM/YYYY hh:mm:ss') : '-'}</div>
              </div>
              <div className="users_grid__child__infos__info">
                <div>Updated By</div>
                <div>-</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="btn-add-client-wrapper p-4">
        <Button className="btn-add-client" onClick={()=>setIsAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} color='black' />
        </Button>
      </div>
      <Modal size="lg" centered show={isAddModal} onHide={()=>setIsAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Form Users</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <FormWithFormik
              type='SEARCHABLE_SELECT'
              name='email'
              className="mb-4"
              label='Email Address'
              options={emails.map(email => ({
                label: email.email,
                value: email.email
              }))}
              config={formik}
              placeholder='Insert email address'
            />
            <FormWithFormik
              type='RADIO'
              name='role'
              label='Label'
              config={formik}
              className="mb-4 mr-2"
              options={roles.map((role)=>({
                label: capitalizeEveryWord(role.name.replace(/_/g," ")),
                value: role.name
              }))}
            />
            <FormWithFormik
              type='RADIO'
              name='status'
              label='Label'
              config={formik}
              className="mb-4 mr-2"
              options={[
                { label: "Active", value: "1" },
                { label: "Disable", value: "0" }
              ]}
            />
            <Button variant="outline-success" onClick={()=>formik.handleSubmit()}>
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
    return {
        ...props.utils
    }
}

export default connect(mapStateToProps, actions)(ManageUser)