import moment from "moment";
import { FC, DragEvent, UIEventHandler, useEffect, useReducer, useRef, useState, CSSProperties, ReactNode, Fragment } from "react";
import "./_kanban.scss";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { sanitizedHtml, useIntersect } from "../../helper";

interface KanbanState {
  isTop: boolean;
  onDragColumnSelected?: number;
  dragColumnSelected?: number;
  dragColumnItemSelected?: number;
  onDragX?: number;
  onDragY?: number;
  dragX: number;
  dragY: number;
  isDragStart: boolean;
  scrollLeft: number;
  scrollTop: number;
  parentWidth?: number;
  parentHeight?: number;
}

const initialState: KanbanState = {
  isTop: true,
  isDragStart: false,
  scrollLeft: 0,
  scrollTop: 0,
  dragX: 0,
  dragY: 0
}

export interface ReusableKanbanItem<T> {
  id?: string;
  title: string;
  desc?: string;
  tag?: string;
  disabled?: boolean;
  createdDate?: Date;
  leftPrefix?: ReactNode;
  updatedDate?: Date;
  additionalData?: T;
}

export interface ReusableKanbanData<T extends any = any> {
  id: string;
  title: string;
  items: Array<ReusableKanbanItem<T>>;
  isActive?: boolean;
  borderColor?: string;
  disabled?: boolean;
  dropTarget?: Array<number>;
}

export interface ItemProperties {
  parent: number;
  child: number;
}

export interface ItemChange {
  from: ItemProperties;
  to: ItemProperties;
}

export type OnChangeKanbanHandler<T extends any> = (datas: Array<ReusableKanbanData<T>>, itemChange: ItemChange) => void

export interface ReusableKanbanProps<T> {
  datas: Array<ReusableKanbanData<T>>;
  onChange: OnChangeKanbanHandler<T>;
  loading?: boolean;
  onClick?: (item: ReusableKanbanItem<T>, column: ReusableKanbanData<T>) => void;
}

const ReusableKanban = <T extends any>(props: ReusableKanbanProps<T>) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const [state, dispatch] = useReducer((s: KanbanState, ns: Partial<KanbanState>) => ({...s, ...ns}), initialState)

  useEffect(()=>{
    if(parentRef.current) {
      dispatch({
        parentWidth: parentRef.current?.clientWidth,
        parentHeight: parentRef.current?.clientHeight,
      })
    }
  },[parentRef.current?.clientWidth, parentRef.current?.clientHeight])

  const handleScroll: UIEventHandler<HTMLDivElement> = (event) => {
    dispatch({
      isTop: event.currentTarget.scrollTop === 0,
      scrollLeft: event.currentTarget.scrollLeft,
      scrollTop: event.currentTarget.scrollTop,
    })
    // event.currentTarget.scrollLeft += 1
  };
  // console.log(state.isDragStart, state.dragColumnSelected, state.dragColumnItemSelected)
  return (
    <div 
      ref={parentRef}
      className="kanban_board"
      onScroll={handleScroll}
      style={{
        overflow: props.loading ? "hidden" : undefined
      }}
    >
      <div 
        className="kanban_board__content" 
        style={{
          height: Math.max(...props.datas.map(d => d.items.length)) * 140
        }}
      >
        {props.datas.map((data,index) =>
          <KanbanColumn 
            state={state}
            dispatch={dispatch}
            index={index}
            parentRef={parentRef.current}
            {...data}
            {...props}
          />
        )}
      </div>
      {props.loading &&
      <div className="kanban_board_loading">
        <ReactLoading type="spin" color="blue" height={100} width={100} />
      </div>
      }
    </div>
  )
}

interface ReusableKanbanColumnProps<T> extends ReusableKanbanData<T>, ReusableKanbanProps<T> {
  state: KanbanState;
  dispatch: (value: Partial<KanbanState>) => void;
  index: number;
  parentRef?: HTMLDivElement | null;
}

const KanbanColumn = <T extends any>(props: ReusableKanbanColumnProps<T>) => {
  const { state, dispatch, index, title, items } = props
  const [isActive, setIsActive] = useState(props.isActive ?? true)

  useEffect(()=>{
    setIsActive(props.isActive ?? true)
  },[props.isActive])

  useEffect(()=>{
    // TODO: https://stackoverflow.com/questions/68397821/react-js-countdown-timer-using-moment-js
  },[])

  const dropHandler = (evt: DragEvent, parentColumn: number) => {
    const defaultHeight = 130
    const height = evt.clientY + state.scrollTop - defaultHeight

    const copyDatas: Array<ReusableKanbanData<T>> = JSON.parse(JSON.stringify(props.datas))
    const newIndex = Math.floor(height/defaultHeight) > copyDatas[parentColumn].items.length - 1 ? copyDatas[parentColumn].items.length : Math.floor(height/defaultHeight)

    const callback = () => {
      
      if(typeof state.dragColumnSelected === "number" && typeof state.dragColumnItemSelected === "number") {
        copyDatas[parentColumn].items.splice(newIndex, 0, copyDatas[state.dragColumnSelected].items[state.dragColumnItemSelected])
        copyDatas[state.dragColumnSelected].items.splice(state.dragColumnItemSelected, 1)
    
        const from: ItemProperties = {
          parent: state.dragColumnSelected,
          child: state.dragColumnItemSelected
        }
        const to: ItemProperties = {
          parent: parentColumn,
          child: newIndex
        }
    
        props.onChange(copyDatas, { from, to })
      }

      dispatch({isDragStart: false})
    }

    if(state.dragColumnSelected && props.datas[state.dragColumnSelected]?.dropTarget && (props.datas[state.dragColumnSelected].dropTarget ?? []).length > 0) {
      const finder = (props.datas[state.dragColumnSelected].dropTarget ?? []).find(d => d === index)

      if(finder !== undefined) callback()
      else Swal.fire("Not Allowed", "You not allowed to do that!", "warning")
    }
    else callback()
  }

  const getStyleItem = (parentIndex: number, childIndex: number): CSSProperties => {
    const defaultMt = (childIndex * 120) + (childIndex * 10)
    const nextMt = ((childIndex+1) * 120) + ((childIndex+1) * 10)
    
    if(
      typeof state.dragColumnSelected === "number" &&
      typeof state.dragColumnItemSelected === "number" &&
      [state.dragColumnSelected, state.dragColumnItemSelected].toString() !== [parentIndex, childIndex].toString() &&
      state.isDragStart && 
      (state.onDragY ?? 0) - 120 < nextMt && 
      state.onDragColumnSelected === parentIndex
    ) {
      return {
        marginTop: defaultMt + 120
      }
    }
    else {
      if([state.dragColumnSelected, state.dragColumnItemSelected].toString() === [parentIndex, childIndex].toString()) return {
        marginTop: defaultMt,
        opacity: 0.1,
        border: '5px solid blue'
        // transform: `translate(${state.dragX}px, ${state.dragY}px)`,
        // background: 'blue'
      }
      else return {
        marginTop: defaultMt
      }
    }
  }

  const getUpdatedDateColor = (item: ReusableKanbanItem<T>): string => {
    if(item.updatedDate) {
      const diff = moment().diff(new Date(item.updatedDate), 'days')
      if(diff >= 3) return "kanban_board__content__item__expiry_date__danger"
      else if(diff >= 2) return "kanban_board__content__item__expiry_date__warning"
      else if(diff >= 0) return "kanban_board__content__item__expiry_date__success"
      else return "kanban_board__content__item__expiry_date__disable"
    }
    else return "kanban_board__content__item__expiry_date__disable"
  }

  return (
    <div 
      className={isActive ? "kanban_board__content__item_active" : "kanban_board__content__item_inactive"}
      onDrop={(evt) => dropHandler(evt, index)}
      onDragOver={(evt)=>{
        evt.preventDefault()
        dispatch({
          onDragX: evt.clientX + state.scrollLeft, 
          onDragY: evt.clientY + state.scrollTop,
          dragX: evt.clientX, 
          dragY: evt.clientY,
        })
      }}
      onDragEnter={()=>dispatch({isDragStart: true, onDragColumnSelected: index})}
    >
      <div 
        style={{
          // transform: `translateX(${-state.scrollLeft}px)`,
        }}
        className={`${isActive ? 'kanban_board__content__header_active' : 'kanban_board__content__header_inactive'} ${state.isTop ? '' : 'kanban_board__content__header_shadow'}`}
        onClick={()=>setIsActive(!isActive)}
      >
        <div className={!isActive ? 'kanban_board__content__header_inactive__content' : undefined}>
          {title} ({items.length})
        </div>
      </div>
      {isActive && items.map((item,indexItem) =>
        <KanbanItem 
          item={item}
          dispatch={dispatch}
          index={index}
          indexItem={indexItem}
          getStyleItem={getStyleItem}
          getUpdatedDateColor={getUpdatedDateColor}
          column={props}
          root={props.parentRef}
          disabled={props.disabled}
        />
      )}
    </div>
  )
}

interface KanbanItemProps<T> {
  column: ReusableKanbanColumnProps<T>;
  disabled?: boolean;
  item: ReusableKanbanItem<T>;
  dispatch: (value: Partial<KanbanState>) => void
  index: number;
  indexItem: number;
  getStyleItem: (parentIndex: number, childIndex: number) => CSSProperties;
  getUpdatedDateColor: (item: ReusableKanbanItem<T>) => string;
  root?: HTMLDivElement | null;
}

const KanbanItem = <T extends any>(props: KanbanItemProps<T>) => {
  const { item, dispatch, index, indexItem, getStyleItem, getUpdatedDateColor, column, root } = props
  const {node, entry} = useIntersect<HTMLDivElement>({root})
  
  return (
    <div 
      ref={node}
      className="kanban_board__content__item kanban_board__content__item_hoverable"
      draggable={!props.disabled ?? !item.disabled}
      // onDrag={(evt) => dispatch({onDragY: evt.clientY})}
      onDragStart={(evt)=>{
        if(!item.disabled) {
          evt.dataTransfer.setData("kanban-item", "evt.target")
          dispatch({dragColumnSelected: index, dragColumnItemSelected: indexItem})
        }
      }}
      // onDragOver={(evt) => dispatch({
      //   dragX: evt.clientX,
      //   dragY: evt.clientY,
      // })}
      onDragEnd={()=>dispatch({
        isDragStart: false,
        dragColumnSelected: undefined,
        dragColumnItemSelected: undefined
      })}
      onClick={()=>{
        const kanbanData: ReusableKanbanData<T> = {
          id: column.id,
          title: column.title,
          items: column.items,
          isActive: column.isActive,
          borderColor: column.borderColor,
          disabled: column.disabled,
        }
        column.onClick && column.onClick(item, kanbanData)
      }}
      style={{
        ...getStyleItem(index, indexItem),
        cursor: column.disabled ?? item.disabled ? 'pointer' : undefined,
        borderColor: column.borderColor,
      }}
    >
      {entry?.isIntersecting &&
        <Fragment>
          <div className="custom-flex-row-space-between-center">
            <div className="kanban_board__content__item__date">{moment(item.createdDate).format("dddd, DD MMMM yyyy HH:mm")}</div>
            {item.tag &&
              <div 
                className="kanban_board__content__item__tag"
                style={{
                  borderColor: column.borderColor
                }}
              >
                {item.tag}
              </div>
            }
          </div>
          <div className="kanban_board__content__item__title mt-2">{item.title ?? "-"}</div>
          {item.desc && <div className="kanban_board__content__item__desc" dangerouslySetInnerHTML={{__html: sanitizedHtml(item.desc)}} />}
          {item.leftPrefix && <div className="kanban_board__content__item__left_prefix" style={{borderColor: column.borderColor}}>{item.leftPrefix}</div>}
          {item.updatedDate && 
            <div className={`kanban_board__content__item__expiry_date ${getUpdatedDateColor(item)}`}>
              {moment(item.updatedDate).fromNow()}
            </div>
          }
        </Fragment>
      }
    </div>
  )
}

export default ReusableKanban