import { useEffect, useState } from 'react';
import moment from 'moment';

export const getDateNow = () => {
  const dateData = moment()
    .toDate()
    .toISOString()
    .split('T');
  const timeData = moment()
    .toDate()
    .toLocaleTimeString('id-ID')
    .split('.');
  const dateNow = dateData[0] + 'T' + timeData[0] + ':' + timeData[1];

  return dateNow;
};

// Hook
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const uniquePriceCode = (totalPrice, uniquePaymentCode) => {
  const totalPriceFixed = totalPrice.toFixed(4);
  const uniquePaymentCodeString = `0.0000${uniquePaymentCode}`;

  const combinePriceWithCode =
    Number(totalPriceFixed) + Number(uniquePaymentCodeString);

  const finalPriceCode = Number(combinePriceWithCode.toFixed(8));

  return finalPriceCode;
};
