/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
  render() {
    // const { user, showHi, showAvatar, showBadge } = this.props;
    const { showHi, showBadge } = this.props;

    let mail = localStorage.getItem("user_mail");

    let firstChar = mail?.charAt(0) || "";
    const capital = firstChar.toUpperCase();

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" align="end">
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Hi, {mail}
              </span>
            )}

            {/* {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {user.fullname}
              </span>
            )} */}

            {/* {showAvatar && <img alt="Pic" src={toAbsoluteUrl("/media/users/300_25.jpg")} />} */}

            {showBadge && (
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                {/* TODO: Should get from currentUser */}
                John Doe
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
            }}
          >
            <div className="kt-user-card__avatar">
              {/* <img alt="Pic" className="kt-hidden" src={user.pic} /> */}
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                {capital}
              </span>
            </div>
            {/* <div className="kt-user-card__name">{user.fullname}</div> */}
            <div className="kt-user-card__badge">
              {/* <span className="btn btn-success btn-sm btn-bold btn-font-md">
                23 messages
              </span> */}
              <Link
                to="/logout"
                className="btn btn-danger btn-sm btn-bold btn-font-md"
              >
                Sign Out
              </Link>
            </div>
          </div>
          {/* <div className="kt-notification">
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link>
            </div>
          </div> */}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserProfile);
