import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from "react"

export interface TransitionSectionProps {
  isPrimary: boolean;
  primary: ReactNode;
  secondary: ReactNode;
  staticHeight?: boolean;
}

const TransitionSection: FC<TransitionSectionProps> = (props) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const { isPrimary, primary, secondary, staticHeight } = props
  const [heightElement, setHeightElement] = useState<number>(0)
  const [isAnimated, setIsAnimated] = useState<boolean>(false)
  const [currentComponent, setCurrentComponent] = useState<ReactNode>(isPrimary ? primary : secondary)
  
  const customStyle: CSSProperties = {
    transition: 'all 0.3s',
    transform: isAnimated ? 'translateY(-150%)' : undefined,
    opacity: isAnimated ? 0 : 1,
    visibility: isAnimated ? 'hidden' : 'visible'
  }

  useEffect(()=>{
    setCurrentComponent(isPrimary ? primary : secondary)
  }, [primary, secondary])

  useEffect(()=>{
    setHeightElement(componentRef.current?.clientHeight ?? 0)
  }, [componentRef.current])

  useEffect(()=> {
    setIsAnimated(true)
    setTimeout(()=>setIsAnimated(false), 200)
  }, [isPrimary])

  useEffect(()=>{
    if(isAnimated) setCurrentComponent(isPrimary ? primary : secondary)
  }, [isAnimated])

  return (
    <div 
      style={{
        height: staticHeight ? heightElement : undefined,
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <div style={customStyle} ref={componentRef}>
        {currentComponent}
      </div>
    </div>
  )
}

export default TransitionSection