import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowBack } from '@material-ui/icons';
require('bootstrap/scss/bootstrap.scss');

const ThubEventTagsCreate = (props) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
  };

  const findFormErrors = () => {
    const { tag_name, status } = form;
    const newErrors = {};
    // name errors
    if (!tag_name || tag_name === '')
      newErrors.tag_name = 'Tag name cant be blank!';
    if (!status || status === '')
      newErrors.status = 'Tag status must be chosen!';

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setShowConfirmation(!showConfirmation);
    }
  };

  const createTag = async () => {
    setLoading(true);
    let display_for = '';
    if (isAdmin && isUser) {
      display_for = 'admin,user';
    } else if (isAdmin) {
      display_for = 'admin';
    } else if (isUser) {
      display_for = 'user';
    }

    const formData = { ...form, display_for };
    await thubActions
      .fetchThubCreateEventTag(token, formData)
      .then((res) => {
        toast.success('Successfully created new tag');
        props.history.push('/thub/event/tags/list');
        setShowConfirmation(!showConfirmation);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setShowConfirmation(!showConfirmation);
        console.log(e);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
            setLoading(false);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form className='m-login__form m-form' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    {' '}
                    <Form.Group>
                      <Form.Label>Tag Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter tag name. Eg: crypto'
                        value={form.tag_name}
                        maxLength={20}
                        onChange={(e) => setField('tag_name', e.target.value)}
                        isInvalid={!!errors.tag_name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.tag_name}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Tag Status</Form.Label>
                      <Form.Control
                        as='select'
                        value={form.status}
                        onChange={(e) => setField('status', e.target.value)}
                        isInvalid={!!errors.status}
                      >
                        <option value='' selected disabled>
                          Select Status
                        </option>
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.status}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Display For</Form.Label>
                      <br />
                      <Form.Check
                        inline
                        type='switch'
                        id='admin'
                        label='Admin'
                        checked={isAdmin}
                        onChange={() => setIsAdmin(!isAdmin)}
                      />
                      <Form.Check
                        inline
                        type='switch'
                        id='user'
                        label='User'
                        checked={isUser}
                        onChange={() => setIsUser(!isUser)}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div
                  className='kt-login__actions'
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <button
                    type='button'
                    disabled={loading}
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/event/tags/list');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                  >
                    Add Tag
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(!showConfirmation)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Tag name cannot be edited and deleted. Are you sure to submit this
            tag?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <Button
            variant='danger'
            onClick={() => setShowConfirmation(!showConfirmation)}
            className='mr-3'
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            disabled={loading}
            onClick={() => createTag()}
            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                  loading,
              }
            )}`}
          >
            Add Tag
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThubEventTagsCreate;
