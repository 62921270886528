import axiosInstance from "../../axios_config";

export const getConfigs = () => {
  return axiosInstance().get("admin/configuration")
}
export const createConfig = (payload) => {
  return axiosInstance().post("admin/configuration", payload)
}
export const getSelectedConfig = (id) => {
  return axiosInstance().get("admin/configuration/"+id)
}
export const updateSelectedConfig = (id, payload) => {
  return axiosInstance().patch("admin/configuration/"+id, payload)
}
export const deleteSelectedConfig = (id) => {
  return axiosInstance().delete("admin/configuration/"+id)
}