import React from "react";
import actions from "../../crud/microsite";
import { toast } from 'react-toastify';
import { Form } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css'; 
import { createBrowserHistory } from 'history';
// import FileUpload from '../../components/upload/fileUpload';
import _ from 'lodash';
import clsx from "clsx";

const history =  createBrowserHistory();

class StaticContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      getData: {},
      loading: false,
      totalData: 0,
      type: 'create',
      titleId: '',
      // titleEn: '',
      contentId: '',
      // contentEn: '',
      image: {},
      preview: '',
      listImage: [],
      params: '?'
    }
  }

  async componentDidMount(){
    await this.getData();
  }

  async getData(){
    const token = this.getToken();
    try {
      const data = await actions.fetchPost(token, 'static', '?order_sort=asc&limit=1');
      let result = data.data.data;
      if(result.total_data > 0){
        let getdata = result.data[0];
        // set disini default datanya
        this.onChangeState('edit', 'type');
        this.onChangeState(getdata, 'getData');
        this.onChangeState(getdata.title_id, 'titleId');
        this.onChangeState(getdata.desc_id, 'contentId');
        if(getdata.attachments[0]){
          this.onChangeState(getdata.attachments[0].url, 'preview');
        }
      }
    } catch (e){
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }
  }

  async isSubmit(event){
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('title', this.state.titleId); 
      bodyFormData.append('desc', this.state.contentId); 
      bodyFormData.append('status', 1); 
      // if(this.state.image !== null){
      //   bodyFormData.append('files', this.state.image);
      // }
      event.preventDefault();
      const self = this;
      self.onChangeState(true, 'loading');
      const token = self.getToken();
      let body = {
        title: self.state.titleId,
        desc: self.state.contentId,
        // files: self.state.image,
      };

      if(self.state.type === 'create'){
        body.status = "1";
        await actions.postData(token, 'static', bodyFormData);
      }else{
        await actions.putPostData(token, 'static', bodyFormData, self.state.getData.id);
      }
      
      setTimeout(() => {
        if(self.state.type === 'create'){
          toast.success("Success Create Static Content");
        }else{
          toast.success("Success Update Static Content");
        }
        self.onChangeState(false, 'loading');
        this.getData();
      }, 1000);
    } catch (error) {
       this.onChangeState(false, 'loading');
       this.getData();
    }
  }

  onChangeFileImage = (e) => {
    let file = e.target.files[0];
    // console.log(file);
    this.onChangeState('', 'preview');
    if(file){
      this.setState({
        image: file
      })
    }
  }

  cloneState() {
      return _.clone(this.state);
  }

  onChangeState(val, field) {
      let state = this.cloneState();
      state[field] = val;
      // console.log(state);
      this.setState(state);
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  makeBase64 = (file) => {
    // let self = this;
    // console.log(file);
    let blob = file.file;
    this.onChangeState(file, 'image');
    // let type = file.type;
    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
        // console.log(reader.result);
        // self.setState({
        //   file_extension: type,
        //   file_content: reader.result.split('base64,')[1],
        // })
    }
  }

  render(){
    return (
      <div className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <hr />
                {this.state.loading ? (
                  <div className="text-center">
                      Loading ...
                  </div>
                ):(
                  <>
                    <form className="m-login__form m-form" onSubmit={(e) => this.isSubmit(e)}>
                      <Form.Group>
                        <Form.Label>Title (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Title ID" value={this.state.titleId} onChange={(e) => this.onChangeState(e.target.value, 'titleId')} required/>
                      </Form.Group>
                      {/* <Form.Group>
                        <Form.Label>Title (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Title EN" value={this.state.titleEn} onChange={(e) => this.onChangeState(e, 'titleEn')} required/>
                      </Form.Group> */}
                      <Form.Group>
                        <Form.Label>Content Description (ID)</Form.Label>
                        <Form.Control as="textarea" rows={5} placeholder="Content Description ID" value={this.state.contentId} onChange={(e) => this.onChangeState(e.target.value, 'contentId')} required/>
                      </Form.Group>
                      {/* <Form.Group>
                        <Form.Label>Content Description (EN)</Form.Label>
                        <Form.Control as="textarea" rows={5} placeholder="Content Description EN" value={this.state.contentEn} onChange={(e) => this.onChangeState(e, 'contentEn')} required/>
                      </Form.Group> */}
                      {/* <Form.Group>
                        <Form.Label>Image</Form.Label>
                        <Form.Control className="form-control" type="file" onChange={this.onChangeFileImage} accept="image/*"/> */}
                        {/* <FileUpload makeBase64={(val) => this.makeBase64(val)} filePreview={this.state.listImage.length > 0 ? this.state.listImage : [{preview: 'https://res.cloudinary.com/indysigner/image/fetch/f_auto,q_auto/w_2000/https://cloud.netlifyusercontent.com/assets/344dbf88-fdf9-42bb-adb4-46f01eedd629/15a70140-841d-4290-a06d-909d5fc35aa0/04-skeleton-screens-react.png', id: 1}]} /> */}
                        {/* {this.state.preview.length > 0 && (
                          <img src={`${this.state.urlAsset}${this.state.preview}`} className="img-responsive mt-4" height="100" alt="" />
                        )}
                      </Form.Group> */}
                      <div className="d-flex justify-content-end align-items-center">
                        <button
                            type="submit"
                            disabled={this.state.loading}
                            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                              {
                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                              }
                            )}`}
                          >
                            {this.state.type === "create" ? "Create Static Content" : "Update Static Content"}
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default StaticContent;