import { useFormik } from "formik";
import moment from "moment";
import { CSSProperties, DetailedHTMLProps, FC, forwardRef, Fragment, HTMLAttributes, PropsWithChildren, ReactNode, useEffect, useReducer, useState } from "react";
import { Badge, Button, Modal, OverlayTrigger, Popover, Spinner, Tab, Tabs } from "react-bootstrap";
import FormWithFormik, { FormWithFormikProps, FormWithFormikTypes } from '../../components/reusableFormWithFormik'
import ReusableTable, { ReusableTableColumn, ReusableTableProps, SortProps } from "../../components/reusableTable";
import { ExternalKYC, getUserKYCs, ICountry, IUser, KYCGovermentOrLiveness, KYCLevel, KYCScreeningRisk, KYCStatus, screening_risk, status, getCountries, IProvince, getProvinces, getDetailUserKYC, getExportData, IDetailKYC, IOccupation, getOccupations, IKYCPagination, UserPremium, FileType, getFile, updateDetailKYC, getPremiumUserKYC, confirmKYC, getLiveness, LivenessDetail, getHistoryNonPremiumUserKYC, getHistoryPremiumUserKYC, ReportTypeValue, DateSource, ExportDataPayload, resetDetailKYC } from "../../crud/kyc-user";
import { capitalizeEveryWord, capitalizeFirstLetter, globalDateTime, isStringJson, useDebounce, useDebounceDidMount, useDidMountEffect } from "../../helper";
import CodeEditor from "react-simple-code-editor"
import { highlight, languages } from "prismjs";
import * as yup from 'yup'
import TransitionSection from "../../components/TransitionSection";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ReusableCopyButton from "../../components/reusableCopyButton";
import Swal from "sweetalert2";
import PreviewImage from "../../components/previewImage";
import { initialState, useUserContext } from "../../../context/userContext";
import { FilePreviewUrl } from "../../crud/global-types";

type ModalDetailType = "GOVT" | "LIVENESS" | "KYC_LV_1" | "KYC_LV_2" | "RESET" | "DETAIL" | "HISTORY_1" | "HISTORY_2"

interface GovermentStatus {
  trx_id: string;
  data?: {
    selfie_photo: string;
  }
}

export type RiskCalculationFilter = "low" | "medium" | "high"

export const getRiskCalculationLabel = (value: string | number) => {
  const val = Number(value)
  if(isNaN(val)) return "-"
  else {
    if(val >= 1 && val <= 1.5) return "low"
    else if(val > 1.5 && val <= 2.5) return "medium"
    else if(val > 2.5 && val <= 3) return "high"
    else return "-"
  }
}

const getGovermentObject = (data: IDetailKYC) => {
  if(data.goverment_result && data.goverment_result.result) {
    const govermentResult: GovermentStatus = JSON.parse(data.goverment_result.result)
    return govermentResult
  }
  else return undefined
}

export interface IForm {
  keyword: string;
  kyc_level: "" | KYCLevel;
  kyc_status: "" | KYCStatus;
  risk_calculation: "" | RiskCalculationFilter;
  liveness: "" | KYCGovermentOrLiveness;
  goverment: "" | KYCGovermentOrLiveness;
  screening_risk: "" | KYCScreeningRisk;
  country: "" | string;
}
const reportTypes: Array<{label: string; value: ReportTypeValue}> = [
  {
    label: 'Simple Report',
    value: 'simple',
  },
  {
    label: 'Peruri Report',
    value: 'peruri_report',
  },
]
const dateTypes: Array<{label: string; value: DateSource}> = [
  {
    label: 'Created Date',
    value: 'create_date',
  },
  {
    label: 'Action Date',
    value: 'action_date',
  },
];
const screeningRisk: Array<{label: string; value: string;}> = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
]
const statusClass = [
  {
    id: -1,
    class: 'primary',
    stat: 'Reject'
  },
  {
    id: 0,
    class: 'warning',
    stat: 'Pending'
  },
  {
    id: 1,
    class: 'success',
    stat: 'Approve'
  }
]

const filterStats: Array<{label: string; value: string}> = [
  { label: 'ALL', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Rejected', value: 'rejected' },
];

export const screeningInfos = [
  {
    class: 'warning',
    stat: 'pending',
    name: 'pending',
  },
  {
    class: 'success',
    stat: 'approve',
    name: 'low',
  },
  {
    class: 'success',
    stat: 'approve',
    name: 'medium',
  },
  {
    class: 'danger',
    stat: 'reject',
    name: 'high',
  }
]

type ModalContent = {
  type: "GOVT"|"LIVENESS"|"RESET";
  ref_id: string | number;
  detail: IDetailKYC;
} | {
  type: "DETAIL";
  detail: IDetailKYC;
  liveness?: LivenessDetail;
} | {
  type: "KYC_LV_1"|"KYC_LV_2";
  detail: IDetailKYC;
  user_id: string | number;
  ref_id: string | number;
} | {
  type: "HISTORY_1";
  id: string | number;
} | {
  type: "HISTORY_2";
  id: string | number;
}

export interface InitialState {
  dataSources: Array<IUser>;
  countries: Array<ICountry>;
  provinces: IProvince;
  occupations: IOccupation;
  loading: boolean;
  activePage: number;
  totalItemsCount: number;
  defaultSort?: SortProps<Omit<IUser, "external_kycs">>;
  modal?: ModalContent;
  exportModal: boolean;
  newVersion: boolean;
  showFilter: boolean;
  iForm: IForm;
}

type GetListUsers = (page: number, form: IForm, sort: SortProps<Omit<IUser, "external_kycs">> | undefined) => Promise<void>

export const newVersionStorageKey = 'new_version_user'

const limit = 15

const User: FC<RouteComponentProps> = (props) => {
  const history = useHistory()
  const {state, dispatch} = useUserContext()
  const formik = useFormik<IForm>({
    initialValues: state.iForm,
    onSubmit: () => {}
  })
  useEffect(()=>{
    dispatch({iForm: formik.values})
  },[formik.values])
  const getOptionUtils = async () => {
    try {
      const countries = await getCountries()
      const provinces = await getProvinces()
      const occupations = await getOccupations()
      dispatch({
        countries: countries.data.data ? countries.data.data.sort((a,b) => a.Name > b.Name ? 1 : -1) : [],
        provinces: provinces.data.data ?? {},
        occupations: occupations.data.data ?? {},
      })
    }
    catch(err) {
      console.log(err)
    }
  }
  const getListUsers: GetListUsers = async (activePage, values, sort) => {
    dispatch({loading: true})
    try {
      const response = await getUserKYCs({
        q: values.keyword ? values.keyword : undefined,
        limit,
        level: values.kyc_level !== "" ? values.kyc_level : undefined,
        page: activePage,
        status: values.kyc_status !== "" ? values.kyc_status : undefined,
        government_status: values.goverment !== "" ? values.goverment : undefined,
        liveness_check: values.liveness !== "" ? values.liveness : undefined,
        screening_risk: values.screening_risk !== "" ? values.screening_risk : undefined,
        risk_calculation: values.risk_calculation !== "" ? values.risk_calculation : undefined,
        country: values.country !== "" ? values.country : undefined,
        order_by: sort?.order_by,
        order_sort: sort?.order_sort
      })
      dispatch({
        dataSources: response.data.data?.data ?? [],
        totalItemsCount: response.data.data?.total_data,
        loading: false
      })
    }
    catch(err) {
      console.log(err)
      dispatch({loading: false})
    }
  }
  const firstInit = () => {
    getOptionUtils()
  }
  useEffect(firstInit, [])
  useDidMountEffect(()=>dispatch({activePage: 1}), [formik.values, state.defaultSort])
  useEffect(()=>{
    localStorage.setItem(newVersionStorageKey, state.newVersion ? "true" : "false")
  }, [state.newVersion])
  useDebounceDidMount<[number, IForm, SortProps<Omit<IUser, "external_kycs">> | undefined]>(
    getListUsers, 
    [state.activePage, formik.values, state.defaultSort], 
    200,
    undefined,
    state.dataSources.length > 0 ? false : true
  )
  const dateTime = (date: string | Date | null) => moment(date).utc().format('DD-MM-Y hh:m A');
  const filters: Array<Array<FormWithFormikProps<IForm>>> = [
    [
      {
        name: "kyc_level",
        type: "DROPDOWN",
        label: "KYC Level",
        options: [
          { label: "ALL", value: "" },
          { label: "Level 1", value: 1 },
          { label: "Level 2", value: 2 },
        ]
      },
      {
        name: "kyc_status",
        type: "DROPDOWN",
        label: "KYC Status",
        options: [
          { label: "ALL", value: "" },
          ...status.map(s => ({ label: capitalizeFirstLetter(s), value: s }))
        ]
      },
      {
        name: "risk_calculation",
        type: "SEARCHABLE_SELECT",
        label: "PPATK Calculation",
        options: [
          { label: "ALL", value: "" },
          { label: "LOW", value: "low" },
          { label: "MEDIUM", value: "medium" },
          { label: "HIGH", value: "high" },
        ]
      },
    ],
    [
      {
        name: "liveness",
        type: "DROPDOWN",
        label: "Liveness",
        options: [
          { label: "ALL", value: "" },
          { label: "Reject", value: -1 },
          { label: "Pending/Reject", value: 0 },
          { label: "Approved", value: 1 },
        ]
      },
      {
        name: "goverment",
        type: "DROPDOWN",
        label: "Govt. Database",
        options: [
          { label: "ALL", value: "" },
          { label: "Pending/Reject", value: -1 },
          { label: "-", value: 0 },
          { label: "Approved", value: 1 },
        ]
      },
      {
        name: "screening_risk",
        type: "DROPDOWN",
        label: "Screening Risk",
        options: [
          { label: "ALL", value: "" },
          ...screening_risk.map((r,index) => ({ label: capitalizeFirstLetter(r), value: index.toString() }))
        ]
      },
      {
        name: "country",
        type: "SEARCHABLE_SELECT",
        label: "Country",
        options: [
          { label: "ALL", value: "" },
          ...state.countries.map(c => ({ label: c.Name, value: c.Iso2 }))
        ]
      },
    ]
  ]
  const showModalDetail = async (type: ModalDetailType, id: string | number) => {
    try {
      if(
        type === "GOVT" || 
        type === "LIVENESS" || 
        type === "RESET"
      ) {
        const detailUser = await getDetailUserKYC(id)
        if(detailUser.data.data) dispatch({modal: {
          type,
          ref_id: id,
          detail: detailUser.data.data
        }})
      }
      else if(type === "KYC_LV_1" ||  type === "KYC_LV_2") {
        const detailUser = await getDetailUserKYC(id)
        if(detailUser.data.data) dispatch({modal: {
          type,
          ref_id: id,
          detail: detailUser.data.data,
          user_id: id
        }})
      }
      else if(type === "DETAIL") {
        const detailUser = await getDetailUserKYC(id)
        const liveness = await getLiveness(id)
        if(detailUser.data.data) dispatch({modal: {
          type,
          detail: detailUser.data.data,
          liveness: liveness.data.data
        }})
      }
      else if(type === "HISTORY_1" || type === "HISTORY_2") dispatch({ modal: { type, id } })
    }
    catch(err) {
      console.log(err)
    }
  }
  const columns = (): Array<ReusableTableColumn<IUser>> => {
    if(state.newVersion) return [
      {
        title: "Action",
        dataIndex: "ref_id",
        render: (_, data) => {
          const getUtilsByStatus = (type: "GOVT" | "LIVENESS" | "SCREENING") => {
            if(type === "GOVT") {
              const isPeruri = data.external_kycs.find(k => k.liveness_video !== null)
              if((data.government_status === -1 && data.kyc_status === 0 && !isPeruri) || (data.government_status === 0 && isPeruri)) return {
                className: "new-version-user-action-btn-pending",
                onClick: ()=>showModalDetail("GOVT", data.ref_id)
              }
              else if((data.government_status === -1 && data.kyc_status === 0 && isPeruri) || (data.government_status === -1 && data.kyc_status !== 0)) return {
                className: "new-version-user-action-btn-reject"
              }
              else if(data.government_status === 1) return {
                className: "new-version-user-action-btn-approve"
              }
              else return {
                className: "new-version-user-action-btn-none"
              }
            }
            else if(type === "LIVENESS") {
              const { liveness_check, external_kycs, kyc_status, ref_id } = data
              const isPeruri = external_kycs.find(k => k.liveness_video !== null)
              if((liveness_check === -1 && kyc_status === 0 && !isPeruri) || liveness_check === 0) return {
                className: "new-version-user-action-btn-pending",
                onClick: () => showModalDetail("LIVENESS", ref_id)
              }
              else if((liveness_check === -1 && kyc_status === 0 && isPeruri) || (liveness_check === -1 && kyc_status !== 0)) return {
                className: "new-version-user-action-btn-reject"
              }
              else if(liveness_check === 1) return {
                className: "new-version-user-action-btn-approve"
              }
              else return {
                className: "new-version-user-action-btn-none"
              }
            }
            else {
              const { screening_risk, kyc_status } = data
              if(screening_risk === 1 || screening_risk === 2 || (screening_risk === 3 && kyc_status === 1)) return {
                className: "new-version-user-action-btn-approve"
              }
              else if(screening_risk === 3 && kyc_status === -1) return {
                className: "new-version-user-action-btn-reject"
              }
              else if(screening_risk === 3 && kyc_status === 0) return {
                className: "new-version-user-action-btn-pending"
              }
              else return {
                className: "new-version-user-action-btn-none"
              }
            }
          }
          const getKYCUtilsByStatus = (type: "KYC_LV_1" | "KYC_LV_2") => {
            const role = localStorage.getItem('rule');
            const { kyc_level, kyc_status, id, ref_id, is_reset } = data
            if(type === "KYC_LV_1") {
              if(kyc_level === 1 && kyc_status === -1) return {
                className: "new-version-kyc-action-reject",
                onClick: () => showModalDetail("RESET", ref_id)
              }
              else if((role === 'superuser' || role === 'compliance' || role === 'kyc') && kyc_level === 1 && kyc_status === 0) return {
                className: "new-version-kyc-action-pending",
                onClick: () => showModalDetail("KYC_LV_1", ref_id)
              }
              else if(role === 'cs' && kyc_level === 1 && kyc_status === 0) return {
                className: "new-version-kyc-action-pending",
              }
              else if((kyc_level === 1 && kyc_status === 1) || kyc_level === 2) return {
                className: "new-version-kyc-action-approve",
              }
              else return {
                className: "new-version-kyc-action-none",
              }
            }
            else {
              if(kyc_level === 2 && kyc_status === -1) return {
                className: "new-version-kyc-action-reject",
                onClick: () => showModalDetail("RESET", ref_id)
              }
              else if((role === 'superuser' || role === 'compliance' || role === 'kyc') && kyc_level === 2 && kyc_status === 0) return {
                className: "new-version-kyc-action-pending",
                onClick: () => showModalDetail("KYC_LV_2", ref_id)
              }
              else if(role === 'cs' && kyc_level === 2 && kyc_status === 0) return {
                className: "new-version-kyc-action-pending",
              }
              else if(kyc_level === 2 && kyc_status === 1) return {
                className: "new-version-kyc-action-approve",
              }
              // else if(is_reset === 1) return {
              //   className: "new-version-kyc-action-none",
              // }
              else return {
                className: "new-version-kyc-action-none",
              }
            }
          }
          return (
            <RowWrapper position="LEFT" className="custom-flex-row-center gap-4">
              <div className="custom-flex-col-center">
                <button className={`w-100 p-2 new-version-kyc-action ${getKYCUtilsByStatus("KYC_LV_2").className}`} onClick={getKYCUtilsByStatus("KYC_LV_2").onClick}>
                  <div className="rotation-wrapper-outer">
                    <div className="rotation-wrapper-inner">
                      <p className="element-to-rotate">KYC 2</p>
                    </div>    
                  </div>
                </button>
                <button className={`w-100 p-2 new-version-kyc-action ${getKYCUtilsByStatus("KYC_LV_1").className}`} onClick={getKYCUtilsByStatus("KYC_LV_1").onClick}>
                  <div className="rotation-wrapper-outer">
                    <div className="rotation-wrapper-inner">
                      <p className="element-to-rotate">KYC 1</p>
                    </div>    
                  </div>
                </button>
              </div>
              <PopoverAction label="Govt. Database">
                <button className={`new-version-user-action-btn ${getUtilsByStatus("GOVT").className}`} onClick={getUtilsByStatus("GOVT").onClick}><span className="fa-regular fa-file-lines" /></button>
              </PopoverAction>
              <PopoverAction label="Liveness">
                <button className={`new-version-user-action-btn ${getUtilsByStatus("LIVENESS").className}`} onClick={getUtilsByStatus("LIVENESS").onClick}><span className="fa-solid fa-user-check" /></button>
              </PopoverAction>
              <PopoverAction label="Name Screening">
                <button className={`new-version-user-action-btn ${getUtilsByStatus("SCREENING").className}`} onClick={getUtilsByStatus("SCREENING").onClick}><span className="fa-solid fa-money-check-dollar" /></button>
              </PopoverAction>
              <PopoverAction label="Go to Detail KYC">
                <button className={`new-version-user-action-btn new-version-user-action-btn-none`} onClick={() => history.push(`/user-new/${data.ref_id}/detail`)}><span className="far fa-eye" /></button>
              </PopoverAction>
            </RowWrapper>
          )
        }
      },
      {
        title: "Email",
        dataIndex: "ref_id",
        render: (_, data) => {
          const name = data.external_kycs[data.external_kycs.length - 1].name
          return (
            <RowWrapper>
              <div className="mt-3">
                <div className="new-version-kyc-table-label">{name ?? "-"}</div>
                <div>{data.email} <ReusableCopyButton value={data.email} /></div>
              </div>
            </RowWrapper>
          )
        }
      },
      {
        title: "UID",
        dataIndex: "ref_id",
        render: (_, data) => {
          return (
            <RowWrapper>
              <div className="mt-3">
                <div className="new-version-kyc-table-label">UID <ReusableCopyButton value={data.ref_id} /></div>
                <div>{data.ref_id}</div>
              </div>
            </RowWrapper>
          )
        }
      },
      {
        title: "Country",
        dataIndex: "external_kycs",
        render: (dataIndex, data) => {
          const external_kycs = dataIndex as Array<ExternalKYC>
          if(external_kycs.length > 0) {
            const findCountry = state.countries.find(c => c.Iso2 === external_kycs[external_kycs.length - 1].country)
            return (
              <RowWrapper>
                <div className="mt-3">
                  <div className="new-version-kyc-table-label">Country</div>
                  <div>
                    {findCountry &&
                      <img
                        src={`https://flagcdn.com/16x12/${findCountry.Iso2.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/32x24/${findCountry.Iso2.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${findCountry.Iso2.toLowerCase()}.png 3x`}
                        width="16"
                        height="12"
                        alt={findCountry.Iso2}
                      />
                    } {findCountry?.Name}
                  </div>
                </div>
              </RowWrapper>
            )
          }
          else return <RowWrapper>-</RowWrapper>
        }
      },
      {
        title: "Last Update",
        dataIndex: "updated_at",
        render: (dataIndex, data) => (
          <RowWrapper>
            <div className="mt-3">
              <div className="new-version-kyc-table-label">Last Update</div>
              <div>{dateTime(dataIndex as string)}</div>
            </div>
          </RowWrapper>
        ),
      },
      {
        title: "Risk Level",
        dataIndex: "screening_risk",
        render: (_, data) => {
          const { screening_risk } = data
          const getClassBar = () => {
            if(Number(screening_risk) === 1) return {
              bar_0: "new-version-row-signal-low-fill",
              bar_1: "new-version-row-signal-low",
              bar_2: "new-version-row-signal-low",
            }
            if(Number(screening_risk) === 2) return {
              bar_0: "new-version-row-signal-medium-fill",
              bar_1: "new-version-row-signal-medium-fill",
              bar_2: "new-version-row-signal-medium",
            }
            if(Number(screening_risk) === 3) return {
              bar_0: "new-version-row-signal-high-fill",
              bar_1: "new-version-row-signal-high-fill",
              bar_2: "new-version-row-signal-high-fill",
            }
            else return {
              bar_0: "new-version-row-signal-none",
              bar_1: "new-version-row-signal-none",
              bar_2: "new-version-row-signal-none",
            }
          }
          return (
            <RowWrapper position="RIGHT">
              <div className="mt-3 new-version-row-signal">
                <div className="new-version-kyc-table-label">Screening Risk</div>
                <div className="custom-flex-row-center-end new-version-row-signal mt-1">
                  <div className={`new-version-row-signal-1 ${getClassBar().bar_0}`} />
                  <div className={`new-version-row-signal-2 ${getClassBar().bar_1}`} />
                  <div className={`new-version-row-signal-3 ${getClassBar().bar_2}`} />
                </div>
              </div>
            </RowWrapper>
          )
        }
      },
      {
        title: "PPATK Calculation",
        dataIndex: "risk_calculation",
        render: (_, data) => {
          const { risk_calculation } = data
          const getClassBar = () => {
            if(getRiskCalculationLabel(risk_calculation) === "low") return {
              bar_0: "new-version-row-signal-low-fill",
              bar_1: "new-version-row-signal-low",
              bar_2: "new-version-row-signal-low",
            }
            if(getRiskCalculationLabel(risk_calculation) === "medium") return {
              bar_0: "new-version-row-signal-medium-fill",
              bar_1: "new-version-row-signal-medium-fill",
              bar_2: "new-version-row-signal-medium",
            }
            if(getRiskCalculationLabel(risk_calculation) === "high") return {
              bar_0: "new-version-row-signal-high-fill",
              bar_1: "new-version-row-signal-high-fill",
              bar_2: "new-version-row-signal-high-fill",
            }
            else return {
              bar_0: "new-version-row-signal-none",
              bar_1: "new-version-row-signal-none",
              bar_2: "new-version-row-signal-none",
            }
          }
          return (
            <RowWrapper position="RIGHT">
              <div className="mt-3 new-version-row-signal">
                <div className="new-version-kyc-table-label">PPATK Calculation</div>
                <div className="custom-flex-row-center-end new-version-row-signal mt-1">
                  <div className={`new-version-row-signal-1 ${getClassBar().bar_0}`} />
                  <div className={`new-version-row-signal-2 ${getClassBar().bar_1}`} />
                  <div className={`new-version-row-signal-3 ${getClassBar().bar_2}`} />
                </div>
              </div>
            </RowWrapper>
          )
        }
      },
    ]
    else return [
      {
        title: "User ID",
        dataIndex: "ref_id"
      },
      {
        title: "Email",
        dataIndex: "email",
        render: (dataIndex, data) => {
          const isPeruri = data.external_kycs.find(k => k.liveness_video !== null)
          return (
            <Fragment>{dataIndex as string} {isPeruri && <sup className="peruri">PERURI</sup>}</Fragment>
          )
        },
        isSortMode: true
      },
      {
        title: "Govt. Database",
        dataIndex: "government_status",
        render: (_, data) => {
          const isPeruri = data.external_kycs.find(k => k.liveness_video !== null)
          if((data.government_status === -1 && data.kyc_status === 0 && !isPeruri) || (data.government_status === 0 && isPeruri)) return (
            <Badge style={{cursor: 'pointer'}} bg="warning" onClick={()=>showModalDetail("GOVT", data.ref_id)}>PENDING</Badge>
          )
          else if((data.government_status === -1 && data.kyc_status === 0 && isPeruri) || (data.government_status === -1 && data.kyc_status !== 0)) return (
            <Badge bg="danger">REJECT</Badge>
          )
          else if(data.government_status === 1) return (
            <Badge bg="success">APPROVED</Badge>
          )
          else return "-"
        }
      },
      {
        title: "Liveness",
        dataIndex: "liveness_check",
        render: (_, data) => {
          const { liveness_check, external_kycs, kyc_status, ref_id } = data
          const isPeruri = external_kycs.find(k => k.liveness_video !== null)
          if((liveness_check === -1 && kyc_status === 0 && !isPeruri) || liveness_check === 0) return (
            <Badge
              style={{ cursor: 'pointer' }}
              onClick={() => showModalDetail("LIVENESS", ref_id)}
              bg="warning"
            >
              PENDING
            </Badge>
          )
          else if((liveness_check === -1 && kyc_status === 0 && isPeruri) || (liveness_check === -1 && kyc_status !== 0)) return (
            <Badge bg="danger">
              REJECT
            </Badge>
          )
          else if(liveness_check === 1) return (
            <Badge bg="success">
              APPROVE
            </Badge>
          )
          return null
        }
      },
      {
        title: "Name Screening",
        dataIndex: "screening_risk",
        render: (_, data) => {
          const { screening_risk, kyc_status } = data
          if(screening_risk === 1 || screening_risk === 2 || (screening_risk === 3 && kyc_status === 1)) return (
            <Badge bg="success">APPROVE</Badge>
          )
          else if(screening_risk === 3 && kyc_status === -1) return (
            <Badge bg="danger">REJECT</Badge>
          )
          else if(screening_risk === 3 && kyc_status === 0) return (
            <Badge bg="warning">PENDING</Badge>
          )
          else return "-"
        }
      },
      {
        title: "KYC Lv. 1",
        dataIndex: "kyc_level",
        render: (_, data) => {
          const role = localStorage.getItem('rule');
          const { kyc_level, kyc_status, id, ref_id } = data
          if(kyc_level === 1 && kyc_status === -1) return (
            <Badge
              bg="danger"
              style={{ cursor: 'pointer' }}
              onClick={() =>showModalDetail("RESET", ref_id)}
            >
              REJECT
            </Badge>
          )
          else if((role === 'superuser' || role === 'compliance' || role === 'kyc') && kyc_level === 1 && kyc_status === 0) return (
            <Badge
              style={{ cursor: 'pointer' }}
              onClick={() =>showModalDetail("KYC_LV_1", ref_id)}
              bg="warning"
            >
              PENDING
            </Badge>
          )
          // else if(role === 'cs' && kyc_level === 1 && kyc_status === 0) return (
          //   <Badge bg="warning">
          //     PENDING
          //   </Badge>
          // )
          else if((kyc_level === 1 && kyc_status === 1) || kyc_level === 2) return (
            <Badge bg="success">
              APPROVE
            </Badge>
          )
          else return "-"
        }
      },
      {
        title: "KYC Lv. 2",
        dataIndex: "kyc_level",
        render: (_, data) => {
          const role = localStorage.getItem('rule');
          const { kyc_level, kyc_status, id, ref_id, is_reset } = data
          if(kyc_level === 2 && kyc_status === -1) return (
            <Badge
              bg="danger"
              style={{ cursor: 'pointer' }}
              onClick={() => showModalDetail("RESET", ref_id)}
            >
              REJECT
            </Badge>
          )
          else if((role === 'superuser' || role === 'compliance' || role === 'kyc') && kyc_level === 2 && kyc_status === 0) return (
            <Badge
              style={{ cursor: 'pointer' }}
              onClick={() => showModalDetail("KYC_LV_2", ref_id)}
              bg="warning"
            >
              PENDING
            </Badge>
          )
          // else if(role === 'cs' && kyc_level === 2 && kyc_status === 0) return (
          //   <Badge bg="warning">
          //     PENDING
          //   </Badge>
          // )
          else if(kyc_level === 2 && kyc_status === 1) return (
            <Badge bg="success">
              APPROVE
            </Badge>
          )
          else if(is_reset === 1) return (
            <Badge bg="info">RESET</Badge>
          )
          else return "-"
        }
      },
      {
        title: "Country",
        dataIndex: "external_kycs",
        render: (dataIndex) => {
          const external_kycs = dataIndex as Array<ExternalKYC>
          if(external_kycs.length > 0) return state.countries.find(c => c.Iso2 === external_kycs[external_kycs.length - 1].country)?.Name
          else return "-"
        }
      },
      {
        title: "Last Update",
        dataIndex: "updated_at",
        render: (dataIndex) => dateTime(dataIndex as string),
        isSortMode: true
      },
      {
        title: "Risk Level",
        dataIndex: "screening_risk",
        render: (_, data) => {
          const { screening_risk } = data
          if(screening_risk !== 0) return (
            <Badge bg={screeningInfos[screening_risk]?.class}>{screeningInfos[screening_risk]?.name.toUpperCase()}</Badge>
          )
          else return "-"
        }
      },
      {
        title: "PPATK Calculation",
        dataIndex: "risk_calculation",
        render: (_, data) => {
          const { risk_calculation } = data
          if(getRiskCalculationLabel(risk_calculation) !== "-") {
            const finder = screeningInfos.find(info => info.name === getRiskCalculationLabel(risk_calculation))
            if(finder) return (
              <Badge bg={finder.class}>{finder.name.toUpperCase()}</Badge>
            )
            else return "-"
          }
          else return "-"
        }
      },
      {
        title: "Action",
        dataIndex: "id",
        render: (_, data) => {
          const { ref_id } = data
          return (
            <Fragment>
              <span
                onClick={()=>showModalDetail("DETAIL", ref_id)}
                style={{
                  padding: '2px',
                  fontSize: '0.8rem',
                  width: '100%',
                  marginBottom: '5px',
                }}
                className="btn btn-outline-primary"
              >
                view
              </span>
              <br />
              <span
                onClick={()=>showModalDetail("HISTORY_1", ref_id)}
                style={{
                  padding: '2px',
                  fontSize: '0.8rem',
                  width: '100%',
                  marginBottom: '5px',
                }}
                className="btn btn-outline-primary"
              >
                History Level 1
              </span>
              <br />
              <span
                onClick={()=>showModalDetail("HISTORY_2", ref_id)}
                style={{
                  padding: '2px',
                  fontSize: '0.8rem',
                  width: '100%',
                }}
                className="btn btn-outline-primary"
              >
                History Level 2
              </span>
            </Fragment>
          )
        }
      },
    ]
  }
  const getTitleModal = () => {
    if(state.modal?.type === "DETAIL") return "Detail Modal"
    else if(state.modal?.type === "HISTORY_1") return "History Log 1"
    else if(state.modal?.type === "HISTORY_2") return "History Log 2"
    else if(state.modal?.type === "GOVT") return "Government Database"
    else if(state.modal?.type === "KYC_LV_1") return "KYC Lv 1"
    else if(state.modal?.type === "KYC_LV_2") return "KYC Lv 2"
    else if(state.modal?.type === "LIVENESS") return "Liveness Modal"
    else if(state.modal?.type === "RESET") return "Reset Modal"
    else return "-"
  }
  const getModalContent = () => {
    if(state.modal && state.modal.type === "GOVT") return (
      <ModalEditDetail 
        ref_id={state.modal.ref_id}
        detail={state.modal.detail} 
        onEdit={async () => {
          await getListUsers(state.activePage, formik.values, state.defaultSort)
          dispatch({modal: undefined})
        }}
      />
    )
    else if(state.modal && state.modal.type === "LIVENESS" && state.modal.detail.liveness_video) return (
      <FileContents 
        contents={[{ raw_file: state.modal.detail.liveness_video, name: "Liveness" }]}
      />
    )
    else if(state.modal && (state.modal.type === "KYC_LV_1" || state.modal.type === "KYC_LV_2")) {
      console.log(state.modal)
      return (
        <ModalKYCLevel 
          ref_id={state.modal.ref_id}
          detail={state.modal.detail} 
          user_id={state.modal.user_id} 
          kyc_level={state.modal.type} 
          onEdit={async () => {
            await getListUsers(state.activePage, formik.values, state.defaultSort)
            dispatch({modal: undefined})
          }}
        />
      )
    }
    else if(state.modal && state.modal.type === "DETAIL") return (
      <ModalDetail 
        {...state.modal} 
        countries={state.countries} 
        occupations={state.occupations} 
      />
    )
    else if(state.modal && (state.modal.type === "HISTORY_1" || state.modal.type === "HISTORY_2")) return (
      <HistoryModal 
        {...state.modal} 
        countries={state.countries} 
        occupations={state.occupations} 
      />
    )
    else if(state.modal && state.modal.type === "RESET") {
      const { user_id } = state.modal.detail
      const resetKYC = async (payload: 0 | 1) => {
        try {
          await resetDetailKYC(user_id, { reset_status: payload })
          await Swal.fire('Success', payload === 0 ? 'Successfully Reset' : 'Successfully set to Pending')
          await getListUsers(state.activePage, formik.values, state.defaultSort)
          dispatch({modal: undefined})
        }
        catch(err) {
          console.log(err)
        }
      }
      return (
        <Fragment>
          <div className="custom-flex-col-center">
            <div className="swal2-header">
              <ul
                className="swal2-progress-steps"
                style={{ display: 'none' }}
              ></ul>
              <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
                <div className="swal2-icon-content">!</div>
              </div>
              <img
                className="swal2-image"
                alt=""
                style={{ display: 'none' }}
              />
              <h3>Are you sure want to reset KYC user? </h3>
            </div>
          </div>
          <div className="swal2-content text-center">
            You won't be able to revert this!
          </div>
          <div className="custom-flex-row-center gap-2 mt-4">
            <Button className="w-100" variant="warning" onClick={()=>resetKYC(1)}>Set to Pending</Button>
            <Button className="w-100" variant="danger" onClick={()=>resetKYC(0)}>Reset KYC</Button>
          </div>
        </Fragment>
      )
    }
  }
  const customCSS: CSSProperties = {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  }
  return (
    <Fragment>
      <SectionWrapper 
        customCSS={state.newVersion ? customCSS : undefined}
        withoutPadding={state.newVersion}
      >
        <div className="custom-flex-row-space-between-center gap-2">
          <FormWithFormik 
            type="TEXT_INPUT"
            config={formik}
            name="keyword"
            placeholder="Search by UID, email, or NIK"
          />
          <Button 
            className="h-100" 
            variant="success" 
            onClick={()=>getListUsers(state.activePage, formik.values, state.defaultSort)}
          >
            <TransitionSection 
              isPrimary={state.newVersion}
              primary={<span className="fa-solid fa-magnifying-glass" />}
              secondary="Search"
              staticHeight
            />
          </Button>
          <Button className="h-100" style={{ minWidth: state.newVersion ? undefined : 150}} variant="info" onClick={()=>dispatch({showFilter: !state.showFilter})}>
            <TransitionSection 
              isPrimary={state.newVersion}
              primary={<span className="fa-solid fa-filter" />}
              secondary={state.showFilter ? "Hide Filter" : "Show Filter"}
              staticHeight
            />
          </Button>
          <Button className="h-100" style={{ minWidth: state.newVersion ? undefined : 150}} variant="warning" onClick={()=>dispatch({exportModal: true})}>
            <TransitionSection 
              isPrimary={state.newVersion}
              primary={<span className="fa-solid fa-file-csv" />}
              secondary="Export Data"
              staticHeight
            />
          </Button>
          <Button 
            className="h-100" 
            style={{minWidth: state.newVersion ? undefined : 200}} 
            onClick={()=>dispatch({newVersion: !state.newVersion})} 
          >
            <TransitionSection 
              isPrimary={state.newVersion}
              primary={<span className="fa-solid fa-right-left" />}
              secondary="Switch to New Version"
              staticHeight
            />
          </Button>
          <Modal
            show={state.exportModal}
            onHide={()=>dispatch({exportModal: false})}
          >
            <Modal.Header>
              <Modal.Title>Export By Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ExportForm 
                countries={state.countries} 
                occupations={state.occupations} 
                isExportModal={state.exportModal} 
                onDownload={()=>dispatch({exportModal: false})}
              />
            </Modal.Body>
          </Modal>
        </div>
      </SectionWrapper>
      {state.showFilter &&
        <SectionWrapper>
          {filters.map((filter, iFilter) =>
            <div className="custom-flex-row-space-between-center gap-2" key={iFilter}>
              {filter.map((f, i) =>
                <FormWithFormik 
                  key={i}
                  config={formik}
                  {...f}
                />
              )}
            </div>
          )}
        </SectionWrapper>
      }
      <Modal
        show={!!state.modal}
        onHide={()=>dispatch({modal: undefined})}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            {getTitleModal()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getModalContent()}
        </Modal.Body>
      </Modal>
      {state.newVersion &&
        <SectionWrapper>
          <div className="custom-flex-row-center gap-4">
            <div className="custom-flex-row-center gap-1">
              <div className="new-version-legend-color-box new-version-legend-color-box-pending" />
              <div>PENDING</div>
            </div>
            <div className="custom-flex-row-center gap-1">
              <div className="new-version-legend-color-box new-version-legend-color-box-approve" />
              <div>APPROVE</div>
            </div>
            <div className="custom-flex-row-center gap-1">
              <div className="new-version-legend-color-box new-version-legend-color-box-reject" />
              <div>REJECT</div>
            </div>
            <div className="custom-flex-row-center gap-1">
              <div className="new-version-legend-color-box new-version-legend-color-box-none" />
              <div>NONE</div>
            </div>
          </div>
        </SectionWrapper>
      }
      <ReusableTable 
        dataSources={state.dataSources}
        columns={columns()}
        onSort={{
          default: state.defaultSort,
          onChange: (sort) => {
            dispatch({defaultSort: sort as SortProps<Omit<IUser, "external_kycs">> | undefined})
          }
        }}
        loading={state.loading}
        pagination={{
          activePage: state.activePage,
          itemsCountPerPage: limit,
          totalItemsCount: state.totalItemsCount,
          onChange: (pageNumber) => {
            dispatch({activePage: pageNumber})
          }
        }}
        withoutHeader={state.newVersion}
        withoutDefaultStyles={state.newVersion}
      />
    </Fragment>
  )
}

export interface SectionWrapperProps {
  customCSS?: CSSProperties;
  withoutPadding?: boolean;
}
export const SectionWrapper: FC<PropsWithChildren<SectionWrapperProps>> = (props) => {
  return (
    <div style={{ fontSize: '11px' }} className="head">
      <div className="kt-portlet smooth-transition" style={props.customCSS}>
        <div className={`smooth-transition kt-portlet__body ${props.withoutPadding ? '' : 'pddmdForce'}`} style={{padding: props.withoutPadding ? 0 : undefined}}>
          <div className="kt-portlet__preview custom-flex-col gap-2">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}


interface ModalEditDetailProps {
  ref_id: string | number;
  detail: IDetailKYC;
  onEdit: (detail: IDetailKYC) => void;
}
interface IDetailForm {
  name: keyof IDetailKYC;
  label?: string;
  editable?: boolean;
  type?: FormWithFormikTypes;
  customRender?: (data: IDetailKYC) => ReactNode;
}
export interface FileContentParams {
  raw_file: string;
  file?: string;
  name?: ReactNode;
  loading?: boolean;
  isError?: boolean;
}
const ModalEditDetail: FC<ModalEditDetailProps> = (props) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const { name, date_of_birth, place_of_birth, id_number } = props.detail
  const formik = useFormik<Partial<Omit<IDetailKYC, "date_of_birth"> & { date_of_birth: Date; }>>({
    initialValues: { name, date_of_birth: new Date(date_of_birth), place_of_birth, id_number },
    onSubmit: async (values) => {
      try {
        const { name, date_of_birth, place_of_birth, id_number } = values
        await updateDetailKYC(props.ref_id, {
          name: name ?? "",
          date_of_birth: moment(date_of_birth).format('YYYY-MM-DD'),
          place_of_birth: place_of_birth ?? "",
          id_number: id_number ?? ""
        })
        props.onEdit({
          ...props.detail,
          name: name ?? "",
          date_of_birth: moment(date_of_birth).format('YYYY-MM-DD'),
          place_of_birth: place_of_birth ?? "",
          id_number: id_number ?? ""
        })
      }
      catch(err) {
        console.log(err)
      }
    }
  })
  const forms: Array<IDetailForm> = [
    { name: "name", editable: true, type: "TEXT_INPUT" },
    { name: "place_of_birth", editable: true, type: "TEXT_INPUT" },
    { name: "date_of_birth", editable: true, type: "DATE" },
    { name: "id_number", editable: true, type: "TEXT_INPUT" },
    { name: "id_type", customRender: (data) => {
      if(data.id_type === 0) return "Identity Card"
      else return "Passport"
    } },
    { name: "goverment_result", label: "Asliri Rating", customRender: (data) => getGovermentObject(data)?.data?.selfie_photo ?? 0 },
  ]
  return (
    <Tabs defaultActiveKey="home">
      <Tab eventKey="home" title="Home">
        <ReusableTable<IDetailForm> 
          withoutHeader
          dataSources={forms}
          columns={[
            {
              dataIndex: "name",
              title: "Label",
              render: (_, data) => <strong>{data.label ? data.label : capitalizeEveryWord(data.name.replace(/_/g, " "))}</strong>
            },
            {
              dataIndex: "name",
              title: "Detail Value",
              render: (_, data) => {
                if(data.editable && isEditMode) {
                  if(data.type === "TEXT_INPUT") return (
                    <FormWithFormik 
                      name={data.name}
                      type="TEXT_INPUT"
                      config={formik}
                    />
                  )
                  else if(data.type === "DATE") return (
                    <FormWithFormik 
                      name={data.name}
                      type="DATE"
                      config={formik}
                    />
                  )
                  else return null
                }
                else {
                  if(data.customRender) return data.customRender(props.detail)
                  else return props.detail[data.name]
                }
              }
            }
          ]}
        />
        
        {isEditMode ? 
          <div className="custom-flex-row gap-2">
            <Button className="w-100" variant="success" onClick={formik.submitForm}>Confirm</Button>
            <Button className="w-100" variant="danger" onClick={()=>setIsEditMode(false)}>Cancel</Button>
          </div>
        : <Button className="w-100" variant="primary" onClick={()=>setIsEditMode(true)}>Edit</Button>}

        <FileContents 
          contents={[
            { raw_file: props.detail.id_photo, name: "ID Card" },
            { raw_file: props.detail.selfie_photo, name: "Selfie" },
          ]}
        />
      </Tab>
      {props.detail.goverment_result?.result &&
        <Tab eventKey="asliri" title="Asliri">
          <CodeEditor
            value={JSON.stringify(JSON.parse(props.detail.goverment_result.result), null, 4)}
            onValueChange={()=>{}}
            highlight={code => highlight(code, languages.javascript, "javascript")}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 16,
            }}
            disabled={true}
          />
        </Tab>
      }
    </Tabs>
  )
}

interface ModalDetailProps {
  type: "DETAIL";
  detail: IDetailKYC;
  liveness?: LivenessDetail;
  countries: Array<ICountry>;
  occupations: IOccupation;
}
interface ModalDetailDataSource {
  label_column_1: string;
  value_column_1: ReactNode;
  label_column_2?: string;
  value_column_2?: ReactNode;
}
const detailDataSources = (props: ModalDetailProps): Array<ModalDetailDataSource> => {
  return [
    {
      label_column_1: "Name",
      value_column_1: props.detail.name,
      label_column_2: "Nationality",
      value_column_2: props.countries.find(c => c.Iso2 === props.detail.country)?.Name,
    },
    {
      label_column_1: "ID Number",
      value_column_1: props.detail.id_number,
      label_column_2: "ID Type",
      value_column_2: props.detail.id_type === 0 ? "Identity Card" : "Passport"
    },
    {
      label_column_1: "Email",
      value_column_1: props.detail.email,
      label_column_2: "Gender",
      value_column_2: props.detail.gender === 1 ? "Male" : "Female"
    },
    {
      label_column_1: "Place & Date Birth",
      value_column_1: `${props.detail.place_of_birth}, ${globalDateTime(props.detail.date_of_birth)}`,
    },
    {
      label_column_1: "Address",
      value_column_1: props.detail.address,
    },
    {
      label_column_1: "Province",
      value_column_1: props.detail.province,
      label_column_2: "Zip Code",
      value_column_2: props.detail.id_type === 0 ? "Identity Card" : "Passport"
    },
    {
      label_column_1: "Occupation",
      value_column_1: props.occupations[props.detail.occupation],
    },
    {
      label_column_1: "Phone",
      value_column_1: props.detail.phone_no,
      label_column_2: "Mobile Phone",
      value_column_2: props.detail.mobile_no
    },
    {
      label_column_1: "Screening Risk",
      value_column_1: props.detail.screening_risk,
      label_column_2: "Asliri Rate",
      value_column_2: getGovermentObject(props.detail)?.data?.selfie_photo
    },
    {
      label_column_1: "PPATK Calculation",
      value_column_1: props.detail?.risk_calculation ? capitalizeFirstLetter(getRiskCalculationLabel(props.detail.risk_calculation)) : '-',
      label_column_2: "Trx id",
      value_column_2: getGovermentObject(props.detail)?.trx_id,
    },
    {
      label_column_1: "Action By",
      value_column_1: props.detail.action_by,
      label_column_2: "Liveness At",
      value_column_2: props.detail.liveness_at ? globalDateTime(props.detail.liveness_at) : "-"
    },
    {
      label_column_1: "Rejected At",
      value_column_1: props.detail.rejected_at ? globalDateTime(props.detail.rejected_at) : "-",
      label_column_2: "Auto Approved",
      value_column_2: props.detail.auto_approve
    },
    {
      label_column_1: "Reject Reason",
      value_column_1: props.detail.reject_reason && isStringJson(props.detail.reject_reason) ? (
        <CodeEditor
          value={JSON.stringify(JSON.parse(props.detail.reject_reason), null, 4)}
          onValueChange={()=>{}}
          highlight={code => highlight(code, languages.javascript, "javascript")}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
          disabled={true}
        />
      ) : props.detail.reject_reason,
    },
    {
      label_column_1: "NPWP",
      value_column_1: props.detail.npwp,
    },
  ]
}
const getContents = (props: ModalDetailProps): Array<FileContentParams> => {
  const contents: Array<FileContentParams> = [
    { raw_file: props.detail.id_photo, name: "ID CARD" },
    { raw_file: props.detail.proof_of_address, name: "KITAS/KITAP" },
    { raw_file: props.detail.selfie_photo, name: "SELFIE" },
  ]
  if(props.detail.liveness_video) contents.splice(1, 0, { raw_file: props.detail.liveness_video, name: "LIVENESS" })
  if(props.detail.nationality !== "ID") return contents
  return contents.filter(c => c.name !== "KITAS/KITAP")
}
const ModalDetail: FC<ModalDetailProps> = (props) => {
  return (
    <Tabs defaultActiveKey="home">
      <Tab eventKey="home" title="Home">
        <ReusableTable 
          dataSources={detailDataSources(props)}
          columns={[
            { dataIndex: "label_column_1", title: "Label", render: (dataIndex) => <strong>{dataIndex}</strong> },
            { dataIndex: "value_column_1", title: "Value" },
            { dataIndex: "label_column_2", title: "Label", render: (dataIndex) => <strong>{dataIndex}</strong> },
            { dataIndex: "value_column_2", title: "Value" },
          ]}
          withoutHeader
        />
        <FileContents 
          contents={getContents(props)}
        />
      </Tab>
      {props.detail.goverment_result?.result &&
        <Tab eventKey="asliri" title="Asliri">
          <CodeEditor
            value={JSON.stringify(JSON.parse(props.detail.goverment_result.result), null, 4)}
            onValueChange={()=>{}}
            highlight={code => highlight(code, languages.javascript, "javascript")}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 16,
            }}
            disabled={true}
          />
        </Tab>
      }
    </Tabs>
  )
}

interface IKYCLevelForm {
  type: "" | "APPROVE" | "REJECT";
  reject_reason: string;
}
interface KYCDataSource {
  label: string;
  value: ReactNode;
}
const ModalKYCLevel: FC<ModalEditDetailProps & { user_id: string | number, kyc_level: "KYC_LV_1" | "KYC_LV_2" }> = (props) => {
  const formik = useFormik<IKYCLevelForm>({
    initialValues: {
      type: "",
      reject_reason: ""
    },
    validationSchema: yup.object().shape({
      reject_reason: yup.string().required('Please fill this form!')
    }),
    onSubmit: async (values) => {
      try {
        await confirmKYC(props.user_id, {
          status: values.type === "APPROVE" ? 1 : -1,
          reject_reason: values.reject_reason,
          level: props.kyc_level === "KYC_LV_1" ? 1 : 2,
        })
        props.onEdit(props.detail)
      }
      catch(err) {
        console.log(err)
      }
    }
  })
  const submitKYCForm = async (type: "APPROVE" | "REJECT") => {
    formik.setValues({ ...formik.values, type })
    await formik.submitForm()
  }
  const onApproveKYC = async () => {
    const swal = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure want to Approve this KYC?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No, Cancel!',
      confirmButtonText: 'Yes, Approve!'
    })
    if(swal.isConfirmed) {
      await confirmKYC(props.user_id, {
        status: 1,
        reject_reason: "",
        level: props.kyc_level === "KYC_LV_1" ? 1 : 2,
      })
      await Swal.fire('Success','KYC Approved!', 'success')
      props.onEdit(props.detail)
    }
  }
  return (
    <Fragment>
      <FormWithFormik 
        name="reject_reason"
        label="Reject Reason"
        type="TEXT_AREA"
        config={formik}
      />
      <div className="custom-flex-row-space-between-center gap-2 mt-4">
        <Button variant="primary" className="w-100" onClick={()=>onApproveKYC()}>Approve</Button>
        <Button variant="danger" className="w-100" onClick={()=>submitKYCForm("REJECT")}>Reject</Button>
      </div>
    </Fragment>
  )
}

export interface FileContentsProps {
  contents: Array<FileContentParams>;
  isWithoutWidget?: boolean;
  isListView?: boolean;
}

export const FileContents: FC<FileContentsProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [contents, setContents] = useState<Array<FileContentParams>>([])
  const [filePreviewUrl, setFilePreviewUrl] = useState<FilePreviewUrl | undefined>(undefined)
  const getFileType = (raw_file: string): FileType => {
    const whitelistImageFormats = ['jpg','jpeg','png','gif','tiff']
    const whitelistVideoFormats = ['mp4','mov','webm','wmv','avi','mkv']
    // const whitelistPdfFormats = ['pdf']
    const getLastWord = raw_file.split('.')[raw_file.split('.').length - 1].toLowerCase()
    if(whitelistImageFormats.includes(getLastWord)) return "image/jpg"
    else if(whitelistVideoFormats.includes(getLastWord)) return "video/mp4"
    else return "application/pdf"
  }
  const converterFile = async () => {
    setLoading(true)
    const result = await Promise.all(props.contents.map(async content => {
      if(content.raw_file) {
        try {
          const response = await getFile(content.raw_file, false, getFileType(content.raw_file))
          return {
            raw_file: content.raw_file,
            file: response,
            name: content.name,
          }
        }
        catch(err) {
          console.log(err)
          return {
            raw_file: content.raw_file,
            name: content.name,
            isError: true
          }
        }
      }
      else return {
        raw_file: content.raw_file,
        name: content.name,
        isError: true
      }
    }))
    setContents(result)
    setLoading(false)
  }
  useEffect(()=>{
    converterFile()
  }, [])
  const downloadImage = (imageB64: string, raw_file: string) => {
    const a = document.createElement('a')
    a.href = imageB64;
    a.download = `${raw_file ?? new Date().toDateString()}`;
    a.click();
  }
  const renderContentByType = (content: FileContentParams) => {
    if(getFileType(content.raw_file) === "image/jpg") return (
      <img 
        className="w-100 cursor-pointer new-version-position-relative--content" 
        src={content.file} 
      />
    )
    else if(getFileType(content.raw_file) === "video/mp4") return (
      <video className="new-version-position-relative--content" width="100%" controls>
        <source src={content.file} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )
    else return (
      <Button 
        variant="link"
        className="p-1"
        onClick={()=>{
          if(content.file) {
            setFilePreviewUrl({
              contentPreview: 'pdf',
              preview: content.file
            })
          }
        }}
      >
        Open PDF
      </Button>
    )
  }
  const getContentPreview = (content: FileContentParams) => {
    const getType = getFileType(content.raw_file)
    if(getType === "image/jpg") return "image"
    else if(getType === "video/mp4") return "video"
    else if(getType === "application/pdf") return "pdf"
    else return undefined
  }
  const contentsRenderer = () => {
    if(loading) return <div className="h-100 custom-flex-col-center-center">
      <Spinner animation="grow" />
      <div>Trying to load files.</div>
    </div>
    else {
      if(contents.length > 0) return contents.map((content, iContent) => {
        const renderActionBtn = () => {
          if(content.file) return (
            <div className="custom-flex-row gap-1">
              <Button 
                variant="link"
                className="p-1"
                onClick={()=>{
                  const fileType = getContentPreview(content)
                  if(fileType && content.file) {
                    setFilePreviewUrl({
                      contentPreview: fileType,
                      preview: content.file
                    })
                  }
                }}
              >
                Preview
              </Button>
              <Button 
                variant="link"
                className="p-1"
                onClick={()=>content.file && downloadImage(content.file, content.raw_file)}
              >
                Download
              </Button>
            </div>
          )
          else return "File could'nt be loaded"
        }
        if(props.isListView) return (
          <div className="custom-flex-row-space-between-center gap-2 border-bottom-1 w-100">
            <div className="new-version-detail-label">{content.name ?? '-'}</div>
            {renderActionBtn()}
          </div>
        )
        else {
          if(props.isWithoutWidget) {
            if(content.isError) return <div style={{margin: 0, height: 100}} className="custom-flex-col-center-center text-muted user-card-file-info">File could'nt be loaded</div>
            else return (
              <div className="new-version-position-relative">
                <div 
                  className="new-version-position-relative--wrapper-content smooth-transition"
                  onClick={()=>{
                    if(content.file) {
                      const fileType = getContentPreview(content)
                      if(fileType) {
                        setFilePreviewUrl({
                          contentPreview: fileType,
                          preview: content.file
                        })
                      }
                    }
                  }}
                >
                  <svg 
                    onClick={()=>content.file && downloadImage(content.file, content.raw_file)}
                    className="new-version-download-button" 
                    width="20" 
                    height="20" 
                    viewBox="0 0 20 20" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 8.15L8.1 7.275C7.9 7.09167 7.66233 7.004 7.387 7.012C7.11233 7.02067 6.88333 7.11667 6.7 7.3C6.51667 7.48333 6.425 7.71667 6.425 8C6.425 8.28333 6.51667 8.51667 6.7 8.7L9.3 11.3C9.48333 11.4833 9.71667 11.575 10 11.575C10.2833 11.575 10.5167 11.4833 10.7 11.3L13.325 8.675C13.5083 8.49167 13.596 8.26233 13.588 7.987C13.5793 7.71233 13.4833 7.48333 13.3 7.3C13.1167 7.11667 12.8833 7.02067 12.6 7.012C12.3167 7.004 12.0833 7.1 11.9 7.3L11 8.15V4.975C11 4.69167 10.9043 4.45833 10.713 4.275C10.521 4.09167 10.2833 4 10 4C9.71667 4 9.47933 4.09567 9.288 4.287C9.096 4.479 9 4.71667 9 5V8.15ZM6 15H14.025C14.3083 15 14.5417 14.904 14.725 14.712C14.9083 14.5207 15 14.2833 15 14C15 13.7167 14.904 13.479 14.712 13.287C14.5207 13.0957 14.2833 13 14 13H5.975C5.69167 13 5.45833 13.0957 5.275 13.287C5.09167 13.479 5 13.7167 5 14C5 14.2833 5.09567 14.5207 5.287 14.712C5.479 14.904 5.71667 15 6 15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z" fill="white"/>
                  </svg>
                </div>
                {renderContentByType(content)}
              </div>
            )
          }
          else return (
            <div key={iContent} className="user-card-file-info">
              <div className="custom-flex-row-space-between-center gap-2 mb-2">
                {content.name && <strong>{content.name}</strong>}
                {!content.isError && <strong className="text-success" style={{cursor: 'pointer'}} onClick={()=>content.file && downloadImage(content.file, content.raw_file)}>Download</strong>}
              </div>
              {content.isError ? <div style={{margin: 0, height: 100}} className="custom-flex-col-center-center text-muted user-card-file-info">File could'nt be loaded</div> : renderContentByType(content)}
            </div>
          )
        }
      })
      else return (
        <div className="h-100 custom-flex-col-center-center">No Attachment</div>
      )
    }
  }
  return (
    <Fragment>
      {contentsRenderer()}
      <PreviewImage 
        active={!!filePreviewUrl}
        contentData={filePreviewUrl?.contentPreview}
        url={filePreviewUrl?.preview}
        disablePreview={()=>setFilePreviewUrl(undefined)}
      />
    </Fragment>
  )
}

interface HistoryModalProps {
  type: "HISTORY_2" | "HISTORY_1";
  id: string | number;
  countries: Array<ICountry>;
  occupations: IOccupation;
}
interface HistoryModalForm {
  type: "HISTORY_2" | "HISTORY_1";
  keyword: string;
}
type HistoryInitialState = Omit<InitialState, "dataSources" | "countries" | "provinces" | "occupations" | "defaultSort" | "modal"> & { 
  dataSources_kyc_lv_1: Array<IDetailKYC>; 
  dataSources_kyc_lv_2: Array<UserPremium>; 
  defaultSort_kyc_lv_1?: SortProps<IDetailKYC>;
  defaultSort_kyc_lv_2?: SortProps<UserPremium>;
  modal_kyc_lv_1?: IDetailKYC;
  modal_kyc_lv_2?: UserPremium;
}
const HistoryModal: FC<HistoryModalProps> = (props) => {
  const reducer = (state: HistoryInitialState, nState: Partial<HistoryInitialState>) => ({...state, ...nState})
  const [state, dispatch] = useReducer(reducer, {
    ...initialState, 
    dataSources_kyc_lv_1: [],
    dataSources_kyc_lv_2: [],
    defaultSort_kyc_lv_1: {
      order_by: "email",
      order_sort: "asc"
    }
  })
  const formik = useFormik<HistoryModalForm>({
    initialValues: {
      type: props.type,
      keyword: ""
    },
    onSubmit: async (values) => await fetchService(values, state.activePage, state.defaultSort_kyc_lv_1, state.defaultSort_kyc_lv_2)
  })
  const fetchService = async (values: HistoryModalForm, activePage: number, defaultSort_kyc_lv_1?: SortProps<IDetailKYC>, defaultSort_kyc_lv_2?: SortProps<UserPremium>) => {
    dispatch({loading: true})
    try {
      if(values.type === "HISTORY_1") {
        const nonPremium = await getHistoryNonPremiumUserKYC(props.id,{
          limit,
          page: activePage,
          q: values.keyword ? values.keyword : undefined,
          order_sort: defaultSort_kyc_lv_1?.order_sort,
          order_by: defaultSort_kyc_lv_1?.order_by
        })
        dispatch({
          dataSources_kyc_lv_1: nonPremium.data.data?.data ?? [],
        })
      }
      else {
        const premium = await getHistoryPremiumUserKYC(props.id, {
          limit,
          page: activePage,
          q: values.keyword ? values.keyword : undefined,
          order_sort: defaultSort_kyc_lv_2?.order_sort,
          order_by: defaultSort_kyc_lv_2?.order_by
        })
        dispatch({dataSources_kyc_lv_2: premium.data.data?.data ?? [] })
      }
      dispatch({loading: false})
    }
    catch(err) {
      console.log(err)
      dispatch({loading: false})
    }
  }
  useDebounce(fetchService, [formik.values, state.activePage, state.defaultSort_kyc_lv_1, state.defaultSort_kyc_lv_2], 200)
  const columns_kyc_lv_1: Array<ReusableTableColumn<IDetailKYC>> = [
    { 
      title: "User ID", 
      dataIndex: "user_id",
      render:() => props.id
    },
    { title: "Name", dataIndex: "name" },
    { title: "Email", dataIndex: "email", isSortMode: true },
    { 
      title: "Status", 
      dataIndex: "status", 
      render: (dataIndex) => {
        const statusFinder = statusClass.find(s => s.id === Number(dataIndex))
        if(statusFinder) return (
          <Badge bg={statusFinder.class}>
            {statusFinder.stat.toUpperCase()}
          </Badge>
        )
        else return "-"
      },
    },
    { title: "Timestamp", dataIndex: "updated_at", render: (dataIndex) => globalDateTime(dataIndex as string, true) },
    { 
      title: "Attachment", 
      dataIndex: "user_id",
      render: (_, data) => (
        <ListContentsModal<ModalDetailDataSource> 
          columns={[
            { dataIndex: "label_column_1", title: "Label", render: (dataIndex) => <strong>{dataIndex}</strong> },
            { dataIndex: "value_column_1", title: "Value" },
            { dataIndex: "label_column_2", title: "Label", render: (dataIndex) => <strong>{dataIndex}</strong> },
            { dataIndex: "value_column_2", title: "Value" },
          ]}
          dataSources={detailDataSources({
            type: "DETAIL",
            detail: data,
            countries: props.countries,
            occupations: props.occupations
          })}
          size="lg"
          withoutHeader
          footerChildren={
            <FileContents 
              contents={getContents({
                type: "DETAIL",
                detail: data,
                countries: props.countries,
                occupations: props.occupations
              })}
            />
          }
        />
      )
    }
  ]
  const columns_kyc_lv_2: Array<ReusableTableColumn<UserPremium>> = [
    { 
      title: "User ID", 
      dataIndex: "user_id",
      render:() => props.id
    },
    { 
      title: "Status", 
      dataIndex: "status", 
      render: (dataIndex) => {
        const statusFinder = statusClass.find(s => s.id === Number(dataIndex))
        if(statusFinder) return (
          <Badge bg={statusFinder.class}>
            {statusFinder.stat.toUpperCase()}
          </Badge>
        )
        else return "-"
      } 
    },
    { title: "Timestamp", dataIndex: "updated_at", render: (dataIndex) => globalDateTime(dataIndex as string, true) },
    { 
      title: "Attachment", 
      dataIndex: "user_id",
      render: (_, data) => (
        <ListContentsModal<{label: string; value: ReactNode;}> 
          columns={[
            { title: "Label", dataIndex: "label" },
            { title: "Value", dataIndex: "value" },
          ]}
          dataSources={[
            { label: "Action By", value: data.action_by },
            { label: "Timestamp", value: globalDateTime(data.updated_at, true) },
            {
              label: "Attachment", value: (
                <FileContents
                  contents={[
                    { raw_file: data.source_of_wealth, name: "Source Of Found" },
                    { raw_file: data.bank_statement, name: "Bank Statement" },
                  ]}
                />
              )
            }
          ]}
          withoutHeader
        />
      )
    },
  ]
  return (
    <Fragment>
      <FormWithFormik 
        name="keyword"
        type="TEXT_INPUT"
        config={formik}
        placeholder="Search"
        className="mb-2"
      />
      {props.type === "HISTORY_1" ?
        <ReusableTable<IDetailKYC> 
          columns={columns_kyc_lv_1}
          dataSources={state.dataSources_kyc_lv_1}
          onSort={{
            default: state.defaultSort_kyc_lv_1,
            onChange: (sort) => dispatch({defaultSort_kyc_lv_1: sort})
          }}
          loading={state.loading}
          pagination={{
            activePage: state.activePage,
            itemsCountPerPage: limit,
            totalItemsCount: state.totalItemsCount,
            onChange: (pageNumber) => {
              dispatch({activePage: pageNumber})
            }
          }}
        />
        :
        <ReusableTable<UserPremium> 
          columns={columns_kyc_lv_2}
          dataSources={state.dataSources_kyc_lv_2}
          onSort={{
            default: state.defaultSort_kyc_lv_2,
            onChange: (sort) => dispatch({defaultSort_kyc_lv_2: sort})
          }}
          loading={state.loading}
          pagination={{
            activePage: state.activePage,
            itemsCountPerPage: limit,
            totalItemsCount: state.totalItemsCount,
            onChange: (pageNumber) => {
              dispatch({activePage: pageNumber})
            }
          }}
        />
      }
    </Fragment>
  )
}

const ListContentsModal = <T extends object>(props: ReusableTableProps<T> & { size?: "sm" | "lg" | "xl"; footerChildren?: ReactNode; }) => {
  const [isModal, setIsModal] = useState<boolean>(false)
  return (
    <Fragment>
      <span style={{cursor: 'pointer', color: 'blue'}} onClick={()=>setIsModal(true)}>View Detail</span>
      <Modal
        show={isModal}
        onHide={()=>setIsModal(false)}
        centered
        size={props.size}
      >
        <Modal.Header>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReusableTable 
            {...props}
          />
          {props.footerChildren}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

interface ExportFormProps{
  countries: Array<ICountry>;
  occupations: IOccupation;
  isExportModal: boolean;
  onDownload: () => void;
}

const ExportForm: FC<ExportFormProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const formik = useFormik<Omit<ExportDataPayload, "date_from" | "date_to"> & {date_from: Date; date_to: Date;}>({
    initialValues: {
      type: "simple",
      nationality: "all",
      occupation: "",
      kyc_lvl_1: "all",
      kyc_lvl_2: "all",
      liveness_check: "all",
      government_status: "all",
      screening_risk: "all",
      date_source: "create_date",
      action_by: "all",
      date_from: moment().subtract(7).toDate(),
      date_to: new Date(),
    },
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await getExportData({
          ...values,
          occupation: Number(values.occupation) ? Number(values.occupation) : null,
          date_from: moment(values.date_from).format('YYYY-MM-DD'),
          date_to: moment(values.date_to).format('YYYY-MM-DD')
        })
        props.onDownload()
        setLoading(false)
      }
      catch(err) {
        console.log(err)
        setLoading(false)
      }
    }
  })
  useEffect(formik.resetForm,[props.isExportModal])
  return (
    <Fragment>
      <FormWithFormik 
        name="type"
        type="DROPDOWN"
        config={formik}
        options={reportTypes}
        label="Report Type"
      />
      <FormWithFormik 
        name="nationality"
        type="SEARCHABLE_SELECT"
        config={formik}
        options={[{label: "ALL", value: "all"}, ...props.countries.map(c => ({label: c.Name, value: c.Iso2 }))]}
        label="Nationality"
      />
      <FormWithFormik 
        name="occupation"
        type="SEARCHABLE_SELECT"
        config={formik}
        options={[{label: "ALL", value: ""}, ...Object.entries(props.occupations).map(([key,val]: [string, string]) => ({ label: val, value: key }))]}
        label="Occupation"
      />
      <FormWithFormik 
        name="kyc_lvl_1"
        type="DROPDOWN"
        config={formik}
        options={filterStats}
        label="KYC Level 1"
      />
      <FormWithFormik 
        name="kyc_lvl_2"
        type="DROPDOWN"
        config={formik}
        options={filterStats}
        label="KYC Level 2"
      />
      <FormWithFormik 
        name="liveness_check"
        type="DROPDOWN"
        config={formik}
        options={filterStats}
        label="Liveness Check Status"
      />
      <FormWithFormik 
        name="government_status"
        type="DROPDOWN"
        config={formik}
        options={filterStats}
        label="Goverment Status"
      />
      <FormWithFormik 
        name="screening_risk"
        type="DROPDOWN"
        config={formik}
        options={screeningRisk}
        label="Screening Risk"
      />
      <FormWithFormik 
        name="date_source"
        type="DROPDOWN"
        config={formik}
        options={dateTypes}
        label="Date Type"
      />
      <FormWithFormik 
        name="action_by"
        type="DROPDOWN"
        config={formik}
        options={[
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'Admin',
            value: 'admin',
          },
          {
            label: 'Peruri',
            value: 'peruri',
          },
          {
            label: 'Auto',
            value: 'auto',
          },
        ]}
        label="Action By"
      />
      <div className="custom-flex-row-center gap-2">
        <FormWithFormik 
          name="date_from"
          type="DATE"
          config={formik}
          label="Start Date"
          dateFormat="dd/MM/yyyy"
          maxDate={formik.values.date_to}
        />
        <FormWithFormik 
          name="date_to"
          type="DATE"
          config={formik}
          label="End Date"
          dateFormat="dd/MM/yyyy"
          minDate={formik.values.date_from}
          maxDate={new Date()}
        />
      </div>
      <Button 
        className="w-100 mt-3" 
        variant="primary" 
        onClick={formik.submitForm}
        disabled={loading}
      >
        DOWNLOAD
      </Button>
    </Fragment>
  )
}

export interface RowWrapperProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  position?: "LEFT" | "RIGHT"
}
export const RowWrapper = forwardRef<HTMLDivElement, PropsWithChildren<RowWrapperProps>>((props) => {
  const { position, className, style, ...anotherProps } = props
  const getClassByPosition = () => {
    if(props.position === "LEFT") return "row-wrapper__left"
    else if(props.position === "RIGHT") return "row-wrapper__right"
    else return ""
  }
  return (
    <div className={`row-wrapper ${getClassByPosition()} ${className ?? ""}`} {...anotherProps} style={{...style, cursor: anotherProps.onClick ? 'pointer' : undefined}}>
      {props.children}
    </div>
  )
})
RowWrapper.displayName = "RowWrapper"

export interface PopoverActionProps {
  label: ReactNode;
}
export const PopoverAction: FC<PropsWithChildren<PopoverActionProps>> = (props) => {
  return (
    <OverlayTrigger
      trigger={["hover", "hover"]}
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>
            {props.label}
          </Popover.Body>
        </Popover>
      }
    >
      <div>{props.children}</div>
    </OverlayTrigger>
  )
}

export default User