import { useFormik } from "formik"
import { FC, ReactNode, useEffect, useReducer } from "react"
import { Badge, Button, Modal } from "react-bootstrap"
import { RouteComponentProps } from "react-router-dom"
import FormWithFormik from "../../components/reusableFormWithFormik"
import ReusableTimeline from "../../components/reusableTimeline"
import Pagination, { ReactJsPaginationProps } from 'react-js-pagination';
import { getDetailUserKYC, getOccupations, getProvinces, IDetailKYC, IOccupation, IProvince, UserPremium, getHistoryNonPremiumUserKYC, getHistoryPremiumUserKYC, confirmKYC, resetDetailKYC, INonPremiumDocument, getNonPremiumDocument, getPremiumDocument } from "../../crud/kyc-user"
import { capitalizeEveryWord, capitalizeFirstLetter, globalDateTime, useDebounce } from "../../helper"
import { FileContentParams, FileContents, getRiskCalculationLabel, PopoverAction, screeningInfos } from "./user"
import Swal2, { SweetAlertIcon } from "sweetalert2"
import * as yup from "yup"

interface IFormDetail {
  history: "HISTORY_1" | "HISTORY_2";
  reason: string;
}

const itemsCountPerPage = 4

interface InitialState extends Omit<ReactJsPaginationProps, "onChange">{
  details?: IDetailKYC;
  occupations?: IOccupation;
  provinces?: IProvince;
  dataSourcesHistory1: Array<IDetailKYC>;
  dataSourcesHistory2: Array<UserPremium>;
  rejectModal: boolean;
  nonPremiumDocument?: INonPremiumDocument;
  premiumDocument?: Pick<UserPremium, "bank_statement" | "source_of_wealth">;
}

const initialState: InitialState = {
  totalItemsCount: 0,
  activePage: 1,
  itemsCountPerPage,
  dataSourcesHistory1: [],
  dataSourcesHistory2: [],
  rejectModal: false
}

interface IInfo {
  propertyName: keyof IDetailKYC;
  titleName?: string;
  render?: ((detail: IDetailKYC) => ReactNode) | undefined;
}

const Detail: FC<RouteComponentProps<{id: string}>> = (props) => {
  const reducer = (state: InitialState, nstate: Partial<InitialState>): InitialState => ({...state, ...nstate})
  const [state, dispatch] = useReducer(reducer, initialState)
  const formik = useFormik<IFormDetail>({
    initialValues: {
      history: "HISTORY_1",
      reason: ""
    },
    validationSchema: yup.object().shape({
      reason: yup.string().required('Please fill this form!')
    }),
    onSubmit: async (values) => await approveOrRejectService("REJECT", values)
  })
  const approveOrRejectService = async (type: "APPROVE" | "REJECT", values: IFormDetail) => {
    try {
      if(props.match.params.id && state.details) {
        await confirmKYC(props.match.params.id, {
          status: type === "APPROVE" ? 1 : -1,
          reject_reason: type === "APPROVE" ? values.reason : "",
          level: state.details.kyc_level,
        })
        await getDetailKYC()
        dispatch({rejectModal: false})
        await Swal2.fire('Success!',`Successfully ${type === "APPROVE" ? "Approved" : "Rejected"}!`, 'success')
      }
    }
    catch(err) {
      console.log(err)
    }
  }
  const modalAction = async (type: "APPROVE" | "PENDING" | "RESET") => {
    try {
      const getLabelModal = (): { label: string; approveLabel: string; icon: SweetAlertIcon } => {
        if(type === "APPROVE") return {
          label: "Are you sure want to approve this KYC ?",
          approveLabel: "Yes, Approve!",
          icon: "success"
        }
        else if(type === "PENDING") return {
          label: "Are you sure want to set to pending ?",
          approveLabel: "Yes, set to Pending!",
          icon: "warning"
        }
        else return {
          label: "Are you sure want to reset ?",
          approveLabel: "Yes, Reset it!",
          icon: "error"
        }
      }
      const swal = await Swal2.fire({
        title: "Warning", 
        html: getLabelModal().label, 
        icon: getLabelModal().icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, Cancel!',
        confirmButtonText: getLabelModal().approveLabel
      })
      if(swal.isConfirmed) {
        if(type === "APPROVE") {
          await approveOrRejectService("APPROVE", formik.values)
          await formik.setValues({
            ...formik.values,
            reason: ""
          })
          await getDetailKYC()
          await Swal2.fire('Approved!','Successfully approved!', 'success')
          dispatch({rejectModal: false})
        }
        else if(type === "PENDING") {
          if(props.match.params.id) {
            await resetDetailKYC(props.match.params.id, { reset_status: 1 })
            await getDetailKYC()
            await Swal2.fire('Success!','Successfully set to Pending!', 'success')
            dispatch({rejectModal: false})
          }
        }
        else {
          if(props.match.params.id) {
            await resetDetailKYC(props.match.params.id, { reset_status: 0 })
            await getDetailKYC()
            await Swal2.fire('Success!','Successfully Reset!', 'success')
            dispatch({rejectModal: false})
          }
        }
      }
    }
    catch(err) {
      console.log(err)
    }
  }
  const getUtils = async () => {
    try {
      const occupations = await getOccupations()
      const provinces = await getProvinces()
      dispatch({
        occupations: occupations.data.data,
        provinces: provinces.data.data
      })
    }
    catch(err) {
      console.log(err)
    }
  }
  const getDetailKYC = async () => {
    try {
      if(props.match.params.id) {
        const response = await getDetailUserKYC(props.match.params.id)
        dispatch({ details: response.data.data })
      }
    }
    catch(err) {
      console.log(err)
    }
  }
  const getHistoryLogs = async (activePage: number, history: "HISTORY_1" | "HISTORY_2") => {
    try {
      if(props.match.params.id) {
        if(history === "HISTORY_1") {
          const response = await getHistoryNonPremiumUserKYC(props.match.params.id, {
            limit: itemsCountPerPage,
            page: activePage
          })
          dispatch({
            dataSourcesHistory1: response.data.data?.data ?? [],
            dataSourcesHistory2: [],
            totalItemsCount: response.data.data?.total_data ?? 0
          })
        }
        else {
          const response = await getHistoryPremiumUserKYC(props.match.params.id, {
            limit: itemsCountPerPage,
            page: activePage
          })
          dispatch({
            dataSourcesHistory1: [],
            dataSourcesHistory2: response.data.data?.data ?? [],
            totalItemsCount: response.data.data?.total_data ?? 0
          })
        }
      }
    }
    catch(err) {
      console.log(err)
    }
  }
  const getDocuments = async () => {
    if(props.match.params.id) {
      try {
        const nonPremiumDocument = await getNonPremiumDocument(props.match.params.id)
        const premiumDocument = await getPremiumDocument(props.match.params.id)
        dispatch({
          nonPremiumDocument,
          premiumDocument
        })
      }
      catch(err) {
        console.log('err:',err)
      }
    }
  }

  useEffect(()=>{
    getDetailKYC()
    getUtils()
    getDocuments()
  }, [])
  useEffect(()=>dispatch({activePage: 1}), [formik.values.history])
  useDebounce<[number, "HISTORY_1" | "HISTORY_2"]>((activePage, history)=> getHistoryLogs(activePage, history), [state.activePage, formik.values.history], 100)
  const infos: Array<IInfo> = [
    { propertyName: "id_type" },
    { propertyName: "id_number" },
    { propertyName: "npwp" },
    { 
      propertyName: "gender",
      render: (detail) => detail.gender === 1 ? "Male" : "Female"
    },
    { propertyName: "place_of_birth" },
    { propertyName: "date_of_birth", render: (detail) => detail.date_of_birth ? globalDateTime(detail.date_of_birth) : "-" },
    { propertyName: "address" },
    { 
      propertyName: "province",
      render: (detail) => {
        if(state.provinces && detail.province) return state.provinces[detail.province]
        else return "-"
      }
    },
    { propertyName: "zip_code" },
    { 
      propertyName: "occupation", 
      render: (detail) => {
        if(state.occupations) return state.occupations[detail.occupation]
        else return "-"
      }
    },
    { propertyName: "phone_no" },
    { propertyName: "mobile_no" },
    { 
      propertyName: "screening_risk",
      render: (detail) => {
        return screeningInfos[detail.screening_risk].name.toUpperCase()
      }
    },
    { 
      propertyName: "risk_calculation", 
      titleName: "PPATK Calculation",
      render: (detail) => capitalizeFirstLetter(getRiskCalculationLabel(detail.risk_calculation))
    },
  ]
  const infosRender = (info: IInfo) => {
    if(state.details) {
      if(info.render) return info.render(state.details)
      else return state.details[info.propertyName] ?? "-"
    }
    else return "-"
  }
  const getContents = (details: IDetailKYC): Array<FileContentParams> => {
    const contents: Array<FileContentParams> = [
      { raw_file: details.id_photo, name: "ID CARD" },
      { raw_file: details.proof_of_address, name: "KITAS/KITAP" },
      { raw_file: details.selfie_photo, name: "SELFIE" },
    ]
    if(details.liveness_video) contents.splice(1, 0, { raw_file: details.liveness_video, name: "LIVENESS" })
    if(details.nationality !== "ID") return contents
    return contents.filter(c => c.name !== "KITAS/KITAP")
  }

  const getUtilsByStatus = (type: "GOVT" | "LIVENESS" | "SCREENING") => {
    if(state.details) {
      if(type === "GOVT") {
        const isPeruri = !!state.details.liveness_video
        if((state.details.government_status === -1 && state.details.status === 0 && !isPeruri) || (state.details.government_status === 0 && isPeruri)) return "new-version-user-action-btn-pending"
        else if((state.details.government_status === -1 && state.details.status === 0 && isPeruri) || (state.details.government_status === -1 && state.details.status !== 0)) return "new-version-user-action-btn-reject"
        else if(state.details.government_status === 1) return "new-version-user-action-btn-approve"
        else return "new-version-user-action-btn-none"
      }
      else if(type === "LIVENESS") {
        const { liveness_check, liveness_video, status } = state.details
        const isPeruri = !!liveness_video
        if((liveness_check === -1 && status === 0 && !isPeruri) || liveness_check === 0) return "new-version-user-action-btn-pending"
        else if((liveness_check === -1 && status === 0 && isPeruri) || (liveness_check === -1 && status !== 0)) return "new-version-user-action-btn-reject"
        else if(liveness_check === 1) return "new-version-user-action-btn-approve"
        else return "new-version-user-action-btn-none"
      }
      else {
        const { screening_risk, status } = state.details
        if(screening_risk === 1 || screening_risk === 2 || (screening_risk === 3 && status === 1)) return "new-version-user-action-btn-approve"
        else if(screening_risk === 3 && status === -1) return "new-version-user-action-btn-reject"
        else if(screening_risk === 3 && status === 0) return "new-version-user-action-btn-pending"
        else return "new-version-user-action-btn-none"
      }
    }
    else return "new-version-user-action-btn-none"
  }
  const getKYCStatus = () => {
    if(state.details) {
      const role = localStorage.getItem('rule');
      const { status } = state.details
      if(status === -1) return {
        className: "new-version-kyc-action-reject",
        label: "REJECT",
        variant: "danger"
      }
      else if((role === 'superuser' || role === 'compliance' || role === 'cs') && status === 0) return {
        className: "new-version-kyc-action-pending",
        label: "PENDING",
        variant: "warning"
      }
      else if(status === 1) return {
        className: "new-version-kyc-action-approve",
        label: "APPROVE",
        variant: "success"
      }
      else return {
        className: "new-version-kyc-action-none",
        label: "-",
        variant: "info"
      }
    }
  }
  return (
    <div className="custom-flex-row gap-2">
      <div className="new-version-detail-user-wrapper custom-flex-col-space-between gap-2 w-100" style={{height: window.innerHeight - 40}}>
        <div className="custom-flex-col gap-2">
          <div className="custom-flex-row-space-between-center gap-4 w-100">
            <Badge onClick={()=>props.history.goBack()} className="cursor-pointer" bg="info">Go Back</Badge>
            {state.details && 
            <div className="custom-flex-row gap-1">
              <Badge bg="success">KYC {state.details?.kyc_level}</Badge>
              <Badge bg={getKYCStatus()?.variant}>{getKYCStatus()?.label}</Badge>
            </div>
            }
          </div>
          <div className="custom-flex-col-center gap-2">
            <div className="new-version-detail-avatar">{(state.details?.name ?? "").split(' ').map(i => (i[0] ?? "").toUpperCase()).join('').slice(0, 2)}</div>
            {state.details &&
              <img
                src={`https://flagcdn.com/16x12/${state.details.country.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/32x24/${state.details.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${state.details.country.toLowerCase()}.png 3x`}
                width="16"
                height="12"
                alt={state.details.country}
              />
            }
            <div className="new-version-detail-name">{state.details?.name}</div>
            <div className="new-version-detail-email">{state.details?.email}</div>
            <div className="new-version-detail-email">UID: {props.match?.params?.id}</div>
            <div className="custom-flex-row-center gap-3">
              <PopoverAction label="Govt. Database">
                <button className={`new-version-user-action-btn ${getUtilsByStatus("GOVT")}`}><span className="fa-regular fa-file-lines" /></button>
              </PopoverAction>
              <PopoverAction label="Liveness">
                <button className={`new-version-user-action-btn ${getUtilsByStatus("LIVENESS")}`}><span className="fa-solid fa-user-check" /></button>
              </PopoverAction>
              <PopoverAction label="Name Screening">
                <button className={`new-version-user-action-btn ${getUtilsByStatus("SCREENING")}`}><span className="fa-solid fa-money-check-dollar" /></button>
              </PopoverAction>
            </div>
          </div>
          {infos.map((info,i)=>
            <div key={i} className="custom-flex-row-space-between-center gap-2 border-bottom-1 w-100">
              <div className="new-version-detail-label">{capitalizeEveryWord((info.titleName ?? info.propertyName).replace(/_/g," "))}</div>
              <div className="new-version-detail-value">{infosRender(info)}</div>
            </div>
          )}
        </div>
        <div className="custom-flex-col gap-2">
          <div className="custom-flex-row-space-between gap-2">
            <Button className="w-100" variant="success" onClick={()=>modalAction("APPROVE")}>Approve</Button>
            <Button className="w-100" variant="danger" onClick={()=>dispatch({rejectModal: true})}>Reject</Button>
          </div>
          <div className="custom-flex-row-space-between gap-2">
            <Button className="w-100" variant="warning" onClick={()=>modalAction("PENDING")}>Set as Pending</Button>
            <Button className="w-100" variant="primary" onClick={()=>modalAction("RESET")}>Reset KYC</Button>
          </div>
        </div>
      </div>
      <div className="new-version-detail-user-wrapper custom-flex-col gap-2 w-100 overflow-auto" style={{maxHeight: window.innerHeight - 40}}>
        {state.details &&
          <FileContents 
            contents={getContents(state.details)}
            isWithoutWidget
          />
        }
      </div>
      <div className="custom-flex-col-space-between gap-2 w-100">
        <div className="new-version-detail-user-wrapper custom-flex-col-space-between gap-2 w-100 h-100">
          <div className="custom-flex-col">
            <FormWithFormik 
              label="History Log"
              type="DROPDOWN"
              name="history"
              config={formik}
              options={[
                { label: "History Level 1", value: "HISTORY_1" },
                { label: "History Level 2", value: "HISTORY_2" }
              ]}
            />
            <ReusableTimeline 
              className="mt-5"
              timelines={formik.values.history === "HISTORY_1" ? state.dataSourcesHistory1.map((data) => ({
                icon: <span className="fas fa-rocket text-primary fa-sm fa-fw" />,
                title: data.name,
                subTitle: globalDateTime(data.updated_at),
                desc: data.email
              })) : state.dataSourcesHistory2.map((d) => ({
                icon: <span className="fas fa-rocket text-primary fa-sm fa-fw" />,
                title: d.user_id,
                subTitle: globalDateTime(d.updated_at),
                desc: d.reject_reason
              }))}
            />
          </div>
          {!(state.totalItemsCount <= itemsCountPerPage) &&
          <Pagination 
            itemClass='page-item'
            linkClass='page-link'
            totalItemsCount={state.totalItemsCount}
            activePage={state.activePage}
            itemsCountPerPage={state.itemsCountPerPage}
            onChange={(page) => dispatch({activePage: page})}
          />
          }
        </div>
        {state.premiumDocument && 
          <div className="new-version-detail-user-wrapper custom-flex-col-space-between gap-2 w-100 h-40">
            <div>Proof of Document</div>
              <div>
                <FileContents 
                  contents={[
                    { name: "Bank Statement", raw_file: state.premiumDocument.bank_statement },
                    { name: "Source of Fund", raw_file: state.premiumDocument.source_of_wealth },
                  ]}
                  isListView={true}
                />
              </div>
          </div>
        }
        {state.nonPremiumDocument?.proof_of_address &&
          <div className="new-version-detail-user-wrapper custom-flex-col-space-between gap-2 w-100 h-40">
            <div>Supporting Document</div>
            <FileContents 
              contents={[
                { name: "Kitas/Kitap", raw_file: state.nonPremiumDocument.proof_of_address },
              ]}
              isListView={true}
            />
          </div>
        }
      </div>
      <Modal
        show={state.rejectModal}
        onHide={()=>dispatch({rejectModal: false})}
        centered
      >
        <Modal.Header>
          <Modal.Title>Reject Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormWithFormik 
            type="TEXT_AREA"
            config={formik}
            name="reason"
            label="Reject Reason"
          />
          <Button className="w-100 mt-3" variant="danger" onClick={formik.submitForm}>REJECT</Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Detail