import React from 'react';
import axios from 'axios';
// import _ from 'lodash';
import actions from '../../../crud/kyc';
import listData from '../../../crud/json';
import { createBrowserHistory } from 'history';
import {
  ButtonToolbar,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import PrettyJSON from 'json-stringify-pretty-compact';

const history = createBrowserHistory();

export default class ThubUserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userKYC: [],
      name: '',
      idNumber: '',
      pob: '',
      dob: '',
      premiumKYC: [],
      show: false,
      shows: false,
      showing: false,
      file: '',
      level: '',
      levelPremium: '',
      livenessData: [],
      reason: '',
      required: false,
      title: '',
      province: '',
      provinces: [],
      country: '',
      countries: [],
      occupation: '',
      occupations: [],
      editStatus: true,
    };

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({});
      obj[fieldName] = event.target.value;
      obj.resetCapctha = false;
      this.setState(obj);
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShows = this.handleShows.bind(this);
    this.handleCloses = this.handleCloses.bind(this);
    // this.handleEdit = this.handleEdit.bind(this);
    // this.handleEditClosing = this.handleEditClosing.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
  }

  handleStatus() {
    if (this.state.editStatus) {
      this.setState({ editStatus: false });
    } else {
      this.setState({ editStatus: true });
      this.getDetailUser();
    }
  }

  getToken() {
    let token = localStorage.getItem('token');
    return token;
  }

  getData(id) {
    axios
      .get(`https://147.139.135.182/admin/file/${id}`, {
        headers: {
          'X-TCDX-SIGNATURE': 'salamtothemoon',
          Authorization:
            'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJhZG1pbkB0Y2R4LmNvbSIsImNyZWF0ZWRfYnkiOm51bGwsInVwZGF0ZWRfYnkiOm51bGwsInN0YXR1cyI6MSwiY3JlYXRlZF9hdCI6IjIwMjAtMDQtMTVUMDE6NDQ6NDYuMDAwWiIsInVwZGF0ZWRfYXQiOiIyMDIwLTA0LTE1VDAxOjQ0OjQ2LjAwMFoiLCJkZWxldGVkX2F0IjpudWxsLCJpYXQiOjE1ODkyNzM0NTQsImV4cCI6MTU5MDQ4MzA1NH0.a7d1DTtDj8t20av3uy0zjp_wsyQ5brwj3fFztW5UTXFiVjRa9ySZQ1xCQlxVN0EmlfwszPw8Q04csKsf_IT4kduEPQkg90Eczqz1dDVvFLWBNyvZwZL-5yDKGwKBlUE0w6J1-MdEARC5PjtHv1qzjErHctn-XcHeAw9yLblUrUWAoxwAFoBCX_7YfqWAzWIFxhTSaIQ_0qNE5TYAZpeSsyt4zy6RGlBODd6UsWYHCFsWywUazsw3e9JuGjaVYlJ2rTrLg1AaCbR8KmVKA_ZvNl9aZQoMHbfM87XJA4Z8PvZsG4rBNp-oK5bSD1wh8j6HSzlcir7fWFNswm25GqhE7g',
        },
      })

      .then((res) => {
        this.setState({
          userKYC: res.data.data,
          loading: false,
        });
      });
  }

  async getDetailUser() {
    // let token = localStorage.getItem("token");
    this.setState({
      loading: true,
    });

    let id = this.props.history.location.pathname.split('/')[
      this.props.history.location.pathname.split('/').length - 2
    ];
    actions
      .fetchDetailUserKYC(this.getToken(), id)
      .then((res) => {
        this.setState({
          name: res.data.data.name,
          idNumber: res.data.data.id_number,
          pob: res.data.data.place_of_birth,
          dob: res.data.data.date_of_birth,
          userKYC: res.data.data,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          userKYC: [],
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  async getPremiumUser() {
    this.setState({
      loading: true,
    });

    let id = this.props.history.location.pathname.split('/')[
      this.props.history.location.pathname.split('/').length - 2
    ];
    actions
      .fetchPremium(this.getToken(), id)
      .then((res) => {
        let data = res.data.data;
        if (data) {
          this.setState({
            levelPremium: res.data.data.status,
            premiumKYC: res.data.data || '',
            loading: false,
          });
        } else {
          this.setState({
            levelPremium: '',
            premiumKYC: res.data.data || '',
            loading: false,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          userKYC: [],
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  async liveGovt(id) {
    this.setState({
      loading: true,
    });
    let idUser = this.props.history.location.pathname.split('/')[
      this.props.history.location.pathname.split('/').length - 2
    ];
    let data = {
      status: parseInt(id),
    };
    if (this.state.level === 1) {
      actions
        .confirmKYCLiveness(this.getToken(), idUser, data)
        .then((res) => {
          this.setState({
            loading: false,
            shows: false,
          });
          this.getDetailUser();
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        });
    }
    if (this.state.level === 2) {
      actions
        .confirmKYCGovernment(this.getToken(), idUser, data)
        .then((res) => {
          this.setState({
            loading: false,
            shows: false,
          });
          this.getDetailUser();
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        });
    }
  }

  getPhoto(id) {
    this.setState({
      loading: true,
    });

    actions
      .fetchPhoto(this.getToken(), id)
      .then((res) => {
        this.setState({
          loading: false,
        });

        var a = document.createElement('a');
        a.href = 'data:image/jpg;base64,' + res.data;
        a.download = `${id}`;
        a.click();
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  confirmKyc(id) {
    this.setState({
      loading: true,
    });
    let idUser = this.props.history.location.pathname.split('/')[
      this.props.history.location.pathname.split('/').length - 2
    ];
    if (id === '-1' && this.state.reason === '') {
      this.setState({ required: true });
    } else {
      let data = {
        status: parseInt(id),
        level: parseInt(this.state.level),
        reject_reason: this.state.reason,
      };

      actions
        .confirmKYC(this.getToken(), idUser, data)
        .then((res) => {
          this.setState({
            loading: false,
            show: false,
          });
          this.getDetailUser();
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        });
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(id) {
    this.setState({ show: true, level: id });
  }

  handleCloses() {
    this.setState({ shows: false, editStatus: true });
  }

  handleShows(status, id) {
    this.setState({ shows: true, level: id, title: status });
  }

  // handleClosing() {
  //   this.setState({ showing: false });
  // }

  // handleShowing(id) {
  //   this.setState({ showing: true});
  //   this.getLiveness(id);
  // }

  getLiveness(id) {
    this.setState({
      loading: false,
    });

    actions
      .fetchLivenessPhoto(this.getToken(), id)
      .then((res) => {
        this.setState({
          loading: false,
        });

        var a = document.createElement('a');
        a.href = 'data:text/plain;base64,' + res.data.data;
        a.download = `${id}.zip`;
        a.click();
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  getCountry() {
    this.setState({
      country: false,
    });
    listData.fetchCountry(this.getToken()).then((res) => {
      this.setState({
        countries: res.data.data,
      });
    });
  }

  getProvinces() {
    listData.fetchProvince(this.getToken()).then((res) => {
      this.setState({
        provinces: res.data.data,
      });
    });
  }

  getOccupations() {
    listData.fetchOccupation(this.getToken()).then((res) => {
      this.setState({
        occupations: res.data.data,
      });
    });
  }

  updateKYC(id) {
    this.setState({
      loading: true,
    });
    let data = {
      name: this.state.name,
      id_number: this.state.idNumber,
      place_of_birth: this.state.pob,
      date_of_birth: this.state.dob,
    };
    actions
      .updateDetailKYC(this.getToken(), id, data)
      .then((res) => {
        this.setState({
          loading: false,
          editStatus: true,
        });
        this.getDetailUser();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          editStatus: true,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
        this.getDetailUser();
      });
  }

  componentDidMount() {
    this.getDetailUser();
    this.getPremiumUser();
    this.getCountry();
    this.getProvinces();
    this.getOccupations();
  }

  render() {
    const getNationality =
      this.state.countries.find(
        (country) => country.Iso2 === this.state.userKYC.nationality
      ) || {};
    const nationality = getNationality.Name || '';
    const getCountry =
      this.state.countries.find(
        (country) => country.Iso2 === this.state.userKYC.country
      ) || {};
    const country = getCountry.Name || '';

    const province =
      this.state.provinces[this.state.userKYC.province] || '';
    const occupation =
      this.state.occupations[this.state.userKYC.occupation] || '';

    const { result } = this.state.userKYC.goverment_result || '';
    let trx_id = '';
    let rate = 0;
    let obj;
    if (result) {
      obj = JSON.parse(result);
      trx_id = obj.trx_id || '';
      if (obj) {
        const subObj = obj.data || 0;
        if (subObj) rate = obj.data.selfie_photo || 0;
      }
    }

    let idUser = this.props.history.location.pathname.split('/')[
      this.props.history.location.pathname.split('/').length - 2
    ];

    let rule = localStorage.getItem('rule');

    return (
      <div className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">User View</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Tabs
                  id="controlled-tab-example"
                  defaultActiveKey="home"
                >
                  <Tab eventKey="home" title="Home">
                    <div className="row">
                      <div className="col-sm-12 margin-botHalf">
                        <div className="row">
                          <div className="col-sm-2 margin-botHalf">
                            Name
                          </div>
                          {this.state.editStatus ? (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              : &nbsp; {this.state.name}
                            </div>
                          ) : (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                style={{ width: '50%' }}
                              />
                            </div>
                          )}
                          <div className="col-sm-2 margin-botHalf">
                            ID Type
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp;{' '}
                            {this.state.userKYC.id_type === 0 ? (
                              <span>Identity Card</span>
                            ) : (
                              <span>Passport</span>
                            )}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            ID Number
                          </div>
                          {/* <div className="col-sm-10 margin-botHalf"> : &nbsp; {this.state.userKYC.id_number}</div> */}
                          {this.state.editStatus ? (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              : &nbsp; {this.state.idNumber}
                            </div>
                          ) : (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.idNumber}
                                onChange={this.handleChange(
                                  'idNumber'
                                )}
                                style={{ width: '40%' }}
                              />
                            </div>
                          )}
                          <div className="col-sm-2 margin-botHalf">
                            Place of Birtday
                          </div>
                          {/* <div className="col-sm-10 margin-botHalf"> : &nbsp; {this.state.userKYC.place_of_birth}</div> */}
                          {this.state.editStatus ? (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              : &nbsp; {this.state.pob}
                            </div>
                          ) : (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.pob}
                                onChange={this.handleChange('pob')}
                                style={{ width: '40%' }}
                              />
                            </div>
                          )}
                          <div className="col-sm-2 margin-botHalf">
                            Date of Birtday
                          </div>
                          {/* <div className="col-sm-10 margin-botHalf"> : &nbsp; {this.state.userKYC.date_of_birth}</div> */}
                          {this.state.editStatus ? (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              : &nbsp; {this.state.dob}
                            </div>
                          ) : (
                            <div className="col-sm-10 margin-botHalf">
                              {' '}
                              <Form.Control
                                size="sm"
                                type="text"
                                value={this.state.dob}
                                onChange={this.handleChange('dob')}
                                style={{ width: '40%' }}
                              />
                              <span
                                style={{
                                  fontSize: '11px',
                                  color: 'orange',
                                }}
                              >
                                *please using format YYYY-MM-DD
                              </span>
                            </div>
                          )}
                          <div className="col-sm-2 margin-botHalf">
                            Gender
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp;{' '}
                            {this.state.userKYC.gender === 0 ? (
                              <span>Female</span>
                            ) : (
                              <span>Male</span>
                            )}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            email
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {this.state.userKYC.email}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Nationality
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {nationality}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Country
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {country}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Province
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {province}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Zip Code
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {this.state.userKYC.zip_code}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Address
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {this.state.userKYC.address}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Occupation
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {occupation}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Number Country Code
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp;{' '}
                            {this.state.userKYC.phone_country_code}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Phone Number
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {this.state.userKYC.phone_no}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Mobile Number
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {this.state.userKYC.mobile_no}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Screening Risk
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp;
                            {this.state.userKYC.screening_risk ===
                              0 && <span> - </span>}
                            {this.state.userKYC.screening_risk ===
                              1 && <span> low </span>}
                            {this.state.userKYC.screening_risk ===
                              2 && <span> medium </span>}
                            {this.state.userKYC.screening_risk ===
                              3 && <span> high </span>}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Asliri rate
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {rate}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            TRX id
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {trx_id}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Created By
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {this.state.userKYC.created_by}
                          </div>
                          <div className="col-sm-2 margin-botHalf">
                            Action By
                          </div>
                          <div className="col-sm-10 margin-botHalf">
                            {' '}
                            : &nbsp; {this.state.userKYC.action_by}
                          </div>
                          <br />
                        </div>
                      </div>
                      {/* <div className="col-sm-3 margin-botHalf">
                      {this.state.userKYC.government_status === -1 && <div>
                        {
                          this.state.editStatus ? <Button variant="primary" onClick={this.handleStatus} style={{position: 'absolute', right: '10px'}}>Edit</Button>
                          :
                          <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            <Button variant="primary" onClick={() => this.updateKYC(idUser)}>Submit</Button>
                            <Button variant="danger" onClick={this.handleStatus}>Cancel</Button>
                          </div>
                        }
                      </div>}
                    </div> */}
                      <div
                        className="col-sm-12"
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          marginTop: '50px',
                        }}
                      >
                        <ButtonToolbar>
                          <span className="pr-4">
                            <Button
                              variant="primary"
                              onClick={() =>
                                this.getPhoto(
                                  this.state.userKYC.selfie_photo
                                )
                              }
                            >
                              Selfie
                            </Button>
                          </span>
                          <span className="pr-4">
                            <Button
                              variant="primary"
                              onClick={() =>
                                this.getPhoto(
                                  this.state.userKYC.id_photo
                                )
                              }
                            >
                              ID
                            </Button>
                          </span>
                          <span className="pr-4">
                            <Button
                              variant="primary"
                              onClick={() => this.getLiveness(idUser)}
                            >
                              Liveness Photo
                            </Button>
                          </span>
                          <span className="pr-4">
                            {this.state.userKYC.liveness_check ===
                              -1 &&
                              this.state.userKYC.status === 0 && (
                                <Button
                                  onClick={() =>
                                    this.handleShows('Liveness', 1)
                                  }
                                >
                                  Liveness
                                </Button>
                              )}
                            {this.state.userKYC.liveness_check ===
                              -1 &&
                              this.state.userKYC.status !== 0 && (
                                <Button variant="danger" disabled>
                                  Liveness
                                </Button>
                              )}
                            {this.state.userKYC.liveness_check ===
                              0 && (
                              <Button
                                onClick={() =>
                                  this.handleShows('Liveness', 1)
                                }
                              >
                                Liveness
                              </Button>
                            )}
                            {this.state.userKYC.liveness_check ===
                              1 && (
                              <Button variant="secondary" disabled>
                                Liveness
                              </Button>
                            )}
                          </span>
                          <span className="pr-4">
                            {rule !== 'cs' &&
                              this.state.userKYC.government_status ===
                                -1 &&
                              this.state.userKYC.status === 0 && (
                                <Button
                                  onClick={() =>
                                    this.handleShows('Goverment', 2)
                                  }
                                >
                                  Goverment
                                </Button>
                              )}
                            {rule !== 'cs' &&
                              this.state.userKYC.government_status ===
                                -1 &&
                              this.state.userKYC.status !== 0 && (
                                <Button variant="danger" disabled>
                                  Goverment
                                </Button>
                              )}
                            {/* {rule !== 'cs' &&  this.state.userKYC.government_status === 0 && <Button onClick={() => this.handleShows('Goverment', 2)}>Goverment</Button>} */}
                            {this.state.userKYC.government_status !==
                              -1 && (
                              <Button variant="secondary" disabled>
                                Goverment
                              </Button>
                            )}
                            {/* {this.state.userKYC.government_status === 1 && <Button variant="secondary" disabled>Goverment</Button>} */}
                          </span>
                          <span className="pr-4">
                            {this.state.userKYC.status === -1 && (
                              <Button variant="danger" disabled>
                                KYC Level 1
                              </Button>
                            )}
                            {rule !== 'cs' &&
                              ((this.state.userKYC.status === 1 &&
                                this.state.userKYC.kyc_level === 1) ||
                                this.state.userKYC.kyc_level === 2 ||
                                rule === 'cs') && (
                                <Button variant="secondary" disabled>
                                  KYC Level 1
                                </Button>
                              )}
                            {rule !== 'cs' &&
                              this.state.userKYC.nationality ===
                                'ID' &&
                              this.state.userKYC.kyc_level === 1 &&
                              this.state.userKYC.status === 0 &&
                              this.state.userKYC.liveness_check ===
                                1 &&
                              this.state.userKYC.government_status ===
                                1 && (
                                <Button
                                  variant="primary"
                                  onClick={() => this.handleShow('1')}
                                >
                                  KYC Level 1
                                </Button>
                              )}
                            {rule !== 'cs' &&
                              this.state.userKYC.nationality !==
                                'ID' &&
                              this.state.userKYC.kyc_level === 1 &&
                              this.state.userKYC.status === 0 &&
                              this.state.userKYC.liveness_check ===
                                1 &&
                              this.state.userKYC.government_status ===
                                1 && (
                                <Button
                                  variant="primary"
                                  onClick={() => this.handleShow('1')}
                                >
                                  KYC Level 1
                                </Button>
                              )}
                            {rule !== 'cs' &&
                              this.state.userKYC.nationality ===
                                'ID' &&
                              this.state.userKYC.kyc_level === 1 &&
                              this.state.userKYC.status === 0 &&
                              (this.state.userKYC.liveness_check !==
                                1 ||
                                this.state.userKYC
                                  .government_status !== 1) && (
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Must approve{' '}
                                      <strong>Liveness check</strong>{' '}
                                      and{' '}
                                      <strong>Goverment check</strong>
                                      .
                                    </Tooltip>
                                  }
                                >
                                  <Button variant="secondary">
                                    KYC Level 1
                                  </Button>
                                </OverlayTrigger>
                              )}
                            {this.state.nationality !== 'ID' &&
                              this.state.userKYC.kyc_level === 1 &&
                              this.state.userKYC.status === 0 &&
                              this.state.liveness_check !== 1 &&
                              this.state.government_status === 0 && (
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Must approve{' '}
                                      <strong>Liveness check</strong>.
                                    </Tooltip>
                                  }
                                >
                                  <Button variant="secondary">
                                    KYC Level 1
                                  </Button>
                                </OverlayTrigger>
                              )}
                          </span>
                          <span className="pr-4">
                            {rule !== 'cs' &&
                              this.state.premiumKYC &&
                              this.state.premiumKYC.status === -1 && (
                                <Button variant="danger" disabled>
                                  KYC Level 2
                                </Button>
                              )}
                            {rule !== 'cs' &&
                              this.state.premiumKYC &&
                              this.state.premiumKYC.status === 1 && (
                                <Button variant="secondary" disabled>
                                  KYC Level 2
                                </Button>
                              )}
                            {rule !== 'cs' &&
                              this.state.premiumKYC &&
                              this.state.premiumKYC.status === 0 && (
                                <Button
                                  variant="primary"
                                  onClick={() => this.handleShow('2')}
                                >
                                  KYC Level 2
                                </Button>
                              )}
                            {(rule === 'cs' ||
                              this.state.premiumKYC === '') && (
                              <Button variant="secondary" disabled>
                                KYC Level 2
                              </Button>
                            )}
                            {/* {this.state.userKYC.kyc_level === 2 && this.state.levelPremium === -1 && <Button variant="danger"  disabled>KYC Level 2</Button>}
                          {(this.state.userKYC.kyc_level === 1 && this.state.premiumKYC && this.state.levelPremium === 0) && <Button variant="primary" onClick={() => this.handleShow('2')}>KYC Level 2</Button>}
                          {(this.state.userKYC.kyc_level !== 2  || !this.state.premiumKYC || this.state.levelPremium === 1) && <Button variant="secondary"  disabled>KYC Level 2</Button>} */}
                          </span>
                        </ButtonToolbar>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="asliri" title="Asliri">
                    <AceEditor
                      width="50"
                      mode="json"
                      theme="github"
                      readOnly={true}
                      value={PrettyJSON(obj)}
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>KYC Level {this.state.level}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: 'center', display: 'grid' }}>
              <Button
                variant="primary"
                onClick={() => this.confirmKyc('1')}
              >
                Approve
              </Button>
              <br />
              <Button
                variant="danger"
                onClick={() => this.confirmKyc('-1')}
              >
                Reject
              </Button>
              <br />
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="left-modal">Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={this.state.reason}
                  onChange={(e) =>
                    this.setState({
                      reason: e.target.value,
                      required: false,
                    })
                  }
                />
                {this.state.required && (
                  <span
                    style={{
                      textAlign: 'left',
                      color: 'red',
                      display: 'block',
                    }}
                  >
                    Please insert reason before rejected
                  </span>
                )}
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.shows}
          onHide={this.handleCloses}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div
                className="col-sm-12 margin-botHalf"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {this.state.editStatus ? (
                  <Button
                    variant="primary"
                    onClick={this.handleStatus}
                  >
                    Edit
                  </Button>
                ) : (
                  <div>
                    <Button
                      variant="primary"
                      onClick={() => this.updateKYC(idUser)}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={this.handleStatus}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
              <div className="col-sm-4 margin-botHalf">Name</div>
              {this.state.editStatus ? (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  : &nbsp; {this.state.name}
                </div>
              ) : (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  <Form.Control
                    size="sm"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange('name')}
                    style={{ width: '50%' }}
                  />
                </div>
              )}
              {/* <div className="col-sm-8 margin-botHalf"> : &nbsp; {this.state.userKYCDetail.name}</div> */}
              <div className="col-sm-4 margin-botHalf">DOB</div>
              {this.state.editStatus ? (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  : &nbsp; {this.state.dob}
                </div>
              ) : (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  <Form.Control
                    size="sm"
                    type="text"
                    value={this.state.dob}
                    onChange={this.handleChange('dob')}
                    style={{ width: '50%' }}
                  />
                </div>
              )}
              <div className="col-sm-4 margin-botHalf">POB</div>
              {this.state.editStatus ? (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  : &nbsp; {this.state.pob}
                </div>
              ) : (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  <Form.Control
                    size="sm"
                    type="text"
                    value={this.state.pob}
                    onChange={this.handleChange('pob')}
                    style={{ width: '50%' }}
                  />
                </div>
              )}
              <div className="col-sm-4 margin-botHalf">ID Number</div>
              {this.state.editStatus ? (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  : &nbsp; {this.state.idNumber}
                </div>
              ) : (
                <div className="col-sm-8 margin-botHalf">
                  {' '}
                  <Form.Control
                    size="sm"
                    type="text"
                    value={this.state.idNumber}
                    onChange={this.handleChange('idNumber')}
                    style={{ width: '50%' }}
                  />
                </div>
              )}
              <div className="col-sm-4 margin-botHalf">ID Type</div>
              <div className="col-sm-8 margin-botHalf">
                {' '}
                : &nbsp;{' '}
                {this.state.userKYC.id_type === 0 ? (
                  <span>Identity Card</span>
                ) : (
                  <span>Passport</span>
                )}
              </div>
              <div className="col-sm-4 margin-botHalf">
                Asliri Rating
              </div>
              <div className="col-sm-8 margin-botHalf">
                {' '}
                : &nbsp; {rate}
              </div>
              <br />
              <br />
              <br />
            </div>
            <div style={{ textAlign: 'center', display: 'grid' }}>
              <Button
                variant="primary"
                onClick={() => this.liveGovt('1')}
              >
                Approve
              </Button>
              <br />
              <Button
                variant="danger"
                onClick={() => this.handleCloses()}
              >
                Cancel
              </Button>
              <br />
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}
