import clsx from 'clsx'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Badge } from "react-bootstrap";
import ReusableFormGenerator, { FIELD_TYPE } from '../../components/reusableFormGenerator'
import { userStatus } from './customers';
import ReusableListPage from '../../components/reusableListPage';
import { approveOTCUser, getDetailOTCUser, getLoginActivityOTCUser, rejectOTCUser, sendResetToEmail, updateProfile } from '../../otc_services/resources/user/user_service';
import Swal from 'sweetalert2';
import { constantDateFormat } from '../../helper';

export const listActivityStatus = [
  { id: 0, label: "Failed", theme: "danger" },
  { id: 1, label: "Success", theme: "success" },
]

const CustomersEdit = (props) => {
  const isEditMode = props.history.location.pathname.includes("edit")
  const itemsCountPerPage = 15
  
  const [activityStatusSource, setActivityStatusSource] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [totalItemCount, setTotalItemCount] = useState(0)

  const [inputs, setInputs] = useState({
    id: null,
    email: "",
    fullname: "",
    password: "",
    tokocrypto_uid: "",
    phone_number: "",
    twoFactorAuthenticationSecret: "",
    otp_code: "",
    forgot_token: "",
    forgot_token_expired: null,
    isTwoFactorAuthenticationEnabled: false,
    crypto_address: "",
    //status code
    //0 = pending
    //-1 = rejected
    //1 = approved
    status: null,
    last_logout: null,
    approvedBy: null,
    rejectedBy: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isActivityLoading, setIsActivityLoading] = useState(false)
  const fields = [
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Email",
        value: inputs.email,
        onChange: (evt) => setInputs({...inputs, email: evt.target.value}),
        placeholder: "Email",
        required: true,
        disabled: true
      },
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "UID",
        value: inputs.tokocrypto_uid,
        onChange: (evt) => setInputs({...inputs, tokocrypto_uid: evt.target.value}),
        placeholder: "UID",
        required: true,
        disabled: true
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Full Name",
        value: inputs.fullname,
        onChange: (evt) => setInputs({...inputs, fullname: evt.target.value}),
        placeholder: "Full Name",
        required: true,
        disabled: !isEditMode
      },
    ],
    [
      {
        type: FIELD_TYPE.TEXT_INPUT,
        label: "Phone Number",
        value: inputs.phone_number,
        onChange: (evt) => setInputs({...inputs, phone_number: evt.target.value}),
        placeholder: "Phone Number",
        required: true,
        disabled: !isEditMode
      },
      {
        type: FIELD_TYPE.DROPDOWN,
        label: "Status",
        value: inputs.status,
        onChange: (evt) => setInputs({...inputs, status: evt.target.value}),
        options:[
          { label: "- Status -", value: null },
          ...userStatus.map((status)=>({
            label: status.label,
            value: status.id
          }))
        ],
        placeholder: "Status",
        required: true,
        disabled: true
      },
    ],
    [
      {
        type: FIELD_TYPE.DATE,
        label: "Registered At",
        selected: inputs.created_at,
        onChange: (val) => setInputs({...inputs, created_at: val}),
        disabled: true
      }
    ]
  ]
  const getStatusSelected = (dataIndex) => {
    return listActivityStatus.find(user => user.id === dataIndex)
  }
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      className: "tableheh all-center",
      render: (dataIndex, data, index) => <span>{index + 1}</span>
    },
    {
      title: "Datetime",
      dataIndex: "created_at",
      className: "tableheh",
      render: (dataIndex) => dataIndex ? constantDateFormat(dataIndex) : '-'
    },
    {
      title: "Device",
      dataIndex: "device",
      className: "tableheh",
    },
    {
      title: "IP Address",
      dataIndex: "ip_address",
      className: "tableheh",
      render: (dataIndex) => dataIndex ? dataIndex.replace(/::ffff:/g,"") : '-'
    },
    {
      title: "Location",
      dataIndex: "location",
      className: "tableheh",
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "tableheh center",
      render: (dataIndex) => (getStatusSelected(dataIndex)&&
        <Badge bg={getStatusSelected(dataIndex).theme}>
          {getStatusSelected(dataIndex).label}
        </Badge>
      )
    },
  ]
  const getDetailUser = () => {
    setIsLoading(true)
      getDetailOTCUser(props.match.params?.id)
        .then((res)=>{
          if(res.data.data?.created_at) res.data.data.created_at = new Date(res.data.data.created_at)
          setInputs(res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(()=>{
          setIsLoading(false)
        })
  }
  const firstInit = () => {
    if(props.match.params?.id){
      getDetailUser()
      getListActivities(1)
    }
  }
  const getListActivities = (page) => {
    setIsActivityLoading(true)
    getLoginActivityOTCUser(props.match.params?.id, {limit: itemsCountPerPage, page})
      .then((res) => {
        res.data.data.data.forEach((d, index) => {
          d.no = ((page - 1) * itemsCountPerPage) + index
        })
        setActivityStatusSource(res.data.data.data)
        setTotalItemCount(res.data.data.total_data)
      })
      .catch((err)=>{
        console.log(err)
      })
      .finally(()=>{
        setIsActivityLoading(false)
      })
  }
  const approveOrReject = (status) => {
    Swal.fire({
      title: `Are you sure to ${status} this request?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${status} it!`
    }).then((result) => {
      if (result.isConfirmed) {
        if(status === "Approve") {
          setIsLoading(true)
          approveOTCUser(props.match.params?.id)
            .then(() => {
              Swal.fire(
                'Success!',
                `You has been successfully ${status} this user!!`,
                'success'
              )
            })
            .catch(err => {
              console.log(err)
            })
            .finally(()=>{
              setIsLoading(false)
              getDetailUser()
            })
        }
        else {
          setIsLoading(true)
          rejectOTCUser(props.match.params?.id)
            .then(() => {
              Swal.fire(
                'Success!',
                `You has been successfully ${status} this user!!`,
                'success'
              )
            })
            .catch(err => {
              console.log(err)
            })
            .finally(()=>{
              setIsLoading(false)
              getDetailUser()
            })
        }
      }
    })
  }
  const updateCustomer = () => {
    if(isEditMode){
      const payload = {
        fullname: inputs.fullname,
        phone_number: inputs.phone_number
      }
      setIsLoading(true)
      updateProfile(props.match.params?.id, payload)
        .then((res)=>{
          Swal.fire(
            'Success!',
            `You has been successfully edit this user!!`,
            'success'
          )
          props.history.push('/otc-finance/customers')
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>setIsLoading(false))
    }
  }
  useEffect(firstInit, [])
  const sendResetEmail = () => {
    setIsLoading(true)
    sendResetToEmail(props.match.params?.id)
      .then((res)=>{
        Swal.fire(
          'Success!',
          res.data.data,
          'success'
        )
      })
      .catch((err)=>{
        console.log(err)
      })
      .finally(()=>{
        setIsLoading(false)
      })
  }
  return (
    <Fragment>
      <ReusableFormGenerator 
        title={isEditMode ? "Edit Customer" : "View Customer"}
        rightTitle={
          <div>
            <button
              type="button"
              disabled={isLoading}
              className={`mr-3 btn btn-outline-primary btn-elevate kt-login__btn-outline-primary ${clsx(
                {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                }
              )}`}
              onClick={()=>sendResetEmail()}
            >
              Send Reset Email
            </button>
            {!isEditMode && (
              <Fragment>
                <button
                  type="button"
                  disabled={isLoading}
                  className={`mr-3 btn btn-success btn-elevate kt-login__btn-success ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                    }
                  )}`}
                  onClick={()=>approveOrReject("Approve")}
                >
                  Approve
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  className={`mr-3 btn btn-danger btn-elevate kt-login__btn-danger ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                    }
                  )}`}
                  onClick={()=>approveOrReject("Reject")}
                >
                  Reject
                </button>
              </Fragment>
            )}
          </div>
        }
        fields={fields}
        footer={
          <Fragment>
            <Link to="/otc-finance/customers">
              <Button variant="outline-danger" className="mr-3">Back</Button>
            </Link>
            {isEditMode ?
              <button
                type="button"
                disabled={isLoading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                  }
                )}`}
                onClick={()=>updateCustomer()}
              >
                Submit
              </button>
              :
              <Fragment>
                <Link to={`/otc-finance/customers/1/edit`}>
                  <Button variant="outline-primary" className="mr-3">Edit</Button>
                </Link>
              </Fragment>
            }
          </Fragment>
        }
        isSubmit={(d)=>{}}
      />
      {!isEditMode &&
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <h2><b>Login Activity</b></h2>
            <br />
          </div>
          <ReusableListPage 
            loading={isActivityLoading}
            dataSources={activityStatusSource}
            columns={columns}
            pagination={{
              activePage,
              itemsCountPerPage,
              totalItemCount,
              onChange: (pageNumber) => {
                getListActivities(pageNumber)
                setActivePage(pageNumber)
              }
            }}
          />
        </div>
      }
    </Fragment>
  )
}

export default CustomersEdit