import React, { ButtonHTMLAttributes, ChangeEventHandler, DetailedHTMLProps, Fragment, InputHTMLAttributes, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import ReactLoading from 'react-loading';
import SelectDate, { DatetimeProps } from '../datetime';
import { Button, Table, Tabs, Tab, TabsProps } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Pagination, { ReactJsPaginationProps } from 'react-js-pagination';
import Checkbox from '../checkbox';
import { ReusableTableColumn } from '../reusableTable';
import { EventKey } from '@restart/ui/esm/types';

require('bootstrap/scss/bootstrap.scss');

export type FILTER_TYPE = 'INPUT_TEXT' | 'DATE_PICKER' | 'DROP_DOWN'

export type TRIGGER_BTN_TYPE = 'BUTTON' | 'LINK'

export interface RowSelection<T> {
  selected: Array<T>;
  key: keyof T;
  setSelected: (selectedList: Array<T>) => void;
}

export interface FieldTypeDropdown<T extends string> { 
  options: Array<{ value: T; label: ReactNode }> ;
  value: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

export type ReusableListFilter<T extends string = string> = 
{ type: "DATE_PICKER" } & DatetimeProps | 
{ type: "DROP_DOWN" } & FieldTypeDropdown<T> | 
{ type: "INPUT_TEXT" } & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "type">

export type ReusableListSecondFilter = 
{
  type: "BUTTON";
  label?: ReactNode;
  variants?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
} & Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "type"> |
{
  type: "LINK";
  label?: ReactNode;
} & LinkProps

export interface ReusableTableTabs<T> {
  eventKey?: EventKey;
  title: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  dataSources: Array<T>;
  columns: Array<ReusableTableColumn<T>>;
  pagination?: Omit<ReactJsPaginationProps, "totalItemsCount"> & { itemsCountPerPage?: number; totalItemCount: number; };
}

export interface ReusableListLabelProps {
  label: ReactNode;
  value: ReactNode;
}

export interface ReusableListPageProps<T, U extends string> {
  loading?: boolean;
  rowSelection?: RowSelection<T>;
  dataSources: Array<T>;
  columns: Array<ReusableTableColumn<T>>;
  pagination?: Omit<ReactJsPaginationProps, "totalItemsCount"> & { itemsCountPerPage?: number; totalItemCount: number; };
  filters?: Array<ReusableListFilter<U>>;
  secondFilters?: Array<ReusableListSecondFilter>;
  triggerBtns?: Array<ReusableListSecondFilter>;
  tabs?: Array<ReusableTableTabs<T>>;
  tabsProps?: TabsProps;
  labels?: Array<ReusableListLabelProps>;
  rightLabels?: ReactNode;
  middleComponent?: ReactNode;
  footerComponent?: ReactNode;
}

const ReusableListPage = <T extends object, U extends string = string>(props: ReusableListPageProps<T, U>) => {
  const conditionalFilterByType = (filter: ReusableListFilter<U>) => {
    if (filter.type === 'INPUT_TEXT') {
      const { type, ...anotherType } = filter
      return (
        <input
          style={{ fontSize: '0.8rem' }}
          type='text'
          className='form-control mr-2'
          {...anotherType}
        />
      );
    }
    else if (filter.type === 'DATE_PICKER'){
      const { type, ...anotherType } = filter
      return <SelectDate {...anotherType as DatetimeProps} />;
    }
    else if (filter.type === 'DROP_DOWN') {
      const { options, value, onChange } = filter
      return (
        <select className='form-control font-8rem' value={value} onChange={onChange}>
          {(options || []).map((option, indexOption) => (
            <option key={indexOption} value={option.value || ''}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }
    else return null;
  };

  const conditionalTriggerButtonByType = (triggerBtn: ReusableListSecondFilter, index: number) => {
    if (triggerBtn.type === 'BUTTON'){
      const { type, label, ...anotherProps } = triggerBtn
      return (
        <button
          key={index}
          className={`btn btn-outline-${anotherProps.variants ?? 'warning'} mr-3 font-8rem`}
          type='button'
          style={{ cursor: (anotherProps).disabled ? 'default' : undefined }}
          // style={{ width: '20%' }}
          {...anotherProps}
        >
          {label}
        </button>
      );
    }
    else if (triggerBtn.type === 'LINK')
      return (
        <Link
          key={index}
          className='mr-3'
          // style={{ width: '21%' }}
          {...triggerBtn}
        >
          <Button
            variant='outline-primary'
            className='font-8rem'
            style={{ width: '100%' }}
          >
            {triggerBtn.label}
          </Button>
        </Link>
      );
    else return null;
  };

  const addOrRemoveItem = (item: T) => {
    if (props.rowSelection) {
      const findExisting = props.rowSelection.selected.find(
        (s) => s[props.rowSelection!!.key] === item[props.rowSelection!!.key]
      );
      if (findExisting)
        props.rowSelection.setSelected(
          props.rowSelection.selected.filter(
            (s) => s[props.rowSelection!!.key] !== item[props.rowSelection!!.key]
          )
        );
      else
        props.rowSelection.setSelected([...props.rowSelection.selected, item]);
    }
  };

  const checkItemIsExisting = (id: T[keyof T]) => {
    if (props.rowSelection) {
      const findExisting = props.rowSelection.selected.find(
        (s) => s[props.rowSelection!!.key] === id
      );
      if (findExisting) return true;
      return false;
    } else return false;
  };

  const checkIsHeaderChecked = () => {
    if (props.rowSelection) {
      const isEvery = props.dataSources.every(
        (data) =>
          props.rowSelection!!.selected
            .map((select) => select[props.rowSelection!!.key])
            .indexOf(data[props.rowSelection!!.key]) > -1
      );
      if (isEvery) return true;
      return false;
    } else return false;
  };

  const onChangeHeaderCheckbox = () => {
    if (props.rowSelection) {
      if (checkIsHeaderChecked()) props.rowSelection.setSelected([]);
      else props.rowSelection.setSelected(props.dataSources);
    }
  };

  const loadingRenderingHandler = (tab: ReusableListPageProps<T, U>, totalTabs?: number) => {
    if (tab.loading)
      return (
        <tbody>
          <tr>
            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} colSpan={props.columns?.length ?? 0}>
              <ReactLoading
                type={'bars'}
                color={'#5867dd'}
                height={'20%'}
                width={'20%'}
              />
            </td>
          </tr>
        </tbody>
      );
    else
      return (
        <tbody>
          {tab.dataSources.length > 0 ? (
            tab.dataSources.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {props.rowSelection && (
                  <td>
                    <Checkbox
                      type='checkbox'
                      color='primary'
                      name='checkbox-head'
                      handleClick={() => addOrRemoveItem(row)}
                      isChecked={checkItemIsExisting(
                        row[props.rowSelection.key]
                      )}
                    />
                  </td>
                )}
                {tab.columns.map((column, columnIndex) => {
                  const { style, ...anotherColumn } = column
                  return (
                    <td key={columnIndex} style={style}>
                      {anotherColumn.render
                        ? anotherColumn.render(
                            row[anotherColumn.dataIndex],
                            row,
                            getRowIndex(rowIndex)
                          )
                        : row[anotherColumn.dataIndex] as any}
                    </td>
                  )
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td
                className='text-center text-danger'
                colSpan={totalTabs || tab.columns.length}
              >
                Data Not Found
              </td>
            </tr>
          )}
        </tbody>
      );
  };
  const getRowIndex = (rowIndex: number) => {
    if (props.pagination) {
      const { activePage, itemsCountPerPage } = props.pagination;
      return (activePage - 1) * (itemsCountPerPage ?? 0) + rowIndex;
    } else return rowIndex;
  };
  const tableComponent = () => {
    if (props.tabs)
      return (
        <Tabs {...props.tabsProps}>
          {props.tabs.map((tab, index) => (
            <Tab
              key={index}
              eventKey={tab.eventKey}
              title={tab.title}
              disabled={tab.disabled}
            >
              <Table striped responsive>
                <thead>
                  <tr>
                    {tab.columns.map((column, columnIndex) => {
                      const {
                        dataIndex,
                        title,
                        render,
                        ...otherColumn
                      } = column;
                      return (
                        <th key={columnIndex} {...otherColumn}>
                          {title}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {loadingRenderingHandler(tab)}
              </Table>
              {tab.pagination && (
                <div className='d-flex justify-content-center align-items-center pagination-data'>
                  <Pagination
                    activePage={tab.pagination.activePage}
                    itemsCountPerPage={tab.pagination.itemsCountPerPage}
                    itemClass='page-item'
                    linkClass='page-link'
                    totalItemsCount={tab.pagination.totalItemCount}
                    pageRangeDisplayed={5}
                    onChange={tab.pagination.onChange}
                  />
                </div>
              )}
            </Tab>
          ))}
        </Tabs>
      );
    else
      return (
        <Fragment>
          <Table striped responsive>
            <thead>
              <tr>
                {props.rowSelection && (
                  <th>
                    <Checkbox
                      type='checkbox'
                      color='primary'
                      name='checkbox-head'
                      handleClick={() => onChangeHeaderCheckbox()}
                      isChecked={checkIsHeaderChecked()}
                    />
                  </th>
                )}
                {props.columns.map((column, index) => {
                  const { dataIndex, title, render, ...otherColumn } = column;
                  return (
                    <th key={index} {...otherColumn}>
                      {title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {loadingRenderingHandler(props, props.columns.length + 1)}
          </Table>
          {props.pagination && (
            <div className='d-flex justify-content-center align-items-center pagination-data'>
              <Pagination
                activePage={props.pagination.activePage}
                itemsCountPerPage={props.pagination.itemsCountPerPage}
                itemClass='page-item'
                linkClass='page-link'
                totalItemsCount={props.pagination.totalItemCount}
                pageRangeDisplayed={5}
                onChange={props.pagination.onChange}
              />
            </div>
          )}
        </Fragment>
      );
  };
  return (
    <div className='head' style={{ fontSize: '10px' }}>
      {(props.filters || props.secondFilters) && (
        <div className='kt-portlet'>
          <div className='kt-portlet__body pad-10-20'>
            <div className='kt-portlet__preview'>
              <div className='row'>
                {(props.filters || []).map((filter, index) => (
                  <div className='col-md-2' key={index}>
                    {conditionalFilterByType(filter)}
                  </div>
                ))}
                <div className={`col-md-${12 - (props.filters ?? []).length * 2}`}>
                  <div className='d-flex'>
                    {(props.triggerBtns || []).map((triggerBtn, index) =>
                      conditionalTriggerButtonByType(triggerBtn, index)
                    )}
                  </div>
                </div>
              </div>
              {props.secondFilters && (
                <div className='row mt-2' style={{ float: 'right' }}>
                  <div className='d-flex'>
                    {(props.secondFilters || []).map((secondFilter, index) =>
                      conditionalTriggerButtonByType(secondFilter, index)
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <div className='kt-flex_row p-3'>
                {props.labels && (
                  <div style={{ width: 300 }}>
                    {props.labels.map((label, index) => (
                      <div
                        key={index}
                        className='kt-flex_row p-1'
                        style={{ gap: 15 }}
                      >
                        <div className='kt-font-boldest'>{label.label}</div>
                        <div>{label.value}</div>
                      </div>
                    ))}
                  </div>
                )}
                {props.rightLabels}
              </div>
              {props.middleComponent}
              {tableComponent()}
              {props.footerComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableListPage;
