// import React, { useState, Component } from "react";
import React, { Component } from "react";
// import { Formik } from "formik";
// import { NotificationManager } from 'react-notifications';
// import 'react-notifications/lib/notifications.css';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core"
import { toAbsoluteUrl } from "../../../_metronic";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login, reset } from "../../crud/login";
import Capthca from '../../components/captcha';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { createBrowserHistory } from 'history';
import { checkPassPrompt } from "../../helper";

const history =  createBrowserHistory();

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      passwordPlaceholder: "Password",
      loading: false,
      captchaSuccess: false,
      loginFailed: false,
      otpId: '',
      otp: '',
      resetCapctha: false,
      captchaValue: null,
      formLogin: true,
      formReset: false,
      resetPassword: '',
      confirmNewPassword: '',
      promptAccess: process.env.REACT_APP_ENV === 'demo' ? true : false, // should be false on prod and dev
      // promptAccess: true,
      userPrompt: 'dev',
      usernamePrompt: '',
      pwPrompt: '',
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  isSubmit = event => {
    
    event.preventDefault()
    let data = {
      "email" : this.state.email,
      "password" : this.state.password,
      "g-recaptcha-response" : this.state.captchaValue || "demo",
    }
    login(data)
    .then(res => {
      const token = res.data.data.token;
      const rule = res.data.data.user.role;
      const userMail = res.data.data.user.email;
      // Store data local
      localStorage.setItem('token', token);
      localStorage.setItem('rule', rule);
      localStorage.setItem('user_mail', userMail);
      if(res.data.data.user.status === -1 ){
        this.setState({
          formLogin: false,
          formReset: true,
          loginFailed: false
        })
        // this.resetPassword(token);
      }else{
        // Store data redux
        this.props.login(token);
        history.push("/dashboard");
        window.location.reload();
      }
    })
    .catch(e => {
      if (e.response) {
        if(e.response.data.code){
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
      this.setState({
        userKYC:[],
        loading: false,
        loginFailed: true,
        captchaSuccess:false,
        resetCapctha: true
      })
    }) 
  }

  onCapthcaSuccess() {
    this.setState({
        captchaSuccess: true,
        resetCapctha: false,
        captchaValue: document.getElementById("g-recaptcha-response").value
    });
  }

  resetPassword(){
    let token = localStorage.getItem("token")    
    let data = {
      "password" : this.state.resetPassword,
      "confirm_password" : this.state.confirmNewPassword,
    }
    reset(token, data)
    .then(res => {
      // Store data redux
      this.props.login(token);
      history.push("/dashboard");
      window.location.reload();
    })
    .catch(e => {
      if (e.response) {
        if(e.response.data.code){
          alert(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
      this.setState({
        userKYC:[],
        loading: false,
        captchaSuccess:false,
        resetCapctha: true
      })
    }) 
  }

  createCookie(cookieName,cookieValue)
  {
    var date = new Date();
    date.setTime(date.getTime()+(1*24*60*60*1000));
    document.cookie = cookieName + "=" + cookieValue + "; expires=" + date.toGMTString();
  }
  accessCookie(cookieName)
  {
    var name = cookieName + "=";
    var allCookieArray = document.cookie.split(';');
    for(var i=0; i<allCookieArray.length; i++)
    {
      var temp = allCookieArray[i].trim();
      if (temp.indexOf(name) === 0)
      return temp.substring(name.length,temp.length);
    }
    return "";
  }
  checkCookie()
  {
    var user = this.accessCookie("testCookie");
    if (user!=="")
    alert("Welcome Back " + user + "!!!");
    else
    {
      user = prompt("Please enter your name");
      var num = prompt("How many days you want to store your name on your computer?");
      if (user!=="" && user!=null)
      {
      this.createCookie("testCookie", user, num);
      }
    }
  }
  isPrompt = event => {
    event.preventDefault()
    // console.log('user', this.state.usernamePrompt);
    // console.log('pass', this.state.pwPrompt);
    var user = this.accessCookie("testCookie");
    if(this.state.usernamePrompt === this.state.userPrompt && checkPassPrompt(this.state.pwPrompt)){
      user = this.state.usernamePrompt;
      this.setState({
        promptAccess: false,
      })
      this.createCookie("testCookie", user);
    }else{
      this.setState({
        promptAccess: true,
        usernamePrompt: '',
        pwPrompt: '',
      })
      alert('Prompt False')
    }
  }

  render(){    
    document.getElementById("g-recaptcha-response");
    var user = this.accessCookie("testCookie");
    if(this.state.promptAccess){
      if (user !== "")
      {
        return(
          <>
            <div className="kt-login__body">
              <div className="kt-login__form">
                <div className="kt-login__title">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/tokocrypto-logo.png")}
                  />
                  <br />
                </div>
                <h3 style={{display: 'flex', justifyContent: 'center'}}>
                  {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                  Sign In To Admin
                  {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
                </h3>
    
                {this.state.loginFailed ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">failed to login</div>
                    </div>
                  ) : null
                }
    
                
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  {this.state.formLogin && !this.state.formReset && <div>
                    <div className="form-group">
                      <TextField
                        type="email"
                        label="Email"
                        margin="normal"
                        className="kt-width-full"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange('email')}
                      />
                    </div>
  
                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label="Password"
                        className="kt-width-full"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                      />
                    </div>
  
                    {/* <div className="form-group captcha-position">
                      <Capthca
                        reset={this.state.resetCapctha}
                        onCapthcaSuccess={() => this.onCapthcaSuccess()} />
                    </div> */}
                  </div> }
  
                  {!this.state.formLogin && this.state.formReset && <div>
                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label="New Password"
                        className="kt-width-full"
                        name="resetPassword"
                        value={this.state.resetPassword}
                        onChange={this.handleChange('resetPassword')}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label="Confirm New Password"
                        className="kt-width-full"
                        name="confirmNewPassword"
                        value={this.state.confirmNewPassword}
                        onChange={this.handleChange('confirmNewPassword')}
                      />
                    </div>
                  </div>}
  
                  <div className="kt-login__actions" style={{justifyContent: 'center'}}>
                      {this.state.loading === false && this.state.formLogin === true && this.state.formReset === false && <button
                          type="submit"
                          // disabled={process.env.REACT_APP_ENV === "demo" ? false : !this.state.captchaSuccess}
                          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                            {
                              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                            }
                          )}`}
                          style={{paddingRight: "2.5rem"}}
                        >
                          Sign In
                        </button>}
                        {this.state.loading === false && this.state.formLogin === false && this.state.formReset === true && <button id="m_login_signin_submit"
                              onClick={() => this.resetPassword() }
                              className="btn btn-primary">
                              Verify
                        </button>}
                  </div>
                </form> 
    
              </div>
            </div>
          </>
        )
      }
      else
      {
        return(
          <>
            <div className="kt-login__body">
              <div className="kt-login__form">
                <div className="kt-login__title">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/tokocrypto-logo.png")}
                  />
                  <br />
                </div>
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">Secret Promt Login</div>
                </div>
                <form className="m-login__form m-form" onSubmit={this.isPrompt}>
                  <div className="form-group">
                    <TextField
                      type="text"
                      label="Username"
                      margin="normal"
                      className="kt-width-full"
                      name="username"
                      value={this.state.usernamePrompt}
                      onChange={this.handleChange('usernamePrompt')}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="Password"
                      className="kt-width-full"
                      name="pwPrompt"
                      value={this.state.pwPrompt}
                      onChange={this.handleChange('pwPrompt')}
                    />
                  </div>
                  <div className="kt-login__actions" style={{justifyContent: 'center'}}>
                    <button
                      type="submit"
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                      style={{paddingRight: "2.5rem"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )
      }
    }else{
      return(
        <>
          <div className="kt-login__body">
            <div className="kt-login__form">
              <div className="kt-login__title">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/tokocrypto-logo.png")}
                />
                <br />
              </div>
              <h3 style={{display: 'flex', justifyContent: 'center'}}>
                {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                Sign In To Admin
                {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
              </h3>
  
              {this.state.loginFailed ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">failed to login</div>
                  </div>
                ) : null
              }
  
              
              <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                {this.state.formLogin && !this.state.formReset && <div>
                  <div className="form-group">
                    <TextField
                      type="email"
                      label="Email"
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange('email')}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="Password"
                      className="kt-width-full"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange('password')}
                    />
                  </div>

                  {/* <div className="form-group captcha-position">
                    <Capthca
                      reset={this.state.resetCapctha}
                      onCapthcaSuccess={() => this.onCapthcaSuccess()} />
                  </div> */}
                </div> }

                {!this.state.formLogin && this.state.formReset && <div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="New Password"
                      className="kt-width-full"
                      name="resetPassword"
                      value={this.state.resetPassword}
                      onChange={this.handleChange('resetPassword')}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="Confirm New Password"
                      className="kt-width-full"
                      name="confirmNewPassword"
                      value={this.state.confirmNewPassword}
                      onChange={this.handleChange('confirmNewPassword')}
                    />
                  </div>
                </div>}

                <div className="kt-login__actions" style={{justifyContent: 'center'}}>
                    {this.state.loading === false && this.state.formLogin === true && this.state.formReset === false && <button
                        type="submit"
                        // disabled={process.env.REACT_APP_ENV === "demo" ? false : !this.state.captchaSuccess}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                          {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                          }
                        )}`}
                        style={{paddingRight: "2.5rem"}}
                      >
                        Sign In
                      </button>}
                      {this.state.loading === false && this.state.formLogin === false && this.state.formReset === true && <button id="m_login_signin_submit"
                            onClick={() => this.resetPassword() }
                            className="btn btn-primary">
                            Verify
                      </button>}
                </div>
              </form>
            </div>
          </div>
        </>
      )
    }
  }
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);