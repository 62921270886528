import axios from 'axios';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
export const uidConverterUrl =
  process.env.REACT_APP_ENV === 'demo'
    ? 'https://service-demo.tcdx.id/'
    : 'https://service.tcdx.id/';

const axiosInstance = () => {
  const newInstance = axios.create({
    baseURL: uidConverterUrl,
  });

  newInstance.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem('token');
    config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon';
    return config;
  });

  newInstance.interceptors.response.use(
    (config) => config,
    (err) => {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${err.response.status} message: ${err.response.data.message}`
          );
        }
      }

      return Promise.reject(err);
    }
  );

  return newInstance;
};

export default axiosInstance;
