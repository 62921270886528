import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import ReactLoading from 'react-loading';
import moment from 'moment';
import actions from "../../crud/tkowebsite";
import { Button, Table, Badge, ButtonToolbar, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
// import clsx from "clsx";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class Tag extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      data: [],
      totalData: 0,
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      postPerPage: 15,
      idTag: 0,
      types: 'create',
      title: '',
      status: '1',
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(id, type) {
    this.setState({ show: true, idTag: id, types: type });
    if(id){
      this.getDetail(id);
    }
  }

  handlePageChangeHistory = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getTags()
    })
  }

  dateTime = date => {
    var fmtDate = moment(date).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getTags() {
    this.setState({
      loading: true
    })

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }

    actions.fetchTag(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data,
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        dataReward:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getDetail(id){
    this.setState({
      loading: true
    })

    actions.fetchDetailTag(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
        title: res.data.data.title,
        status: res.data.data.status,
      })
      this.getTags();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        title:'',
        status:'1',
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  isSubmit = event => {
    const dataTag = {
      status: this.state.status.toString(),
      title: this.state.title,
    }
    event.preventDefault();
    if(this.state.types === 'create'){
      this.addTag(dataTag);
    }
    else{
      this.editTag(dataTag);
    }
  }

  addTag(dataTag){
    this.setState({
      loading: true
    })

    actions.postTag(this.getToken(), dataTag)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new tag success");
      }
      this.setState({
        loading: false,
        show: false,
        title:'',
        status:'1',
      })
      this.getTags();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        show: false,
        title:'',
        status:'1',
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  editTag(dataTag){
    this.setState({
      loading: true
    })
    actions.updateTag(this.getToken(), this.state.idTag, dataTag)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Update is success");
      }
      this.setState({
        loading: false,
        show: false,
        title:'',
        status:'1',
      })
      this.getTags();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        show: false,
        title:'',
        status:'1',
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  
  componentDidMount(){
    this.getTags();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getTags()
  };

  render(){
    const statusType = [
      {
        id: "1",
        name: "Enabled",
      },
      {
        id: "0",
        name: "Disabled",
      }
    ];
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body pad-10-20">
            <div className="kt-portlet__preview">
              <Link to="/tko-website/article" className="mr-3">
                <Button variant="outline-warning" className="font-8rem">Back To Article</Button>
              </Link>
              <Button
                style={{width: '10%'}}
                variant="outline-primary"
                className="font-8rem"
                onClick={() => this.handleShow(null, 'create')}
              >
                Add Tag
              </Button>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle', width: '4%' }}>
                        No &nbsp;
                      </th>
                      <th className="tableheh" style={{ width: '29%' }}>
                        Article Tag
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        created At &nbsp;
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh center">
                        Status
                      </th>
                      <th className="tableheh center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="6"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.data.map((row,index) => (
                        <tr key={index}>
                          <td className="all-center">{index+1}</td>
                          <td>{row.title}</td>
                          <td>{this.dateTime(row.created_date)}</td>
                          <td className="center align-middle">
                            {row.status === '1' ?
                              // <Badge bg="success" onClick={() => this.handleShow(row.id, row.status)} style={{cursor: 'pointer'}}>
                              <Badge bg="success">
                                Enabled
                              </Badge>
                            :
                              <Badge bg="warning">
                                Disabled
                              </Badge>
                            }
                          </td>
                          <td className="align-middle">
                          <ButtonToolbar style={{ justifyContent: 'center'}}>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Edit Tag
                                </Tooltip>
                              }
                            >
                              <span className="btn-action" onClick={() => this.handleShow(row.id, 'update')}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                              </span>
                            </OverlayTrigger>
                            &nbsp; | &nbsp;
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Delete Tag
                                </Tooltip>
                              }
                            >
                              <span className="btn-action" onClick={() => this.handleDelete(row.id)}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                              </span>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChangeHistory}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
                <Modal.Title>{this.state.types === 'create' ? 'Add Tags' : 'Update Tags'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="m-login__form m-form" onSubmit={this.isSubmit}>
              <div className="form-group" style={{marginBottom: '15px'}}>
                <label className="col-md-6 p-0">Name Tag</label>
                <input
                style={{fontSize: '0.8rem'}}
                type="text" 
                className="form-control mr-2" 
                name="search" 
                placeholder="Name Tag" 
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })} />
              </div>
              <div className="form-group">
                <label className="col-md-6 p-0">Select Status</label>
                <div className="input-group">
                  <select className="form-control font-8rem" onChange={(e) => this.setState({ status: e.target.value })} placeholder="status">
                    {/* <option disabled selected={this.state.status === '' ? true : false}>status</option> */}
                    {statusType.map((e) => (
                      <option key={e.id} value={e.id} selected={e.id === this.state.status ? true : false} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                <Button variant="outline-danger" className="mr-3" onClick={() => this.handleClose()}>Cancel</Button>
                <button
                  type="submit"
                  style={{width: '20%'}}
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                >
                  {this.state.types === 'create' ? 'Add' : 'Update'}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}