import React from "react";
import actions from "../../crud/priceAlert";
import { toast } from 'react-toastify';
import { Form, FormControl, InputGroup } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css'; 
// import _ from 'lodash';
import clsx from "clsx";
import { createBrowserHistory } from 'history';

const history =  createBrowserHistory();

export default class PriceAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      up: 0,
      down: 0,
      intervalType: null,
      selectedCoin: [
        {
          coin: '',
          pairing: '',
          group_settings: '',
        }
      ],
      listCoinJson: [],
      listCoin: [],
      disabledCoin: {},
    }
  }

  emptyState(){
    this.setState({
      up: 0,
      down: 0,
      intervalType: null,
      selectedCoin: [
        {
          coin: '',
          pairing: '',
          group_settings: '',
        }
      ],
      listCoinJson: [],
      listCoin: [],
      disabledCoin: {},
    })
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getCoin(){
    this.setState({
      loading: true
    })

    actions.fetchCoinPairing(this.getToken())
    .then(res => {
      const alldata = res.data.data;
      const objectData = Object.keys(alldata);
      this.setState({
        loading: false,
        listCoin: objectData || [],
        listCoinJson: alldata || []
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    }) 
  }

  getData(){
    this.setState({
      loading: true
    })

    actions.fetchPriceData(this.getToken())
    .then(res => {
      const alldata = res.data.data
      const allcoin = alldata.coin;

      let makeCoin = [];
      allcoin.forEach(item => {
        let makeData = {
          coin: item.coin,
          pairing: item.pairing,
          group_settings: item.group_settings,
        }
        makeCoin.push(makeData);
      });
      
      this.checkIfSameWithState(makeCoin);
      this.setState({
        loading: false,
        up: alldata.percentageUp,
        down: alldata.percentageDown,
        intervalType: alldata.interval,
        selectedCoin: makeCoin.length > 0 ? makeCoin : this.state.selectedCoin,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    }) 
  }

  isSubmit = event => {
    event.preventDefault();
    const checkPairCoin = this.checkIfEmptyState();
    let validasiStatus = true;
    if(this.state.eventType !== ''){
      validasiStatus = checkPairCoin ? true : false;
    }

    if(validasiStatus){
      alert('Please, fill out the form completely');
    }else{
      const submitData = {
        percentageUp: parseFloat(this.state.up),
        percentageDown: parseFloat(this.state.down),
        interval: parseFloat(this.state.intervalType),
        coin: this.state.selectedCoin
      }
      this.addDataPriceAlert(submitData);
    }
  }

  addDataPriceAlert(submitData){
    this.setState({
      loading: true
    })

    actions.updatePriceAlert(this.getToken(), submitData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new admin success");
      }
      // this.emptyState();
      this.getData();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getCoin();
    this.getData();
  }

  handleAdd(){
    const _fields = [...this.state.selectedCoin];
    _fields.push({ coin: '', pairing: '' });
    this.setState({
      selectedCoin: _fields,
    });
  }

  handleRemove(i){
    const _fields = [...this.state.selectedCoin];
    _fields.splice(i, 1);
    this.setState({
      selectedCoin: _fields,
    });
  }

  onChangeListCoin(i, e){
    const coinl = this.state.selectedCoin;
    const _coin = [...coinl];
    _coin[i].coin = e.target.value;
    this.checkIfSameWithState();
    this.setState({
      selectedCoin: _coin,
    })
  }

  onChangeListPair(i, e){
    const _pair = [...this.state.selectedCoin];
    _pair[i].pairing = e.target.value;
    this.setState({
      selectedCoin: _pair,
    })
    this.checkIfSameWithState();
  }

  onChangeGroupSetting(i, e){
    const _pair = [...this.state.selectedCoin];
    _pair[i].group_settings = e.target.value;
    this.setState({
      selectedCoin: _pair,
    })
    this.checkIfSameWithState();
  }

  checkIfEmptyState(){
    const _fields = this.state.selectedCoin;
    let status = false;
    _fields.forEach(element => {
      if(element.coin === '' || element.pairing === '') {
        status = true;
      }
    });
    return status;
  }

  checkIfSameWithState(coinSend = []){
    const _fields = coinSend.length > 0 ? coinSend : this.state.selectedCoin;
    let makelistdisabled = {};
    _fields.forEach(element => {
      makelistdisabled[element.coin] = makelistdisabled[element.coin] ? {...makelistdisabled[element.coin]} : {};
      if(element.pairing !== '') makelistdisabled[element.coin][element.pairing] = true;
    });
    const savedata = makelistdisabled;
    this.setState({
      disabledCoin: savedata,
    });
  }

  render(){
    const listInterval = [
      {
        id: 24,
        name: '24H',
      },
      {
        id: 12,
        name: '12H',
      },
      {
        id: 4,
        name: '4H',
      },
      {
        id: 1,
        name: '1H',
      },
    ];

    const groupSetting = [
      {
        id: "all",
        name: 'ALL',
      },
      {
        id: "local_groups",
        name: 'Local Groups',
      },
      {
        id: "int_groups",
        name: 'International Groups',
      },
    ];

    return (
      <div className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <label htmlFor="basic-url">Percentage Up</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="0"
                        type="number"
                        min='0'
                        max='100'
                        step="0.1"
                        value={this.state.up}
                        onChange={(e) => this.setState({ up: e.target.value })}
                      />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="col-md-4 col-sm-12">
                  <label htmlFor="basic-url">Percentage Down</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="0"
                        type="number"
                        min='0'
                        max='100'
                        step="0.1"
                        value={this.state.down}
                        onChange={(e) => this.setState({ down: e.target.value })}
                      />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Select Interval</label>
                      <div className="input-group">
                          <select className="form-control" onChange={(e) => this.setState({ intervalType: e.target.value })} placeholder="Select Data">
                              <option disabled defaultValue={this.state.intervalType === null ? true : false}>Pilih Salah Satu</option>
                              {listInterval.map((e) => (
                                <>
                                  <option key={e.id} value={e.id} selected={e.id === this.state.intervalType ? true : false} defaultValue={e.id === this.state.intervalType ? true : false}>{e.name}</option>
                                </>
                              ))}
                          </select>
                      </div>
                    </div>
                  </div>
                </div>
                <Form.Group>
                  <Form.Label>Coin</Form.Label>
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <button type="button" onClick={() => this.handleAdd()} className="btn btn-sm btn-outline-success">Add Coin</button>
                    </div>
                    <div className="col-md-12">
                      {this.state.selectedCoin.map((element, i) => {
                          return (
                            <>
                              <div className="row mt-2" key={i}>
                                <div className="col-md-2">
                                  <button type="button" onClick={() => this.handleRemove(i)} className="btn btn-sm btn-outline-danger mt-1">Remove</button>
                                </div>
                                <div className="col-md-3">
                                  <select className="form-control" onChange={(e) => this.onChangeListCoin(i, e)}>
                                    <option selected={element.coin === '' ? true : false} disabled>Select Coin </option>
                                    {this.state.listCoin.map(coin => (
                                      <option value={coin} selected={coin === element.coin ? true : false}>{coin}</option>  
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  {element.coin && (
                                    <>
                                      {this.state.listCoinJson[element.coin] && (
                                        <select className="form-control" onChange={(e) => this.onChangeListPair(i, e)}>
                                          <option selected={element.pairing === '' ? true : false} disabled>Select Pairing</option>
                                          {this.state.listCoinJson[element.coin].map(pair => (
                                            <option selected={element.pairing === pair.parsing ? true : false} value={pair.parsing} disabled={this.state.disabledCoin[element.coin][pair.parsing] ? true : false}>{pair.parsing}</option>  
                                          ))}
                                        </select>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <select className="form-control" onChange={(e) => this.onChangeGroupSetting(i, e)}>
                                    <option selected={(element.group_settings === '' || !element.group_settings ) ? true : false} disabled>Select Button Type</option>
                                    {groupSetting.map(e => (
                                      <option value={e.id} selected={e.id === element.group_settings ? true : false}>{e.name}</option>  
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </>
                          );
                      })}
                    </div>
                  </div>
                </Form.Group>
                <br />
                <br />
                <div className="d-flex justify-content-center align-items-center">
                  <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      Submit
                  </button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}