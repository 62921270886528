import { useFormik } from "formik"
import { FC, Fragment } from "react"
import { Modal, Tabs, Tab } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import FormWithFormik, { FileUpload } from "../../components/reusableFormWithFormik"
import { ReusableTableColumn } from "../../components/reusableTable"
import { otcCheckSaveRequest, otcGetRequestSummary, otcRequestSubmitDJP, otcSaveRequest } from "../../crud/tax-service"
import { monthNames, StatusOtcTax, statusOtcTaxs, TaxOtcCheckSaveProcessResponse, TaxOtcSummary, SendStatus, sendStatusOption, TypeFilter } from "../../crud/tax-types"
import { useDebounce, useMultipleState, globalDateTime, numFormatter, convertToXLSX, convertToCSV, isStringJson } from "../../helper"
import { SectionWrapper } from "../user-new/user"
import * as yup from "yup"
import clsx from "clsx"
import Swal from "sweetalert2"
import PaginationTable from "../../components/reusablePaginationTable"

interface InitialState {
  loading: boolean;
  dataSources: Array<TaxOtcSummary>;
  selectedYear?: Date | null;
  pageNumber: number;
  importModal: boolean;
  submitLoading: boolean;
  sentModalLoading: boolean;
  checkSaveDataSources: Array<TaxOtcCheckSaveProcessResponse>;
  checkSaveMonth?: number;
  checkSaveYear?: number;
  checkSaveStatus?: StatusOtcTax;
  filter: TypeFilter;
}

const filters = [
  {value: TypeFilter.OTC, label: "OTC"},
  {value: TypeFilter.VENDOR, label: "Vendor"},
  {value: TypeFilter.REWARD, label: "Reward"},
]

interface ITaxSaveForm {
  month: Date;
  year: Date;
  file: FileUpload;
  type: TypeFilter;
}

const SetupOTCDJPDocs: FC = () => {
  const [state, dispatch] = useMultipleState<InitialState>({
    loading: false,
    dataSources: [],
    selectedYear: new Date(),
    pageNumber: 1,
    importModal: false,
    submitLoading: false,
    sentModalLoading: false,
    checkSaveDataSources: [],
    filter: TypeFilter.OTC
  })
  
  const form = useFormik<Partial<ITaxSaveForm>>({
    initialValues: {},
    onSubmit: (vals) => {
      if(vals.type && vals.year && vals.month && vals.file?.originFileObjs && vals.file?.originFileObjs[0]) {
        dispatch({submitLoading: true})
        otcSaveRequest(vals.type, {
          year: vals.year.getFullYear(),
          month: vals.month.getMonth() + 1,
          file: vals.file?.originFileObjs[0]
        })
          .then(() => {
            Swal.fire('Success','Successfully insert datas', 'success')

            dispatch({importModal: false})
            getSummary(state.selectedYear ?? new Date(), state.filter)
          })
          .catch(err => console.log(err))
          .finally(()=>dispatch({submitLoading: false}))
      }
    },
    validationSchema: yup.object({
      month: yup.mixed().nullable().required('Month Must be fill!'),
      year: yup.mixed().nullable().required('Year Must be fill!'),
      file: yup.mixed().nullable().required('File Must be fill!'),
      type: yup.string().required('Type Must be fill!')
    }),
  })

  const getCheckSaveRequest = (month: number, year: number, status: StatusOtcTax) => {
    dispatch({sentModalLoading: true})
    otcCheckSaveRequest(state.filter, {month, year})
      .then(res => {
        if(res.data.data && res.data.data?.length > 0) dispatch({ 
          checkSaveDataSources: res.data.data,
          checkSaveMonth: month,
          checkSaveYear: year,
          checkSaveStatus: status
        })
        else Swal.fire('Data Empty', 'Data Empty', 'error')
      })
      .catch(err => console.log(err))
      .finally(()=>dispatch({sentModalLoading: false}))
  }

  const column = (activePage: number, limit: number): Array<ReusableTableColumn<TaxOtcSummary>> => [
    {
      title: "No",
      dataIndex: "id",
      render: (_, _data, index) => index + ((activePage - 1) * limit) + 1
    },
    {
      title: "Month",
      dataIndex: "month",
      render: (_, data) => monthNames[data.month - 1]
    },
    {
      title: "Upload Date",
      dataIndex: "created_at",
      render: (_, data) => globalDateTime(data.created_at)
    },
    {
      title: "Uploaded By",
      dataIndex: "saved_by"
    },
    {
      title: "Send Date",
      dataIndex: "send_date",
      render: (_, data) => data.send_date ? globalDateTime(data.send_date) : "-"
    },
    {
      title: "Sent By",
      dataIndex: "send_by"
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, data) => {
        if(data.processed_data > 0) return <Fragment>{data.processed_data}/{data.total_data}</Fragment>
        else return statusOtcTaxs.find(status => status.value === Number(data.status))?.label
      }
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, data) => {
        return (
          <button 
          onClick={()=>getCheckSaveRequest(data.month, data.year, Number(data.status))}
          className={`btn btn-success ${clsx(
            {
              'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
              state.sentModalLoading,
            }
            )}`}
            disabled={state.sentModalLoading}
            >
            {Number(data.status) === StatusOtcTax.DRAFT ? 'Sent' : 'View'}
          </button>
        )
      }
    },
  ]

  const downloadCSVTemplate = () => {
    convertToCSV([
      ['uid','id_number','country','name','date','b_comission','currency','market','direction','qty','rate','idr','ppn','pph'],
      ['121268','','ID','leo tes tesy','2023-04-07 9:15:01','USDT','BIDR','USDT_BIDR','BUY','120','"15,600.00"','"1,872,000.00"','"3,931.20"',''],
      ['121268','','ID','leooleoo','2023-04-07 7:20:01','USDT','BIDR','USDT_BIDR','BUY','93','"12,391.00"','"1,152,363.00"','"2,419.96"','"1,267.60"'],
      ['101123','29898282828','SG','Tanzil New','2023-04-07 7:20:01','USDT','BIDR','USDT_BIDR','BUY','110','"15,800.00"','"123,910.00"','"1,200.00"','"1,300.00"'],
      ['','29898282828','','Tanzil','2023-04-07 7:20:01','USDT','BIDR','USDT_BIDR','BUY','110','"15,800.00"','"123,910.00"','"1,200.00"','"1,300.00"'],
      ['','','SG','Tanzil New Old','2023-04-07 7:20:01','USDT','BIDR','USDT_BIDR','BUY','93','"12,391.00"','"1,872,000.00"','"3,931.20"','']
    ], "otc-djp-template.csv")
  }

  const checkSaveDataColumn = (activePage: number, limit: number): Array<ReusableTableColumn<TaxOtcCheckSaveProcessResponse>> => {
    const col: Array<ReusableTableColumn<TaxOtcCheckSaveProcessResponse>> = [
      {
        title: "No",
        dataIndex: "id",
        render: (_, _data, index) => index + ((activePage - 1) * limit) + 1
      },
      { title: "Name", dataIndex: "name" },
      { title: "ID Number", dataIndex: "id_number" },
      { title: "UID", dataIndex: "uid" },
      { title: "Qty", dataIndex: "trx_qty", render: (_, data) => numFormatter(Number(data.trx_qty ?? "0")) },
      { title: "Rate", dataIndex: "trx_price", render: (_, data) => numFormatter(Number(data.trx_price ?? "0")) },
      { title: "Pairing", dataIndex: "market" },
      { title: "Total", dataIndex: "idr", render: (_, data) => numFormatter(Number(data.idr ?? "0")) },
      { title: "PPN", dataIndex: "ppn", render: (_, data) => numFormatter(Number(data.ppn ?? "0")) },
      { title: "PPH", dataIndex: "pph", render: (_, data) => numFormatter(Number(data.pph ?? "0")) },
    ]

    if(state.checkSaveStatus !== StatusOtcTax.DRAFT) col.push({ 
      title: "Status", 
      dataIndex: "send_status", 
      render: (_, data) => {
        const selected = sendStatusOption.find(status => status.value === Number(data.send_status))
        return <div className={selected?.className}>{selected?.label}</div>
      } 
    })

    col.push({ 
      title: "Reason", 
      dataIndex: "djp_response", 
      render: (_, data) => Number(data.send_status) === SendStatus.FAILED && data.djp_response && isStringJson(data.djp_response) &&  JSON.parse(data.djp_response)?.message ? JSON.parse(data.djp_response).message : ""
    })

    return col
  }
  
  const getSummary = (year: Date | undefined | null, filter: TypeFilter) => {
    if(year) {
      otcGetRequestSummary(filter, {
        year: new Date(year).getFullYear()
      })
        .then((res) => dispatch({dataSources: res.data.data}))
        .catch(err => console.log(err))
        .finally(()=> dispatch({ loading: false }))
    }
  }

  useDebounce<[Date | undefined | null, TypeFilter]>(getSummary, [state.selectedYear, state.filter], 400, () => dispatch({ loading: true }))

  const checkSaveSubmit = () => {
    if(state.checkSaveMonth && state.checkSaveYear) {
      otcRequestSubmitDJP(state.filter, {
        month: state.checkSaveMonth,
        year: state.checkSaveYear
      })
        .then(() => {
          Swal.fire('Success', 'Success submit to DJP', 'success')

          dispatch({
            checkSaveDataSources: [],
            checkSaveMonth: undefined,
            checkSaveYear: undefined,
            checkSaveStatus: undefined
          })

          getSummary(state.selectedYear ?? new Date(), state.filter)
        })
        .catch(err => console.log(err))
    }
  }

  const checkSaveToExcel = () => {
    if(state.checkSaveMonth && state.checkSaveYear) {
      const titles: Array<keyof TaxOtcCheckSaveProcessResponse> = ["country", "id", "id_number", "idr", "market", "month_bucket", "name", "pph", "ppn", "trx_amount", "trx_price", "trx_qty", "uid", "year_bucket", "djp_response"]
  
      const datas: Array<Array<string>> = state.checkSaveDataSources.map(source => {
        return titles.map(title => {
          if(title === "djp_response" && isStringJson(String(source[title]))) return JSON.parse(String(source[title]))?.message ?? ""
          else return String(source[title])
        })
      })
  
      convertToXLSX([titles as Array<string>].concat(datas), `otc-djp-${monthNames[state.checkSaveMonth - 1]}-${state.checkSaveYear}.xlsx`)
    }
  }
  
  return (
    <SectionWrapper>
      <div className='custom-flex-row-space-between mb-3'>
        <h2>Non Tradeable DJP Sent Data Record</h2>
        <button className="btn btn-success" onClick={()=>dispatch({importModal: true})}>
          Import New Data
        </button>
      </div>
      <div className='custom-flex-row-space-between'>
        <div className='custom-flex-row-center gap-2'>
          <div>Year</div>
          <ReactDatePicker 
            className="form-control"
            selected={state.selectedYear}
            onChange={(date) => dispatch({ selectedYear: date })}
            dateFormat="yyyy"
            maxDate={new Date()}
            showYearPicker
          />
        </div>
        <button className="btn btn-primary" onClick={()=>getSummary(state.selectedYear ?? new Date(), state.filter)}>Refresh</button>
      </div>
      <Tabs
        activeKey={state.filter}
        onSelect={(filter) => dispatch({filter: (filter as TypeFilter | null) ?? undefined})}
      >
        {filters.map((filter, index) =>
          <Tab key={index} eventKey={filter.value} title={filter.label} />
        )}
      </Tabs>
      <PaginationTable 
        loading={state.loading}
        dataSources={state.dataSources}
        columns={column}
      />
      <Modal
        show={state.importModal}
        onHide={()=>dispatch({importModal: false})}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import New Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-flex-col gap-2">
          <div className="custom-flex-row gap-2">
            <FormWithFormik 
              type="DATE"
              name="year"
              label="Year"
              placeholderText="Year"
              dateFormat="yyyy"
              config={form}
              showYearPicker
            />
            <FormWithFormik 
              type="DATE"
              name="month"
              label="Month"
              placeholderText="Month"
              dateFormat="MMMM"
              config={form}
              showMonthYearPicker
              showTimeSelect={false}
            />
            <FormWithFormik 
              type="SEARCHABLE_SELECT"
              label="Type"
              config={form}
              options={filters}
              name="type"
            />
          </div>
          <FormWithFormik 
            type="UPLOAD_FILE"
            name="file"
            config={form}
          />
          <div>Example of the import template can be download <span className="text-success cursor-pointer" onClick={downloadCSVTemplate}>here</span></div>
          <button 
            onClick={form.submitForm} 
            className={`btn btn-success w-100 mt-5 ${clsx(
              {
                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                state.submitLoading,
              }
            )}`}
            disabled={state.submitLoading}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        show={state.checkSaveDataSources.length > 0}
        onHide={()=>dispatch({checkSaveDataSources: [], checkSaveMonth: undefined, checkSaveYear: undefined, checkSaveStatus: undefined})}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.checkSaveStatus === StatusOtcTax.DRAFT ? 'Sent Preview' : 'Document Preview'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{state.checkSaveMonth && monthNames[state.checkSaveMonth - 1]} {state.checkSaveYear}</div>
          <PaginationTable 
            dataSources={state.checkSaveDataSources}
            columns={checkSaveDataColumn}
          />
          <div className="custom-flex-row-center-end">
            <b>Total in IDR: Rp {numFormatter(state.checkSaveDataSources.map(s => Number(s.idr ?? "0")).reduce((a,b) => a+b, 0))} ,00</b>
          </div>
          {state.checkSaveStatus !== StatusOtcTax.DRAFT && 
            <div className="custom-flex-row-center-end">
              <b>Total Sent / Data: {state.checkSaveDataSources.filter(source => Number(source.send_status) === SendStatus.SENT).length} / {state.checkSaveDataSources.length}</b>
            </div>
          }
        </Modal.Body>
        <Modal.Footer className="custom-flex-row-center-end gap-2">
          {state.checkSaveStatus === StatusOtcTax.DRAFT && <button className="btn btn-success" onClick={checkSaveSubmit}>Submit</button>}
          <button className="btn btn-primary" onClick={checkSaveToExcel}>Export to Excel</button>
        </Modal.Footer>
      </Modal>
    </SectionWrapper>
  )
}

export default SetupOTCDJPDocs