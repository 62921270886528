import React from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import moment from 'moment';
import actions from "../../crud/tkowebsite";
import SelectDate from '../../components/datetime';
import { Button, Table, Badge, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Swal from 'sweetalert2';
import Pagination from "react-js-pagination";
// import clsx from "clsx";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class Article extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      loading: false,
      data: [],
      totalData: 0,
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      postPerPage: 15,
      title: '',
      date: null,
      status: '',
    }
  }

  handlePageChangeHistory = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      // this.getArticle()
    })
  }

  dateTime = date => {
    var fmtDate = moment(date).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getArticle() {
    this.setState({
      loading: true
    })

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }

    if(this.state.date){
      const st = moment(this.state.date).format('Y-MM-DD');
      params += '&created_date='+st;
    }

    if(this.state.title !== ''){
      params += `&title=${this.state.title}`
    }

    if(this.state.status !== ''){
      params += `&status=${this.state.status}`
    }

    actions.fetchArticle(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data,
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  handleDelete(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(id);
      }
    })
  }

  delete(id){
    this.setState({
      loading: true
    })
    actions.deleteDataArticle(this.getToken(), id)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Delete is success");
      }
      this.setState({
        loading: false,
      })
      this.getArticle()
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  
  componentDidMount(){
    this.getArticle();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    // this.getArticle()
  };

  render(){
    const statusType = [
      {
        id: "",
        name: "All",
      },
      {
        id: "1",
        name: "Enabled",
      },
      {
        id: "0",
        name: "Disabled",
      }
    ];
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body pad-10-20">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-2">
                  <input
                    style={{fontSize: '0.8rem'}}
                    type="text" 
                    className="form-control mr-2" 
                    name="search" 
                    placeholder="Article Title" 
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })} />
                </div>
                <div className="col-md-2">
                  <SelectDate changeDate={(val) => this.setState({ date: val })} />
                </div>
                <div className="col-md-2">
                  <select className="form-control font-8rem" onChange={(e) => this.setState({ status: e.target.value })} placeholder="status">
                    {/* <option disabled selected={this.state.status === '' ? true : false}>status</option> */}
                    {statusType.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <button className="btn btn-outline-warning mr-3 font-8rem" type="button"  onClick={() => this.getArticle()} style={{ width: '20%' }}>
                      Search
                    </button>
                    <Link to="/tko-website/article/add" className="mr-3" style={{ width: '21%' }}>
                      <Button variant="outline-primary" className="font-8rem" style={{ width: '100%' }}>Add Article</Button>
                    </Link>
                    <Link to="/tko-website/article/tag-config" style={{ width: '21%' }}>
                      <Button variant="outline-primary" className="font-8rem" style={{ width: '100%' }}>Tags Config</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh all-center" style={{ width: '4%' }}>
                        No &nbsp;
                      </th>
                      <th className="tableheh" style={{ width: '39%' }}>
                        Article Title &nbsp;
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        Tags
                      </th>
                      <th className="tableheh">
                        Created At
                      </th>
                      <th className="tableheh center">
                        Status
                      </th>
                      <th className="tableheh center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="6"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody>
                    :
                    <tbody>
                      {this.state.data.map( (row, index) => (
                        <tr key={index}>
                          <td className="all-center">{index+1}</td>
                          <td className="align-middle">
                            <b>{row.title_en} / {row.title_id}</b>
                          </td>
                          <td>{row.external_tko_tag ? row.external_tko_tag.title : ''}</td>
                          <td>{this.dateTime(row.created_date)}</td>
                          <td className="center align-middle">
                            {row.status === '1' ?
                              <Badge bg="success">
                                Enabled
                              </Badge>
                            :
                              <Badge bg="warning">
                                Disabled
                              </Badge>
                            }
                          </td>
                          <td className="align-middle">
                            <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Edit Mini Banner
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={{
                                    pathname: `/tko-website/article/${row.id}/edit`
                                  }}
                                  className="kt-link">
                                  <span className="btn-action">
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                  </span>
                                </Link>
                              </OverlayTrigger>
                              &nbsp; | &nbsp;
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Delete Link
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleDelete(row.id)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                </span>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChangeHistory}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}