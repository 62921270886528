import instance from './axiosKycInstance'

const getNFTSummary = (token, params) => {
  return instance.get("/admin/nft-list" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchNFTSummary = (token, params) => {
  return new Promise((resolve, reject) => {
    getNFTSummary(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailNFTSummary = (token, id) => {
  return instance.get("admin/nft/" + id + "/detail", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailNFTSummary = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailNFTSummary(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDownloadNFTSummary = (token, params) => {
  return instance.get("/admin/nft/download-waitinglist" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDownloadNFTSummary = (token, params) => {
  return new Promise((resolve, reject) => {
    getDownloadNFTSummary(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}


export default {
  fetchNFTSummary: fetchNFTSummary,
  fetchDetailNFTSummary: fetchDetailNFTSummary,
  fetchDownloadNFTSummary: fetchDownloadNFTSummary,
};