import React from 'react';
import clsx from "clsx";
import { Link } from "react-router-dom";
import moment from 'moment';
import actions from "../../crud/launcher";
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import "react-datepicker/dist/react-datepicker.css";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class User extends React.Component {
  history() {
    history.push("/launcher");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tokolink-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tokolink-prod.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      title: '',
      title_en: '',
      description: '',
      description_en: '',
      slug: '',
      position: '',
      keyword: '',
      icon: null,
      iconEdit: '',
      status: '1',
      show_menu: '1',
      editPage: false,
      idData: ''
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      title: '',
      title_en: '',
      description: '',
      description_en: '',
      slug: '',
      position: '',
      icon: null,
      iconEdit: '',
      keyword: '',
      status: '1',
      editPage: false,
      show_menu: '1',
      idData: ''
    });
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getDetailData(id){
    this.setState({
      loading: true,
    })

    actions.fetchDetailSummaryCategory(this.getToken(), id)
    .then(res => {
      const data = res.data.data
      let slugs = ''
      if (!data.slug || data.slug === ''){
        let str = data.category_title_en.replace(/\s+/g, '-').toLowerCase();
        slugs = str
      } else {
        slugs = data.slug
      }
      this.setState({
        loading: false,
        title: data.category_title_id,
        title_en: data.category_title_en,
        description: data.category_description_id,
        description_en: data.category_description_en,
        slug: slugs,
        iconEdit: data.category_icon,
        status: data.category_status,
        show_menu: data.show_menu,
        position: data.position,
        keyword: data.category_metakeyword,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 

  }

  isSubmit = event => {
    event.preventDefault();

    let slugs = ''
    if (!this.state.slug || this.state.slug === ''){
      let str = this.state.title_en.replace(/\s+/g, '-').toLowerCase();
      slugs = str
    } else {
      slugs = this.state.slug.replace(/\s+/g, '-').toLowerCase();
    }

    var bodyFormData = new FormData();
    bodyFormData.append('category_title_id', this.state.title);
    bodyFormData.append('category_title_en', this.state.title_en);
    bodyFormData.append('category_description_id', this.state.description);
    bodyFormData.append('category_description_en', this.state.description_en);
    bodyFormData.append('slug', slugs);
    bodyFormData.append('category_status', this.state.status);
    bodyFormData.append('show_menu', this.state.show_menu);
    bodyFormData.append('position', this.state.position);
    bodyFormData.append('category_metakeyword', this.state.keyword);
    if(this.state.icon !== null){
      bodyFormData.append('icon', this.state.icon);
    }

    if(this.state.editPage){
      this.editEvent(bodyFormData);
    }else{
      this.addEvent(bodyFormData);
    }
  }

  addEvent(bodyFormData){
    this.setState({
      loading: true
    })

    actions.postCategory(this.getToken(), bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new Category is success");
      }
      this.setState({
        loading: false,
      })
      this.emptyState()
      setTimeout(() => {
        this.history()
      }, 2000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  editEvent(bodyFormData){
    this.setState({
      loading: true
    })

    actions.putCategory(this.getToken(), this.state.idData, bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Edit new event is success");
      }
      this.setState({
        loading: false,
      })
      
      this.emptyState()
      setTimeout(() => {
        this.history()
      }, 2000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  onChangeFile = async (e) => {
    let file = e.target.files[0];
    if(file){
      await this.setState({
        icon: file
      })
    }
  }

  componentDidMount(){
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit-launcher") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      this.getDetailData(checkPath[checkPath.length - 2]);
    }
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()

    return ISOformat
  }

  render(){
    const listStatus = [
      {
        id: "1",
        name: 'Enabled',
      },
      {
        id: "0",
        name: 'Disabled',
      },
    ];
    const showStatus = [
      {
        id: "1",
        name: 'Yes',
      },
      {
        id: "0",
        name: 'No',
      },
    ];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Category Title (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.title_en} onChange={(e) => this.setState({title_en: e.target.value})} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Category Title (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} required/>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Description (EN)</Form.Label>
                        <Form.Control as="textarea" rows={4} value={this.state.description_en} onChange={(e) => this.setState({ description_en: e.target.value })}/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Description (ID)</Form.Label>
                        <Form.Control as="textarea" rows={4} value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })}/>
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group>
                    <Form.Label>Slug</Form.Label>
                    <Form.Control type="text" placeholder="Input slug" value={this.state.slug} onChange={(e) => this.setState({slug: e.target.value})} />
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-9 col-sm-12">
                      <Form.Group>
                        <Form.Label>Category Icon</Form.Label>
                        <div className="d-flex justify-content-center align-items-center">
                          <Form.Control className="form-control" type="file" onChange={this.onChangeFile} accept="image/*"/>
                        </div>  
                        <Form.Text className="text-muted">
                          <span>*Please using 64px x 64px for this image</span>
                        </Form.Text>
                      </Form.Group>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <Form.Group>
                        <div style={{marginTop: '2rem'}}>
                          {this.state.iconEdit !== '' && (
                            <a href={this.state.urlAsset+this.state.iconEdit} target='_blank' rel="noopener noreferrer">
                              <img src={this.state.urlAsset+this.state.iconEdit} alt="img" className="img-bn" />
                            </a>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Status</label>
                        <div className="input-group">
                          <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })} placeholder="Select Data">
                            {/* <option disabled selected={this.state.status === '' ? true : false}>Pilih Salah Satu</option> */}
                            {listStatus.map((e) => (
                              <option key={e.id} value={e.id} selected={e.id === this.state.status ? true : false} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Show On Homepage</label>
                        <div className="input-group">
                          <select className="form-control" onChange={(e) => this.setState({ show_menu: e.target.value })} placeholder="Select Data">
                            {showStatus.map((e) => (
                              <option key={e.id} value={e.id} selected={e.id === this.state.show_menu ? true : false} defaultValue={e.id === this.state.show_menu ? true : false}>{e.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <Form.Group>
                        <Form.Label>Sorting No.</Form.Label>
                        <Form.Control type="number" min="0" value={this.state.position} onChange={(e) => this.setState({ position: e.target.value })}/>
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group>
                    <Form.Label>Meta Keyword</Form.Label>
                    <Form.Control type="text" placeholder="Youtube, Videos, Sponsored" value={this.state.keyword} onChange={(e) => this.setState({keyword: e.target.value})} required/>
                  </Form.Group>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <Link to="/launcher">
                      <Button variant="outline-danger" className="mr-3">Back To Category</Button>
                    </Link>
                    <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      Save Event
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}