import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import './bookingOrder.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { getDateNow } from './bookingOrder.constant';
import ArrowBack from '@material-ui/icons/ArrowBack';
require('bootstrap/scss/bootstrap.scss');

const ThubBookingOrderEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [stringToken, setStringToken] = useState();
  const [locationList, setLocationList] = useState([]);
  const [bookingTypeList, setBookingTypeList] = useState([]);
  const [seatList, setSeatList] = useState([]);
  const [currentBookingOrderType, setCurrentBookingOrderType] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [bookingOrderDetail, setBookingOrderDetail] = useState();
  const params = useParams();

  const getDateValue = (date) => {
    const splitDate = date.split('T');
    const splitHour = splitDate[1].split(':');
    const dateValue = splitDate[0];
    const hourValue = `${splitHour[0]}:${splitHour[1]}`;

    return `${dateValue} ${hourValue}`;
  };

  const getBookingOrderDetail = async () => {
    const { id } = params;
    setLoading(true);
    thubActions
      .fetchThubBookingOrderDetail(stringToken, id)
      .then((res) => {
        const { data } = res.data;
        const startDateValue = data.start_date.split('.');
        const endDateValue = data.end_date.split('.');
        setBookingOrderDetail(data);
        setCurrentBookingOrderType(data.booking_type.type_name);
        setFieldValue('user_id', data.user.user_id);
        setFieldValue('location_id', data.location.location_id);
        setFieldValue('booking_type_id', data.booking_type.booking_type_id);
        setFieldValue('seat_id', data.seats.seat_id);
        setFieldValue('start_date', getDateValue(startDateValue[0]));
        setFieldValue('end_date', getDateValue(endDateValue[0]));
        setFieldValue('additional_note', data.additional_note);
        setFieldValue('product_code', data.payment_method.product_code);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setBookingOrderDetail([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const editBookingOrder = async (data) => {
    const orderType = currentBookingOrderType.toLowerCase();
    setLoading(true);
    await thubActions
      .fetchThubBookingOrderEdit(stringToken, data, params.id, orderType)
      .then((res) => {
        if (res.data.code === 200 || res.data.code === 201) {
          toast.success('Edit Booking order success');
          setLoading(false);
          setTimeout(() => {
            props.history.push('/thub/booking-order');
            // window.location.reload();
          }, 1000);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getUserList = async () => {
    setLoading(true);
    await thubActions
      .fetchThubUserAll(stringToken)
      .then((res) => {
        setUserList(res.data.data.users);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setUserList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getLocationList = async () => {
    setLoading(true);
    thubActions
      .fetchThubLocationAll(stringToken)
      .then((res) => {
        setLocationList(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setLocationList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();

            setTimeout(() => {
              props.history.push('/auth');
              window.location.reload();
            }, 1500);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getBookingTypeList = async (locationId) => {
    setLoading(true);

    thubActions
      .fetchThubBookingTypeByLocation(stringToken, locationId)
      .then((res) => {
        setBookingTypeList(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setBookingTypeList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();

            setTimeout(() => {
              props.history.push('/auth');
              window.location.reload();
            }, 1500);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getSeatList = async (bookingTypeId) => {
    setLoading(true);

    thubActions
      .fetchThubSeatListByBookingType(stringToken, bookingTypeId)
      .then((res) => {
        setSeatList(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setSeatList([]);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();

            setTimeout(() => {
              props.history.push('/auth');
              window.location.reload();
            }, 1500);
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const checkSeatAvailibilty = async () => {
    const { values } = bookingFormik;
    const combinedStartDate = values.start_date.split('T').join(' ');
    const combinedEndDate = values.end_date.split('T').join(' ');
    const params = `?start_date=${combinedStartDate}&end_date=${combinedEndDate}&booking_type_id=${values.booking_type_id}&seat_id=${values.seat_id}`;
    setLoading(true);

    thubActions
      .fetchThubCheckSeatAvailibility(stringToken, params)
      .then((res) => {
        setIsDisabled(false);
        setLoading(false);
        toast.success('Seat is Available 1');
      })
      .catch((e) => {
        setLoading(false);
        setIsDisabled(true);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required('User is required'),
    location_id: Yup.string().required('Location is required'),
    booking_type_id: Yup.string().required('Booking type name is required'),
    seat_id: Yup.string().required('Seat list is required'),
    start_date: Yup.string().required('Start date is required'),
    end_date: Yup.string().required('End date is required'),
  });

  const bookingFormik = useFormik({
    initialValues: {
      user_id: '',
      location_id: '',
      booking_type_id: '',
      seat_id: '',
      start_date: '',
      end_date: '',
      product_code: 'special-privilege',
      additional_note: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      editBookingOrder(values);
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getBookingOrderDetail();
    }
  }, [stringToken]);

  useEffect(() => {
    if (stringToken && bookingOrderDetail) {
      getUserList();
      getLocationList();
      getBookingTypeList(bookingOrderDetail.location.location_id);
      getSeatList(bookingOrderDetail.booking_type.booking_type_id);
    }
  }, [bookingOrderDetail]);

  const { handleBlur, handleChange, errors, touched, values, setFieldValue } =
    bookingFormik;

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              {bookingOrderDetail && (
                <form
                  className='m-login__form m-form'
                  onSubmit={(e) => {
                    e.preventDefault();
                    bookingFormik.handleSubmit(e);
                  }}
                >
                  <div className='row'>
                    <div className='col-md-4'>
                      {userList && userList.length > 0 ? (
                        <Form.Group>
                          <Form.Label>User</Form.Label>
                          <Form.Control
                            as='select'
                            value={values.user_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='user_id'
                            className={`${
                              touched.user_id && errors.user_id && 'error'
                            }`}
                          >
                            {userList.map((data, i) => (
                              <option value={data.user_id} key={i}>
                                {`${data.first_name} ${data.last_name} - ${data.email}`}
                              </option>
                            ))}
                          </Form.Control>
                          {touched.user_id && errors.user_id && (
                            <div className='error-message'>
                              {errors.user_id}
                            </div>
                          )}
                        </Form.Group>
                      ) : (
                        <p>
                          You must create a user before creating a new booking
                          order
                        </p>
                      )}
                    </div>
                    <div className='col-md-4'>
                      {locationList && locationList.length > 0 ? (
                        <Form.Group>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            as='select'
                            value={values.location_id}
                            disabled
                          >
                            <option value='' selected>
                              Select Location
                            </option>
                            {locationList.map((data, i) => (
                              <option value={data.location_id} key={i}>
                                {data.location_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      ) : (
                        <p>
                          You must create a location before creating a new
                          booking order
                        </p>
                      )}
                    </div>
                    <div className='col-md-4'>
                      <Form.Group>
                        <Form.Label>Booking Type</Form.Label>
                        <Form.Control
                          as='select'
                          disabled
                          value={values.booking_type_id}
                        >
                          <option selected>Select Booking Type</option>
                          {bookingTypeList &&
                            bookingTypeList.length > 0 &&
                            bookingTypeList
                              .filter(
                                (data) =>
                                  data.type_name !== 'Multi Function' &&
                                  data.type_name !== 'Multifunction'
                              )
                              .map((data, i) => (
                                <option value={data.booking_type_id} key={i}>
                                  {data.type_name}
                                </option>
                              ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <Form.Group>
                        <Form.Label>Seat / Room</Form.Label>
                        <Form.Control
                          as='select'
                          disabled
                          value={values.seat_id}
                        >
                          <option selected>Select Seat / Room</option>
                          {seatList &&
                            seatList.length > 0 &&
                            seatList.map((data, i) => (
                              <option value={data.seat_id} key={i}>
                                {data.seat_name}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div className='col-md-4'>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type='datetime-local'
                          // min={getDateNow()}
                          value={values.start_date}
                          onChange={(e) => {
                            setFieldValue('start_date', e.target.value);
                            setIsDisabled(true);
                          }}
                          onBlur={handleBlur}
                          name='start_date'
                          className={
                            touched.start_date && errors.start_date && 'error'
                          }
                        />
                        {touched.start_date && errors.start_date && (
                          <div className='error-message'>
                            {errors.start_date}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <div className='col-md-4'>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type='datetime-local'
                          // min={getDateNow()}
                          value={values.end_date}
                          onChange={(e) => {
                            setFieldValue('end_date', e.target.value);
                            setIsDisabled(true);
                          }}
                          onBlur={handleBlur}
                          name='end_date'
                          className={
                            touched.end_date && errors.end_date && 'error'
                          }
                        />
                        {touched.end_date && errors.end_date && (
                          <div className='error-message'>{errors.end_date}</div>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group>
                    <Form.Label>Additional Notes</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      name='additional_note'
                      placeholder='Enter your notes'
                      value={values.additional_note}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <div
                    className='kt-login__actions'
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <button
                      type='button'
                      disabled={loading}
                      className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                      onClick={() => {
                        props.history.push('/thub/booking-order');
                      }}
                    >
                      <ArrowBack /> Back
                    </button>
                    <button
                      type='button'
                      className={`btn btn-secondary btn-elevate kt-login__btn-secondary ${clsx(
                        {
                          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                            loading,
                        }
                      )}`}
                      style={{ marginRight: 10 }}
                      onClick={() => checkSeatAvailibilty()}
                    >
                      Check Availability
                    </button>
                    <button
                      type='submit'
                      disabled={isDisabled}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                            loading,
                        }
                      )}`}
                    >
                      Edit Booking Order
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThubBookingOrderEdit;
