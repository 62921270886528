import { ChangeEvent, CSSProperties, FC, Fragment, KeyboardEventHandler, ReactNode, useEffect, useState } from "react"
import { ButtonGroup, Form, FormCheckProps, FormControlProps, FormSelectProps, InputGroup, ToggleButton } from "react-bootstrap";
import { FieldArray, FormikContextType, FormikValues } from "formik"
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import CodeEditor from "react-simple-code-editor"
import { highlight, languages } from "prismjs"
import JoditEditor from "jodit-react"
import 'prismjs/themes/prism.css'
import Constant from '../../../Constant'
import ReactSelect, { GroupBase, OptionsOrGroups, Props as ReactSelectProps, CSSObjectWithLabel } from 'react-select'
import AsyncReactSelect, { AsyncProps } from 'react-select/async';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MimeTypes } from "../../crud/global-types";
import { sanitizedHtml } from "../../helper";

export type FormWithFormikTypes = "TEXT_INPUT" | "EMAIL" | "TEXT_AREA" | "NUMBER_INPUT" | "FILE" | "MULTIPLE_FILE" | "UPLOAD_FILE" | "DROPDOWN" | "RADIO" | "DATE" | "WYSIWYG" | "CHECKBOX" | "MULTIPLE_CHECKBOX" | "JSON_HIGHTLIGHT" | "MULTIPLY_INPUT" | "SEARCHABLE_SELECT" | "ASYNC_SEARCHABLE_SELECT" | "MULTI_ASYNC_SEARCHABLE_SELECT" | "CREATABLE_MULTI_SELECT" | "ASYNC_CREATABLE_SELECT"

export interface FormStyling {
  className?: string;
  style?: CSSProperties;
}

export interface FormWithFormikCustom<Values extends FormikValues, T extends FormWithFormikTypes> {
  type: T;
  config?: FormikContextType<Values>;
  name: keyof Values;
  label?: ReactNode;
  placeholder?: string;
}

export interface FormMultiplyField {
  type: FormMultiplyTypeInput;
  label: string;
}

export interface ReactSelectDropdown {
  label: ReactNode;
  value?: string;
}

export interface FileUpload {
  originFileObjs?: FileList;
  url?: string;
}

export type FormMultiplyTypeInput = "PASSWORD"
export interface FormCustomOption { value: string | number; label: ReactNode }
export interface FormCustomOptions { options: Array<FormCustomOption> }
export type FormSelectCustomProps = FormSelectProps & FormCustomOptions
export type FormCheckCustomProps = Omit<FormCheckProps, "type"> & FormCustomOptions
export type FormSingleCheckboxCustomProps = Omit<FormCheckProps, "type"> & { description: string; }
export type FormMultiCheckboxCustomProps = Omit<FormCheckProps, "type"> & FormCustomOptions
export type FormMultiplyInputProps = Omit<FormControlProps, "type"> & { fields: Array<FormMultiplyField> }
export interface FormImageProps<Values extends FormikValues> {
  allowedMimes?: Array<MimeTypes>;
  affix?: (values?: Values) => ReactNode;
}
export interface MultiAsyncOptions<Option, Group extends GroupBase<Option>> {
  options?: (index: number) => OptionsOrGroups<Option, Group>;
  loadOptions?: (inputValue: string, callback: (options: OptionsOrGroups<Option, Group>) => void, index: number) => Promise<OptionsOrGroups<Option, Group>> | void;
  isRemovable?: boolean;
  isNonCreatable?: boolean;
  rightPrefix?: (index: number, option?: Option) => ReactNode;
  onDelete?: (index: number) => void;
}

export type FormWithFormikProps<Values extends FormikValues> = FormWithFormikCustom<Values, "TEXT_INPUT" | "EMAIL" | "TEXT_AREA" | "NUMBER_INPUT" | "FILE" | "MULTIPLE_FILE"> & FormControlProps
  | FormWithFormikCustom<Values, "UPLOAD_FILE"> & FormImageProps<Values>
  | FormWithFormikCustom<Values, "DROPDOWN"> & FormSelectCustomProps
  | FormWithFormikCustom<Values, "RADIO"> & FormCheckCustomProps
  | FormWithFormikCustom<Values, "DATE"> & Omit<ReactDatePickerProps, "onChange">
  | FormWithFormikCustom<Values, "WYSIWYG"> & { disabled?: boolean } & FormStyling
  | FormWithFormikCustom<Values, "CHECKBOX"> & FormSingleCheckboxCustomProps
  | FormWithFormikCustom<Values, "MULTIPLE_CHECKBOX"> & FormMultiCheckboxCustomProps
  | FormWithFormikCustom<Values, "JSON_HIGHTLIGHT"> & { disabled?: boolean } & FormStyling
  | FormWithFormikCustom<Values, "MULTIPLY_INPUT"> & FormMultiplyInputProps
  | FormWithFormikCustom<Values, "SEARCHABLE_SELECT"> & ReactSelectProps<ReactSelectDropdown>
  | FormWithFormikCustom<Values, "ASYNC_SEARCHABLE_SELECT"> & AsyncProps<ReactSelectDropdown, false, GroupBase<ReactSelectDropdown>>
  | FormWithFormikCustom<Values, "MULTI_ASYNC_SEARCHABLE_SELECT"> & Omit<AsyncProps<ReactSelectDropdown, false, GroupBase<ReactSelectDropdown>>, "options" | "loadOptions"> & MultiAsyncOptions<ReactSelectDropdown, GroupBase<ReactSelectDropdown>>
  | FormWithFormikCustom<Values, "CREATABLE_MULTI_SELECT"> & CreatableProps<ReactSelectDropdown, true, GroupBase<ReactSelectDropdown>>
  | FormWithFormikCustom<Values, "ASYNC_CREATABLE_SELECT"> & AsyncCreatableProps<ReactSelectDropdown, false, GroupBase<ReactSelectDropdown>>

const FormWithFormik = <Values extends FormikValues>(props: FormWithFormikProps<Values>) => {
  const fieldRenderedByType = () => {
    if (props.type === "DROPDOWN") {
      const { name, config, type, label, options, ...anotherProps } = props
      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <Form.Select
            name={name.toString()}
            isInvalid={!!config?.errors[name]}
            value={config?.values[name] ?? ""}
            onChange={(evt) => config?.setFieldValue(name.toString(), evt.target.value, true)}
            data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + props.name}
            {...anotherProps}
          >
            {options.map((option, key) =>
              <option key={key} value={option.value}>{option.label}</option>
            )}
          </Form.Select>
        </Fragment>
      )
    }
    else if (props.type === "RADIO") {
      const { name, config, type, label, options, ...anotherProps } = props
      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <div className="d-flex gap-3">
            {options.map((option, key) =>
              <Form.Check
                key={key}
                checked={config?.values[name] === option.value ? true : false}
                onClick={() => config?.setFieldValue(name.toString(), option.value, true)}
                label={option.label}
                name={name}
                type="radio"
                data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + props.name}
                {...anotherProps}
              />
            )}
          </div>
        </Fragment>
      )
    }
    else if (props.type === "CHECKBOX") {
      const { name, config, type, label, description, ...anotherProps } = props
      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <div>
            <Form.Check
              checked={config?.values[name] ? true : false}
              onClick={() => config?.setFieldValue(name.toString(), !config?.values[name], true)}
              label={description}
              name={name}
              type="checkbox"
              data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + props.name}
              {...anotherProps}
            />
          </div>
        </Fragment>
      )
    }
    else if (props.type === "MULTIPLE_CHECKBOX") {
      const { name, config, type, label, options, ...anotherProps } = props

      const getValues = (): Array<string> => {
        if (Array.isArray(config?.values[name]) && config?.values[name].every((i: any) => typeof i === "string")) return config?.values[name]
        else return []
      }

      const onClickHandler = (option: FormCustomOption) => {
        const selectedFinder = getValues().find(value => value === option.value)
        if (selectedFinder) config?.setFieldValue(name.toString(), getValues().filter(value => value !== option.value), true)
        else config?.setFieldValue(name.toString(), [...getValues(), option.value], true)
      }

      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <div>
            {options.map((option, key) =>
              <Form.Check
                key={key}
                checked={getValues().find(value => value === option.value) ? true : false}
                onClick={() => onClickHandler(option)}
                label={option.label}
                name={name}
                type="checkbox"
                data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + props.name}
                {...anotherProps}
              />
            )}
          </div>
        </Fragment>
      )
    }
    else if (props.type === "DATE") {
      const { name, config, type, label, ...anotherProps } = props
      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <ReactDatePicker
            className={`form-control ${props.config?.errors[props.name]
              ? 'is-invalid'
              : ''
              }`}
            selected={props.config?.values[props.name]}
            onChange={(date) => props.config?.setFieldValue(props.name.toString(), date)}
            showTimeSelect
            dateFormat='Pp'
            data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + props.name}
            {...anotherProps}
          />
        </Fragment>
      )
    }
    else if (props.type === "WYSIWYG") return <WYSIWYG {...props} />
    else if (props.type === "JSON_HIGHTLIGHT") {
      const { name, config, label, disabled, className, style } = props
      return (
        <div className={className} style={style}>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <CodeEditor
            value={config?.values[name] ?? ""}
            onValueChange={code => config?.setFieldValue(name.toString(), code, true)}
            highlight={code => highlight(code, languages.javascript, "javascript")}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 16,
            }}
            disabled={disabled}
            data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + name.toString()}
          />
        </div>
      )
    }
    else if (props.type === "MULTIPLY_INPUT") {
      const { fields, className, style } = props
      return (
        <div className={className} style={style}>
          {props.label && <Form.Label><b>{props.label}</b></Form.Label>}
          {fields.map((field, index) =>
            <MultiplyInput key={index} {...props} field={field} index={index} />
          )}
        </div>
      )
    }
    else if(props.type === "SEARCHABLE_SELECT") {
      const { name, config, type, label, ...anotherProps } = props
      const newValue = (anotherProps.options as Array<ReactSelectDropdown>)?.find(option => option.value === config?.values[name])
      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <ReactSelect
            styles={{
              control: (base) => ({
                ...base,
                border: props.config?.errors[props.name] ? '1px solid red' : undefined
              } as CSSObjectWithLabel),
              clearIndicator: (base) => ({
                ...base,
                cursor: "pointer"
              } as CSSObjectWithLabel)
            }}
            name={name.toString()}
            defaultValue={newValue}
            value={newValue}
            onChange={(nv)=>config?.setFieldValue(name.toString(), (nv as ReactSelectDropdown)?.value, true)}
            isSearchable
            {...anotherProps}
          />
        </Fragment>
      )
    }
    else if(props.type === "ASYNC_SEARCHABLE_SELECT") {
      const { name, config, type, label, ...anotherProps } = props
      const newValue = (anotherProps.options as Array<ReactSelectDropdown>)?.find(option => option.value === config?.values[name])
      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <AsyncReactSelect 
            styles={{
              control: (base) => ({
                ...base,
                border: props.config?.errors[props.name] ? '1px solid red' : undefined
              } as CSSObjectWithLabel),
              clearIndicator: (base) => ({
                ...base,
                cursor: "pointer"
              } as CSSObjectWithLabel)
            }}
            name={name.toString()}
            defaultValue={newValue}
            value={newValue}
            onChange={(nv)=>config?.setFieldValue(name.toString(), (nv as ReactSelectDropdown)?.value, true)}
            cacheOptions
            {...anotherProps}
          />
        </Fragment>
      )
    }
    else if(props.type === "MULTI_ASYNC_SEARCHABLE_SELECT") {
      const { name, config, type, label, options, loadOptions, isRemovable, isNonCreatable, rightPrefix, ...anotherProps } = props

      const values = config?.values && config?.values[name] as Array<Values[keyof Values & (string | undefined)] | undefined>

      return (
        <Fragment>
          {label && <Form.Label><b>{label}</b></Form.Label>}
          <div className="custom-flex-col gap-2">
            {values?.map((v,index) => {
              return (
                <div key={index} className="custom-flex-row-center">
                  <AsyncReactSelect 
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "100%",
                        border: props.config?.errors[props.name] ? '1px solid red' : undefined
                      } as CSSObjectWithLabel),
                      container: (base) => ({
                        ...base,
                        width: "100%",
                      } as CSSObjectWithLabel)
                    }}
                    name={name.toString()}
                    defaultValue={v}
                    value={v}
                    onChange={(nv)=>{
                      let clone = [...values]
                      clone[index] = nv as Values[keyof Values & undefined] ?? undefined
                      config?.setFieldValue(name.toString(), clone, true)
                    }}
                    cacheOptions
                    options={options ? options(index) : undefined}
                    loadOptions={(input, c) => loadOptions && loadOptions(input, c, index)}
                    {...anotherProps}
                  />
                  {isRemovable &&
                    <button 
                      className="btn btn-danger"
                      type="button" 
                      onClick={() => {
                        const newValues = [...values]
                        newValues.splice(index, 1);
                        props.onDelete && props.onDelete(index)
                        config?.setFieldValue(name.toString(), newValues, true)
                      }}
                    >
                      -
                    </button>
                  }
                  {rightPrefix && rightPrefix(index, v)}
                </div>
              )
            })}
          </div>
          {!isNonCreatable && 
            <button 
              type="button" 
              className="btn btn-primary w-100 mt-3" 
              onClick={()=>config?.setFieldValue(name.toString(), values ? [...values, undefined] : [undefined], true)}
              disabled={anotherProps.isDisabled}
            >
              Add
            </button>
          }
        </Fragment>
      )
    }
    else if(props.type === "CREATABLE_MULTI_SELECT") return <FormCreatableMultiSelect {...props} />
    else if(props.type === "ASYNC_CREATABLE_SELECT") return <FormAsyncCreatableSelect {...props} />
    else if(props.type === "UPLOAD_FILE") return <UploadFileComponent {...props} />
    else {
      const { name, config, type, label, ...anotherProps } = props
      const getType = (): FormControlProps & { multiple?: boolean } => {
        if (type === "EMAIL") return { type: 'email' }
        else if (type === "TEXT_AREA") return { as: 'textarea' }
        else if (type === "NUMBER_INPUT") return { type: 'number' }
        else if (type === "FILE") return { type: 'file' }
        else if (type === "MULTIPLE_FILE") return { type: 'file', multiple: true }
        else return { type: 'text' }
      }
      return (
        <Fragment>
          {props.label && <Form.Label><b>{props.label}</b></Form.Label>}
          <Form.Control
            {...getType()}
            name={name.toString()}
            isInvalid={!!config?.errors[name]}
            value={config?.values[name]}
            onChange={(evt) => config?.setFieldValue(name.toString(), evt.target.value, true)}
            data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + name.toString()}
            {...anotherProps}
          />
        </Fragment>
      )
    }
  }

  const errorMessagesHandler = () => {
    const errors = props.config?.errors[props.name]
    if(Array.isArray(errors)) return errors.map(error => {
      if(typeof error === "object") return Object.entries(error).map(([_key,val]) => <div>{val}</div>)
      else return String(error)
    })
    else return errors?.toString()
  }
  return (
    <Form.Group className="w-100">
      {fieldRenderedByType()}
      {errorMessagesHandler() && (
        <Form.Text className='text-danger' data-testid={Constant.UNIT_TEST_PREFIX_ID.ERROR_MESSAGE + props.name.toString()}>
          {errorMessagesHandler()}
        </Form.Text>
      )}
    </Form.Group>
  )
}

const MultiplyInput = <Values extends FormikValues>(props: FormWithFormikCustom<Values, "MULTIPLY_INPUT"> & FormMultiplyInputProps & { field: FormMultiplyField, index: number }) => {
  const [visible, setVisible] = useState(false)
  const { name, config, type, label, field, index, ...anotherProps } = props
  const getType = (type: FormMultiplyTypeInput): FormControlProps => {
    return {
      type: visible ? "text" : "password"
    }
  }
  return (
    <InputGroup className="mb-2">
      <InputGroup.Text>{field.label}</InputGroup.Text>
      <Form.Control
        {...getType(field.type)}
        name={name.toString()}
        isInvalid={!!config?.errors[name]}
        value={config?.values[name][index]}
        onChange={(evt) => {
          let arrayValue = config?.values[name] as Array<string>
          arrayValue[index] = evt.target.value
          config?.setFieldValue(name.toString(), arrayValue, true)
        }}
        data-testid={Constant.UNIT_TEST_PREFIX_ID.FIELD + name.toString()}
      />
      <InputGroup.Text>
        <i onClick={() => setVisible(!visible)} className={visible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
      </InputGroup.Text>
    </InputGroup>
  )
}

type WYSIWYGMode = "EDITOR" | "RAW" | "PREVIEW"

const WYSIWYG = <Values extends FormikValues>(props: FormWithFormikProps<Values> & { disabled?: boolean; } & FormStyling) => {
  const [mode, setMode] = useState<WYSIWYGMode>("EDITOR")
  const { name, config, label, disabled, className, style } = props

  const disabledListener = () => {
    if (disabled) setMode("PREVIEW")
  }

  useEffect(disabledListener, [disabled])

  const editorRenderer = () => {
    if (mode === "RAW") return (
      <CodeEditor
        value={config?.values[name] ?? ""}
        onValueChange={code => config?.setFieldValue(name.toString(), sanitizedHtml(code), true)}
        highlight={code => highlight(code, languages.html, "html")}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 16,
        }}
        disabled={disabled}
      />
    )
    else if (mode === "EDITOR") return (
      <div style={{ borderRadius: 3 }}>
        <JoditEditor
          onBlur={code => !disabled && config?.setFieldValue(name.toString(), sanitizedHtml(code), true)}
          onChange={code => !disabled && config?.setFieldValue(name.toString(), sanitizedHtml(code), true)}
          value={config?.values[name] ?? ""}
        />
      </div>
    )
    else return (
      <div style={{ borderRadius: 3, padding: 10 }} dangerouslySetInnerHTML={{ __html: config?.values[name] ? sanitizedHtml(config?.values[name]) : "" }} />
    )
  }
  return (
    <div className={className} style={style}>
      <div className='custom-flex-row-space-between'>
        <Form.Label><b>{label}</b></Form.Label>
        <div>
          <ButtonGroup>
            <ToggleButton style={{zIndex: 0}} variant={mode === "EDITOR" ? 'success' : ''} value="EDITOR" type="radio" checked={mode === "EDITOR"} disabled={disabled} onClick={(evt) => setMode("EDITOR")}>Editor</ToggleButton>
            <ToggleButton style={{zIndex: 0}} variant={mode === "RAW" ? 'success' : ''} value="RAW" type="radio" checked={mode === "RAW"} onClick={(evt) => setMode("RAW")}>Raw Code</ToggleButton>
            <ToggleButton style={{zIndex: 0}} variant={mode === "PREVIEW" ? 'success' : ''} value="PREVIEW" type="radio" checked={mode === "PREVIEW"} onClick={(evt) => setMode("PREVIEW")}>Preview</ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      {editorRenderer()}
    </div>
  )
}

const FormCreatableMultiSelect = <Values extends FormikValues>(props: FormWithFormikCustom<Values, "CREATABLE_MULTI_SELECT"> & CreatableProps<ReactSelectDropdown, true, GroupBase<ReactSelectDropdown>>) => {
  const [search, setSearch] = useState("")
  const { name, config, type, label, ...anotherProps } = props

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!search) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const prev: Array<ReactSelectDropdown> = config?.values[name] ?? []
        config?.setFieldValue(name.toString(), [...prev, {
          label: search,
          value: search
        }], true)
        setSearch('');
        event.preventDefault();
    }
  };

  return (
    <Fragment>
      {label && <Form.Label><b>{label}</b></Form.Label>}
      <CreatableSelect 
        styles={{
          control: (base) => ({
            ...base,
            border: props.config?.errors[props.name] ? '1px solid red' : undefined
          } as CSSObjectWithLabel)
        }}
        components={{
          DropdownIndicator: null
        }}
        name={name.toString()}
        defaultValue={config?.values[name]}
        value={config?.values[name]}
        isClearable
        isMulti
        menuIsOpen={false}
        inputValue={search}
        onInputChange={setSearch}
        onChange={(nv)=>config?.setFieldValue(name.toString(), nv, true)}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press enter..."
        {...anotherProps}
      />
    </Fragment>
  )
}

const FormAsyncCreatableSelect = <Values extends FormikValues>(props: FormWithFormikCustom<Values, "ASYNC_CREATABLE_SELECT"> & AsyncCreatableProps<ReactSelectDropdown, false, GroupBase<ReactSelectDropdown>>) => {
  const { name, config, type, label, ...anotherProps } = props

  return (
    <Fragment>
      {label && <Form.Label><b>{label}</b></Form.Label>}
      <AsyncCreatableSelect
        styles={{
          control: (base) => ({
            ...base,
            border: props.config?.errors[props.name] ? '1px solid red' : undefined
          } as CSSObjectWithLabel)
        }}
        isClearable
        cacheOptions
        defaultOptions
        name={name.toString()}
        defaultValue={config?.values[name]}
        value={config?.values[name]}
        onChange={(nv,meta)=>{
          if(nv && meta.action === "select-option") config?.setFieldValue(name.toString(), nv, true)
          else if(nv && meta.action === "create-option") config?.setFieldValue(name.toString(), {
            label: nv.value,
            value: undefined
          }, true)
          else config?.setFieldValue(name.toString(), undefined, true)
        }}
        {...anotherProps}
      />
    </Fragment>
  )
}

const UploadFileComponent = <Values extends FormikValues>(props: FormWithFormikCustom<Values, "UPLOAD_FILE"> & FormImageProps<Values>) => {
  const { name, config, type, label, ...anotherProps } = props
  
  return (
    <Fragment>
      {label && <Form.Label><b>{label}</b></Form.Label>}
      <Form.Control 
        type="file"
        onChange={(evt: ChangeEvent<HTMLInputElement>)=>{
          const {files} = evt.target
          if(files) {
            const newFiles = Array.from(files).filter(f => {
              if(anotherProps.allowedMimes) {
                if(anotherProps.allowedMimes.find(allow => allow.toLowerCase() === f.type.toLowerCase())) return true
                else return false
              }
              else return true
            })
            config?.setFieldValue(name.toString(), {
              ...config?.values[name], 
              originFileObjs: newFiles
            })
          }
        }}
        accept={anotherProps.allowedMimes?.join(',')}
      />
      {anotherProps.affix && anotherProps.affix(config?.values)}
    </Fragment>
  )
}

export default FormWithFormik