import React, { Component, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from './Form';
import services from '../../../crud/tokoscholarContact';

const ViewContactUs = (props) => {
  const [isEdit, setisEdit] = useState(true);
  const [detailData, setdetailData] = useState(null);
  const formRef = useRef(null);
  const { id } = useParams();

  const getToken = () => localStorage.getItem('token');

  const getContactDetail = async (id) => {
    try {
      const res = await services.fetchContactById(getToken(), id);
      setdetailData(res.data.data);
      console.log(res, 'res');
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (id) getContactDetail(id);
  }, [id]);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            {/* <div className='d-flex justify-content-between align-content-center'>
              <div className=''>
                <p className='d-inline-block font-weight-bold'>
                  Create New Course
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <Form submitRef={formRef} isEdit={isEdit} data={detailData} />
            {/* <div className='flex-fill mt-5 d-flex justify-content-end align-items-center'>
              <button
                class='btn btn-outline-warning mr-2'
                type='submit'
                onClick={() => formRef.current()}
              >
                Submit
              </button>
              <button
                class='btn btn-outline-danger '
                type='submit'
                onClick={() => props.history.push('/tokoscholar/courses')}
              >
                Back To Course List
              </button>
            </div> */}
            <div className='row'>
              <div className='col-md-4'>
                <button
                  onClick={() => props.history.push('/tokoscholar/contactus')}
                  type='button'
                  className={`btn btn-info`}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewContactUs;
