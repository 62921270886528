import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
// import './bookingOrder.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { daysOption } from './configurationList.constant';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';
require('bootstrap/scss/bootstrap.scss');

const ThubConfigurationListEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const [stringToken, setStringToken] = useState();
  const [configurationDetail, setConfigurationDetail] = useState();
  const [daysList, setDaysList] = useState([]);

  const params = useParams();

  const editConfigurationType = async (data) => {
    setLoading(true);
    const { open_days, max_day_booked, ...hourlyData } = data;
    const { max_hour_booked, ...dailyData } = data;
    const formData = data.config_type === 'daily' ? dailyData : hourlyData;

    try {
      const response = await thubActions.fetchThubConfigurationEdit(
        stringToken,
        formData,
        params.id
      );

      if (response.data.code === 200 || response.data.code === 201) {
        toast.success('Edit configuration type success');
        setLoading(false);

        setTimeout(() => {
          props.history.push('/thub/configuration/list');
          // window.location.reload();
        }, 1500);
      }
    } catch (e) {
      setLoading(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          props.history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const getConfigurationDetail = async (configId) => {
    setLoading(true);
    try {
      const response = await thubActions.fetchThubConfigurationDetail(
        stringToken,
        configId
      );
      const { data } = response.data;

      setConfigurationDetail(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setConfigurationDetail([]);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          props.history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const handleGenerateDays = (data) => {
    const selectedDays = data.map((val) => {
      const label = val.charAt(0).toUpperCase() + val.slice(1);
      return {
        label: label,
        value: val,
      };
    });
    return selectedDays;
  };

  const handleGenerateAdmin = (data) => {
    const selectedAdmin = data.map((val) => {
      return {
        email_admins: val,
      };
    });
    return selectedAdmin;
  };

  const handleAddAdmins = () => {
    const _fields = [...configFormik.values.email_admins];
    _fields.push({
      email_admins: '',
    });
    configFormik.setFieldValue('email_admins', _fields);
  };

  const handleRemoveAdmins = (i) => {
    const _fields = [...configFormik.values.email_admins];
    _fields.splice(i, 1);
    configFormik.setFieldValue('email_admins', _fields);
  };

  const onChangeAdmins = (i, e) => {
    const adminsValue = configFormik.values.email_admins;
    const _adminsValue = [...adminsValue];
    _adminsValue[i][e.target.name] = e.target.value;

    configFormik.setFieldValue('email_admins', _adminsValue);
  };

  const handleChangeOpenDays = (e) => {
    setDaysList(e);
    const daysData =
      (Array.isArray(e) &&
        e.reduce(
          (prev, cur) =>
            prev === '' ? (prev = cur.value) : (prev += ',' + cur.value),
          ''
        )) ||
      '';

    configFormik.setFieldValue('open_days', daysData);
  };

  const validationSchema = Yup.object().shape({
    config_name: Yup.string().required('Config name is required'),
    config_type: Yup.string().required('Config type is required'),
    max_day_ahead: Yup.string().required('Max day ahead is required'),
    email_admins: Yup.array()
      .of(
        Yup.object().shape({
          email_admins: Yup.string().required('Email admins is required'),
        })
      )
      .min(1, 'You need at least 1 email admins')
      .required(),
    // email_admins: Yup.string().required('Email admins is required'),
    // open_days: Yup.mixed().required('Open days is required'),
    // max_day_booked: Yup.string().required(
    //   'Max day booked is required'
    // ),
    // max_hour_booked: Yup.string().required(
    //   'Max hour booked is required'
    // ),
    // open_time: Yup.string().required('Open Time is required'),
    // close_time: Yup.string().required('Close Time is required'),
  });

  const configFormik = useFormik({
    initialValues: {
      config_name: '',
      config_type: '',
      max_day_ahead: '',
      email_admins: [],
      open_days: '',
      max_day_booked: '',
      max_hour_booked: '',
      open_time: '',
      close_time: '',
      temp_open_time: '',
      temp_close_time: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { temp_close_time, temp_open_time, ...rest } = values;
      editConfigurationType(rest);
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
  }, []);

  useEffect(() => {
    if (stringToken && typeof stringToken !== 'undefined') {
      getConfigurationDetail(params.id);
    }
  }, [stringToken]);

  const formatTime = (time) => {
    return moment('2022-02-02 ' + time)
      .add(moment().utcOffset(), 'minute')
      .format('HH:mm');
  };

  const handleChangeTime = (e) => {
    try {
      const value = moment('2022-02-02 ' + e.target.value)
        .subtract(moment().utcOffset(), 'minute')
        .format('HH:mm');
      configFormik.setFieldValue(e.target.name, value);
      configFormik.setFieldValue('temp_' + e.target.name, e.target.value);
    } catch (error) {}
  };

  useEffect(() => {
    if (configurationDetail && typeof configurationDetail === 'object') {
      const {
        config_name,
        config_type,
        email_admins,
        max_day_ahead,
        max_day_booked,
        max_hour_booked,
        open_days,
        open_time,
        close_time,
      } = configurationDetail;
      const daysData = handleGenerateDays(open_days);
      const adminData = handleGenerateAdmin(email_admins);

      setFieldValue('config_name', config_name);
      setFieldValue('config_type', config_type);
      setFieldValue('email_admins', adminData);
      setFieldValue('max_day_ahead', max_day_ahead);
      setFieldValue('max_day_booked', max_day_booked);
      setFieldValue('max_hour_booked', max_hour_booked);
      setFieldValue('open_days', open_days);
      setFieldValue('open_time', open_time);
      setFieldValue('close_time', close_time);
      setFieldValue('temp_open_time', formatTime(open_time));
      setFieldValue('temp_close_time', formatTime(close_time));

      setDaysList(daysData);
    }
  }, [configurationDetail]);

  const { handleBlur, handleChange, errors, touched, values, setFieldValue } =
    configFormik;

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form
                className='m-login__form m-form'
                onSubmit={(e) => {
                  e.preventDefault();
                  configFormik.handleSubmit(e);
                }}
              >
                <div className='row'>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Config Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter config name. Eg: Thub Bali Daily'
                        value={values.config_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='config_name'
                        className={
                          touched.config_name && errors.config_name && 'error'
                        }
                      />
                      {touched.config_name && errors.config_name && (
                        <div className='error-message'>
                          {errors.config_name}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Config Type</Form.Label>
                      <Form.Control
                        as='select'
                        value={values.config_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='config_type'
                        className={
                          touched.config_type && errors.config_type && 'error'
                        }
                      >
                        <option selected>Select Config Type</option>
                        <option value='daily'>Daily</option>
                        <option value='hourly'>Hourly</option>
                      </Form.Control>
                      {touched.config_type && errors.config_type && (
                        <div className='error-message'>
                          {errors.config_type}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Max Day Ahead</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter max day ahead for this config. Eg: 7'
                        value={values.max_day_ahead}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='max_day_ahead'
                        className={
                          touched.max_day_ahead &&
                          errors.max_day_ahead &&
                          'error'
                        }
                      />
                      {touched.max_day_ahead && errors.max_day_ahead && (
                        <div className='error-message'>
                          {errors.max_day_ahead}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>

                {values.config_type === 'daily' && (
                  <Fragment>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Group>
                          <Form.Label>Open Days</Form.Label>
                          <div className='input-group col-md-12 p-0'>
                            <div className='input-group'>
                              <Select
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    width: '100%',
                                  }),
                                }}
                                name='facilities'
                                isMulti
                                options={daysOption}
                                classNamePrefix='select'
                                onChange={handleChangeOpenDays}
                                onBlur={handleBlur}
                                className={`${
                                  touched.open_days &&
                                  errors.open_days &&
                                  'error'
                                } basic-multi-select`}
                                value={daysList}
                              />
                            </div>
                          </div>
                          {touched.open_days && errors.open_days && (
                            <div className='error-message'>
                              {errors.open_days}
                            </div>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Group>
                          <Form.Label>Max Day Booked</Form.Label>
                          <Form.Control
                            type='number'
                            placeholder='Enter max day booked for this config. Eg: 7'
                            value={values.max_day_booked}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='max_day_booked'
                            className={
                              touched.max_day_booked &&
                              errors.max_day_booked &&
                              'error'
                            }
                          />
                          {touched.max_day_booked && errors.max_day_booked && (
                            <div className='error-message'>
                              {errors.max_day_booked}
                            </div>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </Fragment>
                )}
                {values.config_type === 'hourly' && (
                  <Fragment>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Group>
                          <Form.Label>Max Hour Booked</Form.Label>
                          <Form.Control
                            type='number'
                            placeholder='Enter max hour booked for this config. Eg: 5'
                            value={values.max_hour_booked}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='max_hour_booked'
                            className={
                              touched.max_hour_booked &&
                              errors.max_hour_booked &&
                              'error'
                            }
                          />
                          {touched.max_hour_booked &&
                            errors.max_hour_booked && (
                              <div className='error-message'>
                                {errors.max_hour_booked}
                              </div>
                            )}
                        </Form.Group>
                      </div>
                    </div>
                  </Fragment>
                )}
                <div className='row'>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Open Time</Form.Label>
                      <Form.Control
                        type='time'
                        placeholder='Enter open time. Eg: 08:00'
                        value={values.temp_open_time}
                        onChange={(e) => handleChangeTime(e)}
                        onBlur={handleBlur}
                        name='open_time'
                        className={
                          touched.temp_open_time &&
                          errors.temp_open_time &&
                          'error'
                        }
                      />
                      {touched.temp_open_time && errors.temp_open_time && (
                        <div className='error-message'>
                          {errors.temp_open_time}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Close Time</Form.Label>
                      <Form.Control
                        type='time'
                        placeholder='Enter close time. Eg: 17:00'
                        value={values.temp_close_time}
                        onChange={(e) => handleChangeTime(e)}
                        onBlur={handleBlur}
                        name='close_time'
                        className={
                          touched.temp_close_time &&
                          errors.temp_close_time &&
                          'error'
                        }
                      />
                      {touched.temp_close_time && errors.temp_close_time && (
                        <div className='error-message'>
                          {errors.temp_close_time}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Email Admins</Form.Label>
                      <div className='row'>
                        <div className='col-md-12 mb-2'>
                          <button
                            type='button'
                            onClick={() => handleAddAdmins()}
                            className='btn btn-sm btn-outline-success'
                          >
                            Add Email Admins
                          </button>
                        </div>
                        <div className='col-md-12'>
                          {values.email_admins &&
                            values.email_admins.length > 0 &&
                            values.email_admins.map((element, i) => {
                              return (
                                <Fragment key={i}>
                                  <div className='row mt-3 mb-2'>
                                    <div className='col-md-2'>
                                      <button
                                        type='button'
                                        onClick={() => handleRemoveAdmins(i)}
                                        className='btn btn-sm btn-outline-danger mt-1'
                                      >
                                        Remove
                                      </button>
                                    </div>
                                    <div className='col-md-10'>
                                      <Form.Group>
                                        <Form.Control
                                          placeholder='Eg: email@email.com'
                                          name={`email_admins[${i}].email_admins`}
                                          type='email'
                                          className={`form-control ${
                                            touched[`email_admins`] &&
                                            touched[`email_admins`][i] &&
                                            touched[`email_admins`][i]
                                              .email_admins &&
                                            errors[`email_admins`] &&
                                            errors[`email_admins`][i] &&
                                            errors[`email_admins`][i]
                                              .email_admins &&
                                            'error'
                                          }`}
                                          value={element.email_admins}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {touched[`email_admins`] &&
                                          touched[`email_admins`][i] &&
                                          touched[`email_admins`][i]
                                            .email_admins &&
                                          errors[`email_admins`] &&
                                          errors[`email_admins`][i] &&
                                          errors[`email_admins`][i]
                                            .email_admins && (
                                            <div className='error-message'>
                                              {
                                                errors[`email_admins`][i]
                                                  .email_admins
                                              }
                                            </div>
                                          )}
                                      </Form.Group>
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })}
                        </div>
                      </div>
                      {typeof errors.email_admins === 'string' &&
                        errors.email_admins && (
                          <div className='error-message'>
                            {errors.email_admins}
                          </div>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div
                  className='kt-login__actions'
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <button
                    type='button'
                    disabled={loading}
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/configuration/list');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                  >
                    Edit Configurations
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThubConfigurationListEdit;
