export type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export type ResponseAPI<T> = ResponseSuccessWrapper<T> | ResponseFailWrapper<T>;

export interface ResponseSuccessWrapper<T> {
  message: string;
  code: IntRange<0, 300>;
  timestamp: string;
  data: T;
}
export interface ResponseFailWrapper<T> {
  data?: T;
  message: string;
  code: IntRange<301, 599>;
  timestamp: string;
}
export interface Pagination<T> {
  total_data: number;
  total_page: number;
  limit: number;
  page: number;
  data: Array<T>;
}

export interface PaginationResponse<T> {
  data: Array<T>;
  current_page: number;
  per_page: number;
  total_data: number;
  total_page: number;
}

export type PaginationRequest<T = object> = {
  limit: number;
  page: number;
  q?: string;
} & T

export enum MimeTypes {
  ".aac" = "audio/aac",
  ".abw" = "application/x-abiword",
  ".arc" = "application/x-freearc",
  ".avi" = "video/x-msvideo",
  ".azw" = "application/vnd.amazon.ebook",
  ".bin" = "application/octet-stream",
  ".bmp" = "image/bmp",
  ".bz" = "application/x-bzip",
  ".bz2" = "application/x-bzip2",
  ".csh" = "application/x-csh",
  ".css" = "text/css",
  ".csv" = "text/csv",
  ".doc" = "application/msword",
  ".docx" = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".eot" = "application/vnd.ms-fontobject",
  ".epub" = "application/epub+zip",
  ".gz" = "application/gzip",
  ".gif" = "image/gif",
  ".htm" = "text/html",
  ".html" = "text/html",
  ".ico" = "image/vnd.microsoft.icon",
  ".ics" = "text/calendar",
  ".jar" = "application/java-archive",
  ".jpeg" = ".jpg",
  ".js" = "text/javascript",
  ".json" = "application/json",
  ".jsonld" = "application/ld+json",
  ".mid" = ".midi",
  ".mjs" = "text/javascript",
  ".mp3" = "audio/mpeg",
  ".mpeg" = "video/mpeg",
  ".mpkg" = "application/vnd.apple.installer+xml",
  ".odp" = "application/vnd.oasis.opendocument.presentation",
  ".ods" = "application/vnd.oasis.opendocument.spreadsheet",
  ".odt" = "application/vnd.oasis.opendocument.text",
  ".oga" = "audio/ogg",
  ".ogv" = "video/ogg",
  ".ogx" = "application/ogg",
  ".opus" = "audio/opus",
  ".otf" = "font/otf",
  ".png" = "image/png",
  ".pdf" = "application/pdf",
  ".php" = "application/php",
  ".ppt" = "application/vnd.ms-powerpoint",
  ".pptx" = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ".rar" = "application/vnd.rar",
  ".rtf" = "application/rtf",
  ".sh" = "application/x-sh",
  ".svg" = "image/svg+xml",
  ".swf" = "application/x-shockwave-flash",
  ".tar" = "application/x-tar",
  ".tif" = "image/tiff",
  ".tiff" = "image/tiff",
  ".ts" = "video/mp2t",
  ".ttf" = "font/ttf",
  ".txt" = "text/plain",
  ".vsd" = "application/vnd.visio",
  ".wav" = "audio/wav",
  ".weba" = "audio/webm",
  ".webm" = "video/webm",
  ".webp" = "image/webp",
  ".woff" = "font/woff",
  ".woff2" = "font/woff2",
  ".xhtml" = "application/xhtml+xml",
  ".xls" = "application/vnd.ms-excel",
  ".xlsx" = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xml" = "XML",
  ".xul" = "application/vnd.mozilla.xul+xml",
  ".zip" = "application/zip",
  ".3gp" = "video/3gpp",
  ".3g2" = "video/3gpp2",
  ".7z" = "application/x-7z-compressed"
}

export const mimeTypes = {
  ".aac": "audio/aac",
  ".abw": "application/x-abiword",
  ".arc": "application/x-freearc",
  ".avi": "video/x-msvideo",
  ".azw": "application/vnd.amazon.ebook",
  ".bin": "application/octet-stream",
  ".bmp": "image/bmp",
  ".bz": "application/x-bzip",
  ".bz2": "application/x-bzip2",
  ".csh": "application/x-csh",
  ".css": "text/css",
  ".csv": "text/csv",
  ".doc": "application/msword",
  ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".eot": "application/vnd.ms-fontobject",
  ".epub": "application/epub+zip",
  ".gz": "application/gzip",
  ".gif": "image/gif",
  ".htm": "text/html",
  ".html": "text/html",
  ".ico": "image/vnd.microsoft.icon",
  ".ics": "text/calendar",
  ".jar": "application/java-archive",
  ".jpeg": ".jpg",
  ".js": "text/javascript",
  ".json": "application/json",
  ".jsonld": "application/ld+json",
  ".mid": ".midi",
  ".mjs": "text/javascript",
  ".mp3": "audio/mpeg",
  ".mpeg": "video/mpeg",
  ".mpkg": "application/vnd.apple.installer+xml",
  ".odp": "application/vnd.oasis.opendocument.presentation",
  ".ods": "application/vnd.oasis.opendocument.spreadsheet",
  ".odt": "application/vnd.oasis.opendocument.text",
  ".oga": "audio/ogg",
  ".ogv": "video/ogg",
  ".ogx": "application/ogg",
  ".opus": "audio/opus",
  ".otf": "font/otf",
  ".png": "image/png",
  ".pdf": "application/pdf",
  ".php": "application/php",
  ".ppt": "application/vnd.ms-powerpoint",
  ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ".rar": "application/vnd.rar",
  ".rtf": "application/rtf",
  ".sh": "application/x-sh",
  ".svg": "image/svg+xml",
  ".swf": "application/x-shockwave-flash",
  ".tar": "application/x-tar",
  ".tif": "image/tiff",
  ".tiff": "image/tiff",
  ".ts": "video/mp2t",
  ".ttf": "font/ttf",
  ".txt": "text/plain",
  ".vsd": "application/vnd.visio",
  ".wav": "audio/wav",
  ".weba": "audio/webm",
  ".webm": "video/webm",
  ".webp": "image/webp",
  ".woff": "font/woff",
  ".woff2": "font/woff2",
  ".xhtml": "application/xhtml+xml",
  ".xls": "application/vnd.ms-excel",
  ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xml": "XML",
  ".xul": "application/vnd.mozilla.xul+xml",
  ".zip": "application/zip",
  ".3gp": "video/3gpp",
  ".3g2": "video/3gpp2",
  ".7z": "application/x-7z-compressed"
}

export interface FilePreviewUrl {
  contentPreview: 'video' | 'image' | 'pdf';
  preview: string;
}