import React from 'react';
import ReactLoading from 'react-loading';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import actions from '../../crud/admin';
import {
  Button,
  Table,
  Modal,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
} from 'react-bootstrap';
import {
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
// import _ from 'lodash';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
require('bootstrap/scss/bootstrap.scss');

const history = createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth,
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth,
  },
  default: {
    class: 'sort',
    fn: (a, b) => a,
  },
};

export default class User extends React.Component {
  detail(id) {
    history.push('/user/' + id + '/kyc');
    window.location.reload();
  }
  history() {
    history.push('/admin/add-admin');
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalData: 0,
      userKYC: [],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      show: false,
      shows: false,
      showPassword: false,
      showDelete: false,
      params: '',
      SelectDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      email: '',
      password: '',
      confirmPassword: '',
      role: 'cs',
      rules: [],
      emailError: '',
      passwordError: '',
      passwordConfirmError: '',
      roleError: '',
      switchValues: [],
      emailEdit: '',
      passwordEdit: '',
      confirmPasswordEdit: '',
      roleEdit: '',
      switchValue: '0',
      emailErrorEdit: '',
      passwordErrorEdit: '',
      passwordConfirmErrorEdit: '',
      roleErrorEdit: '',
      type: '',
      selectedRoleEdit: null,
    };

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({});
      obj[fieldName] = event.target.value;
      obj.resetCapctha = false;
      this.setState(obj);
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShows = this.handleShows.bind(this);
    this.handleCloses = this.handleCloses.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.handleGenerateRole = this.handleGenerateRole.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleCloses() {
    this.setState({ shows: false });
  }

  handleShows(type, param) {
    this.setState({ shows: true, params: param, type: type });
    this.getDetail(param);
  }

  handleDelete() {
    this.setState({ showDelete: false });
  }

  handleDeleteOpen(param) {
    this.setState({ showDelete: true, params: param });
  }

  handleClickShowPassword() {
    if (this.state.showPassword === false) {
      this.setState({ showPassword: true });
    } else {
      this.setState({ showPassword: false });
    }
  }

  getToken() {
    let token = localStorage.getItem('token');
    return token;
  }

  async checkParams() {
    let params = '';
    if (this.state.orderBy !== '') {
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`;
    } else {
      params = `?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`;
    }

    this.setState({
      loading: true,
    });
    actions
      .fetchAdmin(this.getToken(), params)
      .then((res) => {
        this.setState({
          totalData: res.data.data.total_data,
          userKYC: res.data.data.data,
          postPerPage: res.data.data.per_page,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          userKYC: [],
          loading: true,
        });
        if (e.response) {
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  onSortChange = async (name) => {
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });

    this.checkParams();
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  };

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber;
    this.setState(
      {
        active: activePage,
      },
      () => {
        this.checkParams();
      }
    );
  };

  dateTime = (date) => {
    var date1 = date;
    var fmtDate = moment(date1)
      .utc()
      .format('DD-MM-YY hh:m A');

    return fmtDate;
  };

  getDetail(id) {
    this.setState({
      loading: true,
    });

    actions
      .fetchDetailAdmin(this.getToken(), id)
      .then((res) => {
        this.setState({
          loading: false,
          show: false,
          emailEdit: res.data.data.email,
          roleEdit: res.data.data.role,
          switchValue: res.data.data.status.toString(),
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  addAdmin(addAdmin) {
    this.setState({
      loading: true,
    });

    actions
      .createAdmin(this.getToken(), addAdmin)
      .then((res) => {
        if (res.data.code === 200) {
          toast.success('Add new admin success');
        }
        this.setState({
          show: false,
          email: '',
          password: '',
          confirmPassword: '',
          role: '',
          showPassword: false,
        });
        this.checkParams();
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
        if (e.response) {
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  editAdmin(editAdmin) {
    this.setState({
      loading: true,
    });

    actions
      .updateAdmin(this.getToken(), this.state.params, editAdmin)
      .then((res) => {
        // console.log('auh', res.data.code)
        if (res.data.code === 200) {
          toast.success('Update success');
          if (editAdmin.email === localStorage.getItem('user_mail')) {
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          }
        }
        this.setState({
          // loading: false,
          shows: false,
          passwordEdit: '',
          confirmPasswordEdit: '',
        });
        this.checkParams();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          shows: false,
        });
        if (e.response) {
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  isValidate() {
    let emailError = '';
    let passwordError = '';
    let passwordConfirmError = '';
    let roleError = '';

    if (!this.state.email) {
      emailError = 'email is required ';
    }
    if (this.state.password.length < 8) {
      passwordError = 'password must be 8 characters ';
    }
    if (this.state.password !== this.state.confirmPassword) {
      passwordConfirmError = 'Passwords do not match';
    }
    if (!this.state.role) {
      roleError = 'role is required ';
    }

    if (
      emailError ||
      passwordError ||
      passwordConfirmError ||
      roleError
    ) {
      this.setState({
        emailError: emailError,
        passwordError: passwordError,
        passwordConfirmError: passwordConfirmError,
        roleError: roleError,
      });
      return false;
    }

    return true;
  }

  isValidateEditAdmin() {
    let emailErrorEdit = '';

    if (!this.state.emailEdit) {
      emailErrorEdit = 'email is required ';
    }

    if (emailErrorEdit) {
      this.setState({
        emailErrorEdit: emailErrorEdit,
      });
      return false;
    }

    return true;
  }

  isValidateResetAdmin() {
    let passwordErrorEdit = '';
    let passwordConfirmErrorEdit = '';

    if (this.state.passwordEdit.length < 8) {
      passwordErrorEdit = 'password must be 8 characters ';
    }
    if (this.state.passwordEdit !== this.state.confirmPasswordEdit) {
      passwordConfirmErrorEdit = 'Passwords do not match';
    }

    if (passwordErrorEdit || passwordConfirmErrorEdit) {
      this.setState({
        passwordErrorEdit: passwordErrorEdit,
        passwordConfirmErrorEdit: passwordConfirmErrorEdit,
      });
      return false;
    }

    return true;
  }

  isSubmit = (event) => {
    const addAdmin = {
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.confirmPassword,
      role: this.state.role,
    };
    event.preventDefault();
    const validate = this.isValidate();
    if (validate) {
      this.addAdmin(addAdmin);
    }
  };

  isUpdate = (event) => {
    let editAdmin = {};
    let stat = -1;
    if (this.state.type === '1') {
      editAdmin = {
        email: this.state.emailEdit,
        role: this.state.roleEdit,
        status: parseInt(this.state.switchValue),
      };
      if (this.isValidateEditAdmin) {
        this.editAdmin(editAdmin);
      }
    } else {
      editAdmin = {
        password: this.state.passwordEdit,
        confirm_password: this.state.confirmPasswordEdit,
        status: stat,
      };
      if (this.isValidateResetAdmin) {
        this.editAdmin(editAdmin);
      }
    }
    event.preventDefault();
  };

  delete() {
    this.setState({
      loading: true,
    });

    actions
      .deleteAdmin(this.getToken(), this.state.params)
      .then((res) => {
        if (res.data.code === 200) {
          toast.success('Delete success');
        }
        this.setState({
          loading: false,
          showDelete: false,
        });
        this.checkParams();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          showDelete: false,
        });
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      });
  }

  resetOTP(id) {
    this.setState({
      loading: true,
    });

    actions
      .updateResetOTP(this.getToken(), id)
      .then((res) => {
        if (res.data.code === 200) {
          toast.success('OTP reset success');
        }
        this.setState({
          showDelete: false,
        });
        this.checkParams();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          showDelete: false,
        });
        toast.error(
          `error code: ${e.response.data.code} message: ${e.response.data.message}`
        );
      });
  }

  componentDidMount() {
    this.checkParams();
  }

  componentDidUpdate(prevprops, prevstate) {
    if (prevstate.roleEdit !== this.state.roleEdit) {
      console.log('test');
      this.handleGenerateRole(this.state.roleEdit);
    }
  }

  roleOption = [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Community',
      value: 'community',
    },
    {
      label: 'Compliance',
      value: 'compliance',
    },
    {
      label: 'CS',
      value: 'cs',
    },
    {
      label: 'Finance',
      value: 'finance',
    },
    {
      label: 'Marketing',
      value: 'marketing',
    },
    {
      label: 'Sales',
      value: 'sales',
    },
    {
      label: 'Dashboard Viewer',
      value: 'dashboard_viewer',
    },
    {
      label: 'UID Converter',
      value: 'uid_converter',
    },
    {
      label: 'Data',
      value: 'data',
    },
    {
      label: 'Tokoscholar',
      value: 'tokoscholar',
    },
    {
      label: 'Thub',
      value: 'thub',
    },
    {
      label: 'Tax',
      value: 'tax',
    },
    {
      label: 'Helpdesk',
      value: 'helpdesk',
    },
    {
      label: 'KYC',
      value: 'kyc'
    }
    // {
    //   label: 'Superadmin',
    //   value: 'superadmin',
    // },
  ];

  handleChangeRole = (e) => {
    const stringRole =
      (Array.isArray(e) &&
        e.reduce(
          (prev, cur) =>
            prev === ''
              ? (prev = cur.value)
              : (prev += ',' + cur.value),
          ''
        )) ||
      '';
    this.setState({ role: stringRole });
  };

  handleChangeRoleEdit = (e) => {
    const stringRole =
      (Array.isArray(e) &&
        e.reduce(
          (prev, cur) =>
            prev === ''
              ? (prev = cur.value)
              : (prev += ',' + cur.value),
          ''
        )) ||
      '';
    this.setState({ roleEdit: stringRole });
  };

  handleGenerateRole = (data) => {
    console.log(data, 'data');
    const arrayRole = data.split(',');

    const selectedRole = arrayRole.map((val, i) => {
      const label = String(val)
        .split('_')
        // .map((val) => val[0].toUpperCase + val.slice());
        .join(' ');

      return {
        label: label,
        value: val,
      };
    });

    this.setState({ selectedRoleEdit: selectedRole });
    // return selectedRole;
  };

  render() {
    return (
      <div style={{ fontSize: '10px' }} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body pddmdForce">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input
                    type="text"
                    className="form-control mr-2"
                    name="search"
                    placeholder="Search"
                    value={this.state.seacrhParams}
                    onChange={this.changeSearch}
                  />
                  <button
                    className="btn btn-success btn-sm pr-5 pl-5"
                    type="button"
                    onClick={() => this.checkParams()}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div className="paddupgrade">
                  <Button
                    variant="success"
                    onClick={() => this.handleShow('add')}
                    style={{ fontSize: '11px' }}
                  >
                    Add new admin
                  </Button>
                </div>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th
                        className="tableheh"
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          width: '5%',
                        }}
                      >
                        No &nbsp;
                      </th>
                      <th
                        className="tableheh"
                        style={{ width: '20%' }}
                      >
                        Email &nbsp;
                        <button
                          onClick={(e) => this.onSortChange('email')}
                          className="sort-button"
                        >
                          <i
                            className={`fas fa-${
                              sortTypes[this.state.orderSort].class
                            }`}
                          />
                        </button>
                      </th>
                      <th
                        className="tableheh"
                        style={{ width: '15%' }}
                      >
                        Role &nbsp;
                        <button
                          onClick={(e) => this.onSortChange('role')}
                          className="sort-button"
                        >
                          <i
                            className={`fas fa-${
                              sortTypes[this.state.orderSort].class
                            }`}
                          />
                        </button>
                      </th>
                      <th
                        className="tableheh"
                        style={{ width: '15%' }}
                      >
                        Time Added &nbsp;
                      </th>
                      <th
                        className="tableheh"
                        style={{ width: '15%' }}
                      >
                        Added By &nbsp;
                      </th>
                      <th className="tableheh">Status &nbsp;</th>
                      <th
                        className="center tableheh"
                        style={{ width: '15%' }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? (
                    <tbody>
                      <tr>
                        <td className="center" colSpan="7">
                          <ReactLoading
                            style={{
                              position: 'relative',
                              left: '50%',
                              fill: 'rgb(88, 103, 221)',
                              height: '5%',
                              width: '5%',
                            }}
                            type={'bars'}
                            color={'#5867dd'}
                            height={'5%'}
                            width={'5%'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {this.state.userKYC &&
                      this.state.userKYC.length > 0 ? (
                        this.state.userKYC.map((row, index) => (
                          <tr key={index}>
                            <td className="center align-middle">
                              {index + 1}
                            </td>
                            <td className="align-middle">
                              {row.email}
                            </td>
                            <td className="align-middle">
                              {row.role}
                            </td>
                            <td className="align-middle">
                              {this.dateTime(row.created_at)}
                            </td>
                            <td className="align-middle">
                              {row.created_by}
                            </td>
                            <td className="align-middle">
                              {row.status === -2 && (
                                <span>not verified</span>
                              )}
                              {row.status === -1 && (
                                <span>
                                  haven't reset the password
                                </span>
                              )}
                              {row.status === 0 && (
                                <span>disabled</span>
                              )}
                              {row.status === 1 && (
                                <span>active</span>
                              )}
                            </td>
                            <td className="center">
                              {(row.status === 1 ||
                                row.status === 0) && (
                                <>
                                  <ButtonToolbar
                                    style={{
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-top`}>
                                          Update user
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="btn-action"
                                        onClick={() =>
                                          this.handleShows(
                                            '1',
                                            row.id
                                          )
                                        }
                                      >
                                        <i
                                          style={{
                                            fontSize: '1rem',
                                            paddingRight: '0',
                                          }}
                                          className="fas fa-pencil-alt text-info"
                                        />
                                      </span>
                                    </OverlayTrigger>
                                    &nbsp; | &nbsp;
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-top`}>
                                          Reset Password
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="btn-action"
                                        onClick={() =>
                                          this.handleShows(
                                            '2',
                                            row.id
                                          )
                                        }
                                      >
                                        <i
                                          style={{
                                            fontSize: '1rem',
                                            paddingRight: '0',
                                          }}
                                          className="fas fa-history text-warning"
                                        />
                                      </span>
                                    </OverlayTrigger>
                                    &nbsp; | &nbsp;
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-top`}>
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="btn-action"
                                        onClick={() =>
                                          this.handleDeleteOpen(
                                            row.id
                                          )
                                        }
                                      >
                                        <i
                                          style={{
                                            fontSize: '1rem',
                                            paddingRight: '0',
                                          }}
                                          className="fas fa-trash-alt text-danger"
                                        />
                                      </span>
                                    </OverlayTrigger>
                                  </ButtonToolbar>
                                </>
                              )}
                              {row.status === -2 && (
                                <>
                                  <span
                                    onClick={() =>
                                      this.resetOTP(row.id)
                                    }
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '55%',
                                    }}
                                    className="btn btn-outline-primary"
                                  >
                                    reset OTP
                                  </span>
                                  {/* <span className="btn-action btn-info"  ></span> */}
                                </>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            className="text-center text-danger"
                            colSpan={7}
                          >
                            Data Not Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new user admin panel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              className="m-login__form m-form"
              onSubmit={this.isSubmit}
            >
              <div
                className="form-group"
                style={{ marginBottom: '15px' }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  label="Email"
                  margin="normal"
                  className="kt-width-full"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                />
                <span className="required">
                  {this.state.emailError}
                </span>
              </div>

              <div
                className="form-group"
                style={{ marginBottom: '15px' }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  label="Password"
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            this.handleClickShowPassword()
                          }
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <span className="required">
                  {this.state.passwordError}
                </span>
              </div>

              <div
                className="form-group"
                style={{ marginBottom: '15px' }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  label="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange('confirmPassword')}
                  InputProps={{
                    'aria-label': 'Weight',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            this.handleClickShowPassword()
                          }
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <span className="required">
                  {this.state.passwordConfirmError}
                </span>
              </div>
              <div className="form-group">
                <label className="control-label col-md-6 p-0">
                  Select Role
                </label>
                <div className="input-group col-md-12  p-0">
                  <div className="input-group">
                    <Select
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          width: '100%',
                        }),
                      }}
                      name="role"
                      isMulti
                      options={this.roleOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleChangeRole}
                    />
                  </div>
                </div>
                <span className="required">
                  {this.state.roleError}
                </span>
              </div>

              <div
                className="kt-login__actions"
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <button
                  type="submit"
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': this
                        .state.loading,
                    }
                  )}`}
                >
                  Add
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.shows}
          onHide={this.handleCloses}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.type === '1' ? 'Update' : 'Reset'} user
              admin panel
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              className="m-login__form m-form"
              onSubmit={this.isUpdate}
            >
              {this.state.type === '1' && (
                <div>
                  <div
                    className="form-group"
                    style={{ marginBottom: '15px' }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="email"
                      label="Email"
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      value={this.state.emailEdit}
                      onChange={this.handleChange('emailEdit')}
                    />
                    <span className="required">
                      {this.state.emailErrorEdit}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-6 p-0">
                      Select Role
                    </label>
                    <div className="input-group col-md-12 p-0">
                      <div className="input-group">
                        <Select
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              width: '100%',
                            }),
                          }}
                          name="role"
                          isMulti
                          options={this.roleOption}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.selectedRoleEdit}
                          onChange={this.handleChangeRoleEdit}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-6 p-0">
                      User Status
                    </label>
                    <div className="input-group col-md-12 p-0">
                      <div className="input-group">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              switchValue: e.target.value,
                            })
                          }
                        >
                          <option defaultValue>
                            {this.state.switchValue === '1'
                              ? 'Enable'
                              : 'Disable'}
                          </option>
                          <option value="0">Disable</option>
                          <option value="1">Enable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.type === '2' && (
                <div>
                  <div
                    className="form-group"
                    style={{ marginBottom: '15px' }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      type={
                        this.state.showPassword ? 'text' : 'password'
                      }
                      label="Password"
                      value={this.state.passwordEdit}
                      onChange={this.handleChange('passwordEdit')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                this.handleClickShowPassword()
                              }
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <span className="required">
                      {this.state.passwordErrorEdit}
                    </span>
                  </div>

                  <div
                    className="form-group"
                    style={{ marginBottom: '15px' }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      type={
                        this.state.showPassword ? 'text' : 'password'
                      }
                      label="confirmPassword"
                      value={this.state.confirmPasswordEdit}
                      onChange={this.handleChange(
                        'confirmPasswordEdit'
                      )}
                      InputProps={{
                        'aria-label': 'Weight',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                this.handleClickShowPassword()
                              }
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <span className="required">
                      {this.state.passwordConfirmErrorEdit}
                    </span>
                  </div>
                </div>
              )}

              <div
                className="kt-login__actions"
                style={{
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <Button variant="danger" onClick={this.handleCloses}>
                  Cancel
                </Button>
                <button
                  type="submit"
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': this
                        .state.loading,
                    }
                  )}`}
                >
                  {this.state.type === '1' ? 'Update' : 'Reset'}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showDelete}
          onHide={this.handleDelete}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header closeButton>
                <Modal.Title>{this.state.type === '1' ? 'Update' : 'Reset'} user admin panel</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <span>are you sure want to delete ?</span>
            <div
              className="kt-login__actions"
              style={{
                justifyContent: 'space-around',
                display: 'flex',
                marginTop: '30px',
              }}
            >
              <Button onClick={this.handleDelete}>Cancel</Button>
              <Button variant="danger" onClick={() => this.delete()}>
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
