import { FC, forwardRef, useEffect, useReducer, useRef, useState, PropsWithChildren, CSSProperties, ReactNode } from "react";
import "./_unauth.scss"
import { Link } from "react-router-dom";
import { CategoryRequest, HelpdeskStatus } from "../../../crud/helpdesk-types";
import { publicGetCategories } from "../../../crud/helpdesk";
import { useDebounce } from "../../../helper";
interface InitialState {
  topHeight: number;
  categories: Array<CategoryRequest>;
  q?: string;
}

const initialState: InitialState = {
  topHeight: 0,
  categories: []
}

const UnauthHelpdesk: FC = () => {
  const [state,dispatch] = useReducer((s: InitialState,ns: Partial<InitialState>) => ({...s,...ns}), initialState)
  
  const getCategories = (q?: string) => {
    publicGetCategories(q)
      .then((res) => dispatch({ 
        categories: res.data.data?.sort((a,b) => {
          if((a.id ?? 0) > (b.id ?? 0)) return 1
          else return -1
        }) 
      }))
      .catch(err => console.log(err))
  }
  useDebounce<[string | undefined]>(getCategories,[state.q], 400)

  const categoriesCard = (c: CategoryRequest, i: number) => {
    if(c.status === HelpdeskStatus.DISABLE) return (
      <div 
        key={i}
        className={`helpdesk-card__item helpdesk-card__item--disabled`}
      >
        <img 
          src={c.image_url} 
          alt={c.title} 
          width={119}
        />
        <h3>{c.title}</h3>
        <span>{c.description}</span>
      </div>
    )
    else return (
      <Link 
        key={i}
        to={`/helpdesk-request/form/${c.code}`}
        className="helpdesk-card__item"
      >
        <img 
          src={c.image_url} 
          alt={c.title} 
          width={119}
        />
        <h3>{c.title}</h3>
        <span>{c.description}</span>
      </Link>
    )
  }

  return (
    <HelpdeskWrapper>
      <div className="helpdesk-wrapper">
        <div className="helpdesk-card">
          {state.categories.filter(c => c.status !== HelpdeskStatus.DISABLE).map((c,i) => categoriesCard(c,i))}
        </div>
      </div>
    </HelpdeskWrapper>
  )
}

export interface HelpdeskWrapperProps {
  bannerStyle?: CSSProperties;
  bannerExtra?: ReactNode;
  isWithoutBanner?: boolean;
  topbarStyle?: CSSProperties;
  topbarMenuStyle?: CSSProperties;
  className?: string;
}

export const HelpdeskWrapper: FC<PropsWithChildren<HelpdeskWrapperProps>> = (props) => {
  const [state,dispatch] = useReducer((s: InitialState,ns: Partial<InitialState>)=>({...s,...ns}), initialState)
  const topbar = useRef<HTMLDivElement>(null)

  useEffect(()=>{
    dispatch({topHeight: topbar.current?.offsetHeight ?? 0})
  }, [topbar.current?.offsetHeight])

  return (
    <div className="helpdesk">
      <HelpdeskTopbar 
        ref={topbar} 
        style={props.topbarStyle}
        menuStyle={props.topbarMenuStyle}
      />
      {!props.isWithoutBanner && 
        <div 
          className="helpdesk__banner" 
          style={{
            marginTop: state.topHeight,
            ...props.bannerStyle
          }}
        >
          {props.bannerExtra}
        </div>
      }
      <div 
        className={`helpdesk-wrapper ${props.className ?? ""}`}
        style={{
          marginTop: props.isWithoutBanner ? state.topHeight : undefined
        }}
      >
        {props.children}
      </div>
    </div>
  )
}

export interface HelpdeskTopbarProps {
  style?: CSSProperties;
  menuStyle?: CSSProperties;
}

export const HelpdeskTopbar = forwardRef<HTMLDivElement, HelpdeskTopbarProps>((props, ref) => {
  const [isTop, setIsTop] = useState<boolean>(false)

  useEffect(()=>{
    const callbackListener = () => {
      setIsTop(window.scrollY === 0)
    }

    callbackListener()

    window.addEventListener("scroll", () => callbackListener())

    return () => window.removeEventListener("scroll", () => callbackListener())
  },[])

  return (
    <div 
      ref={ref} 
      className="helpdesk__topbar"
      style={{
        boxShadow: isTop ? undefined : "0 .5rem 1rem rgba(0,0,0,.15)",
        ...props.style
      }}
    >
      <div className="helpdesk-wrapper custom-flex-row-space-between-center">
        <img src="/media/helpdesk/tokocrypto_logo.svg" alt="logo" />
        <div className="custom-flex-row gap-5">
          <Link className="helpdesk__topbar__menu" to="/helpdesk-request" style={props.menuStyle}>HOME</Link>
          <Link className="helpdesk__topbar__menu" to="/helpdesk-request/ticket-status" style={props.menuStyle}>TIKET STATUS</Link>
          <Link className="helpdesk__topbar__menu" to="/helpdesk-request/faq" style={props.menuStyle}>FAQ</Link>
        </div>
      </div>
    </div>
  )
})

HelpdeskTopbar.displayName = "HelpdeskTopbar"

export default UnauthHelpdesk