import { FC, ReactElement } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faUsers, faGear, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { Container, Stack } from "react-bootstrap";
import '../../scss/global.scss';
import { Link } from "react-router-dom";


interface CardAttribute {
    icon?: ReactElement<SVGAElement>;
    title: string;
    subAttribute?: Array<SubAttribute>
}

interface SubAttribute {
    title: string;
    desc: string;
    href?: string;
}

const SettingMiddleware: FC = () => {

    const cards: Array<CardAttribute> = [
        {
            icon: <FontAwesomeIcon icon={faUsers} size={'xl'} />,
            title: 'User Management',
            subAttribute: [
                {
                    title: 'Manage User',
                    desc: 'Add and remove users who can access payment management',
                    href: '/middleware/settings/user-management'
                }
            ]
        },
        {
            icon: <FontAwesomeIcon icon={faGear} size={'xl'} />,
            title: 'General Setting',
            subAttribute: [
                {
                    title: 'Manage Channels',
                    desc: 'Add and remove channels on the system',
                    href: '/middleware/settings/channels'
                },
                {
                    title: 'Manage Payment Method',
                    desc: 'Add and remove channels on the system',
                    href: '/middleware/settings/payment-method'
                },
                {
                    title: 'Manage Merchants',
                    desc: 'Manage merchants inforamtion on the system',
                    href: '/middleware/settings/merchants'
                }
            ]
        },
        {
            icon: <FontAwesomeIcon icon={faLaptopCode} size={'xl'} />,
            title: 'Developer',
            subAttribute: [
                {
                    title: 'Manage Clients',
                    desc: 'Add and remove clients who can use middleware payment API',
                    href: '/middleware/settings/clients'
                },
                {
                    title: 'Manage Callback Type',
                    desc: 'Add and remove list of callbacks based on clients.',
                    href: '/middleware/settings/callback-type'
                },
                {
                    title: 'Manage Callback Items',
                    desc: 'Add and remove list of callbacks based on clients.',
                    href: '/middleware/settings/callback-item'
                },
                {
                    title: 'IP Whitelist',
                    desc: 'Add and remove list of IPs based on each clients.',
                }
            ]
        },
        {
            icon: <FontAwesomeIcon icon={faTools} size={'xl'} />,
            title: 'Tools',
            subAttribute: [
                {
                    title: 'API Logs',
                    desc: 'Monitor and overview logs of API transactions'
                },
                {
                    title: 'Callback Logs',
                    desc: 'Monitor and overview logs of Webhook transactions'
                }
            ]
        },
    ]

    return (
        <>
            <Container fluid className="my-4">
                <Stack direction="horizontal" gap={4} className='flex-wrap'>
                    {cards.map((item, i) => (
                        <div key={i} className='box-card bg-white p-5' style={{ width: '35rem', height: '380px' }}>
                            <div className="d-flex mb-5">
                                {item.icon}
                                <h3 className="title-card">{item.title}</h3>
                            </div>
                            <div style={{ height: '230px', overflowY: 'auto' }}>
                                {item.subAttribute?.map((data, idx) => (
                                    <div key={idx} className="mb-4">
                                        <Link to={data.href} className="card-anchor">{data.title}</Link>
                                        <p style={{ color: '#8D8D8D', marginBottom: 0 }}>{data.desc}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Stack>
            </Container>
        </>
    )
}

export default SettingMiddleware;