import React from 'react';
// import { Button, InputGroup, FormControl, Table } from "react-bootstrap";
import ReactLoading from 'react-loading';
import moment from 'moment';
import data from "../../crud/tes.json"
import actions from "../../crud/kyc";
import listData from "../../crud/json";
import { Button, InputGroup, FormControl, Table, Modal } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import PreviewImage from '../../components/previewImage';
import Pagination from "react-js-pagination";
import { Badge } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth
  },
  default: {
    class: 'sort',
    fn: (a, b) => a
  }
};

const statusClass = {
  '-1': {
    class: 'primary',
    stat: 'Reject'
  },
  0: {
    class: 'warning',
    stat: 'Pending'
  },
  1: {
    class: 'success',
    stat: 'Approve'
  }
};

export default class UserHistory extends React.Component {
  course(id) {
    history.push("/user/view/" + id + "/kyc");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      data:data,
      loading: false,
      loadingPreview: false,
      totalData: 0,
      userKYC:[],
      userKYCDetail:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      show: false,
      param1: '',
      param2: '',
      province: '',
      provinces: [],
      country: '',
      countries: [],
      occupation: '',
      occupations: [],
      visible: false,
      preview: '',
      contentPreview: 'image',
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(selfie, idPhoto) {
    this.setState({ show: true,  param1:selfie, param2:idPhoto});
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  async checkParams () {
    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = ''
    }
    
    this.setState({
      loading: true
    })

    let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
    
    actions.fetchHistoryUserKYC(this.getToken(), id , params)
    .then(res => {
      window.pushPage = (id) => {
        history.push('customers/view/' + id + "/kyc");
      }
      this.setState({
        totalData: res.data.data.total_data,
        userKYC: res.data.data.data,
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        userKYC:[],
        loading: true
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    })
    
  }
  
  async getDetailUser(){
    // let token = localStorage.getItem("token");
    this.setState({
      loading: true
    })

    let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
    actions.fetchDetailUserKYC(this.getToken(), id)
    .then(res => {
      this.setState({
        userKYCDetail: res.data.data,
        loading: false
      })

    })
    .catch(e => {
      console.log(e);
      this.setState({
        userKYCDetail:[],
        loading: false
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    })
  }

  getCountry(){    
    this.setState({
        
        country: false
    })
    listData.fetchCountry(this.getToken()).then(res => {
      this.setState({
        countries: res.data.data,
      })
    })
  }

  getProvinces(){    
    listData.fetchProvince(this.getToken()).then(res => {
      this.setState({
        provinces: res.data.data,
      })
    })
  }

  getOccupations(){    
    listData.fetchOccupation(this.getToken()).then(res => {
      this.setState({
        occupations: res.data.data,
      })
    })
  }

  componentDidMount(){
    this.checkParams();
    this.getDetailUser();
    this.getCountry();
    this.getProvinces();
    this.getOccupations();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.checkParams()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.checkParams()
    })
  }

  previewPDF = (url) => {
    this.setState({
      visible: true,
      preview: url,
      contentPreview: 'pdf'
    })
  };

  previewImage = (url) => {
    this.setState({
      visible: true,
      preview: url,
      contentPreview: 'image'
    })
  };

  disabledImagePreview = () =>{
    this.setState({
      visible: false,
      preview: ''
    })
  }

  getPhoto(id, type){
    this.setState({
      loadingPreview: true
    })

    actions.fetchPhoto(this.getToken(), id)
    .then(res => {

      this.setState({
        loadingPreview: false
      })
      if(type === 'preview'){
        const extension = id.split('.')[id.split('.').length - 1]
        let data = ""
        if(extension === "pdf"){
          data = "data:application/pdf;base64, " + encodeURI(res.data);
          this.previewPDF(data);
        }else{
          data = "data:image/jpg;base64," + res.data;
          this.previewImage(data);
        }
      }else{
        var a = document.createElement("a");
        a.href = "data:image/jpg;base64," + res.data;
        a.download = `${id}`;
        a.click();
      }

    })
    .catch(e => {
      console.log(e);
      this.setState({
        loadingPreview: false
      });
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    });
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('YYYY-MM-DD H:m:s');

    return fmtDate
  }

  render(){
    const getNationality = this.state.countries.find(country => country.Iso2 === this.state.userKYCDetail.nationality) || {}
    const nationality = getNationality.Name || '';
    // const getCountry = this.state.countries.find(country => country.Iso2 === this.state.userKYCDetail.country) || {}
    // const country = getCountry.Name || '';

    const province = this.state.provinces[this.state.userKYCDetail.province] || '';
    const occupation = this.state.occupations[this.state.userKYCDetail.occupation] || '';
    
    const { result } = this.state.userKYCDetail.goverment_result || '';
    let trx_id = '';
    let rate = 0;
    if ( result ){
      const obj = JSON.parse(result);
      trx_id = obj.trx_id || '';
      if(obj){
        const subObj = obj.data || 0;
        if(subObj)
        rate = obj.data.selfie_photo || 0
      }
    }
    return (
      <div style={{ fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div className="row row-search">
                  <div className="col-sm-4">
                    <InputGroup size="sm">
                      <FormControl
                        value={this.state.seacrhParams}
                        onChange={this.changeSearch}
                        placeholder="Search"
                      />
                      <Button variant="outline-secondary" onClick={() => this.checkParams()} >Search</Button>
                    </InputGroup>
                  </div>
                </div>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center', verticalAlign: 'middle', width: '7%' }}>
                        User ID &nbsp;
                      </th>
                      <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        name &nbsp;
                        <button onClick={(e) => this.onSortChange('id')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        email &nbsp;
                      </th>
                      <th className="center">Status</th>
                      <th className="center">Timestamp</th>
                      <th className="center">Detail</th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="6"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.userKYC.map(row => (
                        <tr key={row.id}>
                          <td>{row.user_id}</td>
                          <td>{row.name}</td>
                          <td>{row.email}</td>
                          <td className="center">
                            <Badge bg={`${statusClass[row.status].class}`}>{`${statusClass[row.status].stat}`}</Badge>
                          </td>
                          <td className="center">{ this.dateTime(row.updated_at) }</td>
                          <td className="center">
                            <span onClick={() => this.handleShow(row.selfie_photo, row.id_photo)} style={{ cursor:'pointer', color: 'blue' }}>view</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                <Pagination
                  activePage={this.state.active}
                  itemsCountPerPage={this.state.postPerPage}
                  itemClass="page-item"
                  linkClass="page-link"
                  totalItemsCount={this.state.totalData}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
          <Modal.Header closeButton>
                <Modal.Title>KYC Level 1</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive>
              <tbody>
                <tr>
                  <td><strong>Nationality</strong></td>
                  <td colSpan="5">{nationality}</td>
                </tr>
                <tr>
                  <td><strong>ID Number</strong></td>
                  <td>{this.state.userKYCDetail.id_number}</td>
                  <td><strong>ID Type</strong></td>
                  <td colSpan="3">{this.state.userKYCDetail.id_type === 0 ? <span>Identity Card</span> : <span>Passport</span>}</td>
                </tr>
                <tr>
                  <td><strong>Email</strong></td>
                  <td>{this.state.userKYCDetail.email}</td>
                  <td><strong>Gender</strong></td>
                  <td colSpan="3">{this.state.userKYCDetail.gender === 0 ? <span>Female</span> : <span>Male</span>}</td>
                </tr>
                <tr>
                  <td><strong>Place & Date of Birth</strong></td>
                  <td colSpan="5">{this.state.userKYCDetail.place_of_birth}, {this.state.userKYCDetail.date_of_birth}</td>
                </tr>
                <tr>
                  <td><strong>Address</strong></td>
                  <td colSpan="5">{this.state.userKYCDetail.address}</td>
                </tr>
                <tr>
                  <td><strong>Province</strong></td>
                  <td>{province}</td>
                  <td><strong>Zip Code</strong></td>
                  <td colSpan="3">{this.state.userKYCDetail.zip_code}</td>
                </tr>
                <tr>
                  <td><strong>Occupation</strong></td>
                  <td colSpan="5">{occupation}</td>
                </tr>
                <tr>
                  <td><strong>Phone</strong></td>
                  <td>{this.state.userKYCDetail.phone_no}</td>
                  <td><strong>Mobile Phone</strong></td>
                  <td colSpan="3">{this.state.userKYCDetail.mobile_no}</td>
                </tr>
                <tr>
                  <td><strong>Screening Risk</strong></td>
                  <td>
                    {this.state.userKYCDetail.screening_risk === 0 && <span> - </span>}
                    {this.state.userKYCDetail.screening_risk === 1 && <span> low </span>}
                    {this.state.userKYCDetail.screening_risk === 2 && <span> medium </span>}
                    {this.state.userKYCDetail.screening_risk === 3 && <span> high </span>}
                  </td>
                  <td><strong>Asliri Rate</strong></td>
                  <td colSpan="3">{rate}</td>
                </tr>
                <tr>
                  <td><strong>Trx id</strong></td>
                  <td colSpan="5">{trx_id === '' || trx_id === null ? 'none' : trx_id}</td>
                </tr>
                <tr>
                  <td><strong>Created By</strong></td>
                  <td>{this.state.userKYCDetail.created_by === '' || this.state.userKYCDetail.created_by === null ? 'none' : this.state.userKYCDetail.created_by}</td>
                  <td><strong>Modified By</strong></td>
                  <td colSpan="3">{this.state.userKYCDetail.action_by === '' || this.state.userKYCDetail.action_by === null ? 'none' : this.state.userKYCDetail.action_by}</td>
                </tr>
              </tbody>
            </Table>
            <Table responsive>
              <tbody>
                <tr>
                  <td style={{verticalAlign: 'middle', width: '10%'}}><strong>ID CARD</strong></td>
                  <td>
                    <div className="container-button">
                      {this.state.loadingPreview ? (
                        <img src="/media/loading.gif" className="img-loading" alt="" />
                      ) : (
                        <div className="grouping-button-new">
                          <button type="button" className="btn btn-in-group btn-outline btn-without-border-lr" onClick={() => this.getPhoto(this.state.param2, 'download')}>Download</button>
                          <button type="button" className="btn btn-in-group btn-outline" onClick={() => this.getPhoto(this.state.param2, 'preview')}>Preview</button>
                        </div>
                      )}
                    </div>
                  </td>
                  <td style={{verticalAlign: 'middle'}}><strong>SELFIE</strong></td>
                  <td>
                    <div className="container-button">
                      {this.state.loadingPreview ? (
                      <img src="/media/loading.gif" className="img-loading" alt="" />
                      ) : (
                        <>
                          <div className="grouping-button-new">
                            <button type="button" className="btn btn-in-group btn-outline btn-without-border-lr" onClick={() => this.getPhoto(this.state.param1, 'download')}>Download</button>
                            <button type="button" className="btn btn-in-group btn-outline" onClick={() => this.getPhoto(this.state.param1, 'preview')}>Preview</button>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
        <PreviewImage active={this.state.visible} contentData={this.state.contentPreview} url={this.state.preview} disablePreview={() => this.disabledImagePreview()}/>
      </div>
    );
  }
}