import { FC, Fragment, useEffect, useReducer, useState } from "react"
import { Alert, Button, Modal } from "react-bootstrap"
import ReusableListPage, { ReusableListFilter, ReusableListSecondFilter } from "../../components/reusableListPage"
import { ReusableTableColumn } from "../../components/reusableTable"
import { getRates, updateRates } from "../../crud/tax-service"
import { Rate } from "../../crud/tax-types"
import { capitalizeEveryWord, useDebounce } from "../../helper"
import Swal from "sweetalert2"

const dummyRate: Partial<Rate> = {
  // month: 8,
  // year: 2022,
  symbol: "AR",
  quote: "USD",
  // open_price: "10.7263",
  close_price: "9.8711",
  // low_price: "9.6617",
  // high_price: "10.9950",
  created_at: "2022-09-27 20:56:03",
  updated_at: "2022-09-27 20:56:03",
  deleted_at: null
}

interface InitialValueFilter {
  q: string;
  date?: Date | null;
}

const initialValueFilter: InitialValueFilter = {
  q: "",
  date: new Date()
}

const reducer = (state: InitialValueFilter,nv: Partial<InitialValueFilter>) => ({...state,...nv})

const CoinRates: FC = () => {
  const [nominal, setNominal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [isUpdateRates, setIsUpdateRates] = useState<boolean>(false)
  const [dataSources, setDataSources] = useState<Array<Rate>>([])
  const [activePage, setActivePage] = useState<number>(1)
  const [filter, dispatch] = useReducer(reducer, initialValueFilter)
  const limit = 15
  const columns: Array<ReusableTableColumn<Rate>> = Object.entries(dummyRate).map<ReusableTableColumn<Rate>>(([key])=>{
    const rateKey = key as keyof Rate
    return {
      dataIndex: rateKey,
      title: capitalizeEveryWord(key.replace(/_/g, " "))
    }
  }).concat({
    title: "Action",
    dataIndex: "id",
    render: (dataIndex) => (
      <UpdateRate 
        dataIndex={dataIndex as string} 
        callback={()=>{
          setLoading(true)
          getListRateService(filter.date)
        }} 
      />
    )
  })
  const getListRateService = (date?: Date | null) => {
    getRates({
      month: (date ?? new Date()).getMonth() + 1,
      year: (date ?? new Date()).getFullYear()
    })
      .then((res)=>{
        if(res.data.data) setDataSources(res.data.data)
      })
      .catch(err =>{
        console.log(err)
      })
      .finally(()=>setLoading(false))
  }
  useDebounce<[Date | null | undefined]>(getListRateService,[filter.date], 400, ()=>setLoading(true))
  const getFilteredDataSources = () => {
    return dataSources.filter(value => value.symbol.toLowerCase().includes(filter.q.toLowerCase()))
  }
  const getDataSourceByPartition = () => {
    const minIndex = (activePage-1) * limit
    const maxIndex = activePage * limit
    let result: Array<Rate> = []

    for(let i = minIndex; i < maxIndex; i++) {
      if(getFilteredDataSources()[i]) result.push(getFilteredDataSources()[i])
    }

    return result
  }
  const filters: Array<ReusableListFilter> = [
    {
      type: "INPUT_TEXT",
      placeholder: "Search by Symbol",
      value: filter.q,
      onChange: (evt) => {
        dispatch({q: evt.target.value})
        setActivePage(1)
      }
    },
    {
      type: "DATE_PICKER",
      selected: filter.date,
      changeDate: (val) => {
        dispatch({date: val})
        setActivePage(1)
      },
      placeholderText: 'Filter by Date',
      dateFormat: 'MM/yyyy',
      showMonthYearPicker: true,
      showFullMonthYearPicker: true
    },
  ]
  const btns: Array<ReusableListSecondFilter> = [
    // {
    //   type: "BUTTON",
    //   label: "Update All Rates",
    //   onClick: () => setIsUpdateRates(true)
    // }
  ]
  const updateRatesService = () => {
    const promises = getFilteredDataSources().map((data) => updateRates({id: data.id, value: nominal}))

    Promise.all(promises)
      .then(()=>{
        Swal.fire('Success!', `Success update rate!`, 'success')
        setLoading(true)
        getListRateService(filter.date)
      })
      .catch((err)=>{
        console.log(err)
      })
  }
  return (
    <div style={{ fontSize: '10px' }} className="head">
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              <ReusableListPage 
                filters={filters}
                triggerBtns={getFilteredDataSources().length > 0 ? btns : []}
                columns={columns}
                dataSources={getDataSourceByPartition()}
                loading={loading}
                pagination={{
                  itemsCountPerPage: limit,
                  totalItemCount: getFilteredDataSources().length,
                  onChange: (pageNumber: number) => setActivePage(pageNumber),
                  activePage,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={isUpdateRates} centered onHide={()=>setIsUpdateRates(false)}>
        <Modal.Header>
          <Modal.Title>Update Multiple Rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Alert variant="warning">You can update all data, based on the&nbsp;<b>filter</b>&nbsp;you previously set.</Alert>
            <input
              style={{ fontSize: '0.8rem' }}
              type='number'
              className='form-control'
              onChange={(evt)=>setNominal(Number(evt.target.value))}
              value={nominal}
            />
            <button
              className='btn btn-outline-success col-md-auto font-8rem mt-3'
              type='button'
              style={{ cursor: 'pointer' }}
              onClick={()=>updateRatesService()}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

interface UpdateRateProps {
  dataIndex: string;
  callback?: () => void;
}

export const UpdateRate: FC<UpdateRateProps> = (props) => {
  const [nominal, setNominal] = useState<number>(0)
  const [updateMode, setUpdateMode] = useState<boolean>(false)
  const updateRate = () => {
    updateRates({
      id: props.dataIndex,
      value: nominal
    })
      .then(()=>{
        Swal.fire('Success!', `Success update rate!`, 'success')
        setNominal(0)
        setUpdateMode(false)
        if(props.callback) props.callback()
      })
      .catch((err)=>{
        console.log(err)
      })
  }
  if(updateMode) return (
    <div className='row gap-2'>
      <input
        style={{ fontSize: '0.8rem' }}
        type='number'
        className='form-control col'
        onChange={(evt)=>setNominal(Number(evt.target.value))}
        value={nominal}
      />
      <button
        className='btn btn-outline-success col-md-auto font-8rem'
        type='button'
        style={{ cursor: 'pointer' }}
        onClick={()=>updateRate()}
      >
        Update
      </button>
      <button
        className='btn btn-outline-warning col-md-auto font-8rem'
        type='button'
        style={{ cursor: 'pointer' }}
        onClick={()=>{
          setUpdateMode(!updateMode)
          setNominal(0)
        }}
      >
        Cancel
      </button>
    </div>
  )
  else return (
    <Button onClick={()=>setUpdateMode(!updateMode)}>Update Rate</Button>
  )
}

export default CoinRates