import instance from "./axiosKycInstance";

const getPost = (token, type, params) => {
  return instance.get("admin/microsite/post/"+ type + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchPost = (token, type, params) => {
  return new Promise((resolve, reject) => {
    getPost(token, type, params).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getDetailPost = (token, type, id) => {
  return instance.get("/admin/microsite/post/"+ type +"/"+ id +"/show", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailPost = (token, type, id) => {
  return new Promise((resolve, reject) => {
    getDetailPost(token, type, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const createPostData = (token, type, data) => {
  return instance.post("/admin/microsite/post/"+type+"/create", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postData = (token, type, data) => {
  return new Promise((resolve, reject) => {
    createPostData(token, type, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const updatePostData = (token, type, data, id) => {
  return instance.put("/admin/microsite/post/"+type+"/"+id+"/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putPostData = (token, type, data, id) => {
  return new Promise((resolve, reject) => {
    updatePostData(token, type, data, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const deletePostData = (token, type, id) => {
  return instance.delete("/admin/microsite/post/"+type+"/"+id+"/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteDataPost = (token, type, id) => {
  return new Promise((resolve, reject) => {
    deletePostData(token, type, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getTags = (token, params) => {
  return instance.get("/admin/microsite/tag"+params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchTags = (token, params) => {
  return new Promise((resolve, reject) => {
    getTags(token, params).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getDetailTag = (token, id) => {
  return instance.get("/admin/microsite/tag/" + id + "/show", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailTag = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailTag(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const createTags = (token, data) => {
  return instance.post("/admin/microsite/tag/create", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postTags = (token, data) => {
  return new Promise((resolve, reject) => {
    createTags(token, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const updateTags = (token, id, data) => {
  return instance.put("/admin/microsite/tag/"+id+"/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putTags = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateTags(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const deleteTags = (token, id) => {
  return instance.delete("/admin/microsite/tag/"+id+"/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteDataTags = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteTags(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const deleteDataAttach = (token, id) => {
  return instance.delete("/admin/microsite/attachment/"+id+"/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteAttach = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteDataAttach(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getCompare = (token, params) => {
  return instance.get("/admin/microsite/comparison" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchCompare = (token, params) => {
  return new Promise((resolve, reject) => {
    getCompare(token, params).then(res => {
        resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const getDetailCompare = (token, id) => {
  return instance.get("/admin/microsite/comparison/"+id+"/show", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailCompare = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailCompare(token, id).then(res => {
        resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const CreateDataCompare = (token, data) => {
  return instance.post("/admin/microsite/comparison/populate", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const CreateCompare = (token, data) => {
  return new Promise((resolve, reject) => {
    CreateDataCompare(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const updateDataCompare = (token, id, data) => {
  return instance.put("/admin/microsite/comparison/"+id+"/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putDataCompare = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateDataCompare(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const deleteData = (token, id) => {
  return instance.delete("/admin/microsite/comparison/"+id+"/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteDataCompare = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteData(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

export default {
  fetchPost: fetchPost,
  fetchDetailPost: fetchDetailPost,
  postData: postData,
  putPostData: putPostData,
  deleteDataPost: deleteDataPost,
  fetchTags: fetchTags,
  fetchDetailTag: fetchDetailTag,
  postTags: postTags,
  putTags: putTags,
  deleteDataTags: deleteDataTags,
  deleteAttach: deleteAttach,
  fetchCompare: fetchCompare,
  fetchDetailCompare: fetchDetailCompare,
  CreateCompare: CreateCompare,
  putDataCompare: putDataCompare,
  deleteDataCompare: deleteDataCompare
};