import React, { useEffect, useState, useRef } from 'react';
import ReusableListPage, {
  FILTER_TYPE,
  TRIGGER_BTN_TYPE,
} from '../../components/reusableListPage/index';
import { Badge, OverlayTrigger, ButtonToolbar, Tooltip } from 'react-bootstrap';
import { depositStatus, withdrawStatus } from './depositRequest';
import {
  confirmPaymentOTC,
  getListOTCTransactionHistory,
} from '../../otc_services/resources/transaction_history/transaction_history_service';
import { debounce } from 'lodash';
import moment from 'moment';
import { constantDateFormat, numFormatter } from '../../helper';
import Swal from 'sweetalert2';
import {
  approveOTCDeposit,
  exportDeposit,
} from '../../otc_services/resources/deposit/deposit_service';

const TransactionHistory = (props) => {
  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keywords, setKeywords] = useState('');
  const [depositStatusSelected, setDepositStatusSelected] = useState('');
  const [withdrawStatusSelected, setWithdrawStatusSelected] = useState('');

  const [activePage, setActivePage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const itemsCountPerPage = 15;

  const getDepositStatusSelected = (dataIndex) => {
    return depositStatus.find((status) => status.id === dataIndex);
  };
  const getWithdrawStatusSelected = (dataIndex) => {
    return withdrawStatus.find((status) => status.id === dataIndex);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      className: 'tableheh all-center',
      render: (dataIndex, data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      className: 'tableheh',
    },
    {
      title: 'UID',
      dataIndex: 'uid',
      className: 'tableheh',
      render: (_, data) => <span>{data.customer?.tokocrypto_uid}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      className: 'tableheh',
      render: (_, data) => <span>{data.customer?.email}</span>,
    },
    {
      title: <div className='text-right'>Amount</div>,
      dataIndex: 'transfer_amount',
      className: 'tableheh',
      render: (dataIndex) => (
        <div className='text-right'>{dataIndex && numFormatter(dataIndex)}</div>
      ),
    },
    {
      title: 'Token',
      dataIndex: 'currency',
      className: 'tableheh',
      render: (dataIndex) => <span>{dataIndex?.toUpperCase()}</span>,
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      className: 'tableheh',
      render: (dataIndex) => (
        <span>{dataIndex && constantDateFormat(dataIndex)}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'deposit_status',
      className: 'tableheh center',
      render: (dataIndex) =>
        getDepositStatusSelected(dataIndex) && (
          <Badge bg={getDepositStatusSelected(dataIndex).theme}>
            {getDepositStatusSelected(dataIndex).label}
          </Badge>
        ),
    },
    {
      title: 'Withdraw Status',
      dataIndex: 'withdraw_status',
      className: 'tableheh center',
      render: (dataIndex) =>
        getWithdrawStatusSelected(dataIndex) && (
          <Badge bg={getWithdrawStatusSelected(dataIndex).theme}>
            {getWithdrawStatusSelected(dataIndex).label}
          </Badge>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'tableheh center',
      render: (dataIndex) => (
        <ButtonToolbar style={{ justifyContent: 'center' }}>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-top`}>Detail</Tooltip>}
          >
            <span
              className='btn-action'
              onClick={() =>
                props.history.push(
                  `/otc-finance/transaction-history/${dataIndex}/log`
                )
              }
            >
              <i
                style={{ fontSize: '1rem', paddingRight: '0' }}
                className='far fa-eye text-info'
              />
            </span>
          </OverlayTrigger>
        </ButtonToolbar>
      ),
    },
  ];

  const filters = [
    {
      type: "DATE_PICKER",
      selected: startDate,
      changeDate: (val) => setStartDate(val),
      placeholderText: 'Start Date',
    },
    {
      type: "DATE_PICKER",
      selected: endDate,
      changeDate: (val) => setEndDate(val),
      placeholderText: 'End Date',
    },
    {
      type: "INPUT_TEXT",
      onChange: (evt) => setKeywords(evt.target.value),
      value: keywords,
      placeholder: 'Search By Email, UID',
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: '- Status -', value: '' },
        ...depositStatus.map((status) => ({
          label: status.label,
          value: status.id || status.id === 0 ? status.id.toString() : 'null',
        })),
      ],
      value: depositStatusSelected,
      onChange: (evt) => setDepositStatusSelected(evt.target.value),
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: '- Withdraw Status -', value: '' },
        ...withdrawStatus.map((status) => ({
          label: status.label,
          value: status.id || status.id === 0 ? status.id.toString() : 'null',
        })),
      ],
      value: withdrawStatusSelected,
      onChange: (evt) => setWithdrawStatusSelected(evt.target.value),
    },
  ];
  const triggerBtns = [
    {
      type: "BUTTON",
      label: 'Search',
      onClick: () =>
        getList(1, {
          q: keywords || undefined,
          start_date: startDate || undefined,
          end_date: endDate || undefined,
          deposit_status: depositStatusSelected || undefined,
          withdraw_status: withdrawStatusSelected || undefined,
        }),
    },
    {
      type: "BUTTON",
      label: 'Export',
      className: 'btn btn-outline-info mr-3 font-8rem',
      disabled: dataSources.length < 1,
      onClick: () => {
        exportDeposit({
          q: keywords || undefined,
          start_date: startDate
            ? moment(startDate).format('YYYY-MM-DD')
            : undefined,
          end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
          deposit_status: depositStatusSelected || undefined,
          withdraw_status: withdrawStatusSelected || undefined,
        })
          .then((res) => {
            Swal.fire('Success', 'Please check your email!', 'success');
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  ];

  const secondFilters = [
    {
      type: "BUTTON",
      label: 'Re-Withdraw',
      className: 'btn btn-success mr-3 font-8rem',
      onClick: () => {
        Swal.fire({
          title: `Are you sure to withdraw each request?`,
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: `Yes, Withdraw!`,
        }).then((result) => {
          if (result.isConfirmed) withdrawRequest();
        });
      },
    },
    {
      type: "BUTTON",
      label: 'Check Payment',
      className: 'btn btn-info mr-3 font-8rem',
      onClick: () => checkPaymentHandler(),
    },
  ];

  const withdrawRequest = () => {
    let promises = dataSelected.map((data) => approveOTCDeposit(data.id));
    Promise.all(promises)
      .then(() => {
        Swal.fire('Success!', `OK!`, 'success');
      })
      .catch((err) => {
        console.log(err.toJSON());
      })
      .finally(() => {
        getList(
          activePage,
          {
            q: keywords || undefined,
            start_date: startDate || undefined,
            end_date: endDate || undefined,
            deposit_status: depositStatusSelected || undefined,
            withdraw_status: withdrawStatusSelected,
          },
          () => setDataSelected([])
        );
      });
  };

  const checkPaymentHandler = () => {
    let promises = dataSelected.map((data) => confirmPaymentOTC(data.id));
    Promise.all(promises)
      .then(() => {
        Swal.fire('Success!', `OK!`, 'success');
      })
      .catch((err) => {
        console.log(err.toJSON());
      })
      .finally(() => {
        getList(
          activePage,
          {
            q: keywords || undefined,
            start_date: startDate || undefined,
            end_date: endDate || undefined,
            deposit_status: depositStatusSelected || undefined,
            withdraw_status: withdrawStatusSelected,
          },
          () => setDataSelected([])
        );
      });
  };

  const getList = (page, params, callback) => {
    setLoading(true);
    if (params.deposit_status && !isNaN(params.deposit_status))
      params.deposit_status = Number(params.deposit_status);
    else params.deposit_status = undefined;
    if (params.withdraw_status && !isNaN(params.withdraw_status))
      params.withdraw_status = Number(params.withdraw_status);
    else params.withdraw_status = undefined;

    if (params.start_date)
      params.start_date = moment(params.start_date).format('YYYY-MM-DD');
    else params.start_date = undefined;
    if (params.end_date)
      params.end_date = moment(params.end_date).format('YYYY-MM-DD');
    else params.end_date = undefined;
    getListOTCTransactionHistory({
      limit: itemsCountPerPage,
      page,
      ...params,
    })
      .then((res) => {
        res.data.data.data.forEach((d, index) => {
          d.no = (page - 1) * itemsCountPerPage + index;
        });
        setDataSources(res.data.data.data);
        setTotalItemCount(res.data.data.total_data);
        if (callback) callback();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const debounceProcess = useRef(
    debounce(
      (
        newKeywords,
        newStartDate,
        newEndDate,
        newDepositStatusSelected,
        newWithdrawStatusSelected
      ) => {
        getList(1, {
          q: newKeywords || undefined,
          start_date: newStartDate || undefined,
          end_date: newEndDate || undefined,
          deposit_status: newDepositStatusSelected || undefined,
          withdraw_status: newWithdrawStatusSelected || undefined,
        });
      },
      1000
    )
  );

  const filterListener = () => {
    setDataSelected([]);
    debounceProcess.current(
      keywords,
      startDate,
      endDate,
      depositStatusSelected,
      withdrawStatusSelected
    );
  };

  useEffect(filterListener, [
    keywords,
    startDate,
    endDate,
    depositStatusSelected,
    withdrawStatusSelected,
  ]);

  return (
    <ReusableListPage
      filters={filters}
      secondFilters={
        localStorage.getItem('rule') !== 'sales' &&
        localStorage.getItem('rule') !== 'data' &&
        secondFilters
      }
      triggerBtns={triggerBtns}
      loading={loading}
      dataSources={dataSources}
      columns={columns}
      pagination={{
        activePage,
        itemsCountPerPage,
        totalItemCount,
        onChange: (pageNumber) => {
          setDataSelected([]);
          getList(pageNumber, {
            q: keywords || undefined,
            start_date: startDate || undefined,
            end_date: endDate || undefined,
            deposit_status: depositStatusSelected || undefined,
            withdraw_status: withdrawStatusSelected,
          });
          setActivePage(pageNumber);
        },
      }}
      rowSelection={{
        selected: dataSelected,
        setSelected: (data) => setDataSelected(data),
        key: 'id',
      }}
    />
  );
};

export default TransactionHistory;
