import React from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
// import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import _ from 'lodash';
import actions from "../../crud/community";
import SelectDate from '../../components/datetime';
import { Button, Table, Badge, OverlayTrigger, ButtonToolbar, Tooltip, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Swal from 'sweetalert2';
import Pagination from "react-js-pagination";
import clsx from "clsx";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

// const sortTypes = {
//   asc: {
//     class: 'sort-up',
//     fn: (a, b) => a.net_worth - b.net_worth
//   },
//   desc: {
//     class: 'sort-down',
//     fn: (a, b) => b.net_worth - a.net_worth
//   },
//   default: {
//     class: 'sort',
//     fn: (a, b) => a
//   }
// };

export default class User extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingUpload: false,
      show: false,
      totalData: 0,
      dataReward:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderByHistory: '',
      orderSort: 'asc',
      orderSortHistory: 'asc',
      active: 1,
      eventName: 'test',
      startDate: null,
      endDate: null,
      trxType: '',
      tippingTypes: '',
      files: null,
      file_extension: '',
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true});
  }

  handlePageChangeHistory = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getTippingReward()
    })
  }

  handleSearch = () => {
    this.setState({ active: 1}, () => {
      this.getTippingReward()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getTippingReward() {
    this.setState({
      loading: true
    })

    let type = '';
    if(this.state.trxType !== ''){
      type = `&status=${parseInt(this.state.trxType)}`;
    }

    let tipping = '';
    if(this.state.tippingTypes !== ''){
      type = `&type=${this.state.tippingTypes}`;
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${type}${tipping}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${type}${tipping}`
    }

    if(this.state.startDate){
      const st = moment(this.state.startDate).format('Y-MM-DD');
      params += '&start_date='+st;
    }
    if(this.state.endDate){
      const ed = moment(this.state.endDate).format('Y-MM-DD');
      params += '&end_date='+ed;
    }

    actions.fetchTippingReward(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        dataReward: res.data.data.data,
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        dataReward:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  generateExcel(){
    this.setState({
      loading: true
    })

    let params = '';
    let start = '';
    let startDownload = '';
    if(this.state.startDate){
      start = `&start_date=${moment(this.state.startDate).format('Y-MM-DD')}`;
      startDownload = `_${moment(this.state.startDate).format('MM-DD-YYYY')}`
    }
    else{
      start = ''
      startDownload = ''
    }
    let end = '';
    let endDownload = '';
    if(this.state.endDate){
      end = `&end_date=${moment(this.state.endDate).format('Y-MM-DD')}`;
      endDownload = `_${moment(this.state.endDate).format('MM-DD-YYYY')}`
    }
    else{
      end = ''
      endDownload = ''
    }
    let type = '';
    let typeDownload = '';
    if(this.state.trxType !== ''){
      type = `&status=${parseInt(this.state.trxType)}`;
      if(this.state.trxType === '1'){
        typeDownload = 'Pending'
      }else{
        typeDownload = 'Distributed'
      }
    }
    else{
      type = ''
      typeDownload = 'All'
    }
    // const st = moment(startDate).format('Y-MM-DD');
    // const ed = moment(endDate).format('Y-MM-DD');
    params = `?excel_download=1${start}${end}${type}`;
    actions.fetchTippingReward(this.getToken(), params)
    .then(res => {
      this.setState({
        loading: false,
      })

      let datas = res.data.data
      var url = "data:application/vnd.ms-excel;base64," + datas;
      // you need to precise a front-end button to have a name
      var a = document.createElement("a");
      a.download = `tipping_export${startDownload}${endDownload}_${typeDownload}.xlsx`;
      a.href = url;
      a.click();
      // window.location.href = "data:application/vnd.ms-excel;base64," + res.data.data;
    })
    .catch(e => {
      console.log(e);
      this.setState({
        dataReward:[],
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  async sendTippingReward(id) {
    this.setState({
      loading: true
    })

    let data = {
      status: 2
    }

    const swal = await Swal.fire({
      title: 'Send Tipping Reward?',
      // text: "Send Tipping Reward",
      html: '<i class="fas fa-gift text-success" style="font-size:4rem;" />',
      // icon: 'question',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    });
    if (swal.isConfirmed) {
      actions.UpdateRewardStatus(this.getToken(), id, data)
      .then(res => {
        this.setState({
          loading: false,
        })
        this.getTippingReward()
      })
      .catch(e => {
        console.log(e);
        this.setState({
          loading: false,
        })
        this.getTippingReward()
        if(e.response){
          if(e.response.data.code === 401){
            toast.error("token has expired");
            localStorage.clear();
            history.push("/auth");
            window.location.reload();
          }
          else{
            toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
          }
        }
      }) 
    }else{
      this.getTippingReward()
    }
  }

  isSubmitFile = event => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', this.state.files); 
    event.preventDefault();
    // const validate = this.isValidate();
    // if(validate){
      this.addUploadData(bodyFormData);
    // };
  }

  addUploadData(bodyFormData){
    this.setState({
      loadingUpload: true,
    })
    actions.createUploadFile(this.getToken(), bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success(res.data.data.message);
      }
      this.setState({
        loadingUpload: false,
        show: false,
        files: '',
        file_extension: ''
      })
      this.getTippingReward(this.state.params);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loadingUpload: false,
        show: false,
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  
  componentDidMount(){
    this.getTippingReward();
  }

  onChangeFile = async (e) => {
    let file = e.target.files[0];
    if(file){
      await this.setState({
        files: file,
        file_extension: file.name
      })
    }
  }
  
  uppercase(word){
    let wording  = word || '';
    const upper = wording.toUpperCase()

    return upper
  }

  financial(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getTippingReward()
  };

  render(){
    const rewardType = [
      {
        id: "",
        name: 'All',
      },
      {
        id: "1",
        name: 'Pending',
      },
      {
        id: "2",
        name: 'Distributed',
      }
    ];
    const TippingType = [
      {
        id: "",
        name: 'All',
      },
      {
        id: "claim",
        name: 'claim',
      },
      {
        id: "donate",
        name: 'donate',
      },
      {
        id: "tipping",
        name: 'tipping',
      }
    ];
    
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body pad-10-20">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-7 pr-0">
                  <div className="d-flex">
                    <Form.Group className="mb-0 mr-2" style={{width: '28%'}}>
                      <Form.Label className="font-8rem">Start Date</Form.Label>
                      <SelectDate changeDate={(val) => this.setState({ startDate: val })} />
                    </Form.Group>
                    <Form.Group className="mb-0 mr-2" style={{width: '25%'}}>
                      <Form.Label className="font-8rem">End Date</Form.Label>
                      <SelectDate changeDate={(val) => this.setState({ endDate: val })} />
                    </Form.Group>
                    <Form.Group className="mb-0 mr-2" style={{width: '22%'}}>
                      <Form.Label className="font-8rem">Reward Status</Form.Label>
                      <select className="form-control font-8rem" onChange={(e) => this.setState({ trxType: e.target.value })} placeholder="Select Data">
                        <option disabled selected={this.state.trxType === '' ? true : false}>Select Type</option>
                        {rewardType.map((e) => (
                          <option value={e.id} selected={e.id === this.state.trxType ? true : false} defaultValue={e.id === this.state.trxType ? true : false}>{e.name}</option>
                        ))}
                      </select>
                    </Form.Group>
                    <Form.Group className="mb-0 mr-2" style={{width: '22%'}}>
                      <Form.Label className="font-8rem">Type</Form.Label>
                      <select className="form-control font-8rem" onChange={(e) => this.setState({ tippingTypes: e.target.value })} placeholder="Select Data">
                        <option disabled selected={this.state.tippingTypes === '' ? true : false}>Select Type</option>
                        {TippingType.map((e) => (
                          <option value={e.id} selected={e.id === this.state.tippingTypes ? true : false} defaultValue={e.id === this.state.tippingTypes ? true : false}>{_.capitalize(e.name)}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="d-flex" style={{marginTop: '1.6rem'}}>
                    <button className="btn btn-outline-warning mr-2 font-8rem" type="button"  onClick={() => this.handleSearch()} style={{width: '17%'}}>
                      Search
                    </button>
                    <button className="btn btn-outline-success mr-2 font-8rem" type="button" onClick={() => this.generateExcel()}>
                      Export To Excel
                    </button>
                    <button className="btn btn-outline-primary mr-2 font-8rem" type="button" onClick={() => this.handleShow()}>
                      Bulky Update Status
                    </button>
                    <Link to="/community-tipping/config" className="kt-link">
                      <Button variant="warning" className="font-8rem" style={{fontSize: '11px', color: '#fff'}}><i className="fas fa-users-cog" />Tipping Config</Button>
                    </Link>
                  </div>
                {/* </div>
                <div className="col-md-2"> */}
                  {/* <div className="d-flex justify-content-around" style={{textAlign: 'end', marginTop: '1.6rem'}}>
                    <Link to="/community-tipping/config" className="kt-link">
                      <Button variant="warning" className="font-8rem" style={{fontSize: '11px', color: '#fff'}}><i className="fas fa-users-cog" />Tipping Config</Button>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle', width: '5%' }}>
                        No &nbsp;
                      </th>
                      <th className="tableheh" style={{ width: '7%' }}>
                        type
                      </th>
                      <th className="tableheh" style={{ width: '13%' }}>
                        Telegram Username &nbsp;
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh" style={{ width: '13%' }}>
                        Email &nbsp;
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh" style={{ width: '6%' }}>
                        UUID &nbsp;
                      </th>
                      <th className="tableheh">
                        Currency
                      </th>
                      <th className="tableheh" style={{ verticalAlign: 'middle' }}>
                        Reward &nbsp;
                      </th>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        created Date &nbsp;
                      </th>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        Reward Status &nbsp;
                      </th>
                      <th className="tableheh" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        Reward Action &nbsp;
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="10"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.dataReward.map( (row, index) => (
                        <tr key={index}>
                          <td className="center">{index +1}</td>
                          <td>{_.capitalize(row.type)}</td>
                          <td>{row.tg_username}</td>
                          <td>{row.email}</td>
                          <td>{row.binance_id}</td>
                          <td>{_.upperCase(row.reward_currency)}</td>
                          <td>{this.financial(row.reward_qty)} {this.uppercase(row.reward_currency)}</td>
                          <td className="center">{this.dateTime(row.created_at)}</td>
                          <td className="center">
                            {row.status === 2 ?
                              <Badge bg="success">
                                Distributed
                              </Badge> 
                            :
                              <Badge bg="warning">
                                Pending
                              </Badge>
                            }
                          </td>
                          <td className="center">
                            {row.status === 1 && 
                              <ButtonToolbar style={{ justifyContent: 'center'}}>
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Send Tipping Reward
                                    </Tooltip>
                                  }
                                >
                                  <span onClick={() => this.sendTippingReward(row.id)} className="link-action btn"><i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-gift text-success" /></span>
                                </OverlayTrigger>
                              </ButtonToolbar>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChangeHistory}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>Bulky Update Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
                <div className="col-md-12">
                  <form className="m-login__form m-form" onSubmit={this.isSubmitFile}>
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group>
                          <Form.Control
                            style={{ padding: '11px 15px', height: 'unset', marginBottom: '8px' }}
                            className="form-control"
                            onChange={this.onChangeFile}
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                          <Form.Text className="text-muted">
                            <span>*Allowed file type for import: .xls</span>
                          </Form.Text>
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          disabled={this.state.loadingUpload}
                          style={{ width: '100%'}}
                          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                            {
                              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loadingUpload
                            }
                          )}`}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}