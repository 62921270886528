import React from "react";
import actions from "../../crud/microsite";
import { toast } from 'react-toastify';
import { Form } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css'; 
import { createBrowserHistory } from 'history';
// import FileUpload from '../../components/upload/fileUpload';
import _ from 'lodash';
import clsx from "clsx";

const history =  createBrowserHistory();

export default class ArticleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalData: 0,
      titleId: '',
      titleEn: '',
      contentId: '',
      contentEn: '',
      listImage: [],
      params: '?status=1',
      addThumbnail: [],
      addUrlThumbnail: [''],
      image: {},
      type: 'create',
      checked: false,
      listTags: [],
      sourceLink: '',
      disabledCoin: {},
    }
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getListTags(){
    this.setState({
      loading: true
    })
    actions.fetchTags(this.getToken(), this.state.params)
    .then(res => {
      const alldata = res.data.data;
      this.setState({
        loading: false,
        listTags: alldata.data || []
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getListTags();
  }

  onChangeFileImage = (e) => {
    let file = e.target.files[0];
    if(file){
      this.setState({
        image: file
      })
    }
  }

  cloneState() {
      return _.clone(this.state);
  }

  onChangeState(val, field) {
      let state = this.cloneState();
      state[field] = val;
      this.setState(state);
  }

  handleAddThumbnail(){
    const _fields = [...this.state.addThumbnail];
    _fields.push('');
    this.setState({
      addThumbnail: _fields,
    });
  }
  handleRemoveThumbnail(i){
    const _fields = [...this.state.addThumbnail];
    _fields.splice(i, 1);
    this.setState({
      addThumbnail: _fields,
    });
  }
  onChangeThumbnail(i, e){
    const self = this;
    let file = e.target.files[0];
    const _pair = [...self.state.addThumbnail];
    if(file){
      _pair[i] = file;
      this.setState({
        addThumbnail: _pair
      })
    }
  }

  handleAddUrlThumbnail(){
    const _fields = [...this.state.addUrlThumbnail];
    _fields.push('');
    this.checkIfSameWithState();
    this.setState({
      addUrlThumbnail: _fields,
    });
  }
  async handleRemoveUrlThumbnail(i){
    const _fields = [...this.state.addUrlThumbnail];
    _fields.splice(i, 1);
    await this.setState({
      addUrlThumbnail: _fields,
    });
    await this.checkIfSameWithState();
  }

  async onChangeListTags(i, e){
    const _pair = [...this.state.addUrlThumbnail];
    _pair[i] = parseInt(e.target.value);
    await this.setState({
      addUrlThumbnail: _pair,
    })
    await this.checkIfSameWithState();
  }

  checkIfSameWithState(coinSend = []){
    const _fields = coinSend.length > 0 ? coinSend : this.state.addUrlThumbnail;
    let makelistdisabled = {};
    _fields.forEach(element => {
      makelistdisabled[element] = makelistdisabled[element] ? {...makelistdisabled[element]} : {};
      if(element !== '') makelistdisabled[element] = true;
    });
    const savedata = makelistdisabled;
    this.setState({
      disabledCoin: savedata,
    });
  }

  isSubmit = event => {
    event.preventDefault();
    let imageFile = this.state.addThumbnail
    let tag = this.state.addUrlThumbnail
    let stat = ''
    if(this.state.checked){
      stat = '1'
    }else{
      stat = '0'
    }
    if(tag.length < 1 || tag[0] === '' ){
      alert('Please, Select tag');
    }
    else{
      if(tag.length < 2){
        tag.push("");
      }
      var bodyFormData = new FormData();
      bodyFormData.append('title', this.state.titleId); 
      bodyFormData.append('desc', this.state.contentId); 
      bodyFormData.append('status', stat);
      imageFile.forEach(element => {
        bodyFormData.append('files', element);
      });
      tag.forEach((element) => {
        bodyFormData.append(`tags`, element);
      });
      bodyFormData.append('source_link', this.state.sourceLink);
      this.submitData(bodyFormData);
    }
  }

  async submitData(bodyFormData){
    this.setState({
      loading: true
    })

    actions.postData(this.getToken(), 'article', bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new event is success");
      }
      this.setState({
        loading: false,
      })
      setTimeout(() => {
        history.push("/btc-microsite/article");
        window.location.reload();
      }, 3000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  makeBase64 = (file) => {
    // let self = this;
    console.log(file);
    let blob = file.file;
    // let type = file.type;
    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
        // console.log(reader.result);
        // self.setState({
        //   file_extension: type,
        //   file_content: reader.result.split('base64,')[1],
        // })
    }
  }

  onChangeChecked = newValue => {
    this.setState({ checked: newValue });
  }

  render(){
    let totalDesc = 255 - this.state.contentId.length
    return (
      <div className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
              <hr />
                {this.state.loading ? (
                  <div className="text-center">
                      Loading ...
                  </div>
                ):(
                  <>
                    <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                      <Form.Group>
                        <Form.Label>Title (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Title ID" value={this.state.titleId} onChange={(e) => this.onChangeState(e.target.value, 'titleId')} required/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Content Description (ID)</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Content Description ID"
                          value={this.state.contentId}
                          onChange={(e) => this.onChangeState(e.target.value, 'contentId')}
                          maxLength="255"
                          required
                        />
                          <Form.Text className="text-muted desc-length-position">
                            <span>Maximum Characters : {totalDesc}</span>
                          </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Source Link</Form.Label>
                        <Form.Control type="text" placeholder="https://www.tokocrypto.com" value={this.state.sourceLink} onChange={(e) => this.onChangeState(e.target.value, 'sourceLink')} required/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Check 
                          type="switch"
                          id="custom-switch"
                          label="Select Publish or Unpublish Article"
                          checked={this.state.checked}
                          onChange={e => this.onChangeChecked(e.target.checked)}
                        />
                      </Form.Group>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group>
                            <Form.Label>Image Setup</Form.Label>
                            <div className="row">
                              <div className="col-md-12 mb-2">
                                <button
                                  type="button"
                                  onClick={() => this.handleAddThumbnail()}
                                  className="btn btn-sm btn-outline-success">
                                    Add Image Thumbnail
                                </button>
                              </div>
                              <div className="col-md-12">
                                {this.state.addThumbnail.map((element, i) => {
                                  return (
                                    <div className="row mt-2" key={i}>
                                      <div className="col-md-2">
                                        <button type="button" onClick={() => this.handleRemoveThumbnail(i)} className="btn btn-sm btn-outline-danger mt-1">Remove</button>     
                                      </div>
                                      <div className="col-md-10">
                                        <Form.Control className="form-control" type="file" onChange={(e) => this.onChangeThumbnail(i, e)} accept="image/*" />
                                        <Form.Text className="text-muted">
                                            {/* <span>*Please using 48 x 48 px for this image</span> <br /> */}
                                        </Form.Text>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group>
                            <Form.Label>&#42; Tag Setup</Form.Label>
                            <div className="row">
                              <div className="col-md-12 mb-2">
                                <button
                                  type="button"
                                  onClick={() => this.handleAddUrlThumbnail()}
                                  className="btn btn-sm btn-outline-success">
                                    Add Tag
                                </button>
                              </div>
                              <div className="col-md-12">
                                {this.state.addUrlThumbnail.map((element, i) => {
                                  return (
                                    <div className="row mt-2" key={i}>
                                      <div className="col-md-2">
                                        <button type="button" onClick={() => this.handleRemoveUrlThumbnail(i)} className="btn btn-sm btn-outline-danger mt-1">Remove</button>     
                                      </div>
                                      <div className="col-md-10">
                                      <select className="form-control" onChange={(e) => this.onChangeListTags(i, e)}>
                                        <option selected={element === '' ? true : false} disabled>Select Tag </option>
                                        {this.state.listTags.map((tag, id) => (
                                          <option key={id} value={tag.id} selected={tag.id === element ? true : false} disabled={this.state.disabledCoin[tag.id] ? true : false}>{tag.name}</option>  
                                        ))}
                                      </select>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      {/* <Form.Group>
                        <Form.Label>Content Description (EN)</Form.Label>
                        <FileUpload makeBase64={(val) => this.makeBase64(val)} filePreview={this.state.listImage.length > 0 ? this.state.listImage : []} />
                      </Form.Group> */}
                      <div className="d-flex justify-content-end align-items-center">
                        <button
                            type="submit"
                            disabled={this.state.loading}
                            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                              {
                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                              }
                            )}`}
                          >
                          Create Content
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}