import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import userService from '../../../crud/tokoscholarUsers';
import { toast } from 'react-toastify';
import CKEditor from 'ckeditor4-react';
import moment from 'moment';
import Swal from 'sweetalert2';

// Yup Schema

const CustomContactForm = (props) => {
  const token = () => localStorage.getItem('token');

  useEffect(() => {
    props.submitRef.current = handleSubmit;
  }, []);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      formik.setValues({
        fullname: props.data.fullname,
        avatar: props.data.profile_image,
        toko_uid: props.data.tokocrypto_uid,
        email: props.data.email,
        wa_number: props.data.mobile_no,
        student: props.data.student,
        university: props.data.university,
      });
    }
  }, [props.data]);

  //   get token
  //   const getToken = () => localStorage.getItem('token');

  //   Use Formik Hook Use

  const formik = useFormik({
    initialValues: {
      fullname: '',
      avatar: '',
      toko_uid: '',
      email: '',
      wa_number: '',
      student: '',
      university: '',
    },
    onSubmit: async (val) => {
      try {
        const res = await userService.patchUpdateUsersData(
          token(),
          props.data.id,
          {
            fullname: val.fullname,
            mobile_no: val.wa_number,
            profile_image: val.avatar,
            student: val.student,
            university: val.university,
          }
        );
        // console.log(res, 'resres');
        Swal.fire('Successfully Update Data!!', res.data.message, 'success');
      } catch (error) {
        if (error.response) {
          Swal.fire(
            'Update Data Failed!!',
            error.response.data.message || 'Something Error!!',
            'error'
          );
        }
      }
    },
  });

  //   console.log(props.isEdit, 'props.isEdit');

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleChangeImage = (e) => {
    const files = e.target.files[0];

    formik.setFieldValue('avatar', files);
  };

  return (
    <form className='m-login__form m-form'>
      <div className='row mb-2 align-items-center'>
        <div
          style={{ height: 200, width: 200, overflow: 'hidden' }}
          className='col-md-4'
        >
          {formik.values.avatar ? (
            <img
              width={'100%'}
              src={
                typeof formik.values.avatar === 'string'
                  ? formik.values.avatar
                  : URL.createObjectURL(formik.values.avatar)
              }
              alt='user'
            ></img>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'gray',
                fontSize: 18,
                color: '#ffffff',
                fontWeight: 500,
              }}
            >
              {formik.values.fullname && formik.values.fullname[0]}
            </div>
          )}
        </div>
        <div className='w-75'>
          <Form.Control
            onChange={handleChangeImage}
            className='form-control'
            type='file'
            disabled={props.isEdit ? true : false}
            // onChange={this.onChangeFile}
            accept='image/*'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-sm-12 mb-2'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Fullname</Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='fullname'
              onChange={formik.handleChange}
              value={formik.values.fullname || ''}
              // required
            />
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12 mb-2'></div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12 mb-2'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Tokocrypto UID</Form.Label>
            <Form.Control
              disabled={true}
              type='text'
              name='course_name_en'
              //                placeholder='Course Name (EN)'
              onChange={formik.handleChange}
              value={formik.values.toko_uid || ''}
              // required
            />
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12 mb-2'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>Email</Form.Label>
            <Form.Control
              disabled={true}
              type='text'
              name='course_name_id'
              placeholder='Mobile No'
              onChange={formik.handleChange}
              value={formik.values.email || ''}
              // required
            />
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12 mb-2'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Whatsapp Number
            </Form.Label>
            <Form.Control
              disabled={props.isEdit ? true : false}
              type='text'
              name='course_name_en'
              //                placeholder='Course Name (EN)'
              onChange={formik.handleChange}
              value={formik.values.wa_number || ''}
              // required
            />
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12 mb-2'>
          {/* <div className='row'>
            <div className='col-md-12'> */}
          <Form.Group>
            <Form.Label className='font-weight-bold'>Student</Form.Label>
            <div className='row ml-4'>
              <Form.Check
                inline
                checked={formik.values.student === 1 ? true : false}
                label='Yes'
                type='radio'
                name='is_student'
                disabled={props.isEdit ? true : false}
                onClick={() => {
                  formik.setFieldValue('student', 1);
                  //   setIsTokenHolder(false);
                }}
              />
              <Form.Check
                checked={formik.values.student !== 1 ? true : false}
                inline
                label='No'
                type='radio'
                disabled={props.isEdit ? true : false}
                name='is_student'
                onClick={() => {
                  formik.setFieldValue('student', 0);
                  //   setFieldValue('is_token_holder', false);
                  //   setIsTokenHolder(true);
                }}
              />
            </div>
          </Form.Group>
          {/* </div>
          </div> */}
        </div>
      </div>
      <div className='row mb-2'>
        {/* <div className='col-md-6 col-sm-6'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>University</Form.Label>
            <Form.Control
              
              disabled={props.isEdit ? true : false}
              type='textarea'
              as='textarea'
              rows={4}
              name='course_name_en'
              value={props?.data?.message || ''}
            />
          </Form.Group>
        </div> */}
        {formik?.values?.student === 1 && (
          <div className='col-md-12 col-sm-12'>
            <Form.Group>
              <Form.Label className='font-weight-bold'>University</Form.Label>
              <Form.Control
                disabled={props.isEdit ? true : false}
                type='text'
                name='course_name_en'
                value={formik?.values?.university || ''}
              />
            </Form.Group>
          </div>
        )}
      </div>
    </form>
  );
};

export default CustomContactForm;
