import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';
import { getMarketMakerById, updateMarketMaker, createMarketMaker } from '../../crud/tax-service';
import Swal from 'sweetalert2'
import { RouteComponentProps } from 'react-router';

const MarketMakerAdd: FC<RouteComponentProps<{id: string}>> = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name must be fill'),
    uid: yup
      .string()
      .matches(/^[0-9]*$/g, 'UID must number')
      .required('UID must be fill'),
  });

  const addMarketMakerFormik = useFormik({
    initialValues: {
      name: '',
      uid: ''
    },
    validationSchema,
    // enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values)
      setIsLoading(true)
      if(props.match.params?.id) {
        updateMarketMaker(Number(props.match.params.id),{name: values.name, uid: values.uid})
          .then(()=>{
            Swal.fire('Success', 'Update Successfully!', 'success');
            props.history.push('/tax/market-maker/list')
          })
          .catch(err => console.log(err))
          .finally(()=>setIsLoading(false))
      }
      else {
        createMarketMaker({name: values.name, uid: values.uid})
          .then(()=>{
            Swal.fire('Success', 'Create Successfully!', 'success');
            props.history.push('/tax/market-maker/list')
          })
          .catch(err => console.log(err))
          .finally(()=>setIsLoading(false))
      }
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    addMarketMakerFormik.handleSubmit(e);
  }

  const firstInit = () => {
    if(props.match.params?.id) {
      getMarketMakerById(Number(props.match.params.id))
      .then(res => {
          if(res.data.data){
            addMarketMakerFormik.setValues({
              name: res.data.data.name,
              uid: res.data.data.uid.toString()
            })
          }
        })
      .catch(err => {console.log(err)})
    }
  }

  useEffect(firstInit, [])

  return (
    <div style={{ fontSize: '10px' }} className="head">
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              <form
                className="m-login__form m-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Market Maker Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Market Maker Name'
                        name='name'
                        onChange={(e) => {
                          addMarketMakerFormik.setFieldValue('name', e.target.value)
                        }}
                        isInvalid={!!(addMarketMakerFormik.errors.name && addMarketMakerFormik.touched.name)}
                        value={addMarketMakerFormik.values.name}
                      />
                      {addMarketMakerFormik.errors.name &&
                        addMarketMakerFormik.touched.name && (
                          <Form.Control.Feedback type="invalid">
                            <span style={{ fontSize: '13px' }}>
                              {addMarketMakerFormik.errors.name}
                            </span>
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Binance UID</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Binance UID'
                        name='uid'
                        id='uid'
                        onChange={(e) =>
                          addMarketMakerFormik.setFieldValue('uid', e.target.value)
                        }
                        isInvalid={!!(addMarketMakerFormik.errors.uid && addMarketMakerFormik.touched.uid)}
                        value={addMarketMakerFormik.values.uid}
                      />
                      {addMarketMakerFormik.errors.uid &&
                        addMarketMakerFormik.touched.uid && (
                          <Form.Control.Feedback type="invalid">
                            <span style={{ fontSize: '13px' }}>
                              {addMarketMakerFormik.errors.uid}
                            </span>
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div
                  className='kt-login__actions mt-3'
                  style={{ justifyContent: 'center', display: 'flex', gap: 15 }}
                >
                  <button 
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={()=>props.history.push('/tax/market-maker/list')}
                  >
                    Back
                  </button>
                  <button
                    type='submit'
                    // onClick={() => isSubmit()}
                    disabled={isLoading === true ? true : false}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': isLoading,
                      }
                    )}`}
                  >
                    {props.match.params?.id ? 'Edit Market Maker' : 'Add Market Maker'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketMakerAdd