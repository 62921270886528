import { FC, useReducer } from "react";
import { Pagination, PaginationRequest } from "../../crud/global-types";
import { ReasonRequest, StatusRequest } from "../../crud/helpdesk-types";
import { useDebounce } from "../../helper";
import { getReasons } from "../../crud/helpdesk";
import ReusableListPage, { ReusableListFilter, ReusableListSecondFilter } from "../../components/reusableListPage";
import { RouteComponentProps } from "react-router-dom"
import { optionsType, reasonType } from "./reasonsCreate";

interface InitialState extends Pagination<ReasonRequest>, PaginationRequest {
  loading: boolean;
  type: StatusRequest | "";
}

const initialState: InitialState = {
  loading: false,
  total_data: 0,
  total_page: 1,
  limit: 15,
  page: 1,
  data: [],
  type: ""
}

const ReasonsHelpdesk: FC<RouteComponentProps<{id: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  useDebounce<[string | undefined, number, StatusRequest | ""]>(async (q, page, type)=>{
    try {
      const response = await getReasons({
        limit: state.limit,
        page,
        q,
        type: type ? type : undefined
      })

      if(response.data.data) dispatch(response.data.data)
    }
    catch(err: unknown) {
      console.log(err)
    }

    dispatch({loading: false})
  },[state.q, state.page, state.type], 400, () => dispatch({loading: true}))
  
  const filters: Array<ReusableListFilter<StatusRequest | "">> = [
    {
      type: "INPUT_TEXT",
      onChange: (evt) => dispatch({q: evt.target.value}),
      value: state.q,
      placeholder: "Search by keyword",
    },
    {
      type: "DROP_DOWN",
      onChange: (evt) => dispatch({type: evt.target.value as StatusRequest ?? ""}),
      value: state.type,
      options: [{label: "ALL", value: ""}, ...optionsType]
    },
  ]

  const triggerBtns: Array<ReusableListSecondFilter> = [
    {
      type: "LINK",
      label: "Add New Reasons",
      to: "/helpdesk/reasons/create"
    },
  ]

  return (
    <ReusableListPage<ReasonRequest> 
      loading={state.loading}
      filters={filters}
      triggerBtns={triggerBtns}
      dataSources={state.data}
      columns={[
        {
          dataIndex: "name",
          title: "Name"
        },
        {
          dataIndex: "details",
          title: "Details"
        },
        {
          dataIndex: "type",
          title: "Type",
          render: (_, data) => reasonType.find(o => o.value === data.type)?.label
        },
        {
          dataIndex: "id",
          title: "Action",
          render: (dataIndex?: string) => dataIndex && (
            <div className="custom-flex-row">
              <button 
                type="button"
                className="btn btn-primary"
                onClick={()=>props.history.push(`/helpdesk/reasons/${dataIndex}/update`)}
              >
                Update
              </button>
            </div>
          )
        },
      ]}
      pagination={{
        itemsCountPerPage: state.limit,
        totalItemCount: state.total_data,
        onChange: (page) => dispatch({page}),
        activePage: state.page
      }}
    />
  )
}

export default ReasonsHelpdesk