import { FC, useReducer } from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "react-bootstrap";
import FormWithFormik from "../../components/reusableFormWithFormik";
import { useFormik } from "formik";
import { CreateEmployeeRequest, HelpdeskStatus } from "../../crud/helpdesk-types";
import { useDebounce } from "../../helper";
import { createEmployee, getEmployee, updateEmployee } from "../../crud/helpdesk";
import Swal from "sweetalert2";
import { SectionWrapper } from "../user-new/user";
import * as yup from "yup";

interface InitialState {
  loading: boolean;
}

interface IFormCreate extends CreateEmployeeRequest {

}

const initialState: InitialState = {
  loading: false,
}

const EmployeeCreate: FC<RouteComponentProps<{id?: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  const formikConfig = useFormik<IFormCreate>({
    initialValues: {
      fullname: "",
      email: "",
      employee_id: "",
      status: HelpdeskStatus.ENABLE
    },
    onSubmit: async (values) => {
      dispatch({loading: true})
      try {

        if(values.id) await updateEmployee(values.id, values)
        else await createEmployee(values)

        Swal.fire("Success", values.id ? "Successfully updated data!" : "Successfully created data!", "success")
        props.history.push("/helpdesk/employees")
      }
      catch(err: unknown) {
        console.log(err)
      }
      dispatch({loading: false})
    },
    validationSchema: yup.object({
      fullname: yup.string().required('Fullname must be fill!'),
      email: yup.string().email('Email is not valid!').required('Email must be fill!'),
      employee_id: yup.string().required('Employee must be fill!'),
      status: yup.string().required('Status must be fill!')
    })
  })
  useDebounce<[string | undefined]>(async ()=>{
    try {
      if(props.match.params?.id) {
        const response = await getEmployee(props.match.params.id)
  
        if(response.data.data) formikConfig.setValues({
          id: props.match.params?.id,
          fullname: response.data.data.name,
          email: response.data.data.email,
          employee_id: response.data.data.employee_id,
          status: response.data.data.status
        })
      }
    }
    catch(err: unknown) {
      console.log(err)
    }
    dispatch({loading: false})
  },[props.match.params?.id], undefined, () => dispatch({loading: true}))
  const isUpdateMode = !!formikConfig.values.id

  return (
    <SectionWrapper>
      <form className="custom-flex-col gap-3" onSubmit={formikConfig.handleSubmit}>
        <h2>{isUpdateMode ? "Update Employee" : "Create Employee"}</h2>
        <FormWithFormik 
          label="Fullname"
          type="TEXT_INPUT"
          name="fullname"
          placeholder="Fullname"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Email"
          type="TEXT_INPUT"
          name="email"
          placeholder="Email"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Employee ID"
          type="TEXT_INPUT"
          name="employee_id"
          placeholder="EMPXXX"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Status"
          type="RADIO"
          name="status"
          options={[
            { label: "ENABLE", value: HelpdeskStatus.ENABLE },
            { label: "DISABLE", value: HelpdeskStatus.DISABLE },
          ]}
          config={formikConfig}
        />
        <br />
        <div className="custom-flex-row-center">
          <Button 
            onClick={()=>props.history.goBack()} 
            variant="outline-info"
            disabled={state.loading}
          >
            Back
          </Button>
          <Button 
            variant="outline-success" 
            onClick={()=>formikConfig.handleSubmit()}
            disabled={state.loading}
          >
            {isUpdateMode ? "Update Employee" : "Create Employee"}
          </Button>
        </div>
      </form>
    </SectionWrapper>
  )
}

export default EmployeeCreate