import React from 'react';
// import { Button, InputGroup, FormControl, Table } from "react-bootstrap";
// import _ from 'lodash';
import ReactLoading from 'react-loading';
import moment from 'moment';
import data from "../../crud/tes.json"
import actions from "../../crud/kyc";
import listData from "../../crud/json";
import { Button, InputGroup, FormControl, Table, Modal } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import PreviewImage from '../../components/previewImage';
import Pagination from "react-js-pagination";
import { Badge } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

// const sortTypes = {
//   asc: {
//     class: 'sort-up',
//     fn: (a, b) => a.net_worth - b.net_worth
//   },
//   desc: {
//     class: 'sort-down',
//     fn: (a, b) => b.net_worth - a.net_worth
//   },
//   default: {
//     class: 'sort',
//     fn: (a, b) => a
//   }
// };

const statusClass = {
  '-1': {
    class: 'primary',
    stat: 'Reject'
  },
  0: {
    class: 'warning',
    stat: 'Pending'
  },
  1: {
    class: 'success',
    stat: 'Approve'
  }
};

export default class UserHistory extends React.Component {
  course(id) {
    history.push("/user/view/" + id + "/kyc");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      data:data,
      loading: false,
      loadingPreview: false,
      totalData: 0,
      userKYC:[],
      userKYCDetail:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      show: false,
      param1: '',
      param2: '',
      province: '',
      provinces: [],
      country: '',
      countries: [],
      occupation: '',
      occupations: [],
      visible: false,
      preview: '',
      contentPreview: 'image',
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(bank, sof) {
    this.setState({ show: true,  param1:bank, param2:sof});
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  async checkParams () {
    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = ''
    }
    
    this.setState({
      loading: true
    })

    let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
    
    actions.fetchHistoryPremiumKYC(this.getToken(), id , params)
    .then(res => {
      window.pushPage = (id) => {
        history.push('customers/view/' + id + "/kyc");
      }
      this.setState({
        totalData: res.data.data.total_data,
        userKYC: res.data.data.data || '',
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        userKYC:'',
        loading: true
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    })
    
  }
  
  async getDetailUser(){
    // let token = localStorage.getItem("token");
    this.setState({
      loading: true
    })

    let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
    actions.fetchPremium(this.getToken(), id)
    .then(res => {
      this.setState({
        userKYCDetail: res.data.data,
        loading: false
      })

    })
    .catch(e => {
      console.log(e);
      this.setState({
        userKYCDetail:[],
        loading: false
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    })
  }

  getCountry(){    
    this.setState({
        
        country: false
    })
    listData.fetchCountry(this.getToken()).then(res => {
      this.setState({
        countries: res.data.data,
      })
    })
  }

  getProvinces(){    
    listData.fetchProvince(this.getToken()).then(res => {
      this.setState({
        provinces: res.data.data,
      })
    })
  }

  getOccupations(){    
    listData.fetchOccupation(this.getToken()).then(res => {
      this.setState({
        occupations: res.data.data,
      })
    })
  }

  componentDidMount(){
    this.checkParams();
    this.getDetailUser();
    this.getCountry();
    this.getProvinces();
    this.getOccupations();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.checkParams()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.checkParams()
    })
  }

  previewPDF = (url) => {
    this.setState({
      visible: true,
      preview: url,
      contentPreview: 'pdf'
    })
  };

  previewImage = (url) => {
    this.setState({
      visible: true,
      preview: url,
      contentPreview: 'image'
    })
  };

  disabledImagePreview = () =>{
    this.setState({
      visible: false,
      preview: ''
    })
  }

  getPhoto(id, type){
    this.setState({
      loadingPreview: true
    })

    actions.fetchPhoto(this.getToken(), id)
    .then(res => {

      this.setState({
        loadingPreview: false
      })
      if(type === 'preview'){
        const extension = id.split('.')[id.split('.').length - 1]
        let data = ""
        if(extension === "pdf"){
          data = "data:application/pdf;base64, " + encodeURI(res.data);
          this.previewPDF(data);
        }else{
          data = "data:image/jpg;base64," + res.data;
          this.previewImage(data);
        }
      }else{
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + res.data;
        a.download = `${id}`;
        a.click();
      }
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loadingPreview: false
      });
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    });
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('YYYY-MM-DD H:m:s');

    return fmtDate
  }

  render(){
    if(this.state.userKYC.length){
      return (
        <div style={{ fontSize: '10px'}} className="head">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-portlet__preview">
                <div className="kt-section">
                  <div className="row row-search">
                    <div className="col-sm-4">
                      <InputGroup size="sm">
                        <FormControl
                          value={this.state.seacrhParams}
                          onChange={this.changeSearch}
                          placeholder="Search"
                        />
                        <Button variant="outline-secondary" onClick={() => this.checkParams()} >Search</Button>
                      </InputGroup>
                    </div>
                  </div>
                  <Table striped bordered responsive>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle', width: '7%' }}>
                          User ID &nbsp;
                        </th>
                        <th className="center">Status</th>
                        <th className="center">Timestamp</th>
                        <th className="center">Detail</th>
                      </tr>
                    </thead>
                    {this.state.loading ? 
                      <tbody>
                        <tr>
                          <td className="center" colSpan="6"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                        </tr>
                      </tbody> :
                      <tbody>
                        {this.state.userKYC.map(row => (
                          <tr key={row.id}>
                            <td>{row.user_id}</td>
                            <td className="center">
                              <Badge bg={`${statusClass[row.status].class}`}>{`${statusClass[row.status].stat}`}</Badge>
                            </td>
                            <td className="center">{ this.dateTime(row.updated_at) }</td>
                            <td className="center">
                              <span onClick={() => this.handleShow(row.bank_statement, row.source_of_wealth)} style={{ cursor:'pointer', color: 'blue' }}>view</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  </Table>
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                  <Modal.Title>KYC Level 2</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-12">
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td style={{verticalAlign: 'middle', width: '30%'}}><strong>Action By</strong></td>
                        <td className="center">{this.state.userKYCDetail.action_by}</td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign: 'middle', width: '30%'}}><strong>Timestamp</strong></td>
                        <td className="center">{this.dateTime(this.state.userKYCDetail.updated_at)}</td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign: 'middle', width: '30%'}}><strong>Bank Statement</strong></td>
                        <td>
                          <div className="container-button">
                            {this.state.loadingPreview ? (
                              <img src="/media/loading.gif" className="img-loading" alt="" />
                            ) : (
                              <div className="grouping-button-new">
                                <button type="button" className="btn btn-in-group btn-outline btn-without-border-lr" onClick={() => this.getPremiumFile(this.state.param1, 'download')}>Download</button>
                                <button type="button" className="btn btn-in-group btn-outline" onClick={() => this.getPhoto(this.state.param1, 'preview')}>Preview</button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign: 'middle', width: '30%'}}><strong>Source Of Found</strong></td>
                        <td>
                          <div className="container-button">
                            {this.state.loadingPreview ? (
                            <img src="/media/loading.gif" className="img-loading" alt="" />
                            ) : (
                              <>
                                <div className="grouping-button-new">
                                  <button type="button" className="btn btn-in-group btn-outline btn-without-border-lr" onClick={() => this.getPremiumFile(this.state.param2, 'download')}>Download</button>
                                  <button type="button" className="btn btn-in-group btn-outline" onClick={() => this.getPhoto(this.state.param2, 'preview')}>Preview</button>
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {/* <div className="col-sm-4 margin-botHalf">Action By</div>
                <div className="col-sm-8 margin-botHalf"> : &nbsp; {this.state.userKYCDetail.action_by}</div>
                <div className="col-sm-4 margin-botHalf">Timestamp</div>
                <div className="col-sm-8 margin-botHalf"> : &nbsp; </div>
                <br />
                <br />
                {this.state.loadingPreview ? (
                  <img src="/media/loading.gif" className="img-loading" alt="" />
                ) : (
                  <div className="col-sm-12 margin-botHalf d-flex justify-content-center">
                    <div className="mt-4 mb-4 container-button">
                      <div className="grouping-button-small">
                        <button type="button" className="btn btn-in-group btc-s">Bank Statement</button>
                        <button type="button" className="btn btn-in-group btn-outline btn-without-border-lr" onClick={() => this.getPhoto(this.state.param1, 'download')}>Download</button>
                        <button type="button" className="btn btn-in-group btn-outline" onClick={() => this.getPhoto(this.state.param1, 'preview')}>Preview</button>
                      </div>
                        <div className="grouping-button-small">
                          <button type="button" className="btn btn-in-group btc-s">Source Of Found</button>
                          <button type="button" className="btn btn-in-group btn-outline btn-without-border-lr" onClick={() => this.getPhoto(this.state.param2, 'download')}>Download</button>
                          <button type="button" className="btn btn-in-group btn-outline" onClick={() => this.getPhoto(this.state.param2, 'preview')}>Preview</button>
                        </div>
                    </div>
                  </div>
                )} */}
              </div>
              {/* <div style={{ textAlign: 'center', display: 'grid', marginTop: '40px' }}>
                <br />
                <Button variant="primary" onClick={() => this.handleClose()}>
                  OK
                </Button>
              </div> */}
            </Modal.Body>
          </Modal>
          <PreviewImage active={this.state.visible} contentData={this.state.contentPreview} url={this.state.preview} disablePreview={() => this.disabledImagePreview()}/>
        </div>
      );
    }else{
      return (
        <div className="no-data">
            {this.state.loading === false ? <span>No Data Found</span> : null}
        </div>
      )
    }
  }
}