import instance from './tokoscholarInstace';

const getUsersData = (token, params) => {
  return instance.get('/admin/users', {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
    params,
    // : {
    //   mobile_no: params?.mobile_no || null,
    //   student: params?.student || null,
    //   // (student atau not-student)
    //   page: params?.page || null,
    //   limit: params?.limit || null,
    //   inquiry: params?.inquiry || null,
    //   //  (course-progress, event-tokoscholar, suggestion)
    //   q: params?.q || null,
    //   //  (isinya string bebas nanti buat query name, tokoscrypto_uid sama emai
    // },
  });
};

const getUsersById = (token, id) => {
  return instance.get('/admin/users/' + id, {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
  });
};

const updateUsersData = (token, id, body) => {
  const formData = new FormData();

  body.fullname && formData.append('fullname', body.fullname);
  body.mobile_no && formData.append('mobile_no', body.mobile_no);
  body.profile_image && formData.append('profile_image', body.profile_image);
  body.student && formData.append('student', body.student);
  body.university && formData.append('university', body.university);

  return instance.patch('/admin/users/' + id, formData, {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
  });
};

const getUserCourseData = (token, params) => {
  return instance.get('/admin/users/progress', {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
    params,
  });
};

const exportUserCourseData = (token, params) => {
  return instance.get('/admin/users/export', {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
    params,
  });
};

const fetchUsersData = (token, query) => {
  return new Promise((resolve, reject) => {
    getUsersData(token, query)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchUsersById = (token, id) => {
  return new Promise((resolve, reject) => {
    getUsersById(token, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patchUpdateUsersData = (token, id, body) => {
  return new Promise((resolve, reject) => {
    updateUsersData(token, id, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchUserCouresData = (token, params) => {
  return new Promise((resolve, reject) => {
    getUserCourseData(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchExportUserCouresData = (token, params) => {
  return new Promise((resolve, reject) => {
    exportUserCourseData(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  fetchUsersData,
  fetchUsersById,
  patchUpdateUsersData,
  fetchUserCouresData,
  fetchExportUserCouresData,
};
