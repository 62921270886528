import instance from "./axiosKycInstance";

const getTippingReward = (token, params) => {
  return instance.get("/admin/tipping/rewards" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchTippingReward = (token, params) => {
  return new Promise((resolve, reject) => {
    getTippingReward(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const postRewardStatus = (token, id, data) => {
  return instance.post("/admin/tipping/reward/"+ id +"/update-status", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const UpdateRewardStatus = (token, id, data) => {
  return new Promise((resolve, reject) => {
    postRewardStatus(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const getTippingConfig = (token) => {
  return instance.get("/admin/tipping/config", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchTippingConfig = (token) => {
  return new Promise((resolve, reject) => {
    getTippingConfig(token).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const postTippingConfig = (token, data) => {
  return instance.post("/admin/tipping/config", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateTippingConfig = (token, data) => {
  return new Promise((resolve, reject) => {
    postTippingConfig(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const postUploadFile = (token, data) => {
  return instance.post("/admin/tipping/upload-reward-tipping", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token,
      'Content-Type': 'multipart/form-data'
    }
  });
}

const createUploadFile = (token, data) => {
  return new Promise((resolve, reject) => {
    postUploadFile(token, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

export default {
  fetchTippingReward: fetchTippingReward,
  UpdateRewardStatus: UpdateRewardStatus,
  fetchTippingConfig: fetchTippingConfig,
  updateTippingConfig: updateTippingConfig,
  createUploadFile: createUploadFile,
};