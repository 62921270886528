import instance from "./axiosKycInstance";

const getPriceData = (token) => {
  return instance.get("/admin/price-alert", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchPriceData = (token) => {
  return new Promise((resolve, reject) => {
    getPriceData(token).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getCoinPair = (token) => {
  return instance.get("/admin/price-alert/coin", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchCoinPairing = (token) => {
  return new Promise((resolve, reject) => {
    getCoinPair(token).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const postUpdatePriceAlert = (token, data) => {
  return instance.post("/admin/price-alert/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updatePriceAlert = (token, data) => {
  return new Promise((resolve, reject) => {
    postUpdatePriceAlert(token, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

export default {
  fetchPriceData: fetchPriceData,
  fetchCoinPairing: fetchCoinPairing,
  updatePriceAlert: updatePriceAlert,
};