import { FC, useState, Fragment, useEffect } from 'react'
import { Stack, Button, Modal } from 'react-bootstrap'
import ReusableListPage, { ReusableListFilter, ReusableListSecondFilter } from '../../components/reusableListPage'
import { ReusableTableColumn } from '../../components/reusableTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import FormWithFormik, { FormCustomOption } from '../../components/reusableFormWithFormik';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { actions, InitialUtilsStateProps } from '../../store/ducks/utils.duck';
import { DataChannels, ListPaymentMethod } from '../../crud/middleware-types';
import { deletePaymentMethod, getDataChannels, getPaymentMethod } from '../../crud/middleware';

interface DummyData {
  id: string;
  channel: string;
  name: string;
  status: string;
}

const PaymentMethod: FC<InitialUtilsStateProps & typeof actions> = (props) => {
  const [dataSources, setDataSources] = useState<Array<ListPaymentMethod>>([])
  const [dataChannels, setDataChannels] = useState<Array<DataChannels>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [activePage, setActivePage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [totalData, setTotalData] = useState<number>(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [isAddModal, setIsAddModal] = useState(false)

  const getListPaymentMenthod = (page: number, name?: string, status?: string, limit?: number) => {
    setLoading(true)
    getPaymentMethod({ page, name, status, limit })
      .then((res) => {
        setDataSources(res.data.data?.data ?? [])
        setTotalData(res.data.data?.total_data ?? 0)
        setRowsPerPage(res.data.data?.per_page ?? 0)
      })
      .catch(err => {
        console.log(err.message)
      })
      .finally(() => setLoading(false))
  }

  const getListChannels = (page?: number, limit?: number) => {
    getDataChannels({ page, limit })
      .then((res) => {
        setDataChannels(res.data.data?.data ?? [])
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  useEffect(() => {
    getListPaymentMenthod(activePage, undefined, undefined, rowsPerPage)
    getListChannels(undefined, undefined)
  }, [activePage, rowsPerPage])

  useEffect(() => {
    // props.addModal({
    //   type: "DANGER",
    //   okLabel: "Confirm",
    //   onOk: () => { console.log('saat ok'); },
    //   cancelLabel: "Cancel",
    //   onCancel: () => { console.log('saat cancel'); },
    //   closable: true,
    //   title: "Delete Confirmation",
    //   content: 'Are you sure you want to delete this data?'
    // })
    // props.addModal({
    //   type: "INFO",
    //   okLabel: "Confirm",
    //   onOk: () => { console.log('saat ok'); },
    //   cancelLabel: "Cancel",
    //   onCancel: () => { console.log('saat cancel'); },
    //   closable: true,
    //   title: "Disable Confirmation",
    //   content: 'Are you sure you want to disable this data?'
    // })
  }, [])

  const openModal = (mode: "CHANGE_STATUS" | "ADD" | "DELETE", data?: ListPaymentMethod) => {
    if (mode === "ADD") setIsAddModal(true)
  }

  const formik = useFormik({
    initialValues: {
      channels: "",
      paymentMethodName: "",
      detailPaymentMethod: "",
      status: "1"
    },
    onSubmit: (vals) => {

    }
  })

  const columns: Array<ReusableTableColumn<ListPaymentMethod>> = [
    {
      title: "Channels",
      dataIndex: "channels"
    },
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (dataIndex) => (
        <ul className="list-status mb-0" style={{ color: dataIndex === "1" ? '#2DA602' : 'black' }}>
          <li>
            <p style={{ fontSize: '15px', color: 'black' }}>{dataIndex}</p>
          </li>
        </ul>
      )
    },
    {
      title: "Options",
      dataIndex: "id",
      render: (dataIndex, data) => (
        <Stack direction="horizontal" gap={2}>
          <Button style={{ background: '#F99705', border: 'none' }} onClick={() => openModal("CHANGE_STATUS", data)}>Disable</Button>
          <Button onClick={() => openModal("ADD", data)} style={{ background: '#278DD7', border: 'none' }}>
            <FontAwesomeIcon icon={faPen} />
          </Button>
          <Button onClick={() => openModalDel(dataIndex as string)} style={{ background: '#BC0505', border: 'none' }}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Stack>
      )
    },
  ]

  const filters: Array<ReusableListFilter> = [
    {
      type: "DROP_DOWN",
      options: dataChannels.map(data => (
        { label: data.name, value: data.id as string }
      )),
      // options: [
      //   { label: '- Channels -', value: '' },
      // ],
      value: '',
      onChange: (evt) => {

      },
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: '- Status -', value: '' },
        { label: 'Active', value: '1' },
        { label: 'Nonactive', value: '0' },
        { label: 'Pending', value: '2' },
      ],
      value: '',
      onChange: (evt) => {

      },
    },
    {
      type: "INPUT_TEXT",
      onChange: (evt) => {

      },
      value: '',
      placeholder: 'Name',
    },
  ]

  const triggerBtns: Array<ReusableListSecondFilter> = [
    {
      type: "BUTTON",
      label: 'Search',
      variants: 'success',
      onClick: () => {
        // getList(1, {
        //   q: keywords,
        //   start_date: startDate,
        //   end_date: endDate,
        //   status: statusSelected,
        // }),
      }
    },
  ]

  const openModalDel = (id: string) => {
    props.addModal({
      type: "DANGER",
      okLabel: "Confirm",
      onOk: () => {
        deletePaymentMethod(id)
          .then(() => {
            //getListPaymentMenthod(activePage, undefined, undefined, rowsPerPage)
          })
          .catch(err => {
            console.log(err.message)
          })
      },
      cancelLabel: "Cancel",
      onCancel: () => { },
      closable: true,
      title: "Delete Confirmation",
      content: 'Are you sure you want to delete this data?'
    })
  }

  return (
    <Fragment>
      <ReusableListPage
        filters={filters}
        triggerBtns={triggerBtns}
        loading={loading}
        columns={columns}
        dataSources={dataSources}
        pagination={{
          activePage,
          itemsCountPerPage: 15,
          totalItemCount,
          onChange: (pageNumber) => {
            // getList(pageNumber, {
            //   q: keywords,
            //   start_date: startDate,
            //   end_date: endDate,
            //   status: statusSelected,
            // });
            // setActivePage(pageNumber);
          },
        }}
      />
      <div className="btn-add-client-wrapper p-4">
        <Button className="btn-add-client" onClick={() => openModal("ADD")}>
          <FontAwesomeIcon icon={faPlus} color='black' />
        </Button>
      </div>

      <Modal size="lg" centered show={isAddModal} onHide={() => setIsAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Form Clients</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <FormWithFormik
              type='DROPDOWN'
              name='channels'
              className="mb-4"
              options={[
                { label: "- Channels -", value: "" }
              ]}
              label='Channels'
              config={formik}
              placeholder='Select Channel'
            />
            <FormWithFormik
              type='TEXT_AREA'
              name='paymentMethodName'
              className="mb-4"
              label='Payment Method Name'
              config={formik}
              placeholder='Insert your payment method name'
            />
            <FormWithFormik
              type='WYSIWYG'
              name='detailPaymentMethod'
              className="mb-4"
              label='Detail of Payment Method'
              config={formik}
            />
            <FormWithFormik
              type='RADIO'
              name='status'
              label='Label'
              config={formik}
              className="mb-4 mr-2"
              options={[
                { label: "Active", value: "1" },
                { label: "Disable", value: "0" }
              ]}
            />
            <Button variant="outline-success" type="submit">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
  return {
    ...props.utils
  }
}

export default connect(mapStateToProps, actions)(PaymentMethod)