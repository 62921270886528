import { PaginationRequest } from "./global-types"

export interface RequiredId {
  id: string;
}

export type OptionalId = Partial<RequiredId>

export enum UpdateRequestStatus {

}
export interface Util {
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  deleted_at?: string;
}

export enum HelpdeskStatus {
  ENABLE = '1',
  DISABLE = '0'
}

export interface Employee extends OptionalId, Util {
  email: string;
  employee_id: string;
  job_position: null
  name: string;
  organitation: null
  status: HelpdeskStatus
  thumbnail: null
}
export interface CreateEmployeeRequest extends OptionalId, Util {
  fullname: string;
  email: string;
  employee_id: string;
  status: HelpdeskStatus;
}

export interface FAQRequest extends OptionalId, Util {
  answer: string;
  question: string;
  category_id: string;
}
export interface FAQResponse extends OptionalId, Util {
  answer: string;
  question: string;
  category_id: CategoryRequest;
}

export enum HelpdeskFormTitle {
  REQUEST_FOR_ACCESS="RFA",
  SYSTEM_CHANGE_REQUEST="SCR",
  EXIT_CLEARANCE="EC",
  REQUEST_FOR_TECHNICAL_SUPPORT="RFTS",
  REQUEST_FOR_ASSET="RFA",
  REQUEST_FOR_DATA="RFD",
  OTHER_REQUEST="OR",
}

export const helpdeskFormTitles: Array<HelpdeskFormTitle> = [
  HelpdeskFormTitle.REQUEST_FOR_ACCESS,
  HelpdeskFormTitle.SYSTEM_CHANGE_REQUEST,
  HelpdeskFormTitle.EXIT_CLEARANCE,
  HelpdeskFormTitle.REQUEST_FOR_TECHNICAL_SUPPORT,
  HelpdeskFormTitle.REQUEST_FOR_ASSET,
  HelpdeskFormTitle.REQUEST_FOR_DATA,
  HelpdeskFormTitle.OTHER_REQUEST,
]

export interface CategoryRequest extends OptionalId, Util {
  title: string;
  code?: HelpdeskFormTitle;
  description: string;
  image_url?: string;
  doc?: File;
  status?: HelpdeskStatus;
}

export enum StatusRequest {
  WAITING_CONFIRMATION = '1',
  RECEIVED = '2',
  WAITING_APPROVAL = '3',
  OPEN = '4',
  ON_PROCESSED = '5',
  ON_HOLD = '6',
  DONE = '7',
  REJECT = '8',
}

export const statusRequestOptions = [
  { label: "WAITING CONFIRMATION", value: StatusRequest.WAITING_CONFIRMATION, color: "#1E1E2C", disabled: true, icon: "fa-solid fa-arrow-up" },
  { label: "RECEIVED", value: StatusRequest.RECEIVED, color: "#3876D4", disabled: true, icon: "fa-solid fa-arrow-down" },
  { label: "OPEN", value: StatusRequest.OPEN, color: "#0E8595", disabled: true, icon: "fa-solid fa-envelope-open" },
  { label: "WAITING APPROVAL", value: StatusRequest.WAITING_APPROVAL, color: "#494E50", disabled: true, icon: "fa-solid fa-list-check" },
  { label: "ON PROCESSED", value: StatusRequest.ON_PROCESSED, color: "#6C63FF", dropTarget: [StatusRequest.ON_PROCESSED, StatusRequest.ON_HOLD, StatusRequest.DONE, StatusRequest.REJECT], icon: "fa-solid fa-spinner" },
  { label: "ON HOLD", value: StatusRequest.ON_HOLD, color: "#F99705", dropTarget: [StatusRequest.ON_HOLD, StatusRequest.ON_PROCESSED], icon: "fa-solid fa-hand-holding" },
  { label: "DONE", value: StatusRequest.DONE, color: "#0C7938", disabled: true, icon: "fa-solid fa-check" },
  { label: "REJECT", value: StatusRequest.REJECT, color: "#FF0000", disabled: true, icon: "fa-solid fa-x" },
]

export const statusRequestSwimlaneOptions = statusRequestOptions.filter(s => s.value !== StatusRequest.RECEIVED)

export interface ReasonRequest extends OptionalId, Util {
  name: string;
  details: string;
  type: Reason;
}

export interface AccessListRequest extends OptionalId, Util {
  name: string;
}
export interface SystemListRequest extends OptionalId, Util {
  name: string;
  status: HelpdeskStatus;
}

export enum PriorityRequest {
  LOW = "1",
  MEDIUM = "2",
  HIGH = "3",
  URGENT = "4",
}

export const priorities = [
  { label: "Low (14 days)", value: PriorityRequest.LOW },
  { label: "Medium (3-4 days)", value: PriorityRequest.MEDIUM },
  { label: "High (2 days)", value: PriorityRequest.HIGH },
  { label: "URGENT! (EOD/1D)", value: PriorityRequest.URGENT },
]

export interface PublicRequest {
  title: string;
  systemlist_id: string | number;
  access_list?: Array<AccessListRequest>;
  category_id: string | number;
  email: string;
  assignee?: Employee;
  priority?: PriorityRequest;
  additional_info: string;
  additional_information?: string;
  note?: string;
  start_date: string;
  end_date: string;
  doc_url: string;
  order?: string | number;
  unique_code?: string;
  request_approval: Array<RequestApprover>;
  status?: StatusRequest;
  created_at?: string;
  id?: string | number;
  created_by?: Employee;
}

export type GetPublicRequest = Omit<PublicRequest, "category_id" | "systemlist_id"> & {
  category_id?: CategoryRequest;
  systemlist_id?: SystemListRequest;
  updated_at?: string;
}

export interface CreateRequest extends Partial<Omit<PublicRequest, "access_list" | "assignee">> {
  access_list: Array<string | number>;
  assignee?: string;
}

export interface UpdateRequest {
  status: UpdateRequestStatus
}

export interface ApproverSetterRequest {
  request_id: string,
  employees_id: Array<string>
}

export interface ResendApprovalRequest {
  request_id: string;
  email: string;
}

export interface RequestApprover{
  id: number;
  status: StatusRequest;
  unique_code: string;
  last_update: string;
  notes?: string | null;
  employee_id: Employee;
  reasons_id?: string | null;
}

export interface GetRequestConfirmation extends Omit<PublicRequest, "category_id" | "systemlist_id">, Omit<Util, "created_by"> {
  systemlist_id?: SystemListRequest;
  category_id?: CategoryRequest;
  created_by?: Employee;
  status: StatusRequest;
  request_approval: Array<RequestApprover>;
  id: number;
}

export interface UpdateRequestConfirmation {
  status: StatusRequest;
  notes?: string;
  reason_id?: string | number;
}

export interface UpdateListRequestPayload {
  id: number;
  status: StatusRequest;
  note?: string;
  order: string;
}

export interface UpdateListRequest {
  statusIndex: Array<UpdateListRequestPayload>;
}

export interface HistoryRequest {
  id: number;
  before_status: StatusRequest;
  after_status: StatusRequest;
  request_id: RequestData;
  created_by: number;
  created_at: string | null;
  deleted_at: string | null;
}

export interface RequestApproval {
  id: number;
  request_id: RequestData;
  employee_id: Employee;
  reasons_id: ReasonRequest;
  notes: string;
}

export interface RequestData {
  id: number;
  title: string;
  additional_information: string;
  doc_url: string;
  unique_code: string;
  status: StatusRequest;
  start_date: string | null;
  end_date: string | null;
  history_request: Array<HistoryRequest>;
  request_approval: Array<RequestApproval>;
  created_by: Employee;
  category_id: CategoryRequest;
  systemlist_id: SystemListRequest;
  updated_by: number;
  order: number;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  access_list?: Array<AccessListRequest>;
  assignee?: Employee;
  priority?: PriorityRequest;
  note?: string;
}

export interface RequestCountResponse {
  status: StatusRequest;
  status_name: string;
  total: string;
}

export enum Reason {
  CANCEL = '1',
  APPROVE = '2',
  REJECT = '3',
}

export interface Reasons {
  id: number;
  name: string;
  details: string;
  type: Reason;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface RequestApprovalResponse {
  id: number;
  request_id: RequestData;
  access_list?: Array<AccessListRequest>;
  employee_id: Employee;
  status: StatusRequest;
  unique_code: string;
  last_update: string;
  reasons_id: Reasons;
  notes: string;
  assignee?: Employee;
  priority?: PriorityRequest;
}

export interface HistoryRequestParams extends PaginationRequest {
  status?: StatusRequest;
  category_id?: number;
  /** YYYY-MM-DD */
  start_date?: string;
  /** YYYY-MM-DD */
  end_date?: string;
}

export interface HistoryResponse {
  id: number;
  before_status: StatusRequest;
  after_status: StatusRequest;
  created_by?: Employee;
  created_at: string;
  request_id: RequestData;
}

export interface TrackingTicketRequest {
  tiket_id: Array<string>;
}

export interface TrackingTicketResponse {
  tiket_no: number;
  uniqe_code: string;
  request_date: string;
  status: string;
  history_request: Array<HistoryRequest>;
}

export interface RequestCountFilter {
  /** YYYY-MM-DD */
  start_date: string;
  /** YYYY-MM-DD */
  end_date: string;
}

export interface RealtimeResponse {
  category: CategoryRequest;
  created_date: string;
  id: number | string;
  last_update: string;
  order: number;
  status: StatusRequest;
  title: string;
  additional_information?: string;
}

export interface RealtimeInstanceResponse {
  from?: RealtimeResponse;
  to: RealtimeResponse;
}

export interface GeneralReportParams extends PaginationRequest {
  /** YYYY-MM-DD */
  start_date?: string;
  /** YYYY-MM-DD */
  end_date?: string;
}

export interface GeneralReportResponse {
  additional_information: string;
  created_at: string;
  doc_url?: string;
  end_date: string;
  id: number;
  note?: string;
  order?: number;
  priority: PriorityRequest;
  start_date: string;
  status: StatusRequest;
  title: string;
  unique_code: string;
  updated_at: string;
  updated_by?: string;
}

export interface AccessListReportResponse {
  access_name: string;
  created_date: string;
  email: string;
  employee_id: string;
  id: number;
  job_position?: string;
  name: string;
  organitation?: string;
  status: StatusRequest;
  thumbnail?: string;
  title: string;
}