import { subMonths, format } from "date-fns"
import { useFormik } from "formik"
import { FC, Fragment, ReactNode } from "react"
import { Modal } from "react-bootstrap"
import FormWithFormik from "../../components/reusableFormWithFormik"
import ReusableTable from "../../components/reusableTable"
import { Pagination, PaginationRequest } from "../../crud/global-types"
import { getAccessListReports } from "../../crud/helpdesk"
import { AccessListReportResponse, GeneralReportResponse, PriorityRequest, StatusRequest } from "../../crud/helpdesk-types"
import { capitalizeEveryWord, globalDateTime, useDebounce, useMultipleState } from "../../helper"
import { PopoverAction, RowWrapper } from "../user-new/user"

interface InitialState extends Pagination<AccessListReportResponse>, PaginationRequest {
  loading: boolean;
  selectedDetail?: AccessListReportResponse;
}

interface IForm {
  keyword: string;
  start_date?: Date | null;
  end_date?: Date | null;
}

interface DetailRenderer {
  key: keyof AccessListReportResponse;
  renderer?: (data: AccessListReportResponse) => ReactNode;
}

const initialState: InitialState = {
  loading: false,
  total_data: 0,
  total_page: 1,
  limit: 5,
  page: 1,
  data: [],
}

const AccessListReport: FC = () => {
  const config = useFormik<IForm>({
    initialValues: {
      start_date: subMonths(new Date(), 1),
      end_date: new Date(),
      keyword: "",
    },
    onSubmit: () => {}
  })
  const [state, dispatch] = useMultipleState<InitialState>(initialState)
  
  const accessListReportService = (q: string, limit: number, page: number, start_date?: Date | null, end_date?: Date | null) => {
    getAccessListReports({
      limit,
      page,
      q,
      start_date: start_date ? format(start_date, "yyyy-MM-dd") : undefined,
      end_date: end_date ? format(end_date, "yyyy-MM-dd") : undefined,
    })
      .then(res => dispatch({
        data: res.data.data?.data,
        total_data: res.data.data?.total_data,
        total_page: res.data.data?.total_page
      }))
      .catch(err => console.log(err))
      .finally(()=>dispatch({loading: false}))
  }

  useDebounce<[string, number, number, Date | null | undefined, Date | null | undefined]>(accessListReportService, [config.values.keyword, state.limit, state.page, config.values.start_date, config.values.end_date], 400, () => dispatch({loading: true}))
  
  const detailRenderer = () => {
    if(state.selectedDetail) {
      // access_name: string;
      // created_date: string;
      // email: string;
      // employee_id: string;
      // id: number;
      // job_position?: string;
      // name: string;
      // organitation?: string;
      // status: StatusRequest;
      // thumbnail?: string;
      // title: string;
      const renderer: Array<DetailRenderer> = [
        { key: "title" },
        { key: "access_name" },
        { key: "created_date", renderer: (data) => data.created_date ? globalDateTime(data.created_date) : null },
        { key: "email" },
        { key: "employee_id" },
        { key: "job_position" },
        { key: "name" },
        { key: "organitation" },
        { 
          key: "status", 
          renderer: (data) => {
            const selectedStatus = Object.entries(StatusRequest).find(([_, value]) => value === data.status)?.[0]

            if(selectedStatus) return selectedStatus.replace(/_/g, " ")
            else return null
          }
        },
      ]

      return renderer.map((r, index) => (
        <div className="custom-flex-row-space-between" key={index}>
          <b>{capitalizeEveryWord(r.key.replace(/_/g," "))}</b>
          <span>{r.renderer ? r.renderer(state.selectedDetail!!) : state.selectedDetail!![r.key]}</span>
        </div>
      ))
    }
    else return null
  }
  
  return (
    <Fragment>
      <div className="custom-flex-col-center gap-2">
        <FormWithFormik 
          type="TEXT_INPUT"
          config={config}
          name="keyword"
          placeholder="Search by keyword"
          label="Search"
        />
        <div className="custom-flex-row-center gap-2 w-100">
          <FormWithFormik 
            type="DATE"
            config={config}
            name="start_date"
            placeholderText="Start Date"
            label="Start Date"
            isClearable
          />
          <FormWithFormik 
            type="DATE"
            config={config}
            name="end_date"
            placeholderText="End Date"
            label="End Date"
            isClearable
          />
        </div>
      </div>
      <ReusableTable<AccessListReportResponse>
        className="mt-3"
        loading={state.loading}
        dataSources={state.data}
        columns={[
          {
            dataIndex: "title",
            title: "Title",
            render: (_, data) => {
              
              return (
                <RowWrapper position="LEFT">
                  <div className="ml-3">
                    <div className="new-version-detail-avatar">{data.name.split(' ').map(i => (i[0] ?? "").toUpperCase()).join('').slice(0, 2)}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "name",
            title: "Name",
            render: (_, data) => {
              return (
                <RowWrapper>
                  <div className="mt-3">
                    <div className="new-version-kyc-table-label">{data.name}</div>
                    <div>{data.email}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "employee_id",
            title: "Employee ID",
            render: (_, data) => {
              return (
                <RowWrapper>
                  <div className="mt-3">
                    <div className="new-version-kyc-table-label">Employee ID</div>
                    <div>{data.employee_id}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "title",
            title: "Title",
            render: (_, data) => {
              return (
                <RowWrapper>
                  <div className="mt-3">
                    <div className="new-version-kyc-table-label">{data.title}</div>
                    <div>{data.access_name}</div>
                  </div>
                </RowWrapper>
              )
            }
          },
          {
            dataIndex: "id",
            title: "Action",
            render: (_, data) => {
              return (
                <RowWrapper position="RIGHT" className="custom-flex-row-center">
                  <PopoverAction label="Open Detail Report">
                    <button className={`new-version-user-action-btn new-version-user-action-btn-none`} onClick={()=>dispatch({selectedDetail: data})}><span className="far fa-eye" /></button>
                  </PopoverAction>
                </RowWrapper>
              )
            }
          }
        ]}
        pagination={{
          itemsCountPerPage: state.limit,
          totalItemsCount: state.total_data,
          onChange: (page) => dispatch({page}),
          activePage: state.page
        }}
        withoutDefaultStyles
        withoutHeader
      />
      <Modal
        show={!!state.selectedDetail}
        onHide={() => dispatch({selectedDetail: undefined})}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Access List Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-flex-col gap-3">
          {detailRenderer()}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default AccessListReport