import instance from "./axiosKycInstance";

const getLeaderboard = (token, params) => {
  return instance.get("/admin/leaderboard" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchLeaderboard = (token, params) => {
  return new Promise((resolve, reject) => {
    getLeaderboard(token, params).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getDetailLeaderboard = (token, id) => {
  return instance.get("/admin/leaderboard/" + id + "/detail", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailLeaderboard = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailLeaderboard(token, id).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getCoinPair = (token) => {
  return instance.get("/admin/leaderboard/get-coin", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchCoinPairing = (token) => {
  return new Promise((resolve, reject) => {
    getCoinPair(token).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

export const getHistoryUploadTransaction = (params) => {
  return instance.get("/admin/leaderboard/history", {params});
}

const fetchHistoryUploadTransaction = (token, params) => {
  return new Promise((resolve, reject) => {
    getHistoryUploadTransaction(token, params).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const postCreateEvent = (token, data) => {
  return instance.post("/admin/leaderboard/create", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token,
      'Content-Type': 'multipart/form-data'
    }
  });
}

const createEvent = (token, data) => {
  return new Promise((resolve, reject) => {
    postCreateEvent(token, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

export const postUploadFile = (data) => {
  return instance.post("/admin/leaderboard/upload-data", data);
}

const createUploadFile = (token, data) => {
  return new Promise((resolve, reject) => {
    postUploadFile(token, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const patchUpdtaeEvent = (token, id, data) => {
  return instance.put("/admin/leaderboard/"+ id + "/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateEvent = (token, id, data) => {
  return new Promise((resolve, reject) => {
    patchUpdtaeEvent(token, id, data).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const deleteAdminEvent = (token, id) => {
  return instance.delete("/admin/leaderboard/" + id + "/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteEvent = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteAdminEvent(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

export default {
  fetchLeaderboard: fetchLeaderboard,
  fetchDetailLeaderboard: fetchDetailLeaderboard,
  fetchCoinPairing: fetchCoinPairing,
  fetchHistoryUploadTransaction: fetchHistoryUploadTransaction,
  createUploadFile: createUploadFile,
  createEvent: createEvent,
  updateEvent: updateEvent,
  deleteEvent: deleteEvent,
};