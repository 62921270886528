import axiosInstance from "../../axios_config";

export const getBanks = (params) => {
  return axiosInstance().get("admin/bank-account",{params})
}
export const getBankDetail = (id) => {
  return axiosInstance().get("admin/bank-account/"+id)
}
export const createBank = (payload) => {
  return axiosInstance().post("admin/bank-account", payload)
}
export const updateBank = (id, payload) => {
  return axiosInstance().patch("admin/bank-account/"+id, payload)
}
export const deleteBank = (id) => {
  return axiosInstance().delete("admin/bank-account/"+id)
}