export enum SipendarSource {
  SIPENDAR = "sipendar",
  TERRORIST = "terrorist"
}

export interface SipendarRequest {
  source?: SipendarSource;
  queue?: number;
}

export enum SipendarUserKycStatus {
  REJECTED = -1,
  PENDING = 0,
  APPROVED = 1,
  UNKNOWN = 2
}

export const sipendarUserKycStatuses = [
  {label: "Rejected", value: SipendarUserKycStatus.REJECTED},
  {label: "Pending", value: SipendarUserKycStatus.PENDING},
  {label: "Approve", value: SipendarUserKycStatus.APPROVED},
  {label: "Unknown", value: SipendarUserKycStatus.UNKNOWN},
]

export enum SipendarScreeningRisk {
  NOT_A_THREAT = -1,
  VERY_LOW = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3
}

export enum SipendarGovermentStatus {
  REJECT = -1,
  PENDING = 0,
  APPROVE = 1,
  FOREIGNER = 2
}

export enum SipendarLivenessCheck {
  UNKNOWN = -1,
  NEED_MANUAL_INTEVENTION = 0,
  NO_NEED_MANUAL_INTEVENTION = 1
}

export enum SipendarManualUser {
  PENDING = 0,
  MANUAL = 1
}

export interface SipendarMatchedUser {
  id: number;
  ref_id: string;
  email: string;
  kyc_status: SipendarUserKycStatus;
  kyc_level: SipendarUserKycStatus;
  screening_risk: SipendarScreeningRisk;
  government_status : SipendarGovermentStatus;
  liveness_check: SipendarLivenessCheck;
  file_limit: number;
  file_count: number;
  mobile_no: string;
  sendgrid_recipient_id: string;
  created_date: string;
  updated_date: string;
  created_by: string;
  action_by: string;
  reset_by: string;
  approved_by: string;
  rejected_by: string;
  accepted_at: string;
  rejected_at: string;
  premium_at: string;
  premium_by: string;
  reset_at: string;
  liveness_at: string;
  liveness_provider_at: string;
  is_manual : SipendarManualUser;
  created_at: string;
  updated_at: string;
}

export interface Sipendar {
  createdAt: string;
  creator: string;
  date_of_birth: string;
  id: number;
  name: string;
  nik?: string;
  place_of_birth?: string;
  source: SipendarSource;
  updatedAt: string;
}

export type GenerateSipendarReportRequest = Required<SipendarRequest>

export interface GenerateSipendarReportResponse {
  id: number;
  data_samples: number;
  data_db: number;
  total_matched: number;
  total_unmatched: number;
  creator: string;
  source: SipendarSource;
  updatedAt: string;
  createdAt: string;
}

export interface SipendarReport {
  id: number;
  report_id: number;
  user_id?: string | number;
  date_of_birth: string;
  place_of_birth: string;
  nik: string;
  source: SipendarSource;
  creator: string;
  generator: string;
  is_match: boolean;
  createdAt: string;
  updatedAt: string;
  matched_user?: SipendarMatchedUser;
  kyc_status?: string | number;
  email?: string;
}

export interface UploadTerroristDataRequest {
  file: File;
  source: SipendarSource;
}

export interface UpdateSipendarDatabaseRequest {
  name: string;
  date_of_birth: string;
}