/* eslint-disable max-len */
import React, { useEffect } from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const PreviewImage = ({ active = true, contentData = 'image', url, disablePreview }) => {
  const disableScroll = () => {
    if(document.getElementById('preview-fullscreen')){
      document.getElementById('preview-fullscreen').style.zIndex = '999';
      document.getElementById('preview-fullscreen').style.top = '-10px';
      const element = document.getElementById('preview-fullscreen');
      element.classList.add('preview-fullscreen-active');
    }
    if(document.getElementsByClassName('modal')[0]){
      document.getElementsByClassName('modal')[0].style.zIndex = '-1';
    }
    if(document.getElementsByClassName('modal-backdrop')[0]){
      document.getElementsByClassName('modal-backdrop')[0].style.zIndex = '-1';
    }
    // document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  };
  const enableScroll = () => {
    // document.getElementById('modalKYC').style.zIndex = '999';
    if(document.getElementsByClassName('modal')[0]){
      document.getElementsByClassName('modal')[0].style.zIndex = '999';
    }
    if(document.getElementsByClassName('modal-backdrop')[0]){
      document.getElementsByClassName('modal-backdrop')[0].style.zIndex = '999';
    }
    if(document.getElementById('preview-fullscreen')){
      document.getElementById('preview-fullscreen').style.zIndex = '';
      const element = document.getElementById('preview-fullscreen');
      element.classList.remove('preview-fullscreen-active');
    }
    // document.getElementsByTagName('body')[0].style.overflow = '';
  };
  const tutupAction = () => {
    enableScroll();
    disablePreview();
  };
  useEffect(() => {
    setTimeout(()=>{
      if (active) {
        disableScroll();
      } else {
        enableScroll();
      }
    }, 1000)
  }, [active]);
  return (
    <div className="preview-fullscreen" id="preview-fullscreen">
      <div className="preview-frame">
        <button type="button" className="btn btn-sm btn-success ml-2" onClick={tutupAction}>Tutup</button>
      </div>
      <div className="preview-content">
        {contentData === 'image' &&
          <ReactPanZoom image={url} alt="document image" />
        }
        {contentData === 'video' &&
          <video autoPlay controls src={url} width="640" height="365">
            The “video” tag is not supported by your browser.
          </video>
        }
        {contentData === 'pdf' &&
          <iframe width='100%' height='100%' src={url} title="PDF" />
        }
      </div>
    </div>
  );
};
export default PreviewImage;
