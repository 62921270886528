import instance from "./axiosKycInstance";

const getSummaryCategory = (token, params) => {
  return instance.get("/admin/launcher/category-list" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchSummaryCategory = (token, params) => {
  return new Promise((resolve, reject) => {
    getSummaryCategory(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailSummaryCategory = (token, id) => {
  return instance.get("/admin/launcher-category/" + id + "/detail", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailSummaryCategory = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailSummaryCategory(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const createCategory = (token, data) => {
  return instance.post("/admin/launcher/create-category", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postCategory = (token, data) => {
  return new Promise((resolve, reject) => {
    createCategory(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const updateCategory = (token, id, data) => {
  return instance.put("/admin/launcher-category/"+id+"/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putCategory = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateCategory(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const updateStatusCategory = (token, id, data) => {
  return instance.put("/admin/launcher-category/"+id+"/update-status", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putStatusCategory = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateStatusCategory(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const deleteCategory = (token, id) => {
  return instance.delete("/admin/launcher-category/"+id+"/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteSummaryCategory = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteCategory(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getLinkCategory = (token, id, params) => {
  return instance.get("/admin/launcher/"+id+"/link-list" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchLinkCategory = (token, id, params) => {
  return new Promise((resolve, reject) => {
    getLinkCategory(token, id, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailLinkCategory = (token, id) => {
  return instance.get("/admin/launcher-list/" + id + "/detail", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailLinkCategory = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailLinkCategory(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const createLinkCategory = (token, data) => {
  return instance.post("/admin/launcher/create-link", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postLinkCategory = (token, data) => {
  return new Promise((resolve, reject) => {
    createLinkCategory(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const updateLinkCategory = (token, id, data) => {
  return instance.put("/admin/launcher/"+id+"/update-link", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putLinkCategory = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateLinkCategory(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const updateStatusLinkCategory = (token, id, data) => {
  return instance.put("/admin/launcher/"+id+"/update-link-status", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putStatusLinkCategory = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateStatusLinkCategory(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const deleteLinkCategory = (token, id) => {
  return instance.delete("/admin/launcher-list/"+id+"/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteLink = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteLinkCategory(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getSummaryTag = (token, params) => {
  return instance.get("/admin/launcher/tags-list" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchSummaryTag = (token, params) => {
  return new Promise((resolve, reject) => {
    getSummaryTag(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getSummaryListTag = (token) => {
  return instance.get("/admin/launcher/tags", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchSummaryListTag = (token) => {
  return new Promise((resolve, reject) => {
    getSummaryListTag(token).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailSummaryTag = (token, id) => {
  return instance.get("/admin/launcher-tag/" + id + "/detail", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailSummaryTag = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailSummaryTag(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const createTag = (token, data) => {
  return instance.post("/admin/launcher/create-tag", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postTag = (token, data) => {
  return new Promise((resolve, reject) => {
    createTag(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const updateTag = (token, id, data) => {
  return instance.put("/admin/launcher-tag/"+id+"/update", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putTag = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateTag(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
        reject(err);
    })
  });
}

const deleteLinkTag = (token, id) => {
  return instance.delete("/admin/launcher-tag/"+id+"/delete", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteTag = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteLinkTag(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}


export default {
  fetchSummaryCategory: fetchSummaryCategory,
  fetchDetailSummaryCategory: fetchDetailSummaryCategory,
  postCategory: postCategory,
  putCategory: putCategory,
  putStatusCategory: putStatusCategory,
  deleteSummaryCategory: deleteSummaryCategory,
  fetchLinkCategory: fetchLinkCategory,
  fetchDetailLinkCategory: fetchDetailLinkCategory,
  postLinkCategory: postLinkCategory,
  putLinkCategory: putLinkCategory,
  putStatusLinkCategory: putStatusLinkCategory,
  deleteLink: deleteLink,
  fetchSummaryTag: fetchSummaryTag,
  fetchSummaryListTag: fetchSummaryListTag,
  fetchDetailSummaryTag: fetchDetailSummaryTag,
  postTag: postTag,
  putTag: putTag,
  deleteTag: deleteTag,
};