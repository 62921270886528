import instance from './tokoscholarInstace';

const getTopics = (token, { q, limit, page, status, course_id }) => {
  return instance.get('/admin/topics', {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
    params: {
      q: q || undefined,
      limit: limit || undefined,
      page: page || undefined,
      status: status === '' ? undefined : status,
      course_id: course_id === '' ? undefined : course_id,
    },
  });
};

const fetchTopic = (token, query) => {
  return new Promise((resolve, reject) => {
    getTopics(token, query)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getTopicDetail = (token, params) => {
  return instance.get('/admin/topics/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchTopicDetail = (token, params) => {
  return new Promise((resolve, reject) => {
    getTopicDetail(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const addTopic = (token, body) => {
  return instance.post('/admin/topics/create', body, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchAddTopic = (token, body) => {
  return new Promise((resolve, reject) => {
    addTopic(token, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editTopic = (token, params, body) => {
  return instance.patch('/admin/topics/' + params, body, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchEditTopic = (token, params, body) => {
  return new Promise((resolve, reject) => {
    editTopic(token, params, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteTopic = (token, params) => {
  return instance.delete('/admin/topics/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchDeleteTopic = (token, params) => {
  return new Promise((resolve, reject) => {
    deleteTopic(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  fetchTopic: fetchTopic,
  fetchTopicDetail: fetchTopicDetail,
  fetchAddTopic: fetchAddTopic,
  fetchDeleteTopic: fetchDeleteTopic,
  fetchEditTopic: fetchEditTopic,
};
