import instance from "./axiosKycInstance";

const getDataSubmission = (token, options) => {
  return instance.get("/admin/kyc-update/data-submission", {
    ...options,
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDataSubmission = (token, options) => {
  return new Promise((resolve, reject) => {
    getDataSubmission(token, options).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getListDataSubmission = (token, params) => {
  return instance.get("/admin/kyc-update/submission-list" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchListDataSubmission = (token, params) => {
  return new Promise((resolve, reject) => {
    getListDataSubmission(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailListDataSubmission = (token, id) => {
  return instance.get("/admin/kyc-update/submission-list/" + id, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailListDataSubmission = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailListDataSubmission(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const postEmailNotif = (token, data) => {
  return instance.post("/admin/kyc-update/email-notification", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const sendEmailNotif = (token, data) => {
  return new Promise((resolve, reject) => {
    postEmailNotif(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const postDownloadDataSubmission = (token, data) => {
  return instance.post("/admin/kyc-update/download-report", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const downloadDataSubmission = (token, data) => {
  return new Promise((resolve, reject) => {
    postDownloadDataSubmission(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const postResendEmail = (token, id, data) => {
  return instance.post("/admin/kyc-update/email-notification/"+ id +"/resend", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const resendEmail = (token, id, data) => {
  return new Promise((resolve, reject) => {
    postResendEmail(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

export default {
  fetchDataSubmission: fetchDataSubmission,
  fetchListDataSubmission: fetchListDataSubmission,
  fetchDetailListDataSubmission: fetchDetailListDataSubmission,
  sendEmailNotif: sendEmailNotif,
  downloadDataSubmission: downloadDataSubmission,
  resendEmail: resendEmail,
};