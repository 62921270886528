import axios from "axios";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { objFormData } from "../helper";
import { Pagination, PaginationRequest, ResponseAPI } from "./global-types";
import { AccessListRequest, CategoryRequest, CreateEmployeeRequest, Employee, FAQRequest, PublicRequest, ReasonRequest, SystemListRequest, UpdateRequest, StatusRequest, FAQResponse, GetRequestConfirmation, UpdateRequestConfirmation, UpdateListRequest, RequestCountResponse, ApproverSetterRequest, ResendApprovalRequest, RequestApprovalResponse, Reason, GetPublicRequest, HistoryRequestParams, HistoryResponse, TrackingTicketRequest, TrackingTicketResponse, RequestCountFilter, CreateRequest, GeneralReportParams, GeneralReportResponse, AccessListReportResponse } from "./helpdesk-types";
import { cancelTokenConfiguration } from "./instanceWithCancelToken";

const history =  createBrowserHistory();

export const baseURL = process.env.REACT_APP_ENV === 'demo' ? "https://helpdesk-service-demo.tcdx.id/" : "https://helpdesk-service.tokocrypto.com/"

const instance = (isCancelToken?: boolean, isUnauthorized?: boolean) => {
  const i = axios.create({
    baseURL,
  });

  if(!isUnauthorized) {
    i.interceptors.request.use((config) => {
      if(config.headers) {
        config.headers.Authorization = localStorage.getItem("token");
        config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
      }
      if(isCancelToken) cancelTokenConfiguration(config)
      return config
    })
    
    i.interceptors.response.use(config => config, (err) => {
      if(err.response){
        if(err.response.status === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
        }
      }
    
      return Promise.reject(err)
    })
  }

  return i
}

const i = instance()
const unauthorizedI = instance(true, true)

export const createEmployee = (payload: CreateEmployeeRequest) => i.post<ResponseAPI<boolean>>("/admin/employee", payload)
export const getEmployees = (params: PaginationRequest) => i.get<ResponseAPI<Pagination<Employee>>>("/admin/employee", {params})
export const getEmployee = (id: string) => i.get<ResponseAPI<Employee>>(`/admin/employee/${id}`)
export const updateEmployee = (id: string, payload: CreateEmployeeRequest) => i.patch<ResponseAPI<boolean>>(`/admin/employee/${id}`, payload)
export const deleteEmployee = (id: string) => i.delete<ResponseAPI<boolean>>(`/admin/employee/${id}`)

export const createFaq = (payload: FAQRequest) => i.post<ResponseAPI<boolean>>("/admin/faq", payload)
export const getFaqs = (params: PaginationRequest) => i.get<ResponseAPI<Pagination<FAQRequest>>>('/admin/faq', {params})
export const getFaq = (id: string) => i.get<ResponseAPI<FAQResponse>>(`/admin/faq/${id}`)
export const updateFaq = (id: string, payload: FAQRequest) => i.patch<ResponseAPI<boolean>>(`/admin/faq/${id}`, payload)
export const deleteFaq = (id: string) => i.delete<ResponseAPI<boolean>>(`/admin/faq/${id}`)

export const createCategory = (payload: CategoryRequest) => i.post<ResponseAPI<boolean>>("/admin/categories", objFormData(payload))
export const getCategories = (params: PaginationRequest) => i.get<ResponseAPI<Pagination<CategoryRequest>>>("/admin/categories/paginate",{params})
export const getCategory = (id: string) => i.get<ResponseAPI<CategoryRequest>>(`/admin/categories/${id}`)
export const updateCategory = (id: string, payload: CategoryRequest) => i.patch<ResponseAPI<boolean>>(`/admin/categories/${id}`, objFormData(payload))
export const deleteCategory = (id: string) => i.delete<ResponseAPI<boolean>>(`/admin/categories/${id}`)

export const createReason = (payload: ReasonRequest) => i.post<ResponseAPI<boolean>>("/admin/reason", payload)
export const getReasons = (params: PaginationRequest & { type?: StatusRequest }) => i.get<ResponseAPI<Pagination<ReasonRequest>>>("/admin/reason", {params})
export const getReason = (id: string) => i.get<ResponseAPI<ReasonRequest>>(`/admin/reason/${id}`)
export const updateReason = (id: string, payload: ReasonRequest) => i.patch<ResponseAPI<boolean>>(`/admin/reason/${id}`, payload)
export const deleteReason = (id: string) => i.delete<ResponseAPI<boolean>>(`/admin/reason/${id}`)

export const createAccessList = (payload: AccessListRequest) => i.post<ResponseAPI<boolean>>("/admin/access-list", payload)
export const getAccessLists = (params: PaginationRequest) => i.get<ResponseAPI<Pagination<AccessListRequest>>>("/admin/access-list/paginate",{params})
export const getAccessList = (id: string) => i.get<ResponseAPI<AccessListRequest>>(`/admin/access-list/${id}`)
export const updateAccessList = (id: string, payload: AccessListRequest) => i.patch<ResponseAPI<boolean>>(`/admin/access-list/${id}`, payload)
export const deleteAccessList = (id: string) => i.delete<ResponseAPI<boolean>>(`/admin/access-list/${id}`)

export const createSystemList = (payload: SystemListRequest) => i.post<ResponseAPI<boolean>>("/admin/systemlist", payload)
export const getSystemLists = (params: PaginationRequest) => i.get<ResponseAPI<Pagination<SystemListRequest>>>("/admin/systemlist",{params})
export const getSystemList = (id: string) => i.get<ResponseAPI<SystemListRequest>>(`/admin/systemlist/${id}`)
export const updateSystemList = (id: string, payload: SystemListRequest) => i.patch<ResponseAPI<boolean>>(`/admin/systemlist/${id}`, payload)
export const deleteSystemList = (id: string) => i.delete<ResponseAPI<boolean>>(`/admin/systemlist/${id}`)

export const getRequest = (id: string) => i.get<ResponseAPI<GetPublicRequest>>(`/admin/request/${id}`)
export const getRequestByStatusRequest = (status: StatusRequest | string, q?: string, params?: Partial<RequestCountFilter>) => i.get<ResponseAPI<Array<GetRequestConfirmation>>>(`/admin/request`,{params: {status, q, ...params}})
export const updateRequest = (id: string, payload: UpdateRequest) => i.patch<ResponseAPI<boolean>>(`/admin/request/${id}`, payload)
export const updateListRequest = (payload: UpdateListRequest) => i.patch<ResponseAPI<boolean>>('/admin/request/update/status-batch-index', payload)
export const getRequestCount = (params?: Partial<RequestCountFilter>) => i.get<ResponseAPI<Array<RequestCountResponse>>>('/admin/request/request-count',{params})
export const setApproverRequest = (payload: ApproverSetterRequest) => i.post<ResponseAPI<boolean>>("/admin/request/approval-request", payload)
export const resendApproval = (payload: ResendApprovalRequest) => i.post<ResponseAPI<boolean>>("/admin/request/re-send/approval", payload)

export const getHistoryRequest = (params: HistoryRequestParams) => i.get<ResponseAPI<Pagination<HistoryResponse>>>('history', {params})
export const getHistoryByRequestId = (id: string) => i.get<ResponseAPI<HistoryResponse>>(`history/${id}`)

export const getGeneralReports = (params: GeneralReportParams) => i.get<ResponseAPI<Pagination<GeneralReportResponse>>>('/admin/general-reports', {params})
export const getAccessListReports = (params: GeneralReportParams) => i.get<ResponseAPI<Pagination<AccessListReportResponse>>>('/admin/accesslists-reports', {params})

export const publicGetEmployees = (q?: string) => unauthorizedI.get<ResponseAPI<Array<Employee>>>("/employee",{params: {q}})
export const publicGetCategories = (q?: string) => unauthorizedI.get<ResponseAPI<Array<CategoryRequest>>>("/categories",{params: {q}})
export const publicGetAccessList = (q?: string) => unauthorizedI.get<ResponseAPI<Array<AccessListRequest>>>("/access-list",{params: {q}})
export const publicGetSystemList = (q?: string) => unauthorizedI.get<ResponseAPI<Array<SystemListRequest>>>("/systemlist",{params: {q}})
export const publicCreateRequest = (payload: CreateRequest) => unauthorizedI.post<ResponseAPI<boolean>>("/request",payload)
export const publicCreateSystemList = (payload: SystemListRequest) => unauthorizedI.post<ResponseAPI<SystemListRequest>>("/systemlist",payload)
export const publicGetRequestConfirmation = (unique_code: string) => unauthorizedI.get<ResponseAPI<GetRequestConfirmation>>(`/request/confirmation/${unique_code}`)
export const publicUpdateRequestConfirmation = (unique_code: string, payload: UpdateRequestConfirmation) => unauthorizedI.patch<ResponseAPI<boolean>>(`/request/confirmation/${unique_code}/status`, payload)
export const publicGetRequestApproval = (unique_code: string) => unauthorizedI.get<ResponseAPI<RequestApprovalResponse>>(`/request/approval/${unique_code}`)
export const publicUpdateRequestApproval = (unique_code: string, payload: UpdateRequestConfirmation) => unauthorizedI.patch<ResponseAPI<boolean>>(`/request/approval/${unique_code}/status`, payload)
export const publicGetReasons = (q?: string, type?: Reason) => unauthorizedI.get<ResponseAPI<Array<ReasonRequest>>>('/reason',{params: {q, type}})
export const publicGetFaqs = (params: PaginationRequest) => unauthorizedI.get<ResponseAPI<Pagination<FAQRequest>>>("/faq",{params})
export const publicGetTicketsRequest = (payload: TrackingTicketRequest) => unauthorizedI.post<ResponseAPI<Array<TrackingTicketResponse>>>('request/tiket', payload)