import { removeSelectedSubmenu } from '../../app/router/RouterHelpers';

// let rule = localStorage.getItem('rule').split(',');s
let items = [];

const OTC_PAGES = {
  title: 'OTC Finance',
  root: true,
  bullet: 'dot',
  icon: 'far fa-bell',
  roles: ['superuser', 'finance', 'sales', 'data'],
  submenu: [
    {
      title: 'Customers',
      page: 'otc-finance/customers',
      roles: ['superuser', 'finance', 'sales'],
    },
    {
      title: 'Deposit Request',
      page: 'otc-finance/deposit-request',
      roles: ['superuser', 'finance'],
    },
    {
      title: 'Transaction History',
      page: 'otc-finance/transaction-history',
      roles: ['superuser', 'finance', 'sales', 'data'],
    },
    {
      title: 'Bank Transfer',
      page: 'otc-finance/bank-transfer',
      roles: ['superuser', 'finance'],
    },
    // {
    //   title: "Sales Referral Code",
    //   page: "otc-finance/sales-referral-code"
    // },
    {
      title: 'Configuration',
      page: 'otc-finance/configuration',
      roles: ['superuser', 'finance'],
    },
  ],
};

items = [
  {
    title: 'Dashboard',
    root: true,
    icon: 'fas fa-home',
    page: 'dashboard',
    translate: 'MENU.DASHBOARD',
    bullet: 'dot',
    roles: [
      'superuser',
      'admin',
      'compliance',
      'cs',
      'marketing',
      'community',
      'finance',
      'sales',
      'dashboard_view',
      'uid_converter',
      'data',
      'tokoscholar',
      'kyc',
    ],
  },
  {
    title: 'User',
    root: true,
    icon: 'flaticon-customer',
    // page: 'user',
    bullet: 'dot',
    roles: ['superuser', 'cs', 'compliance', 'uid_converter', 'kyc'],
    submenu: [
      {
        title: 'KYC Dashboard',
        page: 'user/kyc-dashboard',
        roles: ['superuser', 'kyc'],
      },
      {
        title: 'Tokocrypto User',
        page: 'user',
        roles: ['superuser', 'kyc', 'compliance', 'cs'],
      },
      {
        title: 'UID Converter',
        page: 'user/uid-converter',
        roles: ['superuser', 'uid_converter', 'data'],
      },
      {
        title: 'Refinitiv',
        page: 'user/refinitiv',
        roles: ['superuser', 'compliance', 'kyc']
      },
      {
        title: 'Reset Request',
        page: 'user/reset-request',
        roles: ['superuser','cs']
      }
    ],
  },
  {
    title: 'TokoScholar',
    root: true,
    icon: 'fas fa-graduation-cap',
    // page: 'user',
    bullet: 'dot',
    roles: ['superuser', 'tokoscholar'],
    submenu: [
      {
        title: 'Courses',
        page: 'tokoscholar/courses',
        roles: ['superuser', 'tokoscholar'],
      },
      {
        title: 'Topics',
        page: 'tokoscholar/topics',
        roles: ['superuser', 'tokoscholar'],
      },
      {
        title: 'Contact Us',
        page: 'tokoscholar/contactus',
        roles: ['superuser', 'tokoscholar'],
      },
      {
        title: 'Users',
        page: 'tokoscholar/users',
        roles: ['superuser', 'tokoscholar'],
      },
      {
        title: 'Users Courses',
        page: 'tokoscholar/user-courses',
        roles: ['superuser', 'tokoscholar'],
      },
    ],
  },
  {
    title: 'Costumer Data Update',
    root: true,
    bullet: 'dot',
    icon: 'fas fa-sync-alt',
    roles: ['superuser', 'compliance'],
    submenu: [
      {
        title: 'Create Data Submission',
        page: 'data-submission/create',
        roles: ['superuser', 'compliance'],
      },
      {
        title: 'Data Submission List',
        page: 'data-submission/list',
        roles: ['superuser', 'compliance'],
      },
    ],
  },
  {
    title: 'Admin',
    root: true,
    icon: 'flaticon-users',
    page: 'admin',
    bullet: 'dot',
    roles: ['superuser', 'admin'],
  },
  {
    title: 'BCA Reconsiliation',
    root: true,
    icon: 'fas fa-money-check',
    page: 'bca-reconsiliation',
    bullet: 'dot',
    roles: ['superuser', 'finance'],
  },
  {
    title: 'Campaigns',
    root: true,
    icon: 'flaticon-line-graph',
    page: 'leaderboard',
    bullet: 'dot',
    roles: ['superuser', 'marketing'],
  },
  // {
  //   title: 'NFT Waiting List',
  //   root: true,
  //   icon: 'far fa-address-card',
  //   page: 'nft-waiting-list',
  //   bullet: 'dot',
  //   roles: ['superuser', 'marketing'],
  // },
  {
    title: 'Community Tipping',
    root: true,
    icon: 'fas fa-users',
    page: 'community-tipping',
    bullet: 'dot',
    roles: ['superuser', 'community'],
  },
  {
    title: 'BTC Microsite',
    root: true,
    bullet: 'dot',
    icon: 'fab fa-bitcoin',
    roles: ['superuser', 'sales'],
    submenu: [
      {
        title: 'Static Content',
        page: 'btc-microsite/static-content',
        roles: ['superuser', 'sales'],
      },
      {
        title: 'Comparation',
        page: 'btc-microsite/comparation',
        roles: ['superuser', 'sales'],
      },
      {
        title: 'Morning Insight',
        page: 'btc-microsite/morning-insight',
        roles: ['superuser', 'sales'],
      },
      {
        title: 'Article',
        page: 'btc-microsite/article',
        roles: ['superuser', 'sales'],
      },
    ],
  },
  {
    title: 'Price Alert',
    root: true,
    icon: 'far fa-bell',
    page: 'price-alert',
    bullet: 'dot',
    roles: ['superuser', 'community'],
  },
  {
    title: 'Launcher Website',
    root: true,
    icon: 'fas fa-external-link-alt',
    page: 'launcher',
    bullet: 'dot',
    roles: ['superuser', 'marketing', 'sales'],
  },
  {
    title: 'TKO Website',
    root: true,
    bullet: 'dot',
    icon: 'fas fa-globe',
    roles: ['superuser', 'marketing'],
    submenu: [
      {
        title: 'Mini Banner',
        page: 'tko-website/mini-banner',
        roles: ['superuser', 'marketing'],
      },
      {
        title: 'Article',
        page: 'tko-website/article',
        roles: ['superuser', 'marketing'],
      },
      // {
      //   title: "Newsletter",
      //   page: "tko-website/newsletter"
      // },
    ],
  },
  {
    title: 'T-Hub',
    root: true,
    icon: 'flaticon-customer',
    // page: 'user',
    bullet: 'dot',
    roles: ['superuser', 'thub', 'superadmin'],
    submenu: [
      {
        title: 'User List',
        page: 'thub/user-list',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      {
        title: 'Location',
        page: 'thub/location',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      {
        title: 'Booking Type',
        page: 'thub/booking-type',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      {
        title: 'Booking Order',
        page: 'thub/booking-order',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      {
        title: 'Events',
        page: 'thub/events',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      {
        title: 'Event Tags',
        page: 'thub/event/tags/list',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      {
        title: 'Speakers',
        page: 'thub/collaboration/list',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      {
        title: 'Configuration',
        page: 'thub/configuration/list',
        roles: ['superuser', 'thub', 'superadmin'],
      },
      // {
      //   title: 'UID Converter',
      //   page: 'user/uid-converter',
      // },
    ],
  },
  {
    title: 'Tax',
    root: true,
    bullet: 'dot',
    icon: 'fas fa-coins',
    roles: ['superuser','finance','tax'],
    submenu: [
      {
        title: 'Market Maker',
        page: 'tax/market-maker/list',
        roles: ['superuser','finance'],
      },
      {
        title: 'Tradeable Document',
        page: 'tax/djp-document',
        roles: ['superuser','finance','tax'],
      },
      {
        title: 'Non Tradeable Document',
        page: 'tax/non-tradeable-djp-document',
        roles: ['superuser','finance','tax'],
      },
      {
        title: 'Rates',
        page: 'tax/rates',
        roles: ['superuser','finance'],
      },
    ],
  },
  OTC_PAGES,
  {
    title: 'Help Desk',
    root: true,
    bullet: 'dot',
    icon: 'fas fa-bell',
    roles: ['superuser','helpdesk'],
    submenu: [
      {
        title: 'Dashboard',
        page: 'helpdesk/home',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'Employees',
        page: 'helpdesk/employees',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'FAQs',
        page: 'helpdesk/faqs',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'Categories',
        page: 'helpdesk/categories',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'Reasons',
        page: 'helpdesk/reasons',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'Access List',
        page: 'helpdesk/access-list',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'General Report',
        page: 'helpdesk/general-report',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'Access List Report',
        page: 'helpdesk/access-list-report',
        roles: ['superuser','helpdesk'],
      },
      {
        title: 'System List',
        page: 'helpdesk/system-list',
        roles: ['superuser','helpdesk'],
      },
    ]
  },
  {
    title: "Compliance",
    root: true,
    bullet: "dots",
    icon: 'fas fa-bell',
    roles: ['superuser', 'compliance'],
    submenu: [
      {
        title: 'Terrorist Database',
        page: 'compliance/terrorist',
        roles: ['superuser', 'compliance'],
      },
      {
        title: 'SIPENDAR Database',
        page: 'compliance/sipendar',
        roles: ['superuser', 'compliance'],
      },
      {
        title: 'Report',
        page: 'compliance/report',
        roles: ['superuser', 'compliance'],
      },
      {
        title: 'FTP Bursa',
        page: 'compliance/bursa',
        roles: ['superuser', 'compliance'],
      },
    ]
  }
  // {
  //   title: 'KYC Users',
  //   root: true,
  //   icon: 'flaticon-customer',
  //   page: 'middleware-kyc-user',
  //   bullet: 'dot',
  //   roles: ['superuser', 'superadmin'],
  // },
  // {
  //   title: 'Middleware Payments',
  //   root: true,
  //   icon: 'fas fa-money-check',
  //   bullet: 'dot',
  //   roles: ['superuser', 'superadmin'],
  //   submenu: [
  //     {
  //       title: 'Dashboard',
  //       page: 'middleware/dashboard',
  //       roles: ['superuser', 'superadmin'],
  //     },
  //     {
  //       title: 'Transactions',
  //       page: 'middleware/transactions',
  //       roles: ['superuser', 'superadmin'],
  //     },
  //     {
  //       title: 'Settings',
  //       page: 'middleware/settings',
  //       roles: ['superuser', 'superadmin'],
  //     },
  //   ],
  // },
];

export default {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: items,
  },
};
