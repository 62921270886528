import { PaginationRequest } from "./global-types";

export enum RefinitivStatus {
  BANNED = '0',
  PASSED = '1',
  NOT_CHECK = '5',
}

export interface GetSummaryParams extends PaginationRequest {
  case?: number;
  uid?: string;
  status?: RefinitivStatus;
  /** Example : 2023-12-19 */
  start_date?: string;
  /** Example : 2023-12-19 */
  end_date?: string;
}

export interface GetSummaryHistoryParams extends PaginationRequest {
  uid: string;
}

export interface UpdatedByRefinitiv {
  id: string;
  email: string;
}

export interface ScreeningDetail {
  /** string json */
  aml_response?: string;
  case: number;
  caseId: string;
  caseSystemId: string;
  created_at: string;
  dob: string;
  id: number;
  name: string;
  nationality: string;
  pob: string;
  status: RefinitivStatus;
  uid: string;
  updated_at: string;
  updated_admin_at?: string;
  updated_by?: UpdatedByRefinitiv;
}

export interface GetSummaryResponse {
  created_at: string;
  id: number;
  screening_detail: ScreeningDetail;
  status: RefinitivStatus;
  uid: string;
  updated_at: string;
  updated_admin_at?: string;
  updated_by?: UpdatedByRefinitiv;
}

export interface UpdateStatusRequest {
  status: RefinitivStatus;
}

export interface GenerateRefinitivRequest {
  start_date?: string;
  end_date?: string;
}

export interface RefinitivResultReview {
  reviewRequired: boolean;
  reviewRequiredDate: string;
  reviewRemark?: string | null;
  reviewDate?: string | null;
}

export interface RefinitivAmlResponseCountry {
  code: string;
  name: string;
}

export interface RefinitivAmlResponseCountryLink {
  country: RefinitivAmlResponseCountry;
  countryText: string;
  type: string;
}

export interface RefinitivAmlResponseSecondaryFieldContent {
  typeId: string;
  value?: string;
  dateTimeValue: string;
}

export interface RefinitivAmlResponseSecondaryField {
  field: RefinitivAmlResponseSecondaryFieldContent;
  typeId: string;
  submittedValue?: string;
  submittedDateTimeValue: string;
  matchedValue?: string;
  matchedDateTimeValue: string;
  fieldResult: string;
}

export interface RefinitivAmlResponseResult {
  resultId: string;
  referenceId: string;
  matchScore: number;
  matchStrength: string;
  matchedTerm: string;
  submittedTerm: string;
  matchedNameType: string;
  sources: Array<string>;
  categories: Array<string>;
  creationDate: string;
  modificationDate: string;
  primaryName: string;
  category: string;
  providerType: string;
  gender: string;
  entityCreationDate: string;
  entityModificationDate: string;
  resultReview: RefinitivResultReview;
  countryLinks: Array<RefinitivAmlResponseCountryLink>;
  secondaryFieldResults: Array<RefinitivAmlResponseSecondaryField>;
}

export interface RefinitivAmlResponse {
  results: Array<Partial<RefinitivAmlResponseResult>>;
}