import instance from './tokoscholarInstace';

const getCourses = (token, query) => {
  return instance.get('/admin/courses', {
    headers: {
      Authorization: token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    },
    params: {
      q: query?.q || undefined,
      limit: query?.limit || undefined,
      page: query?.page || undefined,
      status: query?.status
        ? query?.status === ''
          ? undefined
          : query.status
        : undefined,
    },
  });
};

const fetchCourses = (token, query) => {
  return new Promise((resolve, reject) => {
    getCourses(token, query)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getCourseDetail = (token, params) => {
  return instance.get('/admin/courses/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchCourseDetail = (token, params) => {
  return new Promise((resolve, reject) => {
    getCourseDetail(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const addCourse = (token, body) => {
  return instance.post('/admin/courses/create', body, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchAddCourse = (token, body) => {
  return new Promise((resolve, reject) => {
    addCourse(token, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editCourse = (token, params, body) => {
  return instance.patch('/admin/courses/' + params, body, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetcheditCourse = (token, params, body) => {
  return new Promise((resolve, reject) => {
    editCourse(token, params, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteCourse = (token, params) => {
  return instance.delete('/admin/courses/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchDeleteCourse = (token, params) => {
  return new Promise((resolve, reject) => {
    deleteCourse(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  fetchCourses: fetchCourses,
  fetchCourseDetail: fetchCourseDetail,
  fetchAddCourse: fetchAddCourse,
  fetchDeleteCourse: fetchDeleteCourse,
  fetcheditCourse: fetcheditCourse,
};
