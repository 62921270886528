import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import thubActions from '../../../crud/thub';
import { Button, Table, Modal, Tab, Tabs } from 'react-bootstrap';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
import clsx from 'clsx';
require('bootstrap/scss/bootstrap.scss');
require('./configuration.scss');

const history = createBrowserHistory();
const ThubConfigurationList = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const [stringToken, setStringToken] = useState();
  const [configurationListData, setConfigurationListData] = useState({
    data: [],
    currentPage: 0,
    totalData: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [filterData, setFilterData] = useState({
    limitData: 10,
  });
  const [configurationDetail, setConfigurationDetail] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [configurationData, setConfigurationData] = useState({
    id: '',
    name: '',
  });

  const getConfigurationList = async (page) => {
    if (stringToken) {
      try {
        setLoading(true);
        const params = `?page=${page || 1}&limit=${filterData.limitData}`;
        const response = await thubActions.fetchThubConfigurationList(
          stringToken,
          params
        );
        const { data } = response.data;

        setConfigurationListData((prev) => ({
          ...prev,
          data: data.configurations,
          currentPage: data.currentPage,
          totalData: data.totalData,
          totalDataOnPage: data.totalDataOnPage,
          totalPages: data.totalPages,
        }));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setConfigurationListData([]);
        if (error.response) {
          if (
            error.response.data.code === 401 ||
            error.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${error.response.data.code} message: ${error.response.data.message}`
            );
          }
        }
      }
    }
  };

  const getConfigurationDetail = async (configId) => {
    try {
      setLoadingDetailData(true);
      const response = await thubActions.fetchThubConfigurationDetail(
        stringToken,
        configId
      );
      const data = {
        ...response.data.data,
        open_time: formatTime(response.data.data.open_time),
        close_time: formatTime(response.data.data.close_time),
      };
      setConfigurationDetail(data);
      setLoadingDetailData(false);
    } catch (e) {
      setLoadingDetailData(false);
      setConfigurationDetail([]);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const handleDeleteConfiguration = async () => {
    try {
      setLoading(true);
      await thubActions.fetchThubConfigurationDelete(
        stringToken,
        configurationData.id
      );

      setShowDeleteModal(!showDeleteModal);
      setConfigurationData({ id: '', name: '' });
      toast.success('Delete configuration type success');

      setTimeout(() => {
        getConfigurationList();
      }, 1000);
    } catch (e) {
      setLoading(false);
      setConfigurationData({ id: '', name: '' });
      setShowDeleteModal(!showDeleteModal);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const handleChangeFilter = (name, value) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePageChange = (page) => {
    setConfigurationListData((prev) => ({
      ...prev,
      currentPage: page,
    }));
    getConfigurationList(page);
  };

  const getDateTime = (date) => {
    const fmtDate = moment(date).format('DD-MM-YYYY - HH:mm:ss');

    return fmtDate;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getConfigurationList();
    }
  }, [stringToken, filterData]);

  const formatTime = (time) => {
    if (!time) return '';
    return moment('2022-02-02 ' + time)
      .add(moment().utcOffset(), 'minute')
      .format('HH:mm');
  };

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='paddupgrade'>
              <div className='configuration-menu__top'>
                <Link to='/thub/configuration/create' className='kt-link'>
                  <Button variant='success' style={{ fontSize: '11px' }}>
                    Create New Configuration
                  </Button>
                </Link>
                <select
                  className='form-control configuration-limit'
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                  name='limitData'
                >
                  <option value={5}>5</option>
                  <option selected value={10}>
                    10
                  </option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet kt-portlet-top'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section' style={{ marginBottom: 0 }}>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                            width: '7%',
                          }}
                        >
                          No &nbsp;
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Config Name &nbsp;
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Config Type &nbsp;
                          <br />
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Open - Close Time &nbsp;
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Max Day Ahead &nbsp;
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Max Hour Booked &nbsp;
                        </th>
                        <th
                          className='tableheh'
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          Max Day Booked &nbsp;
                        </th>
                        <th className=' tableheh'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {configurationListData.data &&
                        configurationListData.data.length === 0 && (
                          <tr style={{ textAlign: 'center' }}>
                            <td colSpan={9}>No records data found</td>
                          </tr>
                        )}
                      {configurationListData.data &&
                        configurationListData.data.length > 0 &&
                        configurationListData.data.map((row, i) => (
                          <tr key={row._id}>
                            <td>{i + 1}</td>
                            <td>{row.config_name}</td>
                            <td>{row.config_type}</td>
                            <td>{`${
                              (row.open_time && formatTime(row.open_time)) || ''
                            } - ${
                              (row.close_time && formatTime(row.close_time)) ||
                              ''
                            }`}</td>
                            <td>{row.max_day_ahead || '-'}</td>
                            <td>{row.max_hour_booked || '-'}</td>
                            <td>{row.max_day_booked || '-'}</td>
                            <td className='center'>
                              <div>
                                <span
                                  onClick={() => {
                                    getConfigurationDetail(row.rate_type_id);
                                    setShowDetailModal(!showDetailModal);
                                  }}
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className='btn btn-outline-primary'
                                >
                                  View
                                </span>
                                <br />
                                <Link
                                  to={`/thub/configuration/${row.rate_type_id}/edit`}
                                >
                                  <span
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className='btn btn-outline-success'
                                  >
                                    Edit
                                  </span>
                                </Link>
                                <br />
                                <span
                                  onClick={() => {
                                    setConfigurationData({
                                      name: row.config_name,
                                      id: row.rate_type_id,
                                    });
                                    setShowDeleteModal(!showDeleteModal);
                                  }}
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className='btn btn-outline-danger'
                                >
                                  Delete
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <div className='d-flex justify-content-center align-items-center pagination-data'>
                    <Pagination
                      activePage={configurationListData.currentPage}
                      itemsCountPerPage={configurationListData.totalDataOnPage}
                      itemClass='page-item'
                      linkClass='page-link'
                      totalItemsCount={configurationListData.totalData}
                      pageRangeDisplayed={
                        configurationListData.totalDataOnPage >=
                        filterData.limitData
                          ? configurationListData.totalPages
                          : 1
                      }
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>{configurationDetail.config_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingDetailData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type={'bars'}
                color={'#0000FF'}
                height={'2%'}
                width={'5%'}
              />
            </div>
          ) : (
            <>
              {configurationDetail &&
                typeof configurationDetail.rate_type_id !== 'undefined' && (
                  <Tabs
                    id='controlled-tab-example'
                    defaultActiveKey='home'
                    // onSelect={key => this.setState({ key })}
                  >
                    <Tab eventKey='home' title='Home'>
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Config ID</strong>
                            </td>
                            <td colSpan={5}>
                              {configurationDetail.rate_type_id}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Config Name</strong>
                            </td>
                            <td colSpan={5}>
                              {configurationDetail.config_name}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Config Type</strong>
                            </td>
                            <td colSpan='5'>
                              {configurationDetail.config_type}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Open - Close Time</strong>
                            </td>
                            <td colSpan='5'>
                              {`${configurationDetail.open_time || ''} - ${
                                configurationDetail?.close_time || ''
                              }`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Created At</strong>
                            </td>
                            <td colSpan='5'>
                              {getDateTime(configurationDetail.created_at)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Updated At</strong>
                            </td>
                            <td colSpan='5'>
                              {getDateTime(configurationDetail.updated_at)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Max Day Ahead</strong>
                            </td>
                            <td colSpan='5'>
                              {configurationDetail.max_day_ahead}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {configurationDetail.config_type === 'hourly'
                                  ? 'Max Hour Booked'
                                  : 'Max Day Booked'}
                              </strong>
                            </td>
                            <td colSpan={5}>
                              {configurationDetail.config_type === 'hourly'
                                ? configurationDetail.max_hour_booked
                                : configurationDetail.max_day_booked}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Open Days</strong>
                            </td>
                            <td colSpan='5'>
                              {configurationDetail.open_days.map((day) => (
                                <p
                                  key={day}
                                  style={{
                                    marginBottom: 5,
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {day}
                                </p>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email Admins</strong>
                            </td>
                            <td colSpan={5}>
                              {configurationDetail.email_admins.map((email) => (
                                <p key={email} style={{ marginBottom: 5 }}>
                                  {email}
                                </p>
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(!showDeleteModal)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete {configurationData.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant='primary'
            onClick={() => setShowDeleteModal(!showDeleteModal)}
            className='mr-3'
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            disabled={loading}
            onClick={() => handleDeleteConfiguration()}
            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                  loading,
              }
            )}`}
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThubConfigurationList;
