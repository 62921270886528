import { FC, ReactNode, useReducer, useState } from "react";
import { HelpdeskWrapper } from ".";
import { Pagination } from "../../../crud/global-types";
import { publicGetFaqs } from "../../../crud/helpdesk";
import { FAQRequest } from "../../../crud/helpdesk-types";
import { useDebounce } from "../../../helper"
import ReactPagination from "react-js-pagination";
import FormWithFormik from "../../../components/reusableFormWithFormik";
import { useFormik } from "formik";

interface InitialState extends Pagination<FAQRequest> {
  
}

const initialState: InitialState = {
  data: [],
  total_data: 0,
  total_page: 0,
  limit: 15,
  page: 1
}

interface IForm {
  q: string;
}

const UnauthHelpdeskFAQ: FC = () => {
  const formik = useFormik<IForm>({
    initialValues: {
      q: ""
    },
    onSubmit: () => {

    }
  })
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s,...ns}), initialState)
  
  useDebounce<[number, string]>((page, q)=>{
    publicGetFaqs({
      limit: state.limit,
      page,
      q
    })
      .then((res) => {
        dispatch({data: res.data.data?.data})
      })
  }, [state.page, formik.values.q], 400)
  
  return (
    <HelpdeskWrapper
      bannerExtra="F.A.Q"
      bannerStyle={{
        height:  165
      }}
      className="helpdesk-faq"
    >
      <FormWithFormik 
        type="TEXT_INPUT"
        name="q"
        placeholder="Search FAQ by keyword"
        config={formik}
      />
      {state.data.map((faq,i) =>
        <FAQItem 
          key={i}
          q={faq.question}
          a={faq.answer}
        />
      )}
      <ReactPagination
        activePage={state.page}
        itemsCountPerPage={state.limit}
        itemClass="page-item"
        linkClass="page-link"
        totalItemsCount={state.total_data}
        onChange={(page) => dispatch({ page })}
      />
    </HelpdeskWrapper>
  )
}

interface FAQItemProps {
  q: ReactNode;
  a: ReactNode;
}

const FAQItem: FC<FAQItemProps> = (props) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  return (
    <div>
      <div className={`helpdesk-question ${isActive ? 'helpdesk-question-active' : 'helpdesk-question-inactive'}`} onClick={()=>setIsActive(!isActive)}>
        <div>{props.q}</div>
        <svg className="helpdesk-question__icon" style={{transform: isActive ? 'rotate(-180deg)' : undefined}} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.8" d="M1.24854 1L5.99475 6L10.741 1" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
      {isActive && <div className="helpdesk-answer">{props.a}</div>}
    </div>
  )
}

export default UnauthHelpdeskFAQ