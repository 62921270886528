import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Pagination from 'react-js-pagination';
import userInstance from '../../../crud/tokoscholarUsers';
import './_tokoscholarusers.scss';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';

const UsersPage = (props) => {
  const [searchKeyword, setsearchKeyword] = useState();
  const [loading, setloading] = useState(false);
  const [userData, setuserData] = useState(null);
  const history = useHistory();
  const [currentPage, setcurrentPage] = useState(1);

  const getToken = () => localStorage.getItem('token');

  const handleGetUserData = async () => {
    try {
      const res = await userInstance.fetchUsersData(getToken());
      setuserData(res.data.data);
      console.log(res, 'res');
    } catch (error) {
      console.log(error, 'Error');
    }
  };

  const handleChangePage = (page) => {};

  useEffect(() => {
    handleGetUserData();
  }, []);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='d-flex justify-content-between flex-wrap  align-content-center'>
              <input
                value={searchKeyword}
                className='form-control'
                style={{ width: '88%' }}
                onChange={(e) => setsearchKeyword(e.target.value)}
                placeholder='Search users'
                type='text'
              />
              <button
                style={{ width: '10%' }}
                className='btn btn-outline-warning'
                type='button'
                // onClick={handleSearchTopic}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'></div>
          </div>
        </div>
      </div>

      <div className='tokoscholar-users-data'>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading
              type={'bars'}
              color={'#0000FF'}
              height={'2%'}
              width={'5%'}
            />
          </div>
        ) : (
          <>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ReactLoading
                  type={'bars'}
                  color={'#0000FF'}
                  height={'2%'}
                  width={'5%'}
                />
              </div>
            ) : (
              <div className='tokoscholar-users-data__container'>
                {userData &&
                  userData.data &&
                  userData.data.map((user, index) => (
                    <div key={index} className='tokoscholar-users-data__item'>
                      <div className='tokoscholar-users-data__card'>
                        <div className='tokoscholar-users-data__card__head'>
                          <div className='tokoscholar-users-data__card__head__image'>
                            {user.profile_image ? (
                              <img
                                src={user.profile_image}
                                alt='user profile'
                              />
                            ) : (
                              <i>
                                <svg
                                  // width='67'
                                  // height='67'
                                  viewBox='0 0 67 67'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M33.5 16.75C31.4295 16.75 29.4055 17.364 27.6839 18.5143C25.9623 19.6646 24.6205 21.2996 23.8281 23.2125C23.0358 25.1254 22.8285 27.2304 23.2324 29.2611C23.6363 31.2918 24.6334 33.1572 26.0975 34.6213C27.5616 36.0854 29.4269 37.0824 31.4577 37.4863C33.4884 37.8903 35.5933 37.683 37.5062 36.8906C39.4191 36.0983 41.0541 34.7565 42.2045 33.0349C43.3548 31.3133 43.9688 29.2893 43.9688 27.2188C43.9688 24.4423 42.8658 21.7795 40.9025 19.8162C38.9393 17.853 36.2765 16.75 33.5 16.75ZM33.5 33.5C32.2577 33.5 31.0433 33.1316 30.0103 32.4414C28.9774 31.7512 28.1723 30.7702 27.6969 29.6225C27.2215 28.4747 27.0971 27.2118 27.3394 25.9933C27.5818 24.7749 28.18 23.6557 29.0585 22.7772C29.9369 21.8988 31.0562 21.3006 32.2746 21.0582C33.493 20.8158 34.756 20.9402 35.9037 21.4156C37.0515 21.891 38.0325 22.6961 38.7227 23.7291C39.4129 24.762 39.7813 25.9764 39.7813 27.2188C39.7796 28.8841 39.1173 30.4808 37.9397 31.6584C36.7621 32.836 35.1654 33.4983 33.5 33.5Z'
                                    fill='#AEAEAE'
                                  />
                                  <path
                                    d='M33.5 4.1875C27.7025 4.1875 22.0353 5.90665 17.2149 9.12755C12.3944 12.3484 8.63739 16.9264 6.41879 22.2826C4.2002 27.6388 3.61972 33.5325 4.75074 39.2186C5.88177 44.9047 8.67352 50.1276 12.7729 54.2271C16.8724 58.3265 22.0954 61.1182 27.7814 62.2493C33.4675 63.3803 39.3613 62.7998 44.7174 60.5812C50.0736 58.3626 54.6516 54.6056 57.8725 49.7852C61.0934 44.9647 62.8125 39.2975 62.8125 33.5C62.8036 25.7286 59.7125 18.278 54.2173 12.7827C48.722 7.28749 41.2715 4.19637 33.5 4.1875ZM20.9375 55.2268V52.3438C20.9392 50.6784 21.6015 49.0817 22.7791 47.9041C23.9567 46.7265 25.5534 46.0642 27.2188 46.0625H39.7813C41.4466 46.0642 43.0433 46.7265 44.2209 47.9041C45.3985 49.0817 46.0608 50.6784 46.0625 52.3438V55.2268C42.2501 57.4529 37.9147 58.626 33.5 58.626C29.0853 58.626 24.7499 57.4529 20.9375 55.2268ZM50.2333 52.1888C50.1915 49.4425 49.0726 46.8225 47.1176 44.8933C45.1625 42.9641 42.5279 41.8802 39.7813 41.875H27.2188C24.4721 41.8802 21.8375 42.9641 19.8824 44.8933C17.9274 46.8225 16.8085 49.4425 16.7668 52.1888C12.9699 48.7985 10.2923 44.3349 9.08863 39.389C7.88495 34.4431 8.21191 29.2483 10.0262 24.4924C11.8405 19.7365 15.0566 15.6438 19.2486 12.7563C23.4406 9.86882 28.4108 8.32271 33.5011 8.32271C38.5913 8.32271 43.5615 9.86882 47.7535 12.7563C51.9455 15.6438 55.1616 19.7365 56.9759 24.4924C58.7902 29.2483 59.1172 34.4431 57.9135 39.389C56.7098 44.3349 54.0322 48.7985 50.2354 52.1888H50.2333Z'
                                    fill='#AEAEAE'
                                  />
                                </svg>
                              </i>
                            )}
                          </div>
                          <div className='tokoscholar-users-data__card__head__identity'>
                            <p className='tokoscholar-users-data__card__head__identity__name'>
                              {user.fullname}
                            </p>
                            <p className='tokoscholar-users-data__card__head__identity__email'>
                              {user.email}
                            </p>
                            <p className='tokoscholar-users-data__card__head__identity__phone'>
                              {user.tokocrypto_uid}
                            </p>
                          </div>
                          <div className='tokoscholar-users-data__card__head__identity__dropdown'>
                            <Dropdown>
                              <Dropdown.Toggle
                                className='tokoscholar-users-data__card__head__icon'
                                as='span'
                              >
                                <div className='tokoscholar-users-data__card__head__icon'>
                                  <FontAwesomeIcon
                                    icon={faEllipsisV}
                                    color='#838383'
                                  />
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    history.push(
                                      '/tokoscholar/users/view/' + user.id
                                    )
                                  }
                                >
                                  View Detail
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    history.push(
                                      '/tokoscholar/users/edit/' + user.id
                                    )
                                  }
                                >
                                  Edit Users
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className='tokoscholar-users-data__card__body'>
                          <div className='tokoscholar-users-data__card__body__item'>
                            <p className='tokoscholar-users-data__card__body__item__left'>
                              Created Date
                            </p>
                            <p className='tokoscholar-users-data__card__body__item__right'>
                              {(user.created_at &&
                                moment(user.created_at).format(
                                  'DD/MMM/YYYY HH:mm:ss'
                                )) ||
                                '-'}
                            </p>
                          </div>
                          <div className='tokoscholar-users-data__card__body__item'>
                            <p className='tokoscholar-users-data__card__body__item__left'>
                              Lates update
                            </p>
                            <p className='tokoscholar-users-data__card__body__item__right'>
                              {(user.updated_at &&
                                moment(user.updated_at).format(
                                  'DD/MMM/YYYY HH:mm:ss'
                                )) ||
                                '-'}
                            </p>
                          </div>
                          <div className='tokoscholar-users-data__card__body__item'>
                            <p className='tokoscholar-users-data__card__body__item__left'>
                              Update By
                            </p>
                            <p className='tokoscholar-users-data__card__body__item__right'>
                              {(user.updated_by && user.updated_by) || '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className='d-flex justify-content-center align-items-center pagination-data'>
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={userData?.per_page || 0}
                itemClass='page-item'
                linkClass='page-link'
                totalItemsCount={userData?.total_data || 0}
                pageRangeDisplayed={5}
                onChange={handleChangePage}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UsersPage;
