import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import courseService from '../../../crud/course';

require('bootstrap/scss/bootstrap.scss');

const CoursesPage = (props) => {
  const [courseData, setcourseData] = useState([]);
  const [loading, setloading] = useState(false);
  const [searchKeyword, setsearchKeyword] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [status, setstatus] = useState(null);

  const perPage = 10;

  //   Get Token
  const getToken = () => {
    return localStorage.getItem('token');
  };

  //   Get Course list
  const handleGetCourse = async (q, page, limit, status) => {
    try {
      const res = await courseService.fetchCourses(getToken(), {
        q,
        page,
        limit,
        status,
      });
      setcourseData(res.data.data || []);
    } catch (err) {
      console.log(err, 'Error');
    }
  };

  // handle delete course
  const handleDeleteCourse = (id) => {
    Swal.fire({
      title: `Are you sure to Delete this data ?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await courseService.fetchDeleteCourse(getToken(), id);
          toast.success(res.data.message);
          handleGetCourse(searchKeyword, currentPage, perPage, status);
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    });
  };

  // handle change page
  const handlePageChange = (page) => {
    let activePage = page;
    setcurrentPage(activePage);
    handleGetCourse(searchKeyword, activePage, perPage, status);
  };

  // handleSearchCourse
  const handleSearchCourse = () => {
    setcurrentPage(1);
    handleGetCourse(searchKeyword, 1, perPage, status);
  };

  useEffect(() => {
    handleGetCourse(searchKeyword, currentPage, perPage);
  }, []);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='d-flex justify-content-between flex-wrap  align-content-center'>
              <input
                value={searchKeyword}
                className='form-control'
                style={{ width: '65%' }}
                onChange={(e) => setsearchKeyword(e.target.value)}
                placeholder='Search by course name'
                type='text'
              />

              <select
                className='form-control'
                style={{ width: '10%' }}
                onChange={(e) => setstatus(e.target.value)}
              >
                <option selected value=''>
                  All Status
                </option>
                <option value='1'>Enabled</option>
                <option value='0'>Disabled</option>
              </select>
              <button
                style={{ width: '10%' }}
                class='btn btn-outline-warning'
                type='submit'
                onClick={handleSearchCourse}
              >
                Search
              </button>
              <button
                style={{ width: '10%' }}
                class='btn btn-outline-info'
                type='button'
                onClick={() => props.history.push('/tokoscholar/courses/add')}
              >
                Create Course
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ReactLoading
                        type={'bars'}
                        color={'#0000FF'}
                        height={'2%'}
                        width={'5%'}
                      />
                    </div>
                  ) : (
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className='tableheh'
                            style={{ verticalAlign: 'middle', width: '7%' }}
                          >
                            No &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{ verticalAlign: 'middle' }}
                          >
                            Course Name &nbsp;
                            <button
                              onClick={(e) => this.onSortChange('email')}
                              className='sort-button'
                            >
                              {/* <i
                          className={`fas fa-${
                            sortTypes[this.state.orderSort].class
                          }`}
                        /> */}
                            </button>
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Course Image&nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Difficulty &nbsp;
                            <br />
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Time Allocation &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Sorting &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Total Sessions &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Status &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Action &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseData &&
                        courseData.data &&
                        courseData.data.length > 0 ? (
                          courseData.data.map((row, key) => (
                            <tr key={key}>
                              <td style={{ verticalAlign: 'middle' }}>
                                {key + 1}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.course_name_en}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                <img
                                  width={91}
                                  style={{ borderRadius: 10 }}
                                  src={row.course_image}
                                  alt=''
                                  srcset=''
                                />
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {Number(row.difficulty) === 1
                                  ? 'Beginner'
                                  : Number(row.difficulty) === 2
                                  ? 'Intermediate'
                                  : 'Advance'}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.time_allocation}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.sorting_number}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.topics.length}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.status === '1' ? (
                                  <span className='text-success'>Enabled</span>
                                ) : (
                                  <span className='text-danger'>Disabled</span>
                                )}
                              </td>

                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                <span
                                  className='btn-action mr-1'
                                  onClick={() =>
                                    props.history.push(
                                      '/tokoscholar/courses/' + row.id
                                    )
                                  }
                                >
                                  <i class='far fa-edit'></i>
                                </span>
                                <span
                                  className='btn-action'
                                  onClick={() => handleDeleteCourse(row.id)}
                                >
                                  <i class='far fa-trash-alt'></i>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9} className='text-center text-danger'>
                              No data available in table
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                  <div className='d-flex justify-content-center align-items-center pagination-data'>
                    <Pagination
                      activePage={1}
                      itemsCountPerPage={courseData?.per_page || 0}
                      itemClass='page-item'
                      linkClass='page-link'
                      totalItemsCount={courseData?.total_data || 0}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesPage;
