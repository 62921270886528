import axios from 'axios';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
const history =  createBrowserHistory();

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://service.tcdx.id/'
      : 'https://service-demo.tcdx.id/',
  // baseURL: process.env.REACT_APP_ENV === 'production' ? 'https://service.tcdx.id/' : 'https://service-kyc-demo.tokocrypto.com/'
});
instance.interceptors.request.use((config) => {
  if(config.headers) {
    config.headers.Authorization = localStorage.getItem("token");
    config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
  }
  return config
})

instance.interceptors.response.use(config => config, (err) => {
  if(err.response){
    if(err.response.status === 401){
      toast.error("token has expired");
      localStorage.clear();
      history.push("/auth");
      window.location.reload();
    }
    else{
      toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
    }
  }

  return Promise.reject(err)
})

export default instance;
