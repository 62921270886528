import { FC, useReducer } from "react";
import { Pagination, PaginationRequest } from "../../crud/global-types";
import { AccessListRequest } from "../../crud/helpdesk-types";
import { useDebounce } from "../../helper";
import { getAccessLists } from "../../crud/helpdesk";
import ReusableListPage, { ReusableListFilter, ReusableListSecondFilter } from "../../components/reusableListPage";
import { RouteComponentProps } from "react-router-dom"

interface InitialState extends Pagination<AccessListRequest>, PaginationRequest {
  loading: boolean;
}

const initialState: InitialState = {
  loading: false,
  total_data: 0,
  total_page: 1,
  limit: 15,
  page: 1,
  data: [],
}

const HelpdeskAccessList: FC<RouteComponentProps<{id: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  useDebounce<[string | undefined, number]>(async (q, page)=>{
    try {
      const response = await getAccessLists({
        limit: state.limit,
        page,
        q
      })

      if(response.data.data) dispatch(response.data.data)
    }
    catch(err: unknown) {
      console.log(err)
    }

    dispatch({loading: false})
  },[state.q, state.page], 400, () => dispatch({loading: true}))
  
  const filters: Array<ReusableListFilter> = [
    {
      type: "INPUT_TEXT",
      onChange: (evt) => dispatch({q: evt.target.value}),
      value: state.q,
      placeholder: "Search by keyword",
    },
  ]

  const triggerBtns: Array<ReusableListSecondFilter> = [
    {
      type: "LINK",
      label: "Add New Access List",
      to: "/helpdesk/access-list/create"
    },
  ]

  return (
    <ReusableListPage<AccessListRequest> 
      loading={state.loading}
      filters={filters}
      triggerBtns={triggerBtns}
      dataSources={state.data}
      columns={[
        {
          dataIndex: "name",
          title: "Name"
        },
        {
          dataIndex: "id",
          title: "Action",
          render: (dataIndex?: string) => dataIndex && (
            <div className="custom-flex-row">
              <button 
                type="button"
                className="btn btn-primary"
                onClick={()=>props.history.push(`/helpdesk/access-list/${dataIndex}/update`)}
              >
                Update
              </button>
            </div>
          )
        },
      ]}
      pagination={{
        itemsCountPerPage: state.limit,
        totalItemCount: state.total_data,
        onChange: (page) => dispatch({page}),
        activePage: state.page
      }}
    />
  )
}

export default HelpdeskAccessList