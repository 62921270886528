import React, { useState, useEffect, Fragment } from 'react';
import CKEditor from 'ckeditor4-react';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, ErrorMessage, useFormik, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
// import Select from 'react-select'
import './bookingType.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { facilitiesOption } from './bookingType.constant';
import { ArrowBack } from '@material-ui/icons';
import { toBase64 } from '../../../helper';
require('bootstrap/scss/bootstrap.scss');

const ThubBookingTypeCreate = (props) => {
  const [loading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [rateTypeList, setRateTypeList] = useState([]);
  const [stringToken, setStringToken] = useState();
  // const [isTokenHolder, setIsTokenHolder] = useState(false);

  const addBookingType = async (data) => {
    setLoading(true);

    try {
      if (data.price_type) {
        const new_price_type = await Promise.all(
          data.price_type.map(async (val) => {
            if (val.qr_code) {
              const file = await toBase64(val.qr_code);
              return {
                ...val,
                qr_code: file,
              };
            }
            return val;
          })
        );
        // console.log(new_price_type, 'new_price_type');
        data.price_type = new_price_type;
      }

      const response = await thubActions.fetchThubBookingTypeCreate(
        stringToken,
        data
      );

      if (response.data.code === 200 || response.data.code === 201) {
        toast.success('Add new Booking Type success');
      }
      setLoading(false);

      setTimeout(() => {
        props.history.push('/thub/booking-type');
        // window.location.reload();
      }, 1500);
    } catch (e) {
      setLoading(false);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          props.history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const getLocationList = async () => {
    setLoading(true);

    try {
      const response = await thubActions.fetchThubLocationAll(stringToken);
      const { data } = response.data;
      setLocationList(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setLocationList([]);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          props.history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const getRateTypeList = async () => {
    setLoading(true);
    try {
      const response = await thubActions.fetchThubRateTypeList(stringToken);
      const { data } = response.data;
      setLoading(false);
      setRateTypeList(data);
    } catch (e) {
      setLoading(false);
      setRateTypeList([]);
      if (e.response) {
        if (e.response.data.code === 401 || e.response.data.code === 500) {
          toast.error('token has expired');
          localStorage.clear();
          props.history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      }
    }
  };

  const ensureNumber = (val) => (isFinite(val) ? val : undefined);
  const validationSchema = Yup.object().shape({
    location_id: Yup.string().required('Location is required'),
    rate_type_id: Yup.string().required('Rate type is required'),
    type_name: Yup.string()
      .required('Booking type name is required')
      .max(100, "*Can't be longer than 100 characters"),
    type_desc_id: Yup.string().required(
      'Type description for ID version is required'
    ),
    type_desc_en: Yup.string().required(
      'Type description for EN version is required'
    ),
    facilities: Yup.mixed().required('List of facilities is required'),
    price_type: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string().required('Type is required'),
          price: Yup.number('Must be a number')
            .required('Price is required')
            .positive('Price must be a positive number'),
          qr_code: Yup.mixed().test(
            'qr-code-validation',
            'Qr Code Image must equal or less than 150kb',
            (val, contxt) => {
              return val && val !== '' ? val.size <= 153600 : true;
            }
          ),
          payment_address: Yup.string(),
        })
      )
      .min(1, 'You need at least 1 price type')
      .required(),
    // price: Yup.number().required('Price is required'),
    // approval_status: Yup.string().required(
    //   'Approval Status must be filled'
    // ),
    seats: Yup.array()
      .of(
        Yup.object().shape({
          seat_name: Yup.string()
            .ensure()
            .required('Seat / Room name is required'),
          status: Yup.string().required('Seat / Room status is required'),
        })
      )
      .min(1, 'You need at least 1 seats')
      .required(),
    capacities: Yup.number()
      .required('Capacities must be filled')
      .min(1, 'Minimum 1 person')
      .positive(),
    // is_token_holder: Yup.string().required(
    //   'Token Holder status must be filled'
    // ),
    // image: Yup.mixed().required('Thub images cant be empty'),
    status: Yup.mixed().required('Status must be chosen'),
    // seats: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       seat_name: Yup.string().required('Seats name is required'),
    //       status: Yup.string().required('Status is required'),
    //     })
    //   )
    //   .required('Seats cant be empty'),
  });

  const bookingFormik = useFormik({
    initialValues: {
      location_id: '',
      type_name: '',
      type_desc_id: '',
      type_desc_en: '',
      facilities: '',
      price_type_default: [
        { type: 'special_privilege', price: 0, description: '' },
        { type: 'collab_with_tokocrypto', price: 0, description: '' },
      ],
      price_type: [],
      // price: '',
      approval_status: 1,
      // reject_reason: '',
      rate_type_id: '',
      capacities: '',
      // is_token_holder: '',
      // token_holder_amount: 0,
      image: '',
      status: 1,
      seats: [{ seat_name: '', status: 1 }],
      benefits: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addBookingType(values);
    },
  });

  const handleChangeEditor = (e) => {
    const data = e.editor.getData();
    const name = e.editor.name;

    bookingFormik.setFieldValue(name, data);
  };
  const handleChangeFacilities = (e) => {
    const facilitiesData =
      (Array.isArray(e) &&
        e.reduce(
          (prev, cur) =>
            prev === '' ? (prev = cur.value) : (prev += ', ' + cur.value),
          ''
        )) ||
      '';

    bookingFormik.setFieldValue('facilities', facilitiesData);
  };

  const handleChangeImages = (event) => {
    const { setFieldValue } = bookingFormik;
    let imageData = [];
    for (let i = 0; i < event.target.files.length; i++) {
      imageData = [...imageData, event.target.files[i]];
    }

    setFieldValue('image', imageData);
  };

  const handleAddSeats = () => {
    const _fields = [...bookingFormik.values.seats];
    _fields.push({
      seat_name: '',
      status: 1,
    });
    bookingFormik.setFieldValue('seats', _fields);
  };

  const handleRemoveSeats = (i) => {
    if (bookingFormik.values.seats.length > 1) {
      const _fields = [...bookingFormik.values.seats];
      _fields.splice(i, 1);
      bookingFormik.setFieldValue('seats', _fields);
    }
  };

  // const onChangeSeats = (i, e) => {
  //   const seatsValue = bookingFormik.values.seats;
  //   const _seatsValue = [...seatsValue];
  //   _seatsValue[i][e.target.name] = e.target.value;

  //   bookingFormik.setFieldValue('seats', _seatsValue);
  // };

  const handleAddPrice = () => {
    const _fields = [...bookingFormik.values.price_type];
    _fields.push({
      type: 'cash',
      price: '0',
      description: '',
      benefits: [],
      qr_code: '',
      payment_address: '',
    });
    bookingFormik.setFieldValue('price_type', _fields);
  };

  const handleRemovePrice = (i) => {
    const _fields = [...bookingFormik.values.price_type];
    _fields.splice(i, 1);
    bookingFormik.setFieldValue('price_type', _fields);
  };

  // const onChangePrice = (i, e) => {
  //   const priceValue = bookingFormik.values.price_type;
  //   const _priceValue = [...priceValue];
  //   if (e.target.name === 'price') {
  //     _priceValue[i][e.target.name] = e.target.value;
  //   } else {
  //     _priceValue[i][e.target.name] = e.target.value;
  //   }

  //   bookingFormik.setFieldValue('price_type', _priceValue);
  // };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setStringToken(token);
    if (stringToken && typeof stringToken !== 'undefined') {
      getLocationList();
      getRateTypeList();
    }
  }, [stringToken]);

  const { handleBlur, handleChange, errors, touched, values, setFieldValue } =
    bookingFormik;

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form
                className='m-login__form m-form'
                onSubmit={(e) => {
                  e.preventDefault();
                  bookingFormik.handleSubmit(e);
                }}
              >
                <div className='row'>
                  <div className='col-md-6'>
                    {locationList && locationList.length > 0 ? (
                      <Form.Group>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          as='select'
                          value={values.location_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='location_id'
                          className={`${
                            touched.location_id && errors.location_id && 'error'
                          }`}
                        >
                          <option value='' selected>
                            Select Location
                          </option>
                          {locationList.map((data, i) => (
                            <option value={data.location_id} key={i}>
                              {data.location_name}
                            </option>
                          ))}
                        </Form.Control>
                        {touched.location_id && errors.location_id && (
                          <div className='error-message'>
                            {errors.location_id}
                          </div>
                        )}
                      </Form.Group>
                    ) : (
                      <p>
                        You must create a location before creating a new booking
                        type
                      </p>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Booking Type</Form.Label>
                      <Form.Control
                        as='select'
                        value={values.type_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='type_name'
                        className={
                          touched.type_name && errors.type_name && 'error'
                        }
                      >
                        <option value='' selected disabled>
                          Select Booking Type
                        </option>
                        <option value='Co-Working'>Co-Working</option>
                        <option value='Private'>Private</option>
                        <option value='Multi Function'>Multi Function</option>
                      </Form.Control>
                      {touched.type_name && errors.type_name && (
                        <div className='error-message'>{errors.type_name}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Type Descriptions ID</Form.Label>
                      <br />
                      <CKEditor
                        onChange={(e) => handleChangeEditor(e)}
                        data={values.type_desc_id}
                        name='type_desc_id'
                        config={{
                          toolbar: [
                            'undo',
                            'redo',
                            'fontFamily',
                            'bold',
                            'italic',
                            'blockQuote',
                            'heading',
                            'numberedList',
                            'bulletedList',
                            'insertTable',
                          ],
                          allowedContent: true,
                        }}
                        fontFamily={{
                          options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                          ],
                        }}
                      />
                      {touched.type_desc_id && errors.type_desc_id && (
                        <div className='error-message'>
                          {errors.type_desc_id}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Type Descriptions EN</Form.Label>
                      <br />
                      <CKEditor
                        onChange={(e) => handleChangeEditor(e)}
                        data={values.type_desc_en}
                        name='type_desc_en'
                        config={{
                          toolbar: [
                            'undo',
                            'redo',
                            'fontFamily',
                            'bold',
                            'italic',
                            'blockQuote',
                            'heading',
                            'numberedList',
                            'bulletedList',
                            'insertTable',
                          ],
                        }}
                        fontFamily={{
                          options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                          ],
                        }}
                      />
                      {touched.type_desc_en && errors.type_desc_en && (
                        <div className='error-message'>
                          {errors.type_desc_en}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    <Form.Group>
                      <Form.Label>Select Facilities</Form.Label>
                      <div className='input-group col-md-12 p-0'>
                        <div className='input-group'>
                          <Select
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '100%',
                              }),
                            }}
                            name='facilities'
                            isMulti
                            options={facilitiesOption}
                            classNamePrefix='select'
                            onChange={handleChangeFacilities}
                            onBlur={handleBlur}
                            className={`${
                              touched.facilities && errors.facilities && 'error'
                            } basic-multi-select`}
                          />
                        </div>
                      </div>
                      {touched.facilities && errors.facilities && (
                        <div className='error-message'>{errors.facilities}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    {rateTypeList && rateTypeList.length > 0 ? (
                      <Form.Group>
                        <Form.Label>Rate Type</Form.Label>
                        <Form.Control
                          as='select'
                          value={values.rate_type_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='rate_type_id'
                          className={
                            touched.rate_type_id &&
                            errors.rate_type_id &&
                            'error'
                          }
                        >
                          <option value='' selected>
                            Select Rate Type
                          </option>
                          {rateTypeList.map((data, i) => (
                            <option value={data.rate_type_id} key={i}>
                              {`${data.config_name} - ${data.config_type}`}
                            </option>
                          ))}
                        </Form.Control>
                        {touched.rate_type_id && errors.rate_type_id && (
                          <div className='error-message'>
                            {errors.rate_type_id}
                          </div>
                        )}
                      </Form.Group>
                    ) : (
                      <p>
                        You need to create rate in configuration type first
                        before choosing rate type
                      </p>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Capacities</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter Thub Capacities. Eg: 1 person, 4 people'
                        value={values.capacities}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='capacities'
                        className={
                          touched.capacities && errors.capacities && 'error'
                        }
                      />
                      {touched.capacities && errors.capacities && (
                        <div className='error-message'>{errors.capacities}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as='select'
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='status'
                        className={touched.status && errors.status && 'error'}
                      >
                        <option selected disabled>
                          Select Status
                        </option>
                        <option value={0}>Closed</option>
                        <option value={1}>Open</option>
                      </Form.Control>
                      {touched.status && errors.status && (
                        <div className='error-message'>{errors.status}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Images</Form.Label>
                      <Form.Control
                        className='form-control'
                        type='file'
                        onChange={(e) => handleChangeImages(e)}
                        accept='image/*'
                        multiple
                      />
                      <Form.Text className='text-muted'>
                        <span>*Please using 1440 x 400 px for this image</span>
                      </Form.Text>
                      {touched.image && errors.image && (
                        <div className='error-message'>{errors.image}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <Form.Group>
                  <Form.Label>Price</Form.Label>
                  <div className='row'>
                    <div className='col-md-12 mb-2'>
                      <button
                        type='button'
                        onClick={() => handleAddPrice()}
                        className='btn btn-sm btn-outline-success'
                      >
                        Add Price
                      </button>
                    </div>
                    <div className='col-md-12'>
                      {values.price_type_default.map((element, i) => {
                        const fieldName = `price_type[${i}]`;
                        return (
                          <div key={i}>
                            <div className='row'>
                              <div className='col-md-1'>
                                <button
                                  type='button'
                                  className={`btn btn-sm btn-secondary disabled mt-1`}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                            <div className='row mt-3 align-items-end mb-4'>
                              <div className='col-md-6'>
                                <Form.Group>
                                  <Form.Label>Price Type</Form.Label>
                                  <Form.Control
                                    as='select'
                                    value={element.type}
                                    onChange={
                                      handleChange
                                      // (e) => onChangePrice(i, e)
                                    }
                                    disabled
                                    onBlur={handleBlur}
                                    name={`${fieldName}.type`}
                                  >
                                    <option selected disabled>
                                      Select Price
                                    </option>
                                    <option value={'cash'}>Cash</option>
                                    <option value={'tko_redeem'}>
                                      TKO Redeem
                                    </option>
                                    <option value={'tko_holder'}>
                                      TKO Holder
                                    </option>
                                    {/* <option value={'nft_holder'}>
                                    NFT Holder
                                  </option> */}
                                    <Fragment>
                                      <option value={'special_privilege'}>
                                        Special Privilege
                                      </option>
                                      <option value={'collab_with_tokocrypto'}>
                                        Collab with Tokocrypto
                                      </option>
                                    </Fragment>
                                  </Form.Control>
                                </Form.Group>
                              </div>
                              <div className='col-md-6'>
                                <Form.Group>
                                  <Form.Label>Price Amount</Form.Label>
                                  <input
                                    placeholder='Price Amount. Eg: 5'
                                    name={`${fieldName}.price`}
                                    type='text'
                                    required
                                    className='form-control'
                                    maxLength='20'
                                    disabled={
                                      element.type === 'special_privilege' ||
                                      element.type === 'collab_with_tokocrypto'
                                    }
                                    value={element.price}
                                    onChange={
                                      handleChange
                                      // (e) => onChangePrice(i, e)
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {values.price_type.map((element, i) => {
                        const fieldName = `price_type[${i}]`;
                        return (
                          <div key={i}>
                            <div className='row'>
                              <div className='col-md-1'>
                                <button
                                  type='button'
                                  onClick={() => {
                                    if (
                                      element.type !== 'special_privilege' &&
                                      element.type !== 'collab_with_tokocrypto'
                                    ) {
                                      handleRemovePrice(i);
                                    }
                                  }}
                                  className={`btn btn-sm ${
                                    element.type !== 'special_privilege' &&
                                    element.type !== 'collab_with_tokocrypto'
                                      ? 'btn-outline-danger'
                                      : 'btn-secondary disabled'
                                  } mt-1`}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                            <div className='row mt-3 mb-4 align-items-end'>
                              <div className='col-md-6'>
                                <Form.Group>
                                  <Form.Label>Price Type</Form.Label>
                                  <Form.Control
                                    as='select'
                                    value={element.type}
                                    onChange={
                                      handleChange
                                      // (e) => onChangePrice(i, e)
                                    }
                                    onBlur={handleBlur}
                                    name={`${fieldName}.type`}
                                  >
                                    <option selected disabled>
                                      Select Price
                                    </option>
                                    <option value={'cash'}>Cash</option>
                                    <option value={'tko_redeem'}>
                                      TKO Redeem
                                    </option>
                                    <option value={'tko_holder'}>
                                      TKO Holder
                                    </option>
                                    {/* <option value={'nft_holder'}>
                                    NFT Holder
                                  </option> */}
                                    <option
                                      value={'special_privilege'}
                                      disabled
                                    >
                                      Special Privilege
                                    </option>
                                    <option
                                      value={'collab_with_tokocrypto'}
                                      disabled
                                    >
                                      Collab with Tokocrypto
                                    </option>
                                  </Form.Control>
                                  {errors[`price_type`] &&
                                    errors[`price_type`][i] &&
                                    errors[`price_type`][i].type && (
                                      <div className='error-message'>
                                        {errors[`price_type`][i].type}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>

                              <div className='col-md-6'>
                                <Form.Group>
                                  <Form.Label>Price Amount</Form.Label>
                                  <Form.Control
                                    placeholder='Price Amount. Eg: 5'
                                    name={`${fieldName}.price`}
                                    type='text'
                                    required
                                    className='form-control'
                                    maxLength='20'
                                    disabled={
                                      element.type === 'special_privilege' ||
                                      element.type === 'collab_with_tokocrypto'
                                    }
                                    value={element.price}
                                    onBlur={handleBlur}
                                    onChange={
                                      handleChange
                                      // (e) => onChangePrice(i, e)
                                    }
                                  />
                                  {touched[`price_type`] &&
                                    touched[`price_type`][i] &&
                                    touched[`price_type`][i].price &&
                                    errors[`price_type`] &&
                                    errors[`price_type`][i] &&
                                    errors[`price_type`][i].price && (
                                      <div className='error-message'>
                                        {errors[`price_type`][i].price}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                            </div>
                            <div className='row mt-3 align-items-end'>
                              <div className='col-md-6'>
                                <div className='row'>
                                  <div className='col-md-8'>
                                    <Form.Group>
                                      <Form.Label>Qr Code</Form.Label>
                                      <Form.Control
                                        type='file'
                                        // value={element.type}
                                        onChange={(e) => {
                                          const priceType =
                                            bookingFormik.values.price_type;
                                          const _priceType = [...priceType];

                                          // let imageData = [];
                                          // for (let i = 0; i < e.target.files.length; i++) {
                                          //   imageData = [...imageData, e.target.files[i]];
                                          // }

                                          _priceType[i]['qr_code'] =
                                            e.target.files[0];

                                          bookingFormik.setFieldValue(
                                            'speakers',
                                            _priceType
                                          );
                                        }}
                                        disabled={
                                          element.type ===
                                            'special_privilege' ||
                                          element.type ===
                                            'collab_with_tokocrypto'
                                        }
                                        onBlur={handleBlur}
                                        name={`${fieldName}.qr_code`}
                                      />
                                      {errors[`price_type`] &&
                                        errors[`price_type`][i] &&
                                        errors[`price_type`][i].qr_code && (
                                          <div className='error-message'>
                                            {errors[`price_type`][i].qr_code}
                                          </div>
                                        )}
                                    </Form.Group>
                                  </div>
                                  <div className='col-md-4'>
                                    <img
                                      className='w-100'
                                      src={
                                        element.qr_code
                                          ? typeof element.qr_code === 'string'
                                            ? element.qr_code
                                            : URL.createObjectURL(
                                                element.qr_code
                                              )
                                          : undefined
                                      }
                                      alt=''
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <Form.Group>
                                  <Form.Label>Payment Address</Form.Label>
                                  <Form.Control
                                    placeholder='Payment address'
                                    name={`${fieldName}.payment_address`}
                                    type='text'
                                    className='form-control'
                                    disabled={
                                      element.type === 'special_privilege' ||
                                      element.type === 'collab_with_tokocrypto'
                                    }
                                    value={element.payment_address}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  {touched[`price_type`] &&
                                    touched[`price_type`][i] &&
                                    touched[`price_type`][i].payment_address &&
                                    errors[`price_type`] &&
                                    errors[`price_type`][i] &&
                                    errors[`price_type`][i].payment_address && (
                                      <div className='error-message'>
                                        {
                                          errors[`price_type`][i]
                                            .payment_address
                                        }
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                            </div>

                            <div className='row mt-1'>
                              <div className='col-md-12'>
                                <Form.Group>
                                  <Form.Label>
                                    Price Benefits (Press enter to input your
                                    benefits, let empty if there's none)
                                  </Form.Label>
                                  <div
                                    className={`input-group col-md-12 tags-input-container`}
                                  >
                                    {element.benefits.map((tag, index) => (
                                      <div className='tag-item' key={index}>
                                        <span className='text'>{tag}</span>
                                        <span
                                          className='close'
                                          onClick={() => {
                                            let inputValue = [];
                                            inputValue =
                                              element.benefits.filter(
                                                (_, i) => i !== index
                                              );
                                            bookingFormik.setFieldValue(
                                              `${fieldName}.benefits`,
                                              inputValue
                                            );
                                          }}
                                        >
                                          &times;
                                        </span>
                                      </div>
                                    ))}
                                    <input
                                      onKeyDown={(e) => {
                                        if (e.key !== 'Enter') return;
                                        const value = e.target.value;
                                        if (!value.trim()) return;

                                        let inputValue = [];
                                        inputValue = [
                                          ...element.benefits,
                                          value,
                                        ];

                                        bookingFormik.setFieldValue(
                                          `${fieldName}.benefits`,
                                          inputValue
                                        );
                                        e.currentTarget.value = '';
                                      }}
                                      type='text'
                                      className='form-control'
                                      placeholder='Type price benefits. Eg: discount 10%, free beverages, etc'
                                      name={`${fieldName}.benefits`}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {typeof errors.price_type === 'string' &&
                        errors.price_type && (
                          <div className='error-message'>
                            {errors.price_type}
                          </div>
                        )}
                    </div>
                  </div>
                  {values.price_type.length < 0 &&
                    touched.price_type &&
                    errors.price_type && (
                      <div className='error-message'>{errors.price_type}</div>
                    )}
                </Form.Group>

                {/* <Form.Group>
                  <Form.Label className="d-block">
                    TKO Holder ?
                  </Form.Label>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    name="is_token_holder"
                    onClick={() => {
                      setFieldValue('is_token_holder', true);
                      setIsTokenHolder(false);
                    }}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    name="is_token_holder"
                    onClick={() => {
                      setFieldValue('is_token_holder', false);
                      setIsTokenHolder(true);
                    }}
                  />

                  {touched.is_token_holder &&
                    errors.is_token_holder && (
                      <div className="error-message">
                        {errors.is_token_holder}
                      </div>
                    )}
                </Form.Group> */}
                {/* <Form.Group>
                  <Form.Label>Token Holder Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter token holder amount"
                    value={values.token_holder_amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="token_holder_amount"
                    className={
                      values.is_token_holder &&
                      touched.token_holder_amount &&
                      errors.token_holder_amount &&
                      'error'
                    }
                    disabled={!values.is_token_holder}
                  />
                  {values.is_token_holder &&
                    touched.token_holder_amount &&
                    errors.token_holder_amount && (
                      <div className="error-message">
                        {errors.token_holder_amount}
                      </div>
                    )}
                </Form.Group> */}

                <Form.Group>
                  <Form.Label>Seats / Room Number</Form.Label>
                  <div className='row'>
                    <div className='col-md-12 mb-2'>
                      <button
                        type='button'
                        onClick={() => handleAddSeats()}
                        className='btn btn-sm btn-outline-success'
                      >
                        Add Seats / Room Number
                      </button>
                    </div>
                    <div className='col-md-12'>
                      {values.seats.map((element, i) => {
                        return (
                          <>
                            <div className='row mt-2' key={i}>
                              <div className='col-md-1'>
                                <button
                                  type='button'
                                  onClick={() => handleRemoveSeats(i)}
                                  className='btn btn-sm btn-outline-danger mt-1'
                                >
                                  Remove
                                </button>
                              </div>
                            </div>

                            <div className='row mt-3'>
                              <div className='col-md-3'>
                                <Form.Group>
                                  <Form.Label>Seat Name</Form.Label>

                                  <Form.Control
                                    placeholder='Seat Name. Eg: A-1'
                                    name={`seats[${i}].seat_name`}
                                    type='text'
                                    className='form-control'
                                    maxLength='20'
                                    value={element.seat_name}
                                    onChange={
                                      handleChange
                                      // (e) => onChangeSeats(i, e)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  {touched[`seats`] &&
                                    touched[`seats`][i] &&
                                    touched[`seats`][i].seat_name &&
                                    errors[`seats`] &&
                                    errors[`seats`][i] &&
                                    errors[`seats`][i].seat_name && (
                                      <div className='error-message'>
                                        {errors[`seats`][i].seat_name}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                              <div className='col-md-3'>
                                <Form.Group>
                                  <Form.Label>Seat Status</Form.Label>
                                  <Form.Control
                                    as='select'
                                    value={element.status}
                                    onChange={
                                      handleChange
                                      // (e) => onChangeSeats(i, e)
                                    }
                                    onBlur={handleBlur}
                                    name={`seats[${i}].status`}
                                  >
                                    <option selected value=''>
                                      Select Status
                                    </option>
                                    <option value={1}>Enabled</option>
                                    <option value={0}>Disabled</option>
                                  </Form.Control>
                                  {touched[`seats`] &&
                                    touched[`seats`][i] &&
                                    touched[`seats`][i].status &&
                                    errors[`seats`] &&
                                    errors[`seats`][i] &&
                                    errors[`seats`][i].status && (
                                      <div className='error-message'>
                                        {errors[`seats`][i].status}
                                      </div>
                                    )}
                                </Form.Group>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  {typeof errors.seats === 'string' && errors.seats && (
                    <div className='error-message'>{errors.seats}</div>
                  )}
                </Form.Group>
                <div
                  className='kt-login__actions'
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <button
                    type='button'
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/booking-type');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                  >
                    Add Booking Type
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ThubBookingTypeCreate;
