import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import topicService from '../../../crud/topics';
import { toast } from 'react-toastify';
import courseService from '../../../crud/course';

const Schema = yup.object({
  course_name: yup
    .string()
    .required('Course Name is a required field')
    .test('course name test', 'Course Name is a required field', (val) => {
      return val && val !== '' ? true : false;
    }),
  topic_name_en: yup.string().required('Topic Name (EN) is a required field'),
  topic_name_id: yup.string().required('Topic Name (ID) is a required field'),
  subtopics: yup
    .array()
    .of(
      yup.object().shape({
        description_en: yup.string().required('Title (EN) is a required field'),
        description_id: yup.string().required('Title (ID) is a required field'),
        long_description_en: yup
          .string()
          .required('Description (EN) is a required field')
          .max(255, 'Description (EN) must be at most 255 characters'),
        long_description_id: yup
          .string()
          .required('Description (ID) is a required field')
          .max(255, 'Description (ID) must be at most 255 characters'),
        link_url: yup.string(),
      })
    )
    .min(1),
  time_allocation: yup.string().required('Time Allocation is a required field'),
  sorting_no: yup
    .string()
    .required('Sorting No is a required field')
    .test('sorting-no-validation', 'Sorting No must more than 0', (val) => {
      return parseInt(val) > 0;
    }),
  status: yup.string().required('Stattus is a required field'),
});

const CustomForm = (props) => {
  const [loading, setloading] = useState(false);
  const [courseData, setcourseData] = useState([]);

  const getToken = () => localStorage.getItem('token');

  useEffect(() => {
    props.submitRef.current = handleSubmit;
    getCourseData();
  }, []);

  const getCourseData = async () => {
    const res = await courseService.fetchCourses(getToken(), null);
    setcourseData(res.data.data.data);
  };

  useEffect(() => {
    if (props.data) {
      TopicForm.setValues({
        course: props?.data?.course,
        course_name: props?.data?.course?.id,
        topic_name_id: props?.data?.topic_name_id,
        topic_name_en: props?.data?.topic_name_en,
        subtopics: props?.data?.subtopics || [],
        topic_id: props?.data?.id,
        is_edit: props?.data?.id ? true : false,
        time_allocation: props?.data?.time_allocation,
        sorting_no: props?.data?.sorting_number,
        status: props?.data?.status,
      });
    }
  }, [props.data]);

  const TopicForm = useFormik({
    initialValues: {
      course_name: '',
      topic_name_en: '',
      topic_name_id: '',
      subtopics: [
        {
          long_description_en: '',
          long_description_id: '',
          description_en: '',
          description_id: '',
          link_url: '',
        },
      ],
      time_allocation: '',
      sorting_no: '',
      status: '1',
      topic_id: '',
    },
    validationSchema: Schema,
    onSubmit: async (value) => {
      try {
        const body = {
          course_id: String(value.course_name),
          topic_name_id: value.topic_name_id,
          topic_name_en: value.topic_name_en,
          status: value.status,
          time_allocation: value.time_allocation,
          sorting_number: value.sorting_no,
          subtopics: value.subtopics,
        };

        if (!value.topic_id) {
          const res = await topicService.fetchAddTopic(getToken(), body);
          toast.success(res.data.message);
        } else {
          const res = await topicService.fetchEditTopic(
            getToken(),
            value.topic_id,
            body
          );
          toast.success(res.data.message);
        }
        props.history.push('/tokoscholar/topics');
      } catch (error) {
        console.log(error, 'error');
        toast.success(error.response.data.message);
      }
    },
  });

  //   Subtopics Handler -------------------------
  const handleAddSubtopics = () => {
    const _fields = [...TopicForm.values.subtopics];
    _fields.push({ description_en: '', description_id: '' });
    TopicForm.setFieldValue('subtopics', _fields);
  };

  const handleRemove = (i) => {
    const _fields = [...TopicForm.values.subtopics];
    _fields.splice(i, 1);
    TopicForm.setFieldValue('subtopics', _fields);
  };

  const onChangeDataSubtopics = (i, e) => {
    const subtopics = TopicForm.values.subtopics;
    const _subtopic = [...subtopics];
    _subtopic[i][e.target.name] = e.target.value;
    TopicForm.setFieldValue('subtopics', _subtopic);
  };
  //   -----------------------------------

  const handleSubmit = () => {
    TopicForm.handleSubmit();
  };

  return (
    <form className='m-login__form m-form'>
      <div className='row'>
        <div className='col-md-12 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Course Name <span className='text-danger'>*</span>
            </Form.Label>
            <select
              className={`form-control ${
                TopicForm.errors.course_name && TopicForm.touched.course_name
                  ? 'is-invalid'
                  : ''
              }`}
              value={String(TopicForm.values.course_name || '')}
              onChange={(e) => {
                TopicForm.setFieldValue('course_name', e.target.value);
              }}
            >
              <option value=''>Select Course</option>
              {courseData && courseData.length > 0 ? (
                courseData.map((course, i) => {
                  return (
                    <option key={i} value={String(course.id)}>
                      {course.course_name_en}
                    </option>
                  );
                })
              ) : (
                <option value={TopicForm.values?.course?.id || ''}>
                  {TopicForm.values?.course?.course_name_en || ''}
                </option>
              )}
            </select>

            {TopicForm.errors.course_name && TopicForm.touched.course_name && (
              <Form.Text className='text-danger'>
                {TopicForm.errors.course_name}
              </Form.Text>
            )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Topic Name (EN)
            </Form.Label>
            <Form.Control
              type='text'
              name='topic_name_en'
              isInvalid={
                TopicForm.errors.topic_name_en &&
                TopicForm.touched.topic_name_en
              }
              placeholder='Topic Name (EN)'
              value={TopicForm.values.topic_name_en}
              onChange={TopicForm.handleChange}
              // required
            />
            {TopicForm.errors.topic_name_en &&
              TopicForm.touched.topic_name_en && (
                <Form.Text className='text-danger'>
                  {TopicForm.errors.topic_name_en}
                </Form.Text>
              )}
          </Form.Group>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Topic Name (ID)
            </Form.Label>
            <Form.Control
              type='text'
              name='topic_name_id'
              isInvalid={
                TopicForm.errors.topic_name_id &&
                TopicForm.touched.topic_name_id
              }
              placeholder='Topic Name (ID)'
              value={TopicForm.values.topic_name_id}
              onChange={TopicForm.handleChange}
              // required
            />
            {TopicForm.errors.topic_name_id &&
              TopicForm.touched.topic_name_id && (
                <Form.Text className='text-danger'>
                  {TopicForm.errors.topic_name_id}
                </Form.Text>
              )}
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Time Allocation <span className='text-danger'>*</span>{' '}
            </Form.Label>
            <Form.Control
              type='text'
              name='time_allocation'
              isInvalid={
                TopicForm.errors.time_allocation &&
                TopicForm.touched.time_allocation
              }
              placeholder='60 min / session'
              value={TopicForm.values.time_allocation}
              onChange={TopicForm.handleChange}
              // required
            />
            {TopicForm.errors.time_allocation &&
              TopicForm.touched.time_allocation && (
                <Form.Text className='text-danger'>
                  {TopicForm.errors.time_allocation}
                </Form.Text>
              )}
          </Form.Group>
        </div>
        <div className='col-md-3 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Sorting No. <span className='text-danger'>*</span>{' '}
            </Form.Label>
            <Form.Control
              type='number'
              name='sorting_no'
              min='1'
              isInvalid={
                TopicForm.errors.sorting_no && TopicForm.touched.sorting_no
              }
              value={TopicForm.values.sorting_no}
              onChange={TopicForm.handleChange}
              // required
            />
            {TopicForm.errors.sorting_no && TopicForm.touched.sorting_no && (
              <Form.Text className='text-danger'>
                {TopicForm.errors.sorting_no}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className='col-md-3 col-sm-12'>
          <Form.Group>
            <Form.Label className='font-weight-bold'>
              Status <span className='text-danger'>*</span>{' '}
            </Form.Label>
            <select
              className='form-control'
              onChange={(e) =>
                TopicForm.setFieldValue('status', e.target.value)
              }
              // disabled={i === 0 ? true : false}
            >
              <option selected={TopicForm.values.status === '1'} value='1'>
                Enabled
              </option>
              <option selected={TopicForm.values.status === '0'} value='0'>
                Disabled
              </option>
            </select>
            {TopicForm.errors.status && TopicForm.touched.status && (
              <Form.Text className='text-danger'>
                {TopicForm.errors.status}
              </Form.Text>
            )}
          </Form.Group>
        </div>
      </div>
      <Form.Group>
        <div className='row'>
          <Form.Label className='font-weight-bold mr-3'>
            Subtopics <span className='text-danger'>*</span>{' '}
          </Form.Label>
          <div className='mb-2'>
            <button
              type='button'
              onClick={() => handleAddSubtopics()}
              className='btn btn-sm btn-outline-success'
            >
              Add
            </button>
          </div>
          <div className='col-md-12'>
            {TopicForm.values.subtopics.map((element, i) => {
              return (
                <div className='row mt-2 align-items-center' key={i}>
                  <div
                    style={{ marginRight: '70px' }}
                    className='font-weight-bold'
                  >
                    {i + 1}
                  </div>
                  <div className='col-md-1'>
                    <button
                      type='button'
                      onClick={() => handleRemove(i)}
                      className='btn btn-sm btn-outline-danger mt-1'
                      disabled={
                        TopicForm.values.subtopics.length <= 1 ? true : false
                      }
                    >
                      Remove
                    </button>
                  </div>
                  {/* <div className='col-md-5'>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      placeholder='Description (EN)'
                      name='description_en'
                      isInvalid={
                        TopicForm.errors.subtopics &&
                        TopicForm.errors.subtopics.length > 0 &&
                        TopicForm.errors.subtopics[i] &&
                        TopicForm.touched.subtopics &&
                        TopicForm.touched.subtopics.length > 0 &&
                        TopicForm.touched.subtopics[i] &&
                        TopicForm.touched.subtopics[i].description_en
                      }
                      value={element?.description_en}
                      onChange={(e) => onChangeDataSubtopics(i, e)}
                    />
                    <div className='row'>
                      <div className='col-12'>
                        {TopicForm.errors.subtopics &&
                          TopicForm.errors.subtopics.length > 0 &&
                          TopicForm.errors.subtopics[i] &&
                          TopicForm.touched.subtopics &&
                          TopicForm.touched.subtopics.length > 0 &&
                          TopicForm.touched.subtopics[i] &&
                          TopicForm.touched.subtopics[i].description_en && (
                            <Form.Text className='text-danger'>
                              {TopicForm.errors.subtopics[i].description_en}
                            </Form.Text>
                          )}
                      </div>
                    </div>
                  </div> */}
                  <div className='col-md-10 mt-2'>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <Form.Group>
                          <Form.Label className='font-weight-bold'>
                            Title (EN)
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='description_en'
                            isInvalid={
                              TopicForm.errors.subtopics &&
                              TopicForm.errors.subtopics.length > 0 &&
                              TopicForm.errors.subtopics[i] &&
                              TopicForm.errors.subtopics[i].description_en &&
                              TopicForm.touched.subtopics &&
                              TopicForm.touched.subtopics.length > 0 &&
                              TopicForm.touched.subtopics[i] &&
                              TopicForm.touched.subtopics[i].description_en
                            }
                            placeholder='Title (EN)'
                            value={element.description_en}
                            onChange={(e) => onChangeDataSubtopics(i, e)}
                            // required
                          />
                          {TopicForm.errors.subtopics &&
                            TopicForm.errors.subtopics.length > 0 &&
                            TopicForm.errors.subtopics[i] &&
                            TopicForm.errors.subtopics[i].description_en &&
                            TopicForm.touched.subtopics &&
                            TopicForm.touched.subtopics.length > 0 &&
                            TopicForm.touched.subtopics[i] &&
                            TopicForm.touched.subtopics[i].description_en && (
                              <Form.Text className='text-danger'>
                                {TopicForm.errors.subtopics[i].description_en}
                              </Form.Text>
                            )}
                        </Form.Group>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <Form.Group>
                          <Form.Label className='font-weight-bold'>
                            Title (ID)
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='description_id'
                            isInvalid={
                              TopicForm.errors.subtopics &&
                              TopicForm.errors.subtopics.length > 0 &&
                              TopicForm.errors.subtopics[i] &&
                              TopicForm.errors.subtopics[i].description_id &&
                              TopicForm.touched.subtopics &&
                              TopicForm.touched.subtopics.length > 0 &&
                              TopicForm.touched.subtopics[i] &&
                              TopicForm.touched.subtopics[i].description_id
                            }
                            placeholder='Title (ID)'
                            value={element.description_id}
                            onChange={(e) => onChangeDataSubtopics(i, e)}
                            // required
                          />
                          {TopicForm.errors.subtopics &&
                            TopicForm.errors.subtopics.length > 0 &&
                            TopicForm.errors.subtopics[i] &&
                            TopicForm.errors.subtopics[i].description_id &&
                            TopicForm.touched.subtopics &&
                            TopicForm.touched.subtopics.length > 0 &&
                            TopicForm.touched.subtopics[i] &&
                            TopicForm.touched.subtopics[i].description_id && (
                              <Form.Text className='text-danger'>
                                {TopicForm.errors.subtopics[i].description_id}
                              </Form.Text>
                            )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <Form.Group>
                          <Form.Label className='font-weight-bold'>
                            Description (EN)
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            name='long_description_en'
                            placeholder='Description (EN)'
                            isInvalid={
                              TopicForm.errors.subtopics &&
                              TopicForm.errors.subtopics.length > 0 &&
                              TopicForm.errors.subtopics[i] &&
                              TopicForm.errors.subtopics[i]
                                .long_description_en &&
                              TopicForm.touched.subtopics &&
                              TopicForm.touched.subtopics.length > 0 &&
                              TopicForm.touched.subtopics[i] &&
                              TopicForm.touched.subtopics[i].long_description_en
                            }
                            value={element?.long_description_en}
                            onChange={(e) => onChangeDataSubtopics(i, e)}
                          />
                          {TopicForm.errors.subtopics &&
                            TopicForm.errors.subtopics.length > 0 &&
                            TopicForm.errors.subtopics[i] &&
                            TopicForm.errors.subtopics[i].long_description_en &&
                            TopicForm.touched.subtopics &&
                            TopicForm.touched.subtopics.length > 0 &&
                            TopicForm.touched.subtopics[i] &&
                            TopicForm.touched.subtopics[i]
                              .long_description_en && (
                              <Form.Text className='text-danger'>
                                {
                                  TopicForm.errors.subtopics[i]
                                    .long_description_en
                                }
                              </Form.Text>
                            )}
                        </Form.Group>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <Form.Group>
                          <Form.Label className='font-weight-bold'>
                            Description (ID)
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            name='long_description_id'
                            placeholder='Description (ID)'
                            isInvalid={
                              TopicForm.errors.subtopics &&
                              TopicForm.errors.subtopics.length > 0 &&
                              TopicForm.errors.subtopics[i] &&
                              TopicForm.errors.subtopics[i]
                                .long_description_id &&
                              TopicForm.touched.subtopics &&
                              TopicForm.touched.subtopics.length > 0 &&
                              TopicForm.touched.subtopics[i] &&
                              TopicForm.touched.subtopics[i].long_description_id
                            }
                            value={element?.long_description_id}
                            onChange={(e) => onChangeDataSubtopics(i, e)}
                          />
                          {TopicForm.errors.subtopics &&
                            TopicForm.errors.subtopics.length > 0 &&
                            TopicForm.errors.subtopics[i] &&
                            TopicForm.errors.subtopics[i].long_description_id &&
                            TopicForm.touched.subtopics &&
                            TopicForm.touched.subtopics.length > 0 &&
                            TopicForm.touched.subtopics[i] &&
                            TopicForm.touched.subtopics[i]
                              .long_description_id && (
                              <Form.Text className='text-danger'>
                                {
                                  TopicForm.errors.subtopics[i]
                                    .long_description_id
                                }
                              </Form.Text>
                            )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 col-sm-12'>
                        <Form.Group>
                          <Form.Label className='font-weight-bold'>
                            Link
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='link_url'
                            isInvalid={
                              TopicForm.errors.subtopics &&
                              TopicForm.errors.subtopics.length > 0 &&
                              TopicForm.errors.subtopics[i] &&
                              TopicForm.errors.subtopics[i].link_url &&
                              TopicForm.touched.subtopics &&
                              TopicForm.touched.subtopics.length > 0 &&
                              TopicForm.touched.subtopics[i] &&
                              TopicForm.touched.subtopics[i].link_url
                            }
                            placeholder='https://www.youtube.com'
                            value={element.link_url}
                            onChange={(e) => onChangeDataSubtopics(i, e)}
                            // required
                          />
                          {TopicForm.errors.subtopics &&
                            TopicForm.errors.subtopics.length > 0 &&
                            TopicForm.errors.subtopics[i] &&
                            TopicForm.touched.subtopics &&
                            TopicForm.touched.subtopics.length > 0 &&
                            TopicForm.touched.subtopics[i] &&
                            TopicForm.touched.subtopics[i].link_url && (
                              <Form.Text className='text-danger'>
                                {TopicForm.errors.subtopics[i].link_url}
                              </Form.Text>
                            )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Form.Group>
    </form>
  );
};

export default CustomForm;
