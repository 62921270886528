import React from 'react';
import { Link } from "react-router-dom";
import actions from "../../crud/community";
import { Form } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import "react-datepicker/dist/react-datepicker.css";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class User extends React.Component {

  history() {
    history.push("/community-tipping");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingUpdate: false,
      inputedValues: []
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getTippingConfig() {
    this.setState({
      loading: true
    })

    actions.fetchTippingConfig(this.getToken())
    .then(res => {
      const allData = res.data.data
      const name = allData.value || []

      this.setState({
        loading: false,
        inputedValues: JSON.parse(name) || []
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  
  updateConfig() {
    this.setState({
      loadingUpdate: true
    })

    let tippingConfig = {
      tg_usernames: this.state.inputedValues
    }

    actions.updateTippingConfig(this.getToken(), tippingConfig)
    .then(res => {
      this.setState({
        loadingUpdate: false
      })
      this.history()
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loadingUpdate: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getTippingConfig();
  }

  onChangeName(i, e){
    const coinl = this.state.inputedValues;
    const _coin = [...coinl];
    _coin[i]= e.target.value;
    this.setState({
      inputedValues: _coin,
    })
  }

  handleAddTeam(){
    const _fields = [...this.state.inputedValues];
    _fields.push('');
    this.setState({
      inputedValues: _fields,
    });
  }
  handleRemoveTeam(i){
    const _fields = [...this.state.inputedValues];
    _fields.splice(i, 1);
    this.setState({
      inputedValues: _fields,
    });
  }

  render(){    
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div style={{padding: '20px'}}>
                  <div className="center">
                    <h2>TIPPING CONFIGURATION</h2>
                  </div>
                  <Form.Group>
                    {/* <Form.Label></Form.Label> */}
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <button type="button" onClick={() => this.handleAddTeam()} className="btn btn-sm btn-outline-success">+ Add Telegram Admin</button>
                      </div>
                      <div className="col-md-12">
                        {this.state.inputedValues.map((element, i) => {
                            return (
                              <>
                                <div className="row mt-2" key={i}>
                                  <div className="col-md-1">
                                    <button type="button" onClick={() => this.handleRemoveTeam(i)} className="btn btn-sm btn-outline-danger mt-1">Remove</button>     
                                  </div>
                                  <div className="col-md-4">
                                    <input placeholder="Id Telegram" name="name" type="text" className="form-control" value={element} onChange={(e) => this.onChangeName(i, e)} />
                                  </div>
                                </div>
                              </>
                            );
                        })}
                      </div>
                    </div>
                  </Form.Group>
                  <br />
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <button
                      onClick={() => this.updateConfig()}
                      className="btn btn-primary"
                      style={{marginRight: '20px'}}
                      disabled={this.state.loadingUpdate}
                    >
                      Submit
                    </button>
                    <Link to="/community-tipping" className="kt-link">
                      <button className="btn btn-danger" style={{ color: '#fff', marginLeft: '20px'}}>Cancel</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}