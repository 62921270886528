import axiosInstance from "../../axios_config";

export const getListOTCDeposit = (params) => {
  return axiosInstance().get("admin/deposit",{params})
}
export const getDetailOTCDeposit = (id) => {
  return axiosInstance().get("admin/deposit/"+id)
}
export const approveOTCDeposit = (id, data) => {
  return axiosInstance().post(`admin/deposit/${id}/confirm-deposit`,data)
}
export const rejectOTCDeposit = (id) => {
  return axiosInstance().get(`admin/deposit/${id}/reject-deposit`)
}
export const cancelOTCDeposit = (id) => {
  return axiosInstance().get(`admin/deposit/${id}/cancel-deposit`)
}
export const getDepositLogsOTC = (id, params) => {
  return axiosInstance().get(`admin/deposit/${id}/deposit-logs`, {params})
}
export const getWithdrawLogsOTC = (id, params) => {
  return axiosInstance().get(`admin/deposit/${id}/withdraw-logs`, {params})
}
export const getBCABalance = () => {
  return axiosInstance().get(`bca/balance`)
}
export const getAccountBalance = () => {
  return axiosInstance().get(`admin/tokocrypto/account/balance`)
}
export const exportDeposit = (params) => {
  return axiosInstance().get(`admin/deposit/export`, {
    params,
  })
}
export const getEstimateBalance = () => {
  return axiosInstance().get(`admin/deposit/estimate-balance`)
}
export const checkWithdraw = (id) => {
  return axiosInstance().post(`admin/deposit/${id}/check-withdraw`)
}