import React, { useState, useEffect } from 'react';
import CKEditor from 'ckeditor4-react';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowBack } from '@material-ui/icons';
require('bootstrap/scss/bootstrap.scss');

const ThubLocationAdd = (props) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
    return token;
  };

  const onChangeFileImage = (e) => {
    let imageData = [];
    for (let i = 0; i < e.target.files.length; i++) {
      // imageData = [...imageData, e[i]];
      imageData.push(e.target.files[i]);
    }
    setField('image', imageData);
    // let file = e.target.files[0];
    // if (file) {
    //   setField('image', file);
    // }
  };

  const findFormErrors = () => {
    const {
      location_name,
      location_address,
      details_en,
      details_id,
      status,
      image,
      map_address,
      lat,
      long,
      location_city,
      phone_number,
    } = form;
    const newErrors = {};
    // name errors
    if (!location_name || location_name === '')
      newErrors.location_name = 'Location name cant be blank!';
    if (!location_address || location_address === '')
      newErrors.location_address = 'Location address cant be blank!';
    if (!details_id || details_id === '')
      newErrors.details_id = 'Indonesian details cant be blank!';
    if (!details_en || details_en === '')
      newErrors.details_en = 'English details cant be blank!';
    if (!status || status === '')
      newErrors.status = 'Thub status must not empty!';
    if (!image || typeof image === 'undefined')
      newErrors.image = 'Image must be filled';
    if (!location_city || location_city === '')
      newErrors.location_city = 'Location city cant be blank!';
    if (!phone_number || phone_number === '')
      newErrors.phone_number = 'Phone number cant be blank!';
    if (!map_address || map_address === '')
      newErrors.map_address = 'Map address cant be blank!';
    if (!lat || lat === '') newErrors.lat = 'Latitude must be filled';
    if (!long || long === '') newErrors.long = 'Longitude must be filled';

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      createLocation();
    }
  };

  const createLocation = async () => {
    setLoading(true);
    await thubActions
      .fetchThubLocationCreate(token, form)
      .then((res) => {
        toast.success('Successfully created a new thub location');
        props.history.push('/thub/location');
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form className='m-login__form m-form' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Location Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Location Name'
                        onChange={(e) =>
                          setField('location_name', e.target.value)
                        }
                        isInvalid={!!errors.location_name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.location_name}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Location Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Location Address'
                        onChange={(e) =>
                          setField('location_address', e.target.value)
                        }
                        isInvalid={!!errors.location_address}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.location_address}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Details ID</Form.Label>
                      <br />
                      <CKEditor
                        onChange={(e) => {
                          setField('details_id', e.editor.getData());
                        }}
                        isInvalid={!!errors.details_id}
                        config={{
                          toolbar: [
                            'undo',
                            'redo',
                            'fontFamily',
                            'bold',
                            'italic',
                            'blockQuote',
                            'heading',
                            'numberedList',
                            'bulletedList',
                            'insertTable',
                          ],
                        }}
                        fontFamily={{
                          options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                          ],
                        }}
                      />
                      {errors.details_id && (
                        <p
                          style={{
                            fontSize: '13px',
                            color: 'red',
                            marginTop: '5px',
                          }}
                        >
                          {errors.details_id}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Details EN</Form.Label>
                      <br />
                      <CKEditor
                        config={{
                          toolbar: [
                            'undo',
                            'redo',
                            'fontFamily',
                            'bold',
                            'italic',
                            'blockQuote',
                            'heading',
                            'numberedList',
                            'bulletedList',
                            'insertTable',
                          ],
                        }}
                        fontFamily={{
                          options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                          ],
                        }}
                        onChange={(e) => {
                          setField('details_en', e.editor.getData());
                        }}
                        isInvalid={!!errors.details_en}
                      />
                      {errors.details_en && (
                        <p
                          style={{
                            fontSize: '13px',
                            color: 'red',
                            marginTop: '5px',
                          }}
                        >
                          {errors.details_en}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <Form.Group>
                      <Form.Label>Map Link Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter map link address. Eg: https://goo.gl/maps/GasEGumoVMPHndDy6'
                        onChange={(e) =>
                          setField('map_address', e.target.value)
                        }
                        isInvalid={!!errors.map_address}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.map_address}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Location City</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter THub city location'
                        onChange={(e) =>
                          setField('location_city', e.target.value)
                        }
                        isInvalid={!!errors.location_city}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.location_city}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter Thub phone number'
                        onChange={(e) =>
                          setField('phone_number', e.target.value)
                        }
                        isInvalid={!!errors.phone_number}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.phone_number}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Location Status</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          as='select'
                          className='form-control'
                          onChange={(e) => {
                            setField('status', e.target.value);
                          }}
                          isInvalid={!!errors.status}
                        >
                          <option value='' disabled selected>
                            Select Status
                          </option>
                          <option value={1}>Open</option>
                          <option value={0}>Close</option>
                        </Form.Control>
                      </div>
                      {errors.status && (
                        <p
                          style={{
                            fontSize: '13px',
                            color: 'red',
                            marginTop: '5px',
                          }}
                        >
                          {errors.status}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Latitude</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter latitude coordinates value'
                        onChange={(e) => setField('lat', e.target.value)}
                        isInvalid={!!errors.lat}
                        step='0.00000001'
                        max={90}
                        min={-90}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>{errors.lat}</span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    <Form.Group>
                      <Form.Label>Longitude</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter longitude coordinates value'
                        onChange={(e) => setField('long', e.target.value)}
                        isInvalid={!!errors.long}
                        step='0.00000001'
                        max={180}
                        min={-180}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>{errors.long}</span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Location Image Gallery</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={(e) => onChangeFileImage(e)}
                          accept='image/*'
                          multiple
                          isInvalid={!!errors.image}
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Please using 1440 x 400 px for this image</span>
                      </Form.Text>
                      {errors.image && (
                        <span style={{ fontSize: '13px', color: 'red' }}>
                          {errors.image}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div
                  className='kt-login__actions'
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <button
                    type='button'
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/location');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                  >
                    Add Location
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ThubLocationAdd;
