import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import ReactLoading from 'react-loading';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import courseService from '../../../crud/course';
import topics from '../../../crud/topics';

require('bootstrap/scss/bootstrap.scss');

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const TopicsPage = (props) => {
  const [topicData, settopicData] = useState([]);
  const [loading, setloading] = useState(false);
  const [searchKeyword, setsearchKeyword] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [status, setStatus] = useState('');
  const [courseData, secourseData] = useState([]);
  const [courseId, setcourseId] = useState('');

  const query = useQuery();

  const course_id = query.get('courseId');

  const perPage = 10;

  //   Get Token
  const getToken = () => {
    return localStorage.getItem('token');
  };

  // get Course Data
  const getCourseData = async () => {
    try {
      const res = await courseService.fetchCourses(getToken(), null);
      secourseData(res.data.data.data);
    } catch (error) {
      console.log(error, 'Error');
    }
  };

  //   Get Course list
  const handleGetTopic = async (q, page, limit, status, course_id) => {
    try {
      const res = await topics.fetchTopic(getToken(), {
        q,
        page,
        limit,
        status,
        course_id,
      });
      settopicData(res.data.data || []);
    } catch (err) {
      console.log(err, 'Error');
    }
  };

  // handle delete course
  const handleDeleteTopics = (id) => {
    Swal.fire({
      title: `Are you sure to Delete this data ?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await topics.fetchDeleteTopic(getToken(), id);
          toast.success(res.data.message);
          handleGetTopic(searchKeyword, currentPage, perPage, status, courseId);
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    });
  };

  // handle change page
  const handlePageChange = (page) => {
    let activePage = page;
    setcurrentPage(activePage);
    handleGetTopic(searchKeyword, activePage, perPage, status, courseId);
  };

  // handleSearchTopic
  const handleSearchTopic = () => {
    setcurrentPage(1);
    handleGetTopic(searchKeyword, 1, perPage, status, courseId);
  };

  useEffect(() => {
    // handleGetTopic(searchKeyword, currentPage, perPage, courseId);
    getCourseData();
  }, []);

  useEffect(() => {
    if (course_id) {
      setcourseId(course_id);
    }
    handleGetTopic(
      searchKeyword,
      currentPage,
      perPage,
      status,
      course_id || ''
    );
  }, [course_id]);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='d-flex justify-content-between flex-wrap  align-content-center'>
              <select
                className='form-control'
                onChange={(e) => {
                  setcourseId(e.target.value);
                }}
                value={courseId || ''}
                style={{ width: '30%' }}
              >
                <option disabled value=''>
                  Search By Course Name
                </option>
                {courseData &&
                  courseData.length > 0 &&
                  courseData.map((course) => (
                    <option value={course.id}>{course.course_name_en}</option>
                  ))}
              </select>
              <input
                value={searchKeyword}
                className='form-control'
                style={{ width: '30%' }}
                onChange={(e) => setsearchKeyword(e.target.value)}
                placeholder='Search by topic name'
                type='text'
              />

              <select
                className='form-control'
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                style={{ width: '10%' }}
              >
                <option selected disabled>
                  Topic Status
                </option>
                <option value='1'>Enabled</option>
                <option value='0'>Disabled</option>
              </select>
              <button
                style={{ width: '10%' }}
                class='btn btn-outline-warning'
                type='button'
                onClick={handleSearchTopic}
              >
                Search
              </button>
              <button
                style={{ width: '10%' }}
                class='btn btn-outline-info'
                type='button'
                onClick={() => props.history.push('/tokoscholar/topics/add')}
              >
                Create Topic
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ReactLoading
                        type={'bars'}
                        color={'#0000FF'}
                        height={'2%'}
                        width={'5%'}
                      />
                    </div>
                  ) : (
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className='tableheh'
                            style={{ verticalAlign: 'middle', width: '7%' }}
                          >
                            No &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{ verticalAlign: 'middle' }}
                          >
                            Course Name &nbsp;
                            <button
                              onClick={(e) => this.onSortChange('email')}
                              className='sort-button'
                            ></button>
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Topic Name&nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Time Allocation &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Total Lectures &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Sorting &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Status &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Action &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topicData &&
                        topicData.data &&
                        topicData.data.length > 0 ? (
                          topicData.data.map((row, key) => (
                            <tr key={key}>
                              <td style={{ verticalAlign: 'middle' }}>
                                {key + 1}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row?.course?.course_name_en || '-'}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.topic_name_en}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.time_allocation}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.subtopics.length}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.sorting_number}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.status === '1' ? (
                                  <span className='text-success'>Enabled</span>
                                ) : (
                                  <span className='text-warning'>Disabled</span>
                                )}
                              </td>

                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                <span
                                  className='btn-action mr-1'
                                  onClick={() =>
                                    props.history.push(
                                      '/tokoscholar/topics/' + row.id
                                    )
                                  }
                                >
                                  <i class='far fa-edit'></i>
                                </span>
                                <span
                                  className='btn-action'
                                  onClick={() => handleDeleteTopics(row.id)}
                                >
                                  <i class='far fa-trash-alt'></i>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colspan={8} className='text-center text-danger'>
                              No data available in table
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                  <div className='d-flex justify-content-center align-items-center pagination-data'>
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={topicData.per_page || 0}
                      itemClass='page-item'
                      linkClass='page-link'
                      totalItemsCount={topicData.total_data || 0}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicsPage;
