import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner, Stack } from "react-bootstrap";
import '../../scss/global.scss';
import { connect } from "react-redux";
import FormWithFormik, { FormCustomOption } from "../../components/reusableFormWithFormik";
import { changeStatusChannel, createChannel, deleteChannel, getDataChannels, getDetailChannels, getTypeChannels, updateChannel } from "../../crud/middleware";
import { DataChannels } from "../../crud/middleware-types";
import { actions, InitialUtilsStateProps } from "../../store/ducks/utils.duck";

enum NameType { "VA", "DIRECT_DEBIT", "EWALLET" }

interface SearchAction {
    status: string;
    q: string;
}

interface Type {
    name: string;
    value: NameType;
}

const initialValues: DataChannels = { name: '', desc: '', type: 'VA' };
const initialValueSearch: SearchAction = { q: '', status: '' };

const titleData: Array<any> = [
    { id: 1, title: 'Name' },
    { id: 2, title: 'Status' },
    { id: 3, title: 'Created Date' },
    { id: 4, title: 'Update Date' },
    { id: 5, title: 'Options' },
]

const Channels: FC<InitialUtilsStateProps & typeof actions> = (props) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<Array<DataChannels>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [loadingGetDetail, setLoadingGetDetail] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5)
    const [totalData, setTotalData] = useState<number>(0)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [saveId, setSaveId] = useState<string | number>('')
    const [type, setType] = useState<Array<Type>>([])
    const [searching, setSearching] = useState<SearchAction>({
        q: '',
        status: ''
    })

    const statusChannels = (data: number | string) => {
        if (data === "1") return <ul className="list-status mb-0" style={{ color: '#2DA602' }}>
            <li>
                <p style={{ fontSize: '15px', color: 'black' }}>ACTIVE</p>
            </li>
        </ul>
        else return <ul className="list-status mb-0" style={{ color: 'red' }}>
            <li>
                <p style={{ fontSize: '15px', color: 'black' }}>NONACTIVE</p>
            </li>
        </ul>
    }

    const getListChannels = (page: number, q?: string, status?: string, limit?: number) => {
        setLoading(true)
        getDataChannels({ page, q, status, limit })
            .then((res) => {
                setDataSource(res.data.data?.data ?? [])
                setTotalData(res.data.data?.total_data ?? 0)
                setRowsPerPage(res.data.data?.per_page ?? 0)
            })
            .catch(err => {
                console.log(err.message)
            })
            .finally(() => setLoading(false))
    }

    const typeChannels = () => {
        getTypeChannels()
            .then((res) => {
                setType(res.data.data)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const getDetailListChannels = (id: string) => {
        const convertType = (res: string) => {
            if (res === "1") return 'VA'
            else if (res === "2") return 'DIRECT_DEBIT'
            else return 'EWALLET'
        }
        setLoadingGetDetail(true)
        getDetailChannels(id)
            .then((res) => {
                configForm.setFieldValue("name", res.data.data?.name)
                configForm.setFieldValue("desc", res.data.data?.desc)
                configForm.setFieldValue("type", convertType(res.data.data?.channel_type as string))
            })
            .catch(err => {
                console.log(err.message)
            })
            .finally(() => setLoadingGetDetail(false))
    }

    useEffect(() => {
        getListChannels(page, searching.q, searching.status || undefined, rowsPerPage)
        typeChannels()
    }, [page, searching, rowsPerPage])

    useEffect(() => {
        if (!showModal) {
            setSaveId('')
            configForm.resetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    useEffect(() => {
        if (saveId) {
            getDetailListChannels(saveId as string)
            setIsEdit(true)
        }
        else setIsEdit(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveId])


    const configFormSearch = useFormik<SearchAction>({
        initialValues: initialValueSearch,
        onSubmit: values => {
            setSearching({ q: values.q, status: values.status })
        },
    })

    const configForm = useFormik<DataChannels>({
        initialValues,
        onSubmit: (values, { resetForm }) => {
            setLoadingSubmit(true)
            if (!isEdit) {
                createChannel(values)
                    .then(() => {
                        getListChannels(page, '', undefined, rowsPerPage)
                    })
                    .catch((err) => { console.log(err.message) })
                    .finally(() => {
                        resetForm()
                        setLoadingSubmit(false)
                        setShowModal(false)
                    })
            } else {
                updateChannel(saveId, values)
                    .then(() => {
                        getListChannels(page, searching.q, searching.status || undefined, rowsPerPage)
                    })
                    .catch((err) => { console.log(err.message) })
                    .finally(() => {
                        resetForm()
                        setLoadingSubmit(false)
                        setShowModal(false)
                    })
            }
        },
    })

    const openModalAdd = () => {
        setShowModal(true)
    }
    const openModalEdit = (id: string) => {
        setShowModal(true)
        setSaveId(id)
    }
    const closeModal = () => setShowModal(false)

    const openModalDel = (id: string) => {
        props.addModal({
            type: "DANGER",
            okLabel: "Confirm",
            onOk: () => {
                deleteChannel(id)
                    .then(() => {
                        getListChannels(page, searching.q, searching.status || undefined, rowsPerPage)
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },
            cancelLabel: "Cancel",
            onCancel: () => { },
            closable: true,
            title: "Delete Confirmation",
            content: 'Are you sure you want to delete this data?'
        })
    }

    const openModalDisable = (id: string, status: string) => {
        let sendData: any = { status }
        props.addModal({
            type: "INFO",
            okLabel: "Confirm",
            onOk: () => {
                changeStatusChannel(id, sendData)
                    .then(() => {
                        getListChannels(page, searching.q, searching.status || undefined, rowsPerPage)
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },
            cancelLabel: "Cancel",
            onCancel: () => { console.log('saat cancel'); },
            closable: true,
            title: "Change Status Confirmation",
            content: 'Are you sure you want to disable this data?'
        })
    }

    let typeOptions: Array<FormCustomOption> = type.map(data => (
        { label: `${data.value}.${data.name}`, value: data.name }
    ))

    return (
        <>
            <Container fluid className="box-card bg-white p-3 mx-2">
                <form onSubmit={configFormSearch.handleSubmit} className="form-search">
                    <Stack direction="horizontal" gap={3}>
                        <FormWithFormik
                            type='DROPDOWN'
                            name='status'
                            config={configFormSearch}
                            options={[
                                { label: "Nonactive", value: "INACTIVE" },
                                { label: "Active", value: "ACTIVE" },
                            ]}
                        />
                        <FormWithFormik
                            type='TEXT_INPUT'
                            name='q'
                            config={configFormSearch}
                            placeholder="Name"
                        />
                        <Button variant="outline-success" type="submit">Search</Button>
                    </Stack>
                </form>
            </Container>
            <Container fluid className="box-card bg-white mx-2 my-4">
                <Row className="head-row-data p-3">
                    {titleData.map(data => (
                        <Col key={data.id}>
                            <p className="data-row-title m-0">{data.title}</p>
                        </Col>
                    ))}
                </Row>
                <div className="box-card" style={{ boxShadow: 'none' }}>
                    {loading ?
                        <div className="py-3 mx-auto text-center">
                            <Spinner animation="border" />
                        </div>
                        :
                        <>
                            {dataSource.map((list) => (
                                <Row key={list.id} className="base-row-data-client my-3 p-3">
                                    <Col>
                                        <p style={{ fontWeight: '400' }}>{list.name}</p>
                                    </Col>
                                    <Col>
                                        {statusChannels(list.status as string)}</Col>
                                    <Col>
                                        <p>{list.created_at}</p>
                                        <p>By {list.createdBy}</p>
                                    </Col>
                                    <Col>
                                        <p>{list.updated_at}</p>
                                        <p>By {list.updatedBy}</p>
                                    </Col>
                                    <Col>
                                        <Stack direction="horizontal" gap={2}>
                                            <Button style={{ background: list.status === "1" ? '#F99705' : '#2DA602', border: 'none' }} onClick={() => openModalDisable(list.id as string, list.status !== "1" ? "ACTIVE" : "INACTIVE")}>
                                                {list.status === "1" ? 'Disable' : 'Enable'}
                                            </Button>
                                            <Button style={{ background: '#278DD7', border: 'none' }} onClick={() => openModalEdit(list.id as string)}>
                                                <FontAwesomeIcon icon={faPen} />
                                            </Button>
                                            <Button onClick={() => openModalDel(list.id?.toString() as string)} style={{ background: '#BC0505', border: 'none' }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </Stack>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    }
                </div>
            </Container>
            {dataSource.length === 0 || dataSource.length === totalData ?
                null
                :
                <div className="text-center my-5">
                    <Button variant="outline-secondary" onClick={() => setRowsPerPage(rowsPerPage + 5)}>Load More...</Button>
                </div>
            }

            <div className="btn-add-client-wrapper p-4">
                <Button className="btn-add-client" onClick={openModalAdd}>
                    <FontAwesomeIcon icon={faPlus} color='black' />
                </Button>
            </div>

            <Modal size="lg" centered show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{!isEdit ? 'Form Channels' : 'Update Form Channels'}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={configForm.handleSubmit}>
                        {loadingGetDetail ?
                            <p className="text-center">Loading...</p>
                            :
                            null
                        }
                        <FormWithFormik
                            type='TEXT_INPUT'
                            name='name'
                            className="mb-4"
                            label='Channel Name'
                            config={configForm}
                            placeholder='Insert your channel name'
                        />
                        <FormWithFormik
                            type='TEXT_AREA'
                            name='desc'
                            className="mb-4"
                            label='Description'
                            config={configForm}
                            placeholder='Insert your description'
                        />
                        <FormWithFormik
                            type='RADIO'
                            name='type'
                            label='Type'
                            config={configForm}
                            className="mb-4 mr-2"
                            options={typeOptions}
                        />
                        <Button variant="outline-success" disabled={loadingSubmit} type="submit">
                            {loadingSubmit ? <Spinner animation="border" variant="secondary" /> : 'Submit'}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
    return {
        ...props.utils
    }
}

export default connect(mapStateToProps, actions)(Channels)