import { FC, useReducer } from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "react-bootstrap";
import FormWithFormik, { FileUpload } from "../../components/reusableFormWithFormik";
import { useFormik } from "formik";
import { CategoryRequest, HelpdeskFormTitle, helpdeskFormTitles } from "../../crud/helpdesk-types";
import { useDebounce } from "../../helper";
import { createCategory, getCategory, updateCategory } from "../../crud/helpdesk";
import Swal from "sweetalert2";
import { SectionWrapper } from "../user-new/user";
import * as yup from "yup";
import { statusOptions } from "./systemListCreate";

interface InitialState {
  loading: boolean;
}

interface IFormCreate extends Omit<CategoryRequest, "title" | "code" | "image_url" | "doc"> {
  title?: string;
  code?: HelpdeskFormTitle;
  image?: FileUpload;
}

const initialState: InitialState = {
  loading: false,
}

const CategoriesCreateHelpdesk: FC<RouteComponentProps<{id?: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  const formikConfig = useFormik<IFormCreate>({
    initialValues: {
      description: "",
    },
    onSubmit: async (values) => {
      dispatch({loading: true})
      try {
        if(values.title) {
          let payload: CategoryRequest = {
            title: values.title,
            description: values.description,
            status: values.status,
            code: values.code
          }

          if(values.image?.url) payload.image_url = values.image.url
          if(values.image?.originFileObjs && values.image?.originFileObjs[0]) payload.doc = values.image?.originFileObjs[0]

          if(values.id) await updateCategory(values.id, payload)
          else await createCategory(payload)
  
          Swal.fire("Success", values.id ? "Successfully updated data!" : "Successfully created data!", "success")
          props.history.push("/helpdesk/categories")
        }
      }
      catch(err: unknown) {
        console.log(err)
      }
      dispatch({loading: false})
    },
    validationSchema: yup.object({
      title: yup.string().required('Title must be fill!'),
      description: yup.string().required('Description must be fill!'),
      status: yup.string().required('Status must be fill!'),
    })
  })
  useDebounce<[string | undefined]>(async ()=>{
    try {
      if(props.match.params?.id) {
        const response = await getCategory(props.match.params.id)
  
        if(response.data.data) formikConfig.setValues({
          ...response.data.data,
          id: props.match.params?.id,
          image: {url: response.data.data.image_url},
        })
      }
    }
    catch(err: unknown) {
      console.log(err)
    }
    dispatch({loading: false})
  },[props.match.params?.id], undefined, () => dispatch({loading: true}))
  const isUpdateMode = !!formikConfig.values.id

  return (
    <SectionWrapper>
      <form className="custom-flex-col gap-3" onSubmit={formikConfig.handleSubmit}>
        <h2>{isUpdateMode ? "Update Category" : "Create Category"}</h2>
        <FormWithFormik 
          label="Title"
          type="TEXT_INPUT"
          name="title"
          placeholder="Title"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Code"
          type="SEARCHABLE_SELECT"
          name="code"
          placeholder="Code"
          options={Object.entries(HelpdeskFormTitle).map(([label,value]) => ({
            label: `${value} [${label.replace(/_/g," ")}]`,
            value
          }))}
          config={formikConfig}
        />
        <FormWithFormik 
          label="Description"
          type="TEXT_AREA"
          name="description"
          placeholder="Description"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Image"
          type="UPLOAD_FILE"
          name="image"
          config={formikConfig}
          affix={(values) => {
            const imgComponent = (src: string) => (
              <img 
                className="mt-2"
                src={src}
                width={350} 
                alt={src}
              />
            )
            
            if((values?.image?.originFileObjs && values?.image?.originFileObjs[0])) return imgComponent(URL.createObjectURL(values.image?.originFileObjs[0]))
            else if(values?.image?.url) return imgComponent(values?.image?.url)
            else return null
          }}
        />
        <FormWithFormik 
          label="Status"
          type="RADIO"
          name="status"
          placeholder="Status"
          options={statusOptions}
          config={formikConfig}
        />
        <br />
        <div className="custom-flex-row-center">
          <Button 
            onClick={()=>props.history.goBack()} 
            variant="outline-info"
            disabled={state.loading}
          >
            Back
          </Button>
          <Button 
            variant="outline-success" 
            onClick={()=>formikConfig.handleSubmit()}
            disabled={state.loading}
          >
            {isUpdateMode ? "Update Category" : "Create Category"}
          </Button>
        </div>
      </form>
    </SectionWrapper>
  )
}

export default CategoriesCreateHelpdesk