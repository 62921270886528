import { FC, useState, Fragment, useEffect } from 'react'
import { Stack, Button, Modal } from 'react-bootstrap'
import ReusableListPage, { ReusableListFilter, ReusableListSecondFilter } from '../../components/reusableListPage'
import { ReusableTableColumn } from '../../components/reusableTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import FormWithFormik, { FormMultiplyField } from '../../components/reusableFormWithFormik';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { actions, InitialUtilsStateProps } from '../../store/ducks/utils.duck';

interface DummyData {
  id: string;
  name: string;
  status: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

const Merchants: FC<InitialUtilsStateProps & typeof actions> = (props) => {
  const [dataSources, setDataSources] = useState<Array<DummyData>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [activePage, setActivePage] = useState(1)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [isAddModal, setIsAddModal] = useState(false)

  useEffect(()=>{
    // props.addModal({
    //   type: "DANGER",
    //   okLabel: "Confirm",
    //   onOk: () => { console.log('saat ok'); },
    //   cancelLabel: "Cancel",
    //   onCancel: () => { console.log('saat cancel'); },
    //   closable: true,
    //   title: "Delete Confirmation",
    //   content: 'Are you sure you want to delete this data?'
    // })
    // props.addModal({
    //   type: "INFO",
    //   okLabel: "Confirm",
    //   onOk: () => { console.log('saat ok'); },
    //   cancelLabel: "Cancel",
    //   onCancel: () => { console.log('saat cancel'); },
    //   closable: true,
    //   title: "Disable Confirmation",
    //   content: 'Are you sure you want to disable this data?'
    // })
  },[])

  const openModal = (mode: "CHANGE_STATUS" | "ADD" | "DELETE", data?: DummyData) => {
    if(mode === "ADD") setIsAddModal(true)
  }

  const propertiesField: Array<FormMultiplyField & {value: string}> = [
    { value: "", type: "PASSWORD", label: "API Key" },
    { value: "", type: "PASSWORD", label: "Secret Key" },
  ]

  const formik = useFormik({
    initialValues: {
      merchantName: "",
      merchantLogo: undefined,
      properties: propertiesField.map(v => v.value),
      status: "1"
    },
    onSubmit: (vals) => {

    }
  })

  const columns: Array<ReusableTableColumn<DummyData>> = [
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (dataIndex) => (
        <ul className="list-status mb-0" style={{ color: dataIndex === "ACTIVE" ? '#2DA602' : 'black' }}>
          <li>
            <p style={{ fontSize: '15px', color: 'black' }}>{dataIndex}</p>
          </li>
        </ul>
      )
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      render: (_, data) => (
        <div>
          <div>01/10/2022 12:12:56</div>
          <div>By Muhammad Wendy Taufik Hidayah</div>
        </div>
      )
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      render: (_, data) => (
        <div>
          <div>01/10/2022 12:12:56</div>
          <div>By Muhammad Wendy Taufik Hidayah</div>
        </div>
      )
    },
    {
      title: "Options",
      dataIndex: "id",
      render: (dataIndex, data) => (
        <Stack direction="horizontal" gap={2}>
          <Button style={{ background: '#F99705', border: 'none' }} onClick={()=>openModal("CHANGE_STATUS", data)}>Disable</Button>
          <Button onClick={()=>openModal("CHANGE_STATUS", data)} style={{ background: '#278DD7', border: 'none' }}>
              <FontAwesomeIcon icon={faPen} />
          </Button>
          <Button onClick={()=>openModal("DELETE", data)} style={{ background: '#BC0505', border: 'none' }}>
              <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Stack>
      )
    },
  ]

  const filters: Array<ReusableListFilter> = [
    {
      type: "DROP_DOWN",
      options: [
        { label: '- Status -', value: '' },
      ],
      value: '',
      onChange: (evt) => {
        
      },
    },
    {
      type: "INPUT_TEXT",
      onChange: (evt) => {

      },
      value: '',
      placeholder: 'Name',
    },
  ]

  const triggerBtns: Array<ReusableListSecondFilter> = [
    {
      type: "BUTTON",
      label: 'Search',
      variants: 'success',
      onClick: () => {
        // getList(1, {
        //   q: keywords,
        //   start_date: startDate,
        //   end_date: endDate,
        //   status: statusSelected,
        // }),
      }
    },
  ]

  return (
    <Fragment>
      <ReusableListPage 
        filters={filters}
        triggerBtns={triggerBtns}
        loading={loading}
        columns={columns}
        dataSources={dataSources}
        pagination={{
          activePage,
          itemsCountPerPage: 15,
          totalItemCount,
          onChange: (pageNumber) => {
            // getList(pageNumber, {
            //   q: keywords,
            //   start_date: startDate,
            //   end_date: endDate,
            //   status: statusSelected,
            // });
            // setActivePage(pageNumber);
          },
        }}
      />
      <div className="btn-add-client-wrapper p-4">
        <Button className="btn-add-client" onClick={() => openModal("ADD")}>
          <FontAwesomeIcon icon={faPlus} color='black' />
        </Button>
      </div>
      <Modal size="lg" centered show={isAddModal} onHide={()=>setIsAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Form Merchants</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <FormWithFormik
              type='TEXT_AREA'
              name='merchantName'
              className="mb-4"
              label='Merchant Name'
              config={formik}
              placeholder='Insert your merchant name'
            />
            <FormWithFormik
              type='FILE'
              name='merchantLogo'
              className="mb-4"
              label='Detail of Payment Method'
              config={formik}
            />
            <FormWithFormik
              type='MULTIPLY_INPUT'
              name='properties'
              label='Label'
              config={formik}
              className="mb-4 mr-2"
              fields={propertiesField.map(({value, ...others}) => others)}
            />
            <FormWithFormik
              type='RADIO'
              name='status'
              label='Label'
              config={formik}
              className="mb-4 mr-2"
              options={[
                { label: "Active", value: "1" },
                { label: "Disable", value: "0" }
              ]}
            />
            <Button variant="outline-success" type="submit">
                Submit
            </Button>
          </form>
        </Modal.Body>
    </Modal>
    </Fragment>
  )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
    return {
        ...props.utils
    }
}

export default connect(mapStateToProps, actions)(Merchants)