import { Action } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export interface User {
  fullname: string;
  pic: string;
  role: string;
  email: string;
}

export interface InitialAuthStateProps {
  user?: User;
  authToken?: string;
}

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
};

const initialAuthState: InitialAuthStateProps = {
  user: undefined,
  authToken: undefined
};

export interface ActionPayload extends Action<string> {
  payload: Partial<{ authToken: string, user: User }>;
}

export const reducer = persistReducer<Partial<InitialAuthStateProps>, ActionPayload>(
    { storage, key: "token", whitelist: [ "authToken"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Register:
        case actionTypes.Login: {
          return { ...action.payload, user: undefined };
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        // case actionTypes.UserLoaded: {
        //   const { user } = action.payload;

        //   return { ...state, user };
        // }

        default:
          return state;
      }
    }
);

export const actions = {
  login: (authToken: string) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken: string) => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  // requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  // fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

// export function* saga() {
//   yield takeLatest(actionTypes.Login, function* loginSaga() {
//     yield put(actions.requestUser());
//   });

//   yield takeLatest(actionTypes.Register, function* registerSaga() {
//     yield put(actions.requestUser());
//   });

//   yield takeLatest(actionTypes.UserRequested, function* userRequested() {
//     const { data: user } = yield getUserByToken();

//     yield put(actions.fulfillUser(user));
//   });
// }
