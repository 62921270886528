import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';

interface CancelPerToken {
  url: string | undefined;
  cancelToken: CancelTokenSource;
}

let cancelTokenPerUrls: Array<CancelPerToken> = []

export const cancelTokenConfiguration = (config: AxiosRequestConfig) => {
  if(!cancelTokenPerUrls.map(cancelTokenPerUrl => cancelTokenPerUrl.url).includes(config.url)) {
    cancelTokenPerUrls.push({
      url: config.url,
      cancelToken: axios.CancelToken.source()
    })
  }
  const selectedUrl = cancelTokenPerUrls.find(cancelTokenPerUrl => cancelTokenPerUrl.url === config.url)
  const indexSelectedUrl = cancelTokenPerUrls.findIndex(cancelTokenPerUrl => cancelTokenPerUrl.url === config.url)
  if (selectedUrl) {
    selectedUrl.cancelToken.cancel("Operation canceled due to new request.")
  }

  if(indexSelectedUrl > -1) {
    cancelTokenPerUrls[indexSelectedUrl].cancelToken = axios.CancelToken.source()
    config.cancelToken = cancelTokenPerUrls[indexSelectedUrl].cancelToken.token
  }
}

const history =  createBrowserHistory();

const instance = (isCancelToken?: boolean, baseUrl?: string) => {
  const i = axios.create({
    baseURL: baseUrl ?? process.env.REACT_APP_ENV === 'production'
    ? 'https://service.tcdx.id/'
    : 'https://service-demo.tcdx.id/',
  });
  i.interceptors.request.use((config) => {
    if(config.headers) {
      config.headers.Authorization = localStorage.getItem("token");
      config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
    }
    if(isCancelToken) cancelTokenConfiguration(config)
    return config
  })
  
  i.interceptors.response.use(config => config, (err) => {
    if(err.response){
      if(err.response.status === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
      }
    }
  
    return Promise.reject(err)
  })

  return i
}

export default instance;
