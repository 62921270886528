import instance from "./instanceWithCancelToken";
import { ResponseAPI } from "./global-types";
import { AxiosResponse } from "axios";
import { RiskCalculationFilter } from "../pages/user-new/user";

export interface ExternalKYC {
  country: string;
  id_number: string;
  liveness_video: string;
  npwp: string | null;
  name?: string;
  risk_calculation: string;
}

export interface IUser {
  accepted_at: string | null;
  action_by: string | null;
  approved_by: string | null;
  created_at: string;
  created_by: string | null;
  email: string;
  external_kycs: Array<ExternalKYC>;
  government_status: number;
  id: number;
  is_manual: number;
  is_reset: number;
  kyc_level: number;
  kyc_status: number;
  liveness_at: null;
  liveness_check: number;
  mobile_no: string;
  ref_id: number;
  rejected_at: string | null;
  rejected_by: string | null;
  screening_risk: number;
  risk_calculation: string;
  updated_at: string;
}

export interface IDetailKYC {
  accepted_at: string | null;
  action_by: string | null;
  address: string;
  approved_by: string | null;
  auto_approve: string | null;
  auto_approve_at: string | null;
  city: string | null;
  country: string;
  created_at: string;
  created_by: string | null;
  date_of_birth: string;
  email: string;
  gender: number;
  goverment_result: any | null;
  government_status: KYCGovermentOrLiveness;
  id: string | number;
  id_number: string;
  id_photo: string;
  id_type: KYCGovermentOrLiveness;
  kyc_level: KYCLevel;
  liveness_at: string | null;
  liveness_check: number;
  liveness_flag: number;
  liveness_response: string | null;
  liveness_video?: string;
  mobile_no: string;
  name: string;
  nationality: string;
  npwp: string | null;
  occupation: number;
  phone_country_code: string;
  phone_no: string;
  place_of_birth: string;
  proof_of_address: string;
  province: string | null;
  register_flag: number;
  reject_reason: string | null;
  rejected_at: string | null;
  rejected_by: string | null;
  reset_by: string | null;
  risk_calculation: string;
  screening_risk: number;
  selfie_photo: string;
  status: number;
  updated_at: string;
  user_created_by: string | null;
  user_id: number;
  user_liveness_at: string | null;
  user_liveness_provider_at: string | null;
  zip_code: string;
}

export interface UserPremium {
  action_by: string | null;
  bank_statement: string;
  created_at: string;
  id: number;
  reject_reason: string | null;
  source_of_wealth: string;
  status: number;
  updated_at: string;
  user_id: number;
}

export interface IKYCPagination<T> {
  current_page: string;
  data: Array<T>;
  per_page: number;
  total_data: number;
  total_page: number;
}
export interface ConfirmKYCPayload {
  status: number;
  reject_reason: string;
  level: number;
}
export interface LivenessDetail {
  content: Array<string>;
  created_at: string;
  email: string;
  gestures_set: number;
  id: number;
  ref_id: string;
  result: string;
  status: number;
  updated_at: string;
}
export interface ConfirmKYCLivenessPayload {
  status: number;
}
export interface DetailKYCPayload {
  name: string;
  id_number: string;
  place_of_birth: string;
  date_of_birth: string;
}
export interface ResetKYCPayload {
  reset_status: 0 | 1;
}
export interface ResponseResetKYC {
  msg: string;
}
export interface IProvince {
  [key: string]: string;
}
export interface IOccupation {
  [key: number]: string;
}
export interface ICountry {
  Capital: string;
  Continent: string;
  Currency: string;
  Dial: string;
  Iso2: string;
  Iso3: string;
  Name: string;
  Unicode: string;
}
export interface INonPremiumDocument {
  id_photo: string;
  proof_of_address?: string;
}
export type DateSource = 'create_date' | 'action_date'
export type ReportTypeValue = 'simple' | 'peruri_report'

export interface ExportDataPayload {
  type: ReportTypeValue;
  nationality?: string | "all";
  occupation?: "" | null | number;
  kyc_lvl_1?: string | "all";
  kyc_lvl_2?: string | "all";
  liveness_check?: string | "all";
  government_status?: string | "all";
  screening_risk?: string | "all";
  date_source: DateSource;
  action_by?: string | "all";
  date_from: string;
  date_to: string;
}
export type KYCStatus = "pending" | "approved" | "rejected"
export type KYCLevel = 1 | 2
export type KYCScreeningRisk = "none" | "low" | "medium" | "high"
export type KYCGovermentOrLiveness = -1 | 0 | 1

export type OrderSort = 'asc' | 'desc'
export type RequestParams<T = undefined, U = undefined> = {
  order_by?: U;
  order_sort?: 'asc' | 'desc';
  q?: string;
  page: number;
  limit: number;
} & T;
export interface RequestUserKYCParams {
  status?: KYCStatus;
  government_status?: KYCGovermentOrLiveness;
  liveness_check?: KYCGovermentOrLiveness;
  screening_risk?: KYCScreeningRisk;
  risk_calculation?: RiskCalculationFilter;
  country?: string;
  level?: KYCLevel;
}
export type FileType = "image/jpg" | "video/mp4" | "application/pdf"
export const status: Array<KYCStatus> = ["pending", "approved", "rejected"]
export const screening_risk: Array<KYCScreeningRisk> = ["none", "low", "medium", "high"]
export const getUserKYCs = (params: RequestParams<RequestUserKYCParams, keyof Omit<IUser, "external_kycs">>) => instance(true).get<ResponseAPI<IKYCPagination<IUser>>>("/admin/user", {params})
export const getDetailUserKYC = (id: string | number) => new Promise<AxiosResponse<ResponseAPI<IDetailKYC>>>(async (resolve,reject) => {
  try {
    const response = await instance().get<ResponseAPI<IDetailKYC>>(`/admin/user/${id}/kyc`)
    if(response.data.data?.selfie_photo === "0") response.data.data.selfie_photo = ""
    resolve(response)
  }
  catch(err) {
    reject(err)
  }
})
export const getHistoryNonPremiumUserKYC = (id: string | number, params: RequestParams<{}, keyof IDetailKYC>) => new Promise<AxiosResponse<ResponseAPI<IKYCPagination<IDetailKYC>>>>(async (resolve,reject) => {
  try {
    const response = await instance().get<ResponseAPI<IKYCPagination<IDetailKYC>>>(`/admin/user/${id}/kyc/history`, {params})
    response.data.data?.data.forEach(d => {
      if(d.selfie_photo === "0") d.selfie_photo = ""
    })
    resolve(response)
  }
  catch(err) {
    reject(err)
  }
})
export const getHistoryPremiumUserKYC = (id: string | number, params: RequestParams<{}, keyof UserPremium>) => instance().get<ResponseAPI<IKYCPagination<UserPremium>>>(`/admin/user/${id}/premium/history`, {params})
export const getPremiumUserKYC = (id: string | number) => instance().get<ResponseAPI<UserPremium>>(`/admin/user/${id}/premium`)
export const getFile = (id: string | number, isDownload: boolean, type: FileType) => new Promise<string>(async (resolve,reject) => {
  try {
    const response = await instance().get<string>(`/admin/file/${id}`,{
      params: {
        base64: 1
      }
    })
    const imageB64 = `data:${type};base64,` + response.data;
    if(isDownload) {
      const a = document.createElement('a')
      a.href = imageB64;
      a.download = `${id}`;
      a.click();
      resolve(imageB64)
    }
    else resolve(imageB64)
  }
  catch(err) {
    reject(err)
  }
})
export const getLivenessPhoto = (id: string | number, isDownload: boolean) => new Promise<string | undefined>(async (resolve,reject) => {
  try {
    const response = await instance().get(`/admin/file/${id}/liveness/download`,{
      params: {
        base64: 1
      }
    })
    if (isDownload) {
      const a = document.createElement('a');
      a.href = 'data:image/jpg;base64,' + response.data.data[0];
      a.download = `${id}`;
      a.click();
      resolve(undefined)
    } else {
      const image = 'data:image/jpg;base64,' + response.data.data[0];
      resolve(image);
    }
  }
  catch(err) {
    reject(err)
  }
})
export const confirmKYC = (id: string | number, payload: ConfirmKYCPayload) => instance().post<ResponseAPI<boolean>>(`/admin/user/${id}/kyc/confirm`, payload)
export const getLiveness = (id: string | number) => instance().get<ResponseAPI<LivenessDetail>>(`/admin/user/${id}/liveness`)
export const confirmKYCLiveness = (id: string | number, payload: ConfirmKYCLivenessPayload) => instance().post<ResponseAPI<boolean>>(`/admin/user/${id}/kyc/liveness/confirm`, payload)
export const confirmKYCGovernment = (id: string | number, payload: ConfirmKYCLivenessPayload) => instance().post<ResponseAPI<boolean>>(`/admin/user/${id}/kyc/government/confirm`, payload)
export const getExportData = (payload: ExportDataPayload) => new Promise<boolean>(async (resolve,reject) => {
  try {
    const response = await instance().post<ResponseAPI<string>>('/admin/user/download-report', payload)
    if(response.data.data) {
      const csvContent = window.atob(response.data.data)
      const url = URL.createObjectURL(
        new Blob([csvContent], {
          type: 'data:application/octet-stream;base64'
        })
      )
      const a = document.createElement('a');
      a.download = `${payload.date_from}.csv`;
      a.href = url;
      a.click();
    }
    resolve(true)
  }
  catch(err) {
    reject(err)
  }
})
export const getNonPremiumDocument = (id: string | number) => new Promise<INonPremiumDocument | undefined>(async (resolve,reject) => {
  try {
    const response = await getHistoryNonPremiumUserKYC(id, { limit: 1, page: 1 })
    if(response.data.data && response.data.data?.data.length > 0) resolve({
      id_photo: response.data.data.data[0].id_photo,
      proof_of_address: response.data.data.data[0].country !== "ID" ? response.data.data.data[0].proof_of_address : undefined
    })
  }
  catch(err) {

  }
})
export const getPremiumDocument = (id: string | number) => new Promise<Pick<UserPremium, "bank_statement" | "source_of_wealth"> | undefined>(async (resolve,reject) => {
  try {
    const response = await getHistoryPremiumUserKYC(id, { limit: 1, page: 1 })
    if(response.data.data && response.data.data?.data.length > 0) resolve({
      bank_statement: response.data.data.data[0].bank_statement,
      source_of_wealth: response.data.data.data[0].source_of_wealth
    })
    else resolve(undefined)
  }
  catch(err) {
    reject(err)
  }
})
export const updateDetailKYC = (id: string | number, payload: DetailKYCPayload) => instance().post<ResponseAPI<boolean>>(`/admin/kyc/${id}/update`, payload)
export const resetDetailKYC = (id: string | number, payload: ResetKYCPayload) => instance().post<ResponseAPI<ResponseResetKYC>>(`/admin/user/${id}/kyc/reset`, payload)

export const getCountries = () => instance().get<ResponseAPI<Array<ICountry>>>('/admin/v1/itemList/country')
export const getOccupations = () => instance().get<ResponseAPI<IOccupation>>('/admin/v1/itemList/en/occupation')
export const getProvinces = () => instance().get<ResponseAPI<IProvince>>('/admin/v1/itemList/id/province')