import instance from "./axiosKycInstance";

const getMiniBanner = (token, params) => {
  return instance.get("/admin/tkoweb/minibanner" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchMiniBanner = (token, params) => {
  return new Promise((resolve, reject) => {
    getMiniBanner(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailMiniBanner = (token, id) => {
  return instance.get("/admin/tkoweb/"+ id +"/detail-minibanner/", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailMiniBanner = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailMiniBanner(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const createPostMiniBanner = (token, data) => {
  return instance.post("/admin/tkoweb/create-minibanner", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postMiniBanner = (token, data) => {
  return new Promise((resolve, reject) => {
    createPostMiniBanner(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const updateMiniBanner = (token, id, data) => {
  return instance.put("/admin/tkoweb/"+id+"/modify-minibanner", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putMiniBanner = (token, id, data) => {
  return new Promise((resolve, reject) => {
    updateMiniBanner(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const updateMiniBannerStatus = (token, data, id) => {
  return instance.put("/admin/tkoweb/"+id+"/disable-minibanner", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const putMiniBannerStatus = (token, data, id) => {
  return new Promise((resolve, reject) => {
    updateMiniBannerStatus(token, data, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const deleteMiniBanner = (token, id) => {
  return instance.delete("/admin/tkoweb/"+id+"/delete-minibanner", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteDataMiniBanner = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteMiniBanner(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getTag = (token, params) => {
  return instance.get("/admin/tkoweb/tag" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchTag = (token, params) => {
  return new Promise((resolve, reject) => {
    getTag(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getTagListActive = (token) => {
  return instance.get("/admin/tkoweb/tag-list", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchTagListActive = (token) => {
  return new Promise((resolve, reject) => {
    getTagListActive(token).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailTag = (token, id) => {
  return instance.get("/admin/tkoweb/"+ id +"/detail-tag", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailTag = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailTag(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const createTag = (token, data) => {
  return instance.post("/admin/tkoweb/create-tag", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postTag = (token, data) => {
  return new Promise((resolve, reject) => {
    createTag(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const putTag = (token, id, data) => {
  return instance.put("/admin/tkoweb/"+id+"/modify-tag", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateTag = (token, id, data) => {
  return new Promise((resolve, reject) => {
    putTag(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const putTagStatus = (token, data, id) => {
  return instance.put("/admin/tkoweb/"+id+"/disable-tag", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateTagStatus = (token, data, id) => {
  return new Promise((resolve, reject) => {
    putTagStatus(token, data, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const deleteTag = (token, id) => {
  return instance.delete("/admin/tkoweb/"+id+"/delete-tag", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteDataTag = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteTag(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getNewsletter = (token, params) => {
  return instance.get("/admin/tkoweb/newsletter" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchNewsletter = (token, params) => {
  return new Promise((resolve, reject) => {
    getNewsletter(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDownloadNewsletter = (token, params) => {
  return instance.get("/admin/tkoweb/download" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDownloadNewsletter = (token, params) => {
  return new Promise((resolve, reject) => {
    getDownloadNewsletter(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getArticle= (token, params) => {
  return instance.get("/admin/tkoweb/article" + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchArticle = (token, params) => {
  return new Promise((resolve, reject) => {
    getArticle(token, params).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const getDetailArticle = (token, id) => {
  return instance.get("/admin/tkoweb/"+ id +"/detail-article", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const fetchDetailArticle = (token, id) => {
  return new Promise((resolve, reject) => {
    getDetailArticle(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const createArticle = (token, data) => {
  return instance.post("/admin/tkoweb/create-article", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const postArticle = (token, data) => {
  return new Promise((resolve, reject) => {
    createArticle(token, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const putArticle = (token, id, data) => {
  return instance.put("/admin/tkoweb/"+id+"/modify-article", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateArticle = (token, id, data) => {
  return new Promise((resolve, reject) => {
    putArticle(token, id, data).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const putArticleStatus = (token, data, id) => {
  return instance.put("/admin/tkoweb/"+id+"/disable-article", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

const updateArticleStatus = (token, data, id) => {
  return new Promise((resolve, reject) => {
    putArticleStatus(token, data, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

const deleteArticle = (token, id) => {
  return instance.delete("/admin/tkoweb/"+id+"/delete-article", {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}
  
const deleteDataArticle = (token, id) => {
  return new Promise((resolve, reject) => {
    deleteArticle(token, id).then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  });
}

export default {
  fetchMiniBanner: fetchMiniBanner,
  fetchDetailMiniBanner: fetchDetailMiniBanner,
  postMiniBanner: postMiniBanner,
  putMiniBanner: putMiniBanner,
  putMiniBannerStatus: putMiniBannerStatus,
  deleteDataMiniBanner: deleteDataMiniBanner,
  fetchTag: fetchTag,
  fetchTagListActive: fetchTagListActive,
  fetchDetailTag: fetchDetailTag,
  postTag: postTag,
  updateTag: updateTag,
  updateTagStatus: updateTagStatus,
  deleteDataTag: deleteDataTag,
  fetchNewsletter: fetchNewsletter,
  fetchDownloadNewsletter: fetchDownloadNewsletter,
  fetchArticle: fetchArticle,
  fetchDetailArticle: fetchDetailArticle,
  postArticle: postArticle,
  updateArticle: updateArticle,
  updateArticleStatus: updateArticleStatus,
  deleteDataArticle: deleteDataArticle,
};