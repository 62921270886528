import { FC, useReducer } from "react";
import { Pagination, PaginationRequest } from "../../crud/global-types";
import { CategoryRequest } from "../../crud/helpdesk-types";
import { useDebounce } from "../../helper";
import { getCategories } from "../../crud/helpdesk";
import ReusableListPage, { ReusableListFilter, ReusableListSecondFilter } from "../../components/reusableListPage";
import { RouteComponentProps } from "react-router-dom"
import { statusOptions } from "./systemListCreate";

interface InitialState extends Pagination<CategoryRequest>, PaginationRequest {
  loading: boolean;
}

const initialState: InitialState = {
  loading: false,
  total_data: 0,
  total_page: 1,
  limit: 15,
  page: 1,
  data: [],
}

const CategoriesHelpdesk: FC<RouteComponentProps<{id: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  useDebounce<[string | undefined, number]>(async (q, page)=>{
    try {
      const response = await getCategories({
        limit: state.limit,
        page,
        q
      })

      if(response.data.data) dispatch(response.data.data)
    }
    catch(err: unknown) {
      console.log(err)
    }

    dispatch({loading: false})
  },[state.q, state.page], 400, () => dispatch({loading: true}))
  
  const filters: Array<ReusableListFilter> = [
    {
      type: "INPUT_TEXT",
      onChange: (evt) => dispatch({q: evt.target.value}),
      value: state.q,
      placeholder: "Search by keyword",
    },
  ]

  const triggerBtns: Array<ReusableListSecondFilter> = [
    {
      type: "LINK",
      label: "Add New Categories",
      to: "/helpdesk/categories/create"
    },
  ]

  return (
    <ReusableListPage<CategoryRequest> 
      loading={state.loading}
      filters={filters}
      triggerBtns={triggerBtns}
      dataSources={state.data}
      columns={[
        {
          dataIndex: "code",
          title: "Code"
        },
        {
          dataIndex: "title",
          title: "Title"
        },
        {
          dataIndex: "description",
          title: "Description"
        },
        {
          dataIndex: "status",
          title: "Status",
          render: (_, data) => statusOptions.find(s => s.value === data.status)?.label
        },
        {
          dataIndex: "image_url",
          title: "Image",
          render: (_, data) => data?.image_url && <img src={data.image_url} alt={data.image_url} width={100} />
        },
        {
          dataIndex: "id",
          title: "Action",
          render: (_, data) => data.id && (
            <div className="custom-flex-row">
              <button 
                type="button"
                className="btn btn-primary"
                onClick={()=>props.history.push(`/helpdesk/categories/${data.id}/update`)}
              >
                Update
              </button>
            </div>
          )
        },
      ]}
      pagination={{
        itemsCountPerPage: state.limit,
        totalItemCount: state.total_data,
        onChange: (page) => dispatch({page}),
        activePage: state.page
      }}
    />
  )
}

export default CategoriesHelpdesk