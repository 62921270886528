import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import clsx from 'clsx';
import data from '../../../crud/tes.json';
import thubActions from '../../../crud/thub';
import {
  Button,
  Table,
  Modal,
  Tab,
  Tabs,
  Image,
  Badge,
} from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import PrettyJSON from 'json-stringify-pretty-compact';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
require('bootstrap/scss/bootstrap.scss');
require('./userlist.scss');

const history = createBrowserHistory();

const StatusData = (id) => {
  switch (id) {
    case 1:
      return 'Active';
    case 0:
      return 'Pending';
    case -1:
      return 'Blocked';
    default:
      return 'Pending';
  }
};

export default class ThubUserList extends React.Component {
  history(id) {
    window.open('/thub/' + id + '/log-history');
    // history.push("/user/" + id + "/history-level-1");
    // window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      data: data,
      loading: false,
      loadingDataModal: false,
      totalData: 0,
      thubUser: [],
      name: '',
      userThubDetail: [],
      postPerPage: 10,
      orderBy: 'updated_at',
      orderSort: 'desc',
      active: 1,
      show: false,
      report: false,
      showDetail: false,
      showBlockModal: false,
      params: '',
      reason: '',
      country: 'all',
      occupation: 'all',
      occupations: [],
      KYC: [],
      blockReason: '',
      blockReasonErrorValue: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      whatsappNumber: '',
      limitData: 10,
      totalPages: 0,
    };

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({});
      obj[fieldName] = event.target.value;

      console.log(obj);
      this.setState(obj);
    };

    this.handleShowDetailUser = this.handleShowDetailUser.bind(this);
    this.handleShowBlockModal = this.handleShowBlockModal.bind(this);
    this.handleCloseBlockModal = this.handleCloseBlockModal.bind(
      this
    );
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
    this.isValidateBlockUser = this.isValidateBlockUser.bind(this);
    this.handleBlockUser = this.handleBlockUser.bind(this);
    this.submitBlockUser = this.submitBlockUser.bind(this);
  }

  handleShowDetailUser(params) {
    this.setState({ showDetail: true, params: params });
    this.getDetailUser(params);
  }

  handleShowBlockModal(params) {
    this.setState({ showBlockModal: true, params: params });
    this.getDetailUser(params);
    // this.getDataLiveness(params);
  }

  handleCloseBlockModal() {
    this.setState({ showBlockModal: false });
  }

  handleCloseDetail() {
    this.setState({ showDetail: false });
    this.checkParams();
  }

  getToken() {
    let token = localStorage.getItem('token');
    return token;
  }

  async checkParams() {
    let params = `?page=${this.state.active}&limit=${this.state.limitData}&email=${this.state.emailAddress}&first_name=${this.state.firstName}&last_name=${this.state.lastName}&whatsapp_number=${this.state.whatsappNumber}`;

    this.setState({
      loading: true,
    });

    thubActions
      .fetchThubUserList(this.getToken(), params)
      .then((res) => {
        this.setState({
          totalData: res.data.data.totalData,
          thubUser: res.data.data.users,
          postPerPage: res.data.data.totalDataOnPage,
          totalPages: res.data.data.totalPages,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          thubUser: [],
          loading: false,
        });
        console.log(e.response);
        if (e.response) {
          if (
            e.response.data.code === 401 ||
            e.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  onSortChange = async (name) => {
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });

    this.checkParams();
  };

  changeFilter = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, active: 1 },
      () => {
        this.checkParams();
      }
    );
  };

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber;
    this.setState(
      {
        active: activePage,
      },
      () => {
        this.checkParams();
      }
    );
  };

  async getDetailUser(params) {
    this.setState({
      loadingDataModal: true,
    });
    thubActions
      .fetchThubUserDetail(this.getToken(), params)
      .then((res) => {
        this.setState({
          userThubDetail: res.data.data,
          loadingDataModal: false,
        });
      })
      .catch((e) => {
        this.setState({
          userThubDetail: [],
          loadingDataModal: false,
        });
        if (e.response) {
          if (
            e.response.data.code === 401 ||
            e.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        } else {
          console.log(e);
        }
      });
  }

  componentDidMount() {
    this.checkParams();
  }

  isValidateBlockUser() {
    if (this.state.blockReason === '') {
      this.setState({
        blockReasonErrorValue: 'Reason is Required',
      });
      return false;
    }

    this.setState({
      blockReasonErrorValue: '',
    });
    return true;
  }

  handleBlockUser = (event) => {
    let blockUserData = {};
    if (this.isValidateBlockUser()) {
      blockUserData = {
        reason_block: this.state.blockReason,
      };

      this.submitBlockUser(
        blockUserData,
        this.state.userThubDetail.user_id
      );
    }
    event.preventDefault();
  };

  submitBlockUser(data, id) {
    this.setState({
      loading: true,
    });

    thubActions
      .fetchThubBlockUser(this.getToken(), data, id)
      .then((res) => {
        if (res.data.code === 200) {
          toast.success('Update success');
        }
        this.setState({
          showBlockModal: false,
          blockReason: '',
        });
        this.checkParams();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          showBlockModal: false,
        });
        if (e.response) {
          if (
            e.response.data.code === 401 ||
            e.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  getBadgeColor(id) {
    switch (id) {
      case 0:
        return 'info';
      case 1:
        return 'success';
      case -1:
        return 'danger';

      default:
        return 'primary';
    }
  }

  getDateTime(date) {
    const fmtDate = moment(date).format('DD MMM YYYY');

    return fmtDate;
  }

  render() {
    return (
      <div style={{ fontSize: '11px' }} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body pddmdForce">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By First Name
                  </label>
                  <input
                    type="text"
                    className="form-control mr-3"
                    name="firstName"
                    placeholder="eg: Jane"
                    value={this.state.firstName}
                    onChange={this.changeFilter}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control mr-3"
                    name="lastName"
                    placeholder="eg: Doe"
                    value={this.state.lastName}
                    onChange={this.changeFilter}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Email
                  </label>
                  <input
                    type="text"
                    className="form-control mr-3"
                    name="emailAddress"
                    placeholder="eg: user@user.com"
                    value={this.state.emailAddress}
                    onChange={this.changeFilter}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Whatsapp
                  </label>
                  <input
                    type="number"
                    className="form-control mr-3"
                    name="whatsappNumber"
                    placeholder="eg: 085675950285"
                    value={this.state.whatsappNumber}
                    onChange={this.changeFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="paddupgrade">
                <div className="userlist-menu__top">
                  <select
                    className="form-control userlist-limit"
                    onChange={this.changeFilter}
                    name="limitData"
                  >
                    <option value={5}>5</option>
                    <option selected value={10}>
                      10
                    </option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section" style={{ marginBottom: 0 }}>
                {this.state.loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ReactLoading
                      type={'bars'}
                      color={'#0000FF'}
                      height={'2%'}
                      width={'5%'}
                    />
                  </div>
                ) : (
                  <>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className="tableheh"
                            style={{
                              verticalAlign: 'middle',
                              width: '7%',
                            }}
                          >
                            No &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{ verticalAlign: 'middle' }}
                          >
                            UID Tokocrypto &nbsp;
                            {/* <button
                              onClick={(e) =>
                                this.onSortChange('userId')
                              }
                              className="sort-button"
                            >
                              <i
                                className={`fas fa-${
                                  sortTypes[this.state.orderSort]
                                    .class
                                }`}
                              />
                            </button> */}
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            First Name &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Last Name &nbsp;
                            <br />
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Email &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Whatsapp &nbsp;
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Status &nbsp;
                          </th>

                          <th
                            className="center tableheh"
                            style={{ textAlign: 'left' }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.thubUser &&
                          this.state.thubUser.length === 0 && (
                            <tr style={{ textAlign: 'center' }}>
                              <td colSpan={8}>
                                No records data found
                              </td>
                            </tr>
                          )}
                        {this.state.thubUser &&
                          this.state.thubUser.length > 0 &&
                          this.state.thubUser.map((row, i) => (
                            <tr key={row.user_id}>
                              <td style={{ verticalAlign: 'middle' }}>
                                {i + 1}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.user_id_toko || '-'}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.first_name || '-'}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.last_name || '-'}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.email || '-'}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.whatsapp_number || '-'}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <Badge
                                  bg={this.getBadgeColor(
                                    row.status
                                  )}
                                >
                                  {StatusData(row.status)}
                                </Badge>
                              </td>

                              <td className="center">
                                <div>
                                  <span
                                    onClick={() =>
                                      this.handleShowDetailUser(
                                        row.user_id
                                      )
                                    }
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className="btn btn-outline-primary"
                                  >
                                    View Detail
                                  </span>
                                  <br />
                                  {row.status !== 0 && (
                                    <Fragment>
                                      <span
                                        onClick={() =>
                                          this.handleShowBlockModal(
                                            row.user_id
                                          )
                                        }
                                        style={{
                                          padding: '2px',
                                          fontSize: '0.8rem',
                                          width: '100%',
                                          marginBottom: '5px',
                                        }}
                                        className="btn btn-outline-danger"
                                      >
                                        {row.status === 1
                                          ? 'Block User'
                                          : 'Unblock User'}
                                      </span>
                                      <br />
                                    </Fragment>
                                  )}
                                  <Link
                                    to={`${row.user_id}/${row.first_name}-${row.last_name}/log-history`}
                                  >
                                    <span
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                      }}
                                      className="btn btn-outline-secondary"
                                    >
                                      Log History
                                    </span>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center align-items-center pagination-data">
                      <Pagination
                        activePage={this.state.active}
                        itemsCountPerPage={this.state.postPerPage}
                        itemClass="page-item"
                        linkClass="page-link"
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={
                          this.state.postPerPage >=
                          this.state.limitData
                            ? this.state.totalPages
                            : 1
                        }
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDetail}
          onHide={this.handleCloseDetail}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.userThubDetail.last_name &&
                this.state.userThubDetail.first_name &&
                this.state.userThubDetail.first_name +
                  ` ` +
                  this.state.userThubDetail.last_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loadingDataModal ? (
              ''
            ) : (
              <>
                <Tabs
                  id="controlled-tab-example"
                  defaultActiveKey="home"
                  // onSelect={key => this.setState({ key })}
                >
                  <Tab eventKey="home" title="Detail Data">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>User ID</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.userThubDetail.user_id || '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>UID Tokocrypto</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.userThubDetail.user_id_toko ||
                              '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>KYC Level</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.userThubDetail.kyc_level}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>First Name</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.userThubDetail.first_name ||
                              '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Last Name</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.userThubDetail.last_name ||
                              '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Nationality</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userThubDetail.nationality ||
                              '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Place & Date of Birth</strong>
                          </td>
                          <td colSpan="5">
                            {(this.state.userThubDetail &&
                              this.state.userThubDetail
                                .date_of_birth &&
                              this.getDateTime(
                                this.state.userThubDetail
                                  .date_of_birth
                              )) ||
                              '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userThubDetail.email || '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Profile Image</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userThubDetail &&
                            this.state.userThubDetail.image ? (
                              <Image
                                thumbnail
                                src={this.state.userThubDetail.image}
                                alt="profile-img"
                                style={{
                                  maxWidth: '200px',
                                  height: 'auto',
                                }}
                              />
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>TKO BSC Address</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.userThubDetail.tko_address ||
                              '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Whatsapp Number</strong>
                          </td>
                          <td colSpan="5">
                            {
                              this.state.userThubDetail
                                .whatsapp_number
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Community Name</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.userThubDetail
                              .community_name || '-'}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>
                              Are you also interested in hosting
                              events at T-Hub?
                            </strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userThubDetail
                              .interested_hosting
                              ? 'Yes'
                              : 'No'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Status</strong>
                          </td>
                          <td colSpan="5">
                            {StatusData(
                              this.state.userThubDetail.status
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Block Reason</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.userThubDetail.reason_block ||
                              '-'}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab>
                  {/* <Tab eventKey="asliri" title="Asliri">
                    <AceEditor
                      width="50"
                      mode="json"
                      theme="github"
                      readOnly={true}
                      // value={PrettyJSON(obj)}
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Tab> */}
                </Tabs>
              </>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showBlockModal}
          onHide={this.handleCloseBlockModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.userThubDetail.status === 1
                ? `Block ${this.state.userThubDetail.first_name} ${this.state.userThubDetail.last_name}`
                : `Unblock ${this.state.userThubDetail.first_name} ${this.state.userThubDetail.last_name}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              className="m-login__form m-form"
              onSubmit={this.handleBlockUser}
            >
              <div>
                <div
                  className="form-group"
                  style={{ marginBottom: '15px' }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label={`Please write user's ${
                      this.state.userThubDetail.status === 1
                        ? 'block'
                        : 'unblock'
                    } reason here...`}
                    margin="normal"
                    className="kt-width-full"
                    name="reason"
                    value={this.state.blockReason}
                    onChange={this.handleChange('blockReason')}
                  />
                  <span className="required">
                    {this.state.blockReasonErrorValue}
                  </span>
                </div>
              </div>

              <div
                className="kt-login__actions"
                style={{
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <Button
                  variant="danger"
                  onClick={this.handleCloseBlockModal}
                >
                  Cancel
                </Button>
                <Button
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': this
                        .state.loading,
                    }
                  )}`}
                  onClick={this.handleBlockUser}
                >
                  {this.state.userThubDetail.status === 1
                    ? 'Block'
                    : 'Unblock'}
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
