import axios from 'axios'
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import { ResponseAPI } from './global-types';
import { BursaResponse } from './compliance-types';

const history =  createBrowserHistory();

const newInstance = axios.create({
  // baseURL: process.env.REACT_APP_ENV === 'demo' ? '/tax-service' : "https://trade.tokocrypto.com/"
  baseURL: process.env.REACT_APP_ENV === 'production' ? 'https://trade.tokocrypto.com/' : 'https://trade-demo.tcdx.id/',
})

newInstance.interceptors.request.use((config) => {
  if(config.headers) {
    config.headers.Authorization = localStorage.getItem("token");
    config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
  }
  return config
})

newInstance.interceptors.response.use(config => config, (err) => {
  if(err.response){
    if(err.response.status === 401){
      toast.error("token has expired");
      localStorage.clear();
      history.push("/auth");
      window.location.reload();
    }
    else{
      toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
    }
  }

  return Promise.reject(err)
})

export const getBursaReport = () => newInstance.get<ResponseAPI<Array<BursaResponse>>>("admin/trade/bursa-reporting/list")