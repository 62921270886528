import React from 'react';
import MenuSection from './MenuSection';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItem from './MenuItem';

export default class MenuSubmenu extends React.Component {
  render() {
    const { item, currentUrl, layoutConfig } = this.props;
    let rule = localStorage.getItem('rule')?.split(',') || [];

    return (
      <ul className='kt-menu__subnav'>
        {item.submenu.map((child, index) => {
          const finded = rule.some((val) => child?.roles?.includes(val));
          if(finded) return (
            <React.Fragment key={index}>
              {child.section && (
                <MenuSection
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.separator && (
                <MenuItemSeparator
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.title && (
                <MenuItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                />
              )}
            </React.Fragment>
          );
        })}
      </ul>
    );
  }
}
