import instance from './axiosKycInstance';

const getListDataFinanceBCA = (token, params) => {
  return instance.get('/admin/bca/summary' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchListDataFinanceBCA = (token, params) => {
  return new Promise((resolve, reject) => {
    getListDataFinanceBCA(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getFileExcel = (token, params) => {
  return instance.get('/admin/bca/download' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchFileExcel = (token, params) => {
  return new Promise((resolve, reject) => {
    getFileExcel(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getSaldo = (token, params) => {
  return instance.get('/admin/bca/balance' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchSaldo = (token, params) => {
  return new Promise((resolve, reject) => {
    getSaldo(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  fetchListDataFinanceBCA: fetchListDataFinanceBCA,
  fetchFileExcel: fetchFileExcel,
  fetchSaldo: fetchSaldo,
};
