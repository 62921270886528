import { FC } from "react";
import { Button, Container, Stack } from 'react-bootstrap';
import { useFormik } from "formik";
import '../../scss/global.scss';
import FormWithFormik from "../../components/reusableFormWithFormik";
import ReusableChart from "../../components/reusableChart";

interface HomeTransactions {
    value?: string;
    title?: string;
}

interface HeaderAction {
    clients: "1" | "0";
    date: string;
}

const HomeMiddleware: FC = () => {
    const formik = useFormik<HeaderAction>({
        initialValues: {
            clients: '1',
            date: ''
        },
        onSubmit: values => {
            console.log(values)
        },
    })

    const charts: Array<any> = [
        { id: 1, title: 'Chart 1' },
        { id: 2, title: 'Chart 2' }
    ]

    const dummyTransactions: Array<HomeTransactions> = [
        {
            value: '200.000',
            title: 'Incoming Transaction'
        },
        {
            value: '32.000',
            title: 'Failed Transaction'
        },
        {
            value: '60.789,991.838',
            title: 'Incoming Transaction Amount'
        }
    ]

    const dummies = [
        { label: '16-10-2022', data1: 10, data2: 15 },
        { label: '17-10-2022', data1: 13, data2: 14 },
        { label: '18-10-2022', data1: 15, data2: 10 },
        { label: '19-10-2022', data1: 15, data2: 10 },
        { label: '20-10-2022', data1: 17, data2: 16 },
        { label: '21-10-2022', data1: 13, data2: 15 },
        { label: '22-10-2022', data1: 5, data2: 13 },
        { label: '23-10-2022', data1: 3, data2: 11 },
        { label: '24-10-2022', data1: 6, data2: 10 },
    ]


    return (
        <>
            <Container fluid className="box-card bg-white p-3 mx-2">
                <form onSubmit={formik.handleSubmit} className="form-search">
                    <Stack direction="horizontal" gap={3}>
                        <FormWithFormik
                            type='DROPDOWN'
                            name='clients'
                            config={formik}
                            options={[
                                { label: "Client a", value: "0" },
                                { label: "Client b", value: "1" },
                            ]}
                        />
                        <FormWithFormik
                            type='DATE'
                            name='date'
                            config={formik}
                            placeholderText="Select Date"
                        />
                        <Button variant="outline-success" type="submit">Search</Button>
                    </Stack>
                </form>
            </Container>
            <Container fluid className="my-4">
                <Stack direction="horizontal" gap={3} className='flex-wrap'>
                    {dummyTransactions.map((data, i) => (
                        <div
                            key={i}
                            className="box-card bg-white px-5 py-5 pb-1 col"
                            style={{ height: '200px' }}
                        >
                            {i === 2 ? <p className="text-desc text-left m-0">IDR</p> : <br />}
                            <div className="text-center">
                                <h1 className="mb-5 font-bold" style={{ color: i === 1 ? '#BC0505' : '' }}>{data.value}</h1>
                                <p className="text-desc">{data.title}</p>
                            </div>
                        </div>
                    ))}
                </Stack>
            </Container>
            {charts.map(list => (
                <Container fluid key={list.id} className="box-card bg-white p-3 mx-2 my-4">
                    <ReusableChart
                        loading={false}
                        datas={[
                            {
                                datasets: {
                                    backgroundColor: 'red',
                                    data: dummies.map((d) => ({ x: d.label, y: d.data1 }))
                                },
                                yAxes: {
                                    scaleLabel: {
                                        display: false
                                    },
                                    ticks: {
                                        callback: function (val, index) {
                                            return val;
                                        },
                                        beginAtZero: true
                                    }
                                },
                            },
                            {
                                datasets: {
                                    backgroundColor: 'blue',
                                    data: dummies.map((d) => ({ x: d.label, y: d.data2 }))
                                },
                                yAxes: {
                                    scaleLabel: {
                                        display: false
                                    },
                                    ticks: {
                                        callback: function (val, index) {
                                            return val;
                                        },
                                        beginAtZero: true
                                    }
                                }
                            }
                        ]}
                        title={list.title}
                        labels={dummies.map(d => d.label)}
                    />
                </Container>
            ))}
        </>
    )
}

export default HomeMiddleware;