import axiosInstance from '../axios_config';

export const createLeaderboards = (payload) => {
  return axiosInstance().post('/admin/leaderboard/create', payload);
};
export const getLeaderboards = (params) => {
  return axiosInstance().get('/admin/leaderboard', { params });
};
export const getLeaderboardById = (id) => {
  return axiosInstance().get('/admin/leaderboard/' + id);
};
export const updateLeaderboardById = (id, payload) => {
  return axiosInstance().patch('/admin/leaderboard/' + id, payload);
};
export const deleteLeaderboardById = (id) => {
  return axiosInstance().delete('/admin/leaderboard/' + id);
};
export const importCsvCoin = (payload) => {
  return axiosInstance().post('/admin/leaderboard/import-pairing', payload);
};
export const downloadImportCsvCoin = () => {
  return axiosInstance().get('/admin/leaderboard/download-all-pairing');
};
export const downloadTemplateCsvCoin = () => {
  return axiosInstance().get('/admin/leaderboard/download-template-pairing');
};
