import ReactSelect, { Props, CSSObjectWithLabel } from "react-select"
export interface ReusableRadioOption<T extends string> {
  type: "OPTION",
  label: string;
  value: T;
}

export interface ReusableRadioSelect extends Props {
  type: "DROPDOWN"
}

export interface ReusableRadioListProps<T extends string> {
  options: Array<ReusableRadioOption<T> | ReusableRadioSelect>;
  value: T;
  onChange: (nv: T) => void;
}

const ReusableRadioList = <T extends string>(props: ReusableRadioListProps<T>) => {
  const optionRenderer = (option: ReusableRadioOption<T> | ReusableRadioSelect, key: number) => {
    if(option.type === "DROPDOWN") return (
      <li key={key} style={{padding: 0, border: 'none'}}>
        <ReactSelect 
          options={option.options}
          styles={{
            container: (base) => ({
              ...base,
              minWidth: 150,
            } as CSSObjectWithLabel),
            control: (base) => ({
              ...base,
              borderRadius: 0,
              minHeight: 52,
            } as CSSObjectWithLabel),
            indicatorSeparator: (base) => ({
              ...base,
              width: 0
            } as CSSObjectWithLabel)
          }}
          classNames={{
            control: (state) => {
              return (state.selectProps.value as unknown as ReusableRadioOption<T>)?.value === props.value ? "custom-filter-list__active" : "custom-filter-list__inactive"
            },
            singleValue: (state) => {
              return (state.selectProps.value as unknown as ReusableRadioOption<T>)?.value === props.value ? "custom-filter-list__text_active" : ""
            },
            indicatorsContainer: (state) => {
              return (state.selectProps.value as unknown as ReusableRadioOption<T>)?.value === props.value ? "custom-filter-list__text_active" : ""
            }
          }}
          value={option.options?.find((opt) => (opt as unknown as ReusableRadioOption<T>).value === props.value)}
          onChange={(nv)=>props.onChange((nv as unknown as ReusableRadioOption<T>).value)}
          {...option}
        />
      </li>
    )
    else return (
      <li 
        key={key} 
        className={`${key === props.options.length - 1 ? "custom-filter-list__last-item" : ""} ${option.value === props.value ? "custom-filter-list__active" : "custom-filter-list__inactive"}`}
        onClick={()=>props.onChange(option.value)}
      >
        {option.label}
      </li>
    )
  }
  return (
    <ul className="custom-filter-list">
      {props.options.map((option, key) => optionRenderer(option, key))}
    </ul>
  )
}

export default ReusableRadioList