import { useFormik } from "formik";
import { FC, Fragment, useEffect, useReducer } from "react";
import { HelpdeskWrapper } from ".";
import ReusableFormWithFormik, { ReactSelectDropdown } from "../../../components/reusableFormWithFormik";
import ReusableTable from "../../../components/reusableTable";
import ReusableTimeline from "../../../components/reusableTimeline";
import { Modal } from "react-bootstrap";
import { statusRequestOptions, TrackingTicketResponse } from "../../../crud/helpdesk-types";
import { publicGetTicketsRequest } from "../../../crud/helpdesk"
import { globalDateTime } from "../../../helper";
import { RouteComponentProps } from "react-router";
import { CSSObjectWithLabel } from "react-select"

interface InitialState {
  tickets: Array<TrackingTicketResponse>;
  selectedTicket?: TrackingTicketResponse;
}

interface IFormTicket {
  searchbar: Array<ReactSelectDropdown>;
}

const initialState: InitialState = {
  tickets: []
}

const UnauthHelpdeskTicketStatus: FC<RouteComponentProps<{unique_code?: string}>> = (props) => {
  const myURL = new URL(window.origin + props.location.pathname + props.location.search);
  const unique_code = myURL.searchParams.get('unique_code') ?? props.match?.params?.unique_code

  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  const formik = useFormik<IFormTicket>({
    initialValues: {
      searchbar: []
    },
    onSubmit: (vals) => {
      if(vals.searchbar.length > 0) {
        publicGetTicketsRequest({
          tiket_id: vals.searchbar.map(s => s.value ?? "")
        })
          .then(res => dispatch({tickets: res.data.data}))
          .catch(err => console.log(err))
      }
    }
  })
  useEffect(()=>{
    if(formik.values.searchbar.length > 0) {
      publicGetTicketsRequest({
        tiket_id: formik.values.searchbar.map(s => s.value ?? "")
      })
        .then(res => dispatch({tickets: res.data.data}))
        .catch(err => console.log(err))
    }
  },[formik.values.searchbar])
  useEffect(()=>{
    if(unique_code) formik.setFieldValue("searchbar", [{label: unique_code, value: unique_code}])
  },[unique_code])
  return (
    <HelpdeskWrapper
      bannerExtra={
        <div className="custom-flex-row-space-between-center gap-2" style={{width: 600}}>
          <ReusableFormWithFormik 
            name="searchbar"
            type="CREATABLE_MULTI_SELECT"
            config={formik}
            styles={{
              placeholder: (base) => ({
                ...base,
                fontSize: 14
              } as CSSObjectWithLabel)
            }}
          />
        </div>
      }
    >
      <ReusableTable 
        dataSources={state.tickets}
        columns={[
          {
            title: "Ticket No.",
            dataIndex: "tiket_no",
          },
          {
            title: "Unique Code",
            dataIndex: "uniqe_code",
          },
          {
            title: "Request Date",
            dataIndex: "request_date",
            render: (_, data) => globalDateTime(data.request_date)
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (_, data) => data.status.replace(/_/g, " ")
          },
          {
            title: "Action",
            dataIndex: "tiket_no",
            render: (_, data) => data.history_request.length > 0 && (
              <button className="btn btn-primary" onClick={()=>dispatch({selectedTicket: data})}>History Log</button>
            )
          },
        ]}
      />
      <Modal
        show={!!state.selectedTicket}
        onHide={()=>dispatch({selectedTicket: undefined})}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Request History Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReusableTimeline 
            timelines={state.selectedTicket?.history_request.map(h => {
              const afterFinder = statusRequestOptions.find(o => o.value === h.after_status)
              const beforeFinder = statusRequestOptions.find(o => o.value === h.before_status)
              return { 
                icon:<i className={afterFinder?.icon} />, 
                title: afterFinder?.label, 
                subTitle: h.created_at ? globalDateTime(h.created_at) : undefined, 
                desc: <Fragment>{beforeFinder?.label} &rarr; {afterFinder?.label}</Fragment>
              }
            }) ?? []}
          />
        </Modal.Body>
      </Modal>
    </HelpdeskWrapper>
  )
}

export default UnauthHelpdeskTicketStatus