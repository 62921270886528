import React, { useEffect, useState, useRef } from 'react'
import ReusableListPage, { FILTER_TYPE, TRIGGER_BTN_TYPE } from '../../components/reusableListPage/index'
import { Badge, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { deleteBank, getBanks } from '../../otc_services/resources/bank/bank_service';
import { debounce } from 'lodash'
import { allBanks } from './bankTransferAdd';

export const bankStatus = [
  { id: 0, label: "DISABLED", theme: "danger" },
  { id: 1, label: "ENABLED", theme: "success" },
]

const BankTransfer = () => {
  const [loading, setLoading] = useState(false)
  const [dataSources, setDataSources] = useState([])

  const [statusSelected, setStatusSelected] = useState(null)
  const [keywords, setKeywords] = useState("")

  const [activePage, setActivePage] = useState(1)
  const [totalItemCount, setTotalItemCount] = useState(0)

  const itemsCountPerPage = 15

  const getSelectedBank = (id) => {
    return allBanks.find(bank => bank.value === id)
  }
  const deleteHandler = (dataIndex) => {
    const findSelected = dataSources.find(data => data.id === dataIndex)
    Swal.fire({
      title: `Are you sure to delete this ${findSelected?.bank_account_number}?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Delete!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBank(dataIndex)
          .then(()=>{
            Swal.fire(
              'Success!',
              `You has been successfully ${findSelected?.bank_account_number} this request!`,
              'success'
            )
            getList(activePage, {q: keywords, status: statusSelected})
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  const getBankStatus = (dataIndex) => {
    return bankStatus.find(status => status.id === dataIndex)
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      className: "tableheh all-center",
      render: (dataIndex) => <span>{dataIndex + 1}</span>
    },
    {
      title: "Account Type",
      dataIndex: "bank_type",
      className: "tableheh",
      render: (dataIndex) => getSelectedBank(dataIndex) ? getSelectedBank(dataIndex).label : '-'
    },
    {
      title: "Account Name",
      dataIndex: "bank_account_name",
      className: "tableheh",
    },
    {
      title: "Account Number",
      dataIndex: "bank_account_number",
      className: "tableheh",
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "tableheh center",
      render: (dataIndex) => (getBankStatus(dataIndex) &&
        <Badge bg={getBankStatus(dataIndex).theme}>
          {getBankStatus(dataIndex).label}
        </Badge>
      )
    },
    {
      title: "Action",
      dataIndex: "id",
      className: "tableheh center",
      render: (dataIndex) => (
        <ButtonToolbar style={{ justifyContent: 'center'}}>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-top`}>
                Edit
              </Tooltip>
            }
          >
            <Link
              to={{
                pathname: `/otc-finance/bank-transfer/${dataIndex}/edit`
              }}
              className="kt-link">
              <span className="btn-action">
                <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-success" />
              </span>
            </Link>
          </OverlayTrigger>
          &nbsp; | &nbsp;
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-top`}>
                Delete
              </Tooltip>
            }
          >
            <span className="btn-action" onClick={()=>deleteHandler(dataIndex)}>
              <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
            </span>
          </OverlayTrigger>
        </ButtonToolbar>
      )
    },
  ]

  const filters = [
    {
      type: "INPUT_TEXT",
      onChange: (evt) => setKeywords(evt.target.value),
      value: keywords,
      placeholder: "Search by Account No."
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: "- Status -", value: null },
        ...bankStatus.map(status => ({
          label: status.label, value: status.id.toString()
        }))
      ],
      value: statusSelected,
      onChange: (evt) => setStatusSelected(evt.target.value)
    },
  ]
  const triggerBtns = [
    {
      type: "BUTTON",
      label: "Search",
      onClick: () => getList(1, {q: keywords, status: statusSelected})
    },
    {
      type: "LINK",
      label: "Create Transfer",
      to: "/otc-finance/bank-transfer/add"
    },
  ]

  const getList = (page, params) => {
    setLoading(true)
    getBanks({limit: itemsCountPerPage, page, ...params})
      .then((res) => {
        res.data.data.data.forEach((d, index) => {
          d.no = ((page - 1) * itemsCountPerPage) + index
        })
        setDataSources(res.data.data.data)
        setTotalItemCount(res.data.data.total_data)
      })
      .catch((err)=>{
        console.log(err)
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  const debounceProcess = useRef(debounce((newKeywords, newStatusSelected) => {
    getList(1, {q: newKeywords, status: newStatusSelected})
  }, 1000))

  const filterListener = () => debounceProcess.current(keywords, statusSelected)

  useEffect(filterListener, [keywords, statusSelected])

  return (
    <ReusableListPage 
      filters={filters}
      triggerBtns={triggerBtns}
      loading={loading}
      dataSources={dataSources}
      columns={columns}
      pagination={{
        activePage,
        itemsCountPerPage,
        totalItemCount,
        onChange: (pageNumber) => {
          getList(pageNumber, {q: keywords, status: statusSelected})
          setActivePage(pageNumber)
        }
      }}
    />
  )
}

export default BankTransfer