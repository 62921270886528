import moment from "moment";
import { hashSync, genSaltSync, compareSync } from 'bcryptjs'
import { DependencyList, useEffect, useReducer, useRef, useState } from "react";
import * as XLSX from 'xlsx'
import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";

export const checkPassPrompt = (value: string) => {
  const hash = hashSync('salamtothemoon2020', genSaltSync(10));

  return compareSync(value, hash);
};

export const obj2ParamStr = (obj: object) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

export const urlToImageFile = (url: string) => {
  return new Promise((resolve) => {
    const getUrlWithoutQueryParam = url.split('?')[0];
    const getFile = getUrlWithoutQueryParam.split('/').pop();
    const getFileName = getFile?.split('.')[0] ?? 'default';
    const getFormatFile = getFile?.split('.').pop();

    const img = new Image();
    img.src = url;
    img.setAttribute('crossOrigin', 'Anonymous');
    img.onload = function () {
      const base64 = getBase64Image(img);
      resolve(btof(base64, getFileName, getFormatFile?.toLowerCase() || ''));
    };
  });
};

export const getBase64Image = (img: HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0, img.width, img.height);
  const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
  return canvas.toDataURL('image/' + ext);
};

export const btof = (data: string, fileName: string, formatFile: string) => {
  const dataArr = data.split(',');
  const byteString = Buffer.from(dataArr[1], 'base64').toString('binary');
  const options: FilePropertyBag = {
    type: `image/${formatFile}`,
    endings: 'native',
  };
  const u8Arr = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    u8Arr[i] = byteString.charCodeAt(i);
  }
  return new File([u8Arr], fileName + `.${formatFile}`, options);
};

export const isValidHttpUrl = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const numFormatter = (n: string | number, decimalSymbol = '.') => {
  if (Number(n) > 0) {
    const splitNumber = String(n).split(decimalSymbol);
    if (splitNumber.length > 1)
      return (
        splitNumber[0].replace(/(.)(?=(\d{3})+$)/g, '$1.') +
        `,${splitNumber[1]}`
      );
    else return String(n).replace(/(.)(?=(\d{3})+$)/g, '$1.');
  } else {
    const splitNumber = String(Number(n) * -1).split(decimalSymbol);
    if (splitNumber.length > 1)
      return (
        '-' +
        splitNumber[0].replace(/(.)(?=(\d{3})+$)/g, '$1.') +
        `,${splitNumber[1]}`
      );
    else return String(n).replace(/(.)(?=(\d{3})+$)/g, '$1.');
  }
};

export const constantDateFormat = (date: string | Date, isUTC = true) => {
  if (isUTC) return moment(new Date(date)).utc().format('DD-MM-Y hh:mm A');
  else return moment(new Date(date)).format('DD-MM-Y hh:mm A');
};

export const getFileFromURL = (url: string) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        let objectURL = URL.createObjectURL(blob);
        let myImage = new Image();
        myImage.src = objectURL;
        resolve(myImage);
      })
      .catch((err) => reject(err));
  });
};

export const getImageOssURL = () => {
  if (process.env.REACT_APP_ENV === 'demo')
    return 'https://tcdx-otc-demo.oss-ap-southeast-5.aliyuncs.com/';
  else return 'https://tcdx-otc-prod.oss-ap-southeast-5.aliyuncs.com/';
};

export const redirectToNewPage = (url: string, isNewTab: boolean) => {
  const link = document.createElement('a');
  link.href = url;
  if (isNewTab) link.target = '_blank';
  link.click();
  link.remove();
};

export const convertTZ = (date: string | Date, tzString = 'Asia/Jakarta') => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    })
  );
};

export const duplicateCount = (arrayString: Array<string>) => {
  let temp: { [key: string]: number } = {};

  arrayString.forEach((str, index) => {
    if (index > 0 && typeof temp[str] === 'number') temp[str] += 1;
    else temp[str] = 1;
  });

  return temp;
};

export const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const debounce = <T extends (...args: any) => any>(
  func: T,
  wait?: number
) => {
  let timeout: NodeJS.Timeout | number | undefined;
  return (...args: any) => {
    const later = () => {
      timeout = undefined;

      func(...args);
    };
    clearTimeout(timeout as number | undefined);

    timeout = setTimeout(later, wait);
  };
};

export const useDebounce = <T extends Array<any>>(
  func: (...args: [...T]) => void,
  args: T,
  wait?: number,
  funcBeforeDebounce?: () => void
) => {
  const debounceProcess = useRef(debounce(func, wait));

  const listener = () => {
    if (funcBeforeDebounce) funcBeforeDebounce();
    debounceProcess.current(...args);
  };

  useEffect(listener, [...args]);
};

export const useDebounceDidMount = <T extends Array<any>>(
  func: (...args: [...T]) => void,
  args: T,
  wait?: number,
  funcBeforeDebounce?: () => void,
  initialValue?: boolean,
) => {
  const debounceProcess = useRef(debounce(func, wait));

  const listener = () => {
    if (funcBeforeDebounce) funcBeforeDebounce();
    debounceProcess.current(...args);
  };

  useDidMountEffect(listener, [...args], initialValue);
};

export const throttle = <T extends (...args: any) => any>(
  func: T,
  wait?: number
) => {
  let shouldWait = false;
  let waitingArgs: any;

  const timeoutFunc = () => {
    if (waitingArgs === null) shouldWait = false;
    else {
      func(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, wait);
    }
  };

  return (...args: any) => {
    if (shouldWait) return;

    func(...args);
    shouldWait = true;

    setTimeout(timeoutFunc, wait);
  };
};

export const changeTimezone = (date: Date, ianatz?: string) => {
  // source: https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone

  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    })
  );

  const diff = date.getTime() - invdate.getTime();

  return new Date(date.getTime() - diff);
};

export const getCurrentTimezoneFromJakarta = (date: string | Date) => {
  const convertToISO = (() => {
    if (
      typeof date === 'string' &&
      moment(moment(date + '+07').toISOString()).isValid()
    )
      return moment(date + '+07').toISOString();
    else return new Date(date).toISOString() + '+07';
  })() as string;
  const newDate = new Date(
    typeof date === 'string' ? new Date(convertToISO) : date
  );

  return changeTimezone(
    newDate,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
};

export const getDiffDateByJakartaTime = (date: string | Date) => {
	return changeTimezone(convertTZ(date, Intl.DateTimeFormat().resolvedOptions().timeZone), "Asia/Jakarta")
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeEveryWord = (sentence: string) => {
	const words = sentence.split(" ")
	const newWords = words.map(word => capitalizeFirstLetter(word))
	return newWords.join(" ")
}

export const globalDateTime = (date: string | Date, isUtc?: boolean) => {
  if(isUtc) return moment(date).utc().format("DD-MM-YYYY HH:mm A")
  else return moment(date).format("DD-MM-YYYY HH:mm A")
}

export const globalDOBTime = (date: string | Date) => {
  return moment(date).format("DD-MM-YYYY")
}

export const downloadFile = (url: string, fileName: string) => {
  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  aElement.href = url;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(url);
}

export const convertBlobToBase64 = (blob: Blob) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

export const convertToXLSX = (data: Array<Array<string>>, documentName?: string, sheetName?: string) =>{
  const workSheet = XLSX.utils.aoa_to_sheet(data)
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName ?? "Sheet 1")
  return XLSX.writeFile(workBook, documentName ?? 'defaultName.xlsx')
}

export const convertToCSV = (data: Array<Array<string>>, documentName?: string) => {
  let csvContent = "data:text/csv;charset=utf-8," + data.map(e => e.join(",")).join("\n");
  const encodedUri = encodeURI(csvContent);
  downloadFile(encodedUri, documentName ?? "my_data.csv")
}

export const smoothScroll = (elementId: string, headerHeight: number) => {
	const scrollElement = document.getElementById(elementId)?.offsetTop || 0;
	window.scrollTo({ top: scrollElement-headerHeight, behavior: 'smooth'});
}

export const middleEllipsisText = (str: string, start: number = 6, end: number = 3, ellipsisText: string = '...') => {
	if (str.length > (start+end)) {
		return str.slice(0, start) + ellipsisText + str.slice(str.length - end, str.length);
	}
	return str;
}

export const isStringJson = (str: string) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const useDidMountEffect = (func: () => void, deps?: DependencyList, initialValue?: boolean) => {
  const didMount = useRef(initialValue ?? false);
  useEffect(() => {
    if (didMount.current) func(); 
    else didMount.current = true;
  }, deps);
};

export const objFormData = (obj: object) => {
  const formData = new FormData()

  Object.entries(obj).forEach(([key,val]) => {
    if(Array.isArray(val)) val.forEach((v) => formData.append(`${key}[]`, v))
    else formData.append(key, val)
  })

  return formData
}

export const asyncFilter = async <T, U>(arr: Array<T>, predicate: (value: T, index: number, array: T[]) => U) => {
	const results = await Promise.all(arr.map(predicate));

	return arr.filter((_v, index) => results[index]);
}

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((i) => {
    return num >= i.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const paginateFromArray = <T>(array: Array<T>, page_size: number, page_number: number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const sanitizedHtml = (code: string) => {
  return code.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
}

export const useMultipleState = <T extends object>(initialState: T) => {
  return useReducer((s: T, ns: Partial<T>) => ({...s, ...ns}), initialState)
}

export type PercentageItem<T> = {
  percentage: number;
} & T

export const getRandomItemByPercentage = <T>(items: Array<PercentageItem<T>>): PercentageItem<T> | null => {
  if(items.length > 0) {
    const totalPercentage = items.reduce((sum, item) => sum + item.percentage, 0);
    const randomNumber = Math.random() * totalPercentage;
    
    let accumulatedPercentage = 0;
    let selectedItem: PercentageItem<T> | null = null;
    
    items.forEach((item) => {
      accumulatedPercentage += item.percentage;
      
      if (randomNumber < accumulatedPercentage && selectedItem === null) {
        selectedItem = item;
      }
    });
    
    return selectedItem;
  }
  else return null
}

export const useSocketUrl = (url: string, opts?: Partial<ManagerOptions & SocketOptions>) => {
  const [socket, setSocket] = useState<Socket | undefined>(undefined)

  useEffect(()=>{
    const sct = io(url, opts)
    
    setSocket(sct)
  }, [url])

  return socket
}

export const useIntersect = <T extends HTMLElement>(opts?: IntersectionObserverInit) => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | undefined>(undefined)
  const node = useRef<T>(null);
  const observer = useRef<IntersectionObserver>(new IntersectionObserver(([e]) => updateEntry(e), opts))

  useEffect(() => {
    if(node.current) observer.current.observe(node.current);
    return () => observer.current.disconnect();
  }, []);

  return {node, entry};
}

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const setMonth = (date: Date, month: number) => {
  date.setMonth(month)
  return date
}

export const getInitialString = (str: string, end?: number) => {
  let words = str.toUpperCase().split(" ").map(s => s.slice(0,1))
  if(end) words = words.slice(0, end)
  return words.join("")
}

export const camelCaseToWords = (text: string) => text.replace(/([a-z0-9])([A-Z])/g, '$1 $2')