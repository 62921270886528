import React, { useState } from 'react'
import ReusableListPage, { FILTER_TYPE, TRIGGER_BTN_TYPE } from '../../components/reusableListPage/index'
import { Badge, Button } from "react-bootstrap";

const SalesRefferalCodeLog = () => {
  const [keywords, setKeywords] = useState("")
  
  const [loading, setLoading] = useState(false)
  const [withdrawStatusLoading, setWithdrawStatusLoading] = useState(false)

  const [dataSources, setDataSources] = useState([])
  const [withdrawStatusDataSources, setWithdrawStatusDataSources] = useState([])

  const [activePage, setActivePage] = useState(1)
  const [withdrawStatusActivePage, setWithdrawStatusActivePage] = useState(1)
  const [itemsCountPerPage, setItemsCountPerPage] = useState(15)
  const [withdrawStatusItemsCountPerPage, setWithdrawStatusItemsCountPerPage] = useState(15)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [withdrawStatusTotalItemCount, setWithdrawStatusTotalItemCount] = useState(0)

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      className: "tableheh",
      render: (dataIndex, data, index) => <span>{index + 1}</span>
    },
    {
      title: "Updated Date",
      dataIndex: "updatedDate",
      className: "tableheh",
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      className: "tableheh",
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "tableheh center",
      render: () => (
        <Badge bg="success">
          Approved
        </Badge>
      )
    }
  ]

  const filters = [
    {
      type: "INPUT_TEXT",
      onChange: (evt) => setKeywords(evt.target.value),
      value: keywords,
      placeholder: "Search By Email, UID"
    },
    {
      type: "DROP_DOWN",
      options: [
        { label: "- Status -", value: "" }
      ],
      value: "",
      onChange: (evt) => {}
    },
  ]
  const triggerBtns = [
    {
      type: "BUTTON",
      label: "Search",
      onClick: () => {}
    },
    {
      type: "BUTTON",
      label: "Export",
      className: "btn btn-outline-info mr-3 font-8rem",
      onClick: () => {}
    },
  ]

  const labels = [
    {label: "UID", value: "12421"},
    {label: "Email", value: "admin@tcdx.com"},
    {label: "Amount", value: "123,456"},
    {label: "Base Currency", value: "BIDR"},
    {label: "Status", value: "Approved"},
    {label: "Withdraw Status", value: "Default"},
  ]

  return (
    <ReusableListPage 
      labels={labels}
      filters={filters}
      triggerBtns={triggerBtns}
      tabs={[
        {
          eventKey: "status",
          title: "Status",
          loading: loading,
          dataSources: dataSources,
          columns: columns,
          pagination: {
            activePage,
            itemsCountPerPage,
            totalItemCount,
            onChange: (pageNumber) => {
              console.log(pageNumber)
            }
          }
        },
        {
          eventKey: "withdrawStatus",
          title: "Withdraw Status",
          loading: withdrawStatusLoading,
          dataSources: withdrawStatusDataSources,
          columns: columns,
          pagination: {
            activePage: withdrawStatusActivePage,
            itemsCountPerPage: withdrawStatusItemsCountPerPage,
            totalItemCount: withdrawStatusTotalItemCount,
            onChange: (pageNumber) => {
              console.log(pageNumber)
            }
          }
        }
      ]}
      tabsProps={{
        defaultActiveKey: "status",
        className: "m-3"
      }}
      footerComponent={
        <div>
          <Button className="ml-2" variant="warning">Cancel</Button>
          <Button className="ml-2" variant="danger">Reject</Button>
          <Button className="ml-2" variant="success">Approve</Button>
          <Button className="ml-2" variant="success">Re-Withdraw</Button>
          <Button className="ml-2" variant="primary">Check Payment</Button>
        </div>
      }
    />
  )
}

export default SalesRefferalCodeLog