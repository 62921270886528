import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReusableListPage, { ReusableListFilter, ReusableListSecondFilter } from '../../components/reusableListPage';
import { getCurrentTimezoneFromJakarta, useDebounce } from '../../helper';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getMarketMaker, deleteMarketMaker } from '../../crud/tax-service';
import { TaxMarketMaker } from '../../crud/tax-types';
import { ReusableTableColumn } from '../../components/reusableTable';

const MarketMakerList: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSources, setDataSources] = useState<Array<TaxMarketMaker>>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [keywords, setKeywords] = useState<string>("")
  const itemsCountPerPage = 15;
  const filters: Array<ReusableListFilter> = [
    {
      type: "INPUT_TEXT",
      onChange: (evt) => setKeywords(evt.target.value),
      value: keywords,
      placeholder: "Search by keyword"
    },
  ];
  const triggerBtns: Array<ReusableListSecondFilter> = [
    {
      type: "LINK",
      label: "Create Market Maker",
      to: "/tax/market-maker/add"
    },
  ];
  const columns: Array<ReusableTableColumn<TaxMarketMaker>> = [
    {
      title: 'No',
      dataIndex: 'id',
      render: (_dataIndex, _data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'UID',
      dataIndex: 'uid',
    },
    {
      title: 'Market Maker Name',
      dataIndex: 'name',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      render: (dataIndex) => dataIndex && moment(getCurrentTimezoneFromJakarta(dataIndex.toString())).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Update At',
      dataIndex: 'updated_at',
      render: (dataIndex) => dataIndex && moment(getCurrentTimezoneFromJakarta(dataIndex.toString())).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (dataIndex) => (
        <ButtonToolbar style={{ justifyContent: 'center' }}>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}
          >
            <Link
              to={{
                pathname: `/tax/market-maker/${dataIndex}/edit`,
              }}
              className='kt-link'
            >
              <span className='btn-action'>
                <i
                  style={{ fontSize: '1rem', paddingRight: '0' }}
                  className='fas fa-pencil-alt text-success'
                />
              </span>
            </Link>
          </OverlayTrigger>
          &nbsp; | &nbsp;
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-top`}>
                Delete
              </Tooltip>
            }
          >
            <span className="btn-action" onClick={()=>deleteHandler(Number(dataIndex))}>
              <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
            </span>
          </OverlayTrigger>
        </ButtonToolbar>
      ),
    },
  ];
  const getList = (page: number, q: string) => {
    getMarketMaker({
      limit: itemsCountPerPage,
      page,
      q
    })
      .then(res => {
        if(res.data.data) {
          setDataSources(res.data.data?.data ?? [])
          setTotalItemCount(res.data.data.total_data)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(()=>setLoading(false));
  };
  const deleteHandler = (id: number) => {
    const findSelected = dataSources.find(data => data.id === id)
    Swal.fire({
      title: `Are you sure to delete this ${findSelected?.name}?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Delete!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMarketMaker(id)
          .then(()=>{
            Swal.fire(
              'Success!',
              `You has been successfully DELETE this ${findSelected?.name}!`,
              'success'
            )
              .then(()=>{
                setLoading(true)
                getList(activePage, keywords)
              })
              .catch(err => console.log(err))
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  };
  useDebounce(getList, [activePage, keywords], 400, ()=>setLoading(true));
  return (
    <ReusableListPage
      loading={loading}
      filters={filters}
      triggerBtns={triggerBtns}
      dataSources={dataSources}
      columns={columns}
      pagination={{
        activePage,
        itemsCountPerPage,
        totalItemCount,
        onChange: (pageNumber) => {
          setActivePage(pageNumber);
        },
      }}
    />
  );
};

export default MarketMakerList;