import { useEffect, useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';

export const categoryOption = [
  {
    label: 'Knowledge',
    value: 'Knowledge',
  },
  {
    label: 'Inspiration',
    value: 'Inspiration',
  },
  {
    label: 'Networking',
    value: 'Networking',
  },
];

export const getDateNow = () => {
  const dateData = moment().toDate().toISOString().split('T');
  const timeData = moment().toDate().toLocaleTimeString('id-ID').split('.');
  const dateNow = dateData[0] + 'T' + timeData[0] + ':' + timeData[1];

  return dateNow;
};

// Hook
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const getDateValue = (date) => {
  const splitDate = date.split('T');
  const splitHour = splitDate[1].split(':');
  const dateValue = splitDate[0];
  const hourValue = `${splitHour[0]}:${splitHour[1]}`;

  return `${dateValue} ${hourValue}`;
};

export const getEventsDate = (date, hour, toLocalTime) => {
  const splitDate = date.split('T');
  const dateValue = splitDate[0];
  let hourValue = hour;

  if (toLocalTime) {
    hourValue = moment(`2022-01-01 ${hourValue}`)
      .add(moment().utcOffset(), 'minute')
      .format('HH:mm');
  }

  return `${dateValue} ${hourValue}`;
};

const generalValidationSchema = {
  user_id: Yup.string().required('User must be filled'),
  event_code: Yup.string().required('Event code is required'),
  event_title: Yup.string().required('Event title is required'),
  event_tag: Yup.string().required('Event tag is required'),
  category: Yup.string().required('Category is required'),
  event_type: Yup.string().required('Event type is required'),
  event_description: Yup.string().required('Event description is required'),
  participant_quota: Yup.number()
    .required('Participant quota is required')
    .min(1, 'Minimum 1 participant')
    .positive(),
  start_date: Yup.string()
    .required('Start date is required')
    .test(
      'end-validation',
      'Start date must less than End date',
      (val, ctx) => {
        const end = ctx.parent.end_date;
        return val && end && end !== ''
          ? moment(end).isAfter(moment(val))
          : true;
      }
    ),
  end_date: Yup.string()
    .required('End date is required')
    .test(
      'end-validation',
      'End date must more than Start date',
      (val, ctx) => {
        const start = ctx.parent.start_date;

        return val && start && start !== ''
          ? moment(start).isBefore(moment(val))
          : true;
      }
    ),
  what_people_will_learn: Yup.string().required(
    'What people will learn is required'
  ),
  skill_level: Yup.string().required('Skill level is required'),
  image: Yup.mixed().test(
    'event-image-validation',
    'Event image is required',
    (values, ctx) => {
      const old_image = ctx.parent.old_image;

      return old_image ? true : values && values !== '';
    }
  ),
};

const additionalValidationSchema = {
  proposal: Yup.mixed().required('Proposal is required'),
  speakers: Yup.array()
    .of(
      Yup.object().shape({
        fullname: Yup.string().required('Speakers fullname is required'),
        tittle: Yup.string().required('Speakers title is required'),
        about: Yup.string().required('Speakers about is required'),
        link_to_personal_data: Yup.string(),
        // .required(
        //   'Speakers link is required'
        // )
        portrait_image: Yup.mixed()
          .test(
            'file-type-speakerimage-validation',
            'File type must image format',
            (val) => {
              if (val && val.length > 0)
                return /\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i.test(
                  val[0]?.name
                );
              return true;
            }
          )
          .test(
            'file-size-speakerimage-validation',
            'Image size must less than 2mb',
            (val) => {
              if (val && val.length > 0) return val[0]?.size <= 2097152;
              return true;
            }
          ),
      })
    )
    .min(1, 'You need at least 1 speakers')
    .required(),
};

export const offlineValidationSchema = Yup.object().shape({
  ...generalValidationSchema,
  ...additionalValidationSchema,
  location_id: Yup.string().required('Location is required'),
  booking_type_id: Yup.string().required('Booking type is required'),
  seat_id: Yup.string().required('Seat is required'),
  product_code: Yup.string().required('Product code is required'),
});

export const onlineValidationSchema = Yup.object().shape({
  ...generalValidationSchema,
  ...additionalValidationSchema,
  meeting_link: Yup.array()
    .of(
      Yup.object().shape({
        link: Yup.string().required('Event meeting link is required'),
        pass_code: Yup.string(),
        platform: Yup.string(),
      })
    )
    .min(1, 'You need at least 1 meeting link')
    .required(),
});

export const locationTypeValidationSchema = Yup.object().shape({
  ...generalValidationSchema,
  ...additionalValidationSchema,
  meeting_link: Yup.array()
    .of(
      Yup.object().shape({
        link: Yup.string().required('Event meeting link is required'),
        pass_code: Yup.string(),
        platform: Yup.string(),
      })
    )
    .min(1, 'You need at least 1 meeting link')
    .required(),
  location_id: Yup.string().required('Location is required'),
  booking_type_id: Yup.string().required('Booking type is required'),
  seat_id: Yup.string().required('Seat is required'),
  product_code: Yup.string().required('Product code is required'),
});

export const editOfflineValidationSchema = Yup.object().shape({
  ...generalValidationSchema,
  location_id: Yup.string().required('Location is required'),
  booking_type_id: Yup.string().required('Booking type is required'),
  seat_id: Yup.string().required('Seat is required'),
  product_code: Yup.string().required('Product code is required'),
});

const { image, ...restValidation } = generalValidationSchema;

export const editOnlineValidationSchema = Yup.object().shape({
  ...restValidation,
  meeting_link: Yup.array()
    .of(
      Yup.object().shape({
        link: Yup.string().required('Event meeting link is required'),
        pass_code: Yup.string(),
        platform: Yup.string(),
      })
    )
    .min(1, 'You need at least 1 meeting link')
    .required(),
});

export const editLocationTypeValidationSchema = Yup.object().shape({
  ...restValidation,
  meeting_link: Yup.array()
    .of(
      Yup.object().shape({
        link: Yup.string().required('Event meeting link is required'),
        pass_code: Yup.string(),
        platform: Yup.string(),
      })
    )
    .min(1, 'You need at least 1 meeting link')
    .required(),
  location_id: Yup.string().required('Location is required'),
  booking_type_id: Yup.string().required('Booking type is required'),
  seat_id: Yup.string().required('Seat is required'),
  product_code: Yup.string().required('Product code is required'),
});
