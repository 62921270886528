import { FC, Fragment, ReactNode, useEffect, useReducer, useState } from "react";
import clsx from "clsx";
import { getActiveUser, getTotalUser, getDailyVolume, getVolume, getReport, getListTransactions, getJournal, getBidrById, getAssetById } from "../../crud/dashboard";
import moment from 'moment';
import { subDays } from "date-fns";
import { Modal, OverlayTrigger, Popover, Accordion, Button } from "react-bootstrap";
import ReactLoading from 'react-loading';
import 'react-toastify/dist/ReactToastify.css'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReusableTable, { ReusableTableColumn } from "../../components/reusableTable";
import ReusableRadioList, { ReusableRadioOption, ReusableRadioSelect } from "../../components/reusableRadioList";
import { BuySellTransactionResponse, CryptoTransactionResponse, FiatConvertResponse, FiatTransactionResponse, ListTransactionType, Total, TransactionResponse, Volume } from "../../crud/dashboard-types";
import { useDebounce, numFormatter, globalDateTime, convertToXLSX, convertToCSV, smoothScroll, middleEllipsisText } from "../../helper";
import Swal from "sweetalert2";
import ReusableChart from "../../components/reusableChart";
import ReactSelect from 'react-select'

interface AssetsEstimationList {
  label: string;
  value: ReactNode;
  type?: "bold" | "green",
  info?: string;
}

type ContentSection = {
  type: "LABEL";
  label: string;
  value: ReactNode;
  childrens?: Array<ContentSection>;
  loading?: boolean;
  tooltips?: ReactNode;
  allowedRoles?: Array<string>;
} | {
  type: "CUSTOM";
  render: ReactNode;
  loading?: boolean;
  allowedRoles?: Array<string>;
}

type DownloadType = "0" | "1"

const transactionTypes: Array<ReusableRadioOption<ListTransactionType> | ReusableRadioSelect> = [
  {
    type: "OPTION",
    label: "Buy & Sell",
    value: "buySell"
  },
  {
    type: "DROPDOWN",
    placeholder: "Withdraw",
    options: [
      {label: "Withdraw Crypto", value: "WCrypto"},
      {label: "Withdraw FIAT", value: "WFiat"},
    ]
  },
  {
    type: "DROPDOWN",
    placeholder: "Deposit",
    options: [
      {label: "Deposit Crypto", value: "DCrypto"},
      {label: "Deposit FIAT", value: "DFiat"},
    ]
  },
  {
    type: "DROPDOWN",
    placeholder: "Instant Trade",
    options: [
      {label: "Instant Trade Crypto", value: "CCrypto"},
      {label: "Instant Trade FIAT", value: "CFiat"},
    ]
  },
]

const whitelistRoles = ["compliance", "dashboard_viewer", "superuser"]

type ModalDetailType = "VIEW_DETAIL" | "UID_DETAIL"

interface ModalDetail {
  transactionType: ListTransactionType;
  type: ModalDetailType;
  contents: Array<AssetsEstimationList>;
}

interface InitialState {
  loading: boolean;
  loadingTable: boolean;
  loadingBtnXLSXTransaction: boolean;
  loadingBtnCSVTransaction: boolean;
  loadingBtnXLSXJournal: boolean;
  loadingBtnCSVJournal: boolean;
  loadingActiveUser: boolean;
  loadingVolumes: boolean;
  isDisableUIDModal: boolean;
  totalUsers?: Total;
  rangeDailyVolume?: Array<Volume>;
  rangeActiveUsers?: Total;
  dailyVolume?: Total;
  dailyActiveUser?: Total;
  startDate?: Date;
  endDate?: Date;
  type: DownloadType;
  transactionTypeSelected: ListTransactionType;
  modalDetail?: ModalDetail;
}

const initialState: InitialState = {
  loading: false,
  loadingTable: false,
  loadingBtnXLSXTransaction: false,
  loadingBtnCSVTransaction: false,
  loadingBtnXLSXJournal: false,
  loadingBtnCSVJournal: false,
  loadingActiveUser: false,
  loadingVolumes: false,
  isDisableUIDModal: false,
  startDate: subDays(new Date(), 7),
  endDate: new Date(),
  type: '0',
  transactionTypeSelected: "buySell"
}

const Dashboard: FC = () => {
  const [state, dispatch] = useReducer((v: InitialState, nv: Partial<InitialState>) => {
    return {
      ...v,
      ...nv
    }
  }, initialState)
  const limit = 6
  const [activePage, setActivePage] = useState<number>(1)
  const [totalItems, setTotalItems] = useState<number>(0)
  const [dataSources, setDataSources] = useState<Array<BuySellTransactionResponse | CryptoTransactionResponse | FiatTransactionResponse | FiatConvertResponse>>([])
  const transactionsSection = "TRANSACTIONS"

  const getActiveUserService = () =>{
    dispatch({
      loadingActiveUser: true
    })
    getActiveUser()
      .then(res => {
        dispatch({
          dailyActiveUser: res.data.data
        })
      })
      .catch(e => {
        console.log(e);
      })
      .finally(()=>{
        dispatch({
          loadingActiveUser: false
        })
      })
  }

  const getDailyVolumeService = () => {
    dispatch({
      loading: true
    })
    getDailyVolume()
      .then(res => {
        dispatch({
          dailyVolume: res.data.data,
        })
      })
      .catch(e => {
        console.log(e);
      })
      .finally(()=>{
        dispatch({
          loading: false
        })
      })
  }

  const getTotalUserService = () =>{
    dispatch({
      loading: true
    })
    getTotalUser()
      .then(res => {
        dispatch({
          totalUsers: res.data.data,
        })
      })
      .catch(e => {
        console.log(e);
      })
      .finally(()=>{
        dispatch({
          loading: false
        })
      })
  }

  const getReportService = (type: DownloadType, isJournal?: boolean) =>{
    if(isDashboardView(whitelistRoles)) {
      if(state.startDate && state.endDate) {
        if(isJournal) {
          if(type === "0") dispatch({loadingBtnCSVJournal: true})
          else dispatch({loadingBtnXLSXJournal: true})
        }
        else {
          if(type === "0") dispatch({loadingBtnCSVTransaction: true})
          else dispatch({loadingBtnXLSXTransaction: true})
        }
        const typeDown = type === "1" ? '.xlsx' : '.csv'
        const services = isJournal ? getJournal({
          start_date: dateTime(state.startDate),
          end_date: dateTime(state.endDate),
          download: "raw",
          type: state.transactionTypeSelected
        }) : getReport({
          start_date: dateTime(state.startDate),
          end_date: dateTime(state.endDate),
          download_type: "raw"
        })
        services
          .then(res => {
            if(res.data.data) {
              const headers = res.data.data.length > 0 ? Object.keys(res.data.data[0]) : []
              const datas = (res.data.data ?? []).map((d: any) => {
                let results: Array<string> = []
                headers.forEach((header) => results.push(d[header]))
                return results
              })
  
              if(type === "1") convertToXLSX([headers].concat(datas), `${isJournal ? "Journal" : "Transaction Volume"} ${moment(state.startDate).format("YYYY/MM/DD")} - ${moment(state.endDate).format("YYYY/MM/DD")}${typeDown}`, isJournal ? "Journal" : "Transaction Volume")
              else convertToCSV([headers].concat(datas), `${isJournal ? "Journal" : "Transaction Volume"} ${moment(state.startDate).format("YYYY/MM/DD")} - ${moment(state.endDate).format("YYYY/MM/DD")}${typeDown}`)
            }
          })
          .catch(e => {
            console.log(e);
          })
          .finally(()=>{
            if(isJournal) {
              if(type === "0") dispatch({loadingBtnCSVJournal: false})
              else dispatch({loadingBtnXLSXJournal: false})
            }
            else {
              if(type === "0") dispatch({loadingBtnCSVTransaction: false})
              else dispatch({loadingBtnXLSXTransaction: false})
            }
          })
      }
      else Swal.fire('Warning', 'Please fill start date and end date correctly!', 'error')
    }
  }

  const searchTrigger = (startDate: Date | undefined, endDate: Date | undefined) =>{
    if(isDashboardView(whitelistRoles)) {
      if(startDate && endDate) {
        setActivePage(1)
  
        dispatch({
          loadingVolumes: true,
          loadingActiveUser: true
        })
        getVolume({
          start_date: dateTime(startDate),
          end_date: dateTime(endDate),
        })
          .then((res) => {
            dispatch({ rangeDailyVolume: res })
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(()=>{
            dispatch({ loadingVolumes: false })
          })
  
        getActiveUser({
          start_date: dateTime(startDate),
          end_date: dateTime(endDate),
        })
          .then((res) => {
            dispatch({ rangeActiveUsers: res.data.data})
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(()=>{
            dispatch({ loadingActiveUser: false })
          })
      }
      else Swal.fire('Warning', 'Please fill start date and end date correctly!', 'error')
    }
  }

  const typeToggleTrigger = (type: ListTransactionType, startDate: Date | undefined, endDate: Date | undefined, page?: number) => {
    if(isDashboardView(whitelistRoles)) {
      if(startDate && endDate) {
        dispatch({loadingTable: true})
        getListTransactions({
          page: page ?? 1,
          limit,
          type,
          start_date: dateTime(startDate),
          end_date: dateTime(endDate),
        })
          .then((res) => {
            setTotalItems(res.data.data?.total_data ?? 0)
            setDataSources(res.data.data?.data ?? [])
          })
          .catch((err) => {
            console.log(err)
            setTotalItems(0)
            setDataSources([])
          })
          .finally(()=>{
            dispatch({loadingTable: false})
          })
      }
      else Swal.fire('Warning', 'Please fill start date and end date correctly!', 'error')
    }
  }

  useDebounce<[Date | undefined, Date | undefined]>(searchTrigger, [state.startDate, state.endDate], 400)
  useDebounce<[ListTransactionType, Date | undefined, Date | undefined, number]>(typeToggleTrigger, [state.transactionTypeSelected, state.startDate, state.endDate, activePage], 400)

  useEffect(()=>{
    getDailyVolumeService();
    getActiveUserService();
    getTotalUserService();
  }, [])
  useEffect(()=>setDataSources([]) ,[state.transactionTypeSelected])

  const dateTime = (date: Date | string, isDetailFormat?: boolean) => {
    return moment(date).utc().format(isDetailFormat ? ' ddd, DD MMM YYYY HH:mm A ' : 'DD/MM/YYYY');
  }

  const cryptoAssetSplitter = (name: string) => {
    const split = name.split("_")
    return {
      coin: split.length > 1 ? split[0] : "",
      pair: split.length > 1 ? split[1] : "",
    }
  }

  const transactionTable = () => {
    const columns = <T extends ListTransactionType>(type: T): Array<ReusableTableColumn<TransactionResponse<T>>> => {
      if(type === "buySell") return [
        {
          title: "No",
          dataIndex: "transaction",
          render: (_, _data, index) => (activePage - 1)*limit + index + 1
        },
        {
          title: "Transaction No.",
          dataIndex: "transaction",
        },
        {
          title: "Transaction Date",
          dataIndex: "transaction_date",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string)
        },
        {
          title: "User ID",
          dataIndex: "user_id",
        },
        {
          title: (
            <div className="custom-flex-row-center gap-2">
              <div>Crypto Assets</div>
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      Eg. TKO / BIDR = Buy or <br/>Sell TKO using BIDR
                    </Popover.Body>
                  </Popover>
                }
              >
                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.2002 10.4C2.4387 10.4 0.200195 8.16149 0.200195 5.39999C0.200195 2.63849 2.4387 0.399994 5.2002 0.399994C7.9617 0.399994 10.2002 2.63849 10.2002 5.39999C10.2002 8.16149 7.9617 10.4 5.2002 10.4ZM5.2002 9.39999C6.26106 9.39999 7.27848 8.97857 8.02862 8.22842C8.77877 7.47828 9.2002 6.46086 9.2002 5.39999C9.2002 4.33913 8.77877 3.32171 8.02862 2.57157C7.27848 1.82142 6.26106 1.39999 5.2002 1.39999C4.13933 1.39999 3.12191 1.82142 2.37177 2.57157C1.62162 3.32171 1.2002 4.33913 1.2002 5.39999C1.2002 6.46086 1.62162 7.47828 2.37177 8.22842C3.12191 8.97857 4.13933 9.39999 5.2002 9.39999ZM4.7002 2.89999H5.7002V3.89999H4.7002V2.89999ZM4.7002 4.89999H5.7002V7.89999H4.7002V4.89999Z" fill="#535353"/>
                </svg>
              </OverlayTrigger>
            </div>
          ),
          dataIndex: "crypto_assets",
        },
        {
          title: "Type",
          dataIndex: "type",
        },
        {
          title: "Volume",
          dataIndex: "volume",
          render: (dataIndex) => numFormatter(decimalRounding(dataIndex as unknown as string))
        },
        {
          title: "Price",
          dataIndex: "price",
          render: (dataIndex) => numFormatter(decimalRounding(dataIndex as unknown as string))
        },
        {
          title: "Transaction Value (in Rp.)",
          dataIndex: "trx_amount",
          render: (dataIndex) => numFormatter(decimalRounding(dataIndex as unknown as string))
        },
        {
          title: "Action",
          dataIndex: "transaction",
          render: (_dataIndex, data) => (
            <div className="custom-flex-col gap-2">
              <button 
                className={`btn btn-primary`}
                onClick={() => setToDetailModal(type, "VIEW_DETAIL", data)}
              >
                View Details
              </button>
              <UIDDetailBtn 
                user_id={data.user_id}
                dispatch={dispatch}
                type={type}
                isDisabled={state.isDisableUIDModal}
                setIsDisabled={(val)=>dispatch({isDisableUIDModal: val})}
              />
            </div>
          )
        }
      ] as Array<ReusableTableColumn<TransactionResponse<T>>>
      else if(type === "DCrypto" || type === "WCrypto") return [
        {
          title: "No",
          dataIndex: "transaction",
          render: (_, _data, index) => (activePage - 1)*limit + index + 1
        },
        {
          title: "Transaction No.", // Detail
          dataIndex: "transaction",
          render: (dataIndex: string) => <TransactionPopover value={dataIndex} />
        },
        {
          title: "Transaction Date", // Detail
          dataIndex: "transaction_date",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string)
        },
        {
          title: "User ID",
          dataIndex: "user_id",
        },
        // {
        //   title: "Asset",  // Detail
        //   dataIndex: "asset",
        // },
        // {
        //   title: "Channel",  // Detail
        //   dataIndex: "channel",
        // },
        // {
        //   title: "Amount",  // Detail
        //   dataIndex: "amount",
        // },
        // {
        //   title: "Address",  // Detail
        //   dataIndex: "address",
        // },
        {
          title: "Transaction Value (in Rp.)",
          dataIndex: "trx_idr",
          render: (dataIndex) => numFormatter(decimalRounding(dataIndex as unknown as string))
        },
        {
          title: "Create Time",
          dataIndex: "create_time",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string),
        },
        {
          title: "Complete Time",
          dataIndex: "complete_time",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string)
        },
        {
          title: "Action",
          dataIndex: "transaction",
          render: (_dataIndex, data) => (
            <div className="custom-flex-col gap-2">
              <button 
                className={`btn btn-primary`}
                onClick={() => setToDetailModal(type, "VIEW_DETAIL", data)}
              >
                View Details
              </button>
              <UIDDetailBtn 
                user_id={data.user_id}
                dispatch={dispatch}
                type={type}
                isDisabled={state.isDisableUIDModal}
                setIsDisabled={(val)=>dispatch({isDisableUIDModal: val})}
              />
            </div>
          )
        }
      ] as Array<ReusableTableColumn<TransactionResponse<T>>>
      else if(type === "DFiat" || type === "WFiat") return [
        {
          title: "No",
          dataIndex: "transaction",
          render: (_, _data, index) => (activePage - 1)*limit + index + 1
        },
        {
          title: "Transaction No.", // Detail
          dataIndex: "transaction",
        },
        {
          title: "Transaction Date", // Detail
          dataIndex: "transaction_date",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string)
        },
        {
          title: "User ID",
          dataIndex: "user_id",
        },
        // {
        //   title: "Asset",  // Detail
        //   dataIndex: "asset",
        // },
        // {
        //   title: "Channel",  // Detail
        //   dataIndex: "channel",
        // },
        {
          title: "Amount",  // Detail
          dataIndex: "amount",
          render: (dataIndex) => numFormatter(decimalRounding(dataIndex as unknown as string))
        },
        {
          title: "Complete Time",
          dataIndex: "complete_time",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string)
        },
        {
          title: "Action",
          dataIndex: "transaction",
          render: (_dataIndex, data) => (
            <div className="custom-flex-col gap-2">
              <button 
                className={`btn btn-primary`}
                onClick={() => setToDetailModal(type, "VIEW_DETAIL", data)}
              >
                View Details
              </button>
              <UIDDetailBtn 
                user_id={data.user_id}
                dispatch={dispatch}
                type={type}
                isDisabled={state.isDisableUIDModal}
                setIsDisabled={(val)=>dispatch({isDisableUIDModal: val})}
              />
            </div>
          )
        }
      ] as Array<ReusableTableColumn<TransactionResponse<T>>>
      else return [
        {
          title: "No",
          dataIndex: "transaction",
          render: (_, _data, index) => (activePage - 1)*limit + index + 1
        },
        {
          title: "Transaction No.",
          dataIndex: "transaction"
        },
        {
          title: "Transaction Date",
          dataIndex: "transaction_date",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string)
        },
        {
          title: "User ID",
          dataIndex: "user_id",
        },
        {
          title: "From",
          dataIndex: "from_asset",
        },
        {
          title: "To",
          dataIndex: "to_asset",
        },
        {
          title: "Amount",
          dataIndex: "to_amount",
          render: (dataIndex) => numFormatter(decimalRounding(dataIndex as unknown as string))
        },
        {
          title: "Transaction Value (in Rp.)",
          dataIndex: "transaction_value",
          render: (dataIndex) => numFormatter(decimalRounding(dataIndex as unknown as string))
        },
        {
          title: "Completed Date",
          dataIndex: "complete_time",
          render: (dataIndex) => globalDateTime(dataIndex as unknown as string)
        },
        {
          title: "Action",
          dataIndex: "transaction",
          render: (_dataIndex, data) => (
            <UIDDetailBtn 
              user_id={data.user_id}
              dispatch={dispatch}
              type={type}
              isDisabled={state.isDisableUIDModal}
              setIsDisabled={(val)=>dispatch({isDisableUIDModal: val})}
            />
          )
        }
      ] as Array<ReusableTableColumn<TransactionResponse<T>>>
    }
    return (
      <div className="custom-flex-col" id={transactionsSection}>
        <div className="p-4">
          <div className="dasboard-content-title-card custom-flex-row gap-1">
            <div>Transaction Details</div>
            <OverlayTrigger
              trigger={["hover", "hover"]}
              placement="bottom"
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Body>
                    Lists of transaction types and<br /> details based on the date range
                  </Popover.Body>
                </Popover>
              }
            >
              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.2002 10.4C2.4387 10.4 0.200195 8.16149 0.200195 5.39999C0.200195 2.63849 2.4387 0.399994 5.2002 0.399994C7.9617 0.399994 10.2002 2.63849 10.2002 5.39999C10.2002 8.16149 7.9617 10.4 5.2002 10.4ZM5.2002 9.39999C6.26106 9.39999 7.27848 8.97857 8.02862 8.22842C8.77877 7.47828 9.2002 6.46086 9.2002 5.39999C9.2002 4.33913 8.77877 3.32171 8.02862 2.57157C7.27848 1.82142 6.26106 1.39999 5.2002 1.39999C4.13933 1.39999 3.12191 1.82142 2.37177 2.57157C1.62162 3.32171 1.2002 4.33913 1.2002 5.39999C1.2002 6.46086 1.62162 7.47828 2.37177 8.22842C3.12191 8.97857 4.13933 9.39999 5.2002 9.39999ZM4.7002 2.89999H5.7002V3.89999H4.7002V2.89999ZM4.7002 4.89999H5.7002V7.89999H4.7002V4.89999Z" fill="#535353"/>
              </svg>
            </OverlayTrigger>
          </div>
          <div className="custom-flex-row-space-between gap-2">
            <ReusableRadioList 
              options={transactionTypes}
              value={state.transactionTypeSelected}
              onChange={(nv) => {
                dispatch({transactionTypeSelected: nv})
                setActivePage(1)
              }}
            />
            <div className="custom-flex-row-center gap-2 p-2">
              <button
                onClick={() => getReportService("1", true)}
                type="button"
                className={`btn btn-success btn-sm ${clsx(
                  {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": state.loadingBtnXLSXJournal
                  }
                )}`}
                disabled={state.loadingBtnXLSXJournal}
              >
                Download XLS
              </button>
              <button
                onClick={() => getReportService("0", true)}
                type="button"
                className={`btn btn-success btn-sm ${clsx(
                  {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": state.loadingBtnCSVJournal
                  }
                )}`}
                disabled={state.loadingBtnCSVJournal}
              >
                Download CSV
              </button>
            </div>
          </div>
        </div>
        <ReusableTable 
          loading={state.loadingTable}
          columns={columns(state.transactionTypeSelected)}
          dataSources={dataSources}
          pagination={{
            itemsCountPerPage: limit,
            totalItemsCount: totalItems,
            onChange: (pageNumber: number) => setActivePage(pageNumber),
            activePage,
          }}
        />
      </div>
    )
  }

  const decimalRounding = (n: number | string) => {
    return Number(Number(n).toFixed(6))
  }

  const getTotalValueLabel = () => {
    if(state.rangeDailyVolume && state.rangeDailyVolume.length > 0) return state.rangeDailyVolume?.map(v => Number(v.total)).reduce((acc, curr) => acc + curr)
    else return 0
  }

  const getAccountNo = (detail: FiatTransactionResponse) => {
    if(detail.bank_account_no) return middleEllipsisText(detail.bank_account_no, 4, 3, "*******")
    else if(detail.virtual_account_no) return middleEllipsisText(detail.virtual_account_no, 4, 3, "*******")
    else return "-"
  }

  const setToDetailModal = <T extends ListTransactionType>(type: T, detailType: ModalDetailType, detail: TransactionResponse<T>, finallyCallback?: () => void) => {
    if(detailType === "VIEW_DETAIL") {
      if(type === "buySell") dispatch({
        modalDetail: {
          transactionType: type,
          type: detailType,
          contents: [
            {
              label: "Transaction ID",
              value: (detail as BuySellTransactionResponse).transaction
            },
            {
              label: "Transaction Date & Time",
              value: dateTime((detail as BuySellTransactionResponse).transaction_date, true),
            },
            {
              label: "Type",
              value: (detail as BuySellTransactionResponse).type,
              type: "bold"
            },
            {
              label: "Coin",
              value: cryptoAssetSplitter((detail as BuySellTransactionResponse).crypto_assets).coin,
              type: "bold"
            },
            {
              label: "Pairing",
              value: cryptoAssetSplitter((detail as BuySellTransactionResponse).crypto_assets).pair,
              type: "bold"
            },
            {
              label: "Amount",
              value: numFormatter(decimalRounding((detail as BuySellTransactionResponse).trx_amount)),
              type: "bold"
            }
          ]
        }
      })
      else if(type === "WCrypto" || type === "DCrypto") dispatch({
        modalDetail: {
          transactionType: type,
          type: detailType,
          contents: [
            {
              label: "Transaction ID",
              value: <TransactionPopover value={(detail as CryptoTransactionResponse).transaction} />
            },
            {
              label: "Transaction Date & Time",
              value: dateTime((detail as CryptoTransactionResponse).transaction_date),
            },
            {
              label: "Coin",
              value: (detail as CryptoTransactionResponse).asset,
              type: "bold"
            },
            {
              label: "Amount",
              value: numFormatter(Number((detail as CryptoTransactionResponse).amount).toFixed(6)),
              type: "bold"
            },
            {
              label: "Wallet Receiver",
              value: <TransactionPopover value={(detail as CryptoTransactionResponse).address} />,
            }
          ]
        }
      })
      else dispatch({
        modalDetail: {
          transactionType: type,
          type: detailType,
          contents: [
            {
              label: "Transaction ID",
              value: (detail as FiatTransactionResponse).transaction
            },
            {
              label: "Transaction Date & Time",
              value: dateTime((detail as FiatTransactionResponse).transaction_date),
            },
            {
              label: "Amount",
              value: `Rp ${numFormatter(Number((detail as FiatTransactionResponse).amount).toFixed(6))}`,
              type: "bold"
            },
            {
              label: type === "WFiat" ? "Bank Name" : "Channel Type",
              value: (detail as FiatTransactionResponse).channel,
              type: "bold"
            },
            {
              label: type === "WFiat" ? "Account Number" : "Virtual Account Number",
              value: getAccountNo(detail as FiatTransactionResponse),
            }
          ]
        }
      })
    }
  }

  const contents: Array<Array<ContentSection>> = [
    [
      {
        type: "LABEL",
        label: "Today's Transaction Volume",
        tooltips: <Fragment>Total amount of today’s<br /> transaction in IDR</Fragment>,
        value: state.dailyVolume?.TOTAL ? numFormatter(decimalRounding(state.dailyVolume.TOTAL)) : 0,
        loading: state.loading,
        childrens: [
          {
            type: "LABEL",
            label: "Indonesian",
            loading: state.loading,
            value: state.dailyVolume?.INDONESIA ? numFormatter(decimalRounding(state.dailyVolume.INDONESIA)) : 0,
          },
          {
            type: "LABEL",
            label: "Foreigners",
            loading: state.loading,
            value: state.dailyVolume?.FOREIGNERS ? numFormatter(decimalRounding(state.dailyVolume.FOREIGNERS)) : 0,
          },
        ]
      },
      {
        type: "LABEL",
        label: "Today's Daily Active Users",
        tooltips: <Fragment>Total active unique users <br />doing trading today</Fragment>,
        value: state.dailyActiveUser?.TOTAL ? numFormatter(decimalRounding(state.dailyActiveUser?.TOTAL)) : 0,
        loading: state.loading,
        childrens: [
          {
            type: "LABEL",
            label: "Indonesian",
            loading: state.loading,
            value: state.dailyActiveUser?.INDONESIA ? numFormatter(decimalRounding(state.dailyActiveUser.INDONESIA)) : 0,
          },
          {
            type: "LABEL",
            label: "Foreigners",
            loading: state.loading,
            value: state.dailyActiveUser?.FOREIGNERS ? numFormatter(decimalRounding(state.dailyActiveUser.FOREIGNERS)) : 0,
          },
        ]
      },
      {
        type: "LABEL",
        label: "Total Users",
        tooltips: <Fragment>Total users with <br />KYC lev. 1 approved</Fragment>,
        value: state.totalUsers?.TOTAL ? numFormatter(decimalRounding(state.totalUsers.TOTAL)) : 0,
        loading: state.loading,
        childrens: [
          {
            type: "LABEL",
            label: "Indonesian",
            loading: state.loading,
            value: state.totalUsers?.INDONESIA ? numFormatter(decimalRounding(state.totalUsers.INDONESIA)) : 0,
          },
          {
            type: "LABEL",
            label: "Foreigners",
            loading: state.loading,
            value: state.totalUsers?.FOREIGNERS ? numFormatter(decimalRounding(state.totalUsers.FOREIGNERS)) : 0,
          },
        ]
      },
    ],
    [
      {
        type: "CUSTOM",
        render: (
          <div className="kt-portlet__body">
            <div className="custom-flex-row-center gap-3">
              <div className="dasboard-content-title-card">Transaction Date Range</div>
              <div style={{width: '150px'}}>
                <DatePicker
                  className="form-control font-8rem"
                  popperPlacement="right-start"
                  selected={state.startDate}
                  minDate={subDays(state.endDate ?? new Date(), 31)}
                  maxDate={new Date()}
                  onChange={(e) => {
                    dispatch({ startDate: e ?? undefined })
                    setActivePage(1)
                  }}
                />
              </div>
              <div style={{width: '150px'}}>
                <DatePicker
                  className="form-control font-8rem"
                  popperPlacement="right-start"
                  selected={state.endDate}
                  minDate={state.startDate}
                  maxDate={new Date()}
                  onChange={(e) => {
                    dispatch({ endDate: e ?? undefined })
                    setActivePage(1)
                  }}
                />
              </div>
              <button 
                className="btn btn-success btn-sm" 
                type="button" 
                onClick={() => {
                  searchTrigger(state.startDate, state.endDate)
                  smoothScroll(transactionsSection, 10)
                }}
              >
                Search
              </button>
            </div>
          </div>
        )
      }
    ],
    [
      {
        type: "LABEL",
        label: "Total Transaction Volume",
        tooltips: <Fragment>Total amount of transactions based <br />on the date range in IDR</Fragment>,
        loading: state.loading,
        value: getTotalValueLabel() ? numFormatter(decimalRounding(getTotalValueLabel())) : 0
      },
      {
        type: "LABEL",
        label: "Total Active Users",
        tooltips: <Fragment>Total active unique users doing <br />trading based on the date range</Fragment>,
        loading: state.loadingActiveUser,
        value: state.rangeActiveUsers?.TOTAL ? numFormatter(decimalRounding(state.rangeActiveUsers.TOTAL)) : 0
      },
    ],
    [
      {
        type: "CUSTOM",
        render: (
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="custom-flex-row gap-2">
                  <div className="dasboard-content-title-card">
                    Transaction Volume
                  </div>
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="bottom"
                    overlay={
                      <Popover id={`popover-positioned-bottom`}>
                        <Popover.Body>
                          Details of total amount of each day<br /> in IDR based on the date range
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.2002 10.4C2.4387 10.4 0.200195 8.16149 0.200195 5.39999C0.200195 2.63849 2.4387 0.399994 5.2002 0.399994C7.9617 0.399994 10.2002 2.63849 10.2002 5.39999C10.2002 8.16149 7.9617 10.4 5.2002 10.4ZM5.2002 9.39999C6.26106 9.39999 7.27848 8.97857 8.02862 8.22842C8.77877 7.47828 9.2002 6.46086 9.2002 5.39999C9.2002 4.33913 8.77877 3.32171 8.02862 2.57157C7.27848 1.82142 6.26106 1.39999 5.2002 1.39999C4.13933 1.39999 3.12191 1.82142 2.37177 2.57157C1.62162 3.32171 1.2002 4.33913 1.2002 5.39999C1.2002 6.46086 1.62162 7.47828 2.37177 8.22842C3.12191 8.97857 4.13933 9.39999 5.2002 9.39999ZM4.7002 2.89999H5.7002V3.89999H4.7002V2.89999ZM4.7002 4.89999H5.7002V7.89999H4.7002V4.89999Z" fill="#535353"/>
                    </svg>
                  </OverlayTrigger>
                  <button
                    onClick={() => getReportService("1")}
                    type="button"
                    className={`btn btn-success btn-sm ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": state.loadingBtnXLSXTransaction
                      }
                    )}`}
                    disabled={state.loadingBtnXLSXTransaction}
                  >
                    Download XLS
                  </button>
                  <button
                    onClick={() => getReportService("0")}
                    type="button"
                    className={`btn btn-success btn-sm ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": state.loadingBtnCSVTransaction
                      }
                    )}`}
                    disabled={state.loadingBtnCSVTransaction}
                  >
                    Download CSV
                  </button>
                </div>
              </Accordion.Header>
              <Accordion.Body className="kt-widget14__chart" style={{ height: "300px", margin: "20px 0 20px 0", padding: 0 }}>
                <ReusableChart 
                  loading={state.loadingVolumes}
                  datas={[
                    {
                      datasets: {
                        backgroundColor: "green",
                        data: state.rangeDailyVolume?.map(v => Number(v.total)) ?? []
                      },
                      yAxes: {
                        scaleLabel:{
                          display: false
                        },
                        ticks: {
                          callback: function(val, index) {
                            return numFormatter(val);
                          },
                        }
                      }
                    }
                  ]}
                  labels={state.rangeDailyVolume?.map(v => v.trx_date) ?? []}
                  customTooltips={{
                    label: (item) => item.value ? numFormatter(Number(item.value).toFixed(6)) : ""
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )
      }
    ],
    [
      {
        allowedRoles: whitelistRoles,
        type: "CUSTOM",
        render: transactionTable()
      }
    ],
  ]

  const detailModalTitle = <T extends ListTransactionType>(type: T, detailType: ModalDetailType) => {
    if(detailType === "VIEW_DETAIL") {
      if(type === "buySell") return "Transaction Crypto Details"
      else if(type === "WCrypto") return "Withdraw Crypto Details"
      else if(type === "WFiat") return "Withdraw Fiat Details"
      else if(type === "DCrypto") return "Deposit Crypto Details"
      else return "Deposit Fiat Details"
    }
    else return "User Total Assets Estimation"
  }

  const isDashboardView = (allowedRoles: Array<string>) => {
    const rules = localStorage.getItem('rule')?.split(',') ?? []
    if(allowedRoles.find(role => rules.find(r => r === role))) return true
    else return false
  }

  const contentRenderer = () => {
    const listRenderer = (list: Array<ContentSection>) => {
      return list.map((l, i) => {
        if(!l.allowedRoles || isDashboardView(l.allowedRoles)) {
          if(l.type === "LABEL") return (
            <div className="col" style={{width: "150px"}} key={i}>
              <div className="kt-portlet" style={{ marginBottom: '10px' }}>
                <div className="kt-portlet__body">
                  <div className="kt-portlet__preview">
                      <div className="kt-section mb-0">
                        <div className="custom-flex-row gap-1 dasboard-content-title-card mb-1">
                          <span>{l.label}</span>
                          {l.tooltips &&
                            <OverlayTrigger
                              trigger={["hover", "hover"]}
                              placement="bottom"
                              overlay={
                                <Popover id={`popover-positioned-bottom`}>
                                  <Popover.Body>
                                    {l.tooltips}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.2002 10.4C2.4387 10.4 0.200195 8.16149 0.200195 5.39999C0.200195 2.63849 2.4387 0.399994 5.2002 0.399994C7.9617 0.399994 10.2002 2.63849 10.2002 5.39999C10.2002 8.16149 7.9617 10.4 5.2002 10.4ZM5.2002 9.39999C6.26106 9.39999 7.27848 8.97857 8.02862 8.22842C8.77877 7.47828 9.2002 6.46086 9.2002 5.39999C9.2002 4.33913 8.77877 3.32171 8.02862 2.57157C7.27848 1.82142 6.26106 1.39999 5.2002 1.39999C4.13933 1.39999 3.12191 1.82142 2.37177 2.57157C1.62162 3.32171 1.2002 4.33913 1.2002 5.39999C1.2002 6.46086 1.62162 7.47828 2.37177 8.22842C3.12191 8.97857 4.13933 9.39999 5.2002 9.39999ZM4.7002 2.89999H5.7002V3.89999H4.7002V2.89999ZM4.7002 4.89999H5.7002V7.89999H4.7002V4.89999Z" fill="#535353"/>
                              </svg>
                            </OverlayTrigger>
                          }
                        </div>
                        {l.loading ?
                          <ReactLoading 
                            type={'spin'} 
                            color={'#5867dd'} 
                            height="30px" 
                            width="30px" 
                          />
                          :
                          <TransactionPopover 
                            value={l.value?.toString() ?? ""} 
                            render={(value)=>(
                              <div className="dasboard-content-value-card">
                                <span>{value}</span>
                              </div>
                            )} 
                          />
                        }
                      </div>
                  </div>
                </div>
              </div>
              {l.childrens &&
                <div className="custom-flex-row-space-between-center gap-3">
                  {listRenderer(l.childrens)}
                </div>
              }
            </div>
          )
          else return (
            <div className="kt-portlet" style={{ marginBottom: '10px' }}>
              <div className="kt-portlet__preview">
                <div className="kt-section mb-0">
                  {l.loading ?
                    <ReactLoading 
                      type={'spin'} 
                      color={'#5867dd'} 
                      height="30px" 
                      width="30px" 
                    />
                    :
                    l.render
                  }
                </div>
              </div>
            </div>
          )
        }
        else return null
      })
    }
    return contents.map((list,index) =>
      <div className="custom-flex-row-space-between-center gap-3" key={index}>
        {listRenderer(list)}
      </div>
    )
  }

  const getClassInfo = (type: "LABEL" | "VALUE", contentType?: "bold" | "green") => {
    if(type === "LABEL") {
      if(contentType === "green") return "dashboard-transaction-body-info dashboard-transaction-body-info__success"
      else return "dashboard-transaction-body-info dashboard-transaction-body-info__bold"
    }
    else {
      if(contentType === "bold") return "dashboard-transaction-body-info dashboard-transaction-body-info__bold"
      else if(contentType === "green") return "dashboard-transaction-body-info dashboard-transaction-body-info__success"
      else return "dashboard-transaction-body-info dashboard-transaction-body-info__normal"
    }
  }

  return (
    <div className="head">
      {contentRenderer()}
      <Modal
        // size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={!!state.modalDetail}
        onHide={()=>dispatch({modalDetail: undefined})}
      >
        <Modal.Body className="dashboard-transaction-body-title">
          <div>{state.modalDetail && detailModalTitle(state.modalDetail.transactionType, state.modalDetail.type)}</div>
        </Modal.Body>
        <Modal.Body className="custom-flex-col gap-3 mt-3 mb-3">
          {state.modalDetail?.contents.map((content, index) =>
            <div className="custom-flex-row-space-between gap-2" key={index}>
              <div className="custom-flex-row-center gap-1">
                <div className={getClassInfo("LABEL", content.type)}>{content.label}:</div>
                {content.info &&
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="auto"
                    overlay={
                      <Popover>
                        <Popover.Body>
                          {content.info}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.2002 10.4C2.4387 10.4 0.200195 8.16152 0.200195 5.40002C0.200195 2.63852 2.4387 0.400024 5.2002 0.400024C7.9617 0.400024 10.2002 2.63852 10.2002 5.40002C10.2002 8.16152 7.9617 10.4 5.2002 10.4ZM5.2002 9.40002C6.26106 9.40002 7.27848 8.9786 8.02862 8.22845C8.77877 7.47831 9.2002 6.46089 9.2002 5.40002C9.2002 4.33916 8.77877 3.32174 8.02862 2.5716C7.27848 1.82145 6.26106 1.40002 5.2002 1.40002C4.13933 1.40002 3.12191 1.82145 2.37177 2.5716C1.62162 3.32174 1.2002 4.33916 1.2002 5.40002C1.2002 6.46089 1.62162 7.47831 2.37177 8.22845C3.12191 8.9786 4.13933 9.40002 5.2002 9.40002ZM4.7002 2.90002H5.7002V3.90002H4.7002V2.90002ZM4.7002 4.90002H5.7002V7.90002H4.7002V4.90002Z" fill="#535353"/>
                    </svg>
                  </OverlayTrigger>
                }
              </div>
              <div className={getClassInfo("VALUE", content.type)}>{content.value}</div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

interface TransactionPopoverProps {
  value: string;
  render?: (value: string) => ReactNode;
}

const TransactionPopover: FC<TransactionPopoverProps> = (props) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const handleClick = () => {
    if (!isCopied) {
      navigator.clipboard.writeText(props.value)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    }
  }

  const renderContent = () => {
    if(props.render) return <div>{props.render(props.value)}</div>
    else return <div className="dashboard-column-transaction-no">{props.value}</div>
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={
        <Popover id={`popover-positioned-bottom`}>
          <Popover.Body>
            <div>{props.value}</div>
            <Button 
              variant="light" 
              style={{width:'100%'}}
              onClick={handleClick}
              disabled={isCopied}
            >
              {isCopied ? 'Copied!' : 'Copy'}
            </Button>
          </Popover.Body>
        </Popover>
      }
    >
      {renderContent()}
    </OverlayTrigger>
  )
}

interface UIDDetailBtnProps {
  user_id: string;
  dispatch: (value: Partial<InitialState>) => void;
  type: ListTransactionType;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
}

const UIDDetailBtn: FC<UIDDetailBtnProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const onClickHandler = () => {
    setLoading(true)
    props.setIsDisabled(true)
    Promise.all([
      getBidrById(props.user_id),
      getAssetById(props.user_id)
    ])
      .then(([bidr, asset]) => {
        props.dispatch({
          modalDetail: {
            transactionType: props.type,
            type: "UID_DETAIL",
            contents: [
              {
                label: "User ID",
                value: props.user_id
              },
              {
                label: "Total Crypto Assets",
                value: asset.data.data ? `Rp ${numFormatter(Number(asset.data.data).toFixed(6))},-` : "-",
                info: "Information of total estimation user’s crypto assets"
              },
              {
                label: "Total BIDR Assets",
                value: `Rp ${numFormatter(Number(bidr.data.data).toFixed(6))},-`,
                info: "Information of total estimation user’s BIDR assets"
              },
              {
                label: "Total All Assets",
                value: bidr.data.data && asset.data.data ? `Rp ${numFormatter((Number(bidr.data.data) + Number(asset.data.data)).toFixed(6))},-` : "-",
                type: "green"
              }
            ]
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(()=>{
        setLoading(false)
        props.setIsDisabled(false)
      })
  }

  return (
    <button 
      className={`btn btn-primary ${clsx(
        {
          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
        }
      )}`}
      disabled={props.isDisabled}
      onClick={onClickHandler}
    >
      UID Details
    </button>
  )
}

export default Dashboard;

