import React from 'react';
import MenuSection from './MenuSection';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItem from './MenuItem';

export default class MenuList extends React.Component {
  render() {
    const { currentUrl, menuConfig, layoutConfig, marginBottom } = this.props;
    let rule = localStorage.getItem('rule')?.split(',') || [];
    return menuConfig.aside.items.map((child, index) => {
      const finded = rule.some((val) => child?.roles?.includes(val));
      if(finded) return (
        <React.Fragment key={`menuList${index}`}>
          {child.section && <MenuSection item={child} />}
          {child.separator && <MenuItemSeparator item={child} />}
          {child.title && (
            <MenuItem
              item={child}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
              marginBottom={menuConfig.aside.items.length - 1 === index ? marginBottom : undefined}
            />
          )}
        </React.Fragment>
      );
    });
  }
}
