import axios from 'axios'
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import { CheckDJPSummaryParams, CheckDJPSummaryResponse, CheckSaveProcessDJP, CheckSaveProcessDJPResponse, MarketMakerParams, MarketMakerPayload, Paginate, Rate, RatesParams, SaveRequestDJPParams, SubmitDJPParams, TaxMarketMaker, TaxOtcCheckSaveProcess, TaxOtcCheckSaveProcessResponse, TaxOtcSaveRequest, TaxOtcSummary, TaxOtcSummaryRequest, TypeFilter, UpdateRatesPayload } from './tax-types';
import { ResponseAPI } from './global-types';
import { DJP } from '../pages/tax/SetupDJPDocs';
import { objFormData } from '../helper';

const history =  createBrowserHistory();

const newInstance = axios.create({
  // baseURL: process.env.REACT_APP_ENV === 'demo' ? '/tax-service' : "https://trade.tokocrypto.com/"
  baseURL: process.env.REACT_APP_ENV === 'demo' ? 'https://service-djp-demo-xyz.tokocrypto.com' : "https://service-tax.tokocrypto.com"
})

newInstance.interceptors.request.use((config) => {
  if(config.headers) {
    config.headers.Authorization = localStorage.getItem("token");
    config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
  }
  return config
})

newInstance.interceptors.response.use(config => config, (err) => {
  if(err.response){
    if(err.response.status === 401){
      toast.error("token has expired");
      localStorage.clear();
      history.push("/auth");
      window.location.reload();
    }
    else{
      toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
    }
  }

  return Promise.reject(err)
})

export const getMarketMaker = (params: Partial<MarketMakerParams>) => newInstance.get<ResponseAPI<Paginate<Array<TaxMarketMaker>>>>("/admin/tax/market-maker",{params});
export const getMarketMakerById = (id: number) => newInstance.get<ResponseAPI<TaxMarketMaker>>("/admin/tax/market-maker/" + id);
export const createMarketMaker = (payload: MarketMakerPayload) => newInstance.post<ResponseAPI<boolean>>("/admin/tax/market-maker/create", payload);
export const updateMarketMaker = (id: number, payload: MarketMakerPayload) => newInstance.patch<ResponseAPI<boolean>>("/admin/tax/market-maker/update/" + id, payload);
export const deleteMarketMaker = (id: number) => newInstance.delete<ResponseAPI<boolean>>("/admin/tax/market-maker/remove/" + id);

export const checkDJPSummary = (payload: CheckDJPSummaryParams) => newInstance.post<ResponseAPI<Array<CheckDJPSummaryResponse>>>("/admin/tax/summary", payload)
export const checkSaveProcessDJP = (params: Partial<CheckSaveProcessDJP>) => newInstance.get<ResponseAPI<false | CheckSaveProcessDJPResponse>>("/admin/tax/check-save-process", {params})
export const saveRequestDJP = (payload: SaveRequestDJPParams) => newInstance.post<ResponseAPI<boolean>>("/admin/tax/save-request", payload)
export const requestSubmitDJP = (payload: SubmitDJPParams) => newInstance.post<ResponseAPI<boolean>>("/admin/tax/request-submit-djp", payload)
export const checkProcessDJP = () => newInstance.get<ResponseAPI<Array<DJP>>>("/admin/tax/check-process")
export const getDJPDataPerYear = (year: number) => newInstance.get('/admin/tax/request',{params:{year}})

export const getRates = (params: RatesParams) => newInstance.get<ResponseAPI<Array<Rate>>>("admin/tax/rates",{params})
export const updateRates = (payload: UpdateRatesPayload) => newInstance.patch<ResponseAPI<boolean>>("admin/tax/rates", payload)

export const otcSaveRequest = (type: TypeFilter, payload: TaxOtcSaveRequest) => newInstance.post(`/admin/tax/${type}/save-request`, objFormData(payload))
export const otcGetRequestSummary = (type: TypeFilter, params: TaxOtcSummaryRequest) => newInstance.get<ResponseAPI<Array<TaxOtcSummary>>>(`/admin/tax/${type}/request-summary`, {params})
export const otcCheckSaveRequest = (type: TypeFilter, params: TaxOtcCheckSaveProcess) => newInstance.get<ResponseAPI<Array<TaxOtcCheckSaveProcessResponse>>>(`/admin/tax/${type}/check-save-process`, {params})
export const otcRequestSubmitDJP = (type: TypeFilter, payload: TaxOtcCheckSaveProcess) => newInstance.post(`/admin/tax/${type}/tax/request-submit-djp`, payload)

export default newInstance