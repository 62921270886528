export const facilitiesOption = [
  {
    label: 'AC',
    value: 'AC',
  },
  {
    label: 'Mineral Water',
    value: 'Mineral Water',
  },
  {
    label: 'Chair',
    value: 'Chair',
  },
  {
    label: 'Whiteboard',
    value: 'Whiteboard',
  },
  {
    label: 'LCD',
    value: 'LCD',
  },
  {
    label: 'Wifi',
    value: 'Wifi',
  },
];
