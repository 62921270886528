import { FC, useEffect, Fragment, useMemo } from "react";
import { useMultipleState, globalDateTime, formatBytes, convertToCSV, convertToXLSX } from "../../helper"
import { BursaResponse } from '../../crud/compliance-types';
import { getBursaReport } from "../../crud/compliance-service"
import PaginationTable from "../../components/reusablePaginationTable";
import FormWithFormik from "../../components/reusableFormWithFormik";
import { useFormik } from "formik";
import { SortProps } from "../../components/reusableTable";

interface IBursaState {
  dataSources: Array<BursaResponse>;
  loading: boolean;
  sortMode?: SortProps<BursaResponse>;
}

interface IFilterFormik {
  keyword: string;
  date?: Date;
}

const BursaFTP: FC = () => {
  const [state, dispatch] = useMultipleState<IBursaState>({
    dataSources: [],
    loading: false
  })
  const filterFormik = useFormik<IFilterFormik>({
    initialValues: {
      keyword: "",
    },
    onSubmit: () => {}
  })
  useEffect(()=>{
    dispatch({loading: true})
    getBursaReport()
      .then(res => dispatch({ dataSources: res.data.data }))
      .catch(err => console.log(err))
      .finally(() => dispatch({loading: false}))
  }, [])

  const newDatasource = useMemo(()=>{
    let newDs = structuredClone(state.dataSources)

    if(state.sortMode?.order_by === "modifyTime") newDs = newDs.sort((a,b) => {
      if(state.sortMode?.order_sort === "asc") {
        if(new Date(a.modifyTime) > new Date(b.modifyTime)) return -1
        else return 1
      }
      else {
        if(new Date(a.modifyTime) > new Date(b.modifyTime)) return 1
        else return -1
      }
    })
    else if(state.sortMode?.order_by === "size") newDs = newDs.sort((a,b) => {
      if(state.sortMode?.order_sort === "asc") {
        if(a.size > b.size) return 1
        else return -1
      }
      else {
        if(a.size > b.size) return -1
        else return 1
      }
    })

    return newDs
  },[state.sortMode, state.dataSources])

  const filteredDataSources = useMemo(()=> {
    return newDatasource.filter(ds => {
      const filtered = ds.name.toLowerCase().includes(filterFormik.values.keyword.toLowerCase())
      if(filterFormik.values.date) {
        const filteredDate = new Date(ds.modifyTime).getMonth() === new Date(filterFormik.values.date).getMonth() && new Date(ds.modifyTime).getFullYear() === new Date(filterFormik.values.date).getFullYear()
        return filtered && filteredDate
      }
      else return filtered
    })
  }, [filterFormik.values.keyword, filterFormik.values.date, newDatasource])

  const downloadDocsHandler = (type: "CSV" | "XLSX") => {
    const titles: Array<keyof BursaResponse> = ["name","size","modifyTime"]
    const contents = state.dataSources.map(ds => titles.map(title => String(ds[title])))

    if(type === "CSV") convertToCSV([titles as Array<string>].concat(contents), "bursa-ftp-files.csv")
    else convertToXLSX([titles as Array<string>].concat(contents), "bursa-ftp-files.xlsx")
  }
  return (
    <div className="wrapper">
      <div className="custom-flex-row-space-between w-100">
        <div className="custom-flex-col-space-between gap-4 w-100">
          <div>
            <div className="title">FTP Bursa</div>
            <div className="desc">List of bursa's files!</div>
          </div>
          <div className="custom-flex-row-space-between">
            <div className="custom-flex-row-center gap-2">
              <FormWithFormik 
                name="keyword"
                config={filterFormik}
                type="TEXT_INPUT"
                placeholder="Search by name"
              />
              <FormWithFormik 
                name="date"
                config={filterFormik}
                isClearable
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                showTimeSelect={false}
                type="DATE"
                placeholderText="Search by Month"
              />
            </div>
            <div className="custom-flex-row-center gap-2">
              <button className="btn btn-primary" onClick={() => downloadDocsHandler("CSV")}>Download CSV</button>
              <button className="btn btn-primary" onClick={() => downloadDocsHandler("XLSX")}>Download XLSX</button>
            </div>
          </div>
        </div>
      </div>
      <PaginationTable<BursaResponse> 
        loading={state.loading}
        limit={10}
        className="sipendar-table"
        dataSources={filteredDataSources}
        onSort={{
          default: state.sortMode,
          onChange: (sortMode) => dispatch({sortMode})
        }}
        columns={(page, limit) => [
          {
            title: "No",
            dataIndex: "name",
            render: (_, _data, index) => ((page - 1) * limit) + index + 1
          },
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Size",
            dataIndex: "size",
            render: (_, data) => <Fragment>{formatBytes(data.size)}</Fragment>,
            isSortMode: true
          },
          {
            title: "Uploaded Time",
            dataIndex: "modifyTime",
            render: (_, data) => globalDateTime(data.modifyTime),
            isSortMode: true
          },
        ]}
      />
    </div>
  )
}

export default BursaFTP