import React from 'react';
import ReactLoading from 'react-loading';
import clsx from "clsx";
// import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import actions from "../../crud/microsite";
import { Button, Table, Modal, OverlayTrigger, ButtonToolbar, Tooltip, InputGroup, FormControl, Form } from "react-bootstrap";
// import { Button, Table, Modal,  } from "react-bootstrap";
// import { TextField } from '@material-ui/core';
// import { VisibilityOff, Visibility } from '@material-ui/icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth
  },
  default: {
    class: 'sort',
    fn: (a, b) => a
  }
};

export default class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      totalData: 0,
      postPerPage: 15,
      seacrhParams: '',
      orderBy: 'id',
      orderSort: 'asc',
      active: 1,
      params: '',
      data: [],
      idEdit : null,
      status: '0',
      name: '',
      code: '',
      type: 'currency',
      return: '0',
      volacity: '0',
      ratio: '0',
      aMonth: '0',
      threeMonth: '0',
      aYear: '0',
      fiveYears: '0',
      typeHandle: 'create',
      listType: [],
      validated: false
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  emptyState(){
    this.setState({
      name: '',
      code: '',
      type: 'currency',
      return: '0',
      volacity: '0',
      ratio: '0',
      aMonth: '0',
      threeMonth: '0',
      aYear: '0',
      fiveYears: '0',
      idEdit: null,
      typeHandle: 'create'
    });
  }

  cloneState() {
    return _.clone(this.state);
  }

  onChangeState(val, field) {
    let state = this.cloneState();
    state[field] = val;
    this.setState(state);
  }

  handleShow(id, type){
    this.setState({ show: true, typeHandle: type, idEdit: id });
    if(type === 'update'){
      this.getDetail(id);
    }
  }
  handleClose(){
    this.setState({ show: false });
    this.emptyState();
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }
  
  async checkParams () {
    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }else{
      params = `?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}`
    }
    

    this.setState({
      loading: true
    })
    actions.fetchCompare(this.getToken(), params)
    .then(res => {
      this.setState({
        data:res.data.data.data,
        loading: false,
        totalData: res.data.data.total_data
      });
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.checkParams()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.checkParams()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getDetail(id){
    this.setState({
      loading: true
    })

    actions.fetchDetailCompare(this.getToken(), id)
    .then(res => {
      console.log(res.data.data)
      const allData = res.data.data
      this.setState({
        loading: false,
        name: allData.invest_name || '',
        code: allData.invest_code || '',
        type: allData.type || 'currency',
        return: allData.hl_roi || '0',
        volacity: allData.hl_volatility || '0',
        ratio: allData.hl_sharpe_ratio || '0',
        aMonth: allData.month_1_roi || '0',
        threeMonth: allData.month_3_roi || '0',
        aYear: allData.month_12_roi || '0',
        fiveYears: allData.year_5_roi || '0',
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    }) 
  }

  handleDeleteOpen(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(id);
      }
    })
  }

  delete(id){
    this.setState({
      loading: true
    })

    actions.deleteDataCompare(this.getToken(), id)
    .then(res => {      
      if(res.data.code === 200){
        toast.success("Delete success");
      }
      this.setState({
        loading: false,
      })
      this.checkParams();
    })
    .catch(e => {
      this.setState({
        loading: false,
        showDelete: false,
      })
      if(e.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
      }
    })
  }

  isSubmit = (event) => {
    let dataCompare = {
      invest_name: this.state.name,
      invest_code: this.state.code,
      type: this.state.type,
      hl_roi: parseFloat(this.state.return.replace(/,/g, '') || '0'),
      hl_volatility: parseFloat(this.state.volacity.replace(/,/g, '') || '0'),
      hl_sharpe_ratio: parseFloat(this.state.ratio.replace(/,/g, '') || '0'),
      month_1_roi: parseFloat(this.state.aMonth.replace(/,/g, '') || '0'),
      month_3_roi: parseFloat(this.state.threeMonth.replace(/,/g, '') || '0'),
      month_12_roi: parseFloat(this.state.aYear.replace(/,/g, '') || '0'),
      year_5_roi: parseFloat(this.state.fiveYears.replace(/,/g, '') || '0'),
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true});
    }else{
      if(this.state.typeHandle === "create"){
        event.preventDefault();
        this.createData(dataCompare);
      }else{
        event.preventDefault();
        this.updateData(dataCompare)
      }
    }
  }

  createData (dataCompare) {
    this.setState({
      loading: true
    })
    
    actions.CreateCompare(this.getToken(), dataCompare)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Comparison added succesfully");
      }
      this.setState({
        loading: false,
        show: false
      })
      this.emptyState()
      this.checkParams()
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        show: false
      })
      this.emptyState()
      this.checkParams()
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message[0].name} ${e.response.data.message[0].message}`);
        }
      }
    })
  }

  updateData (dataCompare) {
    this.setState({
      loading: true
    })
    
    actions.putDataCompare(this.getToken(), this.state.idEdit, dataCompare)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Comparison updated succesfully");
      }
      this.setState({
        loading: false,
        show: false
      })
      this.emptyState()
      this.checkParams()
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        show: false
      })
      this.emptyState()
      this.checkParams()
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }
  
  componentDidMount(){
    this.checkParams();
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('DD-MM-YY hh:m A');

    return fmtDate
  }

  render(){
    const listType = ['currency', 'index', 'metal', 'stock'];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body pddmdForce">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-2" 
                    name="search" 
                    placeholder="Search" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} /> 
                  <button className="btn btn-success btn-sm pr-5 pl-5" type="button" onClick={() => this.checkParams()}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div className="paddupgrade">
                  <Button onClick={() => this.handleShow(null, 'create')} variant="success" style={{fontSize: '11px'}}>Create</Button>
                </div>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" rowSpan="2">
                        Name &nbsp;
                        <button onClick={(e) => this.onSortChange('invest_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" rowSpan="2">
                        Code &nbsp;
                        <button onClick={(e) => this.onSortChange('invest_code')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" rowSpan="2">
                        Type
                      </th>
                      <th className="tableheh" rowSpan="2">
                        Return
                        <button onClick={(e) => this.onSortChange('hl_roi')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" rowSpan="2">
                        Volacity
                        <button onClick={(e) => this.onSortChange('hl_volatility')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" rowSpan="2">
                        Sharpe Ratio
                        <button onClick={(e) => this.onSortChange('hl_sharpe_ratio')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="center tableheh" colSpan="4">
                        Return (%)
                      </th>
                      <th className="tableheh" style={{ width: '10%' }} rowSpan="2">
                        Created At
                        <button onClick={(e) => this.onSortChange('created_at')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="center tableheh" style={{ width: '8%' }} rowSpan="2">
                        Options
                      </th>
                    </tr>
                    <tr>
                      <th className="tableheh">
                        1 Month
                        <button onClick={(e) => this.onSortChange('month_1_roi')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh">
                        3 Month
                        <button onClick={(e) => this.onSortChange('month_3_roi')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh">
                        12 Month
                        <button onClick={(e) => this.onSortChange('month_12_roi')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh">
                        5 Years
                        <button onClick={(e) => this.onSortChange('year_5_roi')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="12"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.data.map( (row, index) => (
                        <tr key={index}>
                          {/* <td className="tableheh center">{index +1}</td> */}
                          <td>{row.invest_name.charAt(0).toUpperCase() + row.invest_name.slice(1)}</td>
                          <td>{row.invest_code}</td>
                          <td>{row.type}</td>
                          <td>{row.hl_roi}</td>
                          <td>{row.hl_volatility}</td>
                          <td>{row.hl_sharpe_ratio}</td>
                          <td>{row.month_1_roi}</td>
                          <td>{row.month_3_roi}</td>
                          <td>{row.month_12_roi}</td>
                          <td>{row.year_5_roi}</td>
                          <td>{this.dateTime(row.created_at)}</td>
                          <td className="center">
                            <>
                              <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Update Data Compare
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-action" onClick={() => this.handleShow(row.id, 'update')}>
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                  </span>
                                </OverlayTrigger>
                                &nbsp; | &nbsp;
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Delete Data Compare
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-action" onClick={() => this.handleDeleteOpen(row.id)}>
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                  </span>
                                </OverlayTrigger>
                              </ButtonToolbar>
                            </>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  } 
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
                <Modal.Title>{this.state.typeHandle === "create" ? 'Create' : 'Update'} Data Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <form className="m-login__form m-form" onSubmit={this.isSubmit}> */}
            <Form noValidate validated={this.state.validated} onSubmit={this.isSubmit}>
              <Form.Group style={{marginBottom: '10px'}}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange('name')}
                  disabled={this.state.name === 'bitcoin' ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  Please input a code.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group style={{marginBottom: '10px'}}>
                <Form.Label>Code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Code"
                  name="code"
                  value={this.state.code}
                  onChange={this.handleChange('code')}
                  disabled={this.state.code === 'btc' ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  Please input a code.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-group" style={{marginBottom: '10px'}}>
                <label className="col-md-6 p-0">Select Type</label>
                <div className="input-group">
                  <select className="form-control" onChange={(e) => this.setState({ type: e.target.value })} required>
                    <option selected={true} disabled>{this.state.type.charAt(0).toUpperCase() + this.state.type.slice(1)}</option>
                    {listType.map((tag, id) => (
                      <option key={id} value={tag} selected={tag === this.state.type ? true : false} >{tag.charAt(0).toUpperCase() + tag.slice(1)}</option>  
                    ))}
                  </select>
                  {/* <select className="form-control" onChange={(e) => this.setState({ type: e.target.value })} required>
                    <option value={this.state.type} selected>{this.state.type}</option>
                    <option value="currency">Currency</option>
                    <option value="index">Index</option>
                    <option value="metal">Metal</option>
                    <option value="stock">Stock</option>
                  </select> */}
                </div>
              </div>
              <div className="form-group" style={{marginBottom: '10px'}}>
                {/* <label htmlFor="basic-url">Your vanity URL</label> */}
                <Form.Label>Return</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    value={this.state.return}
                    onChange={(e) => this.onChangeState(e.target.value, 'return')}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="form-group" style={{marginBottom: '10px'}}>
                {/* <label htmlFor="basic-url">Your vanity URL</label> */}
                <Form.Label>Volacity</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    value={this.state.volacity}
                    onChange={(e) => this.onChangeState(e.target.value, 'volacity')}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="form-group" style={{marginBottom: '10px'}}>
                {/* <label htmlFor="basic-url">Your vanity URL</label> */}
                <Form.Label>Sharpe Ratio</Form.Label>
                <FormControl
                  type="number"
                  value={this.state.ratio}
                  onChange={(e) => this.onChangeState(e.target.value, 'ratio')}
                  required
                />
              </div>
              <div className="form-group" style={{marginBottom: '10px'}}>
                {/* <label htmlFor="basic-url">Your vanity URL</label> */}
                <Form.Label>Return 1 Month</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    value={this.state.aMonth}
                    onChange={(e) => this.onChangeState(e.target.value, 'aMonth')}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="form-group" style={{marginBottom: '10px'}}>
                {/* <label htmlFor="basic-url">Your vanity URL</label> */}
                <Form.Label>Return 3 Months</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    value={this.state.threeMonth}
                    onChange={(e) => this.onChangeState(e.target.value, 'threeMonth')}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="form-group" style={{marginBottom: '10px'}}>
                {/* <label htmlFor="basic-url">Your vanity URL</label> */}
                <Form.Label>Return 1 Year</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    value={this.state.aYear}
                    onChange={(e) => this.onChangeState(e.target.value, 'aYear')}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="form-group" style={{marginBottom: '10px'}}>
                {/* <label htmlFor="basic-url">Your vanity URL</label> */}
                <Form.Label>Return 5 Years</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    value={this.state.fiveYears}
                    onChange={(e) => this.onChangeState(e.target.value, 'fiveYears')}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                <button
                  type="submit"
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                  // onClick={() => this.isSubmit()}
                >
                  {this.state.typeHandle === "create" ? 'Create' : 'Update'} Data Compare
                </button>
              </div>
            </Form>
            {/* </form> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}