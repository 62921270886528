import React from 'react';
import clsx from "clsx";
import { Link } from "react-router-dom";
import moment from 'moment';
import actions from "../../crud/tkowebsite";
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import "react-datepicker/dist/react-datepicker.css";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class AddMiniBanner extends React.Component {
  history() {
    history.push("/tko-website/mini-banner");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      titleEN: '',
      titleID: '',
      url: '',
      image: null,
      imageEdit: '',
      status: '1',
      editPage: false,
      idData: ''
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      titleEN: '',
      titleID: '',
      url: '',
      image: null,
      imageEdit: '',
      status: '1',
      editPage: false,
      idData: ''
    });
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getDetailData(id){
    this.setState({
      loading: true,
    })

    actions.fetchDetailMiniBanner(this.getToken(), id)
    .then(res => {
      const data = res.data.data
      this.setState({
        loading: false,
        titleEN: data.title_en,
        titleID: data.title_id,
        url: data.url,
        imageEdit: data.image,
        status: data.status,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 

  }

  isSubmit = event => {
    event.preventDefault();

    var bodyFormData = new FormData();
    bodyFormData.append('title_en', this.state.titleEN); 
    bodyFormData.append('title_id', this.state.titleID);
    bodyFormData.append('url', this.state.url);
    bodyFormData.append('status', this.state.status);
    if(this.state.image !== null){
      bodyFormData.append('image', this.state.image);
    }

    if(this.state.editPage){
      this.editEvent(bodyFormData);
    }else{
      this.addEvent(bodyFormData);
    }
  }

  addEvent(bodyFormData){
    this.setState({
      loading: true
    })

    actions.postMiniBanner(this.getToken(), bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new Mini Banner is success");
      }
      this.setState({
        loading: false,
      })
      this.emptyState()
      setTimeout(() => {
        this.history()
      }, 2000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  editEvent(bodyFormData){
    this.setState({
      loading: true
    })

    actions.putMiniBanner(this.getToken(), this.state.idData, bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Edit new Mini Banner is success");
      }
      this.setState({
        loading: false,
      })
      
      this.emptyState()
      setTimeout(() => {
        this.history()
      }, 2000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  onChangeFile = async (e) => {
    let file = e.target.files[0];
    if(file){
      await this.setState({
        image: file
      })
    }
  }

  componentDidMount(){
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      this.getDetailData(checkPath[checkPath.length - 2]);
    }
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()

    return ISOformat
  }

  render(){
    const listStatus = [
      {
        id: "1",
        name: 'Enabled',
      },
      {
        id: "0",
        name: 'Disabled',
      },
    ];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Mini Banner Title (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.titleEN} onChange={(e) => this.setState({titleEN: e.target.value})} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Mini Banner Title (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.titleID} onChange={(e) => this.setState({titleID: e.target.value})} required/>
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group>
                    <Form.Label>URL</Form.Label>
                    <Form.Control type="text" placeholder="https://tokocrypto.com/" value={this.state.url} onChange={(e) => this.setState({url: e.target.value})} required/>
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-9 col-sm-12">
                      <Form.Group>
                        <Form.Label>Mini Banner Image</Form.Label>
                        <div className="d-flex justify-content-center align-items-center">
                          <Form.Control className="form-control" type="file" onChange={this.onChangeFile} accept="image/*"/>
                        </div>  
                        <Form.Text className="text-muted">
                          <span>*Please using 600px x 400px for this image</span>
                        </Form.Text>
                      </Form.Group>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <Form.Group>
                        <div style={{marginTop: '2rem'}}>
                          {this.state.imageEdit !== '' && (
                            <a href={this.state.urlAsset+this.state.imageEdit} target='_blank' rel="noopener noreferrer">
                              <img src={this.state.urlAsset+this.state.imageEdit} alt="img" className="img-bn" />
                            </a>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <div className="input-group">
                      <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })} placeholder="Select Data">
                        {/* <option disabled selected={this.state.status === '' ? true : false}>Pilih Salah Satu</option> */}
                        {listStatus.map((e) => (
                          <option key={e.id} value={e.id} selected={e.id === this.state.status ? true : false} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <Link to="/tko-website/mini-banner" className="kt-link">
                      <Button variant="outline-danger" className="mr-3">Cancel</Button>
                    </Link>
                    <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      Save Event
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}