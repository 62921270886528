import instance from './axiosKycInstance'

const getCountry = (token) => {
  return instance.get("/admin/v1/itemList/country");
}

const fetchCountry = (token) => {
  return new Promise((resolve, reject) => {
    getCountry(token).then(res => {
          resolve(res);
      })
          .catch(err => {
              reject(err);
          })
  });
}

const getProvince = (token) => {
    return instance.get("/admin/v1/itemList/id/province");
}
  
const fetchProvince = (token) => {
    return new Promise((resolve, reject) => {
        getProvince(token).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
}

const getOccupation = (token) => {
    return instance.get("/admin/v1/itemList/en/occupation");
}
  
const fetchOccupation = (token) => {
    return new Promise((resolve, reject) => {
        getOccupation(token).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
}

const avgIncomePerYear = () => instance.get('/v1/itemList/en/average-income-per-year')
const sourceOfIncome = (language) => instance.get(`/v1/itemList/${language}/source-of-income`)
const purposeOfAccount = () => instance.get('/v1/itemList/en/purpose-of-account')
const listOfBanks = () => instance.get('/v1/itemList/bank-code')

export default {
    fetchCountry: fetchCountry,
    fetchProvince: fetchProvince,
    fetchOccupation: fetchOccupation,
    avgIncomePerYear,
    sourceOfIncome,
    purposeOfAccount,
    listOfBanks,
};