import { CSSProperties, DetailedHTMLProps, Dispatch, FC, Fragment, HTMLAttributes, ReactNode, SetStateAction, useEffect, useState } from "react";
import { Button, Dropdown, Placeholder, Table } from "react-bootstrap";
import Pagination, { ReactJsPaginationProps } from 'react-js-pagination';
import Constant from '../../../Constant'

require('bootstrap/scss/bootstrap.scss');

export interface ReusableTablePagination {
  activePage: number;
}

export type ReusableTableRender<T> = (dataIndex: T[keyof T], data: T, index: number) => ReactNode;

export interface ReusableTableColumn<T> {
  title: ReactNode;
  dataIndex: keyof T;
  render?: ReusableTableRender<T>;
  style?: CSSProperties;
  isSortMode?: boolean;
  headerStyle?: CSSProperties;
  headerClassName?: string;
}

export interface SortByKeyKeywords<T> extends DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {
  propertiesKey: keyof T;
}

export interface Expandable<T> {
  expandedRowTrigger?: (record: T) => ReactNode;
  expandedRowRender: (record: T) => ReactNode;
  rowExpandable: (record: T) => boolean;
}

export interface ReusableTableOnSort<T> {
  default?: SortProps<T>;
  onChange?: (sort?: SortProps<T>) => void;
}

export interface ReusableTableLimitConfig {
  limits: Array<number>;
  selectedLimit: number;
  onSelect: (value: number) => void;
}

export interface ReusableTableProps<T> {
  loading?: boolean;
  dataSources: Array<T>;
  columns: Array<ReusableTableColumn<T>>;
  pagination?: ReactJsPaginationProps;
  className?: string;
  onSort?: ReusableTableOnSort<T>;
  stickyColumn?: Partial<Record<"left" | "right", number>>;
  sortByKeyKeywords?: SortByKeyKeywords<T>;
  expandable?: Expandable<T>;
  withoutHeader?: boolean;
  withoutDefaultStyles?: boolean;
  limitConfig?: ReusableTableLimitConfig;
}

export interface SortProps<T> {
  order_by: keyof T;
  order_sort?: "asc" | "desc";
}

interface SortByKeyKeywordsDummy<T> {
  propertiesValue: T[keyof T];
  dataSources: Array<T>;
}

const ReusableTable = <T extends object>(props: ReusableTableProps<T>) => {
  const [currentSort, setCurrentSort] = useState<SortProps<T> | undefined>(props.onSort?.default);
  useEffect(()=>{
    if(props.onSort?.onChange) props.onSort.onChange(currentSort)
  }, [currentSort])
  const loadingRenderingHandler = () => {
    const getLengthColumn = props.pagination?.itemsCountPerPage ?? 5
    if(props.loading) return (
      <tr>
        <td 
          rowSpan={props.pagination?.itemsCountPerPage ?? 5}
          colSpan={props.columns.length}
          style={{
            textAlign: 'center'
          }}
        >
          Please wait, the data will be ready soon…
        </td>
      </tr>
    )
    // if(props.loading) return (
    //   [...Array(props.pagination?.itemsCountPerPage ?? 5).keys()].map((_, keyIndex) =>
    //     <tr key={keyIndex}>
    //       {props.columns.map((column, cIndex) =>
    //         <td
    //           style={{
    //             transition: "all 0.3s",
    //             ...column.style,
    //           }}
    //           key={cIndex}
    //         >
    //           <Placeholder />
    //         </td>
    //       )}
    //     </tr>
    //   )
    // )
    else {
      if(props.dataSources.length > 0) {
        if(props.sortByKeyKeywords) {
          const { propertiesKey, ...trProps } = props.sortByKeyKeywords
          let dummies: Array<SortByKeyKeywordsDummy<T>> = []

          props.dataSources.forEach((dataSource, index) => {
            const selectedDummyIndex = dummies.findIndex(dummy => dummy.propertiesValue === dataSource[propertiesKey])
            if(index > 0 && selectedDummyIndex > -1) dummies[selectedDummyIndex].dataSources = [...dummies[selectedDummyIndex].dataSources, dataSource]
            else dummies = [...dummies, {
              propertiesValue: dataSource[propertiesKey],
              dataSources: [dataSource]
            }]
          })

          return dummies.map((dummy, dIndex) =>
            <Fragment key={dIndex}>
              <tr data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_ROW} {...trProps}>
                <td colSpan={props.columns.length}>{String(dummy.propertiesValue).toUpperCase()}</td>
              </tr>
              {dummy.dataSources.map((row, rowIndex) =>
                <RowComponent 
                  columns={props.columns}
                  expandable={props.expandable}
                  row={row}
                  rowIndex={rowIndex}
                  key={rowIndex}
                />
              )}
            </Fragment>
          )
        }
        else return props.dataSources.map((row, rowIndex) =>
          <RowComponent 
            columns={props.columns}
            expandable={props.expandable}
            row={row}
            rowIndex={rowIndex}
            key={rowIndex}
            withoutDefaultStyles={props.withoutDefaultStyles}
          />
        )
      }
      else return (
        <tr data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_ROW}>
          <td
            data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_COLUMN}
            className='text-center text-danger'
            colSpan={props.expandable ? props.columns.length + 1 : props.columns.length}
          >
            Data Not Found
          </td>
        </tr>
      )
    }
  }

  const footerComponentRenderer = () => {
    if(props.pagination || props.limitConfig) {
      return (
        <div className={`d-flex ${props.limitConfig ? 'justify-content-between' : 'justify-content-center'} align-items-center pagination-data`}>
          {props.pagination && 
            <Pagination
              itemClass='page-item'
              linkClass='page-link'
              pageRangeDisplayed={5}
              {...props.pagination}
            />
          }
          {props.limitConfig &&
            <div className='d-flex align-items-center gap-2'>
              <span>Select Limit:</span>
              <Dropdown>
                <Dropdown.Toggle className="d-flex align-items-center justify-content-between" style={{minWidth: 80}}>{props.limitConfig.selectedLimit}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.limitConfig.limits.map((limit, index) =>
                    <Dropdown.Item active={props.limitConfig?.selectedLimit === limit} key={index} onClick={()=>props.limitConfig?.onSelect(limit)}>{limit}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        </div>
      )
    }
  }

  return (
    <Fragment>
      <Table 
        striped={!props.withoutDefaultStyles}
        responsive
        className={props.className}
      >
        {!props.withoutHeader &&
          <thead>
            <tr data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_ROW_HEAD}>
              {props.columns.map((column, index) => (
                <HeaderTable 
                  key={index}
                  currentSort={currentSort}
                  setCurrentSort={setCurrentSort}
                  {...column}
                />
              ))}
              {props.expandable && <th>&nbsp;</th>}
            </tr>
          </thead>
        }
        <tbody>
        {loadingRenderingHandler()}

        </tbody>
      </Table>
      {footerComponentRenderer()}
    </Fragment>
  )
}

interface RowComponentProps<T> extends Pick<ReusableTableProps<T>, "columns" | "expandable"> {
  row: T;
  rowIndex: number;
  withoutDefaultStyles?: boolean;
}

const RowComponent = <T extends object>(props: RowComponentProps<T>) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  return (
    <Fragment>
      <tr data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_ROW}>
        {props.columns.map((column, cIndex) => {
          const { style, ...anotherProps } = column
          return (
            <td data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_COLUMN} key={cIndex} style={{...style, padding: props.withoutDefaultStyles ? 0 : undefined}}>
              {anotherProps.render ? anotherProps.render(props.row[anotherProps.dataIndex], props.row, props.rowIndex) : props.row[anotherProps.dataIndex] as any}
            </td>
          )
        })}
        {props.expandable && 
          <td 
            data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_COLUMN} 
            onClick={()=>props.expandable?.rowExpandable(props.row) && setIsExpanded(!isExpanded)}
          >
            {props.expandable?.rowExpandable(props.row) ? (props.expandable?.expandedRowTrigger ? props.expandable?.expandedRowTrigger(props.row) : <Button variant="primary">Expand</Button>) : <td>&nbsp;</td>}
          </td>
        }
      </tr>
      {props.expandable &&
        <tr data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_ROW} style={{ display: isExpanded ? 'block' : 'none' }}>
          <td data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_COLUMN} colSpan={props.expandable ? props.columns.length + 1 : props.columns.length}>
            {props.expandable.expandedRowRender(props.row)}
          </td>
        </tr>
      }
    </Fragment>
  )
}

export interface ReusableTableHeaderTableProps<T> extends ReusableTableColumn<T>{
  currentSort?: SortProps<T>;
  setCurrentSort: Dispatch<SetStateAction<SortProps<T> | undefined>>;
}
const HeaderTable = <T extends object>(props: ReusableTableHeaderTableProps<T>) => {
  const { dataIndex, title, render, isSortMode, currentSort, setCurrentSort, headerClassName, headerStyle, ...otherColumn } = props;
  
  const getClassDirection = () => {
    if(currentSort?.order_by === dataIndex && currentSort?.order_sort === "desc") return "reusable-table-header-sort-trigger--desc"
    else if(currentSort?.order_by === dataIndex && currentSort?.order_sort === "asc") return "reusable-table-header-sort-trigger--asc"
    else return ""
  }
  return (
    <th 
      data-testid={Constant.UNIT_TEST_PREFIX_ID.REUSABLE_TABLE_COLUMN_HEAD} 
      {...otherColumn}
      style={{
        ...headerStyle,
        color: props.isSortMode ? 'blue' : undefined
      }}
      className={`${isSortMode ? "reusable-table-header-sort" : ""} ${headerClassName ?? ""}`}
      onClick={()=>isSortMode && setCurrentSort(prev => {
        if(prev) {
          if(prev.order_sort === "asc") return {
            order_sort: "desc",
            order_by: dataIndex
          }
          else return undefined
        }
        else return {
          order_sort: "asc",
          order_by: dataIndex
        }
      }) }
    >
      <div className="custom-flex-row-center">
        <span>{title}</span>
        {isSortMode && <i className={`reusable-table-header-sort-trigger ${getClassDirection()} la la-angle-right`} />}
      </div>
    </th>
  )
}

export default ReusableTable