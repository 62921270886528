import instance from './uidConverterInstance';

const getInstance = (url) => instance().get(url);
const postInstance = (url, body) => instance().post(url, body);

const fetchUIDConverterHistory = (params) => {
  return new Promise((resolve, reject) => {
    getInstance('kyc-import' + params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const downloadUIDConverterDataById = (id) => {
  return new Promise((resolve, reject) => {
    getInstance('kyc-import/download-csv/' + id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const uploadUIDConverterCsvFile = (body) => {
  return new Promise((resolve, reject) => {
    postInstance('kyc-import/submission', body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  fetchUIDConverterHistory,
  downloadUIDConverterDataById,
  uploadUIDConverterCsvFile,
};
