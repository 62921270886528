import React, { Component, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from './Form';
import courseService from '../../../crud/course';
import Swal from 'sweetalert2';

const EditCourse = (props) => {
  const [isEdit, setisEdit] = useState(false);
  const submitRef = useRef(null);
  const [data, setdata] = useState(null);
  const { id } = useParams();

  const getToken = () => localStorage.getItem('token');

  const handleGetDetail = async () => {
    try {
      const res = await courseService.fetchCourseDetail(getToken(), id);
      setdata(res.data.data);
    } catch (error) {
      console.log(error, 'Error');
    }
  };

  useEffect(() => {
    handleGetDetail();
  }, [id]);

  const formatDate = (date) => {
    return date ? (
      <>{new Date(new Date(date || '') - 420 * 60000).toLocaleString()} </>
    ) : null;
  };

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='d-flex justify-content-between align-content-center'>
              <div className=''>
                <p className='d-inline-block font-weight-bold'>
                  Introduction to Trading Course
                </p>
                <p className='d-block'>
                  Last Update on {formatDate(data?.updated_at || null)} by
                  {' ' + data?.updated_by || ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <Form data={data} submitRef={submitRef} isEdit={isEdit} />
            <div className='flex-fill mt-3 d-flex justify-content-end align-items-center'>
              <button
                class='btn btn-outline-info mr-2'
                type='submit'
                onClick={() => {
                  props.history.push(
                    '/tokoscholar/topics?courseId=' + data?.id || ''
                  );
                }}
              >
                See All Topics
              </button>

              <button
                class='btn btn-outline-warning mr-2'
                type='submit'
                onClick={() => {
                  Swal.fire({
                    title: `Are you sure to update this data ?`,
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Update!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      submitRef.current();
                    }
                  });
                }}
              >
                Edit Course
              </button>

              <button
                class='btn btn-outline-danger '
                type='submit'
                onClick={() => props.history.push('/tokoscholar/courses')}
              >
                Back To Course List
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCourse;
