import Chart, { ChartDataSets, ChartTooltipCallback, ChartYAxe } from "chart.js";
import { ReactNode, useEffect, useRef } from "react"
import ReactLoading from 'react-loading'

export interface ReusableChartProps<T> {
  loading?: boolean;
  datas: Array<{datasets: ChartDataSets, yAxes: ChartYAxe}>;
  title?: ReactNode;
  labels?: Array<string | Array<string>>;
  customTooltips?: ChartTooltipCallback;
}

const ReusableChart = <T extends object>(props: ReusableChartProps<T>) => {
  const ref = useRef<HTMLCanvasElement>(null)

  const chartBinder = () => {
    let chart: Chart | undefined
    if(ref.current) {
      chart = new Chart(ref.current, {
        data: {
          labels: props.labels,
          datasets: props.datas.map(data => data.datasets)
        },
        type: "bar",
        options: {
          title: { display: false },
          tooltips: {
            intersect: false,
            mode: "nearest",
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10,
            callbacks: props.customTooltips,
          },
          legend: { display: false },
          responsive: true,
          maintainAspectRatio: false,
          scales:{
            yAxes: props.datas.map(data => ({...data.yAxes, stacked: true})),
            xAxes: props.datas.map(() => ({stacked: true}))
          },
          layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } }
        }
      })
    }
    return () => {
      chart?.destroy()
    }
  }

  useEffect(chartBinder, [props.datas, ref.current])

  if(props.loading) return (
    <div className="custom-flex-center">
      <ReactLoading type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} />
    </div>
  )
  else return <canvas ref={ref} />
}

export default ReusableChart