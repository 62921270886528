import { useFormik } from 'formik'
import * as yup from 'yup';
import React, { FC, FormEvent, FormEventHandler, Fragment, MouseEvent, MouseEventHandler, PropsWithChildren, useEffect, useState } from 'react'
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import ReusableListPage from '../../components/reusableListPage';
import { convertToCSV, numFormatter, useDebounce } from '../../helper';
import { checkDJPSummary, checkProcessDJP, checkSaveProcessDJP, getDJPDataPerYear, requestSubmitDJP, saveRequestDJP } from '../../crud/tax-service';
import ReusableTable, { ReusableTableColumn, ReusableTableProps } from '../../components/reusableTable';
import { CheckDJPSummaryResponse, CheckSaveProcessDJPResponse, monthNames } from '../../crud/tax-types';
import ReactDatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading'
import moment from 'moment';
import PaginationTable from '../../components/reusablePaginationTable';

interface DateInput {
  month: number; 
  year: number;
}
export interface DJP {
  created_at: string;
  days_of_month: number;
  days_save: number;
  id: number;
  is_processed: boolean;
  is_saved: boolean;
  month: number;
  on_process: boolean;
  on_save: boolean;
  processed_data: number | null // if on_process: true, it will be return number
  saved_by: string;
  send_by: string | null
  total_data: number;
  updated_at: string;
  usdt_bidr_rate: string;
  year: number;
}

// const limit = 15

const convertToCSVHandler = (sources: Array<CheckDJPSummaryResponse>, date: DateInput) => {
  const datas: Array<Array<string>> = [["Commission Asset","Total Fee"]].concat(sources.map(ds => [ds.b_commission_asset.toString(),ds.totalFee.toString()]))
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let docName = "_.csv"
  if(!isNaN(date.month)) {
    const indexNumber = date.month - 1 >= 0 ? date.month - 1 : 12
    docName = [monthNames[indexNumber], date.year].join("_") + docName
  }
  convertToCSV(datas, docName)
}

const SetupDJPDocs = () => {
  // const [progress, setProgress] = useState<CheckSaveProcessDJPResponse | undefined>(undefined)
  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataSources, setDataSources] = useState<Array<CheckDJPSummaryResponse>>([])
  const [summarySources, setSummarySources] = useState<Array<CheckDJPSummaryResponse>>([])
  const [dataDJP, setDataDJP] = useState<Array<DJP>>([])
  // const [activePage, setActivePage] = useState<number>(1)
  const [selectedMonthRate, setSelectedMonthRate] = useState<boolean>(false)

  useEffect(()=>{
    if(!selectedMonthRate) formikConfig.resetForm()
  },[selectedMonthRate])

  const columnDJP = (newActivePage: number, newLimit: number): Array<ReusableTableColumn<DJP>> => [
    { dataIndex: 'id', title: "No", render: (_, _data, index) => ((newActivePage - 1) * newLimit) + index + 1 },
    { 
      dataIndex: 'month', 
      title: "Month", 
      render: (_,data) => {
        if(!isNaN(data.month)) {
          const indexNumber = data.month - 1 >= 0 ? data.month - 1 : 12
          return monthNames[indexNumber]
        }
        else return "-"
      } 
    },
    { 
      dataIndex: 'usdt_bidr_rate', 
      title: "USDT - BIDR Rate",
      render: (_, data) => numFormatter(Number(data.usdt_bidr_rate))
    },
    { 
      dataIndex: 'created_at', 
      title: "Created Date",
      render: (_,data) => moment(data.created_at).format('DD-MMMM-YYYY hh:mm:ss A')
    },
    { 
      dataIndex: 'updated_at', 
      title: "Updated Date",
      render: (_,data) => moment(data.created_at).format('DD-MMMM-YYYY hh:mm:ss A')
    },
    { dataIndex: 'saved_by', title: "Edit Rate By" },
    { dataIndex: 'send_by', title: "Send DJP By" },
    { 
      dataIndex: 'id', 
      title: "Status",
      render: (_,data) => (
        <StatusComponent 
          {...data}
          onChange={(_type, newData) => {
            setDataDJP((djpData) => {
              return djpData.map(d => {
                if(d.id === newData.id) {
                  return {
                    ...d,
                    ...newData
                  }
                }
                else return d
              })
            })
          }}
        />
      )
    },
    {
      dataIndex: 'id',
      title: 'Action',
      render: (_, data) => (
        <ActionComponent 
          {...data} 
          onChange={(newData) => {
            setDataDJP((djpData) => {
              return djpData.map(d => {
                if(d.id === newData.id) {
                  return {
                    ...d,
                    ...newData
                  }
                }
                else return d
              })
            })
          }}
        />
      )
    }
  ]
  // const getDataSourceByPartition = () => {
  //   const minIndex = (activePage-1) * limit
  //   const maxIndex = activePage * limit
  //   let result: Array<DJP> = []

  //   for(let i = minIndex; i < maxIndex; i++) {
  //     if(dataDJP[i]) result.push(dataDJP[i])
  //   }

  //   return result
  // }
  const [initialValues, setInitialValues] = useState({
    bidr: "0",
    date: null
  })
  const [selectedYear, setSelectedYear] = useState<Date>(new Date())
  const columns = (activePage: number, limit: number): Array<ReusableTableColumn<CheckDJPSummaryResponse>> => [
    {
      title: 'No.',
      dataIndex: 'totalFee',
      render: (_, _data, index) => ((activePage - 1) * limit) + index + 1
    },
    {
      title: 'SKU',
      dataIndex: 'b_commission_asset'
    },
    {
      title: 'Total in BIDR',
      dataIndex: 'totalFee',
      render: (dataIndex) => numFormatter(Number(dataIndex)),
      style: {
        textAlign: 'right'
      }
    },
  ]
  const formikConfig = useFormik({
    initialValues,
    validationSchema: yup.object({
      bidr: yup
        .number()
        .moreThan(0, 'Must more than 0'),
      date: yup.mixed().nullable().required('Date Must be fill!')
    }),
    onSubmit: (vals) => {
      const selectedDate = vals.date ?? new Date()
      setIsSummaryLoading(true)
      checkDJPSummary({
        month: selectedDate.getMonth() + 1,
        year: selectedDate.getFullYear(),
        bidr_rate: Number(vals.bidr),
      })
        .then((res)=>{
          if(selectedMonthRate) {
            setSummarySources(res.data.data ?? [])
            getDJPDataPerYearService(selectedYear)
          }
          else {
            setDataSources(res.data.data ?? [])
          }
          setInitialValues(vals)
        })
        .catch(err => console.log(err))
        .finally(()=>setIsSummaryLoading(false))
    }
  })

  useEffect(()=>{
    setSummarySources([])
  },[formikConfig?.values.bidr, formikConfig?.values.date])

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   formikConfig.handleSubmit(e);
  // }
  // const getSaveProcess = (sDate?: Date | null) => {
  //   checkSaveProcessDJP({
  //     year: (sDate ?? new Date()).getFullYear(),
  //     month: (sDate ?? new Date()).getMonth() + 1
  //   })
  //     .then((res)=>{
  //       setProgress(typeof res.data.data !== "boolean" ? res.data.data : undefined)
  //     })
  //     .catch(err => console.log(err))
  // }
  // const getDJPProcess = () => {
  //   checkProcessDJP()
  //     .then((res)=>{
  //       console.log(res)
  //     })
  //     .catch(err => console.log(err))
  // }
  // const saveChanges = () => {
  //   setLoading(true)
  //   saveRequestDJP({
  //     bidr_rate: Number(initialValues.bidr),
  //     month: (formikConfig.values.date ?? new Date()).getMonth() + 1,
  //     year: (formikConfig.values.date ?? new Date()).getFullYear(),
  //   })
  //     .then(()=>{
  //       setDataSources([])
  //       // getSaveProcess(formikConfig.values.date ?? new Date())
  //     })
  //     .catch(err => console.log(err))
  //     .finally(()=>setLoading(false))
  // }
  const saveNewRate = () => {
    formikConfig.validateForm()
      .then(()=>{
        const { values } = formikConfig
        setIsSummaryLoading(true)
        saveRequestDJP({
          bidr_rate: Number(values.bidr),
          month: (values.date ? new Date(values.date) : new Date()).getMonth() + 1,
          year: (values.date ? new Date(values.date) : new Date()).getFullYear(),
        })
          .then(()=>{
            setSelectedMonthRate(false)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(()=>{
            setIsSummaryLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // const firstInit = () => {
  //   getDJPProcess()
  // }
  // useEffect(firstInit,[])
  // useDebounce((ndate)=>{
  //   getSaveProcess(ndate)
  // }, [formikConfig.values.date], 200)

  const getDJPDataPerYearService = (year: Date) => {
    getDJPDataPerYear(year.getFullYear())
      .then(res=>{
        setDataDJP(res.data.data)
      })
      .catch(err=>{
        console.log(err)
      })
  }

  useEffect(()=>{
    getDJPDataPerYearService(selectedYear)
  }, [selectedYear])
  // const sendToDJP = () => {
  //   requestSubmitDJP({
  //     month: (formikConfig.values.date ?? new Date()).getMonth() + 1,
  //     year: (formikConfig.values.date ?? new Date()).getFullYear(),
  //   })
  //     .then(()=>{
  //       Swal.fire(
  //         'Success!',
  //         `Terkirim ke DJP!`,
  //         'success'
  //       )
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }
  // const sendToDJPHandler = () => {
  //   Swal.fire({
  //     title: 'Confirmation',
  //     html: `Are you sure to Sent All Data ${selectedYear.getFullYear()} to DJP ?`,
  //     confirmButtonText: "YES!!",
  //     showCancelButton: true,
  //     cancelButtonText: "NO",
  //     icon: 'question'
  //   })
  //     .then(s => {
  //       if(s.isConfirmed) {
  //         const promises = dataDJP.map(val => requestSubmitDJP({
  //           month: val.month,
  //           year: val.year
  //         }))
  //         Promise.all(promises)
  //           .then(() => getDJPDataPerYearService(selectedYear))
  //           .catch((err) => {
  //             console.log(err)
  //           })
  //       }
  //     })
  // }
  // const sentToDJPComponent = () => {
  //   if(dataDJP.every(djp => djp.on_save === false && djp.on_process === false)) return (
  //     <button className="btn btn-primary custom-btn" onClick={()=>sendToDJPHandler()}>Sent Datas {selectedYear.getFullYear()} to DJP</button>
  //   )
  //   else return null
  // }
  return (
    <Fragment>
      {/* <SectionWrapper>
        <form className="m-login__form m-form" onSubmit={(e) => handleSubmit(e)}>
          <div 
            style={{
              display:"flex",
              flexDirection:"row",
              gap: 15
            }}
          >
            <Form.Group>
              <Form.Label>USDT to BIDR Rate</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter BIDR Rate'
                name='bidr'
                onChange={(e) => {
                  formikConfig.setFieldValue('bidr', Number(e.target.value ?? 0).toString())
                }}
                isInvalid={!!(formikConfig.errors.bidr && formikConfig.touched.bidr)}
                value={formikConfig.values.bidr}
              />
              {formikConfig.errors.bidr &&
                formikConfig.touched.bidr && (
                  <Form.Control.Feedback type="invalid">
                    <span style={{ fontSize: '13px' }}>
                      {formikConfig.errors.bidr}
                    </span>
                  </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Selected Month</Form.Label>
              <br />
              <ReactDatePicker 
                className={`form-control ${
                  formikConfig.errors.date &&
                  formikConfig.touched.date
                    ? 'is-invalid'
                    : ''
                }`}
                selected={formikConfig?.values.date}
                onChange={(date) => formikConfig?.setFieldValue('date', date)}
                dateFormat="MM/yyyy"
                maxDate={new Date()}
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </Form.Group>
            <div 
              style={{
                display:'flex',
                flexDirection:'row',
                gap: 15
              }}
              className="mt-3"
            >
              <button 
                type="button"
                className="btn btn-success"
                disabled={isSummaryLoading}
                onClick={()=>formikConfig.handleSubmit()}
              >
                {isSummaryLoading ? 'Please wait ..' : 'Save'}
              </button>
              {(progress?.days_save ?? 0) / (progress?.days_of_month ?? 0) >= 1 &&
                <button 
                  type="button"
                  className="btn btn-primary"
                  onClick={()=>sendToDJP()}
                >
                  Kirim ke DJP
                </button>
              }
            </div>
          </div>
          <Modal show={isSummaryLoading} centered>
            <Modal.Header>
              <Modal.Title className='custom-flex-row-space-between-center gap-2'>
                <ReactLoading color="#5d78ff" height={20} width={20} type="spin" />
                <div>Warning</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please do not close your page. We're currently getting your coins' summary rate.
            </Modal.Body>
          </Modal>
          <Modal show={dataSources.length > 0} onHide={()=>setDataSources([])}>
            <Modal.Body style={{padding: '1.25rem 0 1.25rem 0'}}>
              <ReusableListPage 
                loading={loading}
                dataSources={dataSources}
                columns={columns}
              />
            </Modal.Body>
            <Modal.Footer className='custom-flex-row-space-between gap-2'>
              <div>Total in BIDR</div>
              <div>Rp {dataSources.length > 0 ? numFormatter(dataSources.map(d => Number(d.totalFee)).reduce((total,current)=>total+current)) : 0}</div>
            </Modal.Footer>
            <Modal.Footer>
              <Button variant="danger" onClick={()=>setDataSources([])}>
                Cancel
              </Button>
              <Button variant="success" onClick={()=>saveChanges()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </form>
      </SectionWrapper>
      {progress &&
        <SectionWrapper>
          <Form.Group>
            <div className='custom-flex-row-space-between'>
              <Form.Label>
                Saving Progress ({(progress.days_save/progress.days_of_month) * 100}%)
              </Form.Label>
              {(progress?.days_save ?? 0) < (progress?.days_of_month ?? 0) &&
                <button style={{width: 'none'}} type="button" className="btn btn-success" onClick={()=>getSaveProcess(formikConfig.values.date ?? new Date())}>
                  Refresh
                </button>
              }
            </div>
            <ProgressBar 
              style={{
                margin: '10px 0 10px 0'
              }}
              animated 
              variant="success" 
              now={(progress.days_save/progress.days_of_month) * 100} 
              label={`${progress.days_save} / ${progress.days_of_month}`} 
            />
          </Form.Group>
        </SectionWrapper>
      } */}
      <Modal show={selectedMonthRate} onHide={()=>setSelectedMonthRate(false)} size="lg">
        <Modal.Body style={{padding: '1.25rem 1rem 1.25rem 1rem'}}>
          <div 
            style={{
              display:"flex",
              flexDirection:"row",
              gap: 15
            }}
          >
            <Form.Group>
              <Form.Label>USDT to BIDR Rate</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter BIDR Rate'
                name='bidr'
                onChange={(e) => {
                  formikConfig.setFieldValue('bidr', Number(e.target.value ?? 0).toString())
                }}
                isInvalid={!!(formikConfig.errors.bidr && formikConfig.touched.bidr)}
                value={formikConfig.values.bidr}
              />
              {formikConfig.errors.bidr &&
                formikConfig.touched.bidr && (
                  <Form.Control.Feedback type="invalid">
                    <span style={{ fontSize: '13px' }}>
                      {formikConfig.errors.bidr}
                    </span>
                  </Form.Control.Feedback>
                )
              }
            </Form.Group>
            <Form.Group>
              <Form.Label>Selected Month</Form.Label>
              <br />
              <ReactDatePicker 
                className={`form-control ${
                  formikConfig.errors.date &&
                  formikConfig.touched.date
                    ? 'is-invalid'
                    : ''
                }`}
                placeholderText="Set Another Rates"
                selected={formikConfig?.values.date}
                onChange={(date) => formikConfig?.setFieldValue('date', date)}
                dateFormat="MMMM yyyy"
                maxDate={new Date()}
                filterDate={(date) => {
                  if(dataDJP.length > 0 && selectedYear.getFullYear() === new Date().getFullYear()) {
                    const months = [...dataDJP].sort((a,b) => {
                      if(a.month<b.month) return 1
                      else return -1
                    }).map(djp => djp.month)
                    const selectedMonth = months[0] === 12 ? 1 : months[0] + 1
                    const disabledDate = new Date(`${selectedMonth}/01/${new Date().getFullYear()}`)
                    const minusDay = new Date(new Date(disabledDate).setDate(new Date(disabledDate).getDate()-1))
                    return date > minusDay
                  }
                  else return true
                }}
                isClearable
                showMonthYearPicker
              />
              {formikConfig.errors.date &&
                formikConfig.touched.date && (
                  <Form.Text className='text-danger' style={{ fontSize: '13px' }}>
                    {formikConfig.errors.date}
                  </Form.Text>
                )
              }
            </Form.Group>
            <Form.Group>
              <Form.Label>Action</Form.Label>
              <br />
              <div 
                style={{
                  display:'flex',
                  flexDirection:'row',
                  gap: 15
                }}
              >
                <button 
                  type="button"
                  className="btn btn-success"
                  disabled={isSummaryLoading}
                  onClick={()=>formikConfig.handleSubmit()}
                >
                  {isSummaryLoading ? 'Please wait ..' : 'Get Summaries'}
                </button>
                {summarySources.length > 0 &&
                  <button 
                    type="button"
                    className="btn btn-primary"
                    disabled={isSummaryLoading}
                    onClick={()=>saveNewRate()}
                  >
                    Save New Rates
                  </button>
                }
              </div>
            </Form.Group>
          </div>
          <PaginationTable 
            loading={loading}
            dataSources={summarySources}
            columns={columns}
          />
        </Modal.Body>
        <Modal.Footer className='custom-flex-row-space-between gap-2'>
          {summarySources.length > 0 &&
            <button 
              onClick={()=>{
                formikConfig.validateForm()
                  .then(() => {
                    const { values } = formikConfig
                    convertToCSVHandler(summarySources,{
                      month: (values.date ? new Date(values.date) : new Date()).getMonth() + 1,
                      year: (values.date ? new Date(values.date) : new Date()).getFullYear(),
                    })
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }} 
              className="btn btn-success custom-btn" 
              type="button"
            >
              Convert to CSV
            </button>
          }
          <div>Total in BIDR</div>
          <div>Rp {summarySources.length > 0 ? numFormatter(summarySources.map(d => Number(d.totalFee)).reduce((total,current)=>total+current)) : 0}</div>
        </Modal.Footer>
      </Modal>
      <SectionWrapper>
        <div className='custom-flex-row-space-between mb-3'>
          <h2>DJP Sent Data Record</h2>
          <div className="custom-flex-row-center gap-2">
            <div className='custom-flex-row-center gap-2'>
              <div>Year</div>
              <ReactDatePicker 
                // className={`form-control ${
                //   formikConfig.errors.date &&
                //   formikConfig.touched.date
                //     ? 'is-invalid'
                //     : ''
                // }`}
                className="form-control"
                selected={selectedYear}
                onChange={(date) => date && setSelectedYear(date)}
                dateFormat="yyyy"
                maxDate={new Date()}
                showYearPicker
              />
            </div>
            <button className="btn btn-primary" onClick={()=>getDJPDataPerYearService(selectedYear)}>Refresh</button>
            <button className="btn btn-primary" onClick={()=>setSelectedMonthRate(true)}>Set Another Rates</button>
          </div>
        </div>
        {/* <ReusableTable 
          dataSources={getDataSourceByPartition()}
          columns={columnDJP}
          pagination={{
            itemsCountPerPage: limit,
            totalItemsCount: dataDJP.length,
            onChange: (pageNumber: number) => setActivePage(pageNumber),
            activePage,
          }}
        /> */}
        <PaginationTable 
          dataSources={dataDJP}
          columns={columnDJP}
        />
        {/* {sentToDJPComponent()} */}
      </SectionWrapper>
    </Fragment>
  )
}

interface StatusComponentProps extends DJP {
  onChange: (type: "OVERWRITE_RATE" | "SENT_TO_DJP", data: CheckSaveProcessDJPResponse) => void;
}
const StatusComponent: FC<StatusComponentProps> = (props) => {
  const onChangeHandler = (type: "OVERWRITE_RATE" | "SENT_TO_DJP") => {
    if(type === "OVERWRITE_RATE") {
      checkSaveProcessDJP({
        year: props.year,
        month: props.month
      })
        .then((res) => {
          if(res.data.data) props.onChange(type, res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const contents = () => {
    if(props.is_processed) return <div className="text-success">Sent</div>
    else if(props.on_process) return <div className="text-warning">
      Sent to DJP ({props.processed_data}/{props.total_data})
    </div>
    else if(props.on_save) return <div className="text-warning">
      Overwrite rate (day {props.days_save}/{props.days_of_month}) <button className="btn btn-primary custom-btn" onClick={()=>onChangeHandler("OVERWRITE_RATE")}>Refresh</button>
    </div>
    else if(props.is_saved) return <div className="text-success">Ready send to DJP</div>
    else return "-"
  }
  return (
    <Fragment>
      {contents()}
    </Fragment>
  )
}

interface ActionComponentProps extends DJP {
  onChange: (data: CheckSaveProcessDJPResponse) => void;
}
const ActionComponent: FC<ActionComponentProps> = (props) => {
  const [isEditRate, setIsEditRate] = useState(false)
  const formik = useFormik({
    initialValues: {
      bidr: Number(props.usdt_bidr_rate)
    },
    validationSchema: yup.object({
      bidr: yup
        .number()
        .moreThan(0, 'Must more than 0'),
    }),
    onSubmit: (vals) => {
      saveRequestDJP({
        bidr_rate: Number(vals.bidr),
        month: props.month,
        year: props.year,
      })
        .then(()=>{
          checkSaveProcessDJP({
            month: props.month,
            year: props.year,
          })
            .then((res) => {
              if(res.data.data) props.onChange(res.data.data)
              Swal.fire('Success','Successfully update rate!','success')
            })
            .catch(err => {
              console.log(err)
            })
            .finally(()=>{
              setIsEditRate(false)
            })
        })
        .catch(err => {
          console.log(err)
        })
    }
  })
  useEffect(()=>{
    if(!isEditRate) formik.resetForm()
  },[isEditRate])
  const sentToDJPPerData = () => {
    Swal.fire({
      title: 'Confirmation',
      html: 'Are you sure to Sent this data to DJP ?',
      showCancelButton: true,
      cancelButtonText: "NO",
      confirmButtonText: "YES!",
      icon: 'question'
    })
      .then((s) => {
        if(s.isConfirmed) {
          requestSubmitDJP({
            month: props.month,
            year: props.year
          })
            .then(()=>{
              checkSaveProcessDJP({
                month: props.month,
                year: props.year,
              })
                .then((res) => {
                  if(res.data.data) props.onChange(res.data.data)
                  Swal.fire('Success','Successfully send to DJP!','success')
                })
                .catch(err => {
                  console.log(err)
                })
                .finally(()=>{
                  setIsEditRate(false)
                })
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const contents = () => {
    if(isEditRate) return (
      <Fragment>
        <Form.Group>
          <Form.Control
            type='number'
            placeholder='Enter BIDR Rate'
            name='bidr'
            onChange={(e) => {
              formik.setFieldValue('bidr', Number(e.target.value ?? 0).toString())
            }}
            isInvalid={!!(formik.errors.bidr && formik.touched.bidr)}
            value={formik.values.bidr}
          />
          {formik.errors.bidr &&
            formik.touched.bidr && (
              <Form.Control.Feedback type="invalid">
                <span style={{ fontSize: '13px' }}>
                  {formik.errors.bidr}
                </span>
              </Form.Control.Feedback>
            )}
        </Form.Group>
        <button className="btn btn-success" type="button" onClick={()=>formik.handleSubmit()}>Submit Rate</button>
        <button className="btn btn-danger" onClick={()=>setIsEditRate(false)}>Cancel</button>
      </Fragment>
    )
    else if(!props.is_processed) return (
    // else return (
      <Fragment>
        <SummaryComponent {...props} />
        {(!props.on_save && !props.on_process) && <button className="btn btn-primary custom-btn" onClick={()=>setIsEditRate(true)}>Edit Rate</button>}
        {(!props.on_process) && <button className="btn btn-success custom-btn" onClick={()=>sentToDJPPerData()}>Sent to DJP</button>}
      </Fragment>
    )
    else return "-"
  }
  return (
    <div className="custom-flex-row-center gap-2">
      {contents()}
    </div>
  )
}

const SummaryComponent: FC<ActionComponentProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSources, setDataSources] = useState<Array<CheckDJPSummaryResponse>>([])

  const getAssetsPrice = () => {
    setLoading(true)
    checkDJPSummary({
      month: props.month,
      year: props.year,
      bidr_rate: Number(props.usdt_bidr_rate),
    })
      .then((res)=>{
        setDataSources(res.data.data ?? [])
      })
      .catch(err => console.log(err))
      .finally(()=>setLoading(false))
  }

  const columns = (activePage: number, limit: number): Array<ReusableTableColumn<CheckDJPSummaryResponse>> => [
    {
      title: 'No.',
      dataIndex: 'totalFee',
      render: (_, _data, index) => ((activePage - 1) * limit) + index + 1
    },
    {
      title: 'SKU',
      dataIndex: 'b_commission_asset'
    },
    {
      title: 'Total in BIDR',
      dataIndex: 'totalFee',
      render: (dataIndex) => numFormatter(Number(dataIndex)),
      style: {
        textAlign: 'right'
      }
    },
  ]

  return (
    <Fragment>
      <button 
        className="btn btn-primary custom-btn" 
        onClick={()=>getAssetsPrice()}
        disabled={loading}
      >
        {loading ? 'Loading ..' : `View Asset Price (IDR)`}
      </button>
      <Modal show={dataSources.length > 0} onHide={()=>setDataSources([])}>
        <Modal.Body style={{padding: '1.25rem 1rem 1.25rem 1rem'}}>
          <PaginationTable 
            loading={loading}
            dataSources={dataSources}
            columns={columns}
          />
        </Modal.Body>
        <Modal.Footer className='custom-flex-row-space-between gap-2'>
          <button onClick={()=>convertToCSVHandler(dataSources, props)} className="btn btn-success custom-btn" type="button">Convert to CSV</button>
          <div>Total in BIDR</div>
          <div>Rp {dataSources.length > 0 ? numFormatter(dataSources.map(d => Number(d.totalFee)).reduce((total,current)=>total+current)) : 0}</div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export const SectionWrapper: FC<PropsWithChildren> = (props) => {
  return (
    <div style={{ fontSize: '10px' }} className="head">
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupDJPDocs