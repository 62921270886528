import React, { useState, useEffect, Fragment } from 'react';
// import { Button, InputGroup, FormControl, Table } from "react-bootstrap";
import ReactLoading from 'react-loading';
import moment from 'moment';
import thubActions from '../../../crud/thub';
import {
  Button,
  InputGroup,
  FormControl,
  Table,
  Modal,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowBack } from '@material-ui/icons';
require('bootstrap/scss/bootstrap.scss');

// const history = createBrowserHistory();

const ThubEventsHistory = (props) => {
  const [eventHistory, setEventHistory] = useState({
    data: [],
    totalData: 0,
    currentPage: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState();

  const { id, name } = useParams();

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
  };

  const geteventHistory = async (page) => {
    if (token) {
      setIsLoading(true);
      const params = `&page=${page || 1}&limit=10`;
      await thubActions
        .fetchThubBookingOrderHistory(token, id, params)
        .then((res) => {
          setEventHistory((prevState) => {
            return {
              ...prevState,
              data: res.data.data.histories,
              totalData: res.data.data.totalData,
              totalDataOnPage: res.data.data.totalDataOnPage,
              currentPage: res.data.data.currentPage,
              totalPages: res.data.data.totalPages,
            };
          });
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setEventHistory((prevState) => {
            return {
              ...prevState,
              data: [],
              totalData: 0,
              currentPage: 0,
              totalDataOnPage: 0,
              totalPages: 0,
            };
          });
          if (e.response) {
            if (
              e.response.data.code === 401 ||
              e.response.data.code === 500
            ) {
              toast.error('token has expired');
              localStorage.clear();
              props.history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const getPaymentStatus = (id) => {
    switch (id) {
      case 0:
        return 'Waiting for Payment';
      case 1:
        return 'Paid';
      case 2:
        return 'Expired';
      case 3:
        return 'Waiting for Confirmation';

      default:
        return 'Waiting for Payment';
    }
  };

  const getApprovalStatus = (id) => {
    switch (id) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';

      default:
        return 'Pending';
    }
  };

  const getDateTime = (date) => {
    const fmtDate = moment(date).format('DD MMM YYYY HH:mm:ss');

    return fmtDate;
  };

  const handlePageChange = (page) => {
    setEventHistory((prev) => ({
      ...prev,
      currentPage: page,
    }));

    geteventHistory(page);
  };

  useEffect(() => {
    getToken();
    setTimeout(() => {
      geteventHistory();
    }, 1000);
  }, [token]);

  return (
    <Fragment>
      <div style={{ fontSize: '10px' }} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                {/* <div className="row row-search">
                  <div className="col-sm-4">
                    <InputGroup size="sm">
                      <FormControl
                        value={this.state.seacrhParams}
                        onChange={this.changeSearch}
                        placeholder="Search"
                      />
                        <Button
                          variant="outline-secondary"
                          onClick={() => this.checkParams()}
                        >
                          Search
                        </Button>
                    </InputGroup>
                  </div>
                </div> */}
                <div className="row row-search">
                  <div className="col-sm-12">
                    <h4>
                      {id} - {name}'s event history log
                    </h4>
                  </div>
                </div>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                          width: '7%',
                        }}
                      >
                        Created at &nbsp;
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Record ID &nbsp;
                        {/* <button
                          onClick={(e) => this.onSortChange('id')}
                          className="sort-button"
                        >
                          <i
                            className={`fas fa-${
                              sortTypes[this.state.orderSort].class
                            }`}
                          />
                        </button> */}
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Start Date - End Date &nbsp;
                      </th>
                      <th className="left">Approval Status</th>
                      <th className="left">Payment Status</th>
                      <th className="left">Updated at</th>
                      <th className="left">Updated by</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td className="center" colSpan="6">
                          <ReactLoading
                            style={{
                              position: 'relative',
                              left: '50%',
                              fill: 'rgb(88, 103, 221)',
                              height: '5%',
                              width: '5%',
                            }}
                            type={'bars'}
                            color={'#5867dd'}
                            height={'5%'}
                            width={'5%'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {eventHistory.data &&
                        eventHistory.data.length > 0 &&
                        eventHistory.data.map((row) => (
                          <tr key={row.id}>
                            <td>{getDateTime(row.created_at)}</td>
                            <td>{row.id}</td>
                            <td>
                              {getDateTime(row.start_date)} -{' '}
                              {getDateTime(row.end_date)}
                            </td>
                            <td>{getApprovalStatus(row.approval)}</td>
                            <td>{getPaymentStatus(row.status)}</td>
                            <td>{getDateTime(row.updated_at)}</td>
                            <td>{row.updated_by}</td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </Table>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <button
                      type="button"
                      className={`btn btn-danger btn-elevate kt-login__btn-danger`}
                      onClick={() => {
                        props.history.push('/thub/events');
                      }}
                    >
                      <ArrowBack /> Back
                    </button>
                  </div>
                  <Pagination
                    activePage={eventHistory.currentPage}
                    itemsCountPerPage={eventHistory.totalDataOnPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={eventHistory.totalData}
                    pageRangeDisplayed={
                      eventHistory.totalDataOnPage >= 10
                        ? eventHistory.totalPages
                        : 1
                    }
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ThubEventsHistory;
