// import React, { useState, Component } from "react";
import React, { Component } from "react";
// import { Formik } from "formik";
// import { NotificationManager } from 'react-notifications';
// import 'react-notifications/lib/notifications.css';
// import { connect } from "react-redux";
import { verifyOTP } from "../../crud/login";
import ReactLoading from 'react-loading';
import { toAbsoluteUrl } from "../../../_metronic";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { createBrowserHistory } from 'history';

const history =  createBrowserHistory();

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      otp: '',
      loading: false,
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  verifyOTP(){

    this.setState({
      loading:true
    })

    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    let data = {
      "otp" : parseInt(this.state.otp),
    }

    verifyOTP(token, data)
    .then(res =>{
      this.setState({
        loading: false,
      })
      history.push("/auth/login");
      window.location.reload();

    })
    .catch(e => {
      if (e.response) {
        if(e.response.data.code){
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
      this.setState({
        loading: false,
      })
    }) 
  }

  render(){
    return(
      <>
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/tokocrypto-logo.png")}
              />
              <br />
            </div>
            <h3 style={{display: 'flex', justifyContent: 'center'}}>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              One-Time Password
              {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
            </h3>

            {this.state.loginFailed ? (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">failed to login</div>
                </div>
              ) : null
            }
            <div className="form-group" style={{marginTop: '50px', marginLeft: '90px'}}>
              <input className="input-otp" maxLength="6" id="mfa" value={this.state.otp} onChange={this.handleChange('otp')}/>
            </div>

            <div className="kt-login__actions" style={{justifyContent: 'center'}}>
              {this.state.loading === false && <button id="m_login_signin_submit"
              onClick={() => this.verifyOTP() }
              className="btn btn-primary">
                Verify
              </button>}
              {this.state.loading === true && <ReactLoading type={'bars'} color={'#0000FF'} height='50' width='50' />}
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    )
  }
}

export default Login;