import React, { useState, useEffect, Fragment } from 'react';
import CKEditor from 'ckeditor4-react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import thubActions from '../../../crud/thub';
import { Form, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import ArrowBack from '@material-ui/icons/ArrowBack';
require('bootstrap/scss/bootstrap.scss');

const ThubLocationEdit = (props) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const [imageGallery, setImageGallery] = useState([]);
  const params = useParams();

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
  };

  const onChangeFileImage = (e) => {
    let imageData = [];
    for (let i = 0; i < e.target.files.length; i++) {
      imageData.push(e.target.files[i]);
    }
    setField('image', imageData);
  };

  const findFormErrors = () => {
    const {
      location_name,
      location_address,
      details_en,
      details_id,
      status,
      image,
      location_city,
      phone_number,
      map_address,
      lat,
      long,
    } = form;
    const newErrors = {};
    // name errors
    if (!location_name || location_name === '')
      newErrors.location_name = 'Location name cant be blank!';
    if (!location_address || location_address === '')
      newErrors.location_address = 'Location address cant be blank!';
    if (!details_id || details_id === '')
      newErrors.details_id = 'Indonesian details cant be blank!';
    if (!details_en || details_en === '')
      newErrors.details_en = 'English details cant be blank!';
    if (status === '') newErrors.status = 'Thub status must not empty!';
    // if (!image || typeof image === 'undefined')
    //   newErrors.image = 'Image must be filled';
    if (!location_city || location_city === '')
      newErrors.location_city = 'Location city cant be blank!';
    if (!phone_number || phone_number === '')
      newErrors.phone_number = 'Phone number cant be blank!';
    if (!map_address || map_address === '')
      newErrors.map_address = 'Map address cant be blank!';
    if (!lat || lat === '') newErrors.lat = 'Latitude must be filled';
    if (!long || long === '') newErrors.long = 'Longitude must be filled';

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      editLocation();
    }
  };

  const editLocation = async () => {
    setLoading(true);
    await thubActions
      .fetchThubLocationEdit(token, form, params.id)
      .then(() => {
        toast.success('Successfully edited thub location');
        props.history.push('/thub/location');
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const getLocationData = (locationId) => {
    thubActions
      .fetchThubLocationDetail(token, locationId)
      .then((res) => {
        const {
          created_at,
          deleted_at,
          updated_at,
          _id,
          image_gallery,
          ...rest
        } = res.data.data;
        setForm(rest);
        setImageGallery(image_gallery);
      })
      .catch((e) => {
        toast.error(
          `error code: ${e.response.data.code} message: ${e.response.data.message}`
        );
        if (e.response) {
          if (e.response.data.code === 401) {
            toast.error('token has expired');
            localStorage.clear();
            props.history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  };

  const handleChangeEditorEN = (event) => {
    const data = event.editor.getData();
    setField('details_en', data);
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token && typeof token !== 'undefined') {
      getLocationData(params.id);
    }
  }, [token]);

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form className='m-login__form m-form' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Location Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Location Name'
                        value={form.location_name}
                        onChange={(e) =>
                          setField('location_name', e.target.value)
                        }
                        isInvalid={!!errors.location_name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.location_name}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Location Address</Form.Label>
                      <Form.Control
                        type='text'
                        value={form.location_address}
                        placeholder='Enter Location Address'
                        onChange={(e) =>
                          setField('location_address', e.target.value)
                        }
                        isInvalid={!!errors.location_address}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.location_address}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Details ID</Form.Label>
                      <br />
                      <CKEditor
                        config={{
                          toolbar: [
                            'undo',
                            'redo',
                            'fontFamily',
                            'bold',
                            'italic',
                            'blockQuote',
                            'heading',
                            'numberedList',
                            'bulletedList',
                            'insertTable',
                          ],
                        }}
                        fontFamily={{
                          options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                          ],
                        }}
                        data={form.details_id}
                        onChange={(e) => {
                          setField('details_id', e.editor.getData());
                        }}
                        isInvalid={!!errors.details_id}
                        // config={{
                        //   toolbar: ["undo", "redo", "bold", "italic", "blockQuote", "heading", "numberedList", "bulletedList", "insertTable"]
                        // }}
                      />
                      {errors.details_id && (
                        <p
                          style={{
                            fontSize: '13px',
                            color: 'red',
                            marginTop: '5px',
                          }}
                        >
                          {errors.details_id}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Details EN</Form.Label>
                      <br />
                      <CKEditor
                        config={{
                          toolbar: [
                            'undo',
                            'redo',
                            'fontFamily',
                            'bold',
                            'italic',
                            'blockQuote',
                            'heading',
                            'numberedList',
                            'bulletedList',
                            'insertTable',
                          ],
                        }}
                        fontFamily={{
                          options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                          ],
                        }}
                        data={form.details_en}
                        onChange={(e) => {
                          setField('details_en', e.editor.getData());
                        }}
                        isInvalid={!!errors.details_en}
                      />
                      {errors.details_en && (
                        <p
                          style={{
                            fontSize: '13px',
                            color: 'red',
                            marginTop: '5px',
                          }}
                        >
                          {errors.details_en}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Form.Group>
                      <Form.Label>Map Link Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter map link address. Eg: https://goo.gl/maps/GasEGumoVMPHndDy6'
                        value={form.map_address}
                        onChange={(e) =>
                          setField('map_address', e.target.value)
                        }
                        isInvalid={!!errors.map_address}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.map_address}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Location City</Form.Label>
                      <Form.Control
                        type='text'
                        value={form.location_city}
                        placeholder='Enter THub city location'
                        onChange={(e) =>
                          setField('location_city', e.target.value)
                        }
                        isInvalid={!!errors.location_city}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.location_city}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type='number'
                        value={form.phone_number}
                        placeholder='Enter THub phone number'
                        onChange={(e) =>
                          setField('phone_number', e.target.value)
                        }
                        isInvalid={!!errors.phone_number}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>
                          {errors.phone_number}
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Location Status</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <select
                          className='form-control'
                          onChange={(e) => {
                            setField('status', e.target.value);
                          }}
                          isInvalid={!!errors.status}
                        >
                          <option value={1} selected={form.status === 1}>
                            Open
                          </option>
                          <option value={0} selected={form.status === 0}>
                            Close
                          </option>
                        </select>
                      </div>
                      {errors.status && (
                        <p
                          style={{
                            fontSize: '13px',
                            color: 'red',
                            marginTop: '5px',
                          }}
                        >
                          {errors.status}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Latitude</Form.Label>
                      <Form.Control
                        type='number'
                        value={form.lat}
                        placeholder='Enter latitude coordinates value'
                        onChange={(e) => setField('lat', e.target.value)}
                        isInvalid={!!errors.lat}
                        step='0.00000001'
                        max={90}
                        min={-90}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>{errors.lat}</span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Longitude</Form.Label>
                      <Form.Control
                        type='number'
                        value={form.long}
                        placeholder='Enter longitude coordinates value'
                        onChange={(e) => setField('long', e.target.value)}
                        isInvalid={!!errors.long}
                        step='0.00000001'
                        max={180}
                        min={-180}
                      />
                      <Form.Control.Feedback type='invalid'>
                        <span style={{ fontSize: '13px' }}>{errors.long}</span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group>
                      <Form.Label>Location Image Gallery</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={(e) => onChangeFileImage(e)}
                          accept='image/*'
                          isInvalid={!!errors.image}
                          multiple
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Please using 1440 x 400 px for this image</span>
                      </Form.Text>
                      {errors.image && (
                        <span style={{ fontSize: '13px', color: 'red' }}>
                          {errors.image}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div
                    className='col-md-6'
                    style={{
                      display: 'flex',
                      columnGap: '5px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {imageGallery &&
                      imageGallery.length > 0 &&
                      imageGallery.map((data, i) => (
                        <Fragment key={i}>
                          {typeof data === 'string' && (
                            <Image
                              thumbnail
                              src={data}
                              alt='gallery-img'
                              style={{
                                maxWidth: '75px',
                                height: 'auto',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                window.open(data);
                              }}
                            />
                          )}
                        </Fragment>
                      ))}
                  </div>
                </div>

                <div
                  className='kt-login__actions'
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <button
                    type='button'
                    disabled={loading}
                    className={`btn btn-danger btn-elevate kt-login__btn-danger mr-3`}
                    onClick={() => {
                      props.history.push('/thub/location');
                    }}
                  >
                    <ArrowBack /> Back
                  </button>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading,
                      }
                    )}`}
                  >
                    Edit Location
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ThubLocationEdit;
