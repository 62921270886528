
import axios from "axios";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { Pagination, PaginationRequest, PaginationResponse, ResponseAPI } from "./global-types";
import { cancelTokenConfiguration } from "./instanceWithCancelToken";
import { GenerateRefinitivRequest, GetSummaryHistoryParams, GetSummaryParams, GetSummaryResponse, ScreeningDetail, UpdateStatusRequest } from "./refinitiv-types";

const history =  createBrowserHistory();

export const baseURL = process.env.REACT_APP_ENV === 'demo' ? "https://service-demo.tcdx.id/" : "https://service-new.tcdx.id/"

const instance = (isCancelToken?: boolean, isUnauthorized?: boolean) => {
  const i = axios.create({
    baseURL,
  });
  
  if(!isUnauthorized) {
    i.interceptors.request.use((config) => {
      if(config.headers) {
        config.headers.Authorization = localStorage.getItem("token");
        config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
      }
      if(isCancelToken) cancelTokenConfiguration(config)
      return config
    })
    
    i.interceptors.response.use(config => config, (err) => {
      if(err.response){
        if(err.response.status === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
        }
      }
      
      return Promise.reject(err)
    })
  }
  
  return i
}

const i = instance()

export const getSummary = (params: GetSummaryParams) => i.get<ResponseAPI<PaginationResponse<GetSummaryResponse>>>("/aml-service/admin/refinitiv/summary", {params})
export const getSummaryHistory = (params: GetSummaryHistoryParams) => i.get<ResponseAPI<PaginationResponse<ScreeningDetail>>>("/aml-service/admin/refinitiv/summary-history", {params})
export const updateStatus = (uid: string, payload: UpdateStatusRequest) => i.patch<ResponseAPI<boolean>>(`/aml-service/admin/refinitiv/${uid}/status`, payload)
export const generateReport = (params: GetSummaryParams) => i.get<ResponseAPI<Array<GetSummaryResponse>>>("/aml-service/admin/refinitiv/export", {params})