import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReusableListPage, {
  FILTER_TYPE,
  TRIGGER_BTN_TYPE,
} from '../../components/reusableListPage/index';
import { Badge, Modal, Button } from 'react-bootstrap';
import { depositStatus, withdrawStatus } from './depositRequest';
import {
  confirmPaymentOTC,
  getDetailOTCTransactionHistory,
} from '../../otc_services/resources/transaction_history/transaction_history_service';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import {
  approveOTCDeposit,
  cancelOTCDeposit,
  checkWithdraw,
  getAccountBalance,
  getDepositLogsOTC,
  getEstimateBalance,
  getWithdrawLogsOTC,
  rejectOTCDeposit,
} from '../../otc_services/resources/deposit/deposit_service';
import { debounce } from 'lodash';
import {
  constantDateFormat,
  getImageOssURL,
  numFormatter,
  redirectToNewPage,
} from '../../helper';
import ReusableFormGenerator, {
  FIELD_TYPE,
} from '../../components/reusableFormGenerator';

const TransactionHistoryLog = (props) => {
  const [activeKey, setActiveKey] = useState('deposit_status');
  const [loading, setLoading] = useState(false);
  const [freeBalance, setFreeBalance] = useState(0);

  const [depositStatusLoading, setDepositStatusLoading] = useState(false);
  const [withdrawStatusLoading, setWithdrawStatusLoading] = useState(false);

  const [depositStatusDataSources, setDepositStatusDataSources] = useState([]);
  const [withdrawStatusDataSources, setWithdrawStatusDataSources] = useState(
    []
  );

  const [bankTransferSelected, setBankTransferSelected] = useState(null);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [approveAmount, setApproveAmount] = useState(0);

  const [inputs, setInputs] = useState({
    id: null,
    transaction_id: '',
    customer: null,
    currency: '',
    amount: null,
    unique_code: null,
    transfer_amount: 0,
    bankAccount: null,
    sales_code: '',
    approvedBy: null,
    rejectedBy: null,
    bank_transfer_files: null,

    // -1 = Rejected
    // 0 = Pending
    // 1 = Waiting Payment
    // 2 = Paid/Waiting Confirmation
    // 3 = Confirmed/Approved
    // 4 = Failed
    // 5 = Expired
    // 6 = Canceled
    deposit_status: null,
    deposit_confirm_count: null,
    deposit_trailer: null,

    // null: pending / not approved
    // 0: Email Sent
    // 1: Cancelled
    // 2: Awaiting Approval
    // 3: Rejected
    // 4: Processing
    // 5: Failure
    // 10: Completed
    withdraw_status: null,
    withdraw_id: null,
    withdraw_trx: '',
    depositStatusLogs: [],
    depositWithdrawStatusLogs: [],
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: new Date(),
  });

  const [keywords, setKeywords] = useState('');
  const [statusSelected, setStatusSelected] = useState('');

  const [depositStatusActivePage, setDepositStatusActivePage] = useState(1);
  const [withdrawStatusActivePage, setWithdrawStatusActivePage] = useState(1);

  const [
    depositStatusTotalItemCount,
    setDepositStatusTotalItemCount,
  ] = useState(0);
  const [
    withdrawStatusTotalItemCount,
    setWithdrawStatusTotalItemCount,
  ] = useState(0);

  const [finalEstimateBalance, setFinalEstimateBalance] = useState(0);

  const depositStatusItemsCountPerPage = 15;
  const getDepositStatusSelected = (dataIndex) => {
    return depositStatus.find((status) => status.id === dataIndex);
  };
  const getWithdrawStatusSelected = (dataIndex) => {
    return withdrawStatus.find((status) => status.id === dataIndex);
  };
  const getBalanceBCA = () => {
    getAccountBalance()
      .then((res) => {
        // console.log(res)
        setFreeBalance(res.data.data.free);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFinalEstimateBalance = () => {
    getEstimateBalance()
      .then((res) => {
        setFinalEstimateBalance(res.data?.data?.balance || 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const depositStatusColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      className: 'tableheh',
      render: (dataIndex, data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Create Date',
      dataIndex: 'created_at',
      className: 'tableheh',
      render: (dataIndex) => (
        <span>{dataIndex && constantDateFormat(dataIndex)}</span>
      ),
    },
    {
      title: 'Transfer Amount',
      dataIndex: 'transfer_amount',
      className: 'tableheh',
      render: (dataIndex) => (
        <span>{dataIndex && numFormatter(dataIndex)}</span>
      ),
    },
    {
      title: 'Updated By',
      dataIndex: 'actionBy',
      className: 'tableheh',
      render: (dataIndex) => dataIndex?.email || 'Customer',
    },
    {
      title: 'Status',
      dataIndex: 'deposit_status',
      className: 'tableheh center',
      render: (dataIndex) =>
        getDepositStatusSelected(dataIndex) && (
          <Badge bg={getDepositStatusSelected(dataIndex).theme}>
            {getDepositStatusSelected(dataIndex).label}
          </Badge>
        ),
    },
  ];

  const withdrawStatusColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      className: 'tableheh',
      render: (dataIndex, data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      className: 'tableheh',
      render: (dataIndex) => (
        <span>{dataIndex && constantDateFormat(dataIndex)}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'withdraw_status',
      className: 'tableheh center',
      render: (dataIndex) =>
        getWithdrawStatusSelected(dataIndex) && (
          <Badge bg={getWithdrawStatusSelected(dataIndex).theme}>
            {getWithdrawStatusSelected(dataIndex).label}
          </Badge>
        ),
    },
  ];

  const filters = [
    // {
    //   type: "INPUT_TEXT",
    //   onChange: (evt) => setKeywords(evt.target.value),
    //   value: keywords,
    //   placeholder: "Search By Email, UID"
    // },
    {
      type: "DROP_DOWN",
      options: [
        { label: `- All Status -`, value: '' },
        ...(activeKey === 'deposit_status'
          ? depositStatus
          : withdrawStatus
        ).map((status) => ({
          label: status.label,
          value: status.id,
        })),
      ],
      value: statusSelected,
      onChange: (evt) => setStatusSelected(evt.target.value),
    },
  ];
  const triggerBtns = [
    {
      type: "BUTTON",
      label: 'Search',
      onClick: () => {
        if (activeKey === 'deposit_status')
          getDepositList(1, { q: keywords, deposit_status: statusSelected });
        else
          getWithdrawList(1, { q: keywords, withdraw_status: statusSelected });
      },
    },
    // {
    //   type: "BUTTON",
    //   label: "Export (belum)",
    //   className: "btn btn-outline-info mr-3 font-8rem",
    //   onClick: () => {}
    // },
  ];

  const labels = [
    { label: 'Transaction ID', value: inputs.transaction_id },
    { label: 'UID', value: inputs.customer?.tokocrypto_uid },
    { label: 'Full Name', value: inputs.customer?.fullname },
    { label: 'Email', value: inputs.customer?.email },
    { label: 'Amount', value: numFormatter(inputs.amount) },
    { label: 'Unique Code', value: inputs.unique_code },
    { label: 'Base Currency', value: inputs.currency.toUpperCase() },
    { label: 'Payment Ref', value: inputs.deposit_trailer || '-' },
  ];

  const getDepositList = (page, params) => {
    if (props.match.params?.id) {
      setDepositStatusLoading(true);
      getDepositLogsOTC(props.match.params?.id, {
        limit: depositStatusItemsCountPerPage,
        page,
        ...params,
        status: params.deposit_status,
      })
        .then((res) => {
          res.data.data.data.forEach((d, index) => {
            d.no = (page - 1) * depositStatusItemsCountPerPage + index;
          });
          setDepositStatusDataSources(res.data.data.data);
          setDepositStatusTotalItemCount(res.data.data.total_data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDepositStatusLoading(false);
        });
    }
  };

  const getWithdrawList = (page, params) => {
    if (props.match.params?.id) {
      setWithdrawStatusLoading(true);
      getWithdrawLogsOTC(props.match.params?.id, {
        limit: depositStatusItemsCountPerPage,
        page,
        ...params,
        status: params.withdraw_status,
      })
        .then((res) => {
          res.data.data.data.forEach((d, index) => {
            d.no = (page - 1) * depositStatusItemsCountPerPage + index;
          });
          setWithdrawStatusDataSources(res.data.data.data);
          setWithdrawStatusTotalItemCount(res.data.data.total_data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setWithdrawStatusLoading(false);
        });
    }
  };

  const debounceProcess = useRef(
    debounce((newKeywords, newStatusSelected) => {
      if (activeKey === 'deposit_status')
        getDepositList(1, {
          q: newKeywords,
          deposit_status: newStatusSelected,
        });
      else
        getWithdrawList(1, {
          q: newKeywords,
          withdraw_status: newStatusSelected,
        });
    }, 1000)
  );

  const filterListener = () =>
    debounceProcess.current(keywords, statusSelected);

  useEffect(filterListener, [keywords, statusSelected]);

  const firstInit = () => {
    getDetail();
    getBalanceBCA();
    getFinalEstimateBalance();
  };

  const getDetail = () => {
    if (props.match.params?.id) {
      setLoading(true);
      getDetailOTCTransactionHistory(props.match.params?.id)
        .then((res) => {
          if (res.data.data) {
            res.data.data.amount = Number(res.data.data.amount);
            res.data.data.transfer_amount = Number(
              res.data.data.transfer_amount
            );
          }
          setInputs(res.data.data);
          setApproveAmount(res.data.data.transfer_amount || 0);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const activeKeyListener = () => {
    setStatusSelected('');
  };

  useEffect(firstInit, []);
  useEffect(activeKeyListener, [activeKey]);

  const approveRejectOrCancel = (status) => {
    if (status !== 'Approve') {
      Swal.fire({
        title: `Are you sure to ${status} this request?`,
        html:
          status === 'Approve' &&
          `
          <div class="${getTextColour()}">Current Balance: IDR ${numFormatter(
            freeBalance
          )}</div>
          <div>Deposit Request: IDR ${numFormatter(
            inputs.transfer_amount || 0
          )}</div>
        `,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, ${status}!`,
      }).then((result) => {
        if (result.isConfirmed) {
          if (status === 'Reject') {
            setLoading(true);
            rejectOTCDeposit(props.match.params?.id)
              .then(() => {
                Swal.fire(
                  'Success!',
                  `You has been successfully ${status} this request!!`,
                  'success'
                );
              })
              .catch((res) => {
                console.log(res);
              })
              .finally(() => {
                setLoading(false);
                getDetail();
                if (activeKey === 'deposit_status')
                  getDepositList(1, {
                    q: keywords,
                    deposit_status: statusSelected,
                  });
                else
                  getDepositList(1, {
                    q: keywords,
                    withdraw_status: statusSelected,
                  });
              });
          } else {
            setLoading(true);
            cancelOTCDeposit(props.match.params?.id)
              .then(() => {
                Swal.fire(
                  'Success!',
                  `You has been successfully ${status} this request!!`,
                  'success'
                );
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setLoading(false);
                getDetail();
                if (activeKey === 'deposit_status')
                  getDepositList(1, {
                    q: keywords,
                    deposit_status: statusSelected,
                  });
                else
                  getDepositList(1, {
                    q: keywords,
                    withdraw_status: statusSelected,
                  });
              });
          }
        }
      });
    } else setIsApproveModal(true);
  };

  const getTextColour = () => {
    if (inputs.transfer_amount > freeBalance) return 'text-danger';
    else return 'text-success';
  };

  const checkPaymentHandler = () => {
    setLoading(true);
    confirmPaymentOTC(inputs.id)
      .then((res) => {
        Swal.fire(
          'Success!',
          `${inputs.transaction_id} is ${
            getDepositStatusSelected(res.data.data.deposit_status)?.label
          }!`,
          'success'
        );
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isPDF = (src) => {
    if (src.includes('.pdf')) return true;
    return false;
  };
  const bankTransferFilesRenderer = (isWithoutMargin) => {
    if (
      Array.isArray(inputs?.bank_transfer_files) &&
      inputs?.bank_transfer_files.length > 0
    )
      return inputs?.bank_transfer_files?.map((file, index) => (
        <img
          key={index}
          className={`img-otc-payment-proof ${
            bankTransferSelected === file ? 'img-otc-payment-proof-active' : ''
          } ${!isWithoutMargin && index > 0 ? 'ml-3' : ''}`}
          src={
            isPDF(file) ? '/media/logos/pdf-icon.png' : getImageOssURL() + file
          }
          onClick={() =>
            isPDF(file)
              ? redirectToNewPage(getImageOssURL() + file, true)
              : setBankTransferSelected(file)
          }
        />
      ));
    else return <div>- No Data -</div>;
  };

  const approveHandler = () => {
    if (!approveAmount)
      Swal.fire(
        'Warning!',
        `Amount must be fill, or must more than 0`,
        'warning'
      );
    // else if(Number(approveAmount) < 50000000) Swal.fire(
    //   'Warning!',
    //   `Amount must more than 50.000.000`,
    //   'warning'
    // )
    else {
      setLoading(true);
      approveOTCDeposit(props.match.params?.id, {
        amount: Number(approveAmount),
      })
        .then(() => {
          Swal.fire(
            'Success!',
            `You has been successfully Approve this request!`,
            'success'
          );
        })
        .catch((err) => {
          console.log(err);
          const getDesc = () => {
            if (Array.isArray(err.response?.data.message)) {
              let stringHTML = '';
              err.response.data.message.forEach((m) => {
                stringHTML += `<div>${m}</div>`;
              });
              return stringHTML;
            } else return err.response?.data.message;
          };
          Swal.fire({
            title: 'Failed!',
            html: getDesc(),
            icon: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setIsApproveModal(false);
          getDetail();
          if (activeKey === 'deposit_status')
            getDepositList(1, { q: keywords, deposit_status: statusSelected });
          else
            getDepositList(1, { q: keywords, withdraw_status: statusSelected });
        });
    }
  };

  const estimateBalanceRenderer = () => {
    if (depositStatusDataSources.length > 0) {
      {
        /* Estimate balance = current balance - total summary deposit 
      request with waiting payment or pending approval status */
      }
      const lastData =
        depositStatusDataSources[depositStatusDataSources.length - 1];

      const status = [0, 1, 7];
      if (status.indexOf(lastData.deposit_status) > -1)
        return (
          <div className='kt-flex_row p-1 text-info' style={{ gap: 15 }}>
            <h4 className='kt-font-boldest'>Estimate Balance</h4>
            <h4>IDR {numFormatter(finalEstimateBalance)}</h4>
          </div>
        );
    }
  };

  const checkWithdrawHandler = () => {
    if (props.match.params?.id) {
      setLoading(true);
      checkWithdraw(props.match.params?.id)
        .then((res) => {
          Swal.fire('Success!', `OK!!`, 'success');
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      <ReusableListPage
        labels={labels}
        rightLabels={
          localStorage.getItem('rule') !== 'sales' &&
          localStorage.getItem('rule') !== 'data' && (
            <div>
              <div>
                <button
                  type='button'
                  disabled={loading}
                  className={`mr-3 btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  onClick={() => approveRejectOrCancel('Cancel')}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  disabled={loading}
                  className={`mr-3 btn btn-danger btn-elevate kt-login__btn-danger ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  onClick={() => approveRejectOrCancel('Reject')}
                >
                  Reject
                </button>
                <Modal
                  show={isApproveModal}
                  onHide={() => {
                    setIsApproveModal(false);
                    setApproveAmount(inputs.transfer_amount);
                  }}
                  size='lg'
                  centered
                >
                  <Modal.Body>
                    <ReusableFormGenerator
                      conditionalComponent={
                        <div>
                          <div
                            className={`${getTextColour()} text-center font-2rem`}
                          >
                            Current Balance: IDR {numFormatter(freeBalance)}
                          </div>
                          <div className={`text-center font-2rem`}>
                            Deposit Request: IDR{' '}
                            {numFormatter(inputs.transfer_amount || 0)}
                          </div>
                          <br />
                          <br />
                          <div className='text-center font-2rem'>
                            Are you sure to Approve this request?
                          </div>
                        </div>
                      }
                      fields={[
                        [
                          {
                            type: FIELD_TYPE.NUMBER_INPUT,
                            label: 'Amount',
                            value: numFormatter(approveAmount),
                            onChange: (evt) =>
                              setApproveAmount(
                                Number(evt.target.value?.replace(/\D+/g, ''))
                              ),
                            placeholder: 'Amount',
                          },
                        ],
                      ]}
                    />
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        setIsApproveModal(false);
                        setApproveAmount(inputs.transfer_amount);
                      }}
                    >
                      Close
                    </Button>
                    <Button variant='primary' onClick={() => approveHandler()}>
                      Approve
                    </Button>
                  </Modal.Footer>
                </Modal>
                <button
                  type='button'
                  disabled={loading}
                  className={`btn btn-success btn-elevate kt-login__btn-success ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  onClick={() => approveRejectOrCancel('Approve')}
                >
                  Approve
                </button>
              </div>
              <div className='mt-2' style={{ float: 'right' }}>
                <button
                  type='button'
                  disabled={loading}
                  className={`mr-3 btn btn-success btn-elevate kt-login__btn-success ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  onClick={() => approveRejectOrCancel('Approve')}
                >
                  Re-Withdraw
                </button>
                <button
                  type='button'
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  onClick={() => checkPaymentHandler()}
                >
                  Check Payment
                </button>
                <br />
                <button
                  type='button'
                  disabled={loading}
                  className={`mt-2 btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  onClick={() => checkWithdrawHandler()}
                >
                  Check Withdraw
                </button>
              </div>
            </div>
          )
        }
        filters={filters}
        triggerBtns={triggerBtns}
        tabs={[
          {
            eventKey: 'deposit_status',
            title: 'Deposit Status',
            loading: depositStatusLoading,
            dataSources: depositStatusDataSources,
            columns: depositStatusColumns,
            pagination: {
              activePage: depositStatusActivePage,
              itemsCountPerPage: depositStatusItemsCountPerPage,
              totalItemCount: depositStatusTotalItemCount,
              onChange: (pageNumber) => {
                getDepositList(pageNumber, {
                  q: keywords,
                  deposit_status: statusSelected,
                });
                setDepositStatusActivePage(pageNumber);
              },
            },
          },
          {
            eventKey: 'withdraw_status',
            title: 'Withdraw Status',
            loading: withdrawStatusLoading,
            dataSources: withdrawStatusDataSources,
            columns: withdrawStatusColumns,
            pagination: {
              activePage: withdrawStatusActivePage,
              itemsCountPerPage: depositStatusItemsCountPerPage,
              totalItemCount: withdrawStatusTotalItemCount,
              onChange: (pageNumber) => {
                getWithdrawList(pageNumber, {
                  q: keywords,
                  withdraw_status: statusSelected,
                });
                setWithdrawStatusActivePage(pageNumber);
              },
            },
          },
        ]}
        tabsProps={{
          className: 'm-3',
          activeKey,
          onSelect: (k) => {
            setActiveKey(k);
            if (k === 'deposit_status')
              getDepositList(1, {
                q: keywords,
                deposit_status: statusSelected,
              });
            else
              getWithdrawList(1, {
                q: keywords,
                withdraw_status: statusSelected,
              });
            setStatusSelected(null);
          },
        }}
        middleComponent={
          <div className='p-3'>
            <h3>Payment Proof</h3>
            {bankTransferFilesRenderer()}
            <Modal
              show={!!bankTransferSelected}
              onHide={() => setBankTransferSelected(null)}
              size='lg'
              centered
            >
              <Modal.Header closeButton>
                {bankTransferSelected && (
                  <Modal.Title>{bankTransferSelected}</Modal.Title>
                )}
              </Modal.Header>

              <Modal.Body>
                <div
                  className='custom-flex-row'
                  style={{ gap: 10, width: '100%' }}
                >
                  <div className='custom-flex-col' style={{ gap: 10 }}>
                    {bankTransferFilesRenderer(true)}
                  </div>
                  {bankTransferSelected && (
                    <img
                      className='img-otc-payment-proof-w-80'
                      src={getImageOssURL() + bankTransferSelected}
                    />
                  )}
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={() => setBankTransferSelected(null)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        }
        footerComponent={
          <div className='kt-flex_row'>
            <div />
            <div style={{ width: '50%' }}>
              <div
                className={`kt-flex_row p-1 ${getTextColour()}`}
                style={{ gap: 15 }}
              >
                <h4 className='kt-font-boldest'>Current Balance</h4>
                <h4>IDR {numFormatter(freeBalance)}</h4>
              </div>
              {estimateBalanceRenderer()}
              <div className='kt-flex_row p-1' style={{ gap: 15 }}>
                <h4 className='kt-font-boldest'>Deposit Request</h4>
                <h4>IDR {numFormatter(inputs.transfer_amount || 0)}</h4>
              </div>
            </div>
          </div>
        }
      />
      <button
        type='button'
        disabled={loading}
        className={`mr-3 btn btn-outline-primary btn-elevate kt-login__btn-outline-primary ${clsx(
          {
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
          }
        )}`}
        onClick={() => props.history.push('/otc-finance/transaction-history')}
      >
        Back
      </button>
    </Fragment>
  );
};

export default TransactionHistoryLog;
