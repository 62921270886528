import React from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import data from '../../../crud/tes.json';
import thubActions from '../../../crud/thub';
import {
  Button,
  Table,
  Modal,
  Tab,
  Tabs,
  Badge,
  Image,
} from 'react-bootstrap';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
require('bootstrap/scss/bootstrap.scss');
require('./location.scss');

const history = createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth,
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth,
  },
  default: {
    class: 'sort',
    fn: (a, b) => a,
  },
};

export default class ThubLocation extends React.Component {
  detail(id) {
    window.open('/user/' + id + '/kyc');
    // history.push("/user/" + id + "/kyc");
    // window.location.reload();
  }
  editLocation(id) {
    window.open('/thub/location/' + id + '/edit');
  }
  history(id, level) {
    if (level === 1) {
      window.open('/thub/location/' + id + '/edit');
      // history.push("/user/" + id + "/history-level-1");
      // window.location.reload();
    }
    if (level === 2) {
      window.open('/thub/' + id + '/log-history');
      // history.push("/user/" + id + "/history-level-2");
      // window.location.reload();
    }
  }

  // addLocation() {
  //   window.open('/thub/location/add');
  // }

  constructor(props) {
    super(props);
    this.state = {
      loadingPreview: false,
      data: data,
      loading: false,
      loadingDataModal: false,
      loadingModalData: false,
      totalData: 0,
      userKYC: [],
      name: '',
      idNumber: '',
      pob: '',
      dob: '',
      userKYCDetail: [],
      userPremium: [],
      livenessData: [],
      postPerPage: 10,
      seacrhParams: '',
      orderBy: 'updated_at',
      orderSort: 'desc',
      active: 1,
      show: false,
      show2: false,
      show3: false,
      report: false,
      showDetail: false,
      liveGovtState: '',
      params: '',
      reason: '',
      country: 'all',
      countries: [],
      countriesData: [],
      occupation: 'all',
      occupations: [],
      occupationsData: [],
      SelectDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      KYC: [],
      asliriResult: null,
      gesture: '',
      editStatus: true,
      FilterLevelData: [],
      filterLevel: 'all',
      FilterStatusData: [],
      filterStatus: 'all',
      FilterLivenessData: [],
      filterLiveness: 'all',
      filterGovermentData: [],
      filterGovernment: 'all',
      filterScreeningData: [],
      filterScreening: 'all',
      filterCountry: 'all',
      loadingFilter: false,
      conversionNationality: '',
      conversionCountry: '',
      conversionOccupation: '',
      conversionProvinvce: '',
      visible: false,
      preview: '',
      contentPreview: 'image',
      liveness_video: '',
      showReset: false,
      paramReset: null,
      report_type: 'simple',
      exportKYC1: 'all',
      exportKYC2: 'all',
      reportLiveness: 'all',
      reportGovernment: 'all',
      reportScreening: 'all',
      reportDateType: 'create_date',
      reportAction: 'all',
      locationList: [],
      locationDetailData: [],
      totalPages: 0,
      locationName: '',
      locationAddress: '',
      locationStatus: '',
      limitData: 10,
    };

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({});
      obj[fieldName] = event.target.value;
      obj.resetCapctha = false;
      this.setState(obj);
    };

    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
  }

  handleShowDetail(params) {
    this.setState({ showDetail: true, params: params });
    this.getDetailLocation(params);
  }
  handleCloseDetail() {
    this.setState({ showDetail: false, params: '' });
    this.checkParams();
  }

  getToken() {
    let token = localStorage.getItem('token');
    return token;
  }

  async checkParams() {
    let params = `?location_address=${this.state.locationAddress}&location_name=${this.state.locationName}&status=${this.state.locationStatus}&page=${this.state.active}&limit=${this.state.limitData}`;

    this.setState({
      loading: true,
    });
    thubActions
      .fetchThubLocationList(this.getToken(), params)
      .then((res) => {
        // window.pushPage = (id) => {
        //   history.push('customers/' + id + '/kyc');
        // };
        // window.pushPage = (id) => {
        //   history.push('customers/' + id + '/history');
        // };
        console.log(res.data.data);
        this.setState({
          totalData: res.data.data.totalData,
          locationList: res.data.data.locations,
          //   postPerPage: res.data.data.totalDataOnPage,
          postPerPage: res.data.data.totalDataOnPage,
          totalPages: res.data.data.totalPages,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          locationList: [],
          loading: false,
        });
        console.log(e.response);
        if (e.response) {
          if (
            e.response.data.code === 401 ||
            e.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  onSortChange = async (name) => {
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });

    this.checkParams();
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  };

  changeFilter = (event) => {
    console.log(event.target.name, event.target.value);
    this.setState(
      {
        [event.target.name]: event.target.value,
        active: 1,
      },
      () => {
        this.checkParams();
      }
    );
  };

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber;
    this.setState(
      {
        active: activePage,
      },
      () => {
        this.checkParams();
      }
    );
  };

  handleSearch = () => {
    this.setState({ active: 1 }, () => {
      this.checkParams();
    });
  };

  dateTime = (date) => {
    var date1 = date;
    var fmtDate = moment(date1).format('DD-MM-Y hh:m A');

    return fmtDate;
  };

  async getDetailLocation(params) {
    console.log(params);
    this.setState({
      loadingDataModal: true,
    });
    thubActions
      .fetchThubLocationDetail(this.getToken(), params)
      .then((res) => {
        this.setState({
          locationDetailData: res.data.data,
          loadingDataModal: false,
        });
      })
      .catch((e) => {
        this.setState({
          locationDetailData: [],
          loadingDataModal: false,
        });
        if (e.response) {
          if (
            e.response.data.code === 401 ||
            e.response.data.code === 500
          ) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        } else {
          console.log(e);
        }
      });
  }

  componentDidMount() {
    this.checkParams();
  }

  getDateTime(date) {
    const fmtDate = moment(date).format('DD MMM YYYY - HH:mm:ss');

    return fmtDate;
  }

  getBadgeColor(status) {
    switch (status) {
      case 1:
        return 'success';
      case 0:
        return 'danger';

      default:
        return 'danger';
    }
  }

  render() {
    return (
      <div style={{ fontSize: '11px' }} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body pddmdForce">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Location Name
                  </label>
                  <input
                    type="text"
                    className="form-control mr-3"
                    name="locationName"
                    placeholder="eg: Thub Bali"
                    value={this.state.locationName}
                    onChange={this.changeFilter}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Location Address
                  </label>
                  <input
                    type="text"
                    className="form-control mr-3"
                    name="locationAddress"
                    placeholder="eg: Bali"
                    value={this.state.locationAddress}
                    onChange={this.changeFilter}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label
                    className="col-md-12"
                    style={{ paddingLeft: '0' }}
                  >
                    Filter By Status
                  </label>
                  <select
                    className="form-control mr-3"
                    name="locationStatus"
                    onChange={this.changeFilter}
                  >
                    <option selected disabled>
                      Select Status
                    </option>
                    <option value="1">Open</option>
                    <option value="0">Close</option>
                  </select>
                  {/* <input
                    type="text"
                    className="form-control mr-3"
                    name="locationStatus"
                    placeholder="eg: Open"
                    value={this.state.locationStatus}
                    onChange={this.changeFilter}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="paddupgrade">
                <div className="location-menu__top">
                  <Link to={'/thub/location/add'}>
                    <Button
                      variant="success"
                      style={{ fontSize: '11px' }}
                    >
                      Add new location
                    </Button>
                  </Link>

                  <select
                    className="form-control location-limit"
                    onChange={this.changeFilter}
                    name="limitData"
                  >
                    <option value={5}>5</option>
                    <option selected value={10}>
                      10
                    </option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section" style={{ marginBottom: 0 }}>
                {this.state.loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ReactLoading
                      type={'bars'}
                      color={'#0000FF'}
                      height={'2%'}
                      width={'5%'}
                    />
                  </div>
                ) : (
                  <>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className="tableheh"
                            style={{
                              verticalAlign: 'middle',
                            }}
                          >
                            No
                          </th>
                          <th
                            className="tableheh"
                            style={{ verticalAlign: 'middle' }}
                          >
                            Location Name
                            <button
                              onClick={(e) =>
                                this.onSortChange('email')
                              }
                              className="sort-button"
                            >
                              <i
                                className={`fas fa-${
                                  sortTypes[this.state.orderSort]
                                    .class
                                }`}
                              />
                            </button>
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Location Address
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Location City
                            <br />
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Contact Number
                          </th>
                          <th
                            className="tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Status
                          </th>

                          <th
                            className="center tableheh"
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.locationList &&
                          this.state.locationList.length === 0 && (
                            <tr style={{ textAlign: 'center' }}>
                              <td colSpan={7}>
                                No records data found
                              </td>
                            </tr>
                          )}
                        {this.state.locationList &&
                          this.state.locationList.length > 0 &&
                          this.state.locationList.map((row, i) => (
                            <tr key={row._id}>
                              <td style={{ verticalAlign: 'middle' }}>
                                {i + 1}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.location_name}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.location_address}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.location_city || '-'}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.phone_number || '-'}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <Badge
                                  bg={this.getBadgeColor(
                                    row.status
                                  )}
                                >
                                  {row.status === 1
                                    ? 'Open'
                                    : 'Close'}
                                </Badge>
                              </td>

                              <td>
                                <div>
                                  {/* <span onClick={() => this.detail(row.ref_id)} style={{ cursor:'pointer', color: 'blue' }}>view</span> */}
                                  <span
                                    onClick={() =>
                                      this.handleShowDetail(
                                        row.location_id
                                      )
                                    }
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className="btn btn-outline-primary"
                                  >
                                    View
                                  </span>
                                  <br />
                                  <Link
                                    to={`/thub/location/${row.location_id}/edit`}
                                  >
                                    <span
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                      }}
                                      className="btn btn-outline-success"
                                    >
                                      Edit
                                    </span>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center align-items-center pagination-data">
                      <Pagination
                        activePage={this.state.active}
                        itemsCountPerPage={this.state.postPerPage}
                        itemClass="page-item"
                        linkClass="page-link"
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={
                          this.state.postPerPage >=
                          this.state.limitData
                            ? this.state.totalPages
                            : 1
                        }
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDetail}
          onHide={this.handleCloseDetail}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.locationDetailData.location_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loadingDataModal &&
            !this.state.locationDetailData ? (
              ''
            ) : (
              <>
                <Tabs
                  id="controlled-tab-example"
                  defaultActiveKey="home"
                  // onSelect={key => this.setState({ key })}
                >
                  <Tab eventKey="home" title="Location Details">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Created At</strong>
                          </td>
                          <td colSpan={5}>
                            {this.getDateTime(
                              this.state.locationDetailData.created_at
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Location ID</strong>
                          </td>
                          <td colSpan={5}>
                            {
                              this.state.locationDetailData
                                .location_id
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Name</strong>
                          </td>
                          <td colSpan={5}>
                            {
                              this.state.locationDetailData
                                .location_name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>City</strong>
                          </td>
                          <td colSpan={5}>
                            {
                              this.state.locationDetailData
                                .location_city
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Address</strong>
                          </td>
                          <td colSpan={5}>
                            {
                              this.state.locationDetailData
                                .location_address
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Map Detail Address</strong>
                          </td>
                          <td colSpan={5}>
                            {
                              this.state.locationDetailData
                                .map_address
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Details (ID)</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.locationDetailData.details_id}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Details (EN)</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.locationDetailData.details_en}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Latitude</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.locationDetailData.lat}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Longitude</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.locationDetailData.long}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Image Gallery</strong>
                          </td>
                          <td colSpan="5">
                            {this.state.locationDetailData &&
                              this.state.locationDetailData
                                .image_gallery &&
                              this.state.locationDetailData
                                .image_gallery.length > 0 &&
                              this.state.locationDetailData.image_gallery.map(
                                (image, i) => (
                                  // <img
                                  //   src={`http://thub-demo.oss-ap-southeast-5.aliyuncs.com/${image}`}
                                  //   key={i}
                                  //   alt="gallery-img"
                                  // />
                                  <Image
                                    thumbnail
                                    src={image}
                                    key={i}
                                    alt="gallery-img"
                                    style={{
                                      maxWidth: '200px',
                                      height: 'auto',
                                    }}
                                  />
                                )
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Status</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.locationDetailData.status ===
                            1
                              ? 'Open'
                              : 'Close'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Phone Number</strong>
                          </td>
                          <td colSpan="5">
                            {
                              this.state.locationDetailData
                                .phone_number
                            }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab>
                  {/* <Tab eventKey="asliri" title="Asliri">
                    <AceEditor
                      width="50"
                      mode="json"
                      theme="github"
                      readOnly={true}
                      value={PrettyJSON(obj)}
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Tab> */}
                </Tabs>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
