import React from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import actions from "../../crud/finance";
import { Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
// import _ from 'lodash';
import Pagination from "react-js-pagination";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

const sortTypes = {
  asc: {
    class: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth
  },
  desc: {
    class: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth
  },
  default: {
    class: 'sort',
    fn: (a, b) => a
  }
};

export default class BCA extends React.Component {
  detail(id) {
    history.push("/user/" + id + "/kyc");
    window.location.reload();
  }
  history() {
    history.push("/admin/add-admin");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getData: [],
      totalData: 0,
      postPerPage: 15,
      active: 1,
      orderBy: '',
      orderSort: 'asc',
      trxDate: new Date(),
      totalAmmount: 0,
      eventType: '',
      account_number: 7630622233,
      transactionAmount:'',
      saldo: 0
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }
  
  getListData () { 
    this.setState({
      loading: true
    })

    let dateParams = this.dateTime(this.state.trxDate)

    let ord = ''
    if(this.state.orderBy !== ''){
      ord=`&order_by=${this.state.orderBy}`
    }else{
      ord=''
    }

    let type = ''
    if(this.state.eventType !== ''){
      type=`&transaction_type=${this.state.eventType}`
    }else{
      type=''
    }

    let Ammount = ''
    if(this.state.transactionAmount !== ''){
      Ammount=`&transaction_amount=${this.state.transactionAmount}`
    }else{
      Ammount=''
    }

    let number = parseInt(this.state.account_number)

    // let params=`?order_sort=${this.state.orderSort}&q=${this.state.seacrhParams}&page=${this.state.active}&limit=${this.state.postPerPage}${ord}${filterStat}${filterLvl}${filterGov}${filterLive}${filterScreen}${filterCountry}`
    let params=`?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}&account_number=${number}&date=${dateParams}${ord}${type}${Ammount}`
    actions.fetchListDataFinanceBCA(this.getToken(), params)
    .then(res => {
      let datas = res.data.data
      this.setState({
        totalData: datas.total_data,
        getData: datas.data,
        postPerPage: datas.per_page,
        loading: false,
        totalAmmount: datas.total_amount
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        getData:[],
        loading: false,
        totalAmmount: 0
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getDownloadExcel () { 
    this.setState({
      loading: true
    })

    let dateParams = this.dateTime(this.state.trxDate)
    let dateDownload = moment(this.state.trxDate).utc().format('MM/DD/YYYY');

    let ord=`&order_by=TransactionType`

    let type = ''
    let typeDownload = ''
    if(this.state.eventType !== ''){
      type=`&transaction_type=${this.state.eventType}`
      typeDownload= this.state.eventType
    }else{
      type=''
      typeDownload= 'All'
    }

    let Ammount = ''
    let AmmountDownload = ''
    if(this.state.transactionAmount !== ''){
      Ammount=`&transaction_amount=${this.state.transactionAmount}`
      AmmountDownload= `_${this.state.transactionAmount}`
    }else{
      Ammount=''
      AmmountDownload = ''
    }

    let number = parseInt(this.state.account_number)

    let params=`?order_sort=${this.state.orderSort}&account_number=${number}&date=${dateParams}${ord}${type}${Ammount}`
    actions.fetchFileExcel(this.getToken(), params)
    .then(res => {
      let datas = res.data.data
      var url = "data:application/vnd.ms-excel;base64," + datas;
      // you need to precise a front-end button to have a name
      var a = document.createElement("a");
      a.download = `bca_export_${dateDownload}_${typeDownload}${AmmountDownload}.xlsx`;
      a.href = url;
      a.click();
      // window.location.href = "data:application/vnd.ms-excel;base64, " + datas;
      
      this.setState({
        loading: false
      })
      this.getListData()
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getSaldo () { 
    this.setState({
      loading: true
    })

    let number = parseInt(this.state.account_number)

    let params=`?account_number=${number}`

    actions.fetchSaldo(this.getToken(), params)
    .then(res => {
      let Saldo = res.data.data.AccountDetailDataSuccess[0].Balance
      this.setState({
        loading: false,
        saldo: Saldo || 0
      })
      // this.getListData()
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getListData()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getListData()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).utc().format('YYYY-MM-DD');

    return fmtDate
  }

  financial(x) {
    return Number.parseFloat(x).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  async search(){
    await this.setState({ active: '1'})
    this.getListData();
    this.getSaldo();
  }

  componentDidMount(){
    this.getListData();
    this.getSaldo();
  }


  render(){
    const listTransactinType = [
      {
        id: "",
        name: 'All',
      },
      {
        id: "D",
        name: 'Debit',
      },
      {
        id: "C",
        name: 'Credit',
      }
    ];

    const accountNumber = [
      {
        id: 7630833331,
        name: '7630833331 (ops)',
      },
      {
        id: 7630622233,
        name: '7630622233 (segre)',
      }
    ];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body pad-10-20">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-2">
                  <Form.Group className="mb-0">
                    <Form.Label className="font-8rem">Account Number</Form.Label>
                    <select className="form-control font-8rem" onChange={(e) => this.setState({ account_number: e.target.value })} placeholder="Select Data">
                      <option disabled selected={this.state.account_number === '' ? true : false}>Select Type</option>
                      {accountNumber.map((e) => (
                        <option value={e.id} selected={e.id === this.state.account_number ? true : false} defaultValue={e.id === this.state.account_number ? true : false}>{e.name}</option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group className="mb-0">
                    <Form.Label className="font-8rem">Transaction Date</Form.Label>
                    <DatePicker
                      className="form-control font-8rem"
                      selected={this.state.trxDate}
                      minDate={subDays(new Date(), 31)}
                      maxDate={new Date()}
                      onChange={(e) => this.setState({ trxDate: e })}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group className="mb-0">
                    <Form.Label className="font-8rem">Transaction Type</Form.Label>
                    <select className="form-control font-8rem" onChange={(e) => this.setState({ eventType: e.target.value })} placeholder="Select Data">
                      <option disabled selected={this.state.eventType === '' ? true : false}>Select Type</option>
                      {listTransactinType.map((e) => (
                        <option value={e.id} selected={e.id === this.state.eventType ? true : false} defaultValue={e.id === this.state.eventType ? true : false}>{e.name}</option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Group className="mb-0">
                    <Form.Label className="font-8rem font-8rem">Transaction Amount</Form.Label>
                    <input 
                      type="text" 
                      className="form-control font-8rem" 
                      name="search"
                      value={this.state.transactionAmount} 
                      onChange={(e) => this.setState({ transactionAmount: e.target.value })} />
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <div style={{marginTop: '1.6rem'}}>
                    <button className="btn btn-outline-secondary mr-3 font-8rem" type="button" onClick={() => this.search()}>Search</button>
                    <button className="btn btn-outline-success font-8rem" type="button" onClick={() => this.getDownloadExcel()}>Export To Excel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet-top">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '15%' }}>
                        Transaction Date &nbsp;
                        <button onClick={(e) => this.onSortChange('TransactionDate')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" style={{ width: '15%' }}>
                        Transaction Type &nbsp;
                        <button onClick={(e) => this.onSortChange('TransactionType')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh" style={{ width: '40%' }}>
                        Trailer &nbsp;
                        <button onClick={(e) => this.onSortChange('Trailer')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh">
                        Transaction Name &nbsp;
                        <button onClick={(e) => this.onSortChange('TransactionName')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                      <th className="tableheh">
                        Transaction Amount &nbsp;
                        <button onClick={(e) => this.onSortChange('TransactionAmount')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="8"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <>
                      <tbody>
                        {this.state.getData === [] ? 
                          <tr>
                            <td colSpan="5" style={{ textAlign: 'end' }}><span>Data Not Found</span></td>
                        </tr>
                        :
                        <>
                          {this.state.getData.map( (row, index) => (
                            <tr key={index}>
                              <td style={{ verticalAlign: 'middle' }}>{row.TransactionDate}</td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.TransactionType === 'D' && 
                                  <>
                                    <span className="label label-dot success mr-2"></span>
                                    &nbsp;
                                    <span>Debit</span>
                                  </>
                                }
                                {row.TransactionType === 'C' && 
                                  <>
                                    <span className="label-dot danger mr-2"></span>
                                    &nbsp;
                                    <span>Credit</span>
                                  </>
                                }
                              </td>
                              <td>{row.Trailer}</td>
                              <td style={{ verticalAlign: 'middle' }}>{row.TransactionName}</td>
                              <td style={{ verticalAlign: 'middle' }}>Rp. {this.financial(row.TransactionAmount)}</td>
                            </tr>
                          ))}
                        </>
                        }
                      </tbody>
                      <tfoot style={{ borderBottom: '1px solid #ebedf2'}}>
                        <tr>
                          <td colSpan="5" style={{ textAlign: 'end' }}><span className="totalAmmount">Total Transaction: Rp. {this.financial(this.state.totalAmmount)}</span></td>
                        </tr>
                      </tfoot>
                    </>
                  }
                </Table>
                <div style={{ display: 'flex' }}>
                  {/* <div className="col-md-6"> */}
                    <Pagination
                      activePage={this.state.active}
                      itemsCountPerPage={this.state.postPerPage}
                      itemClass="page-item"
                      linkClass="page-link"
                      totalItemsCount={this.state.totalData}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                    />
                  {/* </div> */}
                  {/* <div className="col-md-6" style={{ paddingRight: '1.5rem', textAlign: 'end'}}> */}
                    <span className="totalAmmount" style={{ right: '15px', position: 'absolute'}}>Total Saldo: Rp. {this.state.saldo !== 0 ? this.financial(this.state.saldo) : 0}</span>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}