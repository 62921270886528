import { Fragment } from 'react';
import { useHistory } from 'react-router'
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { getHistoryUploadTransaction, postUploadFile } from '../../crud/leaderboard';
import {
  Modal,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  deleteLeaderboardById,
  getLeaderboards,
} from '../../leaderboard_services/resources/leaderboard';
import { constantDateFormat, convertTZ, useDebounce, useMultipleState } from '../../helper';
import ReusableTable from '../../components/reusableTable';
import { useFormik } from 'formik';
import FormWithFormik from '../../components/reusableFormWithFormik';
import Swal from 'sweetalert2';
require('bootstrap/scss/bootstrap.scss');

const LIMIT = 15

const LeaderboardMenu = () => {
  const history = useHistory()
  const importDataHistoryFormik = useFormik({
    initialValues: {
      import_data: undefined
    },
    onSubmit: (vals) => {
      console.log(vals)
      if(vals.import_data?.originFileObjs && vals.import_data?.originFileObjs[0]) {
        const body = new FormData();
        body.append('file', vals.import_data?.originFileObjs[0]);
        body.append('id', state.selected_history_id);
        
        dispatch({ history_loading: true })
        postUploadFile(body)
          .then(() => {
            toast.success(`upload file success!`);
  
            dispatch({ history_loading: true })
            getHistoryService(state.history_page, state.selected_history_id)
          })
          .catch(err => {
            console.log(err)
            dispatch({ history_loading: false })
          })
      }
    }
  })
  const [state, dispatch] = useMultipleState({
    loading: false,
    datas: [],
    q: "",
    sort: undefined,
    page: 1,
    total_page: 0,
    total_data: 0,
    selected_history_id: undefined,
    history_loading: false,
    histories: [],
    history_page: 1,
    history_total_page: 0,
    history_total_data: 0
  })

  const getListService = (q, page, sort) => {
    getLeaderboards({
      order_by: sort?.order_by,
      order_sort: sort?.order_sort,
      q,
      page,
      limit: LIMIT,
    })
      .then((res) => dispatch({
        datas: res.data.data.data,
        total_page: res.data.data.total_page,
        total_data: res.data.data.total_data
      }))
      .catch(err => {
        console.log(err)
      })
      .finally(()=>dispatch({loading: false}))
  }

  const getHistoryService = (page, id_event) => {
    getHistoryUploadTransaction({
      order_sort: 'desc',
      page,
      limit: LIMIT,
      id_event
    })
      .then((res) => dispatch({
        histories: res.data.data.data,
        history_total_page: res.data.data.total_page,
        history_total_data: res.data.data.total_data
      }))
      .catch(err => console.log(err))
      .finally(()=>dispatch({ history_loading: false }))
  }

  useDebounce((page, id_event) => {
    if(typeof id_event !== 'undefined') getHistoryService(page, id_event)
  }, [state.history_page, state.selected_history_id], 400, () => dispatch({ history_loading: true }))

  useDebounce((q, page, sort)=>getListService(q, page, sort), [state.q, state.page, state.sort], 400, () => dispatch({loading: true}))

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      render: (_, _data, index) => index + 1 + ((state.page - 1) * LIMIT)
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      isSortMode: true,
      style: {
        width: 200
      }
    },
    {
      title: "Title ID / Title EN",
      dataIndex: "title_id",
      render: (_, data) => <Fragment>{data.title_id} | {data.title_en}</Fragment>,
      style: {
        width: 280
      }
    },
    {
      title: "Campaign Type",
      dataIndex: "campaign_type",
      isSortMode: true
    },
    {
      title: "Dropdown Status",
      dataIndex: "menu_dropdownlist_status",
      isSortMode: true,
      render: (_, data) => data.menu_dropdownlist_status === "1" ? "Showing" : "Not Showing",
    },
    {
      title: "Homepage Status",
      dataIndex: "homepage_list_status",
      isSortMode: true,
      render: (_, data) => data.homepage_list_status === "1" ? "Showing" : "Not Showing",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (_, data) => constantDateFormat(
        convertTZ(data.startDate),
        false
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: (_, data) => constantDateFormat(
        convertTZ(data.endDate),
        false
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, data) => (
        <div className="custom-flex-row">
          <ButtonToolbar
            style={{ justifyContent: 'center' }}
          >
            {data.campaign_type === 'leaderboard' &&
              !(
                data.trading_start_date ||
                data.trading_end_date
              ) && (
                <>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        Upload Excel
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() => dispatch({selected_history_id: data.id})}
                      className='link-action btn'
                    >
                      <i
                        style={{
                          fontSize: '1rem',
                          paddingRight: '0',
                        }}
                        className='fas fa-cloud-upload-alt text-success'
                      />
                    </span>
                  </OverlayTrigger>
                </>
              )}
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Edit Data
                </Tooltip>
              }
            >
              <span
                onClick={() => history.push('/leaderboard/' + data.id + '/editEvent')}
                className='link-action btn'
              >
                <i
                  style={{
                    fontSize: '1rem',
                    paddingRight: '0',
                  }}
                  className='fas fa-pencil-alt text-info'
                />
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Delete Data
                </Tooltip>
              }
            >
              <span
                onClick={() => handleDeleteOpen(data.id, data.endDate)}
                className='link-action btn'
              >
                <i
                  style={{
                    fontSize: '1rem',
                    paddingRight: '0',
                  }}
                  className='fas fa-trash-alt text-danger'
                />
              </span>
            </OverlayTrigger>
          </ButtonToolbar>
        </div>
      )
    },
  ]

  const historyColumns = [
    {
      title: "No",
      dataIndex: "id",
      render: (_, _data, index) => index + 1 + ((state.history_page - 1) * LIMIT)
    },
    {
      title: "Filename",
      dataIndex: "filename",
    },
    {
      title: "Created By",
      dataIndex: "email",
    },
    {
      title: "Created At",
      dataIndex: "created_date",
      render: (_, data) => constantDateFormat(convertTZ(data.created_date), false)
    },
    {
      title: "Total Record",
      dataIndex: "total_record",
    },
    {
      title: "Total Imported Record",
      dataIndex: "total_inserted",
    },
    {
      title: "Status Upload",
      dataIndex: "status",
      render: (_, data) => {
        if(data.status === -1) return (
          <span className='text-danger'>Failed</span>
        )
        else if(data.status === 0) return (
          <span className='text-warning'>Waiting</span>
        )
        else if(data.status === 1) return (
          <span className='text-warning'>Process</span>
        )
        else if(data.status === 2) return (
          <span className='text-success'>Done</span>
        )
        else return <span>-</span>
      }
    },
  ]

  const handleDeleteOpen = (id, endDate) => {
    const now = new Date();
    const date1 = moment(now);
    const date2 = moment(endDate);
    const diff = date2.diff(date1);
    
    if(diff > 0) Swal.fire({
      title: "Warning",
      html: "This event is still on going?",
      icon: "warning",
      showCancelButton: false
    })
    else Swal.fire({
      title: "Warning",
      html: "Are you sure want to delete this event?",
      icon: "warning",
      confirmButtonText: "Yes, Delete it!",
      showCancelButton: true
    }).then((result) => {
      if(result.isConfirmed) {
        deleteLeaderboardById(id)
          .then(()=>{
            toast.success("Delete success")

            dispatch({ loading: true })
            getListService(state.q, state.page, state.sort)
          })
          .catch(err => console.log(err))
      }
    })
  }
  
  return (
    <Fragment>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview custom-flex-row-center'>
            <input
              type='text'
              className='form-control mr-2'
              name='search'
              placeholder='Search'
              value={state.q}
              onChange={(evt) => dispatch({ q: evt.target.value })}
            />
            <button
              className='btn btn-success btn-sm pr-5 pl-5'
              type='button'
              onClick={() => getListService(state.q, state.page, state.sort)}
            >
              Search
            </button>
            <button
              style={{width: 200}}
              className='btn btn-success btn-sm pr-5 pl-5'
              type='button'
              onClick={() => history.push('/leaderboard/addEvent')}
            >
              Add New Event
            </button>
          </div>
        </div>
      </div>
      <ReusableTable 
        loading={state.loading}
        dataSources={state.datas}
        columns={columns}
        pagination={{
          totalItemsCount: state.total_data,
          itemsCountPerPage: LIMIT,
          pageRangeDisplayed: 5,
          onChange: (page) => dispatch({ page }),
          activePage: state.page
        }}
        onSort={{
          onChange: (sort) => dispatch({ sort })
        }}
      />
      <Modal
        show={typeof state.selected_history_id !== 'undefined'}
        onHide={()=>dispatch({ selected_history_id: undefined })}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Data History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-flex-row-center gap-2">
            <FormWithFormik 
              name="import_data"
              type="UPLOAD_FILE"
              config={importDataHistoryFormik}
              allowedMimes={['text/csv','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            />
            <button
              className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                {
                  'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': state.history_loading,
                }
              )}`}
              style={{width: 200}}
              onClick={importDataHistoryFormik.submitForm}
            >
              Import Data
            </button>
          </div>
          <div className='allowed'>
            <span>
              <sup>*</sup>Allowed file type is .csv
            </span>
            <span>
              <sup>*</sup>Maximum row that can be imported are
              65,500 rows
            </span>
          </div>
          <ReusableTable 
            loading={state.history_loading}
            dataSources={state.histories}
            columns={historyColumns}
            pagination={{
              totalItemsCount: state.history_total_data,
              itemsCountPerPage: LIMIT,
              pageRangeDisplayed: 5,
              onChange: (history_page) => dispatch({ history_page }),
              activePage: state.history_page
            }}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default LeaderboardMenu