import axios from 'axios';
import moment from 'moment';
import { toBase64 } from '../helper';

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://api.thub.space'
      : 'https://api-thub-demo.tcdx.id',
});

const getThubUserList = (token, params) => {
  return instance.get('/api/v1/admin/user' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubUserList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubUserList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubUserDetail = (token, params) => {
  return instance.get('/api/v1/admin/user/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubUserDetail = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubUserDetail(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubUserAll = (token, params) => {
  return instance.get('/api/v1/admin/user/list', {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
    params,
  });
};

const fetchThubUserAll = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubUserAll(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const putThubBlockUser = (token, params, id) => {
  return instance.put('/api/v1/admin/user/block/' + id, params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubBlockUser = (token, params, id) => {
  return new Promise((resolve, reject) => {
    putThubBlockUser(token, params, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubLocationList = (token, params) => {
  return instance.get('/api/v1/admin/location/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubLocationList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubLocationList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubLocationAll = (token, params) => {
  return instance.get('/api/v1/admin/location/list', {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubLocationAll = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubLocationAll(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubLocationDetail = (token, params) => {
  return instance.get('/api/v1/admin/location/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubLocationDetail = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubLocationDetail(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubLocationCreate = (token, params) => {
  var data = new FormData();

  const { image, ...rest } = params;
  for (var key in rest) {
    data.append(key, rest[key]);
  }

  Array.from(image).forEach((file) => {
    data.append('image', file);
  });

  return instance.post('/api/v1/admin/location', data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubLocationCreate = (token, params) => {
  return new Promise((resolve, reject) => {
    postThubLocationCreate(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubLocationEdit = (token, params, id) => {
  var data = new FormData();
  const { image, ...rest } = params;
  for (var key in rest) {
    data.append(key, rest[key]);
  }

  if (image && image.length > 0) {
    Array.from(image).forEach((file) => {
      data.append('image', file);
    });
  }

  return instance.patch(`/api/v1/admin/location/${id}`, data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubLocationEdit = (token, params, id) => {
  return new Promise((resolve, reject) => {
    postThubLocationEdit(token, params, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubUserHistoryLog = (token, userId, params) => {
  return instance.get('/api/v1/admin/user-logs/' + userId + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubUserHistoryLog = (token, userId, params) => {
  return new Promise((resolve, reject) => {
    getThubUserHistoryLog(token, userId, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubUserLoginHistory = (token, userId, params) => {
  return instance.get('/api/v1/admin/login-activity/' + userId + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubUserLoginHistory = (token, userId, params) => {
  return new Promise((resolve, reject) => {
    getThubUserLoginHistory(token, userId, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubBookingTypeList = (token, params) => {
  return instance.get('/api/v1/admin/booking-type' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubBookingTypeList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubBookingTypeList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubBookingTypeByLocation = (token, locationId) => {
  return instance.get(
    `/api/v1/admin/booking-type/location/${locationId}/list`,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubBookingTypeByLocation = (token, locationId) => {
  return new Promise((resolve, reject) => {
    getThubBookingTypeByLocation(token, locationId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubRateTypeList = async (token) => {
  const response = await instance.get(
    '/api/v1/admin/configuration-type/list/config',
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );

  return response;
};

const fetchThubRateTypeList = (token) => {
  return new Promise((resolve, reject) => {
    getThubRateTypeList(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubBookingTypeCreate = (token, params) => {
  var data = new FormData();
  const { image, seats, price_type, price_type_default, capacities, ...rest } =
    params;

  const priceTypeArray = price_type.concat(price_type_default);
  for (var key in rest) {
    data.append(key, rest[key]);
  }

  data.append('capacities', `${capacities}`);

  Array.from(image).forEach((file) => {
    data.append('image', file);
  });

  Array.from(seats).forEach((seat, index) => {
    data.append(`seats[${index}][seat_name]`, seat.seat_name);
    data.append(`seats[${index}][status]`, seat.status);
  });

  Array.from(priceTypeArray).forEach(async (price, index) => {
    console.log(price, 'pricepriceprice');
    data.append(`price_type[${index}][type]`, price.type);
    if (price.benefits && price.benefits.length > 0) {
      for (const benefit of price.benefits) {
        data.append(`price_type[${index}][benefits][]`, benefit);
      }
    }
    data.append(`price_type[${index}][price]`, parseFloat(price.price));
    price.payment_address &&
      data.append(
        `price_type[${index}][payment_address]`,
        price.payment_address
      );
    if (price.qr_code) {
      data.append(`price_type[${index}][qr_code]`, price.qr_code);
    }
  });

  return instance.post('/api/v1/admin/booking-type', data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubBookingTypeCreate = (token, params) => {
  return new Promise((resolve, reject) => {
    postThubBookingTypeCreate(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubBookingTypeDetail = async (token, params) => {
  return instance.get('/api/v1/admin/booking-type/' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubBookingTypeDetail = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubBookingTypeDetail(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patchThubBookingTypeEdit = (token, params, userId) => {
  var data = new FormData();
  const {
    image,
    // seats,
    price_type,
    price_type_default,
    capacities,
    ...rest
  } = params;
  for (var key in rest) {
    data.append(key, rest[key]);
  }

  data.append('capacities', `${capacities}`);

  Array.from(image).forEach((file) => {
    data.append('image', file);
  });

  Array.from(price_type).forEach((price, index) => {
    data.append(`price_type[${index}][type]`, price.type);
    if (price.benefits && price.benefits.length > 0) {
      for (const benefit of price.benefits) {
        data.append(`price_type[${index}][benefits][]`, benefit);
      }
    }
    data.append(`price_type[${index}][price]`, parseFloat(price.price));
    price.payment_address &&
      data.append(
        `price_type[${index}][payment_address]`,
        price.payment_address
      );
    if (price.qr_code) {
      data.append(`price_type[${index}][qr_code]`, price.qr_code);
    }
    if (price.deleted) {
      data.append(`price_type[${index}][deleted]`, price.deleted);
    }
  });

  //   const speakersString = body?.speakers
  //   ?.map((speaker: ISpeakersItemForm) => {
  //     const data = JSON.stringify({
  //       fullname: speaker.fullname,
  //       tittle: speaker.title,
  //       about: speaker.about,
  //       link_to_personal_data: speaker.personal_link,
  //       instagram: speaker.instagram || '',
  //       twitter: speaker.twitter || '',
  //     });
  //     speaker?.picture && formBody.append(`portrait_image`, speaker?.picture);
  //     return data;
  //   })
  //   .join(',')
  //   .replace('
  // [', '')
  //   .replace(']', '');

  return instance.patch('/api/v1/admin/booking-type/' + userId, data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubBookingTypeEdit = (token, params, userId) => {
  return new Promise((resolve, reject) => {
    patchThubBookingTypeEdit(token, params, userId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubBookingTypeSeatList = (token, userId, params) => {
  return instance.get(
    `/api/v1/admin/booking-type/${userId}/seat-room` + params,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubBookingTypeSeatList = (token, userId, params) => {
  return new Promise((resolve, reject) => {
    getThubBookingTypeSeatList(token, userId, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const putThubBookingTypeEditSeatList = (
  token,
  params,
  bookingTypeId,
  seatId
) => {
  var data = new FormData();
  // for (var key in params) {
  //   data.append(key, params[key]);
  // }
  data.append('seat_name', params.seat_name);
  data.append('status', params.status);
  params.images &&
    params.images.forEach((val) => {
      data.append('image', val);
    });

  return instance.put(
    `/api/v1/admin/booking-type/${bookingTypeId}/seat-room/${seatId}`,
    data,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubBookingTypeEditSeatList = (
  token,
  params,
  bookingTypeId,
  seatId
) => {
  return new Promise((resolve, reject) => {
    putThubBookingTypeEditSeatList(token, params, bookingTypeId, seatId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubBookingTypeCreateSeatList = (token, params, bookingTypeId) => {
  var data = new FormData();
  data.append('seat_name', params.seat_name);
  data.append('status', params.status);
  params.images &&
    params.images.forEach((val) => {
      data.append('image', val);
    });

  // for (var key in params) {
  //   data.append(key, params[key]);
  // }

  return instance.post(
    `/api/v1/admin/booking-type/${bookingTypeId}/seat-room`,
    data,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubBookingTypeCreateSeatList = (
  token,
  params,
  bookingTypeId,
  seatId
) => {
  return new Promise((resolve, reject) => {
    postThubBookingTypeCreateSeatList(token, params, bookingTypeId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubSeatListByBookingType = (token, bookingTypeId) => {
  return instance.get(
    `/api/v1/admin/booking-type/seat-room/${bookingTypeId}/list`,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubSeatListByBookingType = (token, bookingTypeId) => {
  return new Promise((resolve, reject) => {
    getThubSeatListByBookingType(token, bookingTypeId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubBookingOrderCreate = (token, data, orderType) => {
  const { start_date, end_date, ...rest } = data;
  const formattedStartDate = start_date.split('T').join(' ');
  const formattedEndDate = end_date.split('T').join(' ');

  const formData = {
    ...rest,
    start_date: formattedStartDate,
    end_date: formattedEndDate,
  };
  return instance.post(`/api/v1/admin/booking/${orderType}`, formData, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubBookingOrderCreate = (token, data, orderType) => {
  return new Promise((resolve, reject) => {
    postThubBookingOrderCreate(token, data, orderType)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubBookingOrderList = (token, params) => {
  return instance.get('/api/v1/admin/booking' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubBookingOrderList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubBookingOrderList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubBookingOrderDetail = (token, bookingId) => {
  return instance.get('/api/v1/admin/booking/' + bookingId, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubBookingOrderDetail = (token, bookingId) => {
  return new Promise((resolve, reject) => {
    getThubBookingOrderDetail(token, bookingId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubCheckSeatAvailibility = (token, params) => {
  return instance.get('/api/v1/schedule/is-overlap' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubCheckSeatAvailibility = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubCheckSeatAvailibility(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const putThubChangeApprovalStatus = (token, bookingId, data) => {
  return instance.put(
    `/api/v1/admin/booking/${bookingId}/approval-status`,
    data,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubChangeApprovalStatus = (token, bookingId, data) => {
  return new Promise((resolve, reject) => {
    putThubChangeApprovalStatus(token, bookingId, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const putThubChangePaymentStatus = (token, bookingId, data) => {
  return instance.put(
    `/api/v1/admin/booking/${bookingId}/payment-status`,
    data,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubChangePaymentStatus = (token, bookingId, data) => {
  return new Promise((resolve, reject) => {
    putThubChangePaymentStatus(token, bookingId, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patchThubBookingOrderEdit = (token, data, bookingId, orderType) => {
  return instance.patch(
    `/api/v1/admin/booking/${bookingId}/${orderType}`,
    data,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubBookingOrderEdit = (token, data, bookingId, orderType) => {
  const { start_date, end_date, ...rest } = data;
  const formattedStartDate = start_date.split('T').join(' ');
  const formattedEndDate = end_date.split('T').join(' ');

  const formData = {
    ...rest,
    start_date: formattedStartDate,
    end_date: formattedEndDate,
  };

  return new Promise((resolve, reject) => {
    patchThubBookingOrderEdit(token, formData, bookingId, orderType)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubConfigurationList = (token, params) => {
  return instance.get('/api/v1/admin/configuration-type' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubConfigurationList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubConfigurationList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubConfigurationDetail = (token, configId) => {
  return instance.get('/api/v1/admin/configuration-type/' + configId, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubConfigurationDetail = (token, configId) => {
  return new Promise((resolve, reject) => {
    getThubConfigurationDetail(token, configId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubConfigurationCreate = (token, data) => {
  const { email_admins, ...rest } = data;

  let params = new URLSearchParams();

  const adminEmail =
    (Array.isArray(email_admins) &&
      email_admins.reduce(
        (prev, cur) =>
          prev === ''
            ? (prev = cur.email_admins)
            : (prev += ', ' + cur.email_admins),
        ''
      )) ||
    '';

  for (var key in rest) {
    params.append(key, rest[key]);
  }

  params.append('email_admins', adminEmail);

  return instance.post(`/api/v1/admin/configuration-type`, params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const fetchThubConfigurationCreate = (token, data) => {
  return new Promise((resolve, reject) => {
    postThubConfigurationCreate(token, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patchThubConfigurationEdit = (token, data, configId) => {
  const { email_admins, ...rest } = data;

  let params = new URLSearchParams();

  const adminEmail =
    (Array.isArray(email_admins) &&
      email_admins.reduce(
        (prev, cur) =>
          prev === ''
            ? (prev = cur.email_admins)
            : (prev += ', ' + cur.email_admins),
        ''
      )) ||
    '';

  for (var key in rest) {
    params.append(key, rest[key]);
  }

  params.append('email_admins', adminEmail);

  return instance.patch(
    `/api/v1/admin/configuration-type/${configId}`,
    params,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

const fetchThubConfigurationEdit = (token, data, configId) => {
  return new Promise((resolve, reject) => {
    patchThubConfigurationEdit(token, data, configId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteThubConfigurationType = (token, configId) => {
  return instance.delete('/api/v1/admin/configuration-type/' + configId, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubConfigurationDelete = (token, configId) => {
  return new Promise((resolve, reject) => {
    deleteThubConfigurationType(token, configId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubEventsCreate = (token, params, isOnline, isOffline) => {
  var data = new FormData();
  const {
    proposal,
    speakers,
    start_date,
    end_date,
    image,
    special_request,
    additional_note,
    meeting_link,
    event_recurring,
    location_id,
    booking_type_id,
    seat_id,
    product_code,
    ...rest
  } = params;
  const formattedStartDate = start_date.split('T').join(' ');
  const formattedEndDate = end_date.split('T').join(' ');
  const startHour = start_date.split('T');
  const endHour = end_date.split('T');

  const _newEventStartData = moment(formattedStartDate)
    .utc()
    .format('YYYY-MM-DD HH:mm');
  const _newEndStartData = moment(formattedEndDate)
    .utc()
    .format('YYYY-MM-DD HH:mm');

  // console.log(_newEventStartData, '_newEventStartData');
  // console.log(_newEndStartData, '_newEndStartData');

  for (var key in rest) {
    data.append(key, rest[key]);
  }

  if (speakers && speakers.length > 0) {
    Array.from(speakers).forEach((speaker, index) => {
      data.append(`speakers[${index}][fullname]`, speaker.fullname);
      data.append(`speakers[${index}][tittle]`, speaker.tittle);
      data.append(`speakers[${index}][about]`, speaker.about);
      data.append(
        `speakers[${index}][link_to_personal_data]`,
        speaker.link_to_personal_data
      );
      if (speaker.instagram) {
        data.append(`speakers[${index}][instagram]`, speaker.instagram);
      }
      if (speaker.twitter) {
        data.append(`speakers[${index}][twitter]`, speaker.twitter);
      }
      if (speaker.portrait_image && speaker.portrait_image.length > 0) {
        Array.from(speaker.portrait_image).forEach((file) => {
          data.append('portrait_image', file);
        });
      }
    });
  }

  if (meeting_link && meeting_link.length > 0) {
    Array.from(meeting_link).forEach((meetData, i) => {
      data.append(`meeting_link[${i}][link]`, meetData.link);
      data.append(`meeting_link[${i}][pass_code]`, meetData.pass_code);
      data.append(`meeting_link[${i}][platform]`, meetData.platform);
    });
  }

  data.append('start_hour', startHour[1]);
  data.append('end_hour', endHour[1]);
  data.append('start_date', _newEventStartData);
  data.append('end_date', _newEndStartData);

  Array.from(image).forEach((file) => {
    data.append('image', file);
  });

  if (
    additional_note &&
    additional_note !== null &&
    typeof additional_note !== 'undefined'
  ) {
    data.append('additional_note', additional_note);
  }

  if (
    event_recurring &&
    event_recurring !== null &&
    typeof event_recurring !== 'undefined'
  ) {
    data.append('event_recurring', event_recurring);
  }

  if (
    special_request &&
    special_request !== null &&
    typeof special_request !== 'undefined'
  ) {
    data.append('special_request', special_request);
  }

  if (isOnline && isOffline) {
    data.append('location_id', location_id);
    data.append('booking_type_id', booking_type_id);
    data.append('seat_id', seat_id);
    data.append('product_code', product_code);
  } else if (!isOnline && isOffline) {
    data.append('location_id', location_id);
    data.append('booking_type_id', booking_type_id);
    data.append('seat_id', seat_id);
    data.append('product_code', product_code);
  }

  return instance.post('/api/v1/admin/event', data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubEventsCreate = (token, params, isOnline, isOffline) => {
  return new Promise((resolve, reject) => {
    postThubEventsCreate(token, params, isOnline, isOffline)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubPaymentProductList = (token) => {
  return instance.get('/api/v1/admin/payment-product', {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubPaymentProductList = (token) => {
  return new Promise((resolve, reject) => {
    getThubPaymentProductList(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubEventsUploadProposal = (token, eventId, params) => {
  var data = new FormData();

  const { proposal } = params;

  Array.from(proposal).forEach((file) => {
    data.append('proposal', file);
  });

  data.append('id', eventId);

  return instance.post('/api/v1/admin/event/proposal', data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubEventsUploadProposal = (token, eventId, params) => {
  return new Promise((resolve, reject) => {
    postThubEventsUploadProposal(token, eventId, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubEventsList = (token, params) => {
  return instance.get('/api/v1/admin/event' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubEventsList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubEventsList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubEventsDetail = (token, eventId) => {
  return instance.get('/api/v1/admin/event/' + eventId, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubEventsDetail = (token, eventId) => {
  return new Promise((resolve, reject) => {
    getThubEventsDetail(token, eventId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubCollaborationList = (token, params) => {
  return instance.get('/api/v1/admin/collaboration' + params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubCollaborationList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubCollaborationList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubCollaborationDetail = (token, collaborationId) => {
  return instance.get('/api/v1/admin/collaboration/' + collaborationId, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubCollaborationDetail = (token, collaborationId) => {
  return new Promise((resolve, reject) => {
    getThubCollaborationDetail(token, collaborationId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubChangeCollaborationStatus = (token, collaborationId, data) => {
  const url =
    data.approval === 1
      ? `/api/v1/admin/collaboration/${collaborationId}/approve`
      : `/api/v1/admin/collaboration/${collaborationId}/reject`;
  const body = data.approval === 2 ? { reject_reason: data.reject_reason } : '';

  return instance.post(url, body, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubChangeCollaborationStatus = (token, collaborationId, data) => {
  return new Promise((resolve, reject) => {
    postThubChangeCollaborationStatus(token, collaborationId, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patchThubCollaborationSpeakersEdit = (token, params, id) => {
  var data = new FormData();
  const { portrait_image, instagram, twitter, ...rest } = params;
  for (var key in rest) {
    data.append(key, rest[key]);
  }

  if (instagram && instagram !== null && typeof instagram !== 'undefined') {
    data.append('instagram', instagram);
  }

  if (twitter && twitter !== null && typeof twitter !== 'undefined') {
    data.append('twitter', twitter);
  }

  if (
    portrait_image &&
    portrait_image.length > 0 &&
    typeof portrait_image === 'object'
  ) {
    Array.from(portrait_image).forEach((img) => {
      data.append('portrait_image', img);
    });
  }

  return instance.patch(`/api/v1/admin/collaboration/${id}`, data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubCollaborationSpeakersEdit = (token, params, id) => {
  return new Promise((resolve, reject) => {
    patchThubCollaborationSpeakersEdit(token, params, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubCollaborationSpeakersCreate = (token, params) => {
  var data = new FormData();
  const { portrait_image, ...rest } = params;
  for (var key in rest) {
    data.append(key, rest[key]);
  }

  if (portrait_image && portrait_image.length > 0) {
    Array.from(portrait_image).forEach((img) => {
      data.append('portrait_image', img);
    });
  }

  return instance.post(`/api/v1/admin/collaboration`, data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubCollaborationSpeakersCreate = (token, params) => {
  return new Promise((resolve, reject) => {
    postThubCollaborationSpeakersCreate(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patchThubEventsEdit = (token, params, eventId, isOnline, isOffline) => {
  var data = new FormData();
  const {
    proposal,
    start_date,
    end_date,
    image,
    additional_note,
    event_recurring,
    meeting_link,
    special_request,
    location_id,
    booking_type_id,
    seat_id,
    product_code,
    location_type,
    temp_start_date,
    temp_end_date,
    ...rest
  } = params;
  // const formattedStartDate = start_date.split('T').join(' ');
  // const formattedEndDate = end_date.split('T').join(' ');
  const _newEventStartData = start_date;
  const _newEndStartData = end_date;
  const startHour = _newEventStartData.split(' ');
  const endHour = _newEndStartData.split(' ');

  for (var key in rest) {
    data.append(key, rest[key]);
  }
  data.append('start_hour', startHour[1]);
  data.append('end_hour', endHour[1]);
  data.append('start_date', _newEventStartData);
  data.append('end_date', _newEndStartData);

  console.log(location_type);
  if (location_type && location_type.length > 0) {
    for (const locType of location_type) {
      data.append(`location_type[]`, locType);
    }
  }

  if (image && image.length > 0) {
    Array.from(image).forEach((file) => {
      data.append('image', file);
    });
  }

  if (meeting_link && meeting_link.length > 0) {
    Array.from(meeting_link).forEach((meetData, i) => {
      data.append(`meeting_link[${i}][link]`, meetData.link);
      data.append(`meeting_link[${i}][pass_code]`, meetData.pass_code);
      data.append(`meeting_link[${i}][platform]`, meetData.platform);
    });
  }

  if (
    additional_note &&
    additional_note !== null &&
    typeof additional_note !== 'undefined'
  ) {
    data.append('additional_note', additional_note);
  }

  if (
    event_recurring &&
    event_recurring !== null &&
    typeof event_recurring !== 'undefined'
  ) {
    data.append('event_recurring', event_recurring);
  }

  if (
    special_request &&
    special_request !== null &&
    typeof special_request !== 'undefined'
  ) {
    data.append('special_request', special_request);
  }

  if (isOnline && isOffline) {
    data.append('location_id', location_id);
    data.append('booking_type_id', booking_type_id);
    data.append('seat_id', seat_id);
    data.append('product_code', product_code);
  } else if (!isOnline && isOffline) {
    data.append('location_id', location_id);
    data.append('booking_type_id', booking_type_id);
    data.append('seat_id', seat_id);
    data.append('product_code', product_code);
  }

  return instance.patch(`/api/v1/admin/event/${eventId}`, data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchThubEventsEdit = (token, params, eventId, isOnline, isOffline) => {
  return new Promise((resolve, reject) => {
    patchThubEventsEdit(token, params, eventId, isOnline, isOffline)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteThubEvents = (token, eventId) => {
  return instance.delete('/api/v1/admin/event/' + eventId, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubEventsDelete = (token, eventId) => {
  return new Promise((resolve, reject) => {
    deleteThubEvents(token, eventId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubBookingOrderHistory = (token, bookingId, params) => {
  return instance.get(
    `/api/v1/admin/booking-change-history?booking_id=${bookingId}` + params,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
    }
  );
};

const fetchThubBookingOrderHistory = (token, bookingId, params) => {
  return new Promise((resolve, reject) => {
    getThubBookingOrderHistory(token, bookingId, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postThubCreateEventTag = (token, params) => {
  var data = new FormData();
  for (var key in params) {
    data.append(key, params[key]);
  }

  return instance.post(`/api/v1/admin/event-tag`, params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubCreateEventTag = (token, params) => {
  return new Promise((resolve, reject) => {
    postThubCreateEventTag(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubEventTagsList = (token, params) => {
  const apiParams = params.replace('#', '%23');
  return instance.get('/api/v1/admin/event-tag' + apiParams, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubEventTagsList = (token, params) => {
  return new Promise((resolve, reject) => {
    getThubEventTagsList(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getThubTagsDetail = (token, tagId) => {
  return instance.get('/api/v1/admin/event-tag/' + tagId, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubTagsDetail = (token, tagId) => {
  return new Promise((resolve, reject) => {
    getThubTagsDetail(token, tagId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patchThubEditEventTag = (token, params, tagId) => {
  var data = new FormData();
  for (var key in params) {
    data.append(key, params[key]);
  }

  return instance.patch(`/api/v1/admin/event-tag/${tagId}`, params, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const fetchThubEditEventTag = (token, params, tagId) => {
  return new Promise((resolve, reject) => {
    patchThubEditEventTag(token, params, tagId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getParticipantEvent = (token, params) => {
  return instance.get(`/api/v1/admin/participant-event`, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
    params,
  });
};

const fetchParticipantEvent = (token, params) => {
  return new Promise((resolve, reject) => {
    getParticipantEvent(token, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const participantEventAttend = (token, body) => {
  return instance.post(`/api/v1/admin/event/attend`, body, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      Authorization: token,
    },
  });
};

const postParticipantEventAttend = (token, body) => {
  return new Promise((resolve, reject) => {
    participantEventAttend(token, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getExportParticipantEvent = (token, params, event_id) => {
  return instance.get(
    `/api/v1/admin/participant-event/${event_id}/export-to-csv`,
    {
      headers: {
        'X-TCDX-SIGNATURE': 'salamtothemoon',
        Authorization: token,
      },
      params,
    }
  );
};

const fetchExportParticipantEvent = (token, params, id) => {
  return new Promise((resolve, reject) => {
    getExportParticipantEvent(token, params, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  fetchThubUserList,
  fetchThubUserAll,
  fetchThubUserDetail,
  fetchThubBlockUser,
  fetchThubLocationList,
  fetchThubLocationAll,
  fetchThubLocationDetail,
  fetchThubLocationCreate,
  fetchThubLocationEdit,
  fetchThubUserHistoryLog,
  fetchThubUserLoginHistory,
  fetchThubBookingTypeList,
  fetchThubBookingTypeByLocation,
  fetchThubRateTypeList,
  fetchThubBookingTypeCreate,
  fetchThubBookingTypeDetail,
  fetchThubBookingTypeEdit,
  fetchThubBookingTypeSeatList,
  fetchThubBookingTypeEditSeatList,
  fetchThubSeatListByBookingType,
  fetchThubBookingOrderCreate,
  fetchThubBookingOrderList,
  fetchThubBookingOrderDetail,
  fetchThubCheckSeatAvailibility,
  fetchThubChangeApprovalStatus,
  fetchThubBookingOrderEdit,
  fetchThubConfigurationList,
  fetchThubConfigurationDetail,
  fetchThubConfigurationCreate,
  fetchThubConfigurationEdit,
  fetchThubConfigurationDelete,
  fetchThubEventsCreate,
  fetchThubPaymentProductList,
  fetchThubEventsUploadProposal,
  fetchThubEventsList,
  fetchThubEventsDetail,
  fetchThubCollaborationList,
  fetchThubCollaborationDetail,
  fetchThubChangeCollaborationStatus,
  fetchThubCollaborationSpeakersEdit,
  fetchThubEventsEdit,
  fetchThubEventsDelete,
  fetchThubBookingOrderHistory,
  fetchThubChangePaymentStatus,
  fetchThubBookingTypeCreateSeatList,
  fetchThubCollaborationSpeakersCreate,
  fetchThubCreateEventTag,
  fetchThubEventTagsList,
  fetchThubTagsDetail,
  fetchThubEditEventTag,
  fetchParticipantEvent,
  postParticipantEventAttend,
  fetchExportParticipantEvent,
};
