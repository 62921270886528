import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ReusableFormGenerator, { FIELD_TYPE } from '../../components/reusableFormGenerator'
import { isValidHttpUrl } from '../../helper'
import { createConfig, getConfigs, updateSelectedConfig } from '../../otc_services/resources/configuration/configuration_service'

const Configuration = () => {
  const [configs, setConfigs] = useState([])
  const [inputs, setInputs] = useState({
    redirectLink: "",
    adsBanner: null,
    termAndCondition: ""
  })
  const [termAndCondition, setTermAndCondition] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  
  const fields = [
    [
      {
        type: FIELD_TYPE.IMAGE_FILE,
        label: "Ads Banner",
        src: inputs.adsBanner,
        onChange: (e) => {
          let file = e.target.files[0];
          if(file) setInputs({...inputs, adsBanner: file})
        },
        formText: "*Recommendation: Please using the same length and width for this image",
        // onError: (e) => {
        //   console.log(e)
        // },
      },
    ],
    [
      {
        type: FIELD_TYPE.NUMBER_INPUT,
        label: "Ads Url",
        value: inputs.redirectLink,
        onChange: (evt) => setInputs({...inputs, redirectLink: evt.target.value}),
        placeholder: "Ads Url",
        required: true
      },
    ],
    [
      {
        type: FIELD_TYPE.WYSIWYG,
        label: "Terms & Conditions",
        data: termAndCondition,
        onChange: ( event ) => {
          const data = event.editor.getData();
          setTermAndCondition(data)
        },
        placeholder: "Terms & Conditions",
        required: true
      },
    ]
  ]
  const getListConfig = () => {
    getConfigs()
      .then((res) => {
        setConfigs(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(()=>{
        setIsLoading(false)
      })
  }
  const firstInit = () => {
    setIsLoading(true)
    getListConfig()
  }
  const configsListener = () => {
    if(configs.length > 0) {
      let newInputs = {...inputs}
      configs.forEach(config => {
        newInputs[config.config_key] = config.config_value
      })

      if(newInputs.termAndCondition) setTermAndCondition(newInputs.termAndCondition)

      if(newInputs.adsBanner) {
        if(!isValidHttpUrl(newInputs.adsBanner)){
          const url = process.env.REACT_APP_ENV === "demo" ? "https://tcdx-otc-demo.oss-ap-southeast-5.aliyuncs.com" : "https://tcdx-otc-prod.oss-ap-southeast-5.aliyuncs.com"
          newInputs.adsBanner = url + '/' + newInputs.adsBanner
        }
        setInputs(newInputs)
      }
      else setInputs(newInputs)
    }
  }
  
  useEffect(firstInit, [])
  useEffect(configsListener, [configs])

  const submitHandler = () => {
    let promises = []
    Object.entries(inputs).forEach(([keyName, keyVal]) => {
      const findExisting = configs.find(config => config.config_key === keyName)
      
      if(findExisting) {
        if(keyVal instanceof File) {
          const payloadFormData = new FormData()
          payloadFormData.append('config_key', findExisting.config_key)
          payloadFormData.append('config_value', findExisting.config_key === 'termAndCondition' ? termAndCondition : keyVal)
          payloadFormData.append('is_enable', true)
  
          promises = [...promises, updateSelectedConfig(findExisting.id, payloadFormData)]
        }
        else {
          const payload = {
            config_key: findExisting.config_key,
            config_value: findExisting.config_key === 'termAndCondition' ? termAndCondition : keyVal,
            is_enable: true
          }
          promises = [...promises, updateSelectedConfig(findExisting.id, payload)]
        }
      }
      else {
        if(keyVal instanceof File) {
          const payloadFormData = new FormData()
          payloadFormData.append('config_key', keyName)
          payloadFormData.append('config_value', keyName === 'termAndCondition' ? termAndCondition : keyVal)
          payloadFormData.append('is_enable', true)
          
          promises = [...promises, createConfig(payloadFormData)]
        }
        else {
          const payload = {
            config_key: keyName,
            config_value: keyName === 'termAndCondition' ? termAndCondition : keyVal,
            is_enable: true
          }
          promises = [...promises, createConfig(payload)]
        }
      }
    })
    setIsLoading(true)
    Promise.all(promises)
      .then(() => {
        Swal.fire(
          'Success!',
          `Your data has been submitted!`,
          'success'
        )
        getListConfig()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(()=>{
        setIsLoading(false)
      })
  }
  return (
    <ReusableFormGenerator 
      title="Configuration"
      fields={fields}
      footer={
        <button
          type="button"
          disabled={isLoading}
          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
            {
              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
            }
          )}`}
          onClick={()=>submitHandler()}
        >
          Submit
        </button>
      }
      isSubmit={(d)=>submitHandler()}
    />
  )
}

export default Configuration