import { useFormik } from "formik";
import { FC, useReducer } from "react";
import { RouteComponentProps } from "react-router";
import { createFaq, getCategories, getFaq, updateFaq } from "../../crud/helpdesk";
import { CategoryRequest, FAQRequest } from "../../crud/helpdesk-types";
import { debounce, useDebounce } from "../../helper";
import { SectionWrapper } from "../user-new/user";
import Swal from "sweetalert2";
import FormWithFormik from "../../components/reusableFormWithFormik";
import { Button } from "react-bootstrap";
import * as yup from "yup";

interface InitialState {
  loading: boolean;
  categories: Array<CategoryRequest>;
}

interface IFormCreate extends FAQRequest {

}

const initialState: InitialState = {
  loading: false,
  categories: []
}

const FaqCreate: FC<RouteComponentProps<{id?: string}>> = (props) => {
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)

  const formikConfig = useFormik<IFormCreate>({
    initialValues: {
      answer: "",
      question: "",
      category_id: "",
    },
    onSubmit: async (values) => {
      dispatch({loading: true})
      try {
        if(values.id) await updateFaq(values.id, values)
        else await createFaq(values)

        Swal.fire("Success", values.id ? "Successfully updated data!" : "Successfully created data!", "success")
        props.history.push("/helpdesk/faqs")
      }
      catch(err: unknown) {
        console.log(err)
      }
      dispatch({loading: false})
    },
    validationSchema: yup.object({
      answer: yup.string().required('Answer must be fill!'),
      question: yup.string().required('Question must be fill!'),
      category_id: yup.string().required('Category must be fill!'),
    })
  })

  useDebounce<[string | undefined]>(async ()=>{
    try {
      if(props.match.params?.id) {
        const response = await getFaq(props.match.params.id)
  
        if(response.data.data) {
          formikConfig.setValues({
            id: props.match.params?.id,
            answer: response.data.data.answer,
            question: response.data.data.question,
            category_id: response.data.data.category_id.id ? response.data.data.category_id.id.toString() : ""
          })

          if(response.data.data.category_id) {
            dispatch({ categories: [response.data.data.category_id] })
          }
        }
      }
    }
    catch(err: unknown) {
      console.log(err)
    }
    dispatch({loading: false})
  },[props.match.params?.id], undefined, () => dispatch({loading: true}))
  const isUpdateMode = !!formikConfig.values.id

  return (
    <SectionWrapper>
      <form className="custom-flex-col gap-3" onSubmit={formikConfig.submitForm}>
        <h2>{isUpdateMode ? "Update FAQs" : "Create FAQs"}</h2>
        <FormWithFormik 
          label="Question"
          type="TEXT_AREA"
          name="question"
          placeholder="Question"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Answer"
          type="TEXT_AREA"
          name="answer"
          placeholder="Answer"
          config={formikConfig}
        />
        <FormWithFormik 
          label="Category"
          type="ASYNC_SEARCHABLE_SELECT"
          name="category_id"
          placeholder="Category"
          config={formikConfig}
          isClearable
          options={state.categories.map(c => ({
            label: c.title,
            value: c.id ? c.id.toString() : ""
          }))}
          loadOptions={debounce((inputValue, callback) => {
            getCategories({
              limit: 15,
              page: 1,
              q: inputValue ? inputValue : undefined
            })
            .then((res) => {
              if(res.data.data?.data) {
                dispatch({categories: res.data.data.data})
                callback(res.data.data.data.map(d => ({
                  label: d.title,
                  value: d.id ?? ""
                })))
              }
            })
            .catch(err => {
              console.log(err)
            })
          }, 400)}
        />
        <br />
        <div className="custom-flex-row-center">
          <Button 
            onClick={()=>props.history.goBack()} 
            variant="outline-info"
            disabled={state.loading}
          >
            Back
          </Button>
          <Button 
            variant="outline-success" 
            onClick={()=>formikConfig.handleSubmit()}
            disabled={state.loading}
          >
            {isUpdateMode ? "Update FAQ" : "Create FAQ"}
          </Button>
        </div>
      </form>
    </SectionWrapper>
  )
}

export default FaqCreate