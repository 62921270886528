import { useEffect, useState } from "react"
import ReusableTable, { ReusableTableColumn, ReusableTableOnSort, ReusableTableProps } from "../reusableTable"

export interface PaginationTableProps<T extends object> extends Omit<Pick<ReusableTableProps<T>, "dataSources" | "columns" | "loading">, "columns"> {
  columns: (activePage: number, limit: number) => Array<ReusableTableColumn<T>>;
  limit?: number;
  className?: string;
  onSort?: ReusableTableOnSort<T>;
}
const PaginationTable = <T extends object>(props: PaginationTableProps<T>) => {
  const limit = props.limit ?? 20
  const [activePage, setActivePage] = useState<number>(1)

  const getDataSourceByPartition = () => {
    const minIndex = (activePage-1) * limit
    const maxIndex = activePage * limit
    let result: Array<T> = []

    for(let i = minIndex; i < maxIndex; i++) {
      if(props.dataSources[i]) result.push(props.dataSources[i])
    }

    return result
  }

  useEffect(()=>{
    if(props.dataSources.length === 0) setActivePage(1)
  },[props.dataSources])

  return (
    <ReusableTable 
      className={props.className}
      loading={props.loading}
      dataSources={getDataSourceByPartition()}
      columns={props.columns(activePage, limit)}
      onSort={props.onSort}
      pagination={{
        itemsCountPerPage: limit,
        totalItemsCount: props.dataSources.length,
        onChange: (pageNumber: number) => setActivePage(pageNumber),
        activePage,
      }}
    />
  )
}

export default PaginationTable
