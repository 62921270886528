import { DetailedHTMLProps, FC, forwardRef, HTMLAttributes, ReactNode } from "react"

export interface ReusableTableContent {
  icon?: ReactNode;
  title: ReactNode;
  subTitle: ReactNode;
  desc: ReactNode;
}

export interface ReusableTimelineProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  timelines: Array<ReusableTableContent>;
}

const ReusableTimeline = forwardRef<HTMLDivElement, ReusableTimelineProps>((props,ref) => {
  const { className, timelines, ...anotherProps } = props
  return (
    <div ref={ref} className={`timeline ${className ?? ''}`} {...anotherProps}>
      <ul className="timeline-with-icons">
        {timelines.map((t, tIndex) =>
          <li className="timeline-item mb-5" key={tIndex}>
            <span className="timeline-icon">
              {t.icon}
            </span>
            <p className="timeline-subtitle text-muted mb-1 fw-bold">{t.subTitle}</p>
            <h5 className="fw-bold">{t.title}</h5>
            <p className="text-muted">{t.desc}</p>
          </li>
        )}
      </ul>
    </div>
  )
})
ReusableTimeline.displayName = "ReusableTimeline"

export default ReusableTimeline