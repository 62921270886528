export default {
  UNIT_TEST_PREFIX_ID: {
    ERROR_MESSAGE: "error-message-test-",
    FIELD_WRAPPER: "field-wrapper-test-",
    FIELD: "field-test-",
    FIELD_COUNTER: "field-counter-test-",
    SUBMIT_BTN: "submit-btn-test",
    REUSABLE_TABLE_ROW: "reusable-table-row",
    REUSABLE_TABLE_ROW_HEAD: "reusable-table-row-head",
    REUSABLE_TABLE_COLUMN: "reusable-table-column",
    REUSABLE_TABLE_COLUMN_HEAD: "reusable-table-column-head",
  }
}