import { Fragment, useEffect, useRef, useState } from 'react';
import FormWithFormik from '../../components/reusableFormWithFormik'
import clsx from 'clsx';
import moment from 'moment';
import actions from '../../crud/leaderboard';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Swal from 'sweetalert2';
import 'react-datepicker/dist/react-datepicker.css';
import {
  createLeaderboards,
  getLeaderboardById,
  updateLeaderboardById,
  importCsvCoin,
  downloadImportCsvCoin,
  downloadTemplateCsvCoin,
} from '../../leaderboard_services/resources/leaderboard';
import { convertTZ, isValidHttpUrl } from '../../helper';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router';

require('bootstrap/scss/bootstrap.scss');

const addEventValidationSchema = yup.object({
  startDate: yup
    .mixed()
    .nullable()
    .required('Start Date must be fill')
    .test(
      'validate-startdate',
      'Start Date must more than now!',
      (value, context) => {
        return moment(value).isAfter(moment());
      }
    )
    .test(
      'validate-startdate',
      'Start Date must less than end date!',
      (value, context) => {
        const endDate = context.parent.endDate;
        return endDate && !moment(value).isAfter(moment(endDate));
      }
    ),
  endDate: yup
    .mixed()
    .nullable()
    .required('End Date must be fill!')
    .test(
      'validate-enddate',
      'End Date must more than Start Date!',
      (value, context) => {
        const startDate = context.parent.startDate;
        return startDate && moment(value).isAfter(moment(startDate));
      }
    ),

  position: yup
    .string()
    .required()
    .test('position-check', 'Position must more than 0', (value, context) => {
      return parseInt(value) > 0;
    }),
  tradingStartDate: yup
    .mixed()
    .nullable()
    .test(
      'trading-start-date-validation',
      'Trading Start Date must be fill!',
      (value, context) => {
        const is_manual = context.parent.is_manual;
        const selectedOption = context.parent.selectedOption;
        return is_manual === '0' && selectedOption === 'leaderboard'
          ? value && value !== ''
          : true;
      }
    )
    .test(
      'trading-start-date-validation',
      'Trading Start Date must more than now!',
      (value, context) => {
        const is_manual = context.parent.is_manual;
        const is_edit = context.parent.is_edit;
        return is_manual === '0' && !is_edit
          ? moment(value).isAfter(moment())
          : true;
      }
    ),
  tradingEndDate: yup
    .mixed()
    .nullable()
    .test(
      'trading-enddate-test',
      'Trading End Date must be fill!',
      (value, context) => {
        const is_manual = context.parent.is_manual;
        const selectedOption = context.parent.selectedOption;
        return is_manual === '0' && selectedOption === 'leaderboard'
          ? value && value !== ''
          : true;
      }
    )
    .test(
      'trading-enddate-test',
      'Trading End Date must more than Trading Start Date!',
      (value, context) => {
        const is_manual = context.parent.is_manual;
        const tradingStartDate = context.parent.tradingStartDate;
        return is_manual === '0'
          ? tradingStartDate
            ? moment(value).isAfter(moment(tradingStartDate))
            : true
          : true;
      }
    )
    .test(
      'trading-enddate-test',
      'Trading End Date must more than Form End Date!',
      (value, context) => {
        const is_manual = context.parent.is_manual;
        const formEndDate = context.parent.form_end_date;
        const is_edit = context.parent.is_edit;
        return is_manual === '0' && !is_edit
          ? moment(value).isAfter(moment(formEndDate))
          : true;
      }
    ),
  form_start_date: yup
    .mixed()
    .nullable()
    .test(
      'form-startdate-validation',
      'Form Start Date must be fill!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        const form_title_en = context.parent.form_title_en;
        const form_content_id = context.parent.form_content_id;
        const form_content_en = context.parent.form_content_en;
        const selected_participant = context.parent.selected_participant;
        // const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const checkOtherForm =
          form_title_en ||
          form_content_en ||
          form_title_id ||
          form_content_id ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? value && value !== ''
              : checkOtherForm
              ? value && value !== ''
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? value && value !== ''
              : true;
        }

        return valid;
      }
    )
    .test(
      'form-startdate-validation',
      'Form Start Date must more than now!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        const form_title_en = context.parent.form_title_en;
        // const form_content_id = context.parent.form_content_id;
        const form_content_en = context.parent.form_content_en;
        const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const selected_participant = context.parent.selected_participant;

        const checkOtherForm =
          form_title_en ||
          form_content_en ||
          form_title_id ||
          form_start_date ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? moment(value).isAfter(moment())
              : checkOtherForm
              ? moment(value).isAfter(moment())
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? moment(value).isAfter(moment())
              : true;
        }

        return valid;
      }
    ),
  form_end_date: yup
    .mixed()
    .nullable()
    .test(
      'form-enddate-validation',
      'Form End Date must be fill!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        const form_title_en = context.parent.form_title_en;
        const form_content_id = context.parent.form_content_id;
        const form_content_en = context.parent.form_content_en;
        const form_start_date = context.parent.form_start_date;
        // const form_end_date = context.parent.form_end_date;
        const selected_participant = context.parent.selected_participant;
        const checkOtherForm =
          form_title_en ||
          form_content_en ||
          form_title_id ||
          form_start_date ||
          form_content_id;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? value && value !== ''
              : checkOtherForm
              ? value && value !== ''
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? value && value !== ''
              : true;
        }

        return valid;
      }
    )
    .test(
      'form-enddate-validation',
      'Form End Date must more than Form Start Date!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        const form_title_en = context.parent.form_title_en;
        // const form_content_id = context.parent.form_content_id;
        const form_content_en = context.parent.form_content_en;
        const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const selected_participant = context.parent.selected_participant;
        const checkOtherForm =
          form_title_en ||
          form_content_en ||
          form_title_id ||
          form_start_date ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? form_start_date
                ? moment(value).isAfter(moment(form_start_date))
                : true
              : checkOtherForm
              ? form_start_date
                ? moment(value).isAfter(moment(form_start_date))
                : true
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? form_start_date
                ? moment(value).isAfter(moment(form_start_date))
                : true
              : true;
        }

        return valid;
      }
    )
    .test(
      'form-enddate-validation',
      'Form End Date must less than Trading End Date!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        const form_title_en = context.parent.form_title_en;
        // const form_content_id = context.parent.form_content_id;
        const form_content_en = context.parent.form_content_en;
        const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const tradingEndDate = context.parent.tradingEndDate;
        const selected_participant = context.parent.selected_participant;
        const checkOtherForm =
          form_title_en ||
          form_content_en ||
          form_title_id ||
          form_start_date ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? !moment(value).isAfter(moment(tradingEndDate))
              : checkOtherForm
              ? !moment(value).isAfter(moment(tradingEndDate))
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? !moment(value).isAfter(moment(tradingEndDate))
              : true;
        }

        return valid;
        // const selectedOption = context.parent.selectedOption;
        // const form_start_date = context.parent.form_start_date;
        // return selectedOption === 'leaderboard'
        //   ? moment(value).isAfter(moment(form_start_date))
        //   : true;
      }
    ),
  eventName: yup.string().required('Event Name is a required field'),
  event_type: yup.string().required('Event Type is a required field'),
  title_en: yup.string().required('Titile (EN) is a required field'),
  title_id: yup.string().required('Title (ID) is a required field'),
  desc_id: yup
    .string()
    .required('Description (ID) is a required field')
    .max(255, 'the maximum character of description is 255 digits'),
  desc_en: yup
    .string()
    .required('Description (EN) is a required field')
    .max(255, 'the maximum character of description is 255 digits'),
  content_id: yup.string().required('Content (ID) is a required field'),
  content_en: yup.string().required('Content (EN) is a required field'),
  form_title_id: yup
    .string()
    .nullable()
    .test(
      'form-titleid-validation',
      'Form title id must filled!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const selected_participant = context.parent.selected_participant;
        const form_title_en = context.parent.form_title_en;
        const form_content_id = context.parent.form_content_id;
        const form_content_en = context.parent.form_content_en;
        const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const checkOtherForm =
          form_title_en ||
          form_content_en ||
          form_content_id ||
          form_start_date ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? value && value !== ''
              : checkOtherForm
              ? value && value !== ''
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? value && value !== ''
              : true;
        }

        return valid;
      }
    ),
  form_title_en: yup
    .string()
    .nullable()
    .test(
      'form-titleen-validation',
      'Form title en must filled!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        // const form_title_en = context.parent.form_title_en;
        const selected_participant = context.parent.selected_participant;
        const form_content_id = context.parent.form_content_id;
        const form_content_en = context.parent.form_content_en;
        const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const checkOtherForm =
          form_title_id ||
          form_content_en ||
          form_content_id ||
          form_start_date ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? value && value !== ''
              : checkOtherForm
              ? value && value !== ''
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? value && value !== ''
              : true;
        }

        return valid;
      }
    ),
  form_content_id: yup
    .string()
    .nullable()
    .test(
      'form-contentid-validation',
      'Form content id must filled!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        const form_title_en = context.parent.form_title_en;
        // const form_content_id = context.parent.form_content_id;
        const selected_participant = context.parent.selected_participant;
        const form_content_en = context.parent.form_content_en;
        const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const checkOtherForm =
          form_title_en ||
          form_content_en ||
          form_title_id ||
          form_start_date ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? value && value !== ''
              : checkOtherForm
              ? value && value !== ''
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? value && value !== ''
              : true;
        }

        return valid;
      }
    ),
  form_content_en: yup
    .string()
    .nullable()
    .test(
      'form-contenten-validation',
      'Form content en must filled!',
      (value, context) => {
        const selectedOption = context.parent.selectedOption;
        const is_edit = context.parent.is_edit;
        const initialFormTitle = context.parent.initialFormTitle;
        const form_title_id = context.parent.form_title_id;
        const form_title_en = context.parent.form_title_en;
        const form_content_id = context.parent.form_content_id;
        // const form_content_en = context.parent.form_content_en;
        const selected_participant = context.parent.selected_participant;
        const form_start_date = context.parent.form_start_date;
        const form_end_date = context.parent.form_end_date;
        const checkOtherForm =
          form_title_en ||
          form_content_id ||
          form_title_id ||
          form_start_date ||
          form_end_date;

        let valid = true;

        if (is_edit) {
          valid =
            initialFormTitle && initialFormTitle !== ''
              ? value && value !== ''
              : checkOtherForm
              ? value && value !== ''
              : true;
        } else {
          valid =
            selectedOption === 'leaderboard' &&
            checkOtherForm &&
            selected_participant === '1'
              ? value && value !== ''
              : true;
        }

        return valid;
      }
    ),
  selected_participant: yup.string(),
  participan_UID: yup
    .string()
    .test(
      'participant-uid-validation',
      'participant uid is required field',
      (value, context) => {
        const is_manual = context.parent.is_manual;
        const selected_participant = context.parent.selected_participant;
        return is_manual === '1' && selected_participant === '1'
          ? value && value !== ''
          : true;
      }
    ),
  slug: yup.string().required(),
  registration_url: yup.string(),
  selectedOption: yup.string(),
  banner: yup.mixed(),
  thumbnail: yup.mixed(),
  selectedDropdown: yup.string(),
  selectedHomepage: yup.string(),
  addButton: yup.array().of(
    yup.object().shape({
      nameID: yup.string().nullable(),
      nameEN: yup.string().nullable(),
      url: yup.string().nullable(),
      class: yup.string().nullable(),
    })
  ),
  selectedCoin: yup
    .array()
    .of(
      yup.object({
        coin: yup
          .string()
          .test(
            'input-selected-coin-validation',
            'coin is required field',
            (value, context) => {
              const is_manual =
                context?.from &&
                Array.isArray(context.from) &&
                context.from[context.from.length - 1].value.is_manual;
              return is_manual !== '1' ? value && value !== '' : true;
            }
          )
          .test(
            'selected-coin-test',
            'Coin at least have 1 data',
            (value, context) => {
              const is_manual =
                context?.from &&
                Array.isArray(context.from) &&
                context.from[context.from.length - 1].value.is_manual;
              return is_manual !== '1' ? value && value !== '' : true;
            }
          ),
        parsing: yup
          .string()
          .test(
            'input-pairing-coin-validation',
            'pairing is required field',
            (value, context) => {
              const is_manual =
                context?.from &&
                Array.isArray(context.from) &&
                context.from[context.from.length - 1].value.is_manual;
              return is_manual !== '1' ? value && value !== '' : true;
            }
          ),
        multiple: yup.string(),
      })
    )
    .test(
      'pairing-list-validation',
      'Pairing List is required field',
      (value, context) => {
        const is_manual = context?.parent.is_manual;
        return is_manual !== '1' ? value.length > 0 : true;
      }
    ),
  inputedTeam: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .test(
          'inputed-teamname-validation',
          'name is required field',
          (value, context) => {
            const event_type =
              context?.from &&
              Array.isArray(context.from) &&
              context.from[context.from.length - 1].value.event_type;
            const is_manual =
              context?.from &&
              Array.isArray(context.from) &&
              context.from[context.from.length - 1].value.is_manual;
            return is_manual !== '1' && event_type !== 'individual'
              ? value && value !== ''
              : true;
          }
        ),
      participant: yup.string(),
      logo: yup.mixed(),
      logo_url: yup.mixed(),
      is_edit: yup.mixed(),
      initialFormTitle: yup.mixed(),
    })
  ),
});

const AddEvent = (props) => {
  const history = useHistory()
  const urlAsset =
    process.env.REACT_APP_ENV === 'demo'
      ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/'
      : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/';
  const [loading, setLoading] = useState(false);
  const [bannerEdit, setBannerEdit] = useState('');
  const [thumbnailEdit, setThumbnailEdit] = useState('');
  const [disabledCoin, setDisabledCoin] = useState({});
  const [listCoinJson, setListCoinJson] = useState([]);
  const [listCoin, setListCoin] = useState([]);
  const coinFileRef = useRef(null);
  const [csvCoinFileValidation, setcsvCoinFileValidation] = useState(true);
  const [csvCoinFile, setcsvCoinFile] = useState(null);
  const [pairingList, setpairingList] = useState('1');

  const redirectToListPage = () => {
    history.push('/leaderboard');
  };

  // const handleChangeEditorID = (event) => {
  //   const data = event.editor.getData();
  //   addEventFormik.setFieldValue('content_id', data);
  // };

  // const handleChangeEditorEN = (event) => {
  //   const data = event.editor.getData();
  //   addEventFormik.setFieldValue('content_en', data);
  // };

  const getToken = () => {
    return localStorage.getItem('token');
  };

  const getCoin = () => {
    setLoading(true);

    actions
      .fetchCoinPairing(getToken())
      .then((res) => {
        const alldata = res.data.data;
        const objectData = Object.keys(alldata);
        setListCoin(objectData || []);
        setListCoinJson(alldata || []);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.code === 401) {
          toast.error('token has expired');
          localStorage.clear();
          history.push('/auth');
          window.location.reload();
        } else {
          toast.error(
            `error code: ${e.response.data.code} message: ${e.response.data.message}`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addEvent = (bodyFormData) => {
    setLoading(true);

    createLeaderboards(bodyFormData)
      .then((res) => {
        toast.success('Add new event is success');
        // emptyState()
        setTimeout(() => {
          redirectToListPage();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.code === 422 && err.response?.data?.errors) {
          let htmlErr = '';
          Object.entries(err.response.data.errors).forEach(([key, val]) => {
            htmlErr += `<div>${key}: ${val}</div>`;
          });
          Swal.fire({
            title: 'Error',
            html: htmlErr,
            icon: 'error',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editEvent = (bodyFormData) => {
    setLoading(true);

    updateLeaderboardById(props.match.params?.id, bodyFormData)
      .then((res) => {
        toast.success('Edit new event is success');

        // emptyState()
        setTimeout(() => {
          redirectToListPage();
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDetailData = () => {
    setLoading(true);

    getLeaderboardById(props.match.params?.id)
      .then((res) => {
        const alldata = res.data.data;
        let makeCoin = alldata.coins.map((coin) => ({
          coin: coin.coin,
          parsing: coin.pairing,
          multiple: coin.multiplication,
        }));
        let makeButton = (alldata.button_homepage || []).map((element) => ({
          nameID: element.nameID,
          nameEN: element.nameEN,
          url: element.url,
          class: element.class,
        }));
        let makeTeam = (alldata.teams || []).map((element) => {
          return {
            name: element.name,
            participant: element.participant.filter((p) => p !== 0).join(','),
            logo: '',
            logo_url: element.logo_url,
          };
        });
        checkIfSameWithState(makeCoin);

        const getParticipants = () => {
          if ((alldata.participant || []).length > 0)
            return (alldata.participant || []).filter((p) => p !== 0).join(',');
          else return alldata.users.filter((p) => p !== 0).join(',');
        };

        addEventFormik.setValues({
          eventName: alldata.event_name,
          title_id: alldata.title_id,
          title_en: alldata.title_en,
          desc_en: alldata.deskripsi_id,
          desc_id: alldata.deskripsi_id,
          event_type: alldata.event_type,
          slug: alldata.slug,
          registration_url: alldata.registration_url,
          position: alldata.position,
          content_id: alldata.wording_id,
          content_en: alldata.wording_en,
          startDate: convertTZ(alldata.startDate),
          endDate: convertTZ(alldata.endDate),
          tradingStartDate: alldata.trading_start_date
            ? convertTZ(alldata.trading_start_date)
            : null,
          tradingEndDate: alldata.trading_end_date
            ? convertTZ(alldata.trading_end_date)
            : null,
          form_title_id: alldata.form_title_id,
          form_title_en: alldata.form_title_en,
          form_content_id: alldata.form_content_id,
          form_content_en: alldata.form_content_en,
          form_start_date: alldata.form_start_date
            ? convertTZ(alldata.form_start_date)
            : null,
          form_end_date: alldata.form_end_date
            ? convertTZ(alldata.form_end_date)
            : null,
          participan_UID: getParticipants(),
          selected_participant: alldata.filtered_user,
          addButton:
            makeButton.length > 0
              ? makeButton
              : addEventFormik.values.addButton,
          selectedCoin:
            makeCoin.length > 0 ? makeCoin : addEventFormik.values.selectedCoin,
          is_manual:
            alldata.trading_start_date && alldata.trading_end_date ? '0' : '1',
          thumbnail: alldata.thumbnail,
          banner: alldata.banner,
          selectedOption: alldata.campaign_type,
          selectedDropdown: alldata.menu_dropdownlist_status,
          selectedHomepage: alldata.homepage_list_status,
          inputedTeam:
            makeTeam.length > 0 ? makeTeam : addEventFormik.values.inputedTeam,
          initialFormTitle: alldata.form_title_id,
          is_edit: props.match.params?.id ? true : false || false,
        });
        setBannerEdit(alldata.banner);
        setThumbnailEdit(alldata.thumbnail);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeListCoin = (i, e) => {
    const coinl = addEventFormik.values.selectedCoin;
    const _coin = [...coinl];
    _coin[i].coin = e.target.value;
    checkIfSameWithState();
    addEventFormik.setFieldValue('selectedCoin', _coin);
  };

  const onChangeListPair = (i, e) => {
    const _pair = [...addEventFormik.values.selectedCoin];
    _pair[i].parsing = e.target.value;
    addEventFormik.setFieldValue('selectedCoin', _pair);
    checkIfSameWithState();
  };

  const onChangeListMultiple = (i, e) => {
    const coinl = addEventFormik.values.selectedCoin;
    const _coin = [...coinl];
    _coin[i].multiple = e.target.value;
    checkIfSameWithState();
    addEventFormik.setFieldValue('selectedCoin', _coin);
  };

  const onChangeNameTeam = (i, e) => {
    const coinl = addEventFormik.values.inputedTeam;
    const _coin = [...coinl];
    _coin[i].name = e.target.value;
    addEventFormik.setFieldValue('inputedTeam', _coin);
  };

  const onChangeUUIDTeamNew = (i, vals) => {
    const newVal = (vals || []).map((val) => val.value).join(', ');
    const _pair = [...addEventFormik.values.inputedTeam];
    _pair[i].participant = newVal;
    addEventFormik.setFieldValue('inputedTeam', _pair);
  };
  const getUUIDTeamValue = (element) => {
    const splittingVals = element.participant
      ? element.participant.replace(/\s/g, '').split(',')
      : [];
    return splittingVals.map((splittingVal) => ({
      label: splittingVal,
      value: splittingVal,
    }));
  };
  const onChangeFileTeam = (i, e) => {
    let file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const _pair = [...addEventFormik.values.inputedTeam];
      if (file) {
        var strImage = reader.result.split('base64,');
        _pair[i].logo = strImage[1];
        addEventFormik.setFieldValue('inputedTeam', _pair);
      }
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };

  const onChangeNameID = (i, e) => {
    const coinl = addEventFormik.values.addButton;
    const _coin = [...coinl];
    _coin[i].nameID = e.target.value;
    addEventFormik.setFieldValue('addButton', _coin);
  };
  const onChangeNameEN = (i, e) => {
    const coinl = addEventFormik.values.addButton;
    const _coin = [...coinl];
    _coin[i].nameEN = e.target.value;
    addEventFormik.setFieldValue('addButton', _coin);
  };
  const onChangeUrl = (i, e) => {
    const coinl = addEventFormik.values.addButton;
    const _coin = [...coinl];
    _coin[i].url = e.target.value;
    addEventFormik.setFieldValue('addButton', _coin);
  };

  const onChangeClassButton = (i, e) => {
    const coinl = addEventFormik.values.addButton;
    const _coin = [...coinl];
    _coin[i].class = e.target.value;
    addEventFormik.setFieldValue('addButton', _coin);
  };

  const onChangeFile = (e) => {
    let file = e.target.files[0];
    if (file) {
      addEventFormik.setFieldValue('banner', file);
    }
  };
  const onChangeFileThum = (e) => {
    let file = e.target.files[0];
    if (file) {
      addEventFormik.setFieldValue('thumbnail', file);
    }
  };

  const handleAdd = () => {
    const _fields = [...addEventFormik.values.selectedCoin];
    _fields.push({ coin: '', parsing: '', multiple: '1' });
    addEventFormik.setFieldValue('selectedCoin', _fields);
  };
  const handleRemove = (i) => {
    const _fields = [...addEventFormik.values.selectedCoin];
    _fields.splice(i, 1);
    addEventFormik.setFieldValue('selectedCoin', _fields);
  };
  const handleAddTeam = () => {
    const _fields = [...addEventFormik.values.inputedTeam];
    _fields.push({
      name: '',
      participant: '',
      logo: '',
      logo_url: '',
    });
    addEventFormik.setFieldValue('inputedTeam', _fields);
  };
  const handleRemoveTeam = (i) => {
    const _fields = [...addEventFormik.values.inputedTeam];
    _fields.splice(i, 1);
    addEventFormik.setFieldValue('inputedTeam', _fields);
  };
  const handleAddButton = () => {
    const _fields = [...addEventFormik.values.addButton];
    _fields.push({
      nameID: '',
      nameEN: '',
      url: '',
    });
    addEventFormik.setFieldValue('addButton', _fields);
  };
  const handleRemoveButton = (i) => {
    const _fields = [...addEventFormik.values.addButton];
    _fields.splice(i, 1);
    addEventFormik.setFieldValue('addButton', _fields);
  };

  const checkIfSameWithState = (coinSend = []) => {
    const _fields =
      coinSend.length > 0 ? coinSend : addEventFormik.values.selectedCoin;
    let makelistdisabled = {};
    _fields.forEach((element) => {
      makelistdisabled[element.coin] = makelistdisabled[element.coin]
        ? { ...makelistdisabled[element.coin] }
        : {};
      if (element.parsing !== '')
        makelistdisabled[element.coin][element.parsing] = true;
    });
    const savedata = makelistdisabled;
    setDisabledCoin(savedata);
  };

  const newFormatDateTime = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };

  const handleOptionChange = (changeEvent) => {
    addEventFormik.setFieldValue('selectedOption', changeEvent.target.value);
    if (changeEvent.target.value === 'static_page') {
      addEventFormik.setFieldValue('is_manual', '1');
    }
  };
  const handleOptionChangeDropdown = (changeEvent) => {
    addEventFormik.setFieldValue('selectedDropdown', changeEvent.target.value);
  };
  const handleOptionChangeHomepage = (changeEvent) => {
    addEventFormik.setFieldValue('selectedHomepage', changeEvent.target.value);
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback([
        // { value: 'chocolate', label: 'Chocolate' },
      ]);
    }, 1000);
  };

  const onKeyDownHandler = (e) => {
    const allowedKeys = ['Enter', 'Backspace', 'Delete'];
    if (!/^\d+$/.test(e.key) && !allowedKeys.includes(e.key))
      e.preventDefault();
  };

  const getParticipanUID = () => {
    const splittingValues = addEventFormik.values.participan_UID
      ? addEventFormik.values.participan_UID.replace(/\s/g, '').split(',')
      : [];
    return splittingValues.map((splittingValue) => ({
      label: splittingValue,
      value: splittingValue,
    }));
  };
  const setterParticipanUID = (vals) => {
    const joinValues = (vals || []).map((val) => val.value).join(', ');
    addEventFormik.setFieldValue('participan_UID', joinValues);
    // setParticipanUID(joinValues);
  };
  const getBannerURL = () => {
    if (isValidHttpUrl(bannerEdit)) return bannerEdit;
    else return urlAsset + bannerEdit;
  };
  const getThumbnailURL = () => {
    if (isValidHttpUrl(thumbnailEdit)) return thumbnailEdit;
    else return urlAsset + thumbnailEdit;
  };
  const getLogoURL = (logoURL) => {
    if (isValidHttpUrl(logoURL)) return logoURL;
    else return urlAsset + logoURL;
  };

  const onChangeSlug = (e) => {
    let event = e.target.value;
    let str = event.replace(/\s+/g, '-').toLowerCase();
    addEventFormik.setFieldValue('eventName', event);
    addEventFormik.setFieldValue('slug', str);
  };

  const listEventA = [
    {
      id: 'individual',
      name: 'Individual',
    },
    {
      id: 'team',
      name: 'Team',
    },
    {
      id: 'individual-team',
      name: 'Individual & Team',
    },
  ];

  const classButton = [
    {
      id: 'btn-register-home',
      name: 'Primary',
    },
    {
      id: 'btn-event-home',
      name: 'Secondary',
    },
    {
      id: 'btn-event-home-border-green',
      name: 'Outline Primary',
    },
    {
      id: 'btn-event-home-white',
      name: 'Outline Secondary',
    },
  ];

  const listMultiplier = [
    {
      id: '1',
      name: 'x1',
    },
    {
      id: '2',
      name: 'x2',
    },
    {
      id: '3',
      name: 'x3',
    },
    {
      id: '4',
      name: 'x4',
    },
    {
      id: '5',
      name: 'x5',
    },
  ];
  const firstInit = () => {
    getCoin();

    if (props.match.params?.id) getDetailData();
  };

  useEffect(firstInit, []);
  const importCoinCsvFile = async () => {
    try {
      // this.setState({ loading: true });
      if (csvCoinFile) {
        const body = new FormData();
        const formatedCoinPair = (addEventFormik.values.selectedCoin &&
          addEventFormik.values.selectedCoin.length > 0 &&
          addEventFormik.values.selectedCoin.map((val) => {
            return {
              Multiplier: val.multiple,
              Coin: val.coin,
              Pairing: val.parsing,
            };
          })) || [{ Multiplier: '', Pairing: '', Coin: '' }];
        body.append('csvFile', csvCoinFile);
        body.append('currentCoinPairing', JSON.stringify(formatedCoinPair));
        const res = await importCsvCoin(body);
        // this.getUIDConverterHistoryData();
        // this.setState({ loading: false });
        const data = res?.data?.data || [];
        const coinValue =
          data &&
          data.length > 0 &&
          data.map((val) => {
            return {
              coin: val?.Coin || '',
              parsing: val.Pairing || '',
              multiple: val?.Multiplier || '',
            };
          });
        await checkIfSameWithState(coinValue);
        addEventFormik.setFieldValue('selectedCoin', coinValue || []);
      }
    } catch (error) {
      console.log(error, 'Error');
    }
  };

  const handleChangeInputCoin = (e) => {
    let filePath = e.target.value;

    // Allowing file type
    let allowedExtensions = /(\.csv)$/i;

    if (!allowedExtensions.exec(filePath)) {
      coinFileRef.current.value = '';
      setcsvCoinFileValidation(false);
      toast.error(`Invalid file type. only csv allowed`);
      return;
    }

    setcsvCoinFileValidation(true);
    setcsvCoinFile(e.target.files[0]);
  };

  const addEventFormik = useFormik({
    initialValues: {
      is_edit: props.match?.params?.id ? true : false || false,
      initialFormTitle: '',
      eventName: '',
      startDate: convertTZ(new Date()),
      endDate: '',
      position: '',
      event_type: 'individual',
      title_en: '',
      title_id: '',
      desc_id: '',
      desc_en: '',
      content_id: `<h2>Syarat Dan Ketentuan</h2><ul><li>Syarat A</li><li>Syarat B</li><li>Syarat C</li><li>Syarat D</li><li>Syarat E</li></ul><p><a href="https://tokocrypto.com">Selengkapnya →</a></p>`,
      content_en: `<h2>Term And Condition</h2><ul><li>Term A</li><li>Term B</li><li>Term C</li><li>Term D</li><li>Term E</li></ul><p><a href="https://tokocrypto.com">Show More →</a></p>`,
      tradingStartDate: '',
      tradingEndDate: '',
      form_title_id: '',
      form_title_en: '',
      form_content_id: '',
      form_content_en: '',
      form_start_date: '',
      form_end_date: '',
      selected_participant: '0',
      participan_UID: '',
      slug: '',
      registration_url: '',
      is_manual: '0',
      selectedOption: 'leaderboard',
      banner: null,
      thumbnail: null,
      selectedDropdown: '1',
      selectedHomepage: '1',
      addButton: [
        {
          nameID: 'Leaderboard',
          nameEN: 'Leaderboard',
          url: '',
          class: 'btn-event-home',
        },
      ],
      selectedCoin: [
        // {
        //   coin: '',
        //   parsing: '',
        //   multiple: '1',
        // },
      ],
      inputedTeam: [
        {
          name: '',
          participant: '',
          logo: '',
          logo_url: '',
        },
      ],
    },
    validationSchema: addEventValidationSchema,
    // enableReinitialize: true,
    onSubmit: (values) => {
      var bodyFormData = new FormData();
      const newTeams = [...addEventFormik.values.inputedTeam].map((team) => {
        return {
          ...team,
          participant: team.participant.replace(/\ /g, '')
            ? team.participant
                .replace(/\ /g, '')
                .split(',')
                .map((p) => Number(p))
            : [],
        };
      });

      bodyFormData.append('event_name', values.eventName);
      bodyFormData.append('title_id', values.title_id);
      bodyFormData.append('title_en', values.title_en);
      bodyFormData.append('deskripsi_id', values.desc_id);
      bodyFormData.append('deskripsi_en', values.desc_en);
      bodyFormData.append('wording_id', values.content_id);
      bodyFormData.append('wording_en', values.content_en);
      bodyFormData.append('slug', values.slug);
      bodyFormData.append('position', values.position);
      bodyFormData.append('start_date', newFormatDateTime(values.startDate));
      bodyFormData.append('end_date', newFormatDateTime(values.endDate));
      if (values.tradingStartDate && values.is_manual === '0')
        bodyFormData.append(
          'trading_start_date',
          newFormatDateTime(values.tradingStartDate)
        );
      if (values.tradingEndDate && values.is_manual === '0')
        bodyFormData.append(
          'trading_end_date',
          newFormatDateTime(values.tradingEndDate)
        );
      if (addEventFormik.values.selectedOption === 'leaderboard') {
        const parsedCoin = values.selectedCoin.map((coin) => ({
          coin: coin.coin,
          pairing: coin.parsing,
          multiple: coin.multiple,
        }));
        bodyFormData.append('coin', JSON.stringify(parsedCoin));
        bodyFormData.append('team', JSON.stringify(newTeams));
        bodyFormData.append(
          'participants',
          values.participan_UID.replace(/\ /g, '')
            ? values.participan_UID
                .replace(/\ /g, '')
                .split(',')
                .map((p) => Number(p))
            : []
        );
      }
      bodyFormData.append('button_homepage', JSON.stringify(values.addButton));
      bodyFormData.append('registration_url', values.registration_url);
      bodyFormData.append('event_type', values.event_type);
      bodyFormData.append('campaign_type', values.selectedOption);
      bodyFormData.append('homepage_list_status', values.selectedHomepage);
      bodyFormData.append('filtered_user', values.selected_participant);
      // bodyFormData.append('participant_id', values.participan_UID);
      bodyFormData.append('menu_dropdownlist_status', values.selectedDropdown);

      // Form Registration payload
      if (values.selectedOption === 'leaderboard') {
        bodyFormData.append('form_title_id', values.form_title_id);
        bodyFormData.append('form_title_en', values.form_title_en);
        bodyFormData.append('form_content_id', values.form_content_id);
        bodyFormData.append('form_content_en', values.form_content_en);
        bodyFormData.append(
          'form_start_date',
          values.form_start_date
            ? newFormatDateTime(values.form_start_date)
            : null
        );
        bodyFormData.append(
          'form_end_date',
          values.form_end_date ? newFormatDateTime(values.form_end_date) : null
        );
      }
      // end

      if (values.selectedOption === 'static_page') {
        bodyFormData.append('is_manual', '1');
      } else {
        bodyFormData.append('is_manual', values.is_manual);
      }
      if (values.banner !== null) {
        bodyFormData.append('banner', values.banner);
      }
      if (values.thumbnail !== null) {
        bodyFormData.append('thumbnail', values.thumbnail);
      }

      if (props.match.params?.id) {
        editEvent(bodyFormData);
      } else {
        addEvent(bodyFormData);
      }
    },
  });

  const handleDownloadCoinPair = async () => {
    try {
      const res = await downloadImportCsvCoin();
      let csvContent = atob(res.data.data);
      var blob = new Blob([csvContent], {
        type: 'data:application/octet-stream;base64',
      });
      var url = window.URL.createObjectURL(blob);
      // you need to precise a front-end button to have a name
      const dlnk = document.createElement('a');
      dlnk.href = url;
      dlnk.download = 'coins.csv';
      dlnk.click();
      document.body.removeChild(dlnk);
    } catch (error) {
      console.log(error, 'Error');
    }
  };
  const handleDownloadTemplateCoinPair = async () => {
    try {
      const res = await downloadTemplateCsvCoin();
      let csvContent = atob(res.data.data);
      var blob = new Blob([csvContent], {
        type: 'data:application/octet-stream;base64',
      });
      var url = window.URL.createObjectURL(blob);
      // you need to precise a front-end button to have a name
      const dlnk = document.createElement('a');
      dlnk.href = url;
      dlnk.download = 'coins-template.csv';
      dlnk.click();
      document.body.removeChild(dlnk);
    } catch (error) {
      console.log(error, 'Error');
    }
  };

  return (
    <div style={{ fontSize: '10px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  addEventFormik.handleSubmit(e);
                }}
                className='m-login__form m-form'
              >
                <Form.Group>
                  <Form.Label>Event Name</Form.Label>
                  <Form.Control
                    type='text'
                    name='eventName'
                    isInvalid={
                      addEventFormik.errors.eventName &&
                      addEventFormik.touched.eventName
                    }
                    placeholder='Enter Event Name'
                    value={addEventFormik.values.eventName}
                    onChange={onChangeSlug}
                    // required
                  />
                  {addEventFormik.errors.eventName &&
                    addEventFormik.touched.eventName && (
                      <Form.Text className='text-danger'>
                        {addEventFormik.errors.eventName}
                      </Form.Text>
                    )}
                </Form.Group>
                <div className='row'>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label className='d-block'>
                        Campaign Types :
                      </Form.Label>
                      <Form.Check
                        inline
                        label='Leaderboard'
                        type='radio'
                        value='leaderboard'
                        checked={
                          addEventFormik.values.selectedOption === 'leaderboard'
                        }
                        onChange={(e) => handleOptionChange(e)}
                        disabled={!!props.match.params?.id}
                      />
                      <Form.Check
                        inline
                        label='Static Page'
                        type='radio'
                        value='static_page'
                        checked={
                          addEventFormik.values.selectedOption === 'static_page'
                        }
                        onChange={(e) => handleOptionChange(e)}
                        disabled={!!props.match.params?.id}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label className='d-block'>
                        Show on the Homepage :
                      </Form.Label>
                      <Form.Check
                        inline
                        label='Yes'
                        type='radio'
                        value='1'
                        checked={addEventFormik.values.selectedHomepage === '1'}
                        onChange={(e) => handleOptionChangeHomepage(e)}
                      />
                      <Form.Check
                        inline
                        label='No'
                        type='radio'
                        value='0'
                        checked={addEventFormik.values.selectedHomepage === '0'}
                        onChange={(e) => handleOptionChangeHomepage(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label className='d-block'>
                        Show on the Drop Down Menu :
                      </Form.Label>
                      <Form.Check
                        inline
                        label='Yes'
                        type='radio'
                        value='1'
                        checked={addEventFormik.values.selectedDropdown === '1'}
                        onChange={(e) => handleOptionChangeDropdown(e)}
                      />
                      <Form.Check
                        inline
                        label='No'
                        type='radio'
                        value='0'
                        checked={addEventFormik.values.selectedDropdown === '0'}
                        onChange={(e) => handleOptionChangeDropdown(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Title (ID)</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Title ID'
                        name='title_id'
                        isInvalid={
                          addEventFormik.errors.title_id &&
                          addEventFormik.touched.title_id
                        }
                        value={addEventFormik.values.title_id}
                        onChange={addEventFormik.handleChange}
                      />
                      {addEventFormik.errors.title_id &&
                        addEventFormik.touched.title_id && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.title_id}
                          </Form.Text>
                        )}
                      {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text> */}
                    </Form.Group>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Title (EN)</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Title EN'
                        value={addEventFormik.values.title_en}
                        name='title_en'
                        isInvalid={
                          addEventFormik.errors.title_en &&
                          addEventFormik.touched.title_en
                        }
                        onChange={addEventFormik.handleChange}
                      />
                      {addEventFormik.errors.title_en &&
                        addEventFormik.touched.title_en && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.title_en}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Description (ID)</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        name='desc_id'
                        isInvalid={
                          addEventFormik.errors.desc_id &&
                          addEventFormik.touched.desc_id
                        }
                        value={addEventFormik.values.desc_id}
                        onChange={addEventFormik.handleChange}
                      />
                      {addEventFormik.errors.desc_id &&
                        addEventFormik.touched.desc_id && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.desc_id}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Description (EN)</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        name='desc_en'
                        isInvalid={
                          addEventFormik.errors.desc_en &&
                          addEventFormik.touched.desc_en
                        }
                        value={addEventFormik.values.desc_en}
                        onChange={addEventFormik.handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
                <FormWithFormik 
                  className="mt-3"
                  type="WYSIWYG"
                  config={addEventFormik}
                  name="content_id"
                  label="Content (ID)"
                />
                <FormWithFormik 
                  className="mt-3"
                  type="WYSIWYG"
                  config={addEventFormik}
                  name="content_en"
                  label="Content (EN)"
                />
                {addEventFormik.values.selectedOption === 'leaderboard' && (
                  <>
                    <Form.Group>
                      <Form.Label>Button Home Page Setup</Form.Label>
                      <div className='row'>
                        <div className='col-md-6 mb-2'>
                          <button
                            disabled={
                              addEventFormik.values.addButton.length > 1
                            }
                            type='button'
                            onClick={() => handleAddButton()}
                            className='btn btn-sm btn-outline-success'
                          >
                            Add Button
                          </button>
                        </div>
                        <div className='col-md-6 mb-2'>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <button disabled className='btn btn-register-home'>
                              Primary
                            </button>
                            <button disabled className='btn btn-event-home'>
                              Secondary
                            </button>
                            <button
                              disabled
                              className='btn btn-event-home-border-green'
                            >
                              Outline Primary
                            </button>
                            <button
                              disabled
                              className='btn btn-event-home-white'
                            >
                              Outline Secondary
                            </button>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          {addEventFormik.values.addButton.map((element, i) => {
                            return (
                              <>
                                <div className='row mt-2'>
                                  <div className='col-md-1'>
                                    <button
                                      type='button'
                                      onClick={() => handleRemoveButton(i)}
                                      className='btn btn-sm btn-outline-danger mt-1'
                                      disabled={i === 0 ? true : false}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                  <div className='col-md-2'>
                                    <input
                                      placeholder='Button Name ID'
                                      name='nameID'
                                      type='text'
                                      className='form-control'
                                      maxLength='20'
                                      value={element.nameID}
                                      disabled={i === 0 ? true : false}
                                      onChange={(e) => onChangeNameID(i, e)}
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <input
                                      placeholder='Button Name EN'
                                      name='nameEN'
                                      type='text'
                                      className='form-control'
                                      maxLength='20'
                                      value={element.nameEN}
                                      disabled={i === 0 ? true : false}
                                      onChange={(e) => onChangeNameEN(i, e)}
                                    />
                                  </div>
                                  <div className='col-md-3'>
                                    <select
                                      className='form-control'
                                      onChange={(e) =>
                                        onChangeClassButton(i, e)
                                      }
                                      disabled={i === 0 ? true : false}
                                    >
                                      <option
                                        selected={
                                          element.class === '' || !element.class
                                            ? true
                                            : false
                                        }
                                        disabled
                                      >
                                        Select Button Type
                                      </option>
                                      {classButton.map((e, index) => (
                                        <option
                                          key={index}
                                          value={e.id}
                                          selected={
                                            e.id === element.class
                                              ? true
                                              : false
                                          }
                                        >
                                          {e.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className='col-md-4'>
                                    <input
                                      placeholder='CTA Link, ex: https://www.domainname.com'
                                      name='url'
                                      type='text'
                                      className='form-control'
                                      value={element.url}
                                      disabled={i === 0 ? true : false}
                                      onChange={(e) => onChangeUrl(i, e)}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </Form.Group>
                  </>
                )}
                <div className='row'>
                  <div className='col-md-10 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Upload Banner</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={onChangeFile}
                          accept='image/*'
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Please using 1440 x 400 px for this image</span>
                      </Form.Text>
                      {addEventFormik.errors.banner &&
                        addEventFormik.touched.banner && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.banner}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <Form.Group>
                      <div style={{ marginTop: '2rem' }}>
                        {bannerEdit !== '' && (
                          <a
                            href={getBannerURL()}
                            target='_blank'
                            without
                            rel='noopener noreferrer'
                          >
                            <img
                              src={getBannerURL()}
                              alt='img'
                              className='img-bn'
                            />
                          </a>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-10 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Upload Banner Logo</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={onChangeFileThum}
                          accept='image/*'
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Please using 600 x 360 px for this image</span>
                        {/* {thumbnail === null ? <a href={urlAsset+thumbnailEdit} target='_blank' without rel="noopener noreferrer">{thumbnailEdit}</a> : ''} */}
                      </Form.Text>
                      {addEventFormik.errors.thumbnail &&
                        addEventFormik.touched.thumbnail && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.thumbnail}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <Form.Group>
                      <div style={{ marginTop: '2rem' }}>
                        {thumbnailEdit !== '' && (
                          <a
                            href={getThumbnailURL()}
                            target='_blank'
                            without
                            rel='noopener noreferrer'
                          >
                            <img
                              src={getThumbnailURL()}
                              alt='img'
                              className='img-bn'
                            />
                          </a>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {addEventFormik.values.selectedOption === 'leaderboard' && (
                  <div className='row'>
                    <div className='col-md-12'>
                      <Form.Group>
                        <Form.Label className='d-block'>Automation</Form.Label>
                        <Form.Check
                          inline
                          label='Okay'
                          type='radio'
                          value='0'
                          disabled={!!props.match.params?.id}
                          checked={addEventFormik.values.is_manual === '0'}
                          onChange={() =>
                            addEventFormik.setFieldValue('is_manual', '0')
                          }
                        />
                        <Form.Check
                          inline
                          label='No, keep Manual'
                          type='radio'
                          value='1'
                          disabled={!!props.match.params?.id}
                          checked={addEventFormik.values.is_manual === '1'}
                          onChange={() =>
                            addEventFormik.setFieldValue('is_manual', '1')
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='col-md-3 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <br />
                      <DatePicker
                        className={`form-control ${
                          addEventFormik.errors.startDate &&
                          addEventFormik.touched.startDate
                            ? 'is-invalid'
                            : ''
                        }`}
                        selected={addEventFormik.values.startDate}
                        onChange={(e) =>
                          addEventFormik.setFieldValue('startDate', e)
                        }
                        showTimeSelect
                        dateFormat='Pp'
                      />
                      {addEventFormik.errors.startDate &&
                        addEventFormik.touched.startDate && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.startDate}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <br />
                      <DatePicker
                        className={`form-control ${
                          addEventFormik.errors.endDate &&
                          addEventFormik.touched.endDate
                            ? 'is-invalid'
                            : ''
                        }`}
                        selected={addEventFormik.values.endDate}
                        // selected={new Date('')}
                        onChange={(e) => {
                          addEventFormik.setFieldValue('endDate', e);
                        }}
                        showTimeSelect
                        dateFormat='Pp'
                      />
                      {addEventFormik.errors.endDate &&
                        addEventFormik.touched.endDate && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.endDate}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                  {addEventFormik.values.selectedOption === 'leaderboard' &&
                    addEventFormik.values.is_manual === '0' && (
                      <Fragment>
                        <div className='col-md-3 col-sm-12'>
                          <Form.Group>
                            <Form.Label>Trading Start Date</Form.Label>
                            <br />
                            <DatePicker
                              className={`form-control ${
                                addEventFormik.errors.tradingStartDate &&
                                addEventFormik.touched.tradingStartDate
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              selected={addEventFormik.values.tradingStartDate}
                              onChange={(e) =>
                                addEventFormik.setFieldValue(
                                  'tradingStartDate',
                                  e
                                )
                              }
                              showTimeSelect
                              dateFormat='Pp'
                              disabled={!!props.match.params?.id}
                            />
                            {addEventFormik.touched.tradingStartDate &&
                              addEventFormik.errors.tradingStartDate && (
                                <Form.Text className='text-danger'>
                                  {addEventFormik.errors.tradingStartDate}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <Form.Group>
                            <Form.Label>Trading End Date</Form.Label>
                            <br />
                            <DatePicker
                              className={`form-control ${
                                addEventFormik.errors.tradingEndDate &&
                                addEventFormik.touched.tradingEndDate
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              selected={addEventFormik.values.tradingEndDate}
                              onChange={(e) =>
                                addEventFormik.setFieldValue(
                                  'tradingEndDate',
                                  e
                                )
                              }
                              showTimeSelect
                              dateFormat='Pp'
                              disabled={!!props.match.params?.id}
                            />
                            {addEventFormik.errors.tradingEndDate &&
                              addEventFormik.touched.tradingEndDate && (
                                <Form.Text className='text-danger'>
                                  {addEventFormik.errors.tradingEndDate}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </div>
                      </Fragment>
                    )}
                </div>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Slug</Form.Label>
                      <Form.Control
                        type='text'
                        name='slug'
                        placeholder='Enter Slug, example: (name-event)'
                        value={addEventFormik.values.slug}
                        onChange={addEventFormik.handleChange}
                        isInvalid={
                          addEventFormik.errors.slug &&
                          addEventFormik.touched.slug
                        }
                        // required`
                      />
                      {addEventFormik.errors.slug &&
                        addEventFormik.touched.slug && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.slug}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <Form.Group>
                      <Form.Label>Sorting Number</Form.Label>
                      <Form.Control
                        // required={true}
                        type='number'
                        min='0'
                        placeholder='0'
                        isInvalid={
                          addEventFormik.errors.position &&
                          addEventFormik.touched.position
                        }
                        name='position'
                        value={addEventFormik.values.position}
                        onChange={addEventFormik.handleChange}
                      />
                      {addEventFormik.errors.position &&
                        addEventFormik.touched.position && (
                          <Form.Text className='text-danger'>
                            {addEventFormik.errors.position}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </div>
                  {addEventFormik.values.selectedOption === 'leaderboard' && (
                    <>
                      <div className='col-md-3 col-sm-12'>
                        <div
                          className={`form-group ${
                            addEventFormik.errors.event_type &&
                            addEventFormik.touched.event_type
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <label className='form-label'>
                            Select Type Event
                          </label>
                          <div className='input-group'>
                            <select
                              className='form-control'
                              name='event_type'
                              onChange={addEventFormik.handleChange}
                              placeholder='Select Data'
                              disabled={!!props.match.params?.id}
                            >
                              <option
                                disabled
                                selected={
                                  addEventFormik.values.event_type === ''
                                    ? true
                                    : false
                                }
                              >
                                Select One
                              </option>
                              {listEventA.map((e) => (
                                <option
                                  value={e.id}
                                  selected={
                                    e.id === addEventFormik.values.event_type
                                      ? true
                                      : false
                                  }
                                  defaultValue={
                                    e.id === addEventFormik.values.event_type
                                      ? true
                                      : false
                                  }
                                >
                                  {e.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {addEventFormik.errors.event_type &&
                            addEventFormik.touched.event_type && (
                              <Form.Text className='text-danger'>
                                {addEventFormik.errors.event_type}
                              </Form.Text>
                            )}
                        </div>
                      </div>
                      {addEventFormik.values.event_type !== 'team' &&
                        addEventFormik.values.event_type !==
                          'individual-team' && (
                          <>
                            <div className='col-md-3'>
                              <Form.Group>
                                <Form.Label className='d-block'>
                                  User Participant for Individual :
                                </Form.Label>
                                <Form.Check
                                  inline
                                  label='Yes'
                                  type='radio'
                                  value='1'
                                  name='selected_participant'
                                  disabled={
                                    props.match.params?.id ? true : false
                                  }
                                  checked={
                                    addEventFormik.values
                                      .selected_participant === '1'
                                  }
                                  onChange={(e) =>
                                    addEventFormik.handleChange(e)
                                  }
                                />
                                <Form.Check
                                  inline
                                  label='No'
                                  type='radio'
                                  value='0'
                                  name='selected_participant'
                                  disabled={
                                    props.match.params?.id ? true : false
                                  }
                                  checked={
                                    addEventFormik.values
                                      .selected_participant === '0'
                                  }
                                  onChange={(e) =>
                                    addEventFormik.handleChange(e)
                                  }
                                />
                              </Form.Group>
                            </div>
                            {addEventFormik.values.selected_participant ===
                              '1' && (
                              <div className='col-md-12'>
                                <Form.Group>
                                  <Form.Label>Participants UID</Form.Label>
                                  {/* <Form.Control type="text" placeholder="111111, 222222, 333333, 444444, 555555, 666666" value={addEventFormik.values.participan_UID} onChange={(e) => setterParticipanUID(e.target.value)}/> */}
                                  <AsyncCreatableSelect
                                    isDisabled={!!props.match.params?.id}
                                    isMulti
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        border:
                                          addEventFormik.errors
                                            .participan_UID &&
                                          addEventFormik.touched.participan_UID
                                            ? '1px solid red'
                                            : '',
                                      }),
                                    }}
                                    loadOptions={loadOptions}
                                    defaultOptions
                                    onKeyDown={onKeyDownHandler}
                                    value={getParticipanUID()}
                                    onChange={(vals) =>
                                      setterParticipanUID(vals)
                                    }
                                  />
                                  {addEventFormik.errors.participan_UID &&
                                    addEventFormik.touched.participan_UID && (
                                      <Form.Text className='text-danger'>
                                        {addEventFormik.errors.participan_UID}
                                      </Form.Text>
                                    )}
                                </Form.Group>
                              </div>
                            )}
                          </>
                        )}
                    </>
                  )}
                </div>
                {addEventFormik.values.selectedOption === 'leaderboard' && (
                  <>
                    <Form.Group>
                      <Form.Label className='d-block'>Pairing Lists</Form.Label>
                      <Form.Check
                        inline
                        label='Import Pairs'
                        type='radio'
                        value='1'
                        name='pairing list'
                        disabled={props.match.params?.id ? true : false}
                        checked={pairingList === '1'}
                        onChange={(e) => {
                          setpairingList(e.target.value);
                          // if (addEventFormik.values.selectedCoin.length > 0) {
                          addEventFormik.setFieldValue('selectedCoin', []);
                          // }
                        }}
                      />
                      <Form.Check
                        inline
                        label='Add Individual Pair'
                        type='radio'
                        value='2'
                        name='pairing list'
                        disabled={props.match.params?.id ? true : false}
                        checked={pairingList === '2'}
                        onChange={(e) => {
                          setpairingList(e.target.value);
                          // if (addEventFormik.values.selectedCoin.length < 1) {
                          addEventFormik.setFieldValue('selectedCoin', [
                            {
                              coin: '',
                              multiple: '1',
                              parsing: '',
                            },
                          ]);
                          // }
                        }}
                      />
                      {pairingList === '1' ? (
                        <>
                          <div className='mt-3'>
                            <div className='d-flex justify-content-between align-content-center'>
                              <div className='flex-fill border mr-3 d-flex align-items-center p-3 '>
                                <input
                                  ref={coinFileRef}
                                  className=''
                                  onChange={handleChangeInputCoin}
                                  disabled={
                                    props.match.params?.id ? true : false
                                  }
                                  type='file'
                                  accept='.csv'
                                />
                              </div>
                              <div>
                                <button
                                  className='btn btn-primary mr-3 h-100'
                                  type='button'
                                  disabled={
                                    props.match.params?.id ? true : false
                                  }
                                  onClick={() =>
                                    addEventFormik.setFieldValue(
                                      'selectedCoin',
                                      []
                                    )
                                  }
                                >
                                  Clear All
                                </button>
                                <button
                                  disabled={
                                    csvCoinFile || !props.match.params?.id
                                      ? false
                                      : true
                                  }
                                  className='btn btn-primary h-100'
                                  type='button'
                                  onClick={importCoinCsvFile}
                                >
                                  Import Data
                                </button>
                              </div>
                            </div>
                            <div className='text-muted mt-4 font'>
                              <span className='d-block'>
                                *Allowed file type is .csv
                              </span>
                              <span className='d-block'>
                                * Examples of the import templates can be seen
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={handleDownloadTemplateCoinPair}
                                  className='text-info'
                                >
                                  {' '}
                                  here
                                </span>
                              </span>
                              <span className='d-block'>
                                * List of all coins and pairing can be
                                downloaded
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={handleDownloadCoinPair}
                                  className='text-info'
                                >
                                  {' '}
                                  here
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                              {addEventFormik.values.selectedCoin.map(
                                (element, i) => {
                                  return (
                                    <>
                                      <div className='row mt-2'>
                                        <div className='col-md-1'>
                                          {props.match.params?.id ? (
                                            <button
                                              type='button'
                                              disabled
                                              className='btn btn-sm btn-outline-secondary mt-1'
                                            >
                                              Remove
                                            </button>
                                          ) : (
                                            <button
                                              type='button'
                                              onClick={() => handleRemove(i)}
                                              className='btn btn-sm btn-outline-danger mt-1'
                                              disabled={
                                                addEventFormik.values
                                                  .selectedCoin.length <= 1
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Remove
                                            </button>
                                          )}
                                        </div>
                                        <div className='col-md-2'>
                                          <select
                                            className={`form-control ${
                                              addEventFormik.errors
                                                .selectedCoin &&
                                              addEventFormik.touched
                                                .selectedCoin &&
                                              addEventFormik.errors.selectedCoin
                                                .length > 0 &&
                                              addEventFormik.touched
                                                .selectedCoin.length > 0 &&
                                              addEventFormik.errors
                                                .selectedCoin[i] &&
                                              addEventFormik.errors
                                                .selectedCoin[i] &&
                                              addEventFormik.errors
                                                .selectedCoin[i].multiple &&
                                              addEventFormik.touched
                                                .selectedCoin[i].multiple
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            onChange={(e) =>
                                              onChangeListMultiple(i, e)
                                            }
                                            disabled={!!props.match.params?.id}
                                          >
                                            <option
                                              selected={
                                                element?.multiple === ''
                                                  ? true
                                                  : false
                                              }
                                              disabled
                                            >
                                              Multiplier
                                            </option>
                                            {listMultiplier.map((multiple) => (
                                              <option
                                                value={multiple.id}
                                                selected={
                                                  multiple.id ===
                                                  element?.multiple
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {multiple.name}
                                              </option>
                                            ))}
                                          </select>
                                          <div className='row'>
                                            <div className='col-12'>
                                              {addEventFormik.errors
                                                .selectedCoin &&
                                                addEventFormik.errors
                                                  .selectedCoin.length > 0 &&
                                                addEventFormik.touched
                                                  .selectedCoin &&
                                                addEventFormik.touched
                                                  .selectedCoin.length > 0 &&
                                                addEventFormik.touched
                                                  .selectedCoin[i] &&
                                                addEventFormik.touched
                                                  .selectedCoin[i].multiple && (
                                                  <Form.Text className='text-danger'>
                                                    {
                                                      addEventFormik.errors
                                                        .selectedCoin[i]
                                                        .multiple
                                                    }
                                                  </Form.Text>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='col-md-4'>
                                          <select
                                            className={`form-control ${
                                              addEventFormik.errors
                                                .selectedCoin &&
                                              addEventFormik.errors.selectedCoin
                                                .length > 0 &&
                                              addEventFormik.errors
                                                .selectedCoin[i] &&
                                              addEventFormik.errors
                                                .selectedCoin[i].coin &&
                                              addEventFormik.touched
                                                .selectedCoin &&
                                              addEventFormik.touched
                                                .selectedCoin.length > 0 &&
                                              addEventFormik.touched
                                                .selectedCoin[i].coin
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            onChange={(e) =>
                                              onChangeListCoin(i, e)
                                            }
                                            disabled={!!props.match.params?.id}
                                          >
                                            <option
                                              selected={
                                                element?.coin === ''
                                                  ? true
                                                  : false
                                              }
                                              disabled
                                            >
                                              Select Coin{' '}
                                            </option>
                                            {listCoin.map((coin) => (
                                              <option
                                                value={coin}
                                                selected={
                                                  coin === element?.coin
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {coin}
                                              </option>
                                            ))}
                                          </select>
                                          <div className='row'>
                                            <div className='col-12'>
                                              {addEventFormik.errors
                                                .selectedCoin &&
                                                addEventFormik.errors
                                                  .selectedCoin.length > 0 &&
                                                addEventFormik.touched
                                                  .selectedCoin &&
                                                addEventFormik.touched
                                                  .selectedCoin.length > 0 &&
                                                addEventFormik.touched
                                                  .selectedCoin[i] &&
                                                addEventFormik.touched
                                                  .selectedCoin[i].coin && (
                                                  <Form.Text className='text-danger'>
                                                    {
                                                      addEventFormik.errors
                                                        .selectedCoin[i].coin
                                                    }
                                                  </Form.Text>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='col-md-5'>
                                          <>
                                            {element.coin && (
                                              <>
                                                {listCoinJson[element.coin] && (
                                                  <select
                                                    className={`form-control ${
                                                      addEventFormik.errors
                                                        .selectedCoin &&
                                                      addEventFormik.errors
                                                        .selectedCoin.length >
                                                        0 &&
                                                      addEventFormik.errors
                                                        .selectedCoin[i] &&
                                                      addEventFormik.errors
                                                        .selectedCoin[i]
                                                        .parsing &&
                                                      addEventFormik.touched
                                                        .selectedCoin &&
                                                      addEventFormik.touched
                                                        .selectedCoin.length >
                                                        0 &&
                                                      addEventFormik.touched
                                                        .selectedCoin[i].parsing
                                                        ? 'is-invalid'
                                                        : ''
                                                    }`}
                                                    onChange={(e) =>
                                                      onChangeListPair(i, e)
                                                    }
                                                    disabled={
                                                      !!props.match.params?.id
                                                    }
                                                  >
                                                    <option
                                                      selected={
                                                        element?.parsing === ''
                                                          ? true
                                                          : false
                                                      }
                                                      disabled
                                                    >
                                                      Select Pairing
                                                    </option>
                                                    {listCoinJson[
                                                      element.coin
                                                    ].map((pair) => (
                                                      <option
                                                        selected={
                                                          element?.parsing ===
                                                          pair.parsing
                                                            ? true
                                                            : false
                                                        }
                                                        value={pair.parsing}
                                                        disabled={
                                                          disabledCoin[
                                                            element.coin
                                                          ][pair.parsing]
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        {pair.parsing}
                                                      </option>
                                                    ))}
                                                  </select>
                                                )}
                                              </>
                                            )}
                                            {element.coin && (
                                              <div className='row'>
                                                <div className='col-12'>
                                                  {addEventFormik.errors
                                                    .selectedCoin &&
                                                    addEventFormik.errors
                                                      .selectedCoin.length >
                                                      0 &&
                                                    addEventFormik.touched
                                                      .selectedCoin &&
                                                    addEventFormik.touched
                                                      .selectedCoin.length >
                                                      0 &&
                                                    addEventFormik.touched
                                                      .selectedCoin[i] &&
                                                    addEventFormik.touched
                                                      .selectedCoin[i]
                                                      .parsing && (
                                                      <Form.Text className='text-danger'>
                                                        {
                                                          addEventFormik.errors
                                                            .selectedCoin[i]
                                                            .parsing
                                                        }
                                                      </Form.Text>
                                                    )}
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='row mt-3'>
                          <div className='col-md-12 mb-2'>
                            {props.match.params?.id ? (
                              <button
                                type='button'
                                disabled
                                className='btn btn-sm btn-outline-secondary'
                              >
                                Add Coin
                              </button>
                            ) : (
                              <button
                                type='button'
                                onClick={() => handleAdd()}
                                className='btn btn-sm btn-outline-success'
                              >
                                Add Coin
                              </button>
                            )}
                          </div>
                          <div className='col-md-12'>
                            {addEventFormik.values.selectedCoin.map(
                              (element, i) => {
                                return (
                                  <>
                                    <div className='row mt-2'>
                                      <div className='col-md-1'>
                                        {props.match.params?.id ? (
                                          <button
                                            type='button'
                                            disabled
                                            className='btn btn-sm btn-outline-secondary mt-1'
                                          >
                                            Remove
                                          </button>
                                        ) : (
                                          <button
                                            type='button'
                                            onClick={() => handleRemove(i)}
                                            className='btn btn-sm btn-outline-danger mt-1'
                                            disabled={
                                              addEventFormik.values.selectedCoin
                                                .length === 1
                                                ? true
                                                : false
                                            }
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </div>
                                      <div className='col-md-2'>
                                        <select
                                          className={`form-control ${
                                            addEventFormik.errors
                                              .selectedCoin &&
                                            addEventFormik.touched
                                              .selectedCoin &&
                                            addEventFormik.errors.selectedCoin
                                              .length > 0 &&
                                            addEventFormik.touched.selectedCoin
                                              .length > 0 &&
                                            addEventFormik.touched.selectedCoin[
                                              i
                                            ] &&
                                            addEventFormik.errors.selectedCoin[
                                              i
                                            ] &&
                                            addEventFormik.errors.selectedCoin[
                                              i
                                            ].multiple &&
                                            addEventFormik.touched.selectedCoin[
                                              i
                                            ].multiple
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          onChange={(e) =>
                                            onChangeListMultiple(i, e)
                                          }
                                          disabled={!!props.match.params?.id}
                                        >
                                          <option
                                            selected={
                                              element?.multiple === ''
                                                ? true
                                                : false
                                            }
                                            disabled
                                          >
                                            Multiplier
                                          </option>
                                          {listMultiplier.map((multiple) => (
                                            <option
                                              value={multiple.id}
                                              selected={
                                                multiple.id ===
                                                element?.multiple
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {multiple.name}
                                            </option>
                                          ))}
                                        </select>
                                        <div className='row'>
                                          <div className='col-12'>
                                            {addEventFormik.errors
                                              .selectedCoin &&
                                              addEventFormik.errors.selectedCoin
                                                .length > 0 &&
                                              addEventFormik.touched
                                                .selectedCoin &&
                                              addEventFormik.errors
                                                .selectedCoin[i] &&
                                              addEventFormik.touched
                                                .selectedCoin.length > 0 &&
                                              addEventFormik.touched
                                                .selectedCoin[i] &&
                                              addEventFormik.touched
                                                .selectedCoin[i].multiple && (
                                                <Form.Text className='text-danger'>
                                                  {
                                                    addEventFormik.errors
                                                      .selectedCoin[i].multiple
                                                  }
                                                </Form.Text>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-md-4'>
                                        <select
                                          className={`form-control ${
                                            addEventFormik.errors
                                              .selectedCoin &&
                                            addEventFormik.errors.selectedCoin
                                              .length > 0 &&
                                            addEventFormik.errors.selectedCoin[
                                              i
                                            ] &&
                                            addEventFormik.errors.selectedCoin[
                                              i
                                            ].coin &&
                                            addEventFormik.touched
                                              .selectedCoin &&
                                            addEventFormik.touched.selectedCoin[
                                              i
                                            ] &&
                                            addEventFormik.touched.selectedCoin
                                              .length > 0 &&
                                            addEventFormik.touched.selectedCoin[
                                              i
                                            ].coin
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          onChange={(e) =>
                                            onChangeListCoin(i, e)
                                          }
                                          disabled={!!props.match.params?.id}
                                        >
                                          <option
                                            selected={
                                              element?.coin === ''
                                                ? true
                                                : false
                                            }
                                            disabled
                                          >
                                            Select Coin{' '}
                                          </option>
                                          {listCoin.map((coin) => (
                                            <option
                                              value={coin}
                                              selected={
                                                coin === element?.coin
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {coin}
                                            </option>
                                          ))}
                                        </select>
                                        <div className='row'>
                                          <div className='col-12'>
                                            {addEventFormik.errors
                                              .selectedCoin &&
                                              addEventFormik.errors.selectedCoin
                                                .length > 0 &&
                                              addEventFormik.errors
                                                .selectedCoin[i] &&
                                              addEventFormik.touched
                                                .selectedCoin &&
                                              addEventFormik.touched
                                                .selectedCoin.length > 0 &&
                                              addEventFormik.touched
                                                .selectedCoin[i] &&
                                              addEventFormik.touched
                                                .selectedCoin[i].coin && (
                                                <Form.Text className='text-danger'>
                                                  {
                                                    addEventFormik.errors
                                                      .selectedCoin[i].coin
                                                  }
                                                </Form.Text>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-md-5'>
                                        <>
                                          {element.coin && (
                                            <>
                                              {listCoinJson[element.coin] && (
                                                <select
                                                  className={`form-control ${
                                                    addEventFormik.errors
                                                      .selectedCoin &&
                                                    addEventFormik.errors
                                                      .selectedCoin.length >
                                                      0 &&
                                                    addEventFormik.errors
                                                      .selectedCoin[i] &&
                                                    addEventFormik.errors
                                                      .selectedCoin[i]
                                                      .parsing &&
                                                    addEventFormik.touched
                                                      .selectedCoin &&
                                                    addEventFormik.touched
                                                      .selectedCoin[i] &&
                                                    addEventFormik.touched
                                                      .selectedCoin.length >
                                                      0 &&
                                                    addEventFormik.touched
                                                      .selectedCoin[i].parsing
                                                      ? 'is-invalid'
                                                      : ''
                                                  }`}
                                                  onChange={(e) =>
                                                    onChangeListPair(i, e)
                                                  }
                                                  disabled={
                                                    !!props.match.params?.id
                                                  }
                                                >
                                                  <option
                                                    selected={
                                                      element?.parsing === ''
                                                        ? true
                                                        : false
                                                    }
                                                    // disabled
                                                  >
                                                    Select Pairing
                                                  </option>
                                                  {listCoinJson[
                                                    element.coin
                                                  ].map((pair) => (
                                                    <option
                                                      selected={
                                                        element?.parsing ===
                                                        pair.parsing
                                                          ? true
                                                          : false
                                                      }
                                                      value={pair.parsing}
                                                      disabled={
                                                        disabledCoin[
                                                          element.coin
                                                        ][pair.parsing]
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      {pair.parsing}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}
                                          {element.coin && (
                                            <div className='row'>
                                              <div className='col-12'>
                                                {addEventFormik.errors
                                                  .selectedCoin &&
                                                  addEventFormik.errors
                                                    .selectedCoin.length > 0 &&
                                                  addEventFormik.touched
                                                    .selectedCoin &&
                                                  addEventFormik.errors
                                                    .selectedCoin[i] &&
                                                  addEventFormik.touched
                                                    .selectedCoin.length > 0 &&
                                                  addEventFormik.touched
                                                    .selectedCoin[i] &&
                                                  addEventFormik.touched
                                                    .selectedCoin[i]
                                                    .parsing && (
                                                    <Form.Text className='text-danger'>
                                                      {
                                                        addEventFormik.errors
                                                          .selectedCoin[i]
                                                          .parsing
                                                      }
                                                    </Form.Text>
                                                  )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                      {addEventFormik.touched.selectedCoin &&
                        addEventFormik.errors.selectedCoin && (
                          <p className='text-danger'>
                            {typeof addEventFormik.errors.selectedCoin ===
                              'string' && addEventFormik.errors.selectedCoin}
                          </p>
                        )}
                    </Form.Group>
                    {addEventFormik.values.event_type === 'team' ||
                    addEventFormik.values.event_type === 'individual-team' ? (
                      <Form.Group>
                        <Form.Label>Team</Form.Label>
                        <div className='row'>
                          <div className='col-md-12 mb-2'>
                            <button
                              type='button'
                              onClick={() => handleAddTeam()}
                              disabled={props.match.params?.id}
                              className={`btn btn-sm ${
                                props.match.params?.id
                                  ? 'btn-outline-secondary'
                                  : 'btn-outline-success'
                              }`}
                            >
                              Add Team
                            </button>
                          </div>
                          <div className='col-md-12'>
                            {addEventFormik.values.inputedTeam &&
                              addEventFormik.values.inputedTeam.length > 0 &&
                              addEventFormik.values.inputedTeam.map(
                                (element, i) => {
                                  return (
                                    <>
                                      <div className='row mt-2'>
                                        <div className='col-md-1'>
                                          <button
                                            type='button'
                                            onClick={() => handleRemoveTeam(i)}
                                            className={`btn btn-sm ${
                                              props.match.params?.id
                                                ? 'btn-outline-secondary'
                                                : 'btn-outline-danger'
                                            } mt-1`}
                                            disabled={
                                              addEventFormik.values.inputedTeam
                                                .length <= 1 ||
                                              props.match.params?.id
                                            }
                                          >
                                            Remove
                                          </button>
                                        </div>
                                        <div className='col-md-3'>
                                          {/* <input placeholder="UUID exp: 122456, 123221, 178092" name="participant" type="text" className="form-control" value={element.participant} onChange={(e) => onChangeUUIDTeam(i, e)} /> */}
                                          <AsyncCreatableSelect
                                            isDisabled={props.match.params?.id}
                                            isMulti
                                            loadOptions={loadOptions}
                                            defaultOptions
                                            value={getUUIDTeamValue(element)}
                                            onChange={(vals) =>
                                              onChangeUUIDTeamNew(i, vals)
                                            }
                                            onKeyDown={onKeyDownHandler}
                                          />
                                        </div>
                                        <div className='col-md-3'>
                                          <input
                                            placeholder='Team Name'
                                            name='name'
                                            type='text'
                                            className={`form-control ${
                                              addEventFormik.errors
                                                .inputedTeam &&
                                              addEventFormik.errors.inputedTeam
                                                .length > 0 &&
                                              addEventFormik.errors.inputedTeam[
                                                i
                                              ] &&
                                              addEventFormik.errors.inputedTeam[
                                                i
                                              ].name &&
                                              addEventFormik.touched
                                                .inputedTeam &&
                                              addEventFormik.touched.inputedTeam
                                                .length > 0 &&
                                              addEventFormik.touched
                                                .inputedTeam[i].name
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            disabled={props.match.params?.id}
                                            value={element.name}
                                            onChange={(e) =>
                                              onChangeNameTeam(i, e)
                                            }
                                          />
                                          <div className='row'>
                                            <div className='col-12'>
                                              {addEventFormik.errors
                                                .inputedTeam &&
                                                addEventFormik.errors
                                                  .inputedTeam.length > 0 &&
                                                addEventFormik.touched
                                                  .inputedTeam &&
                                                addEventFormik.touched
                                                  .inputedTeam.length > 0 &&
                                                addEventFormik.touched
                                                  .inputedTeam[i] &&
                                                addEventFormik.touched
                                                  .inputedTeam[i].name && (
                                                  <Form.Text className='text-danger'>
                                                    {
                                                      addEventFormik.errors
                                                        .inputedTeam[i].name
                                                    }
                                                  </Form.Text>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='col-md-4'>
                                          <Form.Control
                                            className='form-control'
                                            type='file'
                                            disabled={props.match.params?.id}
                                            onChange={(e) =>
                                              onChangeFileTeam(i, e)
                                            }
                                            accept='image/*'
                                          />
                                          <Form.Text className='text-muted'>
                                            <span>
                                              *Please using 48 x 48 px for this
                                              image
                                            </span>
                                            <br />
                                            {/* {element.logo === '' ? <a href={urlAsset+element.logo_url} target='_blank' without rel="noopener noreferrer">{element.logo_url}</a> : ''} */}
                                          </Form.Text>
                                        </div>
                                        <div className='col-md-1'>
                                          {element.logo_url !== '' && (
                                            <a
                                              href={getLogoURL(
                                                element.logo_url
                                              )}
                                              target='_blank'
                                              without
                                              rel='noopener noreferrer'
                                            >
                                              <img
                                                src={getLogoURL(
                                                  element.logo_url
                                                )}
                                                alt='img'
                                                className='img-th'
                                              />
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </Form.Group>
                    ) : null}
                  </>
                )}
                {addEventFormik.values.selectedOption === 'leaderboard' &&
                  addEventFormik.values.is_manual !== '1' &&
                  (addEventFormik.values.event_type !== 'individual'
                    ? true
                    : addEventFormik.values.selected_participant === '1') && (
                    <div className='border border-2 rounded p-4'>
                      <h3>Form Registration</h3>
                      <br />
                      <br />
                      <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                          <Form.Group>
                            <Form.Label>Form Title (ID)</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Title ID'
                              name='form_title_id'
                              isInvalid={
                                addEventFormik.errors.form_title_id &&
                                addEventFormik.touched.form_title_id
                              }
                              value={addEventFormik.values.form_title_id}
                              onChange={addEventFormik.handleChange}
                            />
                            {addEventFormik.errors.form_title_id &&
                              addEventFormik.touched.form_title_id && (
                                <Form.Text className='text-danger'>
                                  {addEventFormik.errors.form_title_id}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                          <Form.Group>
                            <Form.Label>Form Title (EN)</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Title EN'
                              name='form_title_en'
                              isInvalid={
                                addEventFormik.errors.form_title_en &&
                                addEventFormik.touched.form_title_en
                              }
                              value={addEventFormik.values.form_title_en}
                              onChange={addEventFormik.handleChange}
                            />
                            {addEventFormik.errors.form_title_en &&
                              addEventFormik.touched.form_title_en && (
                                <Form.Text className='text-danger'>
                                  {addEventFormik.errors.form_title_en}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </div>
                      </div>
                      <FormWithFormik 
                        className="mt-3"
                        type="WYSIWYG"
                        config={addEventFormik}
                        name="form_content_id"
                        label="Form Content (ID)"
                      />
                      <FormWithFormik 
                        className="mt-3"
                        type="WYSIWYG"
                        config={addEventFormik}
                        name="form_content_en"
                        label="Form Content (EN)"
                      />
                      <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                          <Form.Group>
                            <Form.Label>Form Start Date</Form.Label>
                            <br />
                            <DatePicker
                              className={`form-control ${
                                addEventFormik.errors.form_start_date &&
                                addEventFormik.touched.form_start_date
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              selected={addEventFormik.values.form_start_date}
                              onChange={(val) =>
                                addEventFormik.setFieldValue(
                                  'form_start_date',
                                  val
                                )
                              }
                              showTimeSelect
                              dateFormat='Pp'
                            />
                            {addEventFormik.errors.form_start_date &&
                              addEventFormik.touched.form_start_date && (
                                <Form.Text className='text-danger'>
                                  {addEventFormik.errors.form_start_date}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                          <Form.Group>
                            <Form.Label>Form End Date</Form.Label>
                            <br />
                            <DatePicker
                              className={`form-control ${
                                addEventFormik.errors.form_end_date &&
                                addEventFormik.touched.form_end_date
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              selected={addEventFormik.values.form_end_date}
                              onChange={(val) =>
                                addEventFormik.setFieldValue(
                                  'form_end_date',
                                  val
                                )
                              }
                              showTimeSelect
                              dateFormat='Pp'
                            />
                            {addEventFormik.errors.form_end_date &&
                              addEventFormik.touched.form_end_date && (
                                <Form.Text className='text-danger'>
                                  {addEventFormik.errors.form_end_date}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  )}
                <br />
                <br />
                <div
                  className='kt-login__actions'
                  style={{ justifyContent: 'center', display: 'flex' }}
                >
                  <button
                    type='submit'
                    // onClick={() => isSubmit()}
                    disabled={loading === true ? true : false}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                      }
                    )}`}
                  >
                    {props.match.params?.id ? 'Edit Event' : 'Add Event'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
