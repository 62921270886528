import React, { Component, useRef, useState } from 'react';
import Form from './Form';

const AddCourse = (props) => {
  const [isEdit, setisEdit] = useState(false);
  const formRef = useRef(null);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='d-flex justify-content-between align-content-center'>
              <div className=''>
                <p className='d-inline-block font-weight-bold'>
                  Create New Course
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <Form submitRef={formRef} isEdit={isEdit} />
            <div className='flex-fill mt-5 d-flex justify-content-end align-items-center'>
              <button
                class='btn btn-outline-warning mr-2'
                type='submit'
                onClick={() => formRef.current()}
              >
                Submit
              </button>
              <button
                class='btn btn-outline-danger '
                type='submit'
                onClick={() => props.history.push('/tokoscholar/courses')}
              >
                Back To Course List
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;
