import React from "react";
import actions from "../../crud/microsite";
import { toast } from 'react-toastify';
import { Form } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css'; 
import { createBrowserHistory } from 'history';
// import FileUpload from '../../components/upload/fileUpload';
import _ from 'lodash';
import clsx from "clsx";

const history =  createBrowserHistory();

class AddMorningInsight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalData: 0,
      titleId: '',
      titleEn: '',
      contentId: '',
      contentEn: '',
      listImage: [],
      params: '?',
      addThumbnail: [],
      addUrlThumbnail: [],
      image: {},
      type: 'create',
      checked: false,
      sourceLink: ''
    }
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  componentDidMount(){
    // console.log('component yey');
  }

  onChangeFileImage = (e) => {
    let file = e.target.files[0];
    if(file){
      this.setState({
        image: file
      })
    }
  }

  cloneState() {
      return _.clone(this.state);
  }

  onChangeState(val, field) {
      let state = this.cloneState();
      state[field] = val;
      this.setState(state);
  }

  handleAddThumbnail(){
    const _fields = [...this.state.addThumbnail];
    _fields.push({});
    this.setState({
      addThumbnail: _fields,
    });
  }
  handleRemoveThumbnail(i){
    const _fields = [...this.state.addThumbnail];
    _fields.splice(i, 1);
    this.setState({
      addThumbnail: _fields,
    });
  }
  onChangeThumbnail(i, e){
    const self = this;
    let file = e.target.files[0];
    const _pair = [...self.state.addThumbnail];
    if(file){
      _pair[i] = file;
      this.setState({
        addThumbnail: _pair
      })
    }
  }

  // handleAddUrlThumbnail(){
  //   const _fields = [...this.state.addUrlThumbnail];
  //   _fields.push({});
  //   this.setState({
  //     addUrlThumbnail: _fields,
  //   });
  // }
  // handleRemoveUrlThumbnail(i){
  //   const _fields = [...this.state.addUrlThumbnail];
  //   _fields.splice(i, 1);
  //   this.setState({
  //     addUrlThumbnail: _fields,
  //   });
  // }
  // onChangeUrlThumbnail(i, e){
  //   const self = this;
  //   const _pair = [...self.state.addUrlThumbnail];
  //   _pair[i] = e.target.value;
  //   this.setState({
  //     addUrlThumbnail: _pair,
  //   })
  // }

  isSubmit = event => {
    let imageFile = this.state.addThumbnail
    // let imageUrl = this.state.addUrlThumbnail
    let stat = ''
    if(this.state.checked){
      stat = '1'
    }else{
      stat = '0'
    }
    event.preventDefault();
      var bodyFormData = new FormData();
      bodyFormData.append('title', this.state.titleId); 
      bodyFormData.append('desc', this.state.contentId); 
      bodyFormData.append('status', stat);
      imageFile.forEach(element => {
        bodyFormData.append('files', element);
      });
      // imageUrl.forEach(element => {
      //   bodyFormData.append('target_link', element);
      // });
      // bodyFormData.append('target_link', JSON.stringify(this.state.addUrlThumbnail));
      // bodyFormData.append('source_link', this.state.sourceLink);
      this.submitData(bodyFormData);
  }

  async submitData(bodyFormData){
    this.setState({
      loading: true
    })

    actions.postData(this.getToken(), 'insight', bodyFormData)
    .then(res => {
      if(res.data.code === 200){
        toast.success("Add new event is success");
      }
      this.setState({
        loading: false,
      })
      setTimeout(() => {
        history.push("/btc-microsite/morning-insight");
        window.location.reload();
      }, 3000);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  makeBase64 = (file) => {
    // let self = this;
    console.log(file);
    let blob = file.file;
    // let type = file.type;
    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
        // console.log(reader.result);
        // self.setState({
        //   file_extension: type,
        //   file_content: reader.result.split('base64,')[1],
        // })
    }
  }

  onChangeChecked = newValue => {
    this.setState({ checked: newValue });
  }

  render(){
    let totalDesc = 255 - this.state.contentId.length;
    return (
      <div className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
              <hr />
                {this.state.loading ? (
                  <div className="text-center">
                      Loading ...
                  </div>
                ):(
                  <>
                    <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                      <Form.Group>
                        <Form.Label>Title (ID)</Form.Label>
                        {/* <Form.Control type="text" placeholder="Enter Title ID" value={this.state.titleId} onChange={(e) => this.onChangeState(e, 'titleId')} required/> */}
                        <Form.Control type="text" placeholder="Enter Title ID" value={this.state.titleId} onChange={(e) => this.onChangeState(e.target.value, 'titleId')} required/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Content Description (ID)</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Content Description ID"
                          value={this.state.contentId}
                          onChange={(e) => this.onChangeState(e.target.value, 'contentId')}
                          maxLength="255"
                          required
                        />
                          <Form.Text className="text-muted desc-length-position">
                            <span>Maximum Characters : {totalDesc}</span>
                          </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Check 
                          type="switch"
                          id="custom-switch"
                          label="Select Publish or Unpublish Article"
                          checked={this.state.checked}
                          onChange={e => this.onChangeChecked(e.target.checked)}
                        />
                      </Form.Group>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group>
                            <Form.Label>Button Home Page Setup</Form.Label>
                            <div className="row">
                              <div className="col-md-12 mb-2">
                                <button
                                  type="button"
                                  onClick={() => this.handleAddThumbnail()}
                                  className="btn btn-sm btn-outline-success">
                                    Add Image Thumbnail
                                </button>
                              </div>
                              <div className="col-md-12">
                                {this.state.addThumbnail.map((element, i) => {
                                    return (
                                      <div className="row mt-2" key={i}>
                                        <div className="col-md-1">
                                          <button type="button" onClick={() => this.handleRemoveThumbnail(i)} className="btn btn-sm btn-outline-danger mt-1">Remove</button>     
                                        </div>
                                        <div className="col-md-11">
                                          <Form.Control className="form-control" type="file" onChange={(e) => this.onChangeThumbnail(i, e)} accept="image/*" />
                                          <Form.Text className="text-muted">
                                              {/* <span>*Please using 48 x 48 px for this image</span> <br /> */}
                                          </Form.Text>
                                        </div>
                                      </div>
                                    );
                                })}
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        {/* <div className="col-md-6">
                          <Form.Group>
                            <Form.Label>Button Home Page Setup</Form.Label>
                            <div className="row">
                              <div className="col-md-12 mb-2">
                                <button
                                  type="button"
                                  onClick={() => this.handleAddUrlThumbnail()}
                                  className="btn btn-sm btn-outline-success">
                                    Add URL Thumbnail
                                </button>
                              </div>
                              <div className="col-md-12">
                                {this.state.addUrlThumbnail.map((element, i) => {
                                    return (
                                      <>
                                        <div className="row mt-2" key={i}>
                                          <div className="col-md-2">
                                            <button type="button" onClick={() => this.handleRemoveUrlThumbnail(i)} className="btn btn-sm btn-outline-danger mt-1" disabled={i === 0 ? true : false}>Remove</button>     
                                          </div>
                                          <div className="col-md-10">
                                            <Form.Control className="form-control" type="text" onChange={(e) => this.onChangeUrlThumbnail(i, e)} />
                                          </div>
                                        </div>
                                      </>
                                    );
                                })}
                              </div>
                            </div>
                          </Form.Group>
                        </div> */}
                      </div>
                      {/* <Form.Group>
                        <Form.Label>Content Description (EN)</Form.Label>
                        <FileUpload makeBase64={(val) => this.makeBase64(val)} filePreview={this.state.listImage.length > 0 ? this.state.listImage : []} />
                      </Form.Group> */}
                      <div className="d-flex justify-content-end align-items-center">
                        <button
                            type="submit"
                            disabled={this.state.loading}
                            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                              {
                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                              }
                            )}`}
                          >
                          Create Content
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddMorningInsight;