import { useFormik } from "formik";
import { FC, forwardRef, HTMLAttributes, ReactNode, useReducer, useEffect } from "react";
import FormWithFormik, { FileUpload } from "../../components/reusableFormWithFormik";
import { globalDOBTime, globalDateTime, useDebounce } from "../../helper";
import Pagination from 'react-js-pagination';
import "./_sipendar.scss"
import { deleteSipendarDatabase, getSipendarData, updateSipendarDatabase, uploadTerroristData } from "../../crud/sipendar";
import { Sipendar, SipendarSource } from "../../crud/sipendar-types";
import { MimeTypes, PaginationRequest, PaginationResponse } from "../../crud/global-types";
import ReusableTable from "../../components/reusableTable";
import { RouteComponentProps } from "react-router";
import Swal from "sweetalert2";
import axios, { AxiosError } from "axios";
import { Modal } from "react-bootstrap";
import * as yup from "yup";

interface IFilterForm {
  keyword: string;
  date?: Date;
  file?: FileUpload;
}

interface InitialState extends PaginationRequest, PaginationResponse<Sipendar> {
  isUpdateFormId?: string;
}

const initialState: InitialState = {
  page: 1,
  limit: 10,
  data: [],
  current_page: 0,
  per_page: 0,
  total_data: 0,
  total_page: 0,
}

interface IUpdateForm {
  name: string;
  date_of_birth?: Date;
}

const SipendarComponent: FC<RouteComponentProps> = (props) => {
  const updateFormik = useFormik<IUpdateForm>({
    initialValues: {
      name: ""
    },
    onSubmit: async (values, helper) => {
      try {
        if(state.isUpdateFormId && values.date_of_birth) {
          await updateSipendarDatabase(state.isUpdateFormId, {
            name: values.name,
            date_of_birth: values.date_of_birth.toISOString()
          })

          getSipendarService(state.page, isSipendarPage, state.q)

          Swal.fire("Successfully Updated!","done!","success")

          dispatch({ isUpdateFormId: undefined })

          helper.resetForm()
        }
      }
      catch(err) {
        if(err instanceof axios.AxiosError) Swal.fire("Failed to remove data!",err.response?.data?.message, "error")
      }
    },
    validationSchema: yup.object({
      name: yup.string().required('Name must be fill!'),
      date_of_birth: yup.date().required('Date of Birth must be fill!')
    })
  })
  const isSipendarPage = String(props.location.pathname).includes("sipendar")
  const [state, dispatch] = useReducer((s: InitialState, ns: Partial<InitialState>) => ({...s, ...ns}), initialState)
  const filterFormik = useFormik<IFilterForm>({
    initialValues: {
      keyword: ""
    },
    onSubmit: () => {}
  })

  useEffect(()=>{
    dispatch({page: 1})
  }, [isSipendarPage])

  const getSipendarService = (page: number, sipendarPage: boolean, q?: string) => {
    getSipendarData({q, limit: state.limit, page, source: sipendarPage ? SipendarSource.SIPENDAR : SipendarSource.TERRORIST})
      .then(res => {
        dispatch({
          data: res.data.data?.data,
          total_data: res.data.data?.total_data,
          total_page: res.data.data?.total_page
        })
      })
      .catch(err => console.log(err))
  }

  useDebounce<[number, boolean, string | undefined]>(getSipendarService, [state.page, isSipendarPage, state.q], 400)

  useEffect(()=>{
    const file = filterFormik.values.file?.originFileObjs ? filterFormik.values.file?.originFileObjs[0] : undefined
    if(file) {
      uploadTerroristData({
        file,
        source: isSipendarPage ? SipendarSource.SIPENDAR : SipendarSource.TERRORIST
      })
        .then((res) => {
          Swal.fire("Successfully Uploading Data!", "Upload data is success!", "success")

          getSipendarService(state.page, isSipendarPage, state.q)

          filterFormik.setFieldValue("file", undefined)
        })
        .catch(err => console.log(err))
    }
  }, [filterFormik.values.file])

  const deleteData = async (data: Sipendar) => {
    try {
      await deleteSipendarDatabase(data.id.toString())

      getSipendarService(state.page, isSipendarPage, state.q)

      Swal.fire("Successfully removing data!","done!", "success")
    }
    catch(err) {
      if(err instanceof axios.AxiosError) Swal.fire("Failed to remove data!",err.response?.data?.message, "error")
    }
  }

  const updateData = (data: Sipendar) => {
    dispatch({ isUpdateFormId: data.id.toString() })

    updateFormik.setFieldValue("name", data.name)
    updateFormik.setFieldValue("date_of_birth", new Date(data.date_of_birth))
  }

  return (
    <div className="wrapper">
      <div className="custom-flex-row-space-between">
        <div className="custom-flex-col-space-between gap-4">
          <div>
            <div className="title">{isSipendarPage ? 'Sipendar' : 'Teroris'} Database</div>
            <div className="desc">Manage your {isSipendarPage ? 'sipendar' : 'terorist'} database here!</div>
          </div>
          <div className="custom-flex-row-center gap-2">
            <FormWithFormik 
              name="keyword"
              config={filterFormik}
              type="TEXT_INPUT"
              placeholder="Search by name"
            />
            <FormWithFormik 
              name="date"
              config={filterFormik}
              type="DATE"
              placeholderText="Search by Date"
            />
          </div>
        </div>
        <div className="text-right custom-flex-col-space-between gap-4">
          <FormWithFormik 
            type="UPLOAD_FILE"
            config={filterFormik}
            name="file"
            // allowedMimes={[MimeTypes[".xlsx"], MimeTypes[".xls"], MimeTypes[".csv"]]}
            allowedMimes={[MimeTypes[".xlsx"]]}
          />
          <div>
            <div className="right-desc">*Allowed file type is .xlsx</div>
            <div className="right-desc">*Maximum row that can be imported per upload are 50,000 rows</div>
          </div>
        </div>
      </div>
      <ReusableTable<Sipendar> 
        withoutHeader={true}
        withoutDefaultStyles={true}
        className="sipendar-table"
        dataSources={state.data}
        columns={[
          {
            title: "",
            dataIndex: "name",
            render: (_, data) => (
              <div className="custom-flex-row-center gap-2 main-row left-row">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.4998 0.416626C10.913 0.416626 9.34176 0.729171 7.87574 1.33641C6.40973 1.94366 5.07767 2.83371 3.95563 3.95575C1.68957 6.22182 0.416504 9.29526 0.416504 12.5C0.416504 15.7047 1.68957 18.7781 3.95563 21.0442C5.07767 22.1662 6.40973 23.0563 7.87574 23.6635C9.34176 24.2707 10.913 24.5833 12.4998 24.5833C15.7045 24.5833 18.778 23.3102 21.044 21.0442C23.3101 18.7781 24.5832 15.7047 24.5832 12.5C24.5832 10.9132 24.2706 9.34188 23.6634 7.87587C23.0561 6.40985 22.1661 5.07779 21.044 3.95575C19.922 2.83371 18.5899 1.94366 17.1239 1.33641C15.6579 0.729171 14.0866 0.416626 12.4998 0.416626ZM12.4998 8.13788C14.4495 9.40456 16.724 10.0802 19.049 10.0833C19.9915 10.0833 20.8978 9.97454 21.7678 9.76912C22.0215 10.627 22.1665 11.5454 22.1665 12.5C22.1665 17.8287 17.8286 22.1666 12.4998 22.1666C8.87484 22.1666 5.72109 20.1608 4.0415 17.2004L6.15609 14.9166V13.7083C6.15609 13.3077 6.31522 12.9235 6.59848 12.6403C6.88174 12.357 7.26592 12.1979 7.6665 12.1979C8.06709 12.1979 8.45127 12.357 8.73453 12.6403C9.01779 12.9235 9.17692 13.3077 9.17692 13.7083V14.9166H12.4998M17.3332 12.1979C16.9326 12.1979 16.5484 12.357 16.2651 12.6403C15.9819 12.9235 15.8228 13.3077 15.8228 13.7083C15.8228 14.1089 15.9819 14.4931 16.2651 14.7763C16.5484 15.0596 16.9326 15.2187 17.3332 15.2187C17.7338 15.2187 18.1179 15.0596 18.4012 14.7763C18.6845 14.4931 18.8436 14.1089 18.8436 13.7083C18.8436 13.3077 18.6845 12.9235 18.4012 12.6403C18.1179 12.357 17.7338 12.1979 17.3332 12.1979Z" fill="#278DD7"/>
                </svg>
                <div className="content">{data.name}</div>
              </div>
            )
          },
          {
            title: "",
            dataIndex: "date_of_birth",
            render: (_, data) => <div className="main-row custom-flex-row-center">{globalDOBTime(new Date(data.date_of_birth))}</div>
          },
          {
            title: "",
            dataIndex: "id",
            render: (_, data) => (
              <div className="main-row right-row custom-flex-row-center-end">
                <button 
                  className="btn btn-primary custom-flex-center"
                  onClick={()=>updateData(data)}
                >
                  <i className="fa-solid fa-pencil" />
                </button>
                <button 
                  className="btn btn-danger custom-flex-center"
                  onClick={()=>deleteData(data)}
                >
                  <i className="fa-solid fa-trash" />
                </button>
              </div>
            )
          }
        ]}
        pagination={{
          pageRangeDisplayed: 5,
          itemsCountPerPage: state.limit,
          totalItemsCount: state.total_data,
          activePage: state.page,
          onChange: (page) => dispatch({page})
        }}
      />
      <Modal
        show={!!state.isUpdateFormId}
        onHide={()=>dispatch({ isUpdateFormId: undefined })}
        centered
        size="lg"
      >
        <Modal.Body className="wrapper">
          <div className="custom-flex-row-space-between gap-2">
            <div className="custom-flex-col gap-2">
              <div className="title">Edit Database</div>
              <div className="desc">Please complete fields required below.</div>
            </div>
            <button 
              className="sipendar-button custom-flex-row-center gap-2 pt-2 pb-2"
              onClick={updateFormik.submitForm}
            >
              <ButtonIcon />
              <span className="ml-2 mr-2">Submit Data</span>
            </button>
          </div>
          <div className="mt-4 custom-flex-col gap-4">
            <FormWithFormik 
              name="name"
              type="TEXT_INPUT"
              label="Full Name"
              placeholder="Full Name"
              config={updateFormik}
            />
            <FormWithFormik 
              name="date_of_birth"
              type="DATE"
              label="Date of Birth"
              placeholderText="Date of Birth"
              config={updateFormik}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const ButtonIcon: FC = () => {
  return (
    <div className="icon">
      <svg width="20" height="22" viewBox="0 0 20 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 12V4.749C17.0001 4.67006 16.9845 4.59189 16.9543 4.51896C16.9241 4.44603 16.8798 4.37978 16.824 4.324L13.676 1.176C13.5636 1.06345 13.4111 1.00014 13.252 1H1.6C1.44087 1 1.28826 1.06321 1.17574 1.17574C1.06321 1.28826 1 1.44087 1 1.6V20.4C1 20.5591 1.06321 20.7117 1.17574 20.8243C1.28826 20.9368 1.44087 21 1.6 21H11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 1V4.4C13 4.55913 13.0632 4.71174 13.1757 4.82426C13.2883 4.93679 13.4409 5 13.6 5H17M13 18H19M19 18L16 15M19 18L16 21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  )
}

export interface ListDataProps extends Omit<HTMLAttributes<HTMLDivElement>, "id"> {
  id: string | number;
  name: ReactNode;
  dob: string;
}

export const ListData = forwardRef<HTMLDivElement, ListDataProps>((props, ref) => {
  const { id, name, dob, className, ...another } = props
  return (
    <div ref={ref} className={`item custom-flex-row-space-between-center gap-2 ${className ?? ""}`} {...another}>
      <div className="custom-flex-row-center gap-2">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.4998 0.416626C10.913 0.416626 9.34176 0.729171 7.87574 1.33641C6.40973 1.94366 5.07767 2.83371 3.95563 3.95575C1.68957 6.22182 0.416504 9.29526 0.416504 12.5C0.416504 15.7047 1.68957 18.7781 3.95563 21.0442C5.07767 22.1662 6.40973 23.0563 7.87574 23.6635C9.34176 24.2707 10.913 24.5833 12.4998 24.5833C15.7045 24.5833 18.778 23.3102 21.044 21.0442C23.3101 18.7781 24.5832 15.7047 24.5832 12.5C24.5832 10.9132 24.2706 9.34188 23.6634 7.87587C23.0561 6.40985 22.1661 5.07779 21.044 3.95575C19.922 2.83371 18.5899 1.94366 17.1239 1.33641C15.6579 0.729171 14.0866 0.416626 12.4998 0.416626ZM12.4998 8.13788C14.4495 9.40456 16.724 10.0802 19.049 10.0833C19.9915 10.0833 20.8978 9.97454 21.7678 9.76912C22.0215 10.627 22.1665 11.5454 22.1665 12.5C22.1665 17.8287 17.8286 22.1666 12.4998 22.1666C8.87484 22.1666 5.72109 20.1608 4.0415 17.2004L6.15609 14.9166V13.7083C6.15609 13.3077 6.31522 12.9235 6.59848 12.6403C6.88174 12.357 7.26592 12.1979 7.6665 12.1979C8.06709 12.1979 8.45127 12.357 8.73453 12.6403C9.01779 12.9235 9.17692 13.3077 9.17692 13.7083V14.9166H12.4998M17.3332 12.1979C16.9326 12.1979 16.5484 12.357 16.2651 12.6403C15.9819 12.9235 15.8228 13.3077 15.8228 13.7083C15.8228 14.1089 15.9819 14.4931 16.2651 14.7763C16.5484 15.0596 16.9326 15.2187 17.3332 15.2187C17.7338 15.2187 18.1179 15.0596 18.4012 14.7763C18.6845 14.4931 18.8436 14.1089 18.8436 13.7083C18.8436 13.3077 18.6845 12.9235 18.4012 12.6403C18.1179 12.357 17.7338 12.1979 17.3332 12.1979Z" fill="#278DD7"/>
        </svg>
        <div className="content">{props.name}</div>
      </div>
      <div className="content">{globalDateTime(props.dob)}</div>
      <div className="custom-flex-row-center gap-2">
        <button className="btn btn-primary custom-flex-center"><i className="fa-solid fa-pencil" /></button>
        <button className="btn btn-danger custom-flex-center"><i className="fa-solid fa-trash" /></button>
      </div>
    </div>
  )
})

ListData.displayName = "ListData"

export default SipendarComponent