import React from 'react';
import ReactLoading from 'react-loading';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import data from '../../../crud/tes.json';
import thubActions from '../../../crud/thub';
import { Button, Table, Modal, Tab, Tabs, Image, Badge } from 'react-bootstrap';
// import { Range } from 'react-range';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'react-js-pagination';
require('bootstrap/scss/bootstrap.scss');
require('./bookingType.scss');

const history = createBrowserHistory();

const StatusData = (id) => {
  const status = `${id}`;
  switch (status) {
    case '1':
      return 'Open';
    case '0':
      return 'Closed';
    default:
      return 'Open';
  }
};

export default class ThubBookingType extends React.Component {
  history(id) {
    window.open('/thub/booking-type/' + id + '/edit');
    // history.push("/user/" + id + "/history-level-1");
    // window.location.reload();
  }
  seatList(id) {
    window.open('/thub/booking-type/' + id + '/seat-list');
    // history.push("/user/" + id + "/history-level-1");
    // window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      data: data,
      loading: false,
      loadingDataModal: false,
      totalData: 0,
      bookingTypeData: [],
      name: '',
      bookingTypeDetail: [],
      postPerPage: 10,
      totalPages: 0,
      orderBy: 'updated_at',
      orderSort: 'desc',
      active: 1,
      show: false,
      report: false,
      showDetail: false,
      showBlockModal: false,
      params: '',
      reason: '',
      country: 'all',
      occupation: 'all',
      occupations: [],
      KYC: [],
      blockReason: '',
      blockReasonErrorValue: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      whatsappNumber: '',
      locationList: [],
      locationId: '',
      minPrice: '',
      maxPrice: '',
      typeName: '',
      typeStatus: '',
      limitData: 10,
      // priceRange: [0, 1000],
    };

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({});
      obj[fieldName] = event.target.value;

      this.setState(obj);
    };

    this.handleShowDetailBookingType =
      this.handleShowDetailBookingType.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
  }

  handleShowDetailBookingType(params) {
    this.setState({ showDetail: true, params: params });
    this.getDetailBookingType(params);
  }

  handleCloseDetail() {
    this.setState({ showDetail: false });
    this.checkParams();
  }

  getToken() {
    let token = localStorage.getItem('token');
    return token;
  }

  async checkParams() {
    const { locationId, maxPrice, minPrice, typeName, typeStatus } = this.state;
    let params = `?page=${this.state.active}&limit=${this.state.limitData}&location_id=${locationId}&min=${minPrice}&max=${maxPrice}&type_name=${typeName}&status=${typeStatus}`;

    this.setState({
      loading: true,
    });

    thubActions
      .fetchThubBookingTypeList(this.getToken(), params)
      .then((res) => {
        const bookingTypesList = res.data.data.bookingTypes;
        const maxPrice = Math.max.apply(
          Math,
          bookingTypesList.map(function (o) {
            return o.price;
          })
        );

        this.setState({
          totalData: res.data.data.totalData,
          bookingTypeData: res.data.data.bookingTypes,
          postPerPage: res.data.data.totalDataOnPage,
          totalPages: res.data.data.totalPages,
          loading: false,
          // maxPrice,
          // minPrice: 0,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          bookingTypeData: [],
          loading: false,
        });
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  onSortChange = async (name) => {
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });

    this.checkParams();
  };

  changeFilter = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, active: 1 },
      () => {
        this.checkParams();
      }
    );
  };

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber;
    this.setState(
      {
        active: activePage,
      },
      () => {
        this.checkParams();
      }
    );
  };

  async getDetailBookingType(params) {
    this.setState({
      loadingDataModal: true,
    });
    await thubActions
      .fetchThubBookingTypeDetail(this.getToken(), params)
      .then((res) => {
        this.setState({
          bookingTypeDetail: res.data.data[0],
          loadingDataModal: false,
        });
      })
      .catch((e) => {
        this.setState({
          bookingTypeDetail: [],
          loadingDataModal: false,
        });
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        } else {
          console.log(e);
        }
      });
  }

  async getLocationList() {
    await thubActions
      .fetchThubLocationAll(this.getToken())
      .then((res) => {
        this.setState({
          locationList: res.data.data,
        });
      })
      .catch((e) => {
        this.setState({
          locationList: [],
        });
        if (e.response) {
          if (e.response.data.code === 401 || e.response.data.code === 500) {
            toast.error('token has expired');
            localStorage.clear();
            history.push('/auth');
            window.location.reload();
          } else {
            toast.error(
              `error code: ${e.response.data.code} message: ${e.response.data.message}`
            );
          }
        }
      });
  }

  componentDidMount() {
    this.checkParams();
    this.getLocationList();
  }

  getPriceTypeColor = (type) => {
    switch (type) {
      case 'cash':
        return 'info';
      case 'special_privilege':
        return 'success';
      case 'collab_with_tokocrypto':
        return 'success';
      case 'tko_redeem':
        return 'warning';
      case 'tko_holder':
        return 'danger';

      default:
        return 'info';
    }
  };

  getStatusColor(status) {
    switch (status) {
      case 1:
        return 'success';
      case 0:
        return 'danger';

      default:
        return 'danger';
    }
  }

  getBookingTypeColor(name) {
    switch (name) {
      case 'Co-Working':
        return 'primary';
      case 'Private':
        return 'warning';
      case 'Multi Function':
        return 'success';

      default:
        return 'secondary';
    }
  }

  render() {
    const { locationList } = this.state;
    return (
      <div style={{ fontSize: '11px' }} className='head'>
        <div className='kt-portlet'>
          <div className='kt-portlet__body pddmdForce'>
            <div className='kt-portlet__preview'>
              <div className='row'>
                {locationList && locationList.length > 0 && (
                  <div className='col-md-2 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Filter By Location
                    </label>
                    <select
                      name='locationId'
                      className='form-control mr-3'
                      onChange={this.changeFilter}
                    >
                      <option value='' selected>
                        Select Location
                      </option>
                      {locationList.map((data, i) => (
                        <option key={i} value={data.location_id}>
                          {data.location_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className='col-md-3 col-sm-12'>
                  <label className='col-md-12' style={{ paddingLeft: '0' }}>
                    Filter By Type Name
                  </label>
                  <select
                    name='typeName'
                    className='form-control mr-3'
                    onChange={this.changeFilter}
                  >
                    <option selected value=''>
                      Select Type Name
                    </option>
                    <option value='Co-Working'>Co-Working</option>
                    <option value='Private'>Private</option>
                    <option value='Multi Function'>Multifunction</option>
                  </select>
                </div>
                <div className='col-md-4 col-sm-12'>
                  <label className='col-md-12' style={{ paddingLeft: '0' }}>
                    Filter By Price
                  </label>
                  <div className='row'>
                    <div className='col-md-5'>
                      <input
                        type='number'
                        className='form-control mr-3'
                        name='minPrice'
                        placeholder='Min Price'
                        value={this.state.minPrice}
                        onChange={this.changeFilter}
                      />
                    </div>
                    <div className='col-md-2 filter-price__divider'>
                      <p>-</p>
                    </div>
                    <div className='col-md-5'>
                      <input
                        type='number'
                        className='form-control mr-3'
                        name='maxPrice'
                        placeholder='Max Price'
                        value={this.state.maxPrice}
                        onChange={this.changeFilter}
                      />
                    </div>
                  </div>
                  {/* <div style={{ padding: '15px 0' }}>
                    <Range
                      step={0.1}
                      min={0}
                      max={100}
                      values={this.state.priceRange}
                      onChange={(values) =>
                        this.setState({ priceRange: values })
                      }
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            backgroundColor: '#ccc',
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props, index, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '20px',
                            width: '20px',
                            borderRadius: '4px',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 2px 6px #AAA',
                          }}
                        >
                          {isDragged && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '-28px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                fontFamily:
                                  'Arial,Helvetica Neue,Helvetica,sans-serif',
                                padding: '4px',
                                borderRadius: '4px',
                                backgroundColor: '#548BF4',
                              }}
                            >
                              {this.state.priceRange[index].toFixed(
                                1
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              height: '16px',
                              width: '5px',
                              backgroundColor: isDragged
                                ? '#548BF4'
                                : '#CCC',
                            }}
                          />
                        </div>
                      )}
                    />
                  </div> */}
                </div>
                <div className='col-md-3 col-sm-12'>
                  <label className='col-md-12' style={{ paddingLeft: '0' }}>
                    Filter By Status
                  </label>
                  <select
                    name='typeStatus'
                    className='form-control mr-3'
                    onChange={this.changeFilter}
                  >
                    <option value='' selected>
                      Select Status
                    </option>
                    <option value='1'>Open</option>
                    <option value='0'>Closed</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='kt-portlet kt-portlet-top'>
          <div className='kt-portlet__body pddlessForce'>
            <div className='kt-portlet__preview'>
              <div className='paddupgrade'>
                <div className='bookingtype-menu__top'>
                  <Link to='/thub/booking-type/create' className='kt-link'>
                    <Button variant='success' style={{ fontSize: '11px' }}>
                      Create Booking Type
                    </Button>
                  </Link>
                  <select
                    className='form-control bookingtype-limit'
                    onChange={this.changeFilter}
                    name='limitData'
                  >
                    <option value={5}>5</option>
                    <option selected value={10}>
                      10
                    </option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='kt-portlet kt-portlet-top'>
          <div className='kt-portlet__body pddlessForce'>
            <div className='kt-portlet__preview'>
              <div className='kt-section' style={{ marginBottom: 0 }}>
                {this.state.loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ReactLoading
                      type={'bars'}
                      color={'#0000FF'}
                      height={'2%'}
                      width={'5%'}
                    />
                  </div>
                ) : (
                  <>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className='tableheh'
                            style={{
                              verticalAlign: 'middle',
                            }}
                          >
                            No
                          </th>
                          <th
                            className='tableheh'
                            style={{ verticalAlign: 'middle' }}
                          >
                            Location
                            {/* <button
                              onClick={(e) =>
                                this.onSortChange('userId')
                              }
                              className="sort-button"
                            >
                              <i
                                className={`fas fa-${
                                  sortTypes[this.state.orderSort]
                                    .class
                                }`}
                              />
                            </button> */}
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Type
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Capacities
                            <br />
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Price
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Rate Type
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Status
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.bookingTypeData &&
                          this.state.bookingTypeData.length === 0 && (
                            <tr style={{ textAlign: 'center' }}>
                              <td colSpan={8}>No records data found</td>
                            </tr>
                          )}
                        {this.state.bookingTypeData &&
                          this.state.bookingTypeData.length > 0 &&
                          this.state.bookingTypeData.map((row, i) => (
                            <tr key={row.booking_type_id}>
                              <td style={{ verticalAlign: 'middle' }}>
                                {i + 1}
                              </td>
                              <td
                                style={{
                                  verticalAlign: 'middle',
                                }}
                              >
                                {row.location_name}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <Badge
                                  bg={this.getBookingTypeColor(row.type_name)}
                                >
                                  {row.type_name}
                                </Badge>
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.capacities}
                              </td>
                              <td
                                style={{
                                  verticalAlign: 'middle',
                                  textTransform: 'capitalize',
                                }}
                              >
                                <div className='price-type__container-box'>
                                  {row.price_type &&
                                    row.price_type.length > 0 &&
                                    row.price_type.map((data, i) => (
                                      <Badge
                                        bg={this.getPriceTypeColor(data.type)}
                                      >
                                        {data.type.split('_').join(' ')}:{' '}
                                        {data.price}
                                      </Badge>
                                    ))}
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: 'middle',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {row.rate_type_name}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <Badge bg={this.getStatusColor(row.status)}>
                                  {StatusData(row.status)}
                                </Badge>
                              </td>

                              <td className='center'>
                                <div>
                                  <span
                                    onClick={() =>
                                      this.handleShowDetailBookingType(
                                        row.booking_type_id
                                      )
                                    }
                                    style={{
                                      padding: '2px',
                                      fontSize: '0.8rem',
                                      width: '100%',
                                      marginBottom: '5px',
                                    }}
                                    className='btn btn-outline-primary'
                                  >
                                    View
                                  </span>
                                  <br />
                                  <Link
                                    to={`/thub/booking-type/${row.booking_type_id}/edit`}
                                  >
                                    <span
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                      }}
                                      className='btn btn-outline-success'
                                    >
                                      Edit
                                    </span>
                                  </Link>
                                  <br />
                                  <Link
                                    to={`/thub/booking-type/${row.booking_type_id}/${row.location_name} - ${row.type_name}/seat-list`}
                                  >
                                    <span
                                      style={{
                                        padding: '2px',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: '5px',
                                      }}
                                      className='btn btn-outline-secondary'
                                    >
                                      Seat List
                                    </span>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <div className='d-flex justify-content-center align-items-center pagination-data'>
                      <Pagination
                        activePage={this.state.active}
                        itemsCountPerPage={this.state.postPerPage}
                        itemClass='page-item'
                        linkClass='page-link'
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={
                          this.state.postPerPage >= this.state.limitData
                            ? this.state.totalPages
                            : 1
                        }
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDetail}
          onHide={this.handleCloseDetail}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.bookingTypeDetail.type_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loadingDataModal ? (
              ''
            ) : (
              <>
                <Tabs
                  id='controlled-tab-example'
                  defaultActiveKey='home'
                  // onSelect={key => this.setState({ key })}
                >
                  <Tab eventKey='home' title='Booking Type Details'>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Location</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.bookingTypeDetail.location &&
                              this.state.bookingTypeDetail.location
                                .location_name}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Type</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.bookingTypeDetail.type_name}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Descriptions EN</strong>
                          </td>
                          <td colSpan='5'>
                            {this.state.bookingTypeDetail.type_desc_en}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Descriptions ID</strong>
                          </td>
                          <td colSpan='5'>
                            {this.state.bookingTypeDetail.type_desc_id}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Capacities</strong>
                          </td>
                          <td colSpan='5'>
                            {this.state.bookingTypeDetail.capacities}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Rate Type</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.bookingTypeDetail.rate_type
                              ? this.state.bookingTypeDetail.rate_type
                                  .config_type
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Price Type - Price Amount</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.bookingTypeDetail.price_type
                              ? this.state.bookingTypeDetail.price_type.map(
                                  (price, i) => (
                                    <div key={i}>
                                      {price.type} : {price.price}
                                    </div>
                                  )
                                )
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Need Approval</strong>
                          </td>
                          <td colSpan='5'>
                            {this.state.bookingTypeDetail.approval_status === 1
                              ? 'Yes'
                              : 'No'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Image Gallery</strong>
                          </td>
                          <td colSpan={5}>
                            {this.state.bookingTypeDetail &&
                              this.state.bookingTypeDetail.image_gallery &&
                              this.state.bookingTypeDetail.image_gallery
                                .length > 0 &&
                              this.state.bookingTypeDetail.image_gallery.map(
                                (image, i) => (
                                  // <img
                                  //   src={`http://thub-demo.oss-ap-southeast-5.aliyuncs.com/${image}`}
                                  //   key={i}
                                  //   alt="gallery-img"
                                  // />
                                  <Image
                                    thumbnail
                                    src={image}
                                    key={i}
                                    alt='gallery-img'
                                    style={{
                                      maxWidth: '200px',
                                      height: 'auto',
                                    }}
                                  />
                                )
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Status</strong>
                          </td>
                          <td colSpan='5'>
                            {StatusData(this.state.bookingTypeDetail.status)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab>
                  {/* <Tab eventKey="asliri" title="Asliri">
                    <AceEditor
                      width="50"
                      mode="json"
                      theme="github"
                      readOnly={true}
                      // value={PrettyJSON(obj)}
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Tab> */}
                </Tabs>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
