import axios from "axios";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { objFormData } from "../helper";
import { Pagination, PaginationRequest, PaginationResponse, ResponseAPI } from "./global-types";
import { cancelTokenConfiguration } from "./instanceWithCancelToken";
import { GenerateSipendarReportRequest, GenerateSipendarReportResponse, Sipendar, SipendarReport, SipendarRequest, SipendarSource, UpdateSipendarDatabaseRequest, UploadTerroristDataRequest } from "./sipendar-types";

const history =  createBrowserHistory();

const instance = (isCancelToken?: boolean, isUnauthorized?: boolean) => {
  const i = axios.create({
    baseURL: process.env.REACT_APP_ENV === 'production'
    ? 'https://service.tcdx.id/'
    : 'https://service-demo.tcdx.id/',
  });

  if(!isUnauthorized) {
    i.interceptors.request.use((config) => {
      if(config.headers) {
        config.headers.Authorization = localStorage.getItem("token");
        config.headers['X-TCDX-SIGNATURE'] = 'salamtothemoon'
      }
      if(isCancelToken) cancelTokenConfiguration(config)
      return config
    })
    
    i.interceptors.response.use(config => config, (err) => {
      if(err.response){
        if(err.response.status === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${err.response.status} message: ${err.response.data.message}`);
        }
      }
    
      return Promise.reject(err)
    })
  }

  return i
}

const i = instance()

export const getSipendarData = (params: PaginationRequest<SipendarRequest>) => i.get<ResponseAPI<PaginationResponse<Sipendar>>>('admin/sipendar/terrorist', {params})
export const generateSipendarReport = (payload: GenerateSipendarReportRequest) => i.post<ResponseAPI<GenerateSipendarReportResponse>>('admin/sipendar/report', objFormData(payload))
export const uploadTerroristData = (payload: UploadTerroristDataRequest) => i.post<ResponseAPI<boolean>>('admin/sipendar/terrorist/upload', objFormData(payload))
export const updateSipendarDatabase = (id: string, payload: UpdateSipendarDatabaseRequest) => i.patch<ResponseAPI<boolean>>(`/admin/sipendar/terrorist/${id}`, payload)
export const deleteSipendarDatabase = (id: string) => i.delete<ResponseAPI<boolean>>(`/admin/sipendar/terrorist/${id}`)

export const getGenerateSipendarReport = (params: PaginationRequest<GenerateSipendarReportRequest>) => i.get<ResponseAPI<PaginationResponse<GenerateSipendarReportResponse>>>('admin/sipendar/report', {params})
export const getReportDetail = (id: string) => i.get<ResponseAPI<Array<SipendarReport>>>(`admin/sipendar/report/${id}`)