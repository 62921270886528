/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Table, Modal } from "react-bootstrap";
// import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import service from "../../crud/submission";
import { capitalizeFirstLetter, obj2ParamStr } from '../../helper';
import SelectDate from '../../components/datetime';
import Checkbox from '../../components/checkbox'
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { getRiskCalculationLabel } from '../user-new/user';
import ReactSelect from 'react-select'

const CreateDataSubmission = () => {
  const history =  createBrowserHistory();
  const [loading, setLoading] = useState(false)
  const [dataSources, setDataSources] = useState([])
  const [show, setShow] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [seacrhParams, setSeacrhParams] = useState("")
  const [submissionLvl, setSubmissionLvl] = useState("")
  const [riskStatus, setRiskStatus] = useState("")
  const [hasMore, setHasMore] = useState(true);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [riskCalculationFilter, setRiskCalculationFilter] = useState("")
  const riskCalculationFilters = [
    { label: "ALL", value: "" },
    { label: "LOW", value: "low" },
    { label: "MEDIUM", value: "medium" },
    { label: "HIGH", value: "high" },
  ]

  const [detailPage, setDetailPage] = useState({
    current_page: 1,
    per_page: 20,
  });

  // const sortTypes = {
  //   asc: {
  //     class: 'sort-up',
  //     fn: (a, b) => a.net_worth - b.net_worth
  //   },
  //   desc: {
  //     class: 'sort-down',
  //     fn: (a, b) => b.net_worth - a.net_worth
  //   },
  //   default: {
  //     class: 'sort',
  //     fn: (a, b) => a
  //   }
  // };

  const getToken = () =>{
    let token = localStorage.getItem("token");
    return token;
  }

  const summary = async (page = 1) => {
    const currentParams = {
      page,
      limit: detailPage.per_page,
      q: seacrhParams,
      risk_calculation: riskCalculationFilter ? riskCalculationFilter : undefined,
      total_data_submission: submissionLvl ? submissionLvl : undefined,
      screening_risk: riskStatus ? riskStatus : undefined,
      start_at: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
      end_at: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined
    };
    
    try {
      setLoading(true);
      const res = await service.fetchDataSubmission(getToken(), {params: currentParams});
      if (page === 1) {
        setDataSources(res.data.data.data);
        setDetailPage((prevState) => ({
          ...prevState,
          current_page: res.data.data.current_page,
          per_page: res.data.data.per_page,
        }));
        setHasMore(true);
      } else if (res.data.data.data.length) {
        setDataSources(Array.from(dataSources).concat(res.data.data.data));
        setDetailPage((prevState) => ({
          ...prevState,
          current_page: res.data.data.current_page,
          per_page: res.data.data.per_page,
        }));
      } else {
        setHasMore(false);
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }
  };

  const submitData = async () => {
    const datas = {
      user_id: isCheck
    }
    setLoading(true);
    try {
      // const res = await service.sendEmailNotif(getToken(), datas);
      const res = await service.sendEmailNotif(getToken(), datas);
      if(res.data.code === 200){
        toast.success('success add data');
      }
      setLoading(false);
      setShow(false);
      summary(1);
      setIsCheck([]);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    summary(1);
    getToken();
  }, []);

  const handleShowDetail = () => {
    setShow(true);
  }
  const handleCloseDetail = () => {
    setShow(false);
  }

  const handleSearch = () => {
    summary(1);
  }
  // const generateExcel = () => {

  // }

  const handleNextSerries = () => {
    summary(parseInt(detailPage.current_page) + 1);
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(dataSources.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClickOne = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
    }
  };

  const dateTime = date => {
    var fmtDate = moment(date).utc().format('DD/MM/Y hh:mm A');
    return fmtDate;
  }
  const FilterScreeningRisks = [{label: "All", value: ""},{label: "None", value: 0},{label: "Low", value: 1},{label: "Medium", value: 2},{label: "High", value: 3}];

  return (
    <div className="head" style={{fontSize: '11px'}}>
      {console.log('startDate :', startDate)}
      <div className="kt-portlet">
        <div className="kt-portlet__body pad-10-20">
          <div className="kt-portlet__preview">
            <div className="row">
              <div className="col d-flex">
                <input 
                  type="text"
                  className="form-control mr-3 font-8rem"
                  name="search"
                  placeholder="Search by UID, Email, Fullname"
                  value={seacrhParams}
                  onChange={(e) => setSeacrhParams(e.target.value)} />
                <input 
                  type="number"
                  className="form-control font-8rem"
                  name="search"
                  placeholder="Submission Level"
                  value={submissionLvl}
                  onChange={(e) => setSubmissionLvl(e.target.value)} />
              </div>
              <div className="col">
                <select className="form-control font-8rem" onChange={(e) => setRiskStatus(e.target.value)} placeholder="Select Data">
                  <option disabled selected={riskStatus === '' ? true : false}>Select Type</option>
                  {FilterScreeningRisks.map((e) => (
                    <option value={e.value} selected={e.value === riskStatus ? true : false} defaultValue={e.value === riskStatus ? true : false}>{e.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <ReactSelect 
                  placeholder="PPATK Calculation"
                  onChange={(filter) => setRiskCalculationFilter(filter.value)}
                  value={riskCalculationFilters.find(f => f.value === riskCalculationFilter)}
                  options={riskCalculationFilters}
                />
              </div>
              <div className="col">
                <SelectDate changeDate={(val) => setStartDate(val)} />
              </div>
              <div className="col">
                <SelectDate changeDate={(val) => setEndDate(val)} />
              </div>
              <div className="col">
                <button className="btn btn-outline-secondary font-8rem mr-3" type="button"  onClick={() => handleSearch()} style={{ width: '108px', height: '38px' }}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kt-portlet kt-portlet-top">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              <div className="m-3">
                <button
                  className="btn btn-outline-success font-8rem mr-3"
                  type="button"
                  disabled={isCheck.length <= 0}
                  onClick={() => handleShowDetail()}
                  style={{ width: '108px', height: '38px' }}>
                  Submit
                </button>
                <span className="add-selection">
                  you have selected <span style={{color: '#17a84b'}}>{isCheck.length}</span> data
                </span>
              </div>
              <InfiniteScroll
                dataLength={dataSources.length}
                next={handleNextSerries}
                hasMore={hasMore}
                // loader={<ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} />}
              >
                <Table striped>
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          handleClick={() => handleSelectAll()}
                          isChecked={isCheckAll}
                        />
                      </th>
                      <th className="tableheh">
                        No
                      </th>
                      <th className="tableheh">
                        User ID
                      </th>
                      <th className="tableheh">
                        Email
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        Created Date
                      </th>
                      <th className="tableheh">
                        Submission &nbsp;
                      </th>
                      <th className="tableheh">
                        Risk Status &nbsp;
                      </th>
                      <th className="tableheh">
                        PPATK Calculation &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataSources.map( (row, index) => (
                      <tr key={index}>
                        <td className="align-middle">
                          <Checkbox
                            type="checkbox"
                            name={row.email}
                            id={row.id}
                            handleClick={handleClickOne}
                            isChecked={isCheck.includes(row.id)}
                            />
                        </td>
                        <td>{index + 1}</td>
                        <td>{row.ref_id}</td>
                        <td>{row.email}</td>
                        <td>{dateTime(row.created_at)}</td>
                        <td>{row.total_data_submission}</td>
                        <td>
                          {row.screening_risk === 0 && 'None'}
                          {row.screening_risk === 1 && 'Low'}
                          {row.screening_risk === 2 && 'Medium'}
                          {row.screening_risk === 3 && 'High'}
                        </td>
                        <td>{row.risk_calculation ? capitalizeFirstLetter(getRiskCalculationLabel(row.risk_calculation)) : "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </InfiniteScroll> 
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleCloseDetail} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <span className="add-selection mr-0">Are you sure want to create this data Submission ?</span>
            <div className="mt-3">
              <button
                className="btn btn-success font-8rem mr-3"
                type="button"
                disabled={loading}
                onClick={() => submitData()}
                style={{ width: '108px', height: '38px' }}>
                Submit
              </button>
              <button
                className="btn btn-danger font-8rem"
                type="button"
                disabled={loading}
                onClick={() => handleCloseDetail()}
                style={{ width: '108px', height: '38px' }}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreateDataSubmission