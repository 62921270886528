import { FC, useState } from "react";
import { Button, Col, Container, Modal, Row, Stack } from 'react-bootstrap';
import { useFormik } from "formik";
import '../../scss/global.scss';
import FormWithFormik from "../../components/reusableFormWithFormik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { InitialUtilsStateProps, actions } from "../../store/ducks/utils.duck";
import { connect } from "react-redux";
import { RouteComponentProps } from 'react-router'

interface Searching {
    clients: "1" | "0";
    name: string;
}

interface DataInput {
    name: string;
    description: string;
    status: "1" | "0";
}

interface DataClients {
    id: number;
    colorLogo: string;
    title: string;
    desc: string;
    status: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}


const Clients: FC<InitialUtilsStateProps & typeof actions & RouteComponentProps<{ id: string }>> = (props) => {
    const [showModal, setShowModal] = useState<boolean>(false)

    const configFormHeader = useFormik<Searching>({
        initialValues: {
            clients: '1',
            name: ''
        },
        onSubmit: values => {
            console.log(values)
        },
    })
    const configForm = useFormik<DataInput>({
        initialValues: {
            name: '',
            description: '',
            status: '0'
        },
        onSubmit: values => {
            console.log(values)
        },
    })

    const titleData: Array<any> = [
        { id: 1, title: 'Name' },
        { id: 2, title: 'Status' },
        { id: 3, title: 'Created Date' },
        { id: 4, title: 'Update Date' },
        { id: 5, title: 'Options' },
    ]

    const clients: Array<DataClients> = [
        {
            id: 1,
            colorLogo: '#2DA602',
            title: 'Tokocrypto',
            desc: 'Tokocrypto is one of our internal company that will use middleware to allow their user to deposit',
            status: "1",
            createdAt: '01/01/2022',
            createdBy: 'Admin',
            updatedAt: '01/01/2022',
            updatedBy: 'Super Admin',
        },
        {
            id: 2,
            colorLogo: '#278DD7',
            title: 'Tokomall',
            desc: 'Tokocrypto is one of our internal company that will use middleware to allow their user to deposit',
            status: "1",
            createdAt: '01/01/2022',
            createdBy: 'Admin',
            updatedAt: '01/01/2022',
            updatedBy: 'Super Admin',
        },
        {
            id: 3,
            colorLogo: '#F99705',
            title: 'Tlaunchpad',
            desc: 'Tokocrypto is one of our internal company that will use middleware to allow their user to deposit',
            status: "0",
            createdAt: '01/01/2022',
            createdBy: 'Admin',
            updatedAt: '01/01/2022',
            updatedBy: 'Super Admin',
        }
    ]

    const statusClient = (data: string) => {
        if (data === "1") return <ul className="list-status mb-0" style={{ color: '#2DA602' }}>
            <li>
                <p style={{ fontSize: '15px', color: 'black' }}>ACTIVE</p>
            </li>
        </ul>
        else return <ul className="list-status mb-0" style={{ color: 'red' }}>
            <li>
                <p style={{ fontSize: '15px', color: 'black' }}>NONACTIVE</p>
            </li>
        </ul>
    }

    const openModalAdd = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const openModalDel = () =>
        props.addModal({
            type: "DANGER",
            okLabel: "Confirm",
            onOk: () => { console.log('saat ok'); },
            cancelLabel: "Cancel",
            onCancel: () => { console.log('saat cancel'); },
            closable: true,
            title: "Delete Confirmation",
            content: 'Are you sure you want to delete this data?'
        })
    const openModalDisable = () =>
        props.addModal({
            type: "INFO",
            okLabel: "Confirm",
            onOk: () => { console.log('saat ok'); },
            cancelLabel: "Cancel",
            onCancel: () => { console.log('saat cancel'); },
            closable: true,
            title: "Disable Confirmation",
            content: 'Are you sure you want to disable this data?'
        })

    return (
        <>
            <Container fluid className="box-card bg-white p-3 mx-2">
                <form onSubmit={configFormHeader.handleSubmit} className="form-search">
                    <Stack direction="horizontal" gap={3}>
                        <FormWithFormik
                            type='DROPDOWN'
                            name='clients'
                            config={configFormHeader}
                            options={[
                                { label: "Nonactive", value: "0" },
                                { label: "Active", value: "1" },
                            ]}
                        />
                        <FormWithFormik
                            type='TEXT_INPUT'
                            name='name'
                            config={configFormHeader}
                            placeholder="Name"
                        />
                        <Button variant="outline-success" type="submit">Search</Button>
                    </Stack>
                </form>
            </Container>
            <Container fluid className="box-card bg-white mx-2 my-4">
                <Row className="head-row-data p-3">
                    {titleData.map(data => (
                        <Col key={data.id}>
                            <p className="data-row-title m-0">{data.title}</p>
                        </Col>
                    ))}
                </Row>
                <div className="box-card" style={{ boxShadow: 'none' }}>
                    {clients.map((list) => (
                        <Row key={list.id} className="base-row-data-client my-3 p-3">
                            <Col>
                                <Stack direction="horizontal" gap={3}>
                                    <div className="circle-project-icon" style={{ background: list.colorLogo }}></div>
                                    <div>
                                        <Link to={`/middleware/settings/clients/detail/${list.id}`} className="to-detail-data-client">
                                            <h4>{list.title}</h4>
                                        </Link>
                                        <p>{list.desc}</p>
                                    </div>
                                </Stack>
                            </Col>
                            <Col>
                                {statusClient(list.status as string)}</Col>
                            <Col>
                                <p>{list.createdAt}</p>
                                <p>By {list.createdBy}</p>
                            </Col>
                            <Col>
                                <p>{list.updatedAt}</p>
                                <p>By {list.updatedBy}</p>
                            </Col>
                            <Col>
                                <Stack direction="horizontal" gap={2}>
                                    <Button style={{ background: '#F99705', border: 'none' }} onClick={openModalDisable}>Disable</Button>
                                    <Button onClick={openModalAdd} style={{ background: '#278DD7', border: 'none' }}>
                                        <FontAwesomeIcon icon={faPen} />
                                    </Button>
                                    <Button onClick={openModalDel} style={{ background: '#BC0505', border: 'none' }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </Stack>
                            </Col>
                        </Row>
                    ))}
                </div>
            </Container>
            <div className="btn-add-client-wrapper p-4">
                <Button className="btn-add-client" onClick={openModalAdd}>
                    <FontAwesomeIcon icon={faPlus} color='black' />
                </Button>
            </div>

            <Modal size="lg" centered show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Form Clients</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={configForm.handleSubmit}>
                        <FormWithFormik
                            type='TEXT_INPUT'
                            name='name'
                            className="mb-4"
                            label='Client Name'
                            config={configForm}
                            placeholder='Insert your client name'
                        />
                        <FormWithFormik
                            type='TEXT_AREA'
                            name='description'
                            className="mb-4"
                            label='Notes / Descriptions'
                            config={configForm}
                            placeholder='Insert your client notes or descriptions'
                        />
                        <FormWithFormik
                            type='RADIO'
                            name='status'
                            label='Label'
                            config={configForm}
                            className="mb-4 mr-2"
                            options={[
                                { label: "Active", value: "1" },
                                { label: "Disable", value: "0" }
                            ]}
                        />
                        <Button variant="outline-success" type="submit">
                            Submit
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
    return {
        ...props.utils
    }
}

export default connect(mapStateToProps, actions)(Clients)
