import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import courseService from '../../../crud/course';
import services from '../../../crud/tokoscholarContact';

require('bootstrap/scss/bootstrap.scss');

const ContactUsPage = (props) => {
  const [contactUsData, setcontactUsData] = useState(null);
  const [loading, setloading] = useState(false);
  const [searchKeyword, setsearchKeyword] = useState(undefined);
  const [currentPage, setcurrentPage] = useState(1);
  const [student, setstudent] = useState(undefined);
  const [inquiry, setinquiry] = useState(undefined);
  const [createdAt, setcreatedAt] = useState({
    start: undefined,
    end: undefined,
  });
  // const history = useHistory();

  const perPage = 10;

  //   Get Token
  const getToken = () => {
    return localStorage.getItem('token');
  };

  //   Get Course list
  // const handleGetCourse = async (q, page, limit, status) => {
  //   try {
  //     const res = await courseService.fetchCourses(getToken(), {
  //       q,
  //       page,
  //       limit,
  //       status,
  //     });
  //     setcontactUsData(res.data.data || []);
  //   } catch (err) {
  //     console.log(err, 'Error');
  //   }
  // };

  // handle delete course
  // const handleDeleteCourse = (id) => {
  //   Swal.fire({
  //     title: `Are you sure to Delete this data ?`,
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Delete!',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const res = await courseService.fetchDeleteCourse(getToken(), id);
  //         toast.success(res.data.message);
  //         // handleGetCourse(searchKeyword, currentPage, perPage, status);
  //       } catch (error) {
  //         toast.error(error.response.data.message);
  //       }
  //     }
  //   });
  // };

  const handleExportContactData = async (
    q,
    student,
    inquiry,
    start_date,
    end_date
  ) => {
    try {
      const res = await services.fetchExportContactData(getToken(), {
        q,
        student,
        inquiry,
        start_date,
        end_date,
      });
      Swal.fire('Success!!', 'Check your email for exported data!!', 'success');
    } catch (error) {
      Swal.fire('Error!!', error.message, 'error');
      console.log(error, 'Error');
    }
  };

  const getContactData = async (
    q,
    page,
    limit,
    student,
    inquiry,
    start_date,
    end_date
  ) => {
    try {
      // console.log(start_date, 'createdAt');
      const res = await services.fetchContactData(getToken(), {
        q,
        page,
        limit,
        student,
        inquiry,
        start_date,
        end_date,
      });

      setcontactUsData(res.data.data);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  // handle change page
  const handlePageChange = (page) => {
    let activePage = page;
    setcurrentPage(activePage);
    getContactData(
      searchKeyword,
      activePage,
      perPage,
      student,
      inquiry,
      createdAt.start,
      createdAt.end
    );
  };

  // handleSearchCourse
  const handleSearchCourse = () => {
    setcurrentPage(1);
    getContactData(
      searchKeyword,
      1,
      perPage,
      student,
      inquiry,
      createdAt.start,
      createdAt.end
    );
  };

  useEffect(() => {
    getContactData(
      searchKeyword,
      currentPage,
      perPage,
      student,
      inquiry,
      createdAt.start,
      createdAt.end
    );
  }, []);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='d-flex justify-content-between flex-wrap  align-items-end'>
              <input
                value={searchKeyword}
                className='form-control'
                style={{ width: '25%' }}
                onChange={(e) => setsearchKeyword(e.target.value)}
                placeholder='Search by name, uid or email'
                type='text'
              />

              <select
                className='form-control'
                style={{ width: '15%' }}
                onChange={(e) =>
                  setstudent(
                    (e.target.value &&
                      e.target.value !== '' &&
                      e.target.value) ||
                      undefined
                  )
                }
              >
                <option selected value=''>
                  All Student Type
                </option>
                <option value='student'>Student</option>
                <option value='not-student'>Not Student</option>
              </select>
              <select
                className='form-control'
                style={{ width: '15%' }}
                onChange={(e) =>
                  setinquiry(
                    (e.target.value &&
                      e.target.value !== '' &&
                      e.target.value) ||
                      undefined
                  )
                }
              >
                <option selected value=''>
                  All Subject Inquiry
                </option>
                <option value='course-progress'>Course on progress</option>
                <option value='event-tokoscholar'>Event TokoScholar</option>
                <option value='suggestion'>Suggestion</option>
              </select>
              <div style={{ width: '12%' }}>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  Start Created Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='start_at_range_1'
                  placeholder='eg: 24/03/2022'
                  onChange={
                    (e) => {
                      setcreatedAt({
                        ...createdAt,
                        start:
                          (e.target.value &&
                            e.target.value !== '' &&
                            e.target.value.replace('T', ' ')) ||
                          undefined,
                      });
                    }
                    // handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <div style={{ width: '12%' }}>
                <label className='col-md-12' style={{ paddingLeft: '0' }}>
                  End Created Date
                </label>
                <input
                  type='datetime-local'
                  className='form-control mr-3'
                  name='start_at_range_1'
                  placeholder='eg: 24/03/2022'
                  onChange={
                    (e) => {
                      setcreatedAt({
                        ...createdAt,
                        end:
                          (e.target.value &&
                            e.target.value !== '' &&
                            e.target.value.replace('T', ' ')) ||
                          undefined,
                      });
                    }
                    // handleChangeFilter(e.target.name, e.target.value)
                  }
                />
              </div>
              <button
                style={{ width: '8%' }}
                class='btn btn-outline-warning'
                type='submit'
                onClick={handleSearchCourse}
              >
                Search
              </button>
              <button
                style={{ width: '8%' }}
                class='btn btn-outline-info'
                type='button'
                onClick={() =>
                  handleExportContactData(
                    searchKeyword,
                    student,
                    inquiry,
                    createdAt.start,
                    createdAt.end
                  )
                }
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ReactLoading
                        type={'bars'}
                        color={'#0000FF'}
                        height={'2%'}
                        width={'5%'}
                      />
                    </div>
                  ) : (
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className='tableheh'
                            style={{ verticalAlign: 'middle', width: '7%' }}
                          >
                            No &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{ verticalAlign: 'middle' }}
                          >
                            Name &nbsp;
                            <button
                              onClick={(e) => this.onSortChange('email')}
                              className='sort-button'
                            >
                              {/* <i
                          className={`fas fa-${
                            sortTypes[this.state.orderSort].class
                          }`}
                        /> */}
                            </button>
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Email&nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Mobile No. &nbsp;
                            <br />
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Tokocrypto UID &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Student &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            University &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Created At &nbsp;
                          </th>
                          <th
                            className='tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Action &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactUsData &&
                        contactUsData.data &&
                        contactUsData.data.length > 0 ? (
                          contactUsData.data.map((row, key) => (
                            <tr key={key}>
                              <td style={{ verticalAlign: 'middle' }}>
                                {key + 1}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {row.name}
                              </td>

                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.email}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.mobile_no}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.tokocrypto_uid}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {row.student}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {(row.student === 'student' &&
                                  row.university) ||
                                  '-'}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                {(row.created_at &&
                                  moment(row.created_at)
                                    .subtract(
                                      moment(row.created_at).utcOffset(),
                                      'minute'
                                    )
                                    .format('DD MMM YYYY HH:mm')) ||
                                  ''}
                              </td>
                              <td
                                className='center'
                                style={{ verticalAlign: 'middle' }}
                              >
                                <span
                                  className='btn-action mr-1'
                                  onClick={() =>
                                    props.history.push(
                                      '/tokoscholar/contactus/' + row.id
                                    )
                                  }
                                >
                                  <i class='far fa-eye'></i>
                                </span>
                                {/* <span
                                  className='btn-action'
                                  onClick={() => handleDeleteCourse(row.id)}
                                >
                                  <i class='far fa-trash-alt'></i>
                                </span> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9} className='text-center text-danger'>
                              No data available in table
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                  <div className='d-flex justify-content-center align-items-center pagination-data'>
                    <Pagination
                      activePage={
                        (contactUsData?.current_page &&
                          Number(contactUsData?.current_page)) ||
                        1
                      }
                      itemsCountPerPage={contactUsData?.per_page || 0}
                      itemClass='page-item'
                      linkClass='page-link'
                      totalItemsCount={contactUsData?.total_data || 0}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
