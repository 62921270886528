import { FC, Fragment } from "react";
import { Button, Modal } from "react-bootstrap"
import { connect } from "react-redux";
import { InitialUtilsStateProps, actions, CustomModal } from "../../store/ducks/utils.duck";

const ReusableModalDialog: FC<InitialUtilsStateProps & typeof actions> = (props) => {
  const removeSelectedModal = (modal: CustomModal) => {
    props.hideModal(modal.id)

    setTimeout(() => props.removeModal(modal.id), 500)
  }
  const beforeCloseModal = (modal: CustomModal) => {
    if (modal.onClosable) modal.onClosable()
    removeSelectedModal(modal)
  }
  const onOkHandler = (modal: CustomModal) => {
    if (modal.onOk) modal.onOk()
    removeSelectedModal(modal)
  }
  const iconVariantRenderer = (modal: CustomModal) => {
    if (modal.type === "DANGER") return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5V0H15V2ZM7 7V15H9V7H7ZM11 7V15H13V7H11Z" fill="#BC0505" />
      </svg>
    )
    else if (modal.type === "INFO") return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_47_1509)">
          <path d="M13.5 26.1562C20.4899 26.1562 26.1562 20.4899 26.1562 13.5C26.1562 6.51015 20.4899 0.84375 13.5 0.84375C6.51015 0.84375 0.84375 6.51015 0.84375 13.5C0.84375 20.4899 6.51015 26.1562 13.5 26.1562Z" fill="#4FD1D9" />
          <path d="M11.3906 11.7281H15.6094V21.8531H11.3906V11.7281Z" fill="white" />
          <path d="M13.5 9.36563C14.665 9.36563 15.6094 8.42123 15.6094 7.25626C15.6094 6.09128 14.665 5.14688 13.5 5.14688C12.335 5.14688 11.3906 6.09128 11.3906 7.25626C11.3906 8.42123 12.335 9.36563 13.5 9.36563Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_47_1509">
            <rect width="27" height="27" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
    else return undefined
  }
  return (
    <Fragment>
      {props.activeModals.map((modal: CustomModal, key: number) =>
        <Modal
          key={key}
          size={modal.size}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={!!modal.show}
          onHide={() => beforeCloseModal(modal)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="custom-flex-row-center">
              {iconVariantRenderer(modal)}
              <div>{modal.title}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modal.content}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={() => beforeCloseModal(modal)}>{modal.cancelLabel ?? 'Close'}</Button>
            {modal.onOk && <Button onClick={() => onOkHandler(modal)} variant="outline-success">{modal.okLabel ?? 'OK'}</Button>}
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  )
}

const mapStateToProps = (props: { utils: InitialUtilsStateProps }) => {
  return {
    ...props.utils
  }
}

export default connect(mapStateToProps, actions)(ReusableModalDialog)