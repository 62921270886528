import React, { useState, useEffect, Fragment } from 'react';
// import { Button, InputGroup, FormControl, Table } from "react-bootstrap";
import ReactLoading from 'react-loading';
import moment from 'moment';
import thubActions from '../../../crud/thub';
import {
  Button,
  InputGroup,
  FormControl,
  Table,
  Modal,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowBack } from '@material-ui/icons';
require('bootstrap/scss/bootstrap.scss');

const ThubUserHistoryLog = (props) => {
  const [userHistory, setUserHistory] = useState({
    data: [],
    totalData: 0,
    currentPage: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [loginHistory, setLoginHistory] = useState({
    data: [],
    totalData: 0,
    currentPage: 0,
    totalDataOnPage: 0,
    totalPages: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [token, setToken] = useState();

  const { id, name } = useParams();

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
  };

  const getHistoryLog = async (page) => {
    if (token) {
      setIsLoading(true);
      const params = `?page=${page || 1}&limit=10`;
      await thubActions
        .fetchThubUserHistoryLog(token, id, params)
        .then((res) => {
          setUserHistory((prevState) => {
            return {
              ...prevState,
              data: res.data.data['user-logs'],
              totalData: res.data.data.totalData,
              totalDataOnPage: res.data.data.totalDataOnPage,
              currentPage: res.data.data.currentPage,
              totalPages: res.data.data.totalPages,
            };
          });
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setUserHistory((prevState) => {
            return {
              ...prevState,
              data: [],
              totalData: 0,
              totalDataOnPage: 0,
              currentPage: 0,
              totalPages: 0,
            };
          });
          if (e.response) {
            if (
              e.response.data.code === 401 ||
              e.response.data.code === 500
            ) {
              toast.error('token has expired');
              localStorage.clear();
              props.history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const getLoginHistory = async (page) => {
    if (token) {
      setIsLoginLoading(true);
      const params = `?page=${page || 1}&limit=10`;
      await thubActions
        .fetchThubUserLoginHistory(token, id, params)
        .then((res) => {
          setLoginHistory((prevState) => {
            return {
              ...prevState,
              data: res.data.data.loginActivity,
              totalData: res.data.data.totalData,
              totalDataOnPage: res.data.data.totalDataOnPage,
              currentPage: res.data.data.currentPage,
              totalPages: res.data.data.totalPages,
            };
          });
          setIsLoginLoading(false);
        })
        .catch((e) => {
          setIsLoginLoading(false);
          setLoginHistory((prevState) => {
            return {
              ...prevState,
              totalData: 0,
              data: [],
              totalDataOnPage: 0,
              currentPage: 0,
              totalPages: 0,
            };
          });
          if (e.response) {
            if (
              e.response.data.code === 401 ||
              e.response.data.code === 500
            ) {
              toast.error('token has expired');
              localStorage.clear();
              props.history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const getDateTime = (date) => {
    const fmtDate = moment(date).format('DD-MM-YYYY - HH:mm:ss');

    return fmtDate;
  };

  const handleUserPageChange = (page) => {
    setUserHistory((prev) => ({
      ...prev,
      currentPage: page,
    }));
    getHistoryLog(page);
  };

  const handleLoginPageChange = (page) => {
    setLoginHistory((prev) => ({
      ...prev,
      currentPage: page,
    }));
    getLoginHistory(page);
  };

  useEffect(() => {
    getToken();
    setTimeout(() => {
      getHistoryLog();
      getLoginHistory();
    }, 1000);
  }, [token]);

  return (
    <Fragment>
      <div style={{ fontSize: '10px' }} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div className="row row-search">
                  <div className="col-sm-12">
                    <h4>{name.split('-').join(' ')}'s Users Log</h4>
                  </div>
                </div>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th className="left">Datetime</th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        User ID
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Tokocrypto ID
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Email Address
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Whatsapp Number
                      </th>
                      <th className="left">Community</th>
                      <th className="left">KYC Level</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td className="center" colSpan="6">
                          <ReactLoading
                            style={{
                              position: 'relative',
                              left: '50%',
                              fill: 'rgb(88, 103, 221)',
                              height: '5%',
                              width: '5%',
                            }}
                            type={'bars'}
                            color={'#5867dd'}
                            height={'5%'}
                            width={'5%'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {userHistory.data &&
                        userHistory.data.length > 0 &&
                        userHistory.data.map((row) => (
                          <tr key={row.user_log_id}>
                            <td>
                              {getDateTime(row.created_at) || '-'}
                            </td>
                            <td>{row.user.user_id || '-'}</td>
                            <td>{row.user.user_id_toko || '-'}</td>
                            <td>{row.user.email}</td>
                            <td>{row.user.whatsapp_number || '-'}</td>
                            <td>{row.user.community_name || '-'}</td>
                            <td>{row.user.kyc_level || '-'}</td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </Table>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  {/* <div>
                    <button
                      type="button"
                      className={`btn btn-danger btn-elevate kt-login__btn-danger`}
                      onClick={() => {
                        props.history.push('/thub/user-list');
                      }}
                    >
                      <ArrowBack /> Back
                    </button>
                  </div> */}
                  <Pagination
                    activePage={userHistory.currentPage}
                    itemsCountPerPage={userHistory.totalDataOnPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={userHistory.totalData}
                    pageRangeDisplayed={
                      userHistory.totalDataOnPage >= 10
                        ? userHistory.totalPages
                        : 1
                    }
                    onChange={handleUserPageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ fontSize: '10px' }} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <div className="row row-search">
                  <div className="col-sm-12">
                    <h4>{name.split('-').join(' ')} Login History</h4>
                  </div>
                </div>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Datetime
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        IP Address
                        {/* <button
                          onClick={(e) => this.onSortChange('id')}
                          className="sort-button"
                        >
                          <i
                            className={`fas fa-${
                              sortTypes[this.state.orderSort].class
                            }`}
                          />
                        </button> */}
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Country
                      </th>
                      <th
                        className="center"
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Device
                      </th>
                      <th
                        className="center"
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  {isLoginLoading ? (
                    <tbody>
                      <tr>
                        <td className="center" colSpan="6">
                          <ReactLoading
                            style={{
                              position: 'relative',
                              left: '50%',
                              fill: 'rgb(88, 103, 221)',
                              height: '5%',
                              width: '5%',
                            }}
                            type={'bars'}
                            color={'#5867dd'}
                            height={'5%'}
                            width={'5%'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {loginHistory.data &&
                        loginHistory.data.length > 0 &&
                        loginHistory.data.map((row) => (
                          <tr key={row.login_activity_id}>
                            <td>{getDateTime(row.created_at)}</td>
                            <td>{row.ip_address}</td>
                            <td>{row.location}</td>
                            <td>{row.device || '-'}</td>
                            <td>
                              {row.status === 1
                                ? 'Success'
                                : 'Failed'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </Table>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <button
                      type="button"
                      className={`btn btn-danger btn-elevate kt-login__btn-danger`}
                      onClick={() => {
                        props.history.push('/thub/user-list');
                      }}
                    >
                      <ArrowBack /> Back
                    </button>
                  </div>
                  <Pagination
                    activePage={loginHistory.currentPage}
                    itemsCountPerPage={loginHistory.totalDataOnPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={loginHistory.totalData}
                    pageRangeDisplayed={
                      loginHistory.totalDataOnPage >= 10
                        ? loginHistory.totalPages
                        : 1
                    }
                    onChange={handleLoginPageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ThubUserHistoryLog;
