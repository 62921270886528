import { PaginationRequest } from "./global-types"

export enum TypeForm {
  ChangeName = '0',
  EnableAccount = '1',
  ResetGA = '2',
  ResetSms = '3',
  ChangeEmail = '4',
  UnlinkAccount = '5',
}

export enum ServiceType {
  CHANGE_NAME = "ChangeName",
  ENABLE_ACCOUNT = "EnableAccount",
  RESET_GA = "ResetGA",
  RESET_SMS = "ResetSms",
  CHANGE_EMAIL = "ChangeEmail",
  UNLINK_ACCOUNT = "UnlinkAccount"
}

export const serviceTypes = [
  {label: ServiceType.CHANGE_NAME, value: TypeForm.ChangeName},
  {label: ServiceType.ENABLE_ACCOUNT, value: TypeForm.EnableAccount},
  {label: ServiceType.RESET_GA, value: TypeForm.ResetGA},
  {label: ServiceType.RESET_SMS, value: TypeForm.ResetSms},
  {label: ServiceType.CHANGE_EMAIL, value: TypeForm.ChangeEmail},
  {label: ServiceType.UNLINK_ACCOUNT, value: TypeForm.UnlinkAccount},
]

export enum StatusSubmission {
  SUBMITTED = '0',
  ONREVIEW = '1',
  FINISH = '2',
}

export enum StatusForm {
  PENDING = '0',
  APPROVED = '1',
  REJECTED = '2',
  PRE_REQUEST = '3', // default
}

export interface IGetCsRequest extends PaginationRequest {
  last_update?: string;
  type?: TypeForm;
  status?: StatusForm;
}

export interface IGetCsResponse {
  id: number;
  type: TypeForm;
  phone_number: number;
  bank_name: string;
  email_tokocrypto: string;
  tokocrypto_account_name: string;
  uid_tokocrypto: string;
  email_binance: string;
  uid_binance: string;
  reason_request: string;
  photo_ktp: string;
  photo_selfie: string;
  photo_bank_book: string;
  photo_failed_wd: string;
  photo_linked_binance: string;
  photo_linked_tokocrypto: string;
  token: string;
  status: StatusForm;
  submission_status: StatusSubmission;
  account_status: string;
  admin_reject_reason: string;
  expired_at: string;
  is_verified: number;
  created_at: string;
  updated_at: string;
}

export interface IUpdateStatusRequest {
  status: StatusForm;
  reason: string;
}
