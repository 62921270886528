import instance from "./axiosKycInstance";

export function login(data) {
  return instance.post("/admin/auth", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function reset(token, data) {
  return instance.patch("/admin/admin-user/reset-password", data, {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      'Authorization': token
    }
  });
}

export function verifyOTP(token, data) {
  return instance.patch("/admin/verify-token", data, {
    headers: {
      'Authorization': token
    }
  });
}