import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import actions from '../../../crud/kyc-uidconverter';

class UidConverter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csvFileValidation: false,
      uidHistoryData: [],
      csvFile: null,
      loading: false,
      postPerPage: 10,
      totalData: 0,
      page: 1,
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.importUIDConverterCsvFile = this.importUIDConverterCsvFile.bind(this);
    this.getUIDConverterHistoryData = this.getUIDConverterHistoryData.bind(
      this
    );
    this.downloadUIDConverterDataById = this.downloadUIDConverterDataById.bind(
      this
    );
    this.autoGetData = this.autoGetData.bind(this);
    this.fileRef = React.createRef(null);
  }

  autoGetData() {
    setInterval(() => {
      this.getUIDConverterHistoryData();
    }, 60000);
  }

  componentDidMount() {
    this.getUIDConverterHistoryData();
    this.autoGetData();
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  handleChangeInput(e) {
    let filePath = e.target.value;

    // Allowing file type
    let allowedExtensions = /(\.csv)$/i;

    if (!allowedExtensions.exec(filePath)) {
      this.fileRef.current.value = '';
      this.setState({ csvFileValidation: false });
      toast.error(`Invalid file type. only csv allowed`);
      return;
    }

    this.setState({ csvFileValidation: true, csvFile: e.target.files[0] });
  }

  dateTime = (date) => {
    var date1 = date;
    var fmtDate = moment(date1)
      .local()
      .format('DD-MM-Y hh:m A');

    return fmtDate;
  };

  async getUIDConverterHistoryData() {
    try {
      this.setState({ loading: true });
      let params = '?order_sort=desc';

      const limit = `&limit=${this.state.postPerPage}`;

      let page = '&page=1';
      if (this.state.page) {
        page = `&page=${this.state.page}`;
      }

      params += `${page}${limit}`;

      const res = await actions.fetchUIDConverterHistory(params);
      this.setState({
        uidHistoryData: res.data.data.data,
        totalData: res.data.data.total_data,
        loading: false,
      });
    } catch (error) {
      console.log(error, 'Error');
    }
  }

  async importUIDConverterCsvFile() {
    try {
      this.setState({ loading: true });
      if (this.state.csvFile) {
        const body = new FormData();
        body.append('fileCSV', this.state.csvFile);
        const res = await actions.uploadUIDConverterCsvFile(body);
        this.getUIDConverterHistoryData();
        // this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error, 'Error');
    }
  }

  async downloadUIDConverterDataById(id) {
    try {
      const res = await actions.downloadUIDConverterDataById(id);
      let csvContent = atob(res.data.content);
      var url = window.URL.createObjectURL(
        new Blob([csvContent], { type: 'data:application/octet-stream;base64' })
      );
      // you need to precise a front-end button to have a name
      var a = document.createElement('a');
      a.download = `${res.data.name}`;
      a.href = url;
      a.click();
    } catch (error) {
      console.log(error, 'Error');
    }
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber;
    this.setState(
      {
        page: activePage,
      },
      () => {
        this.getUIDConverterHistoryData();
      }
    );
  };

  render() {
    return (
      <div style={{ fontSize: '11px' }} className='head'>
        <div className='kt-portlet'>
          <div className='kt-portlet__body pddmdForce'>
            <div className='kt-portlet__preview'>
              <div className='d-flex justify-content-between align-content-center'>
                <div className='flex-fill border mr-3 d-flex align-items-center p-3 '>
                  <input
                    ref={this.fileRef}
                    className=''
                    onChange={this.handleChangeInput}
                    type='file'
                    accept='.csv'
                  />
                </div>
                <button
                  disabled={
                    this.state.csvFileValidation && !this.state.loading
                      ? false
                      : true
                  }
                  class='btn btn-primary'
                  type='submit'
                  onClick={this.importUIDConverterCsvFile}
                >
                  Import Data
                </button>
              </div>
              <div className='text-muted mt-4 font'>
                <span className='d-block'>*Allowed file type is .csv</span>
                <span className='d-block'>
                  * Maximum row that can be imported per upload are 50,000 rows
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='kt-portlet kt-portlet-top'>
          <div className='kt-portlet__body pddlessForce'>
            <div className='kt-portlet__preview'>
              <div className='kt-section'>
                {this.state.loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ReactLoading
                      type={'bars'}
                      color={'#0000FF'}
                      height={'2%'}
                      width={'5%'}
                    />
                  </div>
                ) : (
                  <>
                    {this.state.loading ? (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <ReactLoading
                          type={'bars'}
                          color={'#0000FF'}
                          height={'2%'}
                          width={'5%'}
                        />
                      </div>
                    ) : (
                      <Table striped responsive>
                        <thead>
                          <tr>
                            <th
                              className='tableheh'
                              style={{ verticalAlign: 'middle', width: '7%' }}
                            >
                              No &nbsp;
                            </th>
                            <th
                              className='tableheh'
                              style={{ verticalAlign: 'middle' }}
                            >
                              File Name &nbsp;
                              <button
                                onClick={(e) => this.onSortChange('email')}
                                className='sort-button'
                              >
                                {/* <i
                              className={`fas fa-${
                                sortTypes[this.state.orderSort].class
                              }`}
                            /> */}
                              </button>
                            </th>
                            <th
                              className='tableheh'
                              style={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              Created By&nbsp;
                            </th>
                            <th
                              className='tableheh'
                              style={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              Created At &nbsp;
                              <br />
                            </th>
                            <th
                              className='tableheh'
                              style={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              Total Imported Record &nbsp;
                            </th>
                            <th
                              className='tableheh'
                              style={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              Total Record Found &nbsp;
                            </th>
                            <th
                              className='tableheh'
                              style={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              Status &nbsp;
                            </th>
                            <th
                              className='tableheh'
                              style={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              Result &nbsp;
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.uidHistoryData &&
                          this.state.uidHistoryData.length > 0 ? (
                            this.state.uidHistoryData.map((row, key) => (
                              <tr key={key}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {key + 1}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {row.file_name}
                                </td>
                                <td
                                  className='center'
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  {row.requested_by}
                                </td>
                                <td
                                  className='center'
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  {this.dateTime(row.created_at)}
                                </td>
                                <td
                                  className='center'
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  {row.total_imported_record}
                                </td>
                                <td
                                  className='center'
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  {row.total_record_found}
                                </td>
                                <td
                                  className='center'
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  {row.status === 1 ? (
                                    <span className='text-success'>
                                      Success
                                    </span>
                                  ) : row.status === 2 ? (
                                    <span className='text-danger'>Failed</span>
                                  ) : (
                                    <span className='text-warning'>
                                      In Progress
                                    </span>
                                  )}
                                </td>

                                <td
                                  className='center'
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  {row.status === 1 && (
                                    <span
                                      onClick={() =>
                                        this.downloadUIDConverterDataById(
                                          row.id
                                        )
                                      }
                                      className='btn-action'
                                    >
                                      <i
                                        style={{ fontSize: 16 }}
                                        class='fas fa-cloud-download-alt'
                                      ></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colspan={8}
                                className='text-center text-danger'
                              >
                                No data available in table
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    )}
                    <div className='d-flex justify-content-center align-items-center pagination-data'>
                      <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={this.state.postPerPage}
                        itemClass='page-item'
                        linkClass='page-link'
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UidConverter;
