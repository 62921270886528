import { createContext, Dispatch, FC, PropsWithChildren, useContext, useReducer } from "react";
import { InitialState, newVersionStorageKey } from "../app/pages/user-new/user";

export const initialState: InitialState = {
  dataSources: [],
  countries: [],
  provinces: {},
  occupations: {},
  loading: false,
  activePage: 1,
  totalItemsCount: 0,
  defaultSort: {
    order_sort: "desc",
    order_by: "updated_at"
  },
  exportModal: false,
  newVersion: localStorage.getItem(newVersionStorageKey) === "true",
  showFilter: true,
  iForm: {
    keyword: "",
    kyc_level: "",
    kyc_status: "",
    liveness: "",
    goverment: "",
    screening_risk: "",
    risk_calculation: "",
    country: "",
  }
}

interface InitialContextType {
	state: InitialState;
	dispatch: Dispatch<Partial<InitialState>>
}

const UserContext = createContext<InitialContextType>({
  state: initialState,
  dispatch: () => null
})

export const useUserContext = () => useContext(UserContext)

export const UserContextWrapper: FC<PropsWithChildren> = (props) => {
  const reducer = (s: InitialState, nState: Partial<InitialState>) => ({...s, ...nState})
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <UserContext.Provider value={{state,dispatch}}>
      {props.children}
    </UserContext.Provider>
  )
}