import React from 'react';

export default class AddAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  render(){

    if (this.state.loading){
      return(
        <p>Loading...</p>
      )
    }
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                Hello
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}