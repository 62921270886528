import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { use, useLocation } from 'react-router-dom';
// import courseService from '../../../crud/course';
import services from '../../../crud/tokoscholarUsers';

require('bootstrap/scss/bootstrap.scss');

const UserCoursePage = (props) => {
  const [userData, setuserData] = useState([]);
  const [userCouresData, setuserCouresData] = useState(null);
  const [loading, setloading] = useState(false);
  const [userFilter, setuserFilter] = useState(undefined);
  const [currentPage, setcurrentPage] = useState(1);
  const [coures, setcoures] = useState(undefined);
  const [topic, settopic] = useState(undefined);
  const [subtopic, setsubtopic] = useState(undefined);
  const [status, setstatus] = useState(undefined);
  const [createdAt, setcreatedAt] = useState({
    start: undefined,
    end: undefined,
  });

  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const idUser = urlParams.get('user');
  // const history = useHistory();

  const perPage = 10;

  //   Get Token
  const getToken = () => {
    return localStorage.getItem('token');
  };

  const handleGetUserData = async () => {
    try {
      setloading(true);
      const res = await services.fetchUsersData(getToken(), {
        page: 1,
        limit: 99,
      });
      setuserData(
        res.data.data.data.map((val) => ({
          label: val.fullname,
          value: val.id,
        }))
      );
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  //   Get Course list
  // const handleGetCourse = async (q, page, limit, status) => {
  //   try {
  //     const res = await courseService.fetchCourses(getToken(), {
  //       q,
  //       page,
  //       limit,
  //       status,
  //     });
  //     setuserCouresData(res.data.data || []);
  //   } catch (err) {
  //     console.log(err, 'Error');
  //   }
  // };

  // handle delete course
  // const handleDeleteCourse = (id) => {
  //   Swal.fire({
  //     title: `Are you sure to Delete this data ?`,
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Delete!',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const res = await courseService.fetchDeleteCourse(getToken(), id);
  //         toast.success(res.data.message);
  //         // handleGetCourse( currentPage, perPage, status);
  //       } catch (error) {
  //         toast.error(error.response.data.message);
  //       }
  //     }
  //   });
  // };

  const handleExportContactData = async (params) => {
    try {
      const res = await services.fetchExportUserCouresData(getToken(), {
        user_id: userFilter.value,
        course_title: coures,
        topic_title: topic,
        sub_topic_title: subtopic,
        status: status,
        ...params,
      });
      Swal.fire('Success!!', 'Check your email for exported data!!', 'success');
    } catch (error) {
      Swal.fire('Error!!', error.message, 'error');
      console.log(error, 'Error');
    }
  };

  const getContactData = async (params) => {
    try {
      const res = await services.fetchUserCouresData(getToken(), {
        page: currentPage,
        limit: perPage,
        user_id: userFilter?.value || undefined,
        course_title: coures,
        topic_title: topic,
        sub_topic_title: subtopic,
        status: status,
        ...params,
      });

      setuserCouresData(res.data.data);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  // handle change page
  const handlePageChange = (page) => {
    let activePage = page;
    setcurrentPage(activePage);
    getContactData({ page: activePage });
  };

  // handleSearchCourse
  const handleSearchCourse = () => {
    setcurrentPage(1);
    // getContactData(1, perPage, userFilter, coures, topic, subtopic);
  };

  useEffect(() => {
    getContactData();
    handleGetUserData();
  }, []);

  useEffect(() => {
    if (idUser) {
      setuserFilter(userData.find((val) => val.value === Number(idUser)));
      getContactData({ user_id: idUser });
    }
  }, [idUser, userData]);

  return (
    <div style={{ fontSize: '11px' }} className='head'>
      <div className='kt-portlet'>
        <div className='kt-portlet__body pddmdForce'>
          <div className='kt-portlet__preview'>
            <div className='row justify-content-between flex-wrap  align-items-end'>
              <div className='mb-3 col-md-6'>
                <Select
                  value={userFilter}
                  onChange={(e) => {
                    // getContactData({ user_id: e.value });
                    setuserFilter(e);
                  }}
                  options={[
                    { label: 'All User', value: undefined },
                    ...userData,
                  ]}
                />
              </div>
              <div className='mb-3 col-md-6'>
                <input
                  onChange={(e) => {
                    setcoures(e.target.value);
                    // getContactData({ course_title: e.target.value });
                  }}
                  value={coures || ''}
                  className='form-control'
                  placeholder='Search by course'
                  type='text'
                />
              </div>
              <div className='mb-3 col-md-4'>
                <input
                  // value={searchKeyword}
                  className='form-control'
                  // style={{ width: '25%' }}
                  onChange={(e) => {
                    settopic(e.target.value);
                    // getContactData({ topic_title: e.target.value });
                  }}
                  value={topic || ''}
                  placeholder='Search by topic'
                  type='text'
                />
              </div>
              <div className='mb-3 col-md-4'>
                <input
                  // value={searchKeyword}
                  className='form-control'
                  // style={{ width: '25%' }}
                  onChange={(e) => {
                    setsubtopic(e.target.value);
                    // getContactData({ sub_topic_title: e.target.value });
                  }}
                  value={subtopic || ''}
                  placeholder='Search by subtopic'
                  type='text'
                />
              </div>
              <div className='mb-3 col-md-2'>
                <select
                  className='form-control'
                  onChange={(e) => {
                    setstatus(
                      (e.target.value &&
                        e.target.value !== '' &&
                        e.target.value) ||
                        undefined
                    );
                    // getContactData({ status: e.target.value });
                  }}
                  value={status}
                >
                  <option selected value=''>
                    Status
                  </option>
                  <option value='0'>Pending</option>
                  <option value='1'>In Progress</option>
                  <option value='2'>Completed</option>
                </select>
              </div>
              <div className='col-md-2 mb-3'>
                <button
                  // style={{ width: '8%' }}
                  class='btn btn-outline-warning mr-3'
                  type='submit'
                  onClick={() => getContactData()}
                >
                  Search
                </button>
                <button
                  // style={{ width: '8%' }}
                  class='btn btn-outline-info'
                  type='button'
                  onClick={
                    () => {
                      handleExportContactData();
                    }
                    //   handleExportContactData(
                    //
                    //     coures,
                    //     topic,
                    //     createdAt.start,
                    //     createdAt.end
                    //   )
                  }
                >
                  Export
                </button>
              </div>
              {/* <div className='col-md-2 mb-2'></div> */}
            </div>
          </div>
        </div>
      </div>

      <div className='kt-portlet'>
        <div className='kt-portlet__body pddlessForce'>
          <div className='kt-portlet__preview'>
            <div className='kt-section'>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactLoading
                    type={'bars'}
                    color={'#0000FF'}
                    height={'2%'}
                    width={'5%'}
                  />
                </div>
              ) : (
                <>
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ReactLoading
                        type={'bars'}
                        color={'#0000FF'}
                        height={'2%'}
                        width={'5%'}
                      />
                    </div>
                  ) : (
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th
                            className='text-center tableheh'
                            style={{ verticalAlign: 'middle', width: '4%' }}
                          >
                            No &nbsp;
                          </th>
                          <th
                            className='text-center tableheh'
                            style={{ verticalAlign: 'middle' }}
                          >
                            User &nbsp;
                            <button
                              onClick={(e) => this.onSortChange('email')}
                              className='text-center sort-button'
                            >
                              {/* <i
                          className={text-center `fas fa-${
                            sortTypes[this.state.orderSort].class
                          }`}
                        /> */}
                            </button>
                          </th>
                          <th
                            className='text-center tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Course&nbsp;
                          </th>
                          <th
                            className='text-center tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Topics &nbsp;
                            <br />
                          </th>
                          <th
                            className='text-center tableheh'
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            Subtopics &nbsp;
                          </th>
                          {/* <th
                            className='tableheh'
                            style={{
                              textalign: 'center',
                              verticalalign: 'middle',
                            }}
                          >
                            Material&nbsp;
                          </th> */}
                          <th
                            className='text-center tableheh'
                            style={{
                              textalign: 'center',
                              verticalalign: 'middle',
                            }}
                          >
                            Status &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userCouresData &&
                        userCouresData.data &&
                        userCouresData.data.length > 0 ? (
                          userCouresData.data.map((row, key) => (
                            <tr key={key}>
                              <td
                                style={{
                                  textAlign: 'center',
                                  verticalalign: 'middle',
                                }}
                              >
                                {key + 1}
                              </td>
                              <td
                                style={{
                                  textAlign: 'center',
                                  verticalalign: 'middle',
                                }}
                              >
                                {row.user.fullname}
                              </td>

                              <td
                                className='center'
                                style={{
                                  textAlign: 'center',
                                  verticalalign: 'middle',
                                }}
                              >
                                {
                                  row.sub_topic.course_topic.course
                                    .course_name_en
                                }
                              </td>
                              <td
                                className='center'
                                style={{
                                  textAlign: 'center',
                                  //   verticalalign: 'middle',
                                }}
                              >
                                {row.sub_topic.course_topic.topic_name_en}
                              </td>
                              <td
                                className='center'
                                style={{
                                  textAlign: 'center',
                                  verticalalign: 'middle',
                                  maxWidth: 200,
                                }}
                              >
                                {row.sub_topic.description_en}
                              </td>
                              {/* <td
                                className='center'
                                style={{ textAlign:'center',verticalalign: 'middle' }}
                              >
                                {row.coures}
                              </td> */}
                              <td
                                className='center'
                                style={{
                                  textAlign: 'center',
                                  verticalalign: 'middle',
                                }}
                              >
                                {row.staus === 1 ? (
                                  <span className='text-center text-warning'>
                                    In Progress
                                  </span>
                                ) : row.status === 2 ? (
                                  <span className='text-center text-success'>
                                    Completed
                                  </span>
                                ) : (
                                  <span>Pending</span>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9} className='text-center text-danger'>
                              No data available in table
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                  <div className='d-flex justify-content-center align-items-center pagination-data'>
                    <Pagination
                      activePage={
                        (userCouresData?.current_page &&
                          Number(userCouresData?.current_page)) ||
                        1
                      }
                      itemsCountPerPage={userCouresData?.per_page || 0}
                      itemClass='page-item'
                      linkClass='page-link'
                      totalItemsCount={userCouresData?.total_data || 0}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCoursePage;
