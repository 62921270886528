import React, { useState, useEffect, Fragment } from 'react';
// import { Button, InputGroup, FormControl, Table } from "react-bootstrap";
import ReactLoading from 'react-loading';
import moment from 'moment';
import thubActions from '../../../crud/thub';
import { Button, InputGroup, FormControl, Table, Modal } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import { ArrowBack } from '@material-ui/icons';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
require('bootstrap/scss/bootstrap.scss');

// const history = createBrowserHistory();

const ThubEventsData = (props) => {
  const [eventDetail, setEventDetail] = useState({
    data: [],
    // totalData: 0,
    // currentPage: 0,
    // totalDataOnPage: 0,
    // totalPages: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState();
  const [participantListData, setparticipantListData] = useState(null);
  const [page, setpage] = useState(1);
  const [filter, setFilter] = useState({
    attended_at: {
      start: undefined,
      end: undefined,
    },
    name: undefined,
    email: undefined,
    ticket_number: undefined,
    whatsapp_number: undefined,
  });

  const limit = 10;

  const { id, bookingid, eventnumber, name } = useParams();

  const getToken = () => {
    let token = localStorage.getItem('token');
    setToken(token);
  };

  const getEventDetailData = async () => {
    if (token) {
      setIsLoading(true);

      await thubActions
        .fetchThubEventsDetail(token, id)
        .then((res) => {
          setEventDetail((prevState) => {
            return {
              ...prevState,
              data: res.data.data,
              // totalData: res.data.data.totalData,
              // dataPerPage: res.data.data.totalDataOnPage,
              // currentPage: res.data.data.currentPage,
            };
          });
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setEventDetail((prevState) => {
            return {
              ...prevState,
              totalData: 0,
              data: [],
              dataPerPage: 0,
              currentPage: 0,
            };
          });
          if (e.response) {
            if (e.response.data.code === 401 || e.response.data.code === 500) {
              toast.error('token has expired');
              localStorage.clear();
              props.history.push('/auth');
              window.location.reload();
            } else {
              toast.error(
                `error code: ${e.response.data.code} message: ${e.response.data.message}`
              );
            }
          }
        });
    }
  };

  const handleGetParticipantEvent = async (params) => {
    try {
      const res = await thubActions.fetchParticipantEvent(token, {
        event_number: eventnumber,
        limit: limit,
        page: page,
        ...params,
      });
      setparticipantListData(res.data.data);
      // console.log(res, 'res');
    } catch (error) {
      setparticipantListData(null);
      console.log(error, 'error');
    }
  };

  const getDateTime = (date) => {
    const fmtDate = moment(date).format('DD-MM-YYYY - HH:mm:ss');

    return fmtDate;
  };

  const handleCheckinUser = async (user_id, ticket_number) => {
    try {
      const res = await thubActions.postParticipantEventAttend(token, {
        id: id,
        user_id,
        ticket_number,
      });
      Swal.fire('Success', 'Successfully Checkin !!', 'success');

      const newAttendedAtStart = moment(
        String(filter.attended_at.start).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');
      const newAttendedAtEnd = moment(
        String(filter.attended_at.end).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');

      handleGetParticipantEvent({
        page: page,
        ticket_number:
          (filter.ticket_number &&
            filter.ticket_number !== '' &&
            filter.ticket_number) ||
          undefined,
        whatsapp_number:
          (filter.whatsapp_number &&
            filter.whatsapp_number !== '' &&
            filter.whatsapp_number) ||
          undefined,
        email:
          (filter.email && filter.email !== '' && filter.email) || undefined,
        first_name:
          (filter.name && filter.name !== '' && filter.name) || undefined,
        attended_at_range_1:
          (filter.attended_at?.start &&
            filter.attended_at?.start !== '' &&
            newAttendedAtStart) ||
          undefined,
        attended_at_range_2:
          (filter.attended_at?.end &&
            filter.attended_at?.end !== '' &&
            newAttendedAtEnd) ||
          undefined,
      });
    } catch (error) {
      console.log(error, 'Error');
      if (error.response)
        Swal.fire(
          'Error',
          error.response?.data?.message || 'Something Wrong!!',
          'error'
        );
    }
  };

  const handlePageChangeParticipant = (page) => {
    try {
      const newAttendedAtStart = moment(
        String(filter.attended_at.start).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');
      const newAttendedAtEnd = moment(
        String(filter.attended_at.end).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');

      setpage(page);
      handleGetParticipantEvent({
        page: page,
        ticket_number:
          (filter.ticket_number &&
            filter.ticket_number !== '' &&
            filter.ticket_number) ||
          undefined,
        whatsapp_number:
          (filter.whatsapp_number &&
            filter.whatsapp_number !== '' &&
            filter.whatsapp_number) ||
          undefined,
        email:
          (filter.email && filter.email !== '' && filter.email) || undefined,
        first_name:
          (filter.name && filter.name !== '' && filter.name) || undefined,
        attended_at_range_1:
          (filter.attended_at?.start &&
            filter.attended_at?.start !== '' &&
            newAttendedAtStart) ||
          undefined,
        attended_at_range_2:
          (filter.attended_at?.end &&
            filter.attended_at?.end !== '' &&
            newAttendedAtEnd) ||
          undefined,
      });
    } catch (error) {
      console.log(error, 'Error');
    }
  };

  const handleSearchParticipant = () => {
    try {
      const newAttendedAtStart = moment(
        String(filter.attended_at.start).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');
      const newAttendedAtEnd = moment(
        String(filter.attended_at.end).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');

      handleGetParticipantEvent({
        page: page,
        attended_at_range_1:
          (filter.attended_at?.start &&
            filter.attended_at?.start !== '' &&
            newAttendedAtStart) ||
          undefined,
        attended_at_range_2:
          (filter.attended_at?.end &&
            filter.attended_at?.end !== '' &&
            newAttendedAtEnd) ||
          undefined,
        ticket_number:
          (filter.ticket_number &&
            filter.ticket_number !== '' &&
            filter.ticket_number) ||
          undefined,
        whatsapp_number:
          (filter.whatsapp_number &&
            filter.whatsapp_number !== '' &&
            filter.whatsapp_number) ||
          undefined,
        email:
          (filter.email && filter.email !== '' && filter.email) || undefined,
        first_name:
          (filter.name && filter.name !== '' && filter.name) || undefined,
        // last_name:,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportParticipant = async () => {
    try {
      const newAttendedAtStart = moment(
        String(filter.attended_at.start).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');
      const newAttendedAtEnd = moment(
        String(filter.attended_at.end).replace('T', ' ')
      )
        .subtract(moment().utcOffset(), 'minute')
        .format('YYYY-MM-DD HH:mm');

      const res = await thubActions.fetchExportParticipantEvent(
        token,
        {
          page,
          attended_at_range_1:
            (filter.attended_at?.start && newAttendedAtStart) || undefined,
          attended_at_range_2:
            (filter.attended_at?.end && newAttendedAtEnd) || undefined,
          ticket_number:
            (filter.ticket_number &&
              filter.ticket_number !== '' &&
              filter.ticket_number) ||
            undefined,
          whatsapp_number:
            (filter.whatsapp_number &&
              filter.whatsapp_number !== '' &&
              filter.whatsapp_number) ||
            undefined,
          email:
            (filter.email && filter.email !== '' && filter.email) || undefined,
          first_name:
            (filter.name && filter.name !== '' && filter.name) || undefined,
          limit: 9999,
        },
        id
      );
      Swal.fire('Success', 'Successfully, Data Send to your email', 'success');
    } catch (error) {
      console.log(error, 'Error');
      if (error.response)
        Swal.fire(
          'error',
          error.response?.data?.message || 'Something Error!',
          'error'
        );
    }
  };

  useEffect(() => {
    getToken();
    setTimeout(() => {
      getEventDetailData();
      if (token) handleGetParticipantEvent();
    }, 1000);
  }, [token]);

  return (
    <Fragment>
      <div style={{ fontSize: '10px' }} className='head'>
        <div className='kt-portlet'>
          <div className='kt-portlet__body'>
            <div className='kt-portlet__preview'>
              <div className='kt-section'>
                <div className='row row-search'>
                  <div className='col-sm-12'>
                    <h4>
                      {eventDetail.data.event_number} - {name}'s speakers list
                    </h4>
                  </div>
                </div>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                          width: '7%',
                        }}
                      >
                        No &nbsp;
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Full Name &nbsp;
                        {/* <button
                          onClick={(e) => this.onSortChange('id')}
                          className="sort-button"
                        >
                          <i
                            className={`fas fa-${
                              sortTypes[this.state.orderSort].class
                            }`}
                          />
                        </button> */}
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Title &nbsp;
                      </th>
                      <th className='left'>About</th>
                      <th className='left'>Link to personal data</th>
                      <th className='left'>Instagram Link</th>
                      <th className='left'>Twitter Link</th>
                      <th className='left'>Action</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td className='center' colSpan='8'>
                          <ReactLoading
                            style={{
                              position: 'relative',
                              left: '50%',
                              fill: 'rgb(88, 103, 221)',
                              height: '5%',
                              width: '5%',
                            }}
                            type={'bars'}
                            color={'#5867dd'}
                            height={'5%'}
                            width={'5%'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {eventDetail.data.speakers &&
                        eventDetail.data.speakers.length > 0 &&
                        eventDetail.data.speakers.map((row, i) => (
                          <tr key={row.speaker_id}>
                            <td>{i + 1}</td>
                            <td>{row.fullname}</td>
                            <td>{row.tittle}</td>
                            <td>{row.about}</td>
                            <td>{row.link_to_personal_data}</td>
                            <td>{row.instagram}</td>
                            <td>{row.twitter}</td>
                            <td>
                              {' '}
                              <Link
                                to={`/thub/collaboration/${row.collaboration_id}/edit`}
                              >
                                <span
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className='btn btn-outline-success'
                                >
                                  Edit
                                </span>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </Table>
                {/* {eventDetail.data.speakers &&
                  eventDetail.data.speakers.length > 0 && (
                    <Pagination
                      activePage={eventDetail.currentPage}
                      itemsCountPerPage={eventDetail.postPerPage}
                      itemClass="page-item"
                      linkClass="page-link"
                      totalItemsCount={eventDetail.totalData}
                      pageRangeDisplayed={5}
                      // onChange={this.handlePageChange}
                    />
                  )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ fontSize: '10px' }} className='head'>
        <div className='kt-portlet'>
          <div className='kt-portlet__body'>
            <div className='kt-portlet__preview'>
              <div className='kt-section'>
                <div className='row row-search'>
                  <div className='col-sm-9'>
                    <h4>
                      {eventDetail.data.event_number} - {name}'s participant
                      list
                    </h4>
                  </div>
                  <div className='col-md-3 col-sm-12 '>
                    <div className='row justify-content-end'>
                      {/* <div className=''> */}
                      <div className=''>
                        <Button
                          variant='success'
                          style={{ fontSize: '11px', marginRight: '12px' }}
                          onClick={handleSearchParticipant}
                        >
                          Search
                        </Button>
                        <Button
                          onClick={handleExportParticipant}
                          variant='info'
                          style={{ fontSize: '11px' }}
                        >
                          Export
                        </Button>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className='row row-search align-items-end'>
                  <div className='col-md-2 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Filter By Start Attended At
                    </label>
                    <input
                      type='datetime-local'
                      className='form-control mr-3'
                      name='start_at_range_1'
                      placeholder='eg: 24/03/2022'
                      onChange={
                        (e) =>
                          setFilter({
                            ...filter,
                            attended_at: {
                              ...filter.attended_at,
                              start: e.target.value,
                            },
                          })
                        // handleChangeFilter(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Filter By End Attended At
                    </label>
                    <input
                      type='datetime-local'
                      className='form-control mr-3'
                      name='start_at_range_2'
                      placeholder='eg: 24/03/2022'
                      onChange={
                        (e) =>
                          setFilter({
                            ...filter,
                            attended_at: {
                              ...filter.attended_at,
                              end: e.target.value,
                            },
                          })
                        // handleChangeFilter(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Email
                    </label>
                    <input
                      type='text'
                      className='form-control mr-3'
                      name='start_at_range_2'
                      placeholder=''
                      onChange={
                        (e) =>
                          setFilter({
                            ...filter,
                            email: e.target.value,
                          })
                        // handleChangeFilter(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Name
                    </label>
                    <input
                      type='text'
                      className='form-control mr-3'
                      name='start_at_range_2'
                      placeholder=''
                      onChange={
                        (e) =>
                          setFilter({
                            ...filter,
                            name: e.target.value,
                          })
                        // handleChangeFilter(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      Ticket Number
                    </label>
                    <input
                      type='text'
                      className='form-control mr-3'
                      name='start_at_range_2'
                      placeholder=''
                      onChange={
                        (e) =>
                          setFilter({
                            ...filter,
                            ticket_number: e.target.value,
                          })
                        // handleChangeFilter(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    <label className='col-md-12' style={{ paddingLeft: '0' }}>
                      WA Number
                    </label>
                    <input
                      type='text'
                      className='form-control mr-3'
                      name='start_at_range_2'
                      placeholder=''
                      onChange={
                        (e) =>
                          setFilter({
                            ...filter,
                            whatsapp_number: e.target.value,
                          })
                        // handleChangeFilter(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        No &nbsp;
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Name &nbsp;
                        {/* <button
                          onClick={(e) => this.onSortChange('id')}
                          className="sort-button"
                        >
                          <i
                            className={`fas fa-${
                              sortTypes[this.state.orderSort].class
                            }`}
                          />
                        </button> */}
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Email &nbsp;
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Ticket Number &nbsp;
                      </th>
                      <th
                        className='center'
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Whatsapp Number
                      </th>
                      <th
                        className='center'
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Joined At
                      </th>
                      <th
                        className='center'
                        style={{
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}
                      >
                        Attended At
                      </th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td className='center' colSpan='6'>
                          <ReactLoading
                            style={{
                              position: 'relative',
                              left: '50%',
                              fill: 'rgb(88, 103, 221)',
                              height: '5%',
                              width: '5%',
                            }}
                            type={'bars'}
                            color={'#5867dd'}
                            height={'5%'}
                            width={'5%'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {participantListData?.participantEvents &&
                      participantListData.participantEvents.length > 0 ? (
                        participantListData.participantEvents.map((row, i) => (
                          <tr key={row.participant_list?.user_id}>
                            <td>{i + 1}</td>
                            <td>{`${row.participant_list?.first_name} ${row.participant_list?.last_name}`}</td>
                            <td>{row.participant_list?.email}</td>
                            <td>
                              {row.participant_list?.ticket_number || '-'}
                            </td>
                            <td>{row.participant_list?.whatsapp_number}</td>
                            <td>
                              {getDateTime(row.participant_list?.joined_at)}
                            </td>
                            <td>
                              {row.participant_list?.attended_at ? (
                                getDateTime(row.participant_list?.attended_at)
                              ) : (
                                <span
                                  style={{
                                    padding: '2px',
                                    fontSize: '0.8rem',
                                    width: '100%',
                                    marginBottom: '5px',
                                  }}
                                  className='btn btn-outline-success'
                                  onClick={() =>
                                    handleCheckinUser(
                                      row.participant_list?.user_id,
                                      row.participant_list?.ticket_number
                                    )
                                  }
                                >
                                  Check In
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td style={{ textAlign: 'center' }} colSpan={7}>
                            Data Not Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </Table>
                <div className='d-flex justify-content-center align-items-center pagination-data'>
                  <Pagination
                    activePage={participantListData?.currentPage}
                    itemsCountPerPage={participantListData?.totalDataOnPage}
                    itemClass='page-item'
                    linkClass='page-link'
                    totalItemsCount={participantListData?.totalData}
                    // pageRangeDisplayed={
                    //   participantListData.totalDataOnPage >= filterData.limitData
                    //     ? participantListData.totalPages
                    //     : 1
                    // }
                    onChange={handlePageChangeParticipant}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <button
                      type='button'
                      className={`btn btn-danger btn-elevate kt-login__btn-danger`}
                      onClick={() => {
                        props.history.push('/thub/events');
                      }}
                    >
                      <ArrowBack /> Back
                    </button>
                  </div>
                </div>
                {/* <Pagination
                  activePage={eventDetail.currentPage}
                  itemsCountPerPage={eventDetail.postPerPage}
                  itemClass="page-item"
                  linkClass="page-link"
                  totalItemsCount={eventDetail.totalData}
                  pageRangeDisplayed={5}
                  // onChange={this.handlePageChange}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ThubEventsData;
